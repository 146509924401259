import React from "react";
import {
  ListBulletIcon,
  ArrowUpTrayIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import Navbar from "../Navbar/Navbar";
import toolBox from "../../assets/images/MynaToolBox.png";
import toolboxGeography from "../../assets/images/toolboxGeography.png";
import toolboxFileupload from "../../assets/images/toolboxUploadfile.png";
import mynaMap from "../../assets/images/MYNAmap.png";
import mynaDraw from "../../assets/images/mynaMapDrawOptions.png";
import mynaPolygonDraw from "../../assets/images/mynaMapPolygonOption.png";
import polygonDraw from "../../assets/images/drawUsingPolygon.png";
import { Link } from "react-router-dom";

function Instructions() {
  localStorage.setItem("isUserNew", true);
  const features = [
    {
      name: "Upload Map File",
      description:
        "By using Upload File option supported files (KML, GeoJson and ShapeFile) can be uploaded and report can be generated",
      icon: ArrowUpTrayIcon,
      subSteps: [
        {
          description:
            "Click on UPLOAD FILE Button in MYNA'S tool box, doing this will expand upload file menu, then point your cursor to Upload file, this will further open a popup with a option to select supported files",
          note: null,
          image: toolboxFileupload,
        },
        {
          description: "Click on required file type, will will open a explorer",
          note: null,
          image: null,
        },
        {
          description:
            "Choose your file and proceed, this will create a polygon respective to the uploaded file on the map",
          note: null,
          image: mynaMap,
        },
        {
          description: "Confirm Geography and Click Generate Report",
          note: "You may also edit the polygon before generting the report by clicking on edit button, this will enable edit mode and you will see edit points while hovering over boundries of the polygon",
          image: null,
        },
      ],
    },
    {
      name: "Draw on Map",
      description:
        "Using Drawing tool, you can draw/select an enclosed area on the map and to generate report",
      icon: PencilIcon,
      subSteps: [
        {
          description:
            "By using Draw tool on TOP-LEFT of map, draw features can be used",
          note: null,
          image: mynaDraw,
        },
        {
          description: "Select Polygon option to draw a polygon on map",
          note: null,
          image: mynaPolygonDraw,
        },
        {
          description:
            "Start Selecting required area to create a polygon, to complete polygon click on first point to complete",
          note: null,
          image: polygonDraw,
        },
        {
          description:
            "After you are done with selecting, confirm the geography and click on Generate Report",
          note: "You can also save the polygon as a KML,GeoJson, or a Shapefile",
          image: null,
        },
      ],
    },
    {
      name: "Select Geography",
      description:
        "Report can be generated By Selecting Geographical Location based on available data.",
      icon: ListBulletIcon,
      subSteps: [
        {
          description: "Click on CHOOSE GEOGRAPHY button on MYNA's toolbox, this will expand geography options",
          note: null,
          image: toolBox,
        },
        {
          description:
            "Select the state and the district, and optionally the locality and click on Generate Report",
          note: null,
          image: toolboxGeography,
        },
      ],
    },
  ];
  return (
    <>
      <Navbar />
      <div className="overflow-hidden bg-white py-16 md:py-12 gandhi-family">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-1">
            <div className="lg:pr-8 lg:pt-4">
              <div className="">
                <h2 className="text-base font-semibold leading-7 ">
                  Usage Instructions
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight gandhi-family-bold text-[#DAB830] sm:text-4xl">
                  Generating Report
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  This tool has three ways to generate report.
                </p>
                <dl className="mt-8 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature, index) => (
                    <div key={feature.name} className="relative pl-9 my-12">
                      <dt className="inline font-semibold text-gray-900 text-1xl">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {index + 1 + ". " + feature.name}
                      </dt>{" "}
                      <dd className="inline text-1xl">{feature.description}</dd>
                      <div className="p-3 shadow-md bg-[#f8f6e8] mt-4">
                        {feature.subSteps.map((step, index) => (
                          <div className="mt-3">
                            {" "}
                            <dt className="inline font-medium text-gray-600">
                              <feature.icon
                                className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                aria-hidden="true"
                              />
                              {`Step ${index + 1}`}
                            </dt>
                            {"-"}
                            <dd className="inline">{step.description}</dd>
                            <dd className="ms-14 text-xs text-gray-400">
                              {step.note && "*" + step.note}
                            </dd>
                            {step.image && (
                              <div className="p-2 md:p-8">
                                <img className={``} src={step.image} alt="" />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </dl>
                <p className="mt-12 text-3xl font-bold tracking-tight gandhi-family-bold text-[#DAB830] sm:text-4xl">
                  Reading the Report
                </p>
                <p className="leading-6">
                  This data is based entirely on eBird data that was downloaded
                  from{" "}
                  <a href="https://www.ebird.org/india" target="_blank">www.ebird.org/india</a>,
                  and includes public observations up till 31 May 2023. Data on
                  sensitive species (
                  <a href="https://ebird.org/india/news/ebird-sensitive-species" target="_blank">
                    https://ebird.org/india/news/ebird-sensitive-species
                  </a>
                  ) is not included in these summaries. All the different
                  classifications in this report follow the defined categories
                  of State of India’s Birds (SoIB) attribute table (
                  <a href="https://stateofindiasbirds.in/#soib_report" target="_blank"  >
                    See Table
                  </a>
                  ).
                </p>
                <p className=" mt-3">
                  <span className="font-medium">Title of the Report</span>: This is directly entered by the
                  user as the Name of the Report. If the name of the report is
                  ‘My Area’, the title of the report will be shown as “Birds of
                  My Area”.
                </p>
                <p className=" mt-3">
                  <span className="font-medium">State & District</span>: This will be displayed only if the
                  report is generated using the ‘Geography’ option.
                </p>

                <p className=" mt-3">
                  <span className="font-medium">SPECIES DETAILS</span>: These summaries only include wild bird
                  species and exclude all exotic species.
                </p>

                <p className=" mt-3">
                  <span className="font-medium">Total:</span> Total number of bird species reported from the
                  region.
                </p>

                <p className=" mt-3">
                  <span className="font-medium">Migratory</span>: Total number of migratory bird species
                  reported from the region. All species listed as the following
                  categories in the SoIB attribute table are treated here as
                  migratory.
                  <ul className="list-disc">
                    <li> Altitudinal Migrant</li>
                    <li> Local Migrant</li>
                    <li> Local Migrant & Summer Migrant</li>
                    <li> Local Migrant & Winter Migrant</li>
                    <li>Localized Summer Migrant & Localized Winter Migrant</li>
                    <li> Passage Migrant</li>
                    <li> Passage Migrant & Localized Summer Migrant</li>
                    <li> Passage Migrant & Localized Winter Migrant</li>
                    <li> Summer Migrant</li>
                    <li> Summer Migrant & Localized Winter Migrant</li>
                    <li> Summer Migrant & Passage Migrant</li>
                    <li> Summer Migrant & Winter Migrant</li>
                    <li> Winter Migrant</li>
                    <li> Winter Migrant & Localized Summer Migrant</li>
                    <li> Winter Migrant (Extirpated)</li>
                    <li> Within-India Migrant</li>
                    <li> Within-India Migrant & Winter Migrant</li>
                  </ul>
                </p>
                <p className="mt-3">
                  <span className="font-medium">Threatened</span>: Number of bird species that are listed as
                  threatened (Critically Endangered, Endangered, or Vulnerable)
                  as per the global <a href="https://www.iucn.org/" target="_blank">IUCN assessments.</a>
                </p>

                <p className="mt-3">
                  <span className="font-medium">High Priority</span>: Number of bird species that are listed
                  as High Conservation Priority in the <a href="https://www.stateofindiasbirds.in/" target="_blank">State of India's Birds
                    2023.</a>
                </p>

                <p className="mt-3">
                  <span className="font-medium">Schedule I</span>: Number of bird species that are listed in
                  Schedule 1 of the <a href="https://prsindia.org/files/bills_acts/acts_parliament/2022/The%20Wild%20Life%20(Protection)%20Amendment%20Act,%202022.pdf" target="_blank">Wild Life (Protection) Amendment Act,
                    2022(WLPA).</a>
                </p>

                <p className="mt-3">
                  <span className="font-medium">Endemic to India</span>: Number of bird species that are
                  endemic to India
                </p>
                <p className="mt-3">
                  <ul className="list-disc">
                    The following table summaries also follow the <a href="https://stateofindiasbirds.in/#soib_report" target="_blank" >SoIB attribute
                      table.</a>
                    <li><a href="https://www.stateofindiasbirds.in/" target="_blank">SoIB Conservation Priority Species</a></li>
                    <li><a href="https://cites.org/eng/app/index.php" target="_blank">CITES Appendix Species</a></li>
                    <li><a href="https://www.cms.int/en/species/appendix-i-ii-cms" target="_blank">CMS Appendix Species</a></li>
                    <li><a href="https://www.iucn.org/" target="_blank">IUCN Red List</a></li>
                  </ul>
                  <p>
                    The remaining tables may not be displayed for every region.
                    If any of these have zero entries, the entire table is
                    skipped.
                  </p>
                  <p>
                    <span className="font-medium">SOIB HIGH PRIORITY SPECIES, IUCN RED LIST SPECIES & ENDEMIC SPECIES</span> tables also include year of latest report and frequency of reporting (i.e., how often a species is reported measured by the percentage of complete checklists reporting this species)
                     apart from the values directly
                    obtained from <a href="https://stateofindiasbirds.in/#soib_report" target="_blank">SoIB data table.</a>
                  </p>

                  <p className="mt-3">
                    <span className="font-medium">WATERBIRD CONGREGATIONS</span> table includes the ‘Highest Count’
                    of a waterbird species reported from the region and its 1%
                    biogeographic (South Asia) population (i.e., <i>Total
                      population of a specific waterbird species found within a
                      particular biogeographic region is its biogeographic
                      population. 1% of this biogeographic population size is used
                      as a significant threshold</i>.). The number in brackets is
                    highest count expressed as a percentage of the biogeographic
                    population (e.g. if the highest count for a Species A is
                    10,000, and the total biogeographic population in South Asia
                    is 1,00,000, then the count is equivalent to 10% of the
                    total biogeographic population of that species.) The year when the largest congregation was reported is also included."
                  </p>
                  <p className="mt-3">
                    <span className="font-medium">MOST COMMON SPECIES</span> chart includes the top ten common
                    species by their frequency of reporting.
                  </p>

                  <p className="mt-3">
                    <span className="font-medium">SEASONAL CHART</span>   includes the top ten migratory species and their frequency of reporting in each month of the year.
                  </p>


                  <p className="mt-3">
                    <span className="font-medium">TOP HOTSPOTS</span> list the top (maximum 5) eBird hotspots from
                    the region from which the maximum number of bird species are
                    reported. Their locations are shown in the map.
                  </p>
                  <p className="mt-3">
                    <span className="font-medium">COMPLETE LIST OF SPECIES</span> is a full table of all the bird
                    species, and their conservation priority, threat status,
                    endemicity, and protection status.
                  </p>
                  <p className="mt-3">
                    <span className="font-medium">DATA CONTRIBUTIONS</span> gives an overview of the effort expended by citizen scientists to generate the above data. It has the total number of observations, number of lists, number of unique lists, number of birding hours, and number of observers. Citizen scientists often visit in groups, with one person making a list and sharing it with others. In such cases, every person present during that visit will get one list of their own. However, we remove such shared duplicates and pick only one unique list for every visit. Except for unique lists and number of birding hours where shared lists are excluded, all other values are based on the complete set of observation data.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-8 flex justify-center gandhi-family">
        <Link to="/">
          <button className="p-2 rounded bg-[#DAB830] hover:bg-[#635b3a] text-slate-100     font-semibold">
            Start Using
          </button>
        </Link>
      </div>
    </>
  );
}

export default Instructions;
