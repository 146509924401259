export const statesList = [
  "Jharkhand",
  "Uttarakhand",
  "Rajasthan",
  "Maharashtra",
  "Lakshadweep",
  "Bihar",
  "Gujarat",
  "Punjab",
  "Puducherry",
  "Himachal Pradesh",
  "Nagaland",
  "Karnataka",
  "Kerala",
  "Daman and Diu",
  "Delhi",
  "Assam",
  "Dadra and Nagar Haveli",
  "Mizoram",
  "West Bengal",
  "Andhra Pradesh",
  "Andaman and Nicobar Islands",
  "Haryana",
  "Telangana",
  "Madhya Pradesh",
  "Tripura",
  "Tamil Nadu",
  "Sikkim",
  "Chhattisgarh",
  "Jammu and Kashmir",
  "Chandigarh",
  "Goa",
  "Odisha",
  "Meghalaya",
  "Ladakh",
  "Uttar Pradesh",
  "Manipur",
  "Arunachal Pradesh",
];
export const district = [
  {
    state: "Andaman and Nicobar Islands",
    districts: [
      {
        district: "South Andaman",
        localities: [
          "6.2 Mile North on SH1",
          "6 miles North of SH1",
          "7 Mile Point on SH1",
          "Aberdeen, Port Blair",
          "Amritography Hotspot, Austinabad, Local Basti",
          "Andaman and Nicobar Environment Team (ANET) Research Station",
          "Andaman Cellular Jail",
          "Andaman College",
          "Andaman-Mahatma Gandhi Marine National Park",
          "Andaman Nicobar College (ANCOL)",
          "Andaman Sea",
          "ANET",
          "ANET mangroves",
          "ANET to Ghumai (Rd Junction)",
          "ANET to North Wandoor beach",
          "ANIIMS  Campus",
          "Attampahad Wetlands (Near AT Villa)",
          "Bada Balu",
          "Badabalu Beach",
          "Badakhari, Rutland",
          "Bamboo Flats",
          "Bathubasti area",
          "Bichdera",
          "Bidnabad",
          "Brookshabad Campus",
          "BSI, Garden, Nayashahar",
          "Burmanallah",
          "Butler Bay Beach",
          "CARI-ICAR Campus",
          "Central Island Agriculture Research Institute",
          "Chatham Jetty",
          "Chidiyatapu--Wild Grass Resort (W)",
          "Chidyatapu Biological Park",
          "Chouldari",
          "Chouldari wetland",
          "Collinpur",
          "Corbyn Cove Beach",
          "DIET Boys Hostel",
          "D&K City",
          "Dr. B.R. Ambedkar Institute of Technology",
          "Dr. BR Ambedkar IT",
          "Elephant beach",
          "Elephant Beach Trail, Havelock Island",
          "Ferrargunj forest",
          "Flat Bay",
          "Forest Patch, Brookshabad",
          "Gandhi park",
          "Gandhi Park, Dilthaman Tank",
          "Garacharma",
          "Garacharma Wetland",
          "Garacharma Wetlands",
          "Garacharm Westland",
          "Govind Nagar Beach",
          "Green Imperial Resort",
          "Guptapara",
          "Haddo Mini Zoo",
          "Harminder Bay",
          "Havelock island",
          "Havelock Island",
          "Havelock island beach resort",
          "Havelock Island--Elephanta Beach",
          "havelock, kala pathhar beach road",
          "Hornbill Nest Resort",
          "Hornbill Nest Resort Road",
          "Hut Bay beach",
          "ICAR KVK Krishi Vigyan Kendra",
          "Indira Bazar",
          "IRBn Campus, Port Mort",
          "Jackson Bay, Little Andaman",
          "Jahaji Forest Area",
          "Jalakara",
          "Jawaharlal Nehru Rajkeeya Mahavidyalaya",
          "Jirkatang",
          "Jorakaan- Sippighat wetlands",
          "kadaka chang wetland, submerge during tsunami",
          "Kalapathar",
          "Kalapather Beach",
          "Kalatang",
          "Khartang RF",
          "Kurmadera",
          "Lighthouse",
          "Little Andaman--General Area (OBI)",
          "Lotus Point (Enroute Farm Tikrey)",
          "Malapuram forest area",
          "Malay home",
          "Manjery (Marine national park)",
          "Marina Park, Port Blair",
          "Marine National Park",
          "Megapode-Inhinloe-Coastal",
          "Megapode Island",
          "Mount Harriet",
          "Mount Harriet NP",
          "Munda Pahadh",
          "Namunaghar",
          "Nature Park and Interpretation Centre, Port Blair",
          "Neil Island",
          "Netaji Subhash Chandra Bose Island",
          "North Wandoor beach",
          "Ograbraij/Chouldari Wetlands",
          "Ograbraj",
          "Ograbraj Wetland",
          "Ograbraj Wetland 3",
          "Ograbraj Wetlands",
          "Organic Horticulture Farm, Sippighat",
          "Phoenix Bay Jetty",
          "Phongibalu, Mahatma Gandhi Marine National Park",
          "Police training School",
          "Pondicherry University, Port Blair Campus",
          "Port Blair",
          "Port Blair Harbour",
          "Radhanagar beach, Havelock island",
          "Red Skin Island",
          "R.K Pur Dam",
          "Rose Island",
          "Ross Island",
          "Salt Marsh, Sippighat",
          "Science Center",
          "SeaShell Samssara Neil  Resort Backyard",
          "Shoal bay",
          "Shoal Bay area forest/mangroves",
          "Shoal Bay waste management Spot",
          "Sippighat",
          "Sippighat-2",
          "sitapur beach",
          "South Andaman (OBI)",
          "Stewartgunj Wetland",
          "Veer Savarkar International Airport",
          "Wandoor",
          "Wandoor Beach",
          "White surf waterfall",
          "White Surf Waterfall",
          "Wild Grass Resort area",
          "Wimberlygunj",
          "Wimberlygunj Wetlands",
        ],
      },
      {
        district: "North and Middle Andaman",
        localities: [
          "Andaman-Baratang I.",
          "Austen Strait (near Chengappa bridge)",
          "Austin, Andaman Trunk Road, Mayabunder",
          "Baratang",
          "Base of Saddle Peak, Lamia Bay",
          "Buchanan Island",
          "Chails Ek, North Andaman Island",
          "Dhanih Nallah Mangrove Walkway",
          "Dhaninallah Mangrove Walk Way",
          "Dhaninallah, Rangat, Middle Andaman",
          "Kadamtala",
          "Kalipur Beach",
          "Kalipur Beach, Diglipur",
          "Kalpong River, Khudirampur, Diglipur",
          "Khudirampur",
          "Khudiram Pur, Forest",
          "Khudirampur Forest area, Diglipur",
          "Lamiya Bay",
          "Long Island",
          "Mangrove Walkway Dhanih Nallah",
          "Mangrove Walkway, Dhani Nallah",
          "Middle Andaman (OBI)",
          "Mud volcano",
          "Mud Volcano, Baratang",
          "Narcondam Island",
          "Parnashala Forest Nursery",
          "Pristine resort kalipur, Diglipur",
          "Radha Nagar mangrove creek",
          "Ramnagar Beach",
          "Rangat Bay, Middle Andamans",
          "Range Office Parnasala",
          "Sabari, Middle Andaman",
          "Saddle peak",
          "Saddle Peak National park",
          "shyamkund jetty",
          "Shyamnagar Creek",
          "Smith and Ross Islands",
          "Smith Island",
          "way to Yerrata to Long Island",
          "Webi village",
          "Yeretta, Middle Andaman",
        ],
      },
      {
        district: "Nicobar",
        localities: [
          "35 KM Beach",
          "(6.9989,93.8815) Great Nicobar Biosphere  Reserve",
          "Bananga",
          "Bay of Bengal: 8.0N 91.0E",
          "Breakwater Jetty Area, Campbell Bay",
          "Camorta",
          "Car Nicobar",
          "Chingan-Campbell Bay",
          "Galathea",
          "Govinda Nagar Tsunami Shelter",
          "Govind Nagar Road",
          "Great Nicobar Biosphere Reserve",
          "Jogindarnagar Beach",
          "Kamorta  Island",
          "Katchal, Island",
          "Komath (Little Nicobar)",
          "Kosingdon, Great Nicobar Island",
          "Laxmi Nagar",
          "Magarnala",
          "Magarnala-Chengappa bay",
          "Maggar Nala",
          "Menchal Island",
          "Military Engineer Services (MES)",
          "Murda Khadi",
          "Nancowrie Jetty",
          "Nancowry",
          "near Great Nic-Bakery",
          "Pilo Milo Island (OBI)",
          "PWD Guest House, Great Nicobar",
          "Shastri Nagar",
          "Shastri Nagar Nala",
          "Shastri Nagar Wetland",
          "Teressa Island--General Area",
          "Tillangchong",
          "Trinkat Island",
        ],
      },
    ],
  },
  {
    state: "Andhra Pradesh",
    districts: [
      {
        district: "YSR District (Kadapa)",
        localities: [
          "Badvel Big Tank (Badvel Pedda Cheruvu)",
          "Bethayapalli Cheruvu.",
          "Gandikota",
          "Gunthapalle fields, Andhra Pradesh, IN (14.74, 79.035)",
          "Jyoti Kshetram",
          "Lingalakunta",
          "Nagisettipalli Cheruvu(Ã Â°Â¨Ã Â°Â¾Ã Â°â€”Ã Â°Â¿Ã Â°Â¸Ã Â±â€ Ã Â°Å¸Ã Â±ÂÃ Â°Å¸Ã Â°Â¿Ã Â°ÂªÃ Â°Â²Ã Â±ÂÃ Â°Â²Ã Â°Â¿ Ã Â°Å¡Ã Â±â€ Ã Â°Â°Ã Â±ÂÃ Â°ÂµÃ Â±Â)",
          "PALAKONDALU WATERFALLS",
          "Pennar River Bridge, Siddavattam",
          "Pullur Cheruvu (Lake)",
          "Reddipalli Pond",
          "Repalli Cheruvu",
          "Sangasamudram Cheruvu",
          "Sri Lankamalleswara Wildlife Sanctuary",
          "Sri Pothuluri Veerabrahmendra Swamy (Brahmamsagar) Reservoir",
          "Uppavari Palli Cheruvu",
          "Veligallu Reservior, Galiveedu",
          "Venkatasettipalli Cheruvu",
          "Vontimitta Cheruvu (lake)",
          "Yogi Vemana University",
        ],
      },
      {
        district: "Prakasam",
        localities: [
          "Bhairavakona",
          "Bhavanasi Lake, Singarakonda",
          "Cumbum Tank",
          "Isukagundam FD Resthouse (restricted)",
          "Mallavaram dam",
          "Nallamala Forest, NSTR",
          "Obulesunipenta FD Resthouse (restricted)",
          "Obulesuni penta to Isukagundam, Eastern Nallamalai road (restricted)",
          "Ramathertham Sagar",
          "Santhanuthalapadu Lake",
          "Thummalabailu, NSTR",
        ],
      },
      {
        district: "Guntur",
        localities: [
          "Karakatta Road (Near irrigation Warehouse)",
          "Krishna River bridge, Mattapalli",
          "Mangalagiri Reserve Forest",
          "Muttayapalem Reserve forest (Bapatla-Suryalanka road)",
          "Nagara banda",
          "Tadikonda Cheruvu (lake)",
          "Templehill Eco-park, AIIMS, Mangalagiri",
          "Uppalapadu Bird Sanctuary",
          "Uppu Revu Mudflats & Sandspit (Krishna Sanctuary)",
          "Veluru lake",
        ],
      },
      {
        district: "Chittoor",
        localities: [
          "Agastya International Foundation Campus, Kuppam",
          "Akkarampalle Lake",
          "Annaswamipalle Lake",
          "Annaswamipalli Lake",
          "Attrapalli nallapalli valley",
          "Bhupal housing colony",
          "Chennayagunta, Tirupati",
          "Cherlapalle",
          "Cherlo Palli",
          "Chittoor Mini-Forest",
          "CMC Vellore, Chittoor campus",
          "DES's Fergusson Center(Tirupati)",
          "Diguvapalayam",
          "Doracheruvu",
          "Elamandyam Lake",
          "Forest trail opposite Arvind Eye Hospital (SV National Park)",
          "Gogarbham Reservoir",
          "Gonupothula Kunta Tank",
          "Guthivari Palli Lake",
          "Horsley Hills",
          "IISER Tirupati--Mangalam Campus",
          "IISER Tirupati--Yerpadu Campus",
          "IIT Sricity",
          "IIT Tirupati",
          "Jangalapalli Lake (yerpedu)",
          "Kaigal Waterfalls",
          "Kailasakona Waterfalls",
          "Kalyani Dam, Andhra Pradesh, IN (13.657, 79.269)",
          "Karakambadi Lake",
          "Kendriya Vidyalaya 2 School, Tirupati",
          "Kodandarama Temple, Vontimitta",
          "Komati Vaani Cheruvu (lake)",
          "Kondalamadugu to Horsley Hill (Ghat road)",
          "Kotala Cheruvu, Kadiri",
          "Koundinya WLS--Hanuman Temple",
          "Krea University, Sricity",
          "Krishnampalli Lake (yerpedu)",
          "Kuppam Lake",
          "Kurrakalva Lake",
          "Lake opposite IIT Tirupati",
          "Lakshmi nagar Lake",
          "Mallappa Konda Betta",
          "Mallimadugu Reservoir, Golla Palli, Andhra Pradesh",
          "Mamadugu Forest",
          "Merlapaka Lake",
          "Mittoor village lake and paddy fields",
          "Mudimadugu Hills",
          "Nayanicheruvu tank",
          "Pagali Village",
          "Palamaner",
          "Papavinasanam",
          "PB Natham Lake",
          "Peddamandyam Wet lands",
          "Peepal Grove School",
          "Peethula Cheruvu, Ramakuppam",
          "Perur lake",
          "Rajulapalem Lake (yerpedu)",
          "Regional Science Center, Tirupati",
          "Rishi Valley (General Area)",
          "Rishi Valley--Krishnamurti Sagar",
          "Rishi Valley--School Campus",
          "Rishi Valley--Valley Mouth",
          "Saamicheruvu, Madanapalle, Chittoor Dt",
          "Science city Lake (yerpedu)",
          "Settipalli lake",
          "Springdale lake, Karakambadi road, Tirupati",
          "Sreenivasapuram Lake (yerpedu)",
          "Sri Padmavati Women's University, Tirupati",
          "Sri Venkateswara NP--Dargah Shareef",
          "Sri Venkateswara NP (general area)",
          "Sri Venkateswara NP--Mamanduru",
          "Sri Venkateswara NP--Nagara Vanam",
          "SV Agricultural College & Research Station, Tirupati",
          "S.V. National Park -- Canteen(Talakona)",
          "SV University, Tirupati",
          "SV Vedic University",
          "SV Veterinary University, Tirupati",
          "SV Zoo Park, Tirupati, Andhra Pradesh",
          "swarnamukhi river Thandlam chittoor county Andhra Pradesh",
          "Talakona-- Nellakona pool",
          "Talakona Road-- Yerravaripalem",
          "Talakona Waterfalls",
          "Tank A, Sri City",
          "Thambalapalle",
          "Thettupalli, Bhakarapeta road, Andhra Pradesh",
          "Thiminaidupalem, Tirupati.",
          "Thondamanadu Lake",
          "Thukivakam Lake",
          "Tirumala",
          "Tirumala Ghat Road",
          "Trail behind LIC Training Center, Mangalam",
          "Tummalagunta lake",
          "Upparapalem Lake",
          "Vendugampalle Lake",
          "Venkatapuram Lake",
          "Venugopalasamy Swamy Temple",
          "Yerpedu large lake",
          "Yerpedu small lake",
        ],
      },
      {
        district: "Kurnool",
        localities: [
          "Ahobilam",
          "Alaganuru Reservoir",
          "Diguvametta - Nandyala ghat road",
          "Erragudur Regulator",
          "Gargeyapuram Tank",
          "Gundla Brahmeswaram FD Resthouse and Temple (restricted)",
          "Isukagundam Rest house to GBM Resthouse, Eastern Nallamalai road (restricted)",
          "Malyala - Srisailam backwaters",
          "Nallamalla Jungle Camp Bairluty",
          "Nallamalla Jungle Camp, Pacherla",
          "Nandyal Cheruvu",
          "NSTR--Sundipenta",
          "Panchalingala Tungabhadra dam on NH7",
          "Phala dhara water falls",
          "Pothireddypadu Head Regulator",
          "Rollapadu Wildlife Sanctuary",
          "Siddapuram Reservoir",
          "Srisailam Dam View Point",
          "Srisailam dam (view point & downstream)",
          "Srisailam Roadbend",
          "Velugodu Balancing Reservoir",
          "Vengallam Palle Lake",
        ],
      },
      {
        district: "Srikakulam",
        localities: [
          "Arasavilli - Voppangi lake, Srikakulam.",
          "Bejjipuram Cheruvu",
          "Budumuru lake",
          "Chinna Karrivani Palem Beach",
          "Donubhai forest",
          "Gelagala Cheruvu",
          "Kalingapatnam(Kokkiliputtuga) Estuary",
          "Karridi Cheruvu (Karrivani Cheruvu)",
          "Kotha Cheruvu (lake)",
          "Kothapalem Lake",
          "Madduvalasa Reservoir",
          "Nagavali River Mouth(PD Palem Beach)",
          "Narsamma cheruvu",
          "Naupada Salt Pans",
          "Naupada Swamps",
          "Nelavanka Estuary",
          "Patrunivalasa Lake",
          "Pedda Cheruvu, Palasa",
          "Pedda Cheruvu, Tekkali",
          "Raghunadhapuram Lake",
          "Smart School, Palasa",
          "S Purla Cheruvu(Pathameghavaram)",
          "Swetha Pushkarini",
          "Telineelapuram",
          "Telukunchi Bird Nesting site",
          "Vemulavada lake (Dali Cheruvu)",
        ],
      },
      {
        district: "Anantapuramu",
        localities: [
          "Anantgram Farms Phase 1",
          "Anantgram Farms Phase 2",
          "Anantgram Farms Phase 3",
          "Bevenahalli Lake, Hindupur",
          "Charlapalli Dam",
          "Chennakesava Swamy Temple, Siddharampuram",
          "Cheruvu Katta, Ananthapur",
          "C. Kodige Palli Kere",
          "Devarapalli",
          "Dharmavaram Junction Railway Station",
          "Dharmavaram Lake",
          "Gangampalli Forest",
          "Gopindevarapalli Lake",
          "guntakal",
          "Hindupur Railway Station",
          "Jeedipalli Dam",
          "Kambalaparthi Kunta",
          "Kanekal Lake",
          "Kothacheruvu (Bukkapatnam Lake)",
          "Lepakshi Temple",
          "Mid Pennar Reservoir",
          "Mudigubba Lake",
          "Mustikovila Dam Area",
          "Mutyala Cheruvu",
          "Nagasamudram Lake",
          "PABR Dam, Korrakodu",
          "Palasamudram Lake",
          "Panthla Cheruvu",
          "Parnapalli Dam (Chitravathi Balancing Reservoir)",
          "Patakottacheruvu",
          "Penukonda Fort Road",
          "Prashanthi Nilayam Ashram",
          "Singanamala Cheruvu",
          "Sreedharaghattu Lake",
          "Sunnampalli Lake",
          "Tekulodu Lake",
          "Thimmamma Marrimanu",
          "Timbaktu Farm",
          "Timbaktu--Kalpavalli Restoration Area",
          "Timbaktu--Office, Chennekothapalli",
          "Vankamaddi Cheruvu, Kadiri",
          "Vengalammacheruvu",
          "Yenumulapalli Lake",
        ],
      },
      {
        district: "Visakhapatnam",
        localities: [
          "Adavivaram Cheruvu",
          "Aguru, Andhra Pradesh, IN (18.26, 83.077)",
          "Airport adjacent water body",
          "Anantapuram lake",
          "Ananthagiri Coffee Plantation",
          "Andhra University North Campus",
          "Andhra University, South Campus",
          "Banta Pond",
          "Biodiversity Park -Botanical Garden",
          "Bojjana Konda",
          "Boni Lake",
          "Central Park Visakhapatnam",
          "Dolphin's Nose",
          "Gambheeramgadda Reservoir",
          "Gayatri Women's Engineering College",
          "Gokada Lake",
          "Gollapallu Pond",
          "Gosthani River Mouth, Bheemili",
          "Gosthani River Mouth North side, Bheemili",
          "Gottipalli pond",
          "Haritha Hill Resort Ananthagiri",
          "Indira Gandhi Park, Steel plant",
          "Indira Gandhi Zoological Park",
          "jodugullapalem beach",
          "Jungle Bells Trail",
          "Kailasagiri Hills",
          "Kalyanapulova Reservoir , Andhra Pradesh, IN",
          "Kambalakonda Eco Park",
          "Kambalakonda Wildlife Sanctuary",
          "Kapuluppada lake",
          "Khambala cheruvu (lake)",
          "Konam Reservoir",
          "Kondakarla Ava",
          "Kuntalam Coffee Plantation",
          "Lambasingi",
          "Lotheru",
          "Mangamaripeta",
          "Marripakalu",
          "Mattam Bhimavaram",
          "Meghadrigedda downstream channel (Behind Airport)",
          "Meghadrigedda Reservoir",
          "Modakondamma Temple Trail (Coffee Plantation)",
          "Modakondamma temple trial coffee plantation",
          "Mudasarlova Reservoir",
          "NSTL",
          "Paderu Ghat road",
          "Padmapuram Garden Arakku",
          "Pavurallakonda, Bheemili",
          "Pedda Cheruvu, Narsipatnam",
          "Podugupalem Lake",
          "Potnuru Pond",
          "Raiwada Reservoir",
          "Red Sandhills",
          "Revenue Colony Park",
          "Saripalli Pond",
          "Sariya waterfalls - Devarapalli",
          "Seethakonda Reserve Forest",
          "Seethapalem Beach, NTPC",
          "Sheela  Nagar Pond",
          "Sileru",
          "Simhachalam",
          "SR Gardens backside pond",
          "St. AnnÃ¢â‚¬â„¢s College for Women",
          "St Ann's College for Women",
          "Thagarapuvalasa Pond",
          "Thandava reservoir",
          "Thanthadi beach",
          "The Park Visakhapatnam",
          "Thimmapuram beach front",
          "Thotlakonda",
          "Thunglam Pond",
          "Tyda near Araku, Vishakapatnam, Andhra Pradesh",
          "Visakhapatnam Fishing Harbor",
          "Visakhapatnam International Airport (restricted)",
          "VPT Mangrove Forest",
          "Vuda Park",
          "Waltair Park (restricted)",
          "Water World Beach Visakhapatnam",
          "Yarada Beach, Visakhapatnam",
          "Yerra Cheruvu",
          "Yerravaram Waterfalls , Narsipatnam Sileru Rd",
        ],
      },
      {
        district: "West Godavari",
        localities: [
          "atapaka",
          "Grass lands, Peddameram, Bhimavaram",
          "Kolleru Bird Sanctuary",
          "Koruturu, Papikondalu.",
          "Kovvali - Mondikodu Road, Eluru",
          "Kulayi Cheruvu, Palakollu",
          "Marritippa",
          "Marshy Lands-Bhimavaram (Undi Road)",
          "Munjuluru Waterfalls",
          "Pedamaina Vanilanka Beach",
          "Pedda & Chinna Cheruvu,Elamanchili",
          "Perupalem wetlands",
          "Sir C R Reddy College for Women, Eluru",
          "Vadalakunta Main Pond & Washerman Pond",
        ],
      },
      {
        district: "Sri Potti Sriramulu Nellore",
        localities: [
          "Allur Lake",
          "Jeellapatur",
          "Kanigiri Reservoir",
          "Krishnapatnam Beach (Arkatpalam)",
          "Meezuru, Pulicat, Nellore IN-AP 13.75283, 80.07429",
          "Narasimha Konda",
          "Nelapattu Bird Sanctuary",
          "Padmavati Vidyalaya school, Sullurpeta",
          "Papi Reddy Cheruvu",
          "Penna Estuary",
          "Pulicat Bird Sanctuary--SHAR",
          "Pulicat Lake (AP section)",
          "Ramanna Tank",
          "Sarvepalli Cheruvu",
          "Somasila Dam",
          "Sriharikota Forest",
          "Vangallu Cheruvu",
          "Zammavaram Tank",
        ],
      },
      {
        district: "Krishna",
        localities: [
          "Ambapuram canal trail",
          "Bhavani Island,Vijayawada (16.516, 80.592)",
          "Brahmalingam Cheruvu",
          "Budameru bridge Uppuluru(16.5055,80.7760)",
          "Edupugallu Pond",
          "Elachetladibba",
          "Fields in PNT colony",
          "Gilakaladindi",
          "Gollanapalle hill",
          "Kanuru Pond, Vijayawada",
          "Kasaraneni Vari Palem marshes (Krishna river)",
          "Kavuluru Cheruvu (Lake)",
          "Kethanakonda",
          "Kolleru Lake",
          "Kondapalli Khilla (fort) Ghat Road, Andhra Pradesh, IN (16.604, 80.508)",
          "Kondapalli Reserve Forest--General Area",
          "Kondapalli Reserve Forest--Trilochanapuram Ash Pond",
          "Kondapavuluru road (next to hill)",
          "Kondapavuluru rural lake",
          "Kotturu Lake",
          "Kotturu Tadepalli lake",
          "Kotturu Tadepalli Lake 2",
          "Krishna Karakatta Road",
          "Krishna Wildlife Sanctuary (Hamsaladeevi road)",
          "Lankapalle",
          "Living Vikasa Vidyavanam Campus (Restricted)",
          "Madduru-Krishna River Bank",
          "Mulapadu Forest",
          "Mustabada rural Lake",
          "Nagaravanam(Urban forest), Ambapuram",
          "Nunna Lake",
          "Nunna Urban Lake",
          "Patamatalanka",
          "Prakasam Barrage,Vijayawada",
          "Pydurupadu Lake 1",
          "Pydurupadu Lake 2",
          "Surampalli lake",
          "Vedurupavuluru Fields",
          "Vedurupavuluru Small Lake",
          "Velagaleru Village Lake",
          "Vemavaram lake trail (behind hill)",
        ],
      },
      {
        district: "Vizianagaram",
        localities: [
          "Aditya Nagar Pond",
          "Amakam Toofan shelter building",
          "Ayya Koneru",
          "Ayyanapeta Pond",
          "Balarampuram Pond",
          "Bangaramma Temple pond",
          "Bhimasangi stream",
          "Bhogapuram Pond",
          "Bondapalli lake",
          "Dasannapeta Pond",
          "Gokapeta Cheruvu",
          "Kanapaka Lake",
          "Kandivalasa Cheruvu",
          "Komati Cheruvu",
          "Koti cheruvu",
          "Natavalasa Pond",
          "Pedada Lake",
          "Polipalle Cheruvu",
          "Pradeep Nagar Tank",
          "Pusapatirega lake (vempadam)",
          "Thatipudi Reservoir",
          "Vizianagaram Pedda Cheruvu",
          "Vizi Wildlife sanctuary",
          "Vuda Colony pond",
          "Zilla parishad High School--Konada",
        ],
      },
      {
        district: "East Godavari",
        localities: [
          "Addateegala - Y Ramavaram road",
          "Antarvedi--Light House Beach",
          "AP State Forest Academy, Rajahmundry",
          "Bhupathipalem Reservoir",
          "Chinnayapalem tank",
          "Chollangi Salt Pans",
          "Coringa Wildlife Sanctuary--Walkway-Boat House",
          "Coringa Wildlife Santuary",
          "Coromandel International Ltd",
          "Creek near Coromandel",
          "Dairy Farm Road Wetlands",
          "Dindi : General area",
          "Godavari MahaPushkara Vanam(Nagaravanam Rajahmundry)",
          "Gokavaram Tank",
          "Hope Island, Kakinada",
          "Kakinada Beach Road (towards Uppada)",
          "Kakinada Fishing Jetty (Kumbhabishekam)",
          "Katrenikona Lake, East Godavari County, Andhra Pradesh, IN",
          "Magasanitippa",
          "Mallavaram Reserve forest",
          "Maredumilli",
          "Maredumilli--Coffee Plantation",
          "Maredumilli--Jalatarangini Water Fall",
          "Maredumilli--Jungle Star Eco Camp",
          "Maredumilli--Pullangi Road",
          "Maredumilli--Rubber Plantation",
          "Maredumilli -- Sokuleru Vagu view point",
          "Pampa Reservoir",
          "Panchaneti Creeks",
          "Pochavaram boating point",
          "Potlavada, Maredumilli, Andhra Pradesh",
          "Pulasa Lanka",
          "Pullangi-Chaparai Route",
          "Pullangi-Gurtedu Road",
          "Punyakshetram",
          "Samanthakuru Mangroves",
          "Sokuleru Vagu View Point and Surroundings",
          "Soodikonda Range Office",
          "Surasaniyanam Marshlands",
          "Tiger Camp, Maredumilli",
          "Uligogula Reserve Forest, Peddipalem",
          "Vanavihari Eco Tourism Centre, Maredumilli",
          "velugubanda",
          "Vijjeswaram",
          "Wetland opposite Coromandel fertilizers",
          "Y.Ramavaram - Jyothimamidi forest road",
          "Y Ramavaram - Sesharayi road",
        ],
      },
    ],
  },
  {
    state: "Arunachal Pradesh",
    districts: [
      { district: "Upper Siang", localities: [] },
      { district: "West Siang", localities: [] },
      { district: "Lepa Rada", localities: [] },
      {
        district: "West Kameng",
        localities: [
          "1300 Chain PWD Camp",
          "Baisakhi",
          "Balemu-Dirang Road (OBI)",
          "Banga Jang Gompa",
          "Bhalukpong",
          "Bomdila View Point",
          "Dirang to Mandala road",
          "Dirang Tourist Lodge Area",
          "Dirang, West Kameng (OBI)",
          "Doimara",
          "Doimara Bridge",
          "Eaglenest WLS & Surroundings--Above Bompu Camp",
          "Eaglenest WLS & Surroundings--Bompu Camp",
          "Eaglenest WLS & Surroundings--Chaku",
          "Eaglenest WLS & Surroundings--Eaglenest Pass",
          "Eaglenest WLS & Surroundings--General Area",
          "Eaglenest WLS & Surroundings--Khellong",
          "Eaglenest WLS & Surroundings--Lama Camp",
          "Eaglenest WLS & Surroundings--Sessni",
          "Eaglenest WLS & Surroundings--Sunderview",
          "Eaglenest WLS--Tragopan Track",
          "Kamengbari Doimara Road,Doimara",
          "Mandala",
          "Mandala Dirang road",
          "Mandala Lodge and Surroundings",
          "Mandala-Phudung Road",
          "Mandala Rd",
          "Mandala Road",
          "Mandala Top",
          "Mandala Village",
          "Nichifu-Nagmandir Road",
          "Road to Eaglenest from Nameri",
          "Sangti",
          "Sangti Valley",
          "Sela Pass",
          "Sessa",
          "Sessa Orchid WLS",
          "Shergaon",
          "Shergaon, West Kameng",
          "Singchung Bugun Community Reserve",
          "Tenzing Gaon, AP (OBI)",
          "Thembang (OBI)",
          "Tippi--Elephant Flat",
        ],
      },
      { district: "Siang", localities: [] },
      {
        district: "Tawang",
        localities: [
          "Jang",
          "Jang Guest House",
          "Jaswant Gad",
          "Tawang--Ani Gompa Road",
          "Tawang--General Area",
          "Tawang Numla",
          "Tawang-Ptso Lake Road",
          "Tsungeshwar Lake",
          "Zemithang",
        ],
      },
      {
        district: "Changlang",
        localities: [
          "Changlang (OBI)",
          "Manmao",
          "Margherita-Changlang Road (OBI)",
          "Miao--General Area",
          "Namdapha Jungle Camp",
          "Namdapha NP--38 mile (Approx)",
          "Namdapha NP--40th mile (Appox)",
          "Namdapha NP--65th Mile",
          "Namdapha NP--67th Mile (Approx)",
          "Namdapha NP--77th Mile",
          "Namdapha NP--Firmbase",
          "Namdapha NP--Gachhile-1",
          "Namdapha NP--Gandhigram",
          "Namdapha NP--Hazulu",
          "Namdapha NP--Lashichilo",
          "Namdapha NP--Mugaphi-2",
          "Namdapha NP--Musala-1",
          "Namdapha NP--Musala-2",
          "Namdapha NP--Musala-3",
          "Namdapha NP--Shidiku",
          "Namdapha NP & Surroundings--13 Mile",
          "Namdapha NP & Surroundings--25 Mile",
          "Namdapha NP & Surroundings--Deban",
          "Namdapha NP & Surroundings--General Area",
          "Namdapha NP & Surroundings--Haldibari Camp",
          "Namdapha NP & Surroundings--Hornbill camp",
          "Namdapha NP & Surroundings--Miao-Vijaynagar Road",
          "Namdapha NP & Surroundings--M'Pen Bridge",
          "Namdapha NP & Surroundings--Raja Jheel",
          "Namdapha NP--Vijayanagar",
          "Pangsau Pass",
        ],
      },
      { district: "Upper Subansiri", localities: [] },
      { district: "Tirap", localities: [] },
      {
        district: "Pakke Kessang",
        localities: [
          "Bhalukpong Ghat",
          "Blyth's KF Point",
          "Jali Nala, Pakke,",
          "Khari,",
          "Pakke Jungle Camp",
          "Pakke Tiger Reserve",
          "RF outside Pakke,",
          "Sukhan Nala, Pakke",
          "West Bank, Pakke",
        ],
      },
      { district: "Namsai", localities: ["Kherem"] },
      {
        district: "Lower Dibang Valley",
        localities: [
          "Bamjir Bridge",
          "Bamjir Grassland",
          "Dambuk Grassland",
          "IHO view point",
          "Jia Grassland",
          "Mehao WLS & Surroundings--General Area",
          "Mishmi Hills--65 (65 km)",
          "Mishmi Hills--65 km to 12 Gulai",
          "Mishmi Hills--6 km",
          "Mishmi Hills--Baramile (12th Mile)",
          "Mishmi Hills--Benders (12 Gulai)",
          "Mishmi Hills--Chikopani Bridge",
          "Mishmi Hills--Coffee House (52 km)",
          "Mishmi Hills - Dibang Valley Jungle Camp",
          "Mishmi Hills--Mayodia Pass (56 km)",
          "Mishmi Hills--Mishmi Hill Camp",
          "Mishmi Hills--Monal Point",
          "Mishmi Hills--Roing-Hunli Road (Forest Gate)",
          "Mishmi Hills--Tiwari Gaon (28 km)",
          "Open Fields--Jia Grassland Road",
          "Roing",
          "Sally Lake",
        ],
      },
      { district: "East Kameng", localities: [] },
      { district: "Shi Yomi", localities: ["Menchuka"] },
      { district: "Kurung Kumey", localities: [] },
      {
        district: "Upper Dibang Valley",
        localities: [
          "acheso",
          "Anini",
          "Anini-Aliney Road",
          "Anini Road",
          "Mipi",
        ],
      },
      {
        district: "Lower Subansiri",
        localities: [
          "Siikhe Lake",
          "Tale WLS--Pange Camp",
          "Tale WLS--Tale valley",
          "View Point, Ziro",
          "Ziro Valley",
        ],
      },
      {
        district: "East Siang",
        localities: [
          "Dr Daying Ering Wildlife Sanctuary (OBI)",
          "Southeast Arunachal Pradesh (OBI)",
        ],
      },
      {
        district: "Longding",
        localities: [
          "Alubari Bridge Road",
          "Alubari Ghat",
          "Kamlang WLS",
          "Lohit Valley (OBI)",
          "Parsuram Kund near Lohit Bridge",
          "Tezu (OBI)",
          "Udayak Pass",
          "Wakro",
          "Wakro near Kamlang Wildlife Division",
        ],
      },
      {
        district: "Anjaw",
        localities: [
          "Chequenty bridge",
          "Dong Valley (OBI)",
          "Hawai",
          "Hayuliang (OBI)",
          "Hayuliang to Chaglagam Rd",
          "Hayuliang to Udayak Pass",
          "Helmet Top,Walong",
          "Kaho,Anjaw county,Arunachal Pradesh",
          "Khupa",
          "Kibitho (OBI)",
          "Meshai",
          "Namti Village (OBI)",
          "Tilam Top",
          "Walong",
          "Walong,Anjaw",
          "Walong--Helmet Top",
          "Walong--Tilam Top",
          "Walong to Helmet",
        ],
      },
      {
        district: "Lohit",
        localities: [
          "Alubari Bridge Road",
          "Alubari Ghat",
          "Kamlang WLS",
          "Lohit Valley (OBI)",
          "Parsuram Kund near Lohit Bridge",
          "Tezu (OBI)",
          "Udayak Pass",
          "Wakro",
          "Wakro near Kamlang Wildlife Division",
        ],
      },
      { district: "Lower Siang", localities: [] },
      {
        district: "Papum Pare",
        localities: [
          "North Eastern Regional Institute of Science and Technology",
          "North Eastern Regional Institute of Science & Technology (NERIST)",
          "Old Sagalee Road initial segment",
          "Rajiv Gandhi University",
        ],
      },
    ],
  },
  {
    state: "Assam",
    districts: [
      {
        district: "Kokrajhar",
        localities: [
          "Abhayakuti",
          "Bodoland University Campus",
          "Chakrashila Forest IB",
          "Chakrashila Wildlife Sanctuary",
          "Diplai Beel (lake)",
          "Gaurang Park",
          "Jomduar Forest IB",
          "Jomduar--Sankosh River",
          "Kokrajhar Town",
          "Manas Tiger Reserve--Jharbari",
          "Manas Tiger Reserve--Ultapani",
          "Pepsu Riverbank",
          "Raimona NP--Central range",
          "Rideline 5",
          "Rideline 7--Hel River Road",
          "Rideline 8--Bongaon Bamuni Road",
          "Rideline 9 & 10--Firing Road",
          "Rideline 9--Mach Bhandar",
          "Sarpang Riverbank--Saralpara",
          "Sona Bhandar",
          "Ultapani Forest IB",
        ],
      },
      {
        district: "Lakhimpur",
        localities: [
          "Bogoli Reserve Forest and Wild Sanctuary",
          "Kakoi Reserve Forest",
          "Satajaan Bird Sanctuary",
          "Satajaan Wetland (Bird Sanctuary)",
        ],
      },
      { district: "South Salmara-Mankachar", localities: [] },
      {
        district: "Biswanath",
        localities: [
          "Bashbari Beel (wetland)",
          "Behali Reserve Forest",
          "Biswanath College-- Assam",
          "Dipara Pukhuri",
          "Kaziranga NP--Central Range (Northbank)",
          "Kaziranga NP--Eastern Range (Northbank)",
          "Nature Discovery Centre",
          "Nemutenga Beel (wetland)",
        ],
      },
      {
        district: "Barpeta",
        localities: [
          "Barpeta (OBI)",
          "Barpeta Town (OBI)",
          "ITI Titka--Lechera Para",
          "Kapla Beel",
          "Madhab Choudhury College",
          "Pohumara Sapori",
          "pohumara sapori birding hotspot",
          "Puthimari Beel",
          "Sorbhog Town",
        ],
      },
      { district: "Dhemaji", localities: [] },
      {
        district: "Dima Hasao",
        localities: [
          "Hampupet trek route,Jatinga",
          "Hengbung",
          "Jatinga, Dima Hasao (OBI)",
          "Panimur Waterfalls",
          "Umrangsu, Dima Hasao (OBI)",
        ],
      },
      {
        district: "Golaghat",
        localities: [
          "Ageratoli Village",
          "Agoratoli Eco Tourism Resort",
          "Bokakhat",
          "Bon Habi Resort",
          "Dering Playground",
          "Golaghat Town",
          "Hakhizhe Amur Roost Spot",
          "Haldibari",
          "Hatikhuli Tea Estate",
          "Hati Mandir (Hati Pathar)",
          "Iora The Retreat, Kaziranga",
          "Jugi beel",
          "Jupuri Ghar Resort",
          "Kaziranga National Orchid and Biodiversity Park",
          "Kaziranga NP--Highway",
          "Kaziranga NP--NH 37 Bokakhat to Bagori",
          "Kaziranga NP & Surroundings--Bhuri Mai",
          "Kaziranga NP & Surroundings--Central Range (Kohora)",
          "Kaziranga NP & Surroundings--Debeswari",
          "Kaziranga NP & Surroundings--Diring Tea Garden",
          "Kaziranga NP & Surroundings--Eastern Range (Agaratoli)",
          "Kaziranga NP & Surroundings--General Area",
          "Kaziranga NP & Surroundings--Hukuma Beel",
          "Kaziranga NP & Surroundings--Panbari Reserve Forest",
          "Kohora--Aranya Lodge",
          "Kohora Chariali",
          "Lower Doigrung (Bijuli) R.F, Golaghat",
          "Nambor Doigurung WLS",
          "Negheriting Tea Estate",
          "Resort Borgos",
          "Sarupathar Town",
          "The Corbett Foundation, Kaziranga",
          "Wild Grass Resort",
        ],
      },
      {
        district: "Bongaigaon",
        localities: [
          "Bhosamari Lake",
          "Birjhora Mahavidyalaya",
          "Bongaigaon Town",
          "Dolani Beel",
          "Kakaijona Reserve Forest",
          "Kakoijana village and hills",
          "Kanara Beel",
          "Nayachora beel",
          "Tulungia",
        ],
      },
      {
        district: "Kamrup",
        localities: [
          "Brahmaputra River Bank--Kamrup",
          "Chandubi Eco-Camp",
          "Chandubi Lake, Kamrup, Assam",
          "Dadara",
          "Digholi Beel (lake)",
          "Dora Beel (Wetland)",
          "Duramari Beel (Lake)",
          "Duramari Grassland",
          "Galli Beel (lake)",
          "Jengdia beel (lake)",
          "Jiakur Village Riverside",
          "Kamalpur-Puthimari Wetland",
          "Kulshi",
          "Mandakata Village",
          "Mirza Town",
          "Puthimari river bank",
          "Rajapara",
          "Rangmahal",
          "Rani Guwahati",
          "Rani, Guwahati (OBI)",
          "Rani-kopili",
          "Rani reserved forest",
          "Rani RF--Vulture Breeding Centre",
          "Sualkuchi Village",
        ],
      },
      {
        district: "Chirang",
        localities: [
          "Basugaon",
          "Champamati Irrigation Project",
          "Jawahar Navodaya Vidyalaya Kokrajhar",
          "Kuklung Forest Camp",
          "Manas NP--Panbari (Eastern Range)",
          "Manas Tiger Reserve--Dhola",
          "Manas Tiger Reserve--Kuklung",
          "Phoolkhungri",
        ],
      },
      {
        district: "Karbi Anglong",
        localities: [
          "Bhelughat Waterfalls",
          "Biodiversity cum Recreation Park",
          "Chapanala Water Falls",
          "Don Bosco Junior and Degree College",
          "Kaipholangso Waterfall",
          "Kangthilangso Waterfall",
          "Kangthilaso Waterfall",
          "Kaziranga",
          "Langkar-Om Eco Park",
          "Mikir Hills",
          "Terang's Home Stay",
        ],
      },
      {
        district: "Kamrup Metropolitan",
        localities: [
          "Amchang WLS",
          "Arya Vidyapeeth College",
          "Assam Don Bosco University",
          "Assam Downtown University Campus",
          "Assam Engineering College",
          "Assam Royal Global University",
          "Assam State Zoo Cum Botanical Garden",
          "Bamunimaidan Buddha Bihar",
          "Basistha Temple Backyard",
          "Basistha Temple, Guwahati (OBI)",
          "B. Borooah College",
          "Bhimashankar Jyotirlinga, Pamohi",
          "BORSOLA BEEL",
          "Brahmaputra Beach",
          "Brahmaputra Jungle Resort",
          "Brahmaputra Riverfront- View point",
          "Brahmaputra River Heritage Centre",
          "Chandrapur Forest, near Guwahati (OBI)",
          "College of Veterinary Science, Khanapara",
          "Cotton University",
          "Deeporbeel--AEC Road",
          "Deepor Beel--Azara",
          "Deepor Beel--Chakardoi",
          "Deepor Beel--Dharapur",
          "Deepor Beel--Dumping Ground",
          "Deepor Beel--General Area",
          "Deepor Beel--Pamohi",
          "Deepor Beel--Watch Tower",
          "Gandhi Mandap",
          "Garbhanga Reserve Forest",
          "Gauhati University Campus",
          "Gauhati University Campus--Botanical Garden",
          "Gauhati University Campus--RCC5",
          "Guwahati",
          "Guwahati--4th Battelion gate",
          "Guwahati Airport (Lokpriya Gopinath Bordoloi International Airport)",
          "Guwahati College",
          "Guwahati--Islampur",
          "Guwahati--Lankeswar",
          "Guwahati Medical College",
          "Guwahati Medical College Campus-Kamrup (OBI)",
          "Guwahati (OBI)",
          "Guwahati Planetarium",
          "Guwahati Railway Station",
          "Guwahati--Santipur",
          "Guwahati--Tetelia",
          "Guwahati Waste Disposal--New",
          "IIT Guwahati",
          "IIT Guwahati--Grassland near Hospital",
          "IIT Guwahati--Wetland Opposite to Brahmaputra Hostel",
          "IIT Guwahati--Wetland Opposite to Core 4",
          "Indian Institute of Technology (IIT) Guwahati--Hill top",
          "IOC Refinery Township",
          "Iora Food Court",
          "Jalukbari (OBI)",
          "Kambhrenga Beel",
          "Maloibari--Swamp",
          "Maloibari Village",
          "Mon Vikas Kendra, DakhinGaon",
          "Nabagraha, Guwahati",
          "Outskirts of Guwahati, Brahmaputra River (OBI)",
          "Pragjyotish College Campus",
          "Regional Science Centre",
          "Rehabari",
          "Satargaon Primary School",
          "S.B Deorah College, Guwahati",
          "Silsako Lake",
          "Soonchali Road",
          "Uzan Bazar Brahmaputra Riverside",
        ],
      },
      {
        district: "Udalguri",
        localities: [
          "Barnadi WLS",
          "Barnadi WLS--Alongjhar",
          "Barnadi WLS--Bogamati",
          "Barnadi WLS--Forest Office",
          "Barnadi WLS--Outskirts",
          "Barnadi WLS--Rajagarh",
          "Bhairavkunda Picnic Spot",
          "Bogamati Picnic Spot",
          "Odalguri, Assam (OBI)",
          "Orang-Mazbat Road",
          "Tangla Town",
        ],
      },
      {
        district: "Morigaon",
        localities: [
          "Dharamtul beside kapili river",
          "Dharamtul (OBI)",
          "Dharamtul to Bhalukaguri road",
          "Hatiutha Dol (Swamp)",
          "Kachari Village",
          "Kholahat Reserve Forest",
          "Manaha Gaon--Swamp and Agricultural land",
          "Manaha Village",
          "Mayong (OBI)",
          "Morakolong, Morigaon",
          "Morigaon College Campus",
          "Morigaon--Dharamtul (OBI)",
          "Morigaon (OBI)",
          "Narasingha Mandir",
          "Pobitora WLS",
          "Tambuli doba wetland",
        ],
      },
      {
        district: "Cachar",
        localities: [
          "Assam University--Guest House and Surrounds",
          "Assam University, Silchar",
          "Barail Wildlife Sanctuary",
          "Bor Jalenga Tea Estate (OBI)",
          "Chatla Haor",
          "Chatla Lake",
          "Dilkosh basti (Village)",
          "Dwarbond",
          "Gunti Bari Forests",
          "Gurucharan College, Silchar",
          "NIT Silchar",
          "Padma Abash Irongmara",
          "Rongpur Lake",
          "Rosekandi Tea Estate",
          "Silchar Airport",
          "Silchar (OBI)",
          "Silcoorie Tea Estate",
          "Upper Jiri Reserve Forest",
        ],
      },
      {
        district: "Tinsukia",
        localities: [
          "Barekuri Village (OBI)",
          "Bherjan Gaon, Assam",
          "Bherjan WLS & Surroundings",
          "Borajan WLS & Surroundings",
          "Dehing Patkai NP--Soraipung (Buffer area)",
          "Dehing Patkai NP--Soraipung (Core area)",
          "Dehing Patkai NP--Soraipung (Ranger's Office)",
          "Dholla Ghat, Tinsukia (OBI)",
          "Dibru-Saikhowa NP (Outskirts)--Baghjan Grassland",
          "Dibru-Saikhowa NP (Outskirts)--Koliapani Grassland",
          "Dibru-Saikhowa NP & Surroundings--General Area",
          "Dibru-Saikhowa NP & Surroundings--Kunda Ghat Camp",
          "Digboi College Campus",
          "Digboi--DFO Office Campus",
          "Digboi Forests",
          "Digboi--General Area",
          "Digboi--India Club",
          "Digboi--IOCL Staff Quarters",
          "Digboi--Matri Mandir",
          "Digboi--Oil Field",
          "Digboi--Oil Museum",
          "Digboi-Pengeri Road",
          "Digboi Tourist Lodge",
          "Doomdooma College",
          "Doom Dooma RF",
          "Guijan ghat",
          "Guijan (OBI)",
          "Hahkhati Reserve Forest",
          "Kakojan RF",
          "Kakopathar--Kumchang Tea Estate",
          "Kakopathar--Tilak Nagar Paddyfield",
          "Kakopathar Town",
          "Laina Grassland, Talap",
          "Ledo Airfield",
          "Likhajan",
          "Lohit River--Dr. Bhupen Hazarika Setu",
          "Maguribeel--Grassland",
          "Maguribeel--Kohuwa Eco Camp",
          "Maguribeel--Maguri Eco Camp",
          "Maguribeel--Wetland",
          "Mounglang Buddhist Monastery",
          "Namdong Resort",
          "Notun Rangagorah Ecotourism Village",
          "Padumoni WLS & Surroundings",
          "Panitola",
          "Panitola, Assam (OBI)",
          "Puroni Motapung Beel (Wetland)",
          "Sadiya, Assam (OBI)",
          "Sadiya--Saikhowa Ghat (River Crossing)",
          "Sarudhadum Gaon",
          "Tinsukia (OBI)",
          "Tinsukia Town",
          "Upper Dihing (East Block)",
        ],
      },
      {
        district: "Jorhat",
        localities: [
          "Assam Agricultural University",
          "Bormukoli Pothar",
          "Bor Sola Beel",
          "Bor Sola Beel (Wetland)",
          "Chowkhat",
          "DCB Girls' College, Jorhat",
          "Fakua Dol Beel",
          "Gibbon resort & surroundings",
          "Hoollongapar Gibbon Sanctuary",
          "Jorhat--Tinikunia Pukhuri",
          "Kadomani Beel",
          "Kokilamukh- Bar Saral beel",
          "Kokilamukh Beel",
          "Missamari Beel",
          "Molai Island",
          "National Institute of Design--Assam",
          "Nemati wetlands",
          "Nimati Ghat - Majuli ferry",
          "North East Institute of Science and Technology Campus",
          "ONGC Avian Park",
          "Potiya Sarala Beel",
          "Sarala Beel",
          "Teok Town",
          "Thengal Village",
          "Tocklai Tea Estate",
        ],
      },
      {
        district: "Darrang",
        localities: [
          "Brahmaputra River--Darrang",
          "Mangaldai Bus Stand",
          "Mangaldai College Campus",
          "Orang National Park",
          "Pandit Dindayal College Campus",
          "The River Land, Soonchali",
        ],
      },
      {
        district: "Dibrugarh",
        localities: [
          "ASTC Dibrugarh",
          "Bogibeel, Dibrugarh (OBI)",
          "Dehing Patkai--Krishna Dorik Homestay",
          "Dehing Patkai NP--Soraipung Paddyfield",
          "Dibrugarh Airport",
          "Dibrugarh (OBI)",
          "Dibrugarh University Park",
          "Dihing Patkai NP--Jeypore (General Area)",
          "Dihing Patkai NP--Jeypore (Kothalguri Camp)",
          "Dihing Patkai NP--Jeypore (Namsang Road)",
          "Dihing Patkai NP--Jeypore (Open fields)",
          "Duliajan (OBI)",
          "Hareghat Bridge on the Buri Dihing Rive (OBI)",
          "Jeypore--Dihing River",
          "Jeypore--Ranger's Colony",
          "Jeypore Reserve Forest--Dillighat",
          "Jokai Botanical Garden",
          "Jokai Reserve Forest",
          "Maijan Grassland",
          "Maijan Marshland",
          "Maijan Tea Estate (OBI)",
          "Merbil-Sasoni Ecotourism Park",
          "Nakari Neogchuk Village (OBI)",
          "Namrup (OBI)",
          "Tengakhat (OBI)",
        ],
      },
      {
        district: "Sonitpur",
        localities: [
          "Army Residential Colony (restricted access)",
          "Baligora Eco Camp",
          "Balipara Town",
          "Base Hospital Rd, Tezpur-- Assam",
          "Beels and river south of Tezpur Bridge",
          "Bhaluk Pung",
          "Borbhagia",
          "Burhachapori WLS",
          "Burhachapori WLS--Dhania IB",
          "Eastern Himalayan Botanic Ark",
          "Gaikhuti Beel (wetland)",
          "Greenhub, Tezpur, Assam",
          "Hazara Pukhuri",
          "Hospital Tezpur",
          "Jia Bharali (Kameng) River",
          "Jia Bhoroli Wildlife Camp",
          "Kamengbari",
          "Kanyaka",
          "Kari Beel (wetland)",
          "Kaziranga NP--Western Range (Northbank)",
          "Lake Brahmaputra--Kolia Bhumura Bridge",
          "Nameri",
          "Nameri Eco Camp",
          "Nameri Eco Camp Road",
          "Nameri NP",
          "near Koliabhomora Bridge (OBI)",
          "Puthimari",
          "Rafting Point",
          "Sapoi Tea Farm",
          "Sonai-Rupai WLS--Range Office",
          "Tezpur--Bhairab Nagar",
          "Tezpur, (OBI)",
          "Tezpur--Padum Pukhuri",
          "Tezpur University Campus",
        ],
      },
      {
        district: "Charaideo",
        localities: ["Moranhat (Moran)", "Patsaku College Campus"],
      },
      {
        district: "Baksa",
        localities: [
          "Bornadi WLS",
          "Fish Diversity Museum",
          "Kokilabari Seed Farm",
          "Manas Chowki, Picnic spot, Baksa, Assam",
          "Manas Maozigendri Ecotourism Society",
          "Manas NP--Alabari Maozigendri",
          "Manas NP--Banasbari (Central Range)",
          "Manas NP--Banasbari Lodge",
          "Manas NP--Bhuyanpara",
          "Manas NP--Bhuyanpara (Eastern Range)",
          "Manas NP--Bhuyanpara Range Office",
          "Manas NP--Doimary",
          "Manas NP--General Area",
          "Manas NP--Hewali To Dwimary",
          "Manas NP--Mathanguri",
          "Manas NP--Narayanguri exit",
          "Manas NP--Sewali to Teklai camp",
          "Manas Tiger Reserve--Chowki",
          "Pagladia River",
          "The Sikhiri Cottages",
        ],
      },
      {
        district: "Sivasagar",
        localities: [
          "ASTC Sivasagar",
          "Demow Town",
          "Gourisagar Tank",
          "Jhanji Town",
          "Joysagar Tank",
          "Nazira Colony",
          "Panidihing Bird Sanctuary",
          "Pohugarh",
          "Pohu Garh (Wetlands)",
          "Rang Ghar",
          "Sivasagar Tank",
          "Tolatol Ghar",
        ],
      },
      {
        district: "Karimganj",
        localities: [
          "Dosdewa Forest",
          "Dosdewa Forest Trail",
          "Dosdewa Village",
          "Hathikhira Tea Estate",
          "Karimganj (OBI)",
          "Makunda Christian Hospital",
          "Nilambazar Railway Station",
          "Patharia Hill RF (OBI)",
          "Secondary Forest Near Dosdewa Village",
          "Soidewa Forest",
          "Sonbeel (Wetland)",
          "Sreerampur Forest, Karimganj (OBI)",
          "Srirampur",
        ],
      },
      {
        district: "West Karbi Anglong",
        localities: ["Hemtap", "Khandajan", "Umru (OBI)"],
      },
      {
        district: "Goalpara",
        localities: [
          "Dhamar Reserve Forest",
          "Goalpara--Dubapara",
          "Goalpara Town",
          "Krishnai",
          "Krishnai Railway Station",
          "Mariumpur Church",
          "Urpad Beel (Lake)",
        ],
      },
      {
        district: "Nalbari",
        localities: [
          "Baramboi Borpukhuri",
          "Barkapla Beel",
          "Belsor Village",
          "Nalbari Brahmaputra River (OBI)",
          "Nalbari Railway Station",
          "Tihu Town",
        ],
      },
      {
        district: "Nagaon",
        localities: [
          "Amsoi-Amtreng Road",
          "Amsoi Village",
          "Anandaram Dehkial Phookan Bridge, Nagaon",
          "Assam Cooperative Jute Mills",
          "Bali Chara Dalani (Swamp)",
          "Batadraba Village",
          "Bordowa Temple",
          "Burapahar",
          "Chapanala Picnic Spot",
          "Chapanala Reserve Forest",
          "College of Education Nagaon Campus",
          "DC Office Campus",
          "Dewbali Jalah (Swamp)",
          "Dol Mandir (Temple)",
          "Hahila Beel",
          "Jasingfaa Aqua Tourism Resort",
          "Jiajuri Tea Estate",
          "Joysagar Doloni (Wetland)",
          "Kamakhya Temple Silghat",
          "Kandhulimari Village",
          "Kaziranga National Park, Eastern Zone",
          "Kaziranga NP & Surroundings--Burapahar Nahrubasti",
          "Kaziranga NP & Surroundings--Burapahar Range (Ghorakati)",
          "Kaziranga NP & Surroundings--Kanchanjhuri Rhino View Point",
          "Kaziranga NP & Surroundings--Pup Deopani",
          "Kaziranga NP & Surroundings--Western Range (Bagori)",
          "Laokhowa Wildlife Sanctuary",
          "Laokhowa Wildlife Sanctuary--Rowmari Beel",
          "Morikolong and Borghat Point areas",
          "Nagaon Girls College Campus",
          "Nagaon Town",
          "Nagoan Bypass",
          "Nehru Yuva Kendra",
          "Nowgong Law College Campus",
          "Palasoni Village",
          "Paradise Lake, Kathalguri, Nagaon",
          "Raha, Assam (OBI)",
          "Ranthali Village",
          "Samaguri Beel,Samaguri,Nagaon,Assam",
          "SANKAR MISSION CAMPUS",
          "Soong Loong Tea Estate",
          "Swang Reserve Forest",
          "Trishuldhari Temple, Silghat",
        ],
      },
      {
        district: "Hojai",
        localities: ["Dhalpukhuri,Hojai,Assam", "Udali RF,Lumding"],
      },
      {
        district: "Dhubri",
        localities: ["chapori in Dhubri (OBI)", "Dhir Beel (lake)"],
      },
      {
        district: "Hailakandi",
        localities: [
          "Aynakhal (OBI)",
          "Bakri Haor Complex, Hailakandi (OBI)",
          "Hailakandi Town",
          "Loharbond Reserve Forest",
          "Matijuri general area",
          "Noxatilla wetlands",
          "Srikishan Sarda College and surroundings",
        ],
      },
      {
        district: "Majuli",
        localities: [
          "Bongaon",
          "Dakhinpat Satra, Majuli",
          "Forest Reserve, Near Auniati Satra",
          "Garamur Satra Road",
          "Kamalabari Ghat",
          "Katimora beel (Wetland)",
          "Magurmari",
          "Majuli--Ghutahula",
          "Majuli Island",
          "Majuli--Kamalabari",
          "Majuli--Mohkhuti Jaan",
          "Majuli--Puhardia",
          "Majuli - Thoni ghat",
          "Near Kamalabari Ghat",
          "River Bank, Near Auniati satra",
          "Sakuli beel",
          "Sakuli beel (wetland)",
          "Soraichung",
          "Vereki beel (Wetland)",
        ],
      },
    ],
  },
  {
    state: "Bihar",
    districts: [
      {
        district: "Araria",
        localities: ["Parman River Araria, Bhagat Tola Rd"],
      },
      { district: "Arwal", localities: [] },
      { district: "Aurangabad", localities: [] },
      { district: "Banka", localities: [] },
      {
        district: "Begusarai",
        localities: [
          "Jaimangla Garh FRH (Forest Guesthouse)",
          "Kanwar Jheel Lake (IBA)",
          "Kanwar Lake -- Jaimangla Garh side",
        ],
      },
      {
        district: "Bhagalpur",
        localities: [
          "3 Number Thokar Bochahi",
          "Digambar Jain Mandir - Bhagalpur",
          "Forestry Dept Sundervan Campus - Bhagalpur",
          "Jagatpur Lake",
          "Jagatpur Lake, Bhagalpur",
          "Karari Tintanga",
          "Shankarpur Diyara",
          "Tintanga Diyara",
          "T M Bhagalpur University Campus",
          "TNB College, Bhagalpur",
          "Vikramshila Gangetic Dolphin Sanctuary -- Sultanganj - Bhagalpur",
        ],
      },
      {
        district: "Bhojpur",
        localities: ["Ara Railway Junction (Bhojpur, Bihar)"],
      },
      { district: "Buxar", localities: [] },
      {
        district: "Darbhanga",
        localities: [
          "Atahi Village Wetalnd (Chaur)",
          "Baghauni",
          "Kherajpur Chaur, Darbhanga",
          "Kusheshwar Sthan",
          "Near Dhiropatti, Horalpatti, Hayaghat",
          "Near Taralahi, Hanumannagar, Darbhanga",
          "Pandasarai, Singhiya Darbhanga Rd, Darbhanga",
          "Piproulia",
          "Purani Durga Mandir Baheri, Lilkhon",
        ],
      },
      {
        district: "Gaya",
        localities: [
          "Bodhgaya",
          "Brahmayoni Hill, Nalli, Gaya",
          "Falgu River at Kesapi, GT Road Gaya, Bhadeya",
          "Gautam Buddha  Wildlife Sanctuary Ã¢â‚¬â€  Bhadeya",
          "Gautam Buddha Wildlife Sanctuary--Barbadih",
          "Gautam Buddha Wildlife Sanctuary--Tikwa Nala",
          "Gautam Budha Wildlife Sanctuary Ã¢â‚¬â€ Babhandev Jungle",
          "Gautam Budha Wildlife Sanctuary Ã¢â‚¬â€ Falgu Riverbed",
          "Gautam Budha Wildlife Sanctuary Ã¢â‚¬â€ Sugasot",
          "Gautam Budha Wildlife Sanctuary -- Baradih",
          "Gautam Budha Wildlife Sanctuary -- GWLS General Area",
          "Gautam Budha Wildlife Sanctuary -- Sri Ram Dhaba",
          "Itwan",
        ],
      },
      { district: "Gopalganj", localities: [] },
      {
        district: "Jamui",
        localities: [
          "Belatad -- Jamui",
          "Devanaahar",
          "Nagi Dam Bird Sanctuary",
          "Nagi Dam Bird Sanctuary (Nagi Dam Jamui IBA)",
          "Nagi-Nakti Bird Sanctuary - Chanantar Temple Rd -- Jamui",
          "Nagi-Nakti Bird Sanctuary -- Jamui",
          "Nawkadih - Nagi Dam Bird Sanctuary",
          "Pailbajan",
        ],
      },
      { district: "Jehanabad", localities: [] },
      {
        district: "Kaimur",
        localities: ["Karmanasa Railway Station, Kaimur (Bhabua), Bihar"],
      },
      {
        district: "Katihar",
        localities: [
          "Gogabil Lake, Manihari (Amdabad Block)",
          "Gogabil Pakshi Vihar, Baghar Beel and Baldia Chaur",
          "Gogabil Pakshi Vihar (IBA) -- Baghar Bil",
          "Gogabil Pakshi Vihar (IBA) -- Jangli Tal",
          "Korha Kothi, Musapur, Katihar",
          "Kursela Dhab",
        ],
      },
      { district: "Khagaria", localities: [] },
      { district: "Kishanganj", localities: [] },
      {
        district: "Lakhisarai",
        localities: [
          "Dumari Halt Railway Station",
          "Kiul Railway Junction, Lakhisarai",
          "Luckeesarai Junction",
        ],
      },
      { district: "Madhepura", localities: [] },
      { district: "Madhubani", localities: ["Benoa Chaur", "Bhachhi"] },
      {
        district: "Munger",
        localities: [
          "Jamalpur Railway junction, Bihar",
          "Vikramshila Gangetic Dolphin Sanctuary, Bhagalpur",
        ],
      },
      { district: "Muzaffarpur", localities: [] },
      {
        district: "Nalanda",
        localities: {
          localities: [
            "Begampur lake, Nalanda",
            "Bihar Sharif, Railway station",
            "Chandasi, Agriculture College,Noorsarai",
            "Chandi Hospital,Nalanda",
            "Chandi, Nalanda college of engineering,Nalanda",
            "Charuipar Noorsarai",
            "Charuipar, Noorsarai",
            "Dasturpar,Bihar",
            "Deepnagar,Pnachane Riverbank",
            "Diya,Biharsharif",
            "Ekangar Dih,Nalanda",
            "Ghoda-Katora Lake Road,Rajgir",
            "Gidhhi lake,Nalanda",
            "Godam Ganda, Noorsarai  803113",
            "Jolahpura, Noorsarai,Nalanda",
            "Kheban Bigha, Noorsarai",
            "Madhy Gramin Bank,Noorsarai",
            "Manik Pur,Noorsarai",
            "Muzaffarpur,Noorsarai",
            "Muzzafarpur-khebanbigha,Noorsarai",
            "Muzzafarpur Village, Noorsarai",
            "Muzzafarpur Village, Noorsarai,4",
            "Nalanda Archaeological site",
            "Nalanda Archeological Site Rd.",
            "Nalanda College, Bihar Sharif,Nalanda",
            "Nalanda Ordnance Factory -- Dhamm Sarovar",
            "Nalanda University New Campus",
            "Noorsarai-JolahPura-Chandasi",
            "Noorsarai, Nalanda",
            "Noorsarai,Sangat middle School, Noorsarai 803113",
            "Panchane River Deepnagar Stadium",
            "Panchane River -- Triveni Sangam Sthal",
            "Pant (Rajgir) Wildlife Sanctuary",
            "Pant (Rajgir) WLS -- Basoori Talab",
            "Pant (Rajgir) WLS -- Deer Park",
            "Pant (Rajgir) WLS -- General Area",
            "Pant (Rajgir) WLS -- Jathian Road",
            "Pant (Rajgir) WLS -- Pandu Pokhar",
            "Pant (Rajgir) WLS -- Veerayatan",
            "Pant (Rajgir) WLS -- Venu Vana and Circuit House",
            "Pawapuri jal mandir ( Temple )",
            "Pawapuri Nalanda",
            "Pawapuri temple , Pawapuri jal mandir",
            "Pawapuri watertank",
            "Pawapuri Water Tank , Pawapuri jal mandir",
            "Rajgir Wildlife Safari",
            "Rajgir wildlife sanctuary (General Area)",
            "Rajgir Wildlife Sanctuary -- Jai Prakash Udyan",
            "Venu bana (Venu Van),Rajgir",
          ],
        },
      },
      { district: "Nawada", localities: [] },
      {
        district: "Pashchim Champaran",
        localities: [
          "Hanging Bridge, Koleshwar Temple -- Valmiki Tiger Reserve",
          "Kharhat -- Sapahi Marg",
          "Udaipur Jungle near Bettiah",
          "Valmiki Tiger Reserve -- Bhikhna Thori",
          "Valmiki Tiger Reserve--Raghia range",
          "Valmiki Tiger Reserve -- Someshwar Trek, Goberdhana,",
          "Valmiki WLS -- General Area",
          "Valmiki WLS -- valmikinagar",
        ],
      },
      {
        district: "Patna",
        localities: [
          "Aunta, Hathidah",
          "Danapur Cantonment (IBA)",
          "Danapur Railway Station (Patna, Bihar)",
          "Indian Institute of Technology (IIT Patna)",
          "JPNi,Airport,Patna",
          "Khaspur, Patna",
          "National Institute of Technology (NIT Patna) Gulzarbagh,",
          "Old Secretariat,Patna",
          "Patliputra Junction",
          "Patna Golf Club",
          "Patna Junction, RS Patna",
          "Patna (OBI)",
          "Raja Bazar -- Patna",
          "Sanjay Gandhi Biological Park",
          "St Michael's High School, Patna",
          "Vrindavan Colony -- Patna",
          "Zoological Survey Of India,GPRC,Housing Colony,Patna",
        ],
      },
      {
        district: "Purba Champaran",
        localities: [
          "Moti Jheel, Motihari",
          "Pataura Tola Jheel Oxbow Lake, Motihari",
          "Pipra Man Lake near Motihari",
          "Rulahi Maan Oxbow Lake, Motihari",
          "Sadahi Maan Oxbow Lake",
          "Sarotar Khetar (Dangari)",
          "Sirsa Maan (Sirsamal) Oxbow Lake",
          "Turkaulia Maan",
        ],
      },
      {
        district: "Purnia",
        localities: ["College Link Road -- Purnea College"],
      },
      { district: "Rohtas", localities: [] },
      { district: "Saharsa", localities: [] },
      { district: "Samastipur", localities: [] },
      { district: "Saran", localities: [] },
      { district: "Sheikhpura", localities: [] },
      { district: "Sheohar", localities: [] },
      { district: "Sitamarhi", localities: [] },
      { district: "Siwan", localities: [] },
      { district: "Supaul", localities: [] },
      {
        district: "Vaishali",
        localities: ["Salim  Ali Zubba Shani Bird Sanctuary (Baraila Lake)"],
      },
    ],
  },
  {
    state: "Chandigarh",
    districts: [
      {
        district: "Chandigarh",
        localities: [
          "Butterfly Park, Sector 26",
          "Chandigarh Bus Terminus",
          "Children Traffic Park",
          "City Forest & Lake Reserve Forest",
          "Corner of Sector 49-C",
          "Dhanas lake",
          "Green Belt Park, Sector 48D",
          "Himachal Bhavan",
          "IN-Chandigarh-Sector 31B (30.7024,76.7828)",
          "Japanese Garden, Chandigarh, IN (30.704, 76.783)",
          "Kaimbwala",
          "Kishangarh",
          "Leisure Valley",
          "Leisure Valley Park Mohali",
          "Nagar Van",
          "Panjab University",
          "Park 42",
          "Park--Sector 31B",
          "Punjab University Botanical Garden",
          "Rose Garden",
          "Sahibzada Ajit Singh Nagar--New Park",
          "Sector 37",
          "Shanti Kunj Park",
          "Smriti Van",
          "Sukhna Lake",
          "Sukhna Lake Chandigarh",
          "Sukhna Lake Reserve Forest",
          "Sukhna Migratory bird watching point",
        ],
      },
    ],
  },
  {
    state: "Chhattisgarh",
    districts: [
      {
        district: "Gariaband",
        localities: [
          "Awanish Farm House, Chichiya, NH 130C, Gariyaband IN-CT 19.94606, 82.56139",
          "Bhejipadar Bridge Nawapada",
          "collage campus",
          "Deobhog, Chhattisgarh, India",
          "Gariyaband Circuit House",
          "Ghatarani - Jatmai Matha Road, Junwani, Chhattisgarh, IN (20.809, 82.068)",
          "Lachkera",
          "Udanti Sitanadi Tiger Reserve",
          "Urtuli Road, Gariyaband",
        ],
      },
      { district: "Balod", localities: ["Onakona"] },
      { district: "Balrampur", localities: ["Haritima Nursery", "Pavai Faal"] },
      {
        district: "Korba",
        localities: [
          "Chaiturgarh",
          "Kendai Waterfall, Bilaspur - Ambikpur Road",
          "Minimata Bango Dam",
          "Nunera",
        ],
      },
      {
        district: "Rajnandgaon",
        localities: [
          "Baghnadi",
          "Cross Hill",
          "Dhara Dam Dangbora Near Dongargarh",
          "Dongargarh",
          "Kauhapani",
          "Maa Bamleshwari Temple Campus",
          "Mangata Park, Chhattisgarh, IN (21.187, 81.157)",
          "Pailimeta Dam Area- Rajnandgaon",
          "Paniyajob Dam, Chhattisgarh, IN (21.207, 80.667)",
          "Pipariya Jalashay, Khairagarh",
          "Rani Sagar Talab",
          "Ruse Waterbody",
          "Sierpur Dam, Chabuknallah, Chhattisgarh, IN (21.076, 80.468)",
        ],
      },
      { district: "Jashpur", localities: [] },
      { district: "Sukma", localities: ["Sukma town"] },
      { district: "Bijapur", localities: ["Bhopalpatnam"] },
      {
        district: "Kabirdham",
        localities: [
          "Achanakmar Wildlife Sanctuary",
          "Badal Pani Waterfall Kawardha",
          "Bhojali Talab, Kawardha",
          "Bhoramdeo Wildlife Sanctuary",
          "Bhoramdev To Chilphi  Road, Chhattisgarh, IN (22.119, 81.155)",
          "Bhoramdev Wildlife Sanctuary",
          "Bhoramdev Wildlife Sanctuary, Chhattisgarh, IN (22.104, 81.139)",
          "Chhirpani Reservoir - Kabirdham",
          "Energy Park, Kawardha",
          "Fisheries College, Sawaikachhar, Kawardha",
          "Kunda",
          "limo village kabeerdham chhattisgarh",
          "Narmdeshwar Mahadev Mandir, Jhirna",
          "Newari Dam",
          "Panch Dev Mandir",
          "Pidhaghat, Kabeerdham",
          "Rani Dahra Water Fall",
          "Sant Kabir College of Agriculture and Research Station",
          "Saroda Dadar Bhoramdev Forest Range",
          "Sarodhadadar, Kawardha, Chhattisgarh",
          "sarodha reservoir kawardha",
          "Sodha Reservoir Kawardha",
          "Sudha Vatika Garden",
        ],
      },
      {
        district: "Mungeli",
        localities: [
          "Achanakmaar Tiger Reserve -- Shivtarai Gate",
          "Achanakmar Tiger Reserve",
          "Gidhwa Pahad",
          "Khuriya Dam",
          "Madku Dweep",
          "outskirts of Achanakmar Tiger Reserve (OBI)",
        ],
      },
      {
        district: "Baloda Bazar",
        localities: [
          "Barnawapara Buffer Chhattisgarh, IN (21.432, 82.419)",
          "Barnawapara WLS",
          "Devhill Resort Road Chhattisgarh, IN (21.428, 82.557)",
          "Hareli Eco Resort, Barnawapara WLS",
          "Jonk River Bhata oppsite  Giroudpuri Chhattisgarh, IN (21.599, 82.549)",
          "Muba's Machaan Resort",
          "Urjaa Sarovar, Sirpur Road",
        ],
      },
      {
        district: "Bastar",
        localities: [
          "Adawal Naaka Jagdalpur",
          "Bajawand",
          "Bajawand Tank",
          "BASTAR ACADEMY OF DANCE, ART & LITERATURE (BADAL)",
          "Bastar Birds Nest",
          "Bastar Jungle Resort Village Kurandi Jagdalpur",
          "Bastar University",
          "Chitrakoot Waterfall",
          "Dalpat Sagar",
          "Dasapal Dam",
          "Forest Training School, Jagdalpur",
          "Ganesh Bahar Nala",
          "Ganesh Bahar Nala--Kurandi",
          "Jagdalpur",
          "Jamawada",
          "Jamawada Tank",
          "Kailash Gufa",
          "Kanger Valley National Park--Butterfly Garden",
          "Kanger Valley NP",
          "Kumarawand",
          "Kumarawand Village",
          "Kurandi Road, Billori, Jagdalpur",
          "KVNP - Darbha",
          "Machkot",
          "Machkot Tiriya Forrest",
          "Mendir - Ghumar Waterfall",
          "Puspal",
          "Rajnagar",
          "Sargipal",
        ],
      },
      {
        district: "Korea",
        localities: [
          "Gobribandh Reservoir, Katkona",
          "Guru Ghasida National Park",
        ],
      },
      { district: "Kondagaon", localities: ["Kondagaon"] },
      {
        district: "Bilaspur",
        localities: [
          "Arpa River, Bilaspur",
          "Banka High School, Bilaspur",
          "Belmundi",
          "Bhaisajhar",
          "Boirpadav Picnic Spot",
          "Chakarbhatha",
          "Chilhati",
          "Delhi Public School",
          "Dyke2 , Rakhardam, Ntpc",
          "GGU - Kaali Mandir Pond No. 1",
          "Ghongha Jalashay, Bilaspur",
          "Guru Ghasidas University Campus, Bilaspur",
          "Kathakoni",
          "Kathakoni Village (OBI)",
          "Keonchi, Chhattisgarh",
          "Kopra Dam",
          "Kori Dam, Kota, Bilaspur",
          "Marahi Mata Mandir, Bhanwartonk",
          "Mohanbhata, Bilaspur",
          "Near Smriti Van, Rajkishor Nagar",
          "NTPC, Seepat, Bilaspur",
          "Rakhad Dam, NTPC, Sipat, Bilaspur",
          "Sipat Thermal Power Station (OBI)",
          "Smriti Van, Rajkishornagar",
          "Smritvan Rajkishore Nagar Bilaspur",
          "Sukhripali Rakhad Dam , NTPC , Seepat",
        ],
      },
      {
        district: "Janjgir-Champa",
        localities: [
          "Crocodile Park, Kotmi Sunar, Janjgir-Champa",
          "khisora forest",
          "Lake of Lagra",
          "Pamgarh",
        ],
      },
      {
        district: "Mahasamund",
        localities: [
          "Birkoni, forest nursury",
          "Kachhardih Pond",
          "Lakshman Mandir, Sirpur",
          "Maharaji - Arjuni",
        ],
      },
      { district: "Raigarh", localities: [] },
      {
        district: "Bemetara",
        localities: [
          "Enamshahi Tank",
          "Gidhwa Tank",
          "Gidhwa Wetland, Tank 2",
          "Kalalbod Tank",
          "Murkuta Tank",
          "Parsada Tank",
          "Somnath Forest Area",
        ],
      },
      { district: "Kanker", localities: ["Prayas School--General Area"] },
      {
        district: "Dhamtari",
        localities: [
          "Bhakhara Waterbody",
          "BRP Govt. Polytechnic College -- Dhamtari",
          "Dhamtari",
          "GAD colony, Kurud, Dhamtari IN-CT 20.83927, 81.70899",
          "Gangrel Dam (forest area)",
          "Govt. Girls College -- Dhamtari",
          "Kurud Nagar Road, Kurud IN-Chhattisgarh (20.8318,81.7161)",
          "Madamsilli Dam",
          "Near Rice mill, Kurud",
          "Sant Guru Ghasidas Govt. P. G. College Campus Kurud",
          "Sant Guru Ghasidas Govt. P. G. College Campus, Kurud, Chhattisgarh, India",
          "Sant Guru Ghasidas PG College, Kurud",
          "Sitanadi Wildlife Sanctuary",
          "Suncity, Kurud,  IN-Chhattisgarh (20.8341,81.7142)",
        ],
      },
      {
        district: "Surguja",
        localities: [
          "Ghaghi Waterfalls, Darima, Chhattisgarh, IN (22.919, 83.26)",
          "Jalpari Point, Mainpat",
          "kuwarpur dam",
          "Lalmati, Luchki Ghat, Raigarh Road, Ambikapur",
          "Mainpat Ghats ,Chhattisgarh, IN (22.895, 83.338)",
          "Mudesa, Chhattisgarh, IN (23.084, 83.099)",
          "Pond Behind Sainik School, Ambikapur",
          "Sanjay Park , National Highway 343, Ambikapur, Chhattisgarh, IN (23.144, 83.206)",
          "Tiger Point, Mainpath",
        ],
      },
      {
        district: "Raipur",
        localities: [
          "Anupam Nagar",
          "Anupam Nagar, Raipur",
          "Avani Vihar Colony",
          "Ayurvedic College - Herbal Garden",
          "Bangoli Water Dam",
          "Barbanda Pond and Surroundings",
          "Bendri Lake",
          "Bhata Behind Amity University, Chhattisgarh, IN (21.401, 81.898)",
          "Bhatagaon Filter Plant",
          "Block 22 campus, Naya Raipur",
          "Budera Pond And Sarround",
          "Canal near DPS school",
          "CG Academy of Administration",
          "Chhattisgarh Science Centre, Raipur",
          "CSPDCL Rest House Campus, Gudhiyari",
          "Deortilda Dam",
          "Doma",
          "Floral City and Surroundings",
          "GSD WRS, Raipur, Chhattisgarh, IN (21.286, 81.654)",
          "Gudhiyari Wetlands, Raipur",
          "IIM Raipur",
          "IIT Raipur",
          "Indian Institute of Management (IIM) Raipur",
          "Indira Smriti Van, Raipur",
          "Jalso Tank",
          "Jhanjh Lake",
          "Jungle near Jhanjh",
          "Kanger Valley Academy Campus",
          "Kathadih Farmhouse, Kathadih, Chhattisgarh, IN (21.178, 81.625)",
          "Khudiya Dam, Naya Raipur",
          "Khuteri Pond",
          "Kosrangi High School",
          "Kurud Tank, Mandir Hasaud",
          "Kushabhao Thakrey Journalism University, Raipur",
          "LIG 1219 Sector-8 Saddu, Raipur Chhattisgarh",
          "Mahant Ghasidas Sangrahalaya, Raipur",
          "Mandhar Primary School Campus",
          "Mandhar Talaab",
          "Marsh Land Near Khilora Village",
          "Metro Greens, Saddu",
          "Mohrenga Nature Trail",
          "MSB Educational Institute",
          "Nahardih Dam and Sarround",
          "National Institute of Technology (NIT) Raipur",
          "Naveen Primary School",
          "Naya Raipur--Cheriya Lake",
          "Naya Raipur--Courser Bhaata",
          "Naya Raipur--General",
          "Naya Raipur--Jungle Safari",
          "Naya Raipur - Khanduwa - Back Waters of Jungle Safari",
          "Naya Raipur--Sendh Lake",
          "Near Diamond Tree Apartments, Daldal Seoni Road, Mowa",
          "New Raipur Sector 27 Residential Complex Campus Block 21 - 26",
          "P22, Sec 1, Avanti Vihar (Restricted)",
          "Pikaridih",
          "Pond Near Gatapar Village, Abhanpur",
          "Prayas Hostel Campus",
          "Pt .J N Medical College",
          "PTS Mana, Raipur",
          "Puja Garden",
          "Purkhouti Muktangan",
          "Rajiv Smriti Van & Urja Park",
          "Rajkumar College",
          "RVH Fields Area Raipur",
          "Science College Ground",
          "Serikhedi",
          "State Forest Research and Training Institute",
          "Swami Vivekananda Airport, Raipur",
          "Tank at Tor, Chhattisgarh, IN (21.371, 81.743)",
          "The Great India School",
          "Tila Anicut",
          "Water Tank - Dam, Keotara, Chhattisgarh, IN (21.494, 81.893)",
          "WRS Colony, Raipur",
        ],
      },
      {
        district: "Durg",
        localities: [
          "Ahiwara Road, Hingna",
          "Avenue- A, Sector 6, Bhilai",
          "Belaudi Tank, Chhattisgarh, IN (21.011, 81.457)",
          "Bhaata Behind Nandani Airstrip, Chhattisgarh, IN (21.286, 81.382)",
          "Bhilai, Durg (OBI)",
          "Minakshinagar Grassland",
          "Mines Area Ahiwara, Chhattisgarh, IN (21.359, 81.407)",
          "Rungta College Of Engineering and Technology (RSR)",
          "Santra Tank, Matang, Chhattisgarh, IN (21.052, 81.462)",
          "Semri Dam, Patan Chhattisgarh, IN (21.025, 81.488)",
          "Talpuri Biodiversity Park Bhilai",
          "Tarighat Temple Backside, Patan",
        ],
      },
      { district: "Dantewada", localities: ["Badebedma, Dantewada"] },
      { district: "Narayanpur", localities: [] },
      {
        district: "Surajpur",
        localities: [
          "Batara Dam , Datima",
          "Mayurbhanj Dam Area Surajpur Chhattisgarh -23.15172,83.09993",
          "Morbhanj, Ajabnagar",
          "Songara Jungle, Banaras Road",
          "Songara Jungle, Banaras Road,Chhattisgarh, IN (23.357, 83.087)",
        ],
      },
    ],
  },
  {
    state: "Dadra and Nagar Haveli",
    districts: [
      {
        district: "Dadra & Nagar Haveli",
        localities: [
          "BBA Polytechnic College Campus",
          "Dadra and Nagar Haveli WLS",
          "Khadoli Outskirts",
          "Madhuban Dam Forest",
          "Madhuban Dam Riverside",
          "Tinoda Forest Trail",
          "Umarkui Waterfalls",
          "Vasona",
        ],
      },
    ],
  },
  {
    state: "Daman and Diu",
    districts: [
      {
        district: "Diu",
        localities: [
          "Diu--General Area (OBI)",
          "Fudam Bird Sanctuary",
          "Gangeshwar Mahadev (OBI)",
          "Ghoghola, Diu",
        ],
      },
      { district: "Daman", localities: [] },
    ],
  },
  {
    state: "Delhi",
    districts: [
      {
        district: "Shahdara",
        localities: [
          "CBD Shahdara Scrub",
          "GTB Hospital Campus,Dilshad Garden IN/DL",
          "GTB Hospital Campus IN/DL",
          "Institute of Human Behaviour and Allied Sciences Hospital (OBI)",
          "Karkardooma",
          "Karkardooma Park",
          "Yamuna Sports Complex IN/DL",
        ],
      },
      {
        district: "Central Delhi",
        localities: [
          "Ambedkar University Delhi, Kashmere Gate Campus",
          "Delta on yamuna bank",
          "Department of Geology, Delhi University",
          "Hansraj College, Delhi",
          "Hindu College, Delhi",
          "Indraprastha College for Women",
          "Jagatpur Khadar (OBI)",
          "Kamla Nehru Ridge Delhi",
          "Miranda House Delhi",
          "New Delhi Railway Station",
          "Nigambodh ghat DL/IN",
          "Old Delhi Railway Station",
          "Raj Ghat, Delhi",
          "Raj Ghat--Yamuna Bank",
          "Red Fort, Delhi",
          "River Yamuna, Asita East DL/IN",
          "Roshanara Bagh  IN-Delhi",
          "Sabhapur Pushta Khader (Sonia Vihar)",
          "St. Stephen's Hospital, Tis Hazari",
          "University of Delhi, North Campus",
          "Yamuna Biodiversity Park phase 2 IN-DL",
          "Yamuna Biodiversity Park--Phase I",
          "Yamuna Khadar,Sunder Vihar Colony",
          "Yamuna Khadar, Wazirabad",
          "Yamuna Khader, Usmanpur (OBI)",
          "Yamuna River, Delhi (OBI)",
        ],
      },
      {
        district: "East Delhi",
        localities: [
          "Anand Vihar, Delhi (OBI)",
          "Commonwealth Games Village Sports Complex DL/IN",
          "DDA Park IP Extension",
          "Ghazipur dumpyard",
          "Sanjay Jheel(Lake) Mayur vihar",
          "Smriti Van Mayur Vihar Phase 3",
          "Vasundhara Enclave IN/DL",
          "Yamuna Bank Depot",
          "Yamuna Khadar,Geeta Colony IN/DL",
        ],
      },
      {
        district: "North Delhi",
        localities: [
          "Ambedkar University Delhi--Dheerpur Campus",
          "Bhalswa Horsehoe Lake",
          "Dheerpur Wetland Park (DWP)",
          "Dhirpur (AUD new campus)",
          "Holambi Kalan railway tracks (OBI)",
          "Japanese park Rohini IN/DL",
          "Nirankari Samagam Ground, Delhi (OBI)",
          "Sungarpur, Yamuna Khadar,Jhangola,IN-Del",
        ],
      },
      {
        district: "New Delhi",
        localities: [
          "AES New Delhi Campus (Private Access)",
          "Aravalli Biodiversity Park",
          "Aryabhatta College/Ram Lal Anand College (RLA)",
          "Ashok Hotel, Delhi",
          "Buddha Jayanti Park IN/DL",
          "Delhi Cantt",
          "Delhi Gymkhana Club DL/IN",
          "Delhi, India (OBI)",
          "Delhi Public School R.K.Puram DL/IN",
          "India Gate",
          "Indira Gandhi International Airport, Delhi",
          "Jantar Mantar, New delhi",
          "Jawaharlal Nehru University (JNU) Campus",
          "Laxmi Bai Nagar",
          "Leela Ambience Hotel, Gurugram",
          "Lodhi Gardens",
          "Malcha Marg Jungle",
          "Naraina Vihar, Delhi (OBI)",
          "National Agricultural Science Museum, ND/IN",
          "National Rail Museum",
          "Nehru Memorial Museum and Library",
          "Nehru Park IN-Delhi-New",
          "NIPGR, V.Kunj IN-DL",
          "Paschimabad Checkdam",
          "Purana Qila IN-DL",
          "Pusa campus--Rajindernagar",
          "RUGBY GROUND VASANT KUNJ",
          "Sarojini Nagar IN/DL",
          "Shankar Vihar (OBI)",
          "Sri Venkateswara College, Delhi",
          "TERI University Campus",
          "United Service Institution of India Subroto park",
          "University of Delhi--South Campus",
        ],
      },
      {
        district: "North East Delhi",
        localities: [
          "Garhimandu City Forest",
          "Garhi Mandu (OBI)",
          "Usmanpur Village (OBI)",
        ],
      },
      {
        district: "South East Delhi",
        localities: [
          "Acharya Narendra Dev College (ANDC)",
          "AIIMS Campus",
          "Ambedkar University Delhi, Lodhi Road Campus",
          "Asola Wildlife Sanctuary",
          "Chilla Khadar Mayur vihar IN-DL",
          "Delhi Golf Club IN/DL",
          "Delhi Zoo",
          "Delhi Zoological Park  DL/IN",
          "Deshbandhu College, Delhi",
          "Hazrat Nizamuddin Railway Station",
          "Holy Family Hospital, Okhla",
          "Humayun's Tomb",
          "Kalindi Kinj Park",
          "Lotus Temple DL/IN",
          "PGDAV College Campus, Delhi",
          "Sadiq Nagar Park",
          "Sunder Nursery, Nizamuddin",
          "Tughlakabad",
          "Tughlaqabad Biodiversity Park IN/DL",
        ],
      },
      {
        district: "South West Delhi",
        localities: [
          "DDA Water Body sector 23 Dwarka DL/IN",
          "District Park, Dwarka Sector 19-B IN/DL",
          "Galibpur village (Najafgarh)",
          "Guru Gobind Singh (GGS) Indraprastha University",
          "Hotel Radisson Blu, Dwarka, New Delhi (OBI)",
          "Najafgarh Drain (Dhansa Barrage) HR/IN",
          "Najafgarh Jheel Area",
          "nazafgarh drain sector 16 b Dwarka Delhi",
          "Nazafgarh, Haryana (OBI)",
        ],
      },
      {
        district: "South Delhi",
        localities: [
          "Arjangarh IN/DL",
          "b5 park Safdarjung Enclave",
          "Bhardwaj Lake,Asola Sanctury IN/DL",
          "Bhatti Mines",
          "chandrabhaga check dam jnu delhi",
          "Garden of Five Senses",
          "Gargi College, Delhi",
          "Green Park, Delhi (OBI)",
          "Hauz Khas--Deer Park",
          "Hauz Khas Forest",
          "Hauz Khas--Lake",
          "IIT Delhi",
          "Indira Gandhi National Open University, (IGNOU)",
          "Jahanpanah City Forest",
          "Jirkhod Dera mandir, Dera Mandi,",
          "Malviya Nagar (OBI)",
          "Ma Sarada Park hauz khas Del-ind",
          "Mehrauli Archaeological Park ND/IN",
          "NCERT Campus, New Delhi",
          "Neela hauz Vasantkunj IN/DL",
          "Quitab Minar inside IN-DL",
          "Qutub Minar",
          "RK Puram--Sector 3",
          "Rose Garden Hauz Khas Marg IN-DL",
          "Rose Garden IN/DL",
          "Sanjay Van",
          "Satpulla Lake Complex",
          "Shaheed Bhagat Singh College IN/DL",
          "SowGood Learning Farm IN/DL",
          "TARA Centre of Excellence (Ghitorni Campus)",
          "The Mother's International School IN-DELHI",
          "Thilpat Valley Biodiversity Park IN/DL",
          "Tilpath Valley Biodiversity Park",
        ],
      },
      {
        district: "North West Delhi",
        localities: [
          "Ambedkar University Delhi (AUD), Rohini Campus",
          "Ambedkar University, Rohini Campus DL/IN",
          "District Park Ã¢â‚¬â€ Shalimar Bagh ND-IN",
        ],
      },
      {
        district: "West Delhi",
        localities: [
          "Ambedkar University Delhi, Karampura Campus",
          "B 1 Park Paschim Vihar",
          "Deen Dayal Upadhyaya College, Delhi",
          "District Park, Paschim Vihar",
          "IARI Pusa Campus",
          "Janakpuri District Park, B1",
          "Kalindi College, University of Delhi",
          "Vikaspuri, F-Block DDA Park",
        ],
      },
    ],
  },
  {
    state: "Goa",
    districts: [
      {
        district: "North Goa",
        localities: [
          "Adulshe, Borim",
          "Agacaim Tidal mudflats",
          "Aldona fields",
          "Anjuna Beach",
          "Ansabhat, Mapusa",
          "Arabian Sea",
          "Arabian Sea: 15.5N 73.5E",
          "Arabian Sea: 15.5N 73.6E",
          "Arabian Sea: 15.5N 73.7E",
          "Arabian Sea: 15.5N 73.8E",
          "Arabian Sea: 15.6N 73.6E",
          "Arabian Sea: 15.6N 73.7E",
          "Arambol",
          "Arambol Mountain & Sweet Lake",
          "Arpora Saltpans",
          "Arpora Woods",
          "Ashvem Beach",
          "Assagao Village",
          "Azossim Fields and Wetlands",
          "Baga",
          "Baga Fields",
          "Baga Hill, Goa, India",
          "Bainguinim",
          "Banastari Wetlands",
          "Barazan Plateau Mopa",
          "Barazan Plateau,Mopa",
          "Barazan Waterfall at Surla",
          "Batim Lake",
          "Beira Mar Hotel, Baga",
          "Betki, Khandola",
          "Bhatulem-Taligaon Communidade Land",
          "Bicholim & Surrounding areas",
          "Bondla wild life sanctuary",
          "Bondla Wildlife Sanctuary",
          "Bondvol/ Bondava Lake, St Cruz",
          "Borim marshlands and paddyfields",
          "Butterfly Conservatory of Goa",
          "Calangute Beach",
          "Calangute Fields",
          "Calvim Island, Aldona",
          "Campal Children's Park",
          "Candolim Beach",
          "Candolim Marshes",
          "Cansarvornem hill",
          "Carambolim- Dhado Wetland Complex- IBA",
          "Carambolim Lake & Surroundings (IBA)",
          "Caranzalem",
          "Caranzalem- Beach (Children's Park Area)",
          "Chapora Estuary",
          "Chapora Fort",
          "Chapora River, Goa (OBI)",
          "Chapora River, North Goa (OBI)",
          "Chidambar trail",
          "Chimbel lake",
          "Club Mahindra Assonora (Restricted Access)",
          "Club Mahindra, Assonora (Restricted Access)",
          "Codal Trail (Madei WLS)",
          "Cumbarjua Canal",
          "Curca Saltpans",
          "Darghalim, Goa (OBI)",
          "Devlay- Khandola, Marcela",
          "Dhamshe Village",
          "Dhargalim, Pernem, Goa.",
          "Divar Island",
          "Dog temple pernem",
          "Dona Paula Beach",
          "Dona Paula Jetty",
          "DoubleTree by Hilton - Panaji",
          "Farmagudi Plateau",
          "Fort Aguada",
          "Ganjem bridge",
          "Ganjem Junction and Ganjeshwari Temple",
          "Ganpat Parsekar College of Education",
          "Goa College of Engineering",
          "Goa Institute of Management Campus (GIM), Sanquelim",
          "Goa State Biodiversity Board Campus",
          "Goa University Campus",
          "Goa Velha Mangroves",
          "Harvelim, Sanquelim",
          "IT Park Dona Paula, Panjim",
          "Kala Academy Environs",
          "Karapur, Sanquelim",
          "Korgao village",
          "Krishi Vigyan Kendra Campus",
          "La Goa Azul and surrounding area(Restricted Area)",
          "Mandrem Beach",
          "Mangeshi Temple and Surroundings",
          "Mayem Lake, Bicholim",
          "Meadow and hilly grasslands near sports stadium,Vadi Talaulim",
          "Merces Marshland",
          "MHADEI BIO REGION",
          "Mhadei Wildlife Sanctuary (IBA)",
          "Mhadei WLS- Chorla Ghat Section (IBA)",
          "Mhadei WLS : Kuntal - Krishnapur Trail",
          "Mhadei WLS--Surla Plateau (IBA)",
          "Mhovacho Guno",
          "Milroc Ribander Retreat (Housing Society)",
          "Miosal Dam and Surroundings",
          "Miramar- Caranzalem Beach",
          "Mojigao",
          "Morjim Beach",
          "Morle Gad Foothills",
          "Nagalli-Caranzalem Pond",
          "Narve-Tikhazan Marsh South",
          "National Institute of Oceanography",
          "Navelim Wetlands (IBA)",
          "Nerul River pathway",
          "Neura Wetlands",
          "New Goa International Airport, Mopa, Goa",
          "Nonorem Ground, (Madei WLS)",
          "Odxel",
          "olaulim backyard",
          "Outside Bondla Wildlife Sanctuary",
          "Outskirt of Mahadei Wildlife Sanctuary,Goa",
          "Ozran Beach",
          "Padeli--Mandovi River Bridge",
          "Paitona Fields",
          "Paliyem village",
          "Panarim Fields",
          "Patacho Vhal Bridge, Ibrampur, Goa, India",
          "Pernem Hills",
          "Pernem village",
          "Pether Tank",
          "Pilerne Fields",
          "Pilerne Lake",
          "Pilerne Plateau",
          "Poraskade marshlands, Pernem",
          "Porvorim Goa Housing Board and Journalist Colony",
          "Querim Beach",
          "Querim village",
          "Ragada River bridge",
          "reis margo fort rd",
          "Ribandar Causeway, Panjim",
          "Saligao Fields",
          "Saligao Plateau",
          "Saligao Zor (springs)",
          "Salim Ali Bird Sanctuary,Chorao",
          "Salvador do Mundo Fields",
          "Salvador Do Mundo Forest",
          "Santa Cruz Wetlands, Panaji",
          "Santo Minguel High School",
          "Satrem",
          "Satre Road",
          "Shikeri Rock Beach",
          "Shirgao, Bicholim",
          "Shiroda Mangroves",
          "Shri Lakshmi Narasimha Temple",
          "Shri Siddhanath Temple, Borim",
          "Sinquerim Beach",
          "Siolim Fields",
          "Siolim Hills",
          "Siridao Beach",
          "Socorro Plateau",
          "St. Cruze Wetland (South)",
          "St Estevam fields",
          "St Inez Creek & Surrounding, Panjim",
          "Sulabhat Lake,Pilar",
          "Swapnagandha Resort, Chorla Ghat",
          "Swapnagandha resort Chorla, Goa",
          "Talaulim-Tiswadi",
          "Tikhazan-Narve Marshes (West)",
          "Tiracol",
          "Torxem Wetlands",
          "Tropical Spice Plantation, Ponda Keri",
        ],
      },
      {
        district: "South Goa",
        localities: [
          "Agonda Beach GOA/IN",
          "Arabian Sea: 15.1N 73.7E",
          "Arabian Sea: 15.1N 73.8E",
          "Arabian Sea: 15.2N 73.7E",
          "Arabian Sea: 15.2N 73.8E",
          "Arabian Sea: 15.3N 73.6E",
          "Arabian Sea: 15.3N 73.7E",
          "Arabian Sea: 15.3N 73.8E",
          "Arabian Sea: 15.4N 73.5E",
          "Arabian Sea: 15.4N 73.6E",
          "Arabian Sea: 15.4N 73.7E",
          "Arabian Sea: 15.4N 73.8E",
          "Arossim Beach",
          "Arrossim Lake & surrounding",
          "Aximaddi Cacora Curchorem- Goa",
          "Aximaddi Cacora playground",
          "Bacbhat fields & surroundings",
          "Backwoods Camp, Tambdi Surla",
          "Backwoods Camp,Tambdi Surla",
          "Baida, Chinchinim,Salcete,Goa",
          "Bela Lake, Cotigao WLS (IBA)",
          "Bela lake road",
          "Benaulim Beach",
          "Benaulim Wetlands",
          "Betalbatim",
          "Betal Temple Road",
          "Betul Fort",
          "Bhagwan Mahaveer WLS and NP (IBBA)",
          "Bhagwan Mahaveer WLS & Mollem NP (IBA)",
          "Bhati,Sanguem",
          "BITS Pilani KK Birla Goa Campus",
          "Bomshal, Maina-Curtorim",
          "Borim bridge",
          "Butterfly Beach- Approach Trail",
          "Cabo-De-Rama Fort",
          "Cabo Serai",
          "Camorlim Marshes",
          "Canacona wetland ecosystem",
          "Canacona Wetland Ecosystem",
          "Cansaulim Beach",
          "Caravela Beach Resort",
          "Carmona Naval Firing Range",
          "Cavelossim Beach",
          "Chandreshwar Hill,Gudi,Paroda",
          "Chapoli",
          "Chicolna/Bogmalo",
          "Chicolna, Vasco da Gama",
          "Chinchinim Hill",
          "Church lake,Curtorim",
          "Club Mahindra Acacia Palms, Colva",
          "Codli Forest Area",
          "Colva Beach",
          "Colva Dry fish wholesale market",
          "Cotarli,Salaulim Canal",
          "Cotigao Wildlife Sanctuary (IBA)",
          "Cotigao WLS Gate,Hattipaul",
          "Cuncolim United HSS Campus",
          "Curpem Forested patch and surrounding areas",
          "Daddy's Home of Joy for Elderly and Surroundings",
          "Dando Plateau, Curtorim VP, Salcete, Goa.",
          "Dhoodhsagar waterfall goa",
          "Don Bosco College of Agriculture",
          "Dudhsagar Waterfalls",
          "Durga Lake & Surounding Chinchinim",
          "Edda, Cotigao Wildlife Sanctuary",
          "Edda-Nadke Road, Cotigao Wildlife Sanctuary",
          "Edda Waterhole",
          "Fatrade Beach",
          "Fields next to Assolna-Cavelossim Bridge",
          "Gate for the trek path",
          "GHS Balli",
          "Goa International Airport, Dabolim",
          "Gogol (OBI)",
          "Govt. College, Quepem",
          "Hills around Froggyland, Nuvem",
          "Igramol Quepem",
          "Kulem (Colem)",
          "Kuske Trail",
          "Loliem Plateau",
          "Loliem wetland",
          "Lotlee Marshes & Surrounding",
          "Lotoulim-Borim Bridge,Lotoulim",
          "Macazana lake",
          "Madel Trail, Uguem",
          "Madgaon, Goa (OBI)",
          "Maimollem Lake, Vasco",
          "Maina-Raia Wetlands",
          "maina wetlands",
          "Maitolle Lake & surrounding,Curtorim",
          "Majorda Beach",
          "Marmagoa Port, Vasco-da-gama (OBI)",
          "Mirabag Dhadem road",
          "Mobor Beach",
          "Mount Mary Higher Secondary School, Chinchinim-- Goa",
          "Nadke, Cotigao Wildlife Sanctuary",
          "Nagoa Fields",
          "Nanda Lake",
          "Naquerim,South Goa",
          "Natures Nest",
          "Natures Nest & Surrounding",
          "Netravali WLS",
          "Nuvem Bypass Junction",
          "NV Ecofarm, Dabal",
          "offshore waters, Goa, India (OBI)",
          "Pale velsao",
          "Palolem",
          "Parvatibai Chowgule College",
          "Pelagic trip off the coast of Goa (OBI)",
          "Quitla Plateau",
          "Rachol Bund",
          "Ragada River Bridge ,Sancordem",
          "Raitollem Lake",
          "Rajbag Beach",
          "Rivona",
          "Salauli Dam Environ,Ugem side",
          "Saljini",
          "Sancordem Bridge",
          "Santimol",
          "Sarzora lake",
          "Savonem-Udo wetland & surroundings, Raia",
          "Savri Waterfall",
          "SeaMist Colva village pond",
          "Seraulim Pond area",
          "Sernabatim Beach",
          "Sernabatim Fields",
          "Seven Temples, Veroda, Goa.",
          "Shabu Tollem, Velim, Salcete, Goa",
          "Shree Mallikarjun College Canacona",
          "Surla, Goa, IN (15.451, 74.21)",
          "Tallya-bandh, Igramol",
          "Talpona",
          "Tambdi Surla- Mahadev Temple and Surroundings",
          "Tanshikar Spice Farm",
          "THE KING'S SCHOOL",
          "Three Kings Chapel hill, velsao ,goa",
          "Tirwal Road, Cotigao WLS",
          "Tree top road",
          "Tree top road, Cotigao WLS",
          "Tulshimol, Cotigao WlS",
          "Udear Springs",
          "Uguem-Zuna",
          "Utroda Beach",
          "Vaco port area",
          "Varca Beach",
          "Varca-Fatrade Beach Road, Goa",
          "Vasco da Gama (OBI)",
          "Velim Marsh",
          "Velsao Beach",
          "Velsao Wetland & fields",
          "Verle-Saljini Road",
          "Verna Ambulor Lake",
          "Verna Industrial Estate Plateu",
          "Verna, South Goa (OBI)",
          "Veroda Bridge",
          "Veroda, Cuncolim, Salcete, Goa",
          "Xeldem",
          "Xelvon, Quepem",
          "ZAC Reservoir & Surrounding",
          "Zuari River",
        ],
      },
    ],
  },
  {
    state: "Gujarat",
    districts: [
      {
        district: "Jamnagar",
        localities: [
          "Balachadi Beach",
          "Bedi Port Rd",
          "Beyt Dwarka--Ferry Point",
          "Dhichda",
          "Dhinchada Lake",
          "Dhuvav lake",
          "Digvijay Saltpans",
          "Fulzar 2 Dam Daltungi",
          "Gagva Saltpans",
          "Jhankhar Lake",
          "Kharaberaja Dam",
          "Khatiya Vidi",
          "Khijadiya Bird Sanctuary",
          "Laher Talav, Naghedi",
          "Lakhota Lake",
          "Mahakaleshwar Temple, Sadodar (OBI)",
          "Marine NP--Pirotan Island",
          "Nageshwar-Momai Mata Temple Road (OBI)",
          "Nana Panchdevda Dam",
          "New Port",
          "Pancheshwar tower road",
          "Pond near Amran",
          "Rangmati Dam",
          "Rangpar Lake, Jamnagar",
          "Ranjitsagar Dam",
          "Reliance Industries Limited, Jamnagar (Restricted)",
          "Rozi Island, Jamnagar (OBI",
          "Ruparel Dam",
          "Rupavati Dam",
          "Sachana",
          "Sachana Beach",
          "Sadodar Village (OBI)",
          "Saltpans near Okha",
          "Saltpans on INS Valsura Rd",
          "Samana Road - Khatiya",
          "Sapda Dam",
          "Sasoi Dam",
          "Swaminarayan Nagar",
          "Und I Dam",
          "Und II Dam",
          "Valsura Road (OBI)",
          "Vibhapar",
          "Vodisang Dam",
          "Wetland near Jamnagar Railway Station",
        ],
      },
      {
        district: "Mahisagar",
        localities: [
          "Joraver Villa",
          "Kadana Hill View",
          "Kaleshwari Temple, Lavana",
          "Kanjara Wetland",
          "Near CEE Campsite, Bakor",
        ],
      },
      {
        district: "Sabarkantha",
        localities: [
          "Adam's Nature Retreat",
          "Ashapura Mataji Temple, Vadali",
          "Dharoi Dam",
          "Idar (OBI)",
          "Markandeshwar Mahadev Pond",
          "Polo Forest",
          "Polo Forest--Abhapur",
          "Poshina (OBI)",
          "Rani Lake",
          "Sabarkantha--Idar (OBI)",
          "Shri Vireshwar Mahadev",
        ],
      },
      {
        district: "Kheda",
        localities: [
          "Bherai Wetland",
          "Chanor--General Area (OBI)",
          "Damri Lake",
          "Gobarapura Reservoir",
          "Goblej Vadala",
          "Heranj Lake",
          "Khareti (OBI)",
          "Khari Catchment Wetland",
          "Kheda (OBI)",
          "Kheda--Shekhupur (OBI)",
          "Limbasi (OBI)",
          "Limbasi-Vastana Rd",
          "Mahelaj (OBI)",
          "Mahisagar River - Galteshwar Mahadev Temple",
          "Malarpur",
          "Matar Outskirts",
          "Meldi Mata Temple",
          "Nagarama TT",
          "Narda Wetland",
          "Navagam Bari",
          "Navagam Matar Rd",
          "Outskirts of Kheda (OBI)",
          "Pariej--Ecotourism Center",
          "Pariej Lake",
          "Pariej--Sayla",
          "Pariyej Lake extantion",
          "Salla, Traj village",
          "Shekhupur, (OBI)",
          "Vatrak Bridge Gadvel",
          "Wagh Bakri Tea Factory",
        ],
      },
      {
        district: "Porbandar",
        localities: [
          "Bardasagar Dam",
          "Barda WLS--General",
          "Barda WLS--Khambhala Gate",
          "Barda WLS--Satvirda Nes",
          "Bhadarbara Wetland",
          "Chhaya Rann wetland",
          "Fodara Dam",
          "Javar Wetland",
          "Kalindri Dam",
          "Kuchdi, Porbandar (OBI)",
          "Kuchhadi",
          "Madhavpur Beach",
          "Mendha Creek",
          "Mokarsagar Wetland Complex",
          "Mokarsagar Wetland Complex--Dharampur",
          "Mokarsagar Wetland Complex--Gosabara Creek",
          "Mokarsagar Wetland Complex--Karli",
          "Mokarsagar Wetland Complex--Vanana",
          "Porbandar Bird Sanctuary",
          "Porbandar (OBI)",
          "Subhashnagar wetland",
        ],
      },
      {
        district: "Chhota Udaipur",
        localities: [
          "Bhabhar Village",
          "Jambughoda WLS",
          "Jambughoda WLS--Targol Range",
          "Kevdi Eco Campsite",
          "Moti Khandi --Hill Range",
          "Sukhi Dam",
        ],
      },
      {
        district: "Mehsana",
        localities: [
          "Dabhala (OBI)",
          "Kanjari Deer Park",
          "Kanjari Deer Sanctuary",
          "Modhera Sun Temple River side",
          "Outskirts of Ahmadabad (OBI)",
          "Rushivan Sant Samadhi Dham, Pilvai",
          "Shankus waterpark",
          "Taranga Hill Wildlife Sanctuary",
          "Thol Bird Sanctuary",
          "Thol--Kanjari Canal",
          "Thol Lake View, Dipeshwari Temple",
          "Village Kadi Road (OBI)",
          "Vishal's home",
        ],
      },
      {
        district: "Bhavnagar",
        localities: [
          "Airport road, Bhavnagar, Gujarat, IN (21.76, 72.175)",
          "Along Gopnath Beach",
          "Avaniya Lake",
          "Avaniya Thorny Forest",
          "Bhavnagar University",
          "Bor Talav (Gaurishankar Lake)",
          "Daripara Dam",
          "Gangajalia Talav (Lake)",
          "Gautam Sarovar",
          "Gopnath",
          "Gorad Smashan",
          "Haathab Beach",
          "Haathab Bunglow Rd",
          "Hills surrounding Malnath Mahadev Temple",
          "Juna Bandar (Old Port)",
          "Kareda Dam",
          "Khodiyar Dam near Rajpara",
          "Kobadi-Ukharala Rd",
          "Krishnakunj Lake",
          "Kuda Beach",
          "Kuda Sikotarma, Kuda, Gujarat, IN (21.627, 72.298)",
          "Kumbharwada wetland",
          "Lake near Bhavnagar University",
          "Lake on New Port - Airport Rd",
          "Lakhanka Dam",
          "Malan Bandhara",
          "m.k.bhavnagar university, Bhavnagar, Gujarat, IN (21.754, 72.132)",
          "M.K. Bhavnagar University, Department of Life Sciences",
          "Nagdhanimba Dam",
          "Nava Bandar (New Port) Rd",
          "Nikol Bandhara",
          "Palitana Hill",
          "Pingaleshwar Beach near Mahuva",
          "Pond on Nawa Bandar- Airport Rd",
          "Prabudas Talav",
          "Saltpans on Ghogha Rd",
          "Shetrunji Dam",
          "Sonariya Lake",
          "Tank near Ravechimata Temple",
          "Vankiya Hanuman Ashram Vicinity",
          "Velavadar NP",
          "Velavadar NP--Alang River",
          "Victoria Park",
          "Wetland in Velavadar NP",
        ],
      },
      {
        district: "Tapi",
        localities: [
          "Govad Dev Temple--Kanjan",
          "Govaldev area, Kaanjan Village (OBI)",
          "Kevdi forest, Mandvi",
          "Madhi ni Khamani, Bajipura",
          "Ukai Reserve Forest",
        ],
      },
      {
        district: "Bharuch",
        localities: [
          "Ankleshwar, Bharuch (OBI)",
          "GNFC lake",
          "Juna Diva Road",
          "Kabirvad",
          "Kavi Beach",
        ],
      },
      { district: "Aravalli", localities: ["Katkuva", "Meswo Reservoir"] },
      {
        district: "Gir Somnath",
        localities: [
          "Aranya - a nature's paradise",
          "Asiatic lion lodge",
          "Creek near Diu Entry",
          "Gir Birding Lodge",
          "Gir Forest--Bhojde",
          "Gir Interpretation Zone (Devaliya Park)",
          "Gir Wildlife Sanctuary--Batheshwar Campsite",
          "Gir Wildlife Sanctuary--General",
          "Gir Wildlife Sanctuary--Jamvala outskirts",
          "Gir Wildlife Sanctuary--Kamleshwar Dam only",
          "Gir Wildlife Sanctuary--Kankai Temple",
          "Gir Wildlife Sanctuary--Route 6/2",
          "Gir Wildlife Sanctuary--Sasan",
          "Gir Wildlife Sanctuary--Tulsishyam Temple",
          "Hadmtiya Gir",
          "Hiran River",
          "Hiran River near Hotel Anil Farm",
          "Lion Safari Camp",
          "Prashnavada Wetland",
          "Reserve forest behind GIZ-Devaliya",
          "Shingoda Dam",
          "Sinhsadan",
          "Somnath Beach",
          "Sutrapada",
          "Tal, near Una (OBI)",
          "Triveni Sangam",
          "Veraval Beach",
          "Wetland on Diu - Una Rd",
        ],
      },
      {
        district: "Navsari",
        localities: [
          "Aat gam to Dandi",
          "Abrama Talav, Navsari",
          "Anklachh",
          "Chikhali (OBI)",
          "Devsar",
          "Gandeva",
          "Gandevi",
          "Kotha Talav",
          "Nani Kakrad",
          "Navsari Agricultural University (NAU)",
          "Navsari Railway Station",
          "Soldhara, Valsad (OBI)",
          "Sultanpur Wetland",
          "Tavdi",
          "Vadi Lake",
          "Vansda--Gadhvi & Jamanpada Area",
          "Vansda NP-- Outskirts",
        ],
      },
      {
        district: "Narmada",
        localities: [
          "Gadhar",
          "Gujarat Forest Rangers College Campus",
          "Sardar Patel Zoological Park",
          "Sardar Sarovar dam",
          "Shoolpaneshwar WLS",
          "Shoolpaneshwar WLS--Junaraj",
          "Shoolpaneshwar WLS--Kokam Hanuman Temple",
          "Shoolpaneshwar WLS--Sagai Eco Campsite",
          "Shoolpaneshwar WLS--Vishal Khadi Eco Campsite",
          "Shoolpaneshwar WLS--Zarvani Range",
          "Statue of Unity",
          "Zarwani Waterfall",
        ],
      },
      {
        district: "Banaskantha",
        localities: [
          "Aravaali trails",
          "Balaram Ambaji WLS",
          "behind Banas Dairy, Banaskantha (OBI)",
          "Dantiwada Dam (OBI)",
          "Hathindra (OBI)",
          "Jessore Sloth Bear Sanctuary",
          "Mahajan Gaushala (OBI)",
          "Patan (OBI)",
          "Ranitook, Dantiwada (OBI)",
          "Sardarkrushinagar Dantiwada Agricultural University",
          "Sipu dam",
          "Vav (OBI)",
        ],
      },
      {
        district: "Dang",
        localities: [
          "Breeding Centre, North Dang",
          "Dand Forest--Bhenskatri",
          "Dang Forest--Bardipada",
          "Dang Forest--Gira-Kudkas Rd",
          "Dang forest--Koshmal",
          "Dang Forest--Maa Shabri Farm House and Resort",
          "Girmal Waterfall",
          "Kilad Campsite",
          "Mahal Campsite",
          "Purna WLS",
          "Saputara, Gujarat (OBI)",
          "Vansda NP",
          "Waghai",
          "Waghai--Botanical Garden",
          "Waghai -- Gira Falls",
          "Waghai outskirts",
        ],
      },
      { district: "Botad", localities: [] },
      {
        district: "Gandhinagar",
        localities: [
          "Adalaj Stepwell",
          "Akshardham Temple",
          "Aranya Udhyan",
          "Butterfly park",
          "Dabhla Panjarapole",
          "DAIICT Campus",
          "Gatehara Lake",
          "GEB Ash Pond, Pethapur",
          "Gopal Lake",
          "Gujarat Forest Research & Training Institute",
          "Indian Institute of Technology (IIT) Gandhinagar, Palaj campus",
          "Indroda Nature Park",
          "Karai near Sabarmati river",
          "Matulya farm",
          "Narmada canal",
          "Narmada Canal, Karai",
          "NIF-Gandhinagar",
          "Nilkanth Mahadev",
          "Ograj, Lapkaman (OBI)",
          "Pandit Deen Dayal Energy University, Gandhinagar",
          "Pandit Deendayal Petroleum University Campus, Raysan",
          "Pandit Dindayal Park",
          "PDPU Bridge",
          "Punit Van",
          "Rancharda Lake",
          "Sabarmati Bank near PDPU",
          "Sabarmati River--Indira Bridge",
          "Sabarmati River near IIT Palaj Campus",
          "Sant Sarovar",
          "Sardar Bag",
          "Sherisa Outskirts",
          "The Serenity Library",
          "Wetland opposite Adhana Village pond",
          "Zala Darbar Pond",
        ],
      },
      {
        district: "Valsad",
        localities: [
          "Ambavadi, vashiyar",
          "Atul Colony",
          "Bilimora-Valsad Coastal Hwy",
          "Dharasana (OBI)",
          "Dharasana Wetland (OBI)",
          "GMERS Medical College, Valsad",
          "Goima",
          "Kalai Beach",
          "Kalai Lake",
          "Lilapore Lake",
          "Pardi Grassland",
          "Parnera Hills",
          "Udvada Beach",
        ],
      },
      {
        district: "Patan",
        localities: [
          "Balisana, Patan (OBI)",
          "Dharpur--General Area (OBI)",
          "Garamadi (OBI)",
          "Garamadi Wetland",
          "GMERS Medical College Campus",
          "Khokhla Talav",
          "Little Rann of Kachchh--Koddha",
          "Masali (OBI)",
          "Rani Ki Vav",
          "Siddhapur (OBI)",
          "Sidhada Village Tank",
          "Tank near Tekariya Hanumanaji Temple, Shankheshwar",
        ],
      },
      {
        district: "Vadodara",
        localities: [
          "Amodar Village Lake",
          "Ankhol Lake",
          "Ankodiya lake, Vadodara",
          "Balaji Township",
          "BK Patel Arts & LM Patel Commerce College",
          "GSRTC ST Depot",
          "Gujarat Engineering Research Institute",
          "Hanumanpura Lake",
          "Harni Tank",
          "Historical Place Vadodara",
          "Jarod Wetlands",
          "Javla Lake",
          "Kirti Mandir",
          "Kotna beach, vadodara",
          "Lukshmi Vilas Palace",
          "Maharaja Sayajirao University (MS University) Campus",
          "Manilakshmi Tirth",
          "Navrachana University Campus",
          "Orsang Gamdi Resort",
          "Prince Ashokraje Gaekwad School",
          "Qutubuddin Tomb",
          "Ravines Eco Park",
          "Sai Villa Apartment",
          "Sayaji Baug",
          "Sevasi Step Well",
          "Sherkhi Hideout",
          "Sindhrot Nature Education Park",
          "The Maharaja Sayajirao University of Baroda",
          "Timbi Lake",
          "Timbi Point 1",
          "Vadhavana Lake, Dabhoi",
          "Vadodara (OBI)",
          "Valvod Island",
          "Vasundhara Park Society",
          "Vishwamitri--Sama",
          "Vyas Bet",
          "Zankeshwar, near Dabhoi (OBI)",
        ],
      },
      {
        district: "Kachchh",
        localities: [
          "Adesar Bridge",
          "Anjar Municipalty Garden",
          "Asar Mata Temple Beach",
          "Babiya Lake",
          "Balapar Pond and Marshes",
          "Balasar Heronry",
          "Banni Grassland",
          "Banni grassland--Bhagadiya Thath",
          "Banni Grassland (OBI)",
          "Banni Grasslands--Hodka",
          "Banni Grasslands--Hodka-Dhordo Rd",
          "Banni Grasslands--Lodai",
          "Bhachau, Kutch (OBI)",
          "Bhadreshwar Beach",
          "Bhed Mata Scrub Forest",
          "Bhimasar Lake",
          "Bhimsar Lake",
          "Bhuj Anjar Road (OBI)",
          "Bhuj--General Area",
          "Bhuj-Khavda Road (OBI)",
          "Bhukhi Dam",
          "Bhukhi river bridge, Devisar",
          "Center for Desert and Ocean (CEDO)",
          "Centre for Desert and Ocean, Kutch (OBI)",
          "Chadva Rakhal",
          "Chavadka Dam",
          "Chhari Dhandh Conservation Reserve",
          "Chhatardi Lake",
          "coast of Kutch (OBI)",
          "Dam near Mothala",
          "Devisar Tank",
          "Devisar village and Khandavara pir,Banni,Kutch",
          "Devsar Dam",
          "Dhamma Sindhu Vipassana Meditation Centre",
          "Dhaneti Lake",
          "Dhinodhar Hill",
          "Dhinodhar Hill and Tropical Thorn Forest",
          "Dhonsa Lake",
          "Dhordo Road, near Khavda (OBI)",
          "Dhrabudi Beach",
          "Dhrang-Lodai Road",
          "Dhunaraja Dam",
          "Dilip Khatau's vadi (farm), Nani Aral (OBI)",
          "Epicenter Homestay, Lodai, Gujarat",
          "Fatelo Talav, Bhuj (OBI)",
          "Fot Mahadev Scrub Forest",
          "Fulay, Kutch",
          "Gadhsisa",
          "Gorasar Tank",
          "Great Rann of Kachchh--Dholavira",
          "Great Rann of Kachchh--Dhordo",
          "Great Rann of Kachchh--General Area",
          "Great Rann of Kachchh--Khadir Beyt Rd",
          "GRK Near Kutch Fossil Park",
          "Gujarat Institute of Desert Ecology",
          "Gulf of Kutch (OBI)",
          "Hamirsar Lake",
          "Hobay-Lodai Road",
          "Infinity Resort",
          "IOCL WRPL Mundra",
          "Jakhau",
          "Jakhau salt pans",
          "Jatawada (OBI)",
          "Kachchh--Bhuj",
          "Kalo Dungar (OBI)",
          "Kalo Dungar--Walking trail",
          "kashi Vishwanathe Beach, Mandvi",
          "Kasvati Dam",
          "Khari Nadi, Bhuj (OBI)",
          "Khatri Talab",
          "Khavda-Dholovira Road",
          "Khavda Road, Kutch (OBI)",
          "Khirjog Dhandh",
          "Khorai Water Body",
          "Kukma lake",
          "Kunathiya (OBI)",
          "Kunj Homestay and Surroundings",
          "Kutchch--General Area (OBI)",
          "Kutch Fossil Park",
          "Lakhapur, Gujarat",
          "Lakhpat Fort",
          "Ler Dam",
          "Little Rann of Kachchh",
          "Little Rann of Kachchh--Bajana Entry",
          "Little Rann of Kachchh--Kharaghoda entry",
          "Little Rann of Kachchh--Kuda",
          "Little Rann of Kachchh--Melak Beyt",
          "Little Rann of Kachchh--Nanda Bet",
          "Little Rann of Kachchh--Surajbari Bridge",
          "Little Rann of Kachchh--Vachchhraj Beyt",
          "Little Rann of Kachchh--West Part",
          "Little Rann of Kachchh--Zainabad Edge",
          "Luna Jheel",
          "Madhapur, Kutch (OBI)",
          "Mandvi Beach",
          "Mata no Madh",
          "Mata no Madh Dam",
          "Mitti Dam",
          "Modhva Beach",
          "Modhva Road",
          "Moti Aral",
          "Moti-Virani, Kutch (OBI)",
          "Moti Virani (OBI)",
          "Mundra--General Area (OBI)",
          "Mundra Port/coast",
          "Nadabet",
          "Nadabet--Zero Point road",
          "Nagasar Lake",
          "Nakhatrana--General Area (OBI)",
          "Naliya Grasslands",
          "Naliya Grasslands--Kunathiya",
          "Nani Aral, Moti Aral, Gichado village, and Layari river side, Banni",
          "Nara Dam",
          "Narayan Sarovar",
          "near Jatavira, Kutch (OBI)",
          "Ningal Lake and Nursery",
          "Nirona Dam",
          "Outskirts of Bhuj (OBI)",
          "Paddhar Lake 1",
          "Paddhar Lake 2",
          "Pakhado Hill",
          "Pingleshwar Beach",
          "Piyoni Mahadev",
          "Piyoni Village, Nakhatrana (OBI)",
          "Pond near Kothara",
          "Pond near Navi Chobari",
          "Pond on Kharoi-Bharudiya Rd",
          "Port Gate, Mandvi",
          "Rann of Kutch Lake",
          "Ratnal Tank",
          "Rava Bityari, Kutch (OBI)",
          "Rawalpir Dargah Thorn Forest",
          "Rodasar, Kutch (OBI)",
          "Roha Fort Forest (OBI)",
          "Rudramata Bridge",
          "Rudramata Dam",
          "Rukmavati River",
          "Saiyadpar Rakhal",
          "Samanda",
          "Samatra Tank",
          "Sang River",
          "Shakoor lake",
          "Sharan Nath Temple",
          "Sun Temple, near Lodai (OBI)",
          "Tapkeshwari Temple",
          "Than Jagir",
          "Topansar Lake",
          "Vaghura Dam",
          "Van Chetna, Bhuj",
          "Vandhay Tank",
          "Vang, Kutch (OBI)",
          "Vekariya Dhandh",
          "Vijay Sagar Dam",
          "Vijay Vilas Palace",
          "Vijay Vilas Palace Beach",
          "Village Phulai, near Moti Virani (OBI)",
        ],
      },
      {
        district: "Morbi",
        localities: [
          "Garida Dam",
          "Grassland near Machu Dam 1",
          "Little Rann of Kachchh--Jogad",
          "Machu Dam 1",
          "Machu  Dam 2",
          "Nani Barar",
          "near Morbi (OBI)",
          "Paneli Vidi (OBI)",
          "Pond on Navlakhi Port Rd",
          "Rampara Wildlife Sanctuary",
          "Saltpans on Navlakhi Port Rd",
          "Samantsar Lake, Halvad",
        ],
      },
      {
        district: "Amreli",
        localities: [
          "Ambardi Safari Park, Dhari",
          "Dhari-Amreli Rd",
          "Dhatarwadi Dam",
          "Facharia Dam",
          "Gir Retreat Resort, near Dhari (OBI)",
          "Gir Sanctuary & NP--Shemardi Checkpost",
          "Hanuman Gala Temple",
          "Khambha",
          "Khavda Road, near Vekariya (OBI)",
          "Khodiyar Dam",
          "Mitiyala (OBI)",
          "Ramnath Mahadev Temple",
          "Saladi Tank",
          "Saltpans near Jafrabad",
          "Thebi Dam",
          "Thordi Lake",
          "Victor Saltpans",
          "Zinzuda Dam",
        ],
      },
      {
        district: "Panchmahal",
        localities: [
          "Bhat Ecocamp",
          "Dem Dam Wetland Complex--Moti Ranbhet",
          "Dev Dam Wetland Complex--Gariyal 1",
          "Dev Dam Wetland Complex--General Area",
          "Dev Dam Wetland Complex--Mota Ankediya",
          "Dev Dam Wetland Complex--Nani Ranbhet",
          "Dev Dam Wetland Complex--Sudhra",
          "Dev Dam Wetland Complex--Vaghbod",
          "Godhara, Gujarat (OBI)",
          "Jambudi Village Pond",
          "Jambughoda--Kada Dam Tent Site",
          "Jambughoda WLS--Bhat",
          "Jay AMBE Industries",
          "kakanpur instructional farm Anand Agril University",
          "Khuneshwar Mahadev",
          "Leopard's Den Home Stay",
          "Malav Talav",
          "Palli Forest",
          "Pavagadh Hills",
          "Pavagadh Hills--Sultanpura",
          "Rameshwar Talav",
          "Shivam Society",
          "Vadatalav Lake",
        ],
      },
      {
        district: "Devbhumi Dwarka",
        localities: [
          "Aniyari Pond",
          "Barda WLS--Kileshwar Temple",
          "Beyt Dwarka",
          "Beyt Dwarka Beach--Dunny Point",
          "Beyt Dwarka--Sundarvan",
          "Bhimrana (OBI)",
          "Charkhala Salt Pans",
          "Dwarka Beach",
          "Ghee Dam",
          "Gomti River Bank",
          "Gulabsagar Dam",
          "Limbadi (OBI)",
          "Marine NP--Poshitra Beach",
          "Marine NP--Poshitra Lakku Point",
          "Mithapur Township",
          "Mulvel Wetland",
          "Nageshwar",
          "Narara Marine NP",
          "Okha Madhi Beach",
          "Okha, near Dwarka (OBI)",
          "Pindara",
          "Ranjitpur Tank",
          "Ranjitpur Village Pond",
          "Saltpans near Vadinar",
          "Sani Dam",
          "Sinhan Dam",
          "Tata Mangrove Plantation, Rukmani Temple",
          "Toran Totel, Dwaraka",
          "Vadatra Tank",
          "Vartu I Dam",
          "Vartu II Dam",
          "Veradi I Dam",
          "Veradi II Dam",
        ],
      },
      {
        district: "Rajkot",
        localities: [
          "Aalan Sagar Dam",
          "Aji-1 Dam",
          "Aji-2 Dam",
          "Baghi swamps",
          "Bhadar Dam",
          "Bhootnath Mahadev Temple",
          "Bushland Reserve, Zalansar",
          "Causeway on Nyari river & Khambhala Lake",
          "Fofal Dam",
          "Fofal Dam--Canal",
          "Fofal Dam--Kuvadva",
          "Fofal River--Ambardi",
          "Fofal River--Sajadiyali",
          "Hanumandhara Temple",
          "Hingolgadh Nature Education Sanctuary",
          "Ishwariya Lake",
          "Khambhala Vidi",
          "Khirasara",
          "Khirasara Vidi",
          "Lalpari Lake",
          "Nyari-1 Dam",
          "Nyari-2 Dam",
          "Nyari-Khirasara Birding Corridor",
          "Nyari River, Rajkot (OBI)",
          "Patanvav Hills",
          "Pradhyuman Zoological Park",
          "Rajkot, Gujarat (OBI)",
          "Rajkot--Ishwaria (OBI)",
          "Randarda Lake",
          "River, Sajadiyali",
          "RK University Campus",
          "RK University Campus, Tramba",
          "Saurashtra University Campus",
          "The Hera Weekend House",
          "Umath Vidi, Hingolgarh",
          "Vadodar Tank",
          "Vagudad Road",
          "Valadungra Reservoir",
          "Veri Dam",
          "Wetland near Gondal, Rajkot (OBI)",
        ],
      },
      {
        district: "Surendranagar",
        localities: [
          "Bhaskarpara Narmada Canal Area",
          "Bhaskarpura Wetland",
          "Chotila Reservoir",
          "Dharam Talav",
          "Dhrangadhra Cantt",
          "Falku Reservoir",
          "Hebatpur, Gujarat",
          "Kamlapar Village Tank",
          "Kharva Village (OBI)",
          "Little Rann of Kachchh--Bajana Creek",
          "Little Rann of Kachchh--Bhavna Resort & Farm",
          "Little Rann of Kachchh--Chikasar",
          "Little Rann of Kachchh--Desert Coursers",
          "Little Rann of Kachchh--Mithaghoda",
          "Little Rann of Kachchh--Nava Talav",
          "Little Rann of Kachchh--Patdi Lake",
          "Little Rann of Kachchh--Zinzuwada entry",
          "Nalsarovar Bird Sanctuary",
          "Nalsarovar--Ranagadh",
          "Nayakpur Saran Wetland",
          "Olak",
          "Randal Mandir, Moti Moldi",
          "Rann Riders, Dasada",
          "Vadala Dam",
          "Vadla Dam (Sukhdisar Bird Migration Point)",
          "Vithalgadh (OBI)",
          "Wadhwan (OBI)",
          "Wild Panchal campsite",
        ],
      },
      {
        district: "Dahod",
        localities: [
          "Dahod Forest Cultivation Area",
          "Dahod (OBI)",
          "Dasla wetland",
          "Kali Dam",
          "Limkheda Outskirt",
          "Muvalia Dam",
          "Rampura Grasslands",
          "Ratanmahal WLS",
          "Ratanmahal WLS--Kanjeta",
          "Wankleshvar Dam",
        ],
      },
      {
        district: "Junagadh",
        localities: [
          "Amarapur Gir (OBI)",
          "Amipur Dam",
          "Balaji Park Society",
          "Dadhichi Lake",
          "Girnar Forest--Chikhaliya Farm",
          "Girnar Forest--Jatashankar",
          "Girnar Forest--Kashmiri Bapu ashram",
          "Girnar Forest--Lal dhori",
          "Girnar Forest--Narayan Dharo",
          "Girnar Forest--Velnath",
          "Girnar Forest--Zinabava ni madhi",
          "Girnar Nature Safari",
          "Girnar WLS",
          "Junagadh Agricultural University",
          "Junagadh Agriculture University Campus",
          "Junagadh (OBI)",
          "Madhuvanti Dam",
          "Narsinh Mehta Lake",
          "Nataliya Vidi",
          "Sarkhadiya Hanuman Temple",
          "Upperkot Fort",
          "Vadla Lake",
          "Willingdon Dam",
        ],
      },
      {
        district: "Surat",
        localities: [
          "Ahuranagar society",
          "Arogya Seva Mandir",
          "Ashwini Kumar Rd",
          "Barbodhan Lake",
          "Bhulka Vihar School",
          "Canal Road",
          "Causeway Rd, Surat",
          "Damka",
          "Department Of Biosciences, VNSGU",
          "Dumas Beach",
          "Eco Farm (NCS)",
          "Gaviyer Lake",
          "Isar Dam",
          "Kavi Kalapi Lake Garden",
          "Kevdi Eco tourism site",
          "Kevdi, Mandvi Taluka (OBI)",
          "Khajod (OBI)",
          "Kumkotar Forest",
          "Kumkotar Forest -- Bridge over Koskhadi River",
          "Magdalla Port Surat",
          "Maya Talav",
          "Mora Lake",
          "N.G. Patel Polytechnic Campus",
          "Port Surat",
          "Raghipura Forest",
          "Sarthana Nature Park and Zoo",
          "Satkeval Temple Hill, Goddha",
          "SMC Multi- Activity Zone",
          "Steneshwar Mahadev Temple, Tena",
          "Surat International Airport",
          "Suvali Beach",
          "Swami Vivekanand Bridge (Makai Pool)",
          "Tapti River Surat",
          "Tena-Hazira Rd",
          "Tena Lake",
          "Vibrant School of Excellence",
          "Waste Disposal Site, Khajod",
        ],
      },
      {
        district: "Anand",
        localities: [
          "Anand Junction",
          "Baroque Pharma (OBI)",
          "Botanical Garden - Sardar Patel University",
          "Bridge on Sabarmati River, Vataman-Tarapur Rd",
          "Dabhou Lake",
          "Dabhou - Malataj Rd",
          "Devataj",
          "FES Campus",
          "Gulf of Khambhat (OBI)",
          "IRMA Campus",
          "Juni Akhol",
          "Kaneval Lake",
          "Khambhat (OBI)",
          "Lambhvel Tank",
          "Mahi Check Dam at Sindhrot",
          "Mahisagar River Bed, Vadodara (OBI)",
          "Malataj Lake",
          "Mogri Talav",
          "Navagambara",
          "NDDB Campus",
          "Rel",
          "Rohni",
          "Rustic Villa ( Mogar )",
          "Surajba Park",
          "Tarakpur Dam",
          "Varasda Lake",
          "Vishal's birding ditch",
        ],
      },
      {
        district: "Ahmedabad",
        localities: [
          "Adani Shantigram Residential Area",
          "Ahmedabad Cantonment",
          "Ahmedabad University",
          "Ambli road, Bopal",
          "Asarwa Lake",
          "Astronomy Club",
          "ATIRA - Ahmedabad Textile Industry's Research Association",
          "AUDA GARDEN",
          "Bagodara (OBI)",
          "Bavla",
          "Bavla Old Mill OBI",
          "Bavla Taluka (OBI)",
          "BhavanÃ¢â‚¬â„¢s Sheth R A College of Science",
          "Bhogawo River near Fedara",
          "Centre for Environment Education (CEE)",
          "CEPT University, Ahmedabad (Restricted)",
          "Chittvan Society",
          "C.N. College Of Fine Arts Campus",
          "Dafnala",
          "Devpujan Apartment",
          "Divan Ballubhai High School campus",
          "Divan Ballubhai Secondary School",
          "Doordarshan , Ahmedabad",
          "Eklavya School",
          "Empty Plot near Hiraba Farm",
          "Gangasar Lake Viramgam",
          "Godhavi (OBI)",
          "Gopi Vallabh Avenue",
          "Gotila Auda Garden",
          "Greenvalley Flats",
          "Gujarat Cancer Society Medical College, Ahmedabad (OBI)",
          "Gujarat University",
          "Gujarat University Boys Hostel",
          "IIM, Ahmedabad (OBI)",
          "ISRO Colony, Vastrapur",
          "Kalhaar Blues and Greens Golf CLub",
          "Kamla Nehru Zoo",
          "Kankaria Lake",
          "Karnavati Club",
          "Kocharab Ashram",
          "Koteshwar Temple near Sabarmati River",
          "Lalbhai Dalpatbhai Museum",
          "Lapkaman (OBI)",
          "Law Garden",
          "Manekbaug Society Garden",
          "M. G. Science Institute",
          "Monarch City 2",
          "Moti Kishol",
          "Moti Kumad",
          "Moti Kumad, Viramgam",
          "Nalsarovar--Boating Point",
          "Nalsarovar--Chaurathali",
          "Nalsarovar--Chokdi",
          "Nal Sarovar Chowkdi, Kitli",
          "Nalsarovar--Flamingo Sankul",
          "Nalsarovar Outskirts",
          "Nalsarovar Outskirts--Adroda",
          "Nalsarovar Outskirts--Aniyali",
          "Nalsarovar Outskirts--Shahpur",
          "Nisarg, near Sanand (OBI)",
          "Outskirts of Ahmedabad (OBI)",
          "Parimal Garden",
          "Physical Research Laboratory Hostel Campus, Navrangpura",
          "Physical Research Laboratory (PRL) Thaltej Campus",
          "Pond, Bhavnagar-Pipali Highway, Before Velavadar NP Turn",
          "Pond near Shela",
          "Rachana School Campus",
          "Rasala Nature Park",
          "Ratna Lake",
          "Riverfront Flower Park, Ahmedabad",
          "Sabarmati Ashram",
          "Sabarmati Riverfront near Buddha Statue, IN (23.055, 72.577)",
          "Sabarmati Riverfront--Usmanpura",
          "Sabarmati Riverfront--Vasna Barage B1",
          "Sabarmati River near Motera Stadium",
          "SAC-ISRO Bopal Campus",
          "Saint Xaviers School",
          "Sakshat Apartments",
          "Sanmukh Apartments",
          "Sardar Patel Institute Of Economic And Social Research (SPIESR)",
          "Sardar Vallabhbhai Patel Airport Ahmedabad",
          "Sardar Vallabhbhai Patel National Memorial",
          "Sarkhej Roza",
          "Saroda village (OBI)",
          "Satyagrah Chhavani",
          "Shela Tank",
          "Sheth C.N. Vidyavihar",
          "Shilaj Village (OBI)",
          "Shreyas Foundation",
          "Shreyas Tekra (OBI)",
          "Silver Gardenia, Gota",
          "Sivanta Apartments",
          "SKUM School Campus",
          "Smarana",
          "SOBO Centre",
          "Someshwar Row House-1",
          "Space Application Centre-ISRO",
          "St Kabir School, Memnagar",
          "St. Xaviers College (Autonomous)",
          "Sundarvan Nature Discovery Centre",
          "Suprabh Apartment",
          "Swareet Apartments",
          "Sweet Home Society (OBI)",
          "Tea Factory",
          "Thori Mubarak",
          "Vaagad, Kutch (OBI)",
          "Vagad, Kutch (OBI)",
          "Van Chetana Kendra",
          "Vasai Wetland",
          "Vastrapur Lake Garden",
          "Vejalpur, Ahmedabad (OBI)",
          "Vikram Nagar Housing Colony",
          "Viramgam, Ahmedabad (OBI)",
          "Viramgam Panjrapol",
          "Virpur Panjrapol Carcass Dump",
          "Vulture Point",
          "way to Thol from Nalsarovar (OBI)",
          "Wetland Near Vasana",
          "Zamp, near Sanand (OBI)",
        ],
      },
    ],
  },
  {
    state: "Haryana",
    districts: [
      { district: "Fatehabad", localities: [] },
      {
        district: "Karnal",
        localities: [
          "Karna Lake, Karnal,  Hr/IN",
          "Karnal Railwsy Station",
          "NDRI Karnal IN/HR",
        ],
      },
      { district: "Charki Dadri", localities: ["Birhi Kalan, Bhiwani IN/HR"] },
      {
        district: "Yamunanagar",
        localities: [
          "Ch. Devi Lal Herbal Nature Park, Khizrabad, Haryana",
          "Hathni Kund Barrage IN/HR",
          "Jagadhri, Yamuna Nagar, IN/HR",
          "Kalesar National Park Haryana",
        ],
      },
      {
        district: "Ambala",
        localities: [
          "Ambala Cantt",
          "Ambala Cantt Railway Station",
          "Matheri Sheikhan Village (OBI)",
        ],
      },
      {
        district: "Jhajjar",
        localities: [
          "Bhindawas Bird Sanctuary",
          "Chhochhi, Dhigal  IN/HR",
          "Dhaur Village, Jhajjar HR/IN",
          "Dighal",
          "Ismailpur Jhajjar IN/HR",
          "Jajjhar, Haryana (OBI)",
          "Jhajjhar (towards bhindawas)",
          "Jharli village Jhajjar IN/HR",
          "Mandothi",
          "Matanhale Jhajjar IN/HR",
          "Mundakhera Flat -- Jhajjar",
          "NTPC Jharli IN/HR",
          "Suhara Village (OBI)",
        ],
      },
      {
        district: "Rohtak",
        localities: [
          "Baland Rohtak",
          "Ghudan, Rohtak HR/IN",
          "IN-Haryana-Baland-Firani Road - 28.804x76.579",
          "Karor Village (OBI)",
          "maina pond rohtak IN/HR",
          "Pakasma, Rohtak, Haryana",
          "Sampla Hr/IN",
          "Scrub Forest near Dighal Haryana",
          "Tilyar Lake Rohtak IN/HR",
        ],
      },
      {
        district: "Palwal",
        localities: [
          "KG Wetland, Palwal",
          "Mandkaula Wetlands (Palwal)",
          "Mandnaka Village (OBI)",
          "MJ 1, Palwal (OBI)",
          "Palwal, Haryana (OBI)",
          "Palwal Railway station",
        ],
      },
      { district: "Kurukshetra", localities: ["Thana Lake, Kaithal IN/HR"] },
      {
        district: "Sonipat",
        localities: [
          "Abbas pur Sonipat  Hr/IN",
          "Ashoka University",
          "DRAIN NO 8 Gudha Hr/IN",
          "Gohana Canal haryana",
          "Mohamedabad marsh, Rohat village Sonepat (Haryana)",
          "OP Jindal Global University, Sonipat, IN/HR",
          "Samboli Village, Sonepat (OBI)",
          "Sonepat, Haryana (OBI)",
          "Sonepat Railway Station",
          "Thana Kalan Sonipath IN-Haryana",
        ],
      },
      { district: "Jind", localities: ["Kalwan village district Jind"] },
      { district: "Kaithal", localities: ["Thana--Kaithal"] },
      {
        district: "Faridabad",
        localities: [
          "Amipur faridabad IN-HR",
          "Badhkal Lake",
          "Eros Garden (OBI)",
          "Faridabad Railway Station",
          "Kant Enclave HR/IN",
          "Leisure Valley Park, Faridabad IN-HR",
          "MANGAR 5 MONITORING IN-HR",
          "Mangar Bani",
          "Mangar Forest (OBI)",
          "Mangar mohit farm",
          "Mangar village",
          "Manjawali Seikhpor Haryana, IN-HR",
          "Nagli-Bahrampur Noida yamuna bank",
          "Newton's Point 2 Noida-Sector150",
          "Shekhpur-Manjhawli, Faridabad IN/HR",
          "Surajkund Hr/IN",
          "Yamuna River--Khader",
        ],
      },
      {
        district: "Gurugram",
        localities: [
          "Amity University, Gurgaon",
          "Amity University--Herbal Garden, Gurgaon",
          "Aravali Biodiversity Park Hr/IN",
          "Basai Wetland",
          "Bhondsi Nature Park",
          "Budhera WTP, Gurgaon",
          "Chakkarpur Bundh Gurgaon IN/HR",
          "Chandu Bhudera, outskirts of Gurugram (OBI)",
          "Damdama Lake",
          "Dharampur (Najafgarh) Gurgaon",
          "dlf,phase 2 Gurgaon",
          "Eco Restoration Wazirabad Bundh Hr/IN",
          "Ghata Gaon - Sector 56",
          "Golden Creepers Farm Retreat gurgaon  HR/IN",
          "Gurgaon (OBI)",
          "Jhanjrola (OBI)",
          "Jolahaka Wetlands",
          "Kheda Jhanjhrola HR/IN",
          "leopard trail Gurugram, Haryana, IN",
          "Malibu Towne, Pine Drive",
          "Manesar Hill IN-Haryana",
          "National Brain Research Centre (NBRC)",
          "NSG Manesar HR-IN",
          "Pathways World School, Aravali, Gurugram, HR/IN",
          "Ranika Singhola Village (OBI)",
          "Sakatpur Aravali Scrub",
          "Sakatpur (Gurgaon) Hr/IN",
          "Shikshantar School sector 41 Gurgaon",
          "Sohna (OBI)",
          "Sultanpur Flat Bee-eater area",
          "Sultanpur Flats",
          "Sultanpur National Park",
          "Sultanpur National park and  Flats",
          "Tau Devilal park",
          "The Shriram School, Gurgaon, IN/HR",
          "Thousand Shades Butterfly Park Gurgaon IN/HR",
          "Trident Hotel, Gurgaon",
        ],
      },
      {
        district: "Rewari",
        localities: [
          "Elephant Rock, Gujjar Ghatala Bhiwadi Haryana",
          "Husainpur, Rewari  IN-HR",
          "Manethi",
          "Manethi, Rewari, Hr/IN",
          "Manethi Village (OBI)",
          "Masani Barrage Hr/IN",
          "Masani Barrage, Rewari IN/HR",
        ],
      },
      {
        district: "Panipat",
        localities: [
          "Panipat Oil Refinery Eco Farm",
          "Panipat Railway Station,",
          "Thermal Station panipath IN/HR",
        ],
      },
      {
        district: "Hisar",
        localities: [
          "Blue Bird Lake, Hisar",
          "Haryana Agricultural University",
          "Kaimri Pond, Hisar IN/HR",
        ],
      },
      { district: "Bhiwani", localities: [] },
      {
        district: "Sirsa",
        localities: [
          "Jamal",
          "Ottu Head, Sirsa district Haryana",
          "Ottu Lake, Sirsa, IN/HR",
        ],
      },
      {
        district: "Nuh",
        localities: ["Hasanpur Tauru, Manesar IN/HR", "Nuh, Haryana (OBI)"],
      },
      {
        district: "Panchkula",
        localities: [
          "Beerh Ghaghar Panchkula IN/HR",
          "Beerh Shikargarh Wildlife Sanctuary IN/HR",
          "Berwala Bird Sanctuary",
          "Cactus Garden Secor 5 Panchkula IN/HR",
          "Command Hospital Chandimandir Haryana",
          "Ghaggar River Opposite Herbal Park, Panchkula HR-IN",
          "Morni Hills",
          "Nandpur Forest, panchkula IN/HR",
          "Nepli Reserved Forest",
          "Panchkula water treatment plant",
          "Pinjore--Haryana",
          "Pinjore, Kaushalya Dam",
          "Recreational Park",
          "Sector 32, Panchkula (OBI)",
          "Suketri, Chandigarh",
          "Sukna Wildlife Sanctuary--General Area",
          "Surajpur (Pinjore) IN/HR",
          "Thapli HR/IN",
          "Thapli IN/HR",
          "Thappli Nature Park, Panchkula, Hr-IN",
          "Yadavindra Gardens Pinjore",
        ],
      },
      {
        district: "Mahendragarh",
        localities: ["Dhosi, Narnaun IN/HR", "Madhogarh Fort HR/IN"],
      },
    ],
  },
  {
    state: "Himachal Pradesh",
    districts: [
      {
        district: "Kinnaur",
        localities: [
          "Baspa Valley (OBI)",
          "Bhaba Valley",
          "Chitkul, Himachal Pradesh",
          "Jeera Farm, Sangla",
          "Kalpa, Kinnaur (OBI)",
          "Nako village",
          "Pooh village",
          "Puh (OBI)",
          "Rakchham",
          "Rakchham-Chhitkul Road",
          "Reckong Peo",
          "Rupi Bhaba Wildlife Sanctuary",
          "Sangla Kanda",
          "Sangla (OBI)",
          "Spillow Village",
          "Sumra village",
        ],
      },
      {
        district: "Kullu",
        localities: [
          "Anjani Mahadev Temple",
          "Bah Village, Kullu dt",
          "Beas Hotel, Manali",
          "Bhunter town",
          "Chandrakini Pass snowline (OBI)",
          "Dhundi village, Kullu Dt",
          "Dungri, Manali (OBI)",
          "GB Pant Institute, Mohal",
          "Great Himalayan NP",
          "Great Himalayan NP--Choidwari",
          "Great Himalayan NP--Dengha Pool",
          "Great Himalayan NP--Dhel camp",
          "Great Himalayan NP--Forest Rest House--Mashyar",
          "Great Himalayan NP--Forest Rest House--Sainj",
          "Great Himalayan NP--Guishaini",
          "Great Himalayan NP--Gumtro camp",
          "Great Himalayan NP--Nada",
          "Great Himalayan NP Pekhri",
          "Great Himalayan NP--Rakundi Top",
          "Great Himalayan NP--Rolla camp",
          "Great Himalayan NP--Roopa",
          "Great Himalayan NP--Shilt",
          "Gulaba--General Area (OBI)",
          "Hadimba Devi Temple, Manali",
          "Jagatsukh",
          "Jalori Pass (OBI)",
          "Jana Forest, Kullu dt",
          "Jibhi (OBI)",
          "Kasol",
          "Kheerganga trail",
          "Khokhan WLS",
          "Klath Village",
          "Kothi, Himachal Pradesh",
          "Kullu Bus stand",
          "Manali-General Area",
          "Manali-Solang valley",
          "Manali Wildlife Sanctuary",
          "Manikaran",
          "Marhi, Himachal Pradesh",
          "Naggar Village",
          "Nature Park, Mohal",
          "Rhotang Pass",
          "Rhotang Pass Kulu Slope",
          "Seobagh, Kullu Dt",
          "Sojha (OBI)",
          "Solang-Gulaba trek",
          "Summit of Rohtang Pass",
          "Tirthan Travel Nest Homestay",
          "Tirthan Valley, Himachal Pradesh",
          "Van Vihar, Manali",
          "Vashist",
        ],
      },
      {
        district: "Lahaul and Spiti",
        localities: [
          "Baralacha Pass",
          "Batal, Lahaul, Himachal",
          "Bhaga River",
          "B/W Gondla & Kyelang",
          "B/W Kyelang & Gondla",
          "B/W Kyelang & Jispar",
          "B/W Sissoo  and  Koksar",
          "B/W Sissoo & Gondla",
          "Chandra Lake",
          "Chandra Taal, Lahaul and Spiti",
          "Chatadu, Lahaul, Himachal",
          "Chhatru",
          "Chinab River Bed, Udaipur, Lahaul Spiti",
          "Chomoling, Kibber, Spiti",
          "Dandak",
          "Darcha",
          "Darcha, Lahaul",
          "Demul Village, Spiti",
          "Dhankar Lake, Spiti",
          "Dumla, Chicham, Spiti",
          "Forest Rest House, Udaipur, Lahaul Spiti",
          "Futi Runi",
          "Gemur (Cimur)",
          "Gete Village, Spiti",
          "Gimur",
          "Gimur Spring",
          "Gondla",
          "Gondla (Chandra Valley)",
          "Hurling Village, Spiti, Himachal",
          "Jahalman Village",
          "Jispa",
          "Jispa (Bhaga River)",
          "Jispar",
          "Juniper Forest",
          "Juniper Forest (Kyelang to Darcha)",
          "Juniper Forest of Keylong",
          "Karcha",
          "Kaza, Spiti",
          "Kee village",
          "Keylong",
          "Key Monastery",
          "Khangsar",
          "Khoksar",
          "Khoksar village",
          "Kibber Village, Spiti",
          "Kinlung Yunnan River",
          "Komic Monastery",
          "Komic, Village",
          "Kunzum Pass, Spiti",
          "Kyelang",
          "Lahul",
          "Lalung village",
          "Langza, Spiti",
          "Lari village",
          "Lidang village",
          "Lingti plain between Phalang Danda and Kinlung",
          "Lingti village",
          "Losar",
          "Losar village, Spiti",
          "Muling (Minling)",
          "Pangmo village",
          "Patseo",
          "Patsio, Lahaul",
          "Pin Valley NP",
          "Poh Village, Spiti, Himachal",
          "PWD Rest House, Udaipur",
          "Rama village",
          "Rangrik Na",
          "Rohtang pass",
          "Rohtang Pass (foot) Chandra River",
          "Salung village",
          "Sarchu",
          "Sarchu--Tsarab River",
          "Segiya Dangsa",
          "Shego village, Spiti",
          "Shigri Glacier",
          "Shukto Village",
          "Sissoo",
          "Sissu village",
          "Somdeo",
          "Sumdo",
          "Sumdo, Spiti",
          "Suraj Tal Lake",
          "Tabo, Spiti Valley (OBI)",
          "Tashigang, Spiti",
          "Tharcha camping ground (Takcha)",
          "Tindi, Tandi-Kishtwar Road",
          "Yurnath",
          "Zingzingbar",
          "Zing Zing Bar",
        ],
      },
      {
        district: "Shimla",
        localities: [
          "Chanshal Pass",
          "Daranghati Wildlife Sanctuary -- Rakhani",
          "Hatu Mata Temple",
          "Hatu Peak, Narkanda",
          "Hatu Road",
          "High Court Shimla",
          "Himalayan Forest Research Institute (HFRI), Shimla",
          "Himalayan Nature Park, Kufri",
          "Kharapathar temple",
          "Kotgarh (OBI)",
          "Kotla",
          "Mandhol, Jubbal (OBI)",
          "Nareil",
          "Rampur, Himachal Pradesh",
          "Sarahan Pheasantry area",
          "Sarahan Village",
          "Seri Village",
          "Shimla",
          "Shimla Water Catchment WLS",
          "Shoghi",
          "Summer Hill, Shimla (OBI)",
          "Thanedar (OBI)",
          "Trail - Dofda to Sharai Koti Temple via Daran Village",
        ],
      },
      {
        district: "Mandi",
        localities: [
          "Aut",
          "Bandli Wildlife Sanctuary",
          "Barot/Multhan",
          "Bhangotu village",
          "Dadour Village",
          "Dohandi Village",
          "HPFA forest",
          "IIT Mandi",
          "Indian Institute of Technology, Mandi",
          "Janjehli Village",
          "Katanda FRH",
          "Mandi (OBI)",
          "Mandi town",
          "Motipur, Mandi HP",
          "Nalsar",
          "Nargu Santuary",
          "Ner chowk town",
          "Parashar Lake",
          "Rahla",
          "Ranger training institute, Sundernagar",
          "Rewalsar Lake",
          "Shikari Devi Wildlife Sanctuary",
          "Tatapani Hot Springs",
        ],
      },
      {
        district: "Bilaspur",
        localities: [
          "Barmana village",
          "Govt. College Bilaspur",
          "Kharsa Changar, Bilaspur",
          "Naina Devi WLS",
          "Nangal Lake (Govind Sagar)",
          "Samletu",
        ],
      },
      { district: "Hamirpur", localities: [] },
      {
        district: "Kangra",
        localities: [
          "Aghanjar Mahadev Temple, Dharamsala",
          "Aima (Ghuggar) Palampur, Himachal",
          "Andretta, Palampur",
          "Bhagsunag Waterfall, McLeodganj",
          "Billing, Kangra",
          "Bir Forest",
          "Chohla - Thatri Road",
          "Dal Lake, Mcleod Ganj",
          "Dharamshala City",
          "Dharamshala (OBI)",
          "Dharmashala Tea Estate",
          "Dragon Resort, Naddi",
          "Gaggal, near Dharamshala (OBI)",
          "Gallu Devi Temple",
          "Gallu-Dharamkot Trek Path",
          "garli",
          "Gopalpur Zoo",
          "Guna Devi Mandir Trek",
          "Jawali (OBI)",
          "Kangra Fort",
          "Kasba-Bagiarra Road, Palampur",
          "Maharana Pratap Sagar (Pong Dam)",
          "McLeod Ganj",
          "Naddi (OBI)",
          "Nagrota Surian Forest Complex",
          "Nalle Road Ghugar, Palampur, Himachal Pradesh",
          "Narwana Village (OBI)",
          "near Jawalaji, Kangra (OBI)",
          "Norbulingka tibetan institute, Kangra",
          "Palampur, Himachal Pradesh",
          "Para-gliders Landing Spot",
          "Pathankot (OBI)",
          "Pongdam",
          "Pong Dam -- Goglara",
          "Pong Dam Lake--Dehar Khad",
          "Pong Dam--Nagrota Surian",
          "Pong Dam--Shah nehar barrage",
          "Rakkar, Himachal Pradesh",
          "Rakkar-Tang Road",
          "Sansarpur Terrace",
          "Shah Nehar Barrage Ponds",
          "Shar Nehar Barrage Lake",
          "Shiva Cafe, Bhagsu Nag Waterfall",
          "Silh village",
          "Sthana, near Shah Nehar Barrage Lake",
          "St John Church, McLeodGanj",
          "Sugghar, Palampur, Himchal Pradesh",
          "Trail Waterfall-Triund",
          "Triund (OBI)",
          "Uparli Dar Paragliding Fields",
          "War Memorial, Dharamshala",
        ],
      },
      {
        district: "Chamba",
        localities: [
          "Bathri Nullah (stream)",
          "Chamba (HP) (OBI)",
          "Dalhousie (OBI)",
          "Holi (1,800m) (OBI)",
          "Kalatop Wildlife Sanctuary",
          "Kalatop WLS",
          "Khajjiar range (Kalatop Khajjiar wls)",
          "Satrundi, near Sach Pass (OBI)",
          "Tundah Wildlife Sanctuary (OBI)",
          "Upper Bakrota, Dalhousie",
        ],
      },
      {
        district: "Solan",
        localities: [
          "Bhoj Nagar and around",
          "Bhojnagar- Chakki Mod Road",
          "Bhoj Nagar-Chakki Mod Road, Himachal Pradesh, IN (30.854, 77.051)",
          "Chail Wildlife Sanctuary, Solan",
          "Chakki Mod",
          "chakki modh road",
          "Chakki Morh",
          "Chakki Mor Road",
          "Dr. Y.S Parmar University, Nauni",
          "Dr. Y. S. Parmar University of Horticulture",
          "Gilbert's Trail",
          "Jagjit Nagar",
          "Jangeshu",
          "Jangeshu, Solan",
          "Kasauli",
          "Nayagram, near Banasar Fort, Himachal Pradesh",
          "Solan (OBI)",
          "Subathu, Solan",
          "Timber Trail Forest Resort",
          "Timber Trail Resort, Parwanoo (lower)",
        ],
      },
      {
        district: "Una",
        localities: ["Bhadwar", "Monkey Sterilization Centre, Baul,Una"],
      },
      {
        district: "Sirmaur",
        localities: [
          "Mist-n-Meadows (Restricted access)",
          "Rajgarh",
          "Renukaji Wildlife Sanctuary",
          "Renuka Lake",
          "Simbalbara National Park",
          "Villa Round, Nahan",
        ],
      },
    ],
  },
  {
    state: "Jammu and Kashmir",
    districts: [
      {
        district: "Anantnag",
        localities: [
          "Achabal Garden",
          "Aru",
          "Betab Valley Pahalgam",
          "Club Garden, Pahalgam",
          "Hassanpora , Anantnag",
          "Jawahar Tunnel, North Point",
          "Lavender Park, Pahalgam.",
          "Lidder Valley (OBI)",
          "Overa-Aru Wildlife Sanctuary, Anantnag, Kashmir",
          "Pahalgam",
          "Panzmullah",
          "Rajparian Wildlife Sanctuary",
          "Sinthan Top",
          "Vir Siran",
          "Yanner Bridge",
        ],
      },
      {
        district: "Bandipora",
        localities: [
          "Arin",
          "Barar",
          "Dawar, Gurez Valley, J&K",
          "Gurez",
          "Mountain Livestock Research Institute, SKUAST-K, Manasbal",
          "Sumbal, Bandipora-J&K",
          "Vishnusar Lake",
          "Wular Lake",
        ],
      },
      {
        district: "Baramulla",
        localities: [
          "Frozen Lake Gulmarg",
          "Gulmarg",
          "Gund Khaleel",
          "Haigam Wetland Reserve",
          "Kajinag NP",
          "Kandi Forest Range",
          "khilanmarg (OBI)",
          "Kongdoori",
          "Limber WLS",
          "Mirgund Wetland",
          "NowPora NHPC Colony",
          "Uri-iind Power Project",
        ],
      },
      {
        district: "Budgam",
        localities: [
          "AAA Memorial Degree College Bemina",
          "Awanpora",
          "Badgam",
          "Bemina flood canal",
          "Dhoodh Pathri",
          "Doodhpathri",
          "Flood Channel Alamdar colony",
          "Hokersar Wetland",
          "Narkara Wetland Badgam",
          "near Yusmarg (OBI)",
          "Rajbagh (OBI)",
          "Rambagh flood chennel",
          "Ryan enclave",
          "Ryan Enclave--Badgam",
          "Shivpora, Kashmir (OBI)",
          "Srinagar (OBI)",
          "Yousmarg Forest Area",
          "Yusmarg",
        ],
      },
      {
        district: "Doda",
        localities: [
          "Bhaderwah Campus, University of Jammu",
          "Bhaderwah Padri Road",
          "Gupt Ganga, Bhaderwah",
          "Jai Ghati",
          "Phalshore (33.217, 75.671)",
          "Phalshore (33.219, 75.671)",
          "Rainda, Bhaderwah",
        ],
      },
      {
        district: "Ganderbal",
        localities: [
          "Alusteng, Kashmir (OBI)",
          "Baba Dariya Din",
          "Baltal",
          "Baltal Wildlife Sanctuary",
          "Central University of Kashmir, Ganderbal",
          "Chaterhama",
          "Gund, Kangan",
          "Kangan",
          "Manasbal Lake",
          "Nainsukh",
          "Naranaag",
          "Shuhama (OBI)",
          "Sindh View Park, Kangan",
          "SKUAST Campus Shuhama",
          "SKUAST-Faculty of Forestry, Benhama",
          "Sonamarg",
          "Sonamarg--Thajiwas Glacier",
          "Zojila Pass",
        ],
      },
      {
        district: "Jammu",
        localities: [
          "Akhnoor",
          "Bagh E Bahu",
          "Bari Brahmana, Jammu",
          "Bharda Kalan",
          "Central University of Jammu",
          "Chak Majra",
          "Chatha",
          "Chowadhi",
          "CSIR-IIIM, Jammu",
          "CSIR- Indian Institute of Integrative Medicine (CSIR-IIIM, Jammu",
          "Domel",
          "Dul (OBI)",
          "Environment Park,Raika",
          "Forest Guard Training School Doomi",
          "Gharana, RS Pura",
          "Gorah Patan",
          "Government Degree College Marh",
          "Govt. MAM PG College, Jammu",
          "IIIM Jammu",
          "Indian Institute of Technology, Jammu",
          "Jourian",
          "Jourian Akhnoor",
          "Mahamaya Forest",
          "Makwal Kukrian Wetland",
          "Nikki Tawi 4th Bridge",
          "Pargwal",
          "Poshana, Jammu (OBI)",
          "Ramnagar Wildlife Sanctuary",
          "Science College, Jammu",
          "SFRI Nursery ,Sidhra",
          "SKUAST--Jammu",
          "Sohanjana",
          "Suhanjina",
          "Sunjwan Forest Jammu",
          "Surinsar",
          "Surinsar- Chalanah Forest",
          "Surinsar Mansar Wildlife Sanctuary",
          "Tawi River (Near Amar Mahal), Jammu",
          "University of Jammu",
        ],
      },
      {
        district: "Kathua",
        localities: [
          "Baggan, Billawar",
          "billawar",
          "Billawar",
          "Darung",
          "Galak",
          "Jammu Jasrota",
          "Mandli - Kishenpur Road",
          "Nangala lake and surroundings",
          "Nangala , Machhedi",
          "Ranjit Sagar Dam, Kathua",
          "Sanoja",
          "Sarthal",
          "Thein, Kathua",
          "Ujh Barrage, Kathua",
        ],
      },
      {
        district: "Kishtwar",
        localities: [
          "Bhandarkote, Kishtwar (OBI)",
          "Chenab Nagar, DPS, Kishtwar",
          "Dulhasti Power Station, Kishtwar, Trigam",
          "Kishtwar Garbage Dump",
          "Kishtwar (OBI)",
          "Kwar (OBI)",
          "Lednie-Birding Hotspot",
          "Sarkoot (OBI)",
          "Zambran",
        ],
      },
      {
        district: "Kulgam",
        localities: [
          "Aharbal Waterfall",
          "Hirpora Wildlife Sanctuary",
          "Hirpora WLS",
          "Peer Ki Gali",
          "Qazigund",
          "Wanpoh",
        ],
      },
      {
        district: "Kupwara",
        localities: [
          "Bungas valley",
          "Govt Degree College Sogam Lolab",
          "Kuligam",
        ],
      },
      {
        district: "Mirpur",
        localities: ["Mangla Dam Lake", "Mendhar", "Mendhar Forests"],
      },
      { district: "Muzaffarabad", localities: ["Jagran, Neelum Valley"] },
      {
        district: "Poonch",
        localities: [
          "Bandichechian",
          "Banjosa Lake",
          "Buffliaz",
          "Dera Ki Gali",
          "Dhokri, Poonch View",
          "Jhulass, Poonch",
          "Kalamound",
          "Mangnar Top",
          "Mendhar",
          "Poonch Bridge",
          "Poonch City Forest",
          "Toli Peer National Park",
          "Vikran singh Dheri",
        ],
      },
      {
        district: "Pulwama",
        localities: [
          "Chatlam Wetland",
          "Chatlam Wetland Reserve",
          "Khrew",
          "Kranchoo--Pampore",
          "Lethipora",
          "Manbough Pampore",
          "Pampore wetlands",
          "Pampore Wetlands",
        ],
      },
      {
        district: "Rajouri",
        localities: [
          "Govt. Degree College (GDC), Kalakote",
          "Manwa Mata Mandir",
          "Shiv Khori,Ransoo",
        ],
      },
      {
        district: "Ramban",
        localities: [
          "Batote (OBI)",
          "Chilla",
          "JKTDC--Banihal",
          "Nachlana",
          "Patnitop",
          "Patnitop-Nathatop Road",
        ],
      },
      {
        district: "Reasi",
        localities: [
          "Bhavan, Vaishno Devo Shrine",
          "Katra--The White Hotel",
          "Panshi Point",
          "Purana Daroor",
          "SMVDU Campus",
        ],
      },
      {
        district: "Samba",
        localities: {
          localities: [
            "Basantar Devak Course",
            "Birpur",
            "Central University Of Jammu, Samba campus",
            "Chhajwal",
            "Government Degree College, Samba",
            "Patti ,Smailpur Villages",
            "Surinsar Mansar Sanctuary",
            "Veer Bhoomi Park Samba",
            "Vijaypur--Jammu",
          ],
        },
      },
      { district: "Shopian", localities: ["Rambiara"] },
      {
        district: "Srinagar",
        localities: [
          "Anchar Lake",
          "Botanical Garden",
          "Boulevard Road, Srinagar (34.092, 74.87)",
          "Brain Nishat Srinagar",
          "Brein Nishat",
          "Chasme Shahi (mughal gardens)",
          "Dachigam National Park",
          "Dachigam National Park--Dagwan",
          "Dal Lake",
          "Emporium Garden",
          "Foreshore Road",
          "Harwan Garden",
          "Inner Dal Lake Road",
          "Manibugh Wetland, Pampore (OBI)",
          "Nallah Amir",
          "Nigeen Lake",
          "Nishat",
          "Nishat Garden",
          "Sarband Lake",
          "Shalimar Bagh",
          "Shallabugh Wetland Reserve",
          "Shankaracharya Reserved Forest (Zaberwan Forest)",
          "SKUAST--Shalimar",
          "Sonwar--Srinagar",
          "Srinagar",
          "Srinagar--Shankaracharya Forest",
          "University of Kashmir",
          "Zabarvan Hills (OBI)",
          "Zabarwan Hills",
        ],
      },
      {
        district: "Udhampur",
        localities: [
          "Dumping ground Udhampur",
          "Khaned, Basantgarh",
          "Kud",
          "Mall Road Kud",
          "Mansar Forests",
          "Mansar Lake",
          "Mantalai",
          "Naag Temple--Patnitop",
          "Sudh Mahadev",
          "Udhampur",
          "Udhampur Railway Station",
        ],
      },
    ],
  },
  {
    state: "Jharkhand",
    districts: [
      {
        district: "East Singhbhum",
        localities: [
          "Amlagora Forest",
          "Bada Banki Grassland (Hurlung)",
          "Beldih Club (restricted access)",
          "Bhatia Park",
          "Burudi Dam",
          "Chota Banki Dam",
          "Dalma WLS--General Area",
          "Dalma WLS--Hill Top",
          "DAV Public School (Restricted access)",
          "Dimna Lake",
          "Galudih Barrage",
          "Hata Grasslands",
          "Hudco Park",
          "Jayanti Sarovar",
          "Jubilee Park",
          "Kankrajhore/Kanimahuli--Shiv Mandir",
          "Kharkhai River Bank",
          "Kharkhai Subarnarekha Sangam",
          "Mango--Subarnarekha River Bank",
          "Nutandih--Subarnarekha River Bank",
          "Tata Steel Zoological Park",
          "Tubemakers Club (restricted access)",
          "Uliyan Pond",
          "XLRI--Subarnarekha River Bank",
        ],
      },
      {
        district: "Giridih",
        localities: [
          "Chongakhar",
          "ISI Farm",
          "Khandoli Reservoir",
          "Parasnath WLS--Base Of Main Hill",
          "Parasnath WLS--Chandraprabhu Tonk",
          "Parasnath WLS--Gandharva Nala",
          "Parasnath WLS-- Gautam Swami Tonk",
          "Parasnath WLS--General Area",
          "Parasnath WLS--Jal Mandir",
          "Parasnath WLS--Kalikund Mandir and 1st Hill",
          "Parasnath WLS--Parasnath Tonk",
          "Parasnath WLS--Sheetal Nala",
          "Shikharji Town",
        ],
      },
      {
        district: "Sahibganj",
        localities: [
          "Pachanandapur River Islands",
          "Sahibganj Ghat",
          "Udhwa Bird Sanctuary--Berhale Jheel",
          "Udhwa Bird Sanctuary--Pataura Lake",
        ],
      },
      {
        district: "Saraikela-Kharsawan",
        localities: [
          "Aasanbani Hillock",
          "Chandil Dam--Dimudih",
          "Chandil Dam--General Area",
          "Dalma WLS--Aasanbani",
          "Dalma WLS--Forest Rest House",
          "Dalma WLS--Makula Kocha",
          "Dobo",
          "Dobo Dam",
          "NIT Jamshedpur",
          "Purisili Pond",
          "Sitarampur Reservoir",
        ],
      },
      {
        district: "Bokaro",
        localities: [
          "Bokaro Ispat Vidyalaya Sector 6A surroundings",
          "Chandankiyari Forest Office",
          "City Park",
          "Cooling pond 1",
          "Cooling Pond 2",
          "Garga Dam",
          "Jawaharlal Nehru Biological Park",
          "Konar Dam",
          "Scrubland behind Surya Mandir",
          "Tenughat Reservoir",
        ],
      },
      {
        district: "Ramgarh",
        localities: ["Patratu Dam", "Ranchi Road Station Wetland"],
      },
      { district: "Simdega", localities: ["Kelaghat Dam"] },
      {
        district: "West Singhbhum",
        localities: [
          "Chiriya",
          "Gindung",
          "Kiriburu",
          "Kiriburu Forest Rest House",
          "Makranda",
          "Pachpaia",
          "Rabangda",
          "Saranda,Chaibasa Forest",
          "Tambo",
          "Tholkobad Forest Guest House",
          "Toebo waterfall",
          "Toybo",
        ],
      },
      { district: "Gumla", localities: ["Upper Sankh Dam"] },
      {
        district: "Hazaribagh",
        localities: [
          "Ambacola",
          "Canary Hill",
          "Chadwa Dam",
          "Hazaribagh Jheel",
          "Hazaribagh NP",
          "Hazaribagh Pinjrapole Gaushala",
          "Lotwa Dam",
          "Mount Carmel School",
          "Parsatari",
          "Rajderwa Bird Sanctuary",
        ],
      },
      { district: "Garhwa", localities: [] },
      { district: "Dumka", localities: ["Masanjore Dam"] },
      { district: "Lohardaga", localities: ["Nandani Dam"] },
      { district: "Godda", localities: [] },
      {
        district: "Latehar",
        localities: [
          "Betla NP--General Area",
          "Garu Gumla Forest Range--Kobarwa Nala",
          "Garu Gumla Forest Range--Kujrung Core Area",
          "Garu Gumla Forest Range--Maromar Guest house",
          "Garu--North Koel River Bank",
          "Netarhat Forest",
          "Netarhat Villlage",
        ],
      },
      { district: "Pakur", localities: [] },
      { district: "Khunti", localities: ["Timra"] },
      {
        district: "Koderma",
        localities: ["Koderma WLS--General Area", "Piprahi", "Tilaiya Dam"],
      },
      {
        district: "Ranchi",
        localities: [
          [
            "Bariatu Hill",
            "Bhagawan Birsa Biological Park",
            "Birla Institute of Technology",
            "Birsa Agricultural College",
            "Dhurva Chota Dam",
            "Dhurwa Dam",
            "Doranda",
            "Getalsud Dam",
            "Gossner College",
            "Hapatbera Hill",
            "Hatia Dam",
            "Hindalco Colony",
            "Horhap Forest--General Area",
            "Horhap Forest--River bank",
            "Horhap Forest--Waterfall",
            "Horhap FRH",
            "Hundru Waterfall",
            "Institute of Forest Productivity",
            "ITBP Hill",
            "Jarga Hill",
            "Jarga Jungle Valley",
            "Jumar River Mini Dam",
            "Kanke Dam",
            "Rajendra Park",
            "Ranchi College",
            "Rukka Dam",
            "Rukka Dam--Hatwal",
            "Rukka Dam--Subarnrekha Bridge",
            "Tigra",
            "Vulture Centre",
          ],
        ],
      },
      { district: "Palamu", localities: ["Malay Dam"] },
      { district: "Chatra", localities: ["Mcluskie Ganj"] },
      { district: "Deoghar", localities: [] },
      { district: "Jamtara", localities: ["Parvat Vihar"] },
      {
        district: "Dhanbad",
        localities: [
          "Amtal Forest Patch",
          "Council of Scientific and Industrial Research",
          "Damodarpur Hill",
          "Dhokra Grassland",
          "Dhokra--Palash Forest",
          "Maithon Dam Jharkhand side",
          "Panchet Dam Jharkhand side",
          "Topchanchi WLS",
          "Workshop Colony",
        ],
      },
    ],
  },
  {
    state: "Karnataka",
    districts: [
      {
        district: "Chitradurga",
        localities: [
          "Aimangala Scrubland Ã Â²ÂÃ Â²Â®Ã Â²â€šÃ Â²â€”Ã Â²Â² Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â",
          "Belaguru Kere",
          "Beliyakal Ranganatha Swamy Temple, Belagur",
          "Challakere grasslands",
          "Chandravalli,",
          "Chitradurga Fort",
          "Chitradurga Pond",
          "Gavi Ranganatha Swami Hillock",
          "Gayathri Jalashaya",
          "Gonur Kere",
          "Gulialu",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Â²Â²Ã Â³Â",
          "Halekallahalli Kere",
          "Holalkere Lake",
          "Jogimatti Forest--Forest Guest House",
          "Jogimatti Wildlife Sanctuary Ã Â²Å“Ã Â³â€¹Ã Â²â€”Ã Â²Â¿Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¨Ã Â³ÂÃ Â²Â¯Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Katral lake",
          "Mallapura Lake",
          "Sangenahalli Lake",
          "Sri Veerapratapa Anjaneya temple, Belaguru",
          "Vani Vilasa Sagara Dam (Marikanive dam)",
        ],
      },
      {
        district: "Yadgiri",
        localities: [
          "Balichakkur Hillock Ã Â²Â¬Ã Â²Â²Ã Â²Â¿Ã Â²Å¡Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â³â€šÃ Â²Â° Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Bheema River Bridge--Yadgir Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ --Ã Â²Â¯Ã Â²Â¾Ã Â²Â¦Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿",
          "Bhima River Barrage Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²Â¾Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²â€™Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â³Â",
          "Bonal Tank Bird Sanctuary Ã Â²Â¬Ã Â³â€¹Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²ÂªÃ Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "College of Agricultural Sciences, Bheemarayanagudi Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¿Ã Â²Å“Ã Â³ÂÃ Â²Å¾Ã Â²Â¾Ã Â²Â¨ Ã Â²Â®Ã Â²Â¹Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯, Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Â¨Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â²Â¿",
          "Dodda Siddapur Kere Ã Â²Â¦Ã Â³Å Ã Â²Â¡Ã Â³ÂÃ Â²Â¡ Ã Â²Â¸Ã Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Gavi Siddalingeshwara Temple Ã Â²â€”Ã Â²ÂµÃ Â²Â¿Ã Â²Â¸Ã Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â§Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨",
          "Gogi Kere Ã Â²â€”Ã Â³â€¹Ã Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Gogikona Kere Ã Â²â€”Ã Â³â€¹Ã Â²â€”Ã Â²Â¿Ã Â²â€¢Ã Â³â€¹Ã Â²Â¨Ã Â²Â¾ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Gundalgera Tunnel Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¾ Ã Â²Â¸Ã Â³ÂÃ Â²Â°Ã Â²â€šÃ Â²â€”",
          "Halbhavi Village Ã Â²Â¹Ã Â²Â¾Ã Â²Â²Ã Â²Â­Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿ Ã Â²â€”Ã Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²Â®",
          "Hasnapur Hillock Ã Â²Â¹Ã Â²Â¸Ã Â²Â¨Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Hasnapur Jodu Kere Ã Â²Â¹Ã Â²Â¸Ã Â²Â¨Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Å“Ã Â³â€¹Ã Â²Â¡Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hattikuni Dam Ã Â²Â¹Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²Â£Ã Â²Â¿ Ã Â²â€ Ã Â²Â£Ã Â³â€ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Khanapur Kere Ã Â²â€“Ã Â²Â¾Ã Â²Â¨Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Lumbini Park Ã Â²Â²Ã Â³ÂÃ Â²â€šÃ Â²Â¬Ã Â²Â¿Ã Â²Â¨Ã Â²Â¿ Ã Â²ÂªÃ Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â³Â",
          "Madnal Kere Ã Â²Â®Ã Â²Â¦Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Â³Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Malnoor Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Paddy Fields, Naikal Ã Â²Â­Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â²Â¦ Ã Â²â€”Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â³â€ Ã Â²â€”Ã Â²Â³Ã Â³Â, Ã Â²Â¨Ã Â²Â¾Ã Â²Â¯Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â",
          "Samnapur Dam Ã Â²Â¸Ã Â²Â®Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¡Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€š",
          "Shettikera Kere Ã Â²Â¶Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¾ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Shettikera Upper Lake Ã Â²Â¶Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¾ Ã Â²Â®Ã Â³â€¡Ã Â²Â²Ã Â³ÂÃ Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Siddhalingeswara Betta Ã Â²Â¸Ã Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â§Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Thalavaipatty Lake (Mettupalayam Tank) Ã Â®Â¤Ã Â®Â³Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â",
          "Tinthani Bridge Ã Â²Â¤Ã Â²Â¿Ã Â²â€šÃ Â²Â¥Ã Â²Â£Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
        ],
      },
      {
        district: "Belagavi",
        localities: [
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP",
          "Aravali Check Dam Ã Â²â€¦Ã Â²Â°Ã Â²ÂµÃ Â²Â³Ã Â²Â¿ Ã Â²Å¡Ã Â³â€ Ã Â²â€¢Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â¡Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€š",
          "Belagavi Golf Course Ã Â²â€”Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²Â«Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€¹Ã Â²Â°Ã Â³ÂÃ Â²Â¸Ã Â³Â, Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿",
          "Belagavi Railway Station Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿ Ã Â²Â°Ã Â³Ë†Ã Â²Â²Ã Â³Â Ã Â²Â¨Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â£",
          "Belgaum--General Area (OBI)",
          "Bhimgad Adventure Camp, Katgali Rd Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²â€”Ã Â²Â¡ Ã Â²â€¦Ã Â²Â¡Ã Â³ÂÃ Â²ÂµÃ Â³â€ Ã Â²â€šÃ Â²Å¡Ã Â²Â°Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€šÃ Â²ÂªÃ Â³ÂÃ¢â‚¬Å’, Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²â€”Ã Â²Â³Ã Â²Â¿ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ ",
          "Bhimgad Wildlife Sanctuary--Hemmadaga Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²â€”Ã Â²Â¡ Ã Â²ÂµÃ Â²Â¨Ã Â³ÂÃ Â²Â¯Ã Â²Â§Ã Â²Â¾Ã Â²Â®--Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¡Ã Â²â€”Ã Â²Â¾",
          "Bidi Minor Irrigation Tank Ã Â²Â¬Ã Â²Â¿Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â²Â¿Ã Â²Â°Ã Â³Â Ã Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â²Â¾Ã Â²ÂµÃ Â²Â°Ã Â²Â¿ Ã Â²Å¸Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€šÃ Â²â€¢Ã Â³Â",
          "Bilakundi Kunte Ã Â²Â¬Ã Â²Â¿Ã Â²Â²Ã Â²â€¢Ã Â³ÂÃ Â²â€šÃ Â²Â¦Ã Â²Â¿ Ã Â²â€¢Ã Â³ÂÃ Â²â€šÃ Â²Å¸Ã Â³â€ ",
          "Chikhale village Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â²Â²Ã Â³â€  Ã Â²â€”Ã Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²Â®",
          "Chikkabudanur Kere Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â¬Ã Â³â€šÃ Â²Â¦Ã Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Dhupdal IB Garden Ã Â²Â¦Ã Â³ÂÃ Â²ÂªÃ Â³ÂÃ Â²ÂªÃ Â²Â¦Ã Â²Â¾Ã Â²Â²Ã Â³Â Ã Â²ÂÃ Â²Â¬Ã Â²Â¿ Ã Â²â€”Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Â¡Ã Â²Â¨Ã Â³Â",
          "Ghataprabha Bird Sanctuary Ã Â²ËœÃ Â²Å¸Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â­Ã Â²Â¾ Ã Â²ÂªÃ Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Godachinmalki Falls Ã Â²â€”Ã Â³Å Ã Â²Â¡Ã Â²Å¡Ã Â²Â¿Ã Â²Â¨Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²â€¢Ã Â²Â¿ Ã Â²Å“Ã Â²Â²Ã Â²ÂªÃ Â²Â¾Ã Â²Â¤",
          "Godageri Kere Ã Â²â€”Ã Â³Å Ã Â²Â¡Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Gogte Institute of Technology Ã Â²â€”Ã Â³â€¹Ã Â²â€”Ã Â²Å¸Ã Â³â€  Ã Â²Â¤Ã Â²Â¾Ã Â²â€šÃ Â²Â¤Ã Â³ÂÃ Â²Â°Ã Â²Â¿Ã Â²â€¢ Ã Â²Â¸Ã Â²â€šÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â³â€ ",
          "Gokak Falls Ã Â²â€”Ã Â³â€¹Ã Â²â€¢Ã Â²Â¾Ã Â²â€¢ Ã Â²Å“Ã Â²Â²Ã Â²ÂªÃ Â²Â¾Ã Â²Â¤",
          "Handibadanganath Mutt Ã Â²Â¹Ã Â²â€šÃ Â²Â¡Ã Â²Â¿Ã Â²Â¬Ã Â²Â¡Ã Â²â€šÃ Â²â€”Ã Â²Â¨Ã Â²Â¾Ã Â²Â¥ Ã Â²Â®Ã Â²Â ",
          "Hanumapur Kere Ã Â²Â¹Ã Â²Â¨Ã Â³ÂÃ Â²Â®Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hidkal Backwaters --Jinaral Ã Â²Â¹Ã Â²Â¿Ã Â²Â¡Ã Â²â€¢Ã Â²Â²Ã Â³Â Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Å“Ã Â²Â¿Ã Â²Â¨Ã Â²Â°Ã Â²Â¾Ã Â²Â³",
          "Hidkal Dam--Viewpoint Ã Â²Â¹Ã Â²Â¿Ã Â²Â¡Ã Â²â€¢Ã Â²Â²Ã Â³Â Ã Â²â€ Ã Â²Â£Ã Â³â€ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â--Ã Â²ÂµÃ Â³â‚¬Ã Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â£Ã Â²Â¾ Ã Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â³",
          "Hirebudanur Kere Ã Â²Â¹Ã Â²Â¿Ã Â²Â°Ã Â³â€¡Ã Â²Â¬Ã Â³â€šÃ Â²Â¦Ã Â²Â¨Ã Â³â€šÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hulikatti Kere Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â²Â¿Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA",
          "Jamboti  Ã Â²Å“Ã Â²â€šÃ Â²Â¬Ã Â³â€¹Ã Â²Å¸Ã Â²Â¿",
          "Kamal Basadi Ã Â²â€¢Ã Â²Â®Ã Â²Â²Ã Â³Â Ã Â²Â¬Ã Â²Â¸Ã Â²Â¦Ã Â²Â¿",
          "Kankumbi Ã Â²â€¢Ã Â²Â£Ã Â²â€¢Ã Â³ÂÃ Â²â€šÃ Â²Â¬Ã Â²Â¿",
          "Kaparatti Kere Ã Â²â€¢Ã Â²ÂªÃ Â²Â°Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kaujalgi Kere Ã Â²â€¢Ã Â³Å’Ã Â²Å“Ã Â²Â²Ã Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kenchanakere, Hunsur (Ã Â²â€¢Ã Â³â€ Ã Â²â€šÃ Â²Å¡Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ) Â²Â£",
          "Kittur Fort Ã Â²â€¢Ã Â²Â¿Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€ ",
          "KLEU Campus Ã Â²â€¢Ã Â³â€ Ã Â²Å½Ã Â²Â²Ã Â³ÂÃ Â²â€¡Ã Â²Â¯Ã Â³Â Ã Â²â€ Ã Â²ÂµÃ Â²Â°Ã Â²Â£",
          "Kolibailu / Ã Â²â€¢Ã Â³â€¹Ã Â²Â³Ã Â²Â¿ Ã Â²Â¬Ã Â³Ë†Ã Â²Â²Ã Â³Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°",
          "Lolasur Bridge Ã Â²Â²Ã Â³â€¹Ã Â²Â³Ã Â²Â¸Ã Â³ÂÃ Â²Â° Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Malagali Kere Ã Â²Â®Ã Â²Â³Ã Â²â€”Ã Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Malaprabha River--Jamboti Bridge Ã Â²Â®Ã Â²Â²Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â­Ã Â²Â¾  Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿--Ã Â²Å“Ã Â²â€šÃ Â²Â¬Ã Â³â€¹Ã Â²Å¸Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Malaprabha River, Kesarkoppa Ã Â²Â®Ã Â²Â²Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â­Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿, Ã Â²â€¢Ã Â³â€ Ã Â²Â¸Ã Â²Â°Ã Â²â€¢Ã Â³Å Ã Â²ÂªÃ Â³ÂÃ Â²Âª",
          "Mhadei Research Center Ã Â²Â®Ã Â²Â¹Ã Â²Â¦Ã Â²Â¾Ã Â²Â¯Ã Â²Â¿ Ã Â²Â¸Ã Â²â€šÃ Â²Â¶Ã Â³â€¹Ã Â²Â§Ã Â²Â¨Ã Â²Â¾ Ã Â²â€¢Ã Â³â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°",
          "Mysore City Bird Atlas 32C, Dalvoy Kere, Mysore Ã Â²Â¦Ã Â²Â³Ã Â²ÂµÃ Â²Â¾Ã Â²Â¯Ã Â²Â¿ Ã",
          "National Institute of Traditional Medicine Ã Â²Â°Ã Â²Â¾Ã Â²Â·Ã Â³ÂÃ Â²Å¸Ã Â³ÂÃ Â²Â°Ã Â³â‚¬Ã Â²Â¯ Ã Â²ÂªÃ Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²ÂªÃ Â²Â°Ã Â²Â¿Ã Â²â€¢ Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â²Â¿Ã Â²Â¤Ã Â³ÂÃ Â²Â¸Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿Ã Â²Å“Ã Â³ÂÃ Â²Å¾Ã Â²Â¾Ã Â²Â¨ Ã Â²Â¸Ã Â²â€šÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â³â€ ",
          "Navilutheertha Dam Ã Â²Â¨Ã Â²ÂµÃ Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¤Ã Â³â‚¬Ã Â²Â°Ã Â³ÂÃ Â²Â¥ Ã Â²â€ Ã Â²Â£Ã Â³â€ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Near Belgaum--General Area (OBI)",
          "Ramnagar (OBI)",
          "Renukasagar Reservoir Ã Â²Â°Ã Â³â€¡Ã Â²Â£Ã Â³ÂÃ Â²â€¢Ã Â²Â¾Ã Â²Â¸Ã Â²Â¾Ã Â²â€”Ã Â²Â° Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Savadatti Jackwell Ã Â²Â¸Ã Â²ÂµÃ Â²Â¦Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â²Â¿ Ã Â²Å“Ã Â²Â¾Ã Â²â€¢Ã Â³ÂÃ Â²ÂµÃ Â³â€ Ã Â²Â²Ã Â³Â",
          "South of Belgaum (OBI)",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Vaccine Depot Ã Â²Â²Ã Â²Â¸Ã Â²Â¿Ã Â²â€¢Ã Â³â€  Ã Â²â€¢Ã Â³â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°",
          "Vadakahole Mutt, Naviluteertha Ã Â²ÂµÃ Â²Â¡Ã Â²â€¢Ã Â²Â¹Ã Â³Å Ã Â²Â³Ã Â³â€  Ã Â²Â®Ã Â²Â , Ã Â²Â¨Ã Â²ÂµÃ Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¤Ã Â³â‚¬Ã Â²Â°Ã Â³ÂÃ Â²Â¥",
          "Vajrapoha Falls, Jamboti  Ã Â²ÂµÃ Â²Å“Ã Â³ÂÃ Â²Â°Ã Â²ÂªÃ Â³â€¹Ã Â²Â¹ Ã Â²Å“Ã Â²Â²Ã Â²ÂªÃ Â²Â¾Ã Â²Â¤, Ã Â²Å“Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³â€¹Ã Â²Å¸Ã Â²Â¿",
          "VTU Ã Â²ÂµÃ Â²Â¿Ã Â²Å¸Ã Â²Â¿Ã Â²Â¯Ã Â³Â",
        ],
      },
      {
        district: "Dharwad",
        localities: [
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMPÃ Â²Â°Ã Â³â€ ",
          "APMC Hubblli Ã Â²Å½.Ã Â²ÂªÃ Â²Â¿.Ã Â²Å½Ã Â²â€š.Ã Â²Â¸Ã Â²Â¿. Ã Â²Â¹Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Ã Â¤Â®Ã Â¤Â¿Ã Â¤Â Ã Â¤Â¾Ã Â¤â€”Ã Â¤Â°, Navi Mumbai, Maharashtra, IN (18.907, 72.972)",
          "Benapatti Outer farmÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bhutnal Kere  Ã Â²Â­Ã Â³â€šÃ Â²Â¤Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Chinnadakere Pond Ã Â²Å¡Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¦Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¢Ã Â³Å Ã Â²Â³",
          "Dadadahalli fields    Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²Â®Ã Â³Ë†Ã Â²Â¦Ã Â²Â¾Ã Â²Â¨",
          "Dadadahalli Kere (Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â",
          "Devaragudihal Kere Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â°Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â²Â¿Ã Â²Â¹Ã Â²Â¾Ã Â²Â³Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Devara Hubballi Kere Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â° Ã Â²Â¹Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Dhatri Farms Ã Â²Â§Ã Â²Â¾Ã Â²Â¤Ã Â³ÂÃ Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²Â°",
          "Dori Kere, Ã Â²Â¦Ã Â³â€¹Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Dzuluk to Nathang Valley Part 1, Ã Â¤Â¸Ã Â¤Â¿Ã Â¤â€¢Ã Â¥ÂÃ Â¤â€¢Ã Â¤Â¿Ã Â¤Â®, IN (27.255, 88.781)",
          "Dzuluk to Nathang Valley Part 1, Ã Â¤Â¸Ã Â¤Â¿Ã Â¤â€¢Ã Â¥ÂÃ Â¤â€¢Ã Â¤Â¿Ã Â¤Â®, IN (27.255, 88.781)°Ã Â³â€ ",
          "Eco Village, Haliyal Road Ã Â²â€¡Ã Â²â€¢Ã Â³â€¹ Ã Â²ÂµÃ Â²Â¿Ã Â²Â²Ã Â³â€¡Ã Â²Å“Ã Â³ÂÃ¢â‚¬Å’, Ã Â²Â¹Ã Â²Â³Ã Â²Â¿Ã Â²Â¯Ã Â²Â¾Ã Â²Â³ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ ",
          "Gamanagatti Ind Estate, Hubballi Ã Â²â€”Ã Â²Â¾Ã Â²Â®Ã Â²Â¨Ã Â²â€”Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²â€¢Ã Â³Ë†Ã Â²â€”Ã Â²Â¾Ã Â²Â°Ã Â²Â¿Ã Â²â€¢Ã Â²Â¾ Ã Â²Å½Ã Â²Â¸Ã Â³ÂÃ Â²Å¸Ã Â³â€¡Ã Â²Å¸Ã Â³Â, Ã Â²Â¹Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ¯",
          "Honnapura Kere Ã Â²Â¹Ã Â³Å Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "IIT Dharwad Ã Â²ÂÃ Â²ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â§Ã Â²Â¾Ã Â²Â°Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã ",
          "JSS Campus Ã Â²Å“Ã Â³â€ Ã Â²Å½Ã Â²Â¸Ã Â³ÂÃ¢â‚¬Å’Ã Â²Å½Ã Â²Â¸Ã Â³Â Ã Â²â€¢Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€šÃ Â²ÂªÃ Â²Â¸Ã Â³Â",
          "Junjanbail Kere Ã Â²Å“Ã Â³ÂÃ Â²â€šÃ Â²Å“Ã Â²Â¨Ã Â²Â¬Ã Â³Ë†Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kalakeri Ã Â²â€¢Ã Â²Â²Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿",
          "Kalkeri Forest Ã Â²â€¢Ã Â²Â²Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯",
          "Kalkeri Forest Ã Â²â€¢Ã Â²Â Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ ",
          "Karnataka College (KCD) Ã Â²â€¢Ã Â²Â°Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Å¸Ã Â²â€¢ Ã Â²â€¢Ã Â²Â¾Ã Â²Â²Ã Â³â€¡Ã Â²Å“Ã Â³ÂÃ¢â‚¬Å’ (Ã Â²â€¢Ã Â³â€ Ã Â²Â¸Ã Â²Â¿Ã Â²Â¡Ã Â²Â¿)",
          "Karnataka State Forest Academy, Gungargatti Ã Â²â€¢Ã Â²Â°Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Å¸Ã Â²â€¢ Ã Â²Â°Ã Â²Â¾Ã Â²Å“Ã Â³ÂÃ Â²Â¯ Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯ Ã Â²â€¦Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³â€ Ã Â²Â®Ã Â²Â¿, Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â°Ã Â²â€”Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿",
          "Karnataka University Ã Â²â€¢Ã Â²Â°Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Å¸Ã Â²â€¢ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kempannana Kere Ã Â²â€¢Ã Â³â€ Ã Â²â€šÃ Â²ÂªÃ Â²Â£Ã Â³ÂÃ Â²Â£Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Kolibailu / Ã Â²â€¢Ã Â³â€¹Ã Â²Â³Ã Â²Â¿ Ã Â²Â¬Ã Â³Ë†Ã Â²Â²Ã Â³Ââ€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kudalagi Kere Ã Â²â€¢Ã Â³ÂÃ Â²Â¡Ã Â²Â²Ã Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kumarapuram/Manalmantha Ã°Å¸ÂÂ¡Ã°Å¸ÂÂ£Ã°Å¸ÂÂ¥Ã°Å¸ÂÂ¤Ã°Å¸ÂÂ¦",
          "Kundal Dongar ( Ã Â¤â€¢Ã Â¥ÂÃ Â¤â€šÃ Â¤Â¡Ã Â¤Â² Ã Â¤Â¡Ã Â¥â€¹Ã Â¤â€šÃ Â¤â€”Ã Â¤Â°)â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â°Ã ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Kutwadi Kere Ã Â²â€¢Ã Â³ÂÃ Â²Å¸Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Gaddige-Hunsur",
          "Mallapur Island Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¨Ã Â²Â¡Ã Â³ÂÃ Â²",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  ",
          "Mavinakoppa Lake",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Mugad Kere  Ã Â²Â®Ã Â³ÂÃ Â²â€”Ã Â²Â¦ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â",
          "Mysore City Bird Atlas 32C, Dalvoy Kere, Mysore Ã Â²Â¦Ã Â²Â³Ã Â²ÂµÃ Â²Â¾Ã Â²Â¯Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nagarhalli S.Malli, Ã Â²â€¢Ã Â²Â°Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Å¸Ã Â²â€¢, IN (16.767, 76.489)",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿)",
          "Narendra Kere Ã Â²Â¨Ã Â²Â°Ã Â³â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Navaloor Ã Â²Â¨Ã Â²ÂµÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Navaloor Kere Ã Â²Â¨Ã Â²ÂµÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Navaloor Kere Ã Â²Â¨Ã Â²Âµe, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³",
          "Naveen Park Ã Â²Â¨Ã Â²ÂµÃ Â³â‚¬Ã Â²Â¨Ã Â³ÂÃ¢â‚¬Å’ Ã Â²ÂªÃ Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ¢â‚¬Å’",
          "Navloor Somapur Road",
          "Neera Sagar Reservoir Ã Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€”Ã Â²Â° Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Nigadi Kere Ã Â²Â¨Ã Â²Â¿Ã Â²â€”Ã Â²Â¦Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)",
          "Nrupatunga Betta  Ã Â²Â¨Ã Â³Æ’Ã Â²ÂªÃ Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€” Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "Nuggikeri Kere Ã Â²Â¨Ã Â³ÂÃ Â²â€”Ã Â³ÂÃ Â²â€”Ã Â²Â¿Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Salakainakoppa Lake",
          "Saligrama Ã Â²Â¸Ã Â²Â¾Ã Â²Â²Ã Â²Â¿Ã Â²â€”Ã Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²Â®¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Shaneshwara Temple Kere Ã Â²Â¶Ã Â²Â¨Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨Ã Â²Â¦ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Sindhuvalli, Nanjanagudu (Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â§Ã Â³ÂÃ Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Sittanur Area Ã Â®Å¡Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°ÃÂ²Â°Ã Â²Â£Ã Â³Ââ€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "SK Arts & Kotambri Colleges, Hubballi  Ã Â²Å½Ã Â²Â¸Ã Â³Â.Ã Â²â€¢Ã Â³â€ . Ã Â²â€ Ã Â²Â°Ã Â³ÂÃ Â²Å¸Ã Â³ÂÃ Â²Â¸Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â®Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³Â Ã Â²â€¢Ã Â³â€¹Ã Â²Â¤Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â²Â¾Ã Â²Â²Ã Â³â€¡Ã Â²Å“Ã Â³ÂÃ Â²â€”Ã Â²Â³Ã Â³Â, Ã Â²Â¹Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Somanakoppa Ã Â²Â¸Ã Â³â€¹Ã Â²Â®Ã Â²Â¨Ã Â²â€¢Ã Â³Å Ã Â²ÂªÃ Â³ÂÃ Â²Âª",
          "Someshwar Kere Ã Â²Â¸Ã Â³â€¹Ã Â²Â®Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Tolankere Ã Â²Â¤Ã Â³â€¹Ã Â²Â³Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "University of Agricultural Sciences  Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¿Ã Â²Å“Ã Â³ÂÃ Â²Å¾Ã Â²Â¾Ã Â²Â¨ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Unkal Kere Ã Â²â€°Ã Â²Â£Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
        ],
      },
      {
        district: "Chamarajanagara",
        localities: [
          "Yelandur--Aalakere agrahara",
          "Anisha Farms",
          "Udigala",
          "Dugatti Dodda kere",
          "Bandipur NP--Safari campus",
          "Bandipur NP--Forest Headquarters",
          "Nalluru kere",
          "Mahadeshwara Temple",
          "Hangala Kere",
          "Hanur",
          "Maleyur",
          "Santemarahalli",
          "Saragur--Cauvery river bank",
          "Hubbe Hunse Dam",
          "Yeriyuru Kere",
          "Bandipur NP--Mangala Village Road East of Mangala",
          "Bedguli-Kaddigere",
          "Yelandur--Bannisarige",
          "Belachavadi Kere",
          "Kakkanahalla, Bandipura",
          "Tondekere",
          "BR Hills--ATREE field station",
          " Hogenakkal Falls",
          "Teramballi",
          "BR Hills--Hondarabalu checkpost",
          "Kollegal--Mudigundam bridge",
          "BR Hills--Gorukana",
          "Yelandur--Vadagere",
          "Suvarnavathi Backwaters - Kulluru/Ã Â²â€¢Ã Â³ÂÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Gundlupet",
          "Y K Mole",
          "Mamballi, Chamarajanagara",
          "Kanakagiri Hilltop",
          "BR Hills--Gumballi checkpost",
          "BR Hills--VGKK",
          "Dhole's Den",
          "Cauvery WLS--Palar Bridge",
          "BR Hills--K-gudi Inspection Bungalow",
          "Kunthuru Kere",
          "The Serai Bandipur",
          "Bandipur Safari Lodge",
          "BR Hills--Gundal Reservoir",
          "Hirikere",
          "BR Hills--IPH field station",
          "Chamarajanagara town",
          "Hiri Kere (Lake)",
          "MM Hills Nature Centre",
          "Bharachukki Falls",
          "Kollegal--Sathyagala bridge",
          "Kollegal--Mudigundam kere",
          "Kollegal",
          "Uppinamole Kere, Yelanduru",
          "Badanaguppe",
          "Sargur Kere",
          "Bandipur NP",
          "BR Hills",
          "Berambadi Kere",
          "Thunga River -Kushavati , Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ -Ã Â²â€¢Ã Â³ÂÃ Â²Â¶Ã Â²Â¾Ã Â²ÂµÃ Â²Â¤Ã Â²Â¿",
          "BR Hills--Krishnaina Katte",
          "Mukanapalya Kere",
          "Ramana Gudde Dam",
          "Nagavalli Kere",
          "Bandipur NP--Mangala Village",
          "Yenkana Kere, Jodemellahalli",
          "Bendaravadi Kere",
          "Gumballi PHC",
          "Thanglittina Mallappana Betta",
          "Bendaravadi Kere, Nanjangudu-Chamarajanagar Road,",
          "Deenabandhu Trust-Boys' Home",
          "BR Hills--Boodipadaga",
          "Morarji Desai Residential School, Ã Â²Â®Ã Â³Å Ã Â²Â°Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Å“Ã Â²Â¿ Ã Â²Â¦Ã Â³â€¡Ã Â²Â¸Ã Â²Â¾Ã Â²Â¯Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¸Ã Â²Â¤Ã Â²Â¿ Ã Â²Â¶Ã Â²Â¾Ã Â²Â²Ã Â³â€ ",
          "Duggatti Chikkere, Yelandur",
          "Kunagalli kere",
          "Ayyanapura camp",
          "Irasavadi Kere, Chamarajanagara",
          "BR Hills--Biligiri Rangaswamy Temple Tiger Reserve",
          "Yeragamballi channel road",
          "Gorukana Eco Wellness Retreat",
          "Agara-Mamballi Kere",
          "Triyambakapura",
          "Bandipur NP--Mangala Village Road West of Mangala",
          "BR Hills--KGudi",
          "Ponnachi",
          "Chikkahole Reservoir, Chamarajanagara Dt",
          "Ranganatha Lake",
          "K. Gudi, B.R. Hills",
          "Yelandur Kere",
          "Parvathi Betta",
          "Bandipur NP--Gopalswamy Betta",
          "Gumballi Kere, Yelandur",
          "Kesthuru Kere",
          "Bandalli Forest",
          "MM Hills Ghat, Kokkuvere Village",
          "yelekere",
          "Male Mahadeshwara Betta",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€š",
          "Haradanahalli district forest",
          "Mulluru Kere",
          "Bandipur -- Himavad Gopalaswamy Temple Lake",
          "Cauvery WLS--Bheemeshwari Fishing & Nature Camp",
          "BR Hills--Bellatha Dam",
          "Kollegal--Agrahara Kere",
          "Gopinatham Mystery Trails, Karnataka, IN (12.049, 77.688)",
          "Cauvery WLS--Gopinatham",
          "Doddarayapete Lake",
          "Amble, Chamarajanagara",
          "Edahalli Doddi",
          "Malai Mahadeshwara Wildlife Sanctuary",
          "BR Hills--Honnametti coffee estate",
          "Kurubana Katte Road",
          "BR Hills--Keredimba Podu",
          "Kollegal--Kunagalli",
        ],
      },
      {
        district: "Bidar",
        localities: [
          "Bidar--Airport Area Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¦Ã Â²Â°Ã Â³Â--Ã Â²ÂµÃ Â²Â¿Ã Â²Â®Ã Â²Â¾Ã Â²Â¨ Ã Â²Â¨Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â£ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Bidar--Chidri Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¦Ã Â²Â°Ã Â³Â--Ã Â²Å¡Ã Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¿",
          "Bidar Fort Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¦Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€ ",
          "Bidar Grasslands--Airport Area Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¦Ã Â²Â°Ã Â³Â Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â--Ã Â²ÂµÃ Â²Â¿Ã Â²Â®Ã Â²Â¾Ã Â²Â¨ Ã Â²Â¨Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â£ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Bidar Railway Station Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¦Ã Â²Â°Ã Â³Â Ã Â²Â°Ã Â³Ë†Ã Â²Â²Ã Â³Â Ã Â²Â¨Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â£",
          "Bidar--Ring Road near Zamistanpur Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¦Ã Â²Â°Ã Â³Â --Ã Â²ÂµÃ Â²Â°Ã Â³ÂÃ Â²Â¤Ã Â³ÂÃ Â²Â³ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€  Ã Â²Å“Ã Â²Â®Ã Â²Â¿Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â²Â¾Ã Â²Â¨Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Blackbuck Resort  Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â®Ã Â³Æ’Ã Â²â€” Ã Â²Â°Ã Â³â€ Ã Â²Â¸Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Chondi Grassland Ã Â²Å¡Ã Â³Å Ã Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Chulki Naala Dam, Mustapur Ã Â²Å¡Ã Â³ÂÃ Â²Â³Ã Â²â€¢Ã Â²Â¿ Ã Â²Â¨Ã Â²Â¾Ã Â²Â²Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯, Ã Â²Â®Ã Â³ÂÃ Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Dori Kere, Ã Â²Â¦Ã Â³â€¹Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ â€” Ã Â²Â°Ã Â³â€ Ã Â²Â¸Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Dzuluk to Nathang Valley Part 1, Ã Â¤Â¸Ã Â¤Â¿Ã Â¤â€¢Ã Â¥ÂÃ Â¤â€¢Ã Â¤Â¿Ã Â¤Â®, IN (27.255, 88.781)",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â¸Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â¯",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA 13.02312, 77.56792",
          "Janawad Ã Â²Å“Ã Â²Â¨Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡",
          "Kalale Kere, Nanjangud (Ã Â²â€¢Ã Â²Â³Ã Â²Â²Ã Â³â€ )Å¡Ã Â²Â¾",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â",
          "Karanja Backwaters--Ranjolkheni Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¾ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â³â€¹Ã Â²Â³Ã Â²â€“Ã Â³â€¡Ã Â²Â£Ã Â²Â¿",
          "Karanja Reservoir Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Karanja Reservoir--Hilalpur Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯--Ã Â²Â¹Ã Â²Â¿Ã Â²Â²Ã Â²Â¾Ã Â²Â²Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã  Â²Â²Ã Â²Â¾Ã Â²ÂÃ Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Kolibailu / Ã Â²â€¢Ã Â³â€¹Ã Â²Â³Ã Â²Â¿ Ã Â²Â¬Ã Â³Ë†Ã Â²Â²Ã Â³Â²â€” Ã Â²Â°Ã Â³â€ Ã Â²Â¸Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Malleshwara Gudda, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€”Ã Â³Â",
          "Malleshwara Gudda, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡ Â²Å¸Ã Â³Â",
          "Markhal Kere, Bawalpur Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Ã Â²Â¬Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³ÂÃ Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Marur lake Ã Â²Â®Ã Â²Â°Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 32C, Dalvoy Kere, Mysore Ã Â²Â¦Ã Â²Â³Ã Â²ÂµÃ Â²Â¾Ã Â²Â¯Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Â³Â",
          "N 09Ã‚Â° 04.135', E 077Ã‚Â° 10.571' Â²ÂµÃ Â²â€šÃ Â²Å¡Ã Â²Â¾",
          "nal sarovar bird.sanctury  nada thal-- Ã Âªâ€”Ã Â«ÂÃ ÂªÅ“Ã ÂªÂ°Ã ÂªÂ¾Ã ÂªÂ¤, IN (2",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿)Ã Â²Â¸Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "NidwanchaÃ¢â‚¬Å’ Ã Â²Â¨Ã Â²Â¿Ã Â²Â¡Ã Â²ÂµÃ Â²â€šÃ Â²Å¡Ã Â²Â¾",
          "Nittur Grassland Ã Â²Â¨Ã Â²Â¿Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)",
          "Papnash Lake Forest Ã Â²ÂªÃ Â²Â¾Ã Â²ÂªÃ Â²Â¨Ã Â²Â¾Ã Â²Â¶ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯",
          "Pragati Pathagar (Ã Â¦ÂªÃ Â§ÂÃ Â¦Â°Ã Â¦â€”Ã Â¦Â¤Ã Â¦Â¿ Ã Â¦ÂªÃ Â¦Â¾Ã Â¦Â Ã Â¦Â¾Ã Â¦â€”Ã Â¦Â¾Ã Â¦Â°)",
          "Shri Channabasaveshwar Kere, Bhalki Ã Â²Â¶Ã Â³ÂÃ Â²Â°Ã Â³â‚¬ Ã Â²Å¡Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¬Ã Â²Â¸Ã Â²ÂµÃ Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Ã Â²Â­Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²â€¢Ã Â²Â¿",
          "Shri Mahisasura Mardini Mata Temple, Saidapur Ã Â²Â¶Ã Â³ÂÃ Â²Â°Ã Â³â‚¬ Ã Â²Â®Ã Â²Â¹Ã Â²Â¿Ã Â²Â¸Ã Â²Â¾Ã Â²Â¸Ã Â³ÂÃ Â²Â° Ã Â²Â®Ã Â²Â°Ã Â³ÂÃ Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¿ Ã Â²Â®Ã Â²Â¾Ã Â²Â¤Ã Â²Â¾ Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â²Â¿, Ã Â²Â¸Ã Â³Ë†Ã Â²Â¦Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Sule Kere Ã Â²Â¸Ã Â³â€šÃ Â²Â³Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Vaakkoor Lake (Ã Â®ÂµÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)",
        ],
      },
      {
        district: "Tumakuru",
        localities: [
          "Albur Kere",
          "Amaani Kere, Tumkur",
          "Amruthur Chikka Kere",
          "Anchepalya Kere",
          "Aramanekallu Betta",
          "Baddihalli kere",
          "Ballekere",
          "Bamboo Forest, Devarayandurga",
          "Base of Fan Road (dense woods)",
          "Bheemasandra Kere, Tumkur",
          "Borana Kanive (Lakkenahalli Kere)",
          "Bugudanahalli",
          "Bukkapatna (Chinkara)Wildlife Sanctuary",
          "Byadarahalli Lake",
          "Bylanjaneya Swamy Temple  Koratagere",
          "Camp Shristi, Kamalapura",
          "Channarayana Durga Fort",
          "Chikkabidare Kere",
          "Chikkasandra State Forest",
          "Chikkayalkur Lake",
          "Chinaga Betta",
          "Cholenahalli Kere",
          "Deepambudhi Lake",
          "Devarayanadurga--Foothills",
          "Devarayanadurga State Forest--Namada Chilume",
          "Deverayanadurga State Forest",
          "Doddaguni lake",
          "Doddenahalli",
          "Dodmallur Lake",
          "Echanuru Tank",
          "Fan Road Kere (small)",
          "Forest Guest House, Bukkapatna Range",
          "Gangadareshwara Kere (Lake), Koratagere",
          "Gangasandra kere Ã Â²â€”Ã Â²â€šÃ Â²â€”Ã Â²Â¸Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Gangsandra tank",
          "Girigondanahalli",
          "Goobalagutte Kere",
          "Gubbi Lake",
          "Gubbi--Mukanahallipatna Kere",
          "Guleharavi kere",
          "Honnamachanahalli Lake",
          "Horticultural Area Lake",
          "Huliyurudurga, Kungal Taluk, Tumkur Dt",
          "Hunaseghatta Kere",
          "Hutridurga",
          "Jayamangali Blackbuck Conservation Reserve",
          "Jayamangali River",
          "JK Memorial Central School Road",
          "Kaggaladu Bird Sanctuary",
          "Kallembella Kere",
          "Kannughatta Kere",
          "Karadi Kere",
          "Kardi, Tumkur",
          "Kodi Lakkamma Devi Temple",
          "Konthihalli Lake",
          "Kothagere Kere (13.053, 77.027)",
          "K Palasandra",
          "Kunigal--Begur Amanikere",
          "kunigal chikkere",
          "Kunigal Dodda Kere",
          "Kunigal Stud Farm",
          "Lakkenahalli Lake",
          "Machaghatta",
          "Madhugiri Scrub Forest",
          "Malapanahalli Betta Voddagere",
          "Mallaghatta Lake",
          "Malleshapura",
          "Mandaragiri/Basadi Betta",
          "Markonahalli Reservoir",
          "Mavathuru Lake",
          "Mayasandra Kere",
          "Mayasandra Paddyfields",
          "Melekote lake, Tumkur",
          "Muthurayana Kere,  Tumkur Dt",
          "Mydala Reserve Forest",
          "Nonavinakere",
          "Nonavinakere Paddyfields, Tiptur Taluk",
          "Pavagada",
          "Ramadevara Betta",
          "Rangaswamy Bettada Kaval Forest",
          "Road to Yednambeel Kere (Checkdam)",
          "Salakatte, Ã Â²Â¸Ã Â²Â¾Ã Â²Â²Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³â€ ",
          "Salapura Kere",
          "Seebi Forest Ã Â²Â¸Ã Â³â‚¬Ã Â²Â¬Ã Â²Â¿ Ã Â²â€ Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯",
          "Seebi Narasimhaswami Temple Ã Â²Â¸Ã Â³â‚¬Ã Â²Â¬Ã Â²Â¿ Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â¹Ã Â²Â¸Ã Â³ÂÃ Â²ÂµÃ Â²Â¾Ã Â²Â®Ã Â²Â¿ Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨",
          "Siddapura Kere",
          "Sira Fort",
          "Srirampura Lake",
          "Srirampura lake--Puravara to ID Halli Road",
          "Suli Kere",
          "Talakere",
          "Theetha Kere",
          "Theja Farm",
          "Thimmegowdanapalya",
          "Thimmlapura Wildlife Sanctaury",
          "Thippapura Cross",
          "Tiptur",
          "Tiptur (OBI)",
          "Tumbadi Kere",
          "Turuvekere--Chikkapura",
          "Turuvekere--Hulikere",
          "TVS School, Pandithanalahalli, Tumkur",
          "Yadavani, Tumkur Dt",
        ],
      },
      {
        district: "Mandya",
        localities: [
          "Abalawadi kere",
          "Adichunchanagiri peacock sanctuary",
          "Aladahalli (Gajanur)",
          "Ananka Farm Stay,Karekura",
          "Arakere, Srirangapattana",
          "Avverahalli",
          "Bachahalli",
          "Balmuri falls surroundings",
          "Bangara Doddi",
          "Bangarasannidhi Farm",
          "Baremegala Koppalu",
          "Basadi Hosakote, KRS Backwaters",
          "Belakavadi Kere  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â²â€¢Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Belur Kere Ã Â²Â¬Ã Â³â€¡Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Betarayana Koppulu, Chinakurli",
          "Bhoo Varaha Swamy Temple, Kallahalli",
          "Boppagowdanapura kere",
          "Boregowdana Koppalu",
          "Brindavan Garden, KRS",
          "Byrasandra Hills",
          "Cauvery Wildlife Sanctuary",
          "Cauvery WLS- Muthathi",
          "Chandagalu kere",
          "Chandravana Road (Guri Thittu)",
          "Chikka Ankanahallikere Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢ Ã Â²â€¦Ã Â²â€šÃ Â²â€¢Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Chikkaballi Kere",
          "Chinakurali Outskirts",
          "Chukki Mane Resort",
          "Chunchagahalli- Kabbare Kere",
          "College Of Agriculture VC Farm",
          "Dadadahalli fields    Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²Â®Ã Â³Ë†Ã Â²Â¦Ã Â²Â¾Ã Â²Â¨",
          "Dadadahalli Kere (Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²",
          "Dadadahalli Kere (Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Dadaga Kere",
          "Danaguru High School",
          "Dari Daulat Bagh Srirangapatanam",
          "Dasanakere Ã Â²Â¦Ã Â²Â¾Ã Â²Â¸Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Dodda Byadarahalli Savannah",
          "Dodda Chikkanahalli kere",
          "Dodda Gosai Ghat",
          "Doddamakali Ã Â²Â¦Ã Â³Å Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â²Â®Ã Â²Â¾Ã Â²â€¢Ã Â²Â³Ã Â²Â¿",
          "Dyabasandra Kere",
          "Gaganachukki Falls",
          "Galibore nature camp",
          "Gendehosalli Islands, Srirangapatna",
          "Gende Hosalli Kere",
          "Giribettada Kere Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²â€¢Ã Â³â€ Ã Â²",
          "Gowdagere",
          "Grace Farm",
          "Guthalu Kere",
          "Halaguru kere",
          "Hallegere kere",
          "Hardanahalli kere",
          "Hatna Wetland",
          "Hemagiri, KR Pete Taluk",
          "Honnagala Kere",
          "Honnakatte Kere",
          "Hosabudanuru Kere",
          "Hosaholau kere Ã Â²Â¹Ã Â³Å Ã Â²Â¸Ã Â²Â¹Ã Â³Å Ã Â²Â³Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hosa koppalu lake",
          "Hosamane Colony Pond",
          "Huralikyathana Halli",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-Karnataka 13.02100, 77.57068",
          "Jayamahal Main Road, Bengaluru, Ã Â¤â€¢Ã Â¤Â°Ã Â¥ÂÃ Â¤Â¨Ã Â¤Â¾Ã Â¤Å¸Ã Â¤â€¢, IN (12.994, 77.",
          "Jodi Aaladamara kere   Ã Â²Å“Ã Â³â€¹Ã Â²Â¡Ã Â²Â¿ Ã Â²â€ Ã Â²Â²Ã Â²Â¦Ã Â²Â®Ã Â²Â° Ã Â²",
          "Kadathanamale Kere (Ã Â²â€¢Ã Â²Â¡Ã Â²Â¤Ã Â²Â¨Ã Â²Â®Ã Â²Â²Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Kalkuni Kere",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Kanagan Lake (Ã Â®â€¢Ã Â®Â©Ã Â®â€¢Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kannali kere Ã Â²â€¢Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "karadanalli",
          "Karadya lake",
          "Karanji Kere Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Karekura",
          "Karighatta",
          "Kaveri Bridge & Surrounds Ã Â®â€¢Ã Â®Â¾Ã Â®ÂµÃ Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â",
          "Kaveri Riverine, Srirangapattana",
          "Kaveri River near Shrirangapatna Station",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â",
          "Keregodu kere",
          "Keregodu kere 2",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³Â",
          "Kikkeri Kere Ã Â²â€¢Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kirugavalu kere Ã Â²â€¢Ã Â²Â¿Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kodagalli Kere (Ã Â²â€¢Ã Â³Å Ã Â²Â¡Ã Â²â€”Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Koorgalli--Power Grid Corporation of India Limited",
          "Koppa Kere   Ã Â²â€¢Ã Â³Å Ã Â²ÂªÃ Â³ÂÃ Â²Âª  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Krishnaraja Sagar -confluence of River Kaveri, Hemavathi & Laxmanathirtha, Mandya & Mysore Dts",
          "KR Pete Kere",
          "Kunti Betta",
          "Lumbini Park Ã Â²Â²Ã Â³ÂÃ Â²â€šÃ Â²Â¬Ã Â²Â¿Ã Â²Â¨Ã Â²Â¿ Ã Â²ÂªÃ Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²",
          "Madduru--Kadalur lake",
          "Madduru kere Ã Â²Â®Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mahadevapura Bridge",
          "Malavalli Kere Ã Â²Â®Ã Â²Â³Ã Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Malavalli town",
          "Malleshwara Gudda, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â",
          "Marehalli Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³â€ Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Marehalli Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³â€ Ã Â²Â¹Ã Â²Â³Ã Â³Â Â²â€šÃ Â²â€”Ã Â²Â¾Ã ÂÂ³€šÃ Â²Â¬Ã Â³ÂÃ ",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mayura River View",
          "Melkote Temple Wildlife Sanctuary",
          "Melkote Town",
          "Mellahally Kere",
          "Mogarahalli, Mysore",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ ",
          "Mudigere Kere",
          "Mysore City Bird Atlas 1A, BEML -Infosys midway",
          "Mysore City Bird Atlas 1B, Adjoining Emerald Enclave, behind Infosys",
          "Mysore City Bird Atlas 1C, BEML & KIADB Area",
          "Mysore City Bird Atlas 2A, HPCL behind, Hebbala",
          "Mysore City Bird Atlas 2B,  Hebbal Industrial Area",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€š",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿³Â",
          "Mysore City Bird Atlas 34B, Avverahalli",
          "Mysore City Bird Atlas 35A, KRS Road, Hulikere",
          "Mysore City Bird Atlas 35B",
          "Mysore City Bird Atlas 35C, Near Belagola Sub-Station, Kuppedada",
          "Mysore City Bird Atlas 35D",
          "Mysore City Bird Atlas 36A, Belagola",
          "Mysore City Bird Atlas 36B,Hosananduru Main Rd",
          "Mysore City Bird Atlas 36C",
          "Mysore City Bird Atlas 36D",
          "Mysore City Bird Atlas 37A, Pump House",
          "Mysore City Bird Atlas 37B, public road",
          "Mysore City Bird Atlas 37C, next to' I can; school",
          "Mysore City Bird Atlas 38B, about a klm from Mysore-Bangalore jun",
          "Mysore City Bird Atlas 41B",
          "Mysore City Bird Atlas 42A",
          "Mysore City Bird Atlas 42B",
          "Mysore City Bird Atlas 42C",
          "Mysore City Bird Atlas 42D",
          "Mysore City Bird Atlas 5B, Sujaya Industries surroundings, Koorgalli, Bangalore",
          "Mysore City Bird Atlas Second 34C Mydanahalli",
          "Mysore City Bird Atlas Second 44B, Public road",
          "Nagarakere",
          "Naguvanahalli",
          "Narayana Durga, Rayasamudra Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Â£ Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²â€”",
          "Narayanapura Kere",
          "Palahalli",
          "Palkere Reservoir",
          "Pandavapura Kere Ã Â²ÂªÃ Â²Â¾Ã Â²â€šÃ Â²Â¡Ã Â²ÂµÃ Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ranganathittu Bird Sanctuary Ã Â²Â°Ã Â²â€šÃ Â²â€”Ã Â²Â¨Ã Â²Â¤Ã Â²Â¿Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â Ã Â²ÂªÃ Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Rayasamudra Kere",
          "Rudrakshi Pura Kere",
          "Shivanasamudra- Canal view point",
          "Shree Swaminarayan Gurukul International School",
          "Shyadanahalli, Mysore",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)€šÃ Â²Â°Ã Â³Â",
          "Srirangapatna",
          "Srirangapatna Nimishamba temple",
          "Srirangapatna Sangama",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³Â",
          "Sule Kere Ã Â²Â¸Ã Â³â€šÃ Â²Â³Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Sule Kere, near Nagamangala",
          "Tailuru Kere Ã Â²Â¤Ã Â³Ë†Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³âÂ³Â Ã Â²â€¢Ã Â",
          "Tailuru Kere Ã Â²Â¤Ã Â³Ë†Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Tonnuru Kere Ã Â²Â¤Ã Â³Å Ã Â²Â£Ã Â³ÂÃ Â²Â£Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "TRC Worldwide Engineers Pvt Ltd.",
          "Tungabhadra Reservoir Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³",
          "Valagere Menasa (Dodda kere)",
          "Varuna kere (Ã Â²ÂµÃ Â²Â°Ã Â³ÂÃ Â²Â£Ã Â²Â¾ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ) Â²Â°Ã Â³â€ ",
          "Vasanthapura",
          "Wasi Cottage lake",
          "Yedumuri walking trail",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)",
        ],
      },
      {
        district: "Kodagu",
        localities: [
          "Magnolia Estates & Resort",
          "Thakeri Rapids",
          "Mojo Plantation Rainforest Retreat",
          "Raja's Samadhi, Madikeri",
          "Begur to Ayyappan Kavu watch tower trek",
          "Hilldale Resort",
          "Kote Abbe Falls",
          "Virajpete, Karanataka",
          "Jungle Mount Adventures",
          "Harangi Dam",
          "Harangi backwaters--Herur",
          "Brahmagiri WLS - Makkutta",
          "Prakash Estate",
          "Monstrose Golf Resort and Spa",
          "Amanvana Resort",
          "Aurika Coorg-Luxury Resort",
          "Harangi Backwaters- Yedavare",
          "Nagerhole--Kutta",
          "Kodagu's Cuckoo field station",
          "Nagarahole NP--General Area",
          "Birunani--Barapole River Eastern Bridge",
          "Kannanda Bane",
          "Abbey (Abbi) Falls",
          "Pushpagiri Reserve Forest-Maandalapatti",
          "Nagarahole NP--Mathigodu Elephant Camp",
          "Kabbe Hills",
          "Bison Manor Country Retreat",
          "Madikeri--General Area (OBI)",
          "Kallehebballa beat Antrasantnthe",
          "Green Pastures, Somawarpet",
          "Madapura",
          "Orange Resort, siddapura, Kodagu",
          "Nagarahole (Murkal)",
          "Ibbani Kadu Estate",
          "Linger, Chettimani",
          "Neerkatte camp, Kallahalla range",
          "Tadiandamol Forest Check Post",
          "Vate Abbi, Kodagu (Coorg), Karnataka",
          "Kotebetta",
          "Aloka  Homestay",
          "Suntikoppa, Madikeri, Karnataka, IN (12.434, 75.74)",
          "Kutta--General Area (OBI)",
          "Somwarpet--General Area",
          "Old Kent Estates (Horoor Estate)",
          "Coorg Raga - Nature Preserve",
          "Monnengeri",
          "Palace estate",
          "Devara Kolli, Sampaje",
          "Dream Acres",
          "Kirgoor",
          "College of Forestry, Ponnampet",
          "Kodagu's Cuckoo Field Station",
          "Kushalnagar--General Area (OBI)",
          "WEST ELERI (131,14,8)",
          "Kaveri Bridge, Hebbale-Sulekote Road",
          "Sampaje",
          "Madikeri East Reserve Forest",
          "Gonikoppal",
          "Pudhukolli Estate Valley",
          "Sand Banks Homestay",
          "Forestdale,Chembebellore",
          "Suntikoppa, Ã Â²Â¶Ã Â³ÂÃ Â²â€šÃ Â²Å¸Ã Â²Â¿Ã Â²â€¢Ã Â³Å Ã Â²ÂªÃ Â³ÂÃ Â²Âª",
          "Patte Paare",
          "Chiklihole Reservoir",
          "Nalknad Palace",
          "Brahmagiri WLS Peak",
          "Neerkatte camp, Kallahalla Range",
          "Club Mahindra, Coorg",
          "Surlabbi Waterfalls",
          "Thithimathi",
          "Kaveri Nisargadhama, Kodagu Dt",
          "Machaan Wilderness Lodge, Nagarhole",
          "Club Mahindra Resort, Virajpet",
          "Taj Madikeri Resort & Spa",
          "Forest near FMC Ground",
          "Institute of Wildlife Veterinary Research",
          "Mandalpatti",
          "Poomale Estate",
          "Thithimathi Timber Depot",
          "Makkala Gudi Betta",
          "Mallalli waterfall trail",
          "Pushpagiri Wildlife Sanctuary",
          "Doddaththur, Kodagu Dt",
          "Machaan Lodge",
          "Kote Betta",
          "Bhadrakali Temple, Galibeedu",
          "Hornbills Nest, Parvathi Valley Estates",
          "Anekad Forest,Herur",
          "SAI sanctuary",
          "Hotel Mayura Valley View",
          "Coorg Wilderness Resort",
          "Madikeri Misty, Coorg",
          "Woodland Bungalow",
          "Belur Golf Club, Somwarpet, Kodagu (Coorg)",
          "Galibeedu",
          "Yedehally Estate",
          "Tadiandamol Hill base, Yevakapadi",
          "Gudloor",
          "Dubare Elephant Camp",
          "Misty Woods Resort",
          "Thadiyandamol Betta",
          "Maldare Bus Stop",
          "Kadagadal",
          "Saphali Family Restaurant",
          "Amanvana Spa Resort",
          "Birunani--Marenad High School & Surrounds",
          "Orange Country Resort, Coorg",
          "Sampige Guest House",
          "Club Mahindra",
          "Honey Valley Estate",
          "Lakshmana Teertha Bridge (Balele-Nittur)",
          "Iruppu waterfall",
          "Luckunda Estate,Thaila",
          "Theralu",
          "Purple Palms Resort",
          "Talakaveri Wildlife Sanctuary",
          "Ela Glamping",
          "Neerkatte Camp, Kallahalla Range",
          "Karada Village",
          "Hemavathi Backwaters- Umblibetta",
          "Chingaara - Honey Valley's Estate House",
          "Perambadi, Kodagu (Coorg)",
          "Coorg Cliffs Resort",
          "Bird Song (Coorg Nature Retreat)",
        ],
      },
      {
        district: "Kalaburagi",
        localities: [
          "Appana Kere, Kalaburagi Ã Â²â€¦Ã Â²ÂªÃ Â³ÂÃ Â²ÂªÃ Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Ã Â²â€¢Ã Â²Â²Ã Â²Â¬Ã Â³ÂÃ Â²Â°Ã Â²â€”Ã Â²Â¿",
          "Belakota Kere Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bennethora Reservoir--Harsur-Sathkhed  Ã Â²Â¬Ã Â³â€ Ã Â²Â£Ã Â³ÂÃ Â²Â£Ã Â³â€ Ã Â²Â¤Ã Â³Å Ã Â²Â°Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯--Ã Â²Â¹Ã Â²Â°Ã Â²Â¸Ã Â³â€šÃ Â²Â°Ã Â³Â-Ã Â²Â¸Ã Â²Â¾Ã Â²Â¤Ã Â³ÂÃ¢â‚¬Å’Ã Â²â€“Ã Â³â€¡Ã Â²Â¡Ã Â³Â",
          "Bennethora Reservoir--Yankanchi Ã Â²Â¬Ã Â³â€ Ã Â²Â£Ã Â³ÂÃ Â²Â£Ã Â³â€ Ã Â²Â¤Ã Â³Å Ã Â²Â°Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯--Ã Â²Â¯Ã Â²â€šÃ Â²â€¢Ã Â²â€šÃ Â²Å¡Ã Â²Â¿",
          "Bheema River--Sannati Bridge Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿--Ã Â²Â¸Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¤Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Bhosga Kere Ã Â²Â­Ã Â³â€¹Ã Â²Â¸Ã Â³ÂÃ Â²â€”Ã Â²Â¾ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Buddha Vihar Ã Â²Â¬Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â§ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¹Ã Â²Â¾Ã Â²Â°",
          "Central University of Karnataka Ã Â²â€¢Ã Â²Â°Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Å¸Ã Â²â€¢ Ã Â²â€¢Ã Â³â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â³â‚¬Ã Â²Â¯ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Chandapur Ã Â²Å¡Ã Â²â€šÃ Â²Â¦Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Chandrampalli Dam Ã Â²Å¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²â€šÃ Â²ÂªÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Chincholi Ã Â²Å¡Ã Â²Â¿Ã Â²â€šÃ Â²Å¡Ã Â³â€¹Ã Â²Â³Ã Â²Â¿",
          "Chincholi Wildlife Sanctuary--Gottamgotta Ã Â²Å¡Ã Â²Â¿Ã Â²â€šÃ Â²Å¡Ã Â³â€¹Ã Â²Â³Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¨Ã Â³ÂÃ Â²Â¯Ã Â²Å“Ã Â³â‚¬Ã Â²ÂµÃ Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®--Ã Â²â€”Ã Â³Å Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²â€šÃ Â²â€”Ã Â³Å Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "CHINCHOLI WILE LIFE SANCTUARY AREA",
          "Gangapura Ã Â²â€”Ã Â²Â¾Ã Â²Â£Ã Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Gulbarga Fort Ã Â²â€”Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â¬Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾ Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€ ",
          "Gulbarga University Ã Â²â€”Ã Â³ÂÃ Â²Â²Ã Â²Â¬Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Kagina River Bridge, Dandoti Ã Â²â€¢Ã Â²Â¾Ã Â²â€”Ã Â²Â¿Ã Â²Â£Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ , Ã Â²Â¦Ã Â²â€šÃ Â²Â¡Ã Â³â€¹Ã Â²Â¤Ã Â²Â¿",
          "Khaja Kotnoor Lake Ã Â²â€“Ã Â²Â¾Ã Â²Å“Ã Â²Â¾ Ã Â²â€¢Ã Â³Å Ã Â²Å¸Ã Â³ÂÃ Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â¾ Ã Â²â€¢Ã Â³â€ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Ladlapur Ã Â²Â²Ã Â²Â¾Ã Â²Â¡Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Lower Mullamari Reservoir Ã Â²Â®Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â³Ã Â²Â¦Ã Â²â€šÃ Â²Â¡Ã Â³â€  Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Nimbarga Ã Â²Â¨Ã Â²Â¿Ã Â²â€šÃ Â²Â¬Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾",
          "Nirchalma Ã Â²Â¨Ã Â²Â¿Ã Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³ÂÃ Â²Â®Ã Â²Â¾",
          "Papnash Lake Forest Ã Â²ÂªÃ Â²Â¾Ã Â²ÂªÃ Â²Â¨Ã Â²Â¾Ã Â²Â¶ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯",
          "Rajapur Road--Koranti Hanuman Temple Ã Â²Â°Ã Â²Â¾Ã Â²Å“Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ --Ã Â²â€¢Ã Â³â€¹Ã Â²Â°Ã Â²â€šÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¹Ã Â²Â¨Ã Â³ÂÃ Â²Â®Ã Â²Â¾Ã Â²Â¨Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨",
          "Wadi Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿",
        ],
      },
      {
        district: "Hassan",
        localities: [
          "Adaravalli",
          "Arsikere Lake",
          "Attihalli",
          "Bedaiganahalli/Seebahalli Kere",
          "Bediganahalli Kere",
          "Bisle Ghat Checkpost",
          "Bylahalli Kere",
          "Changadihalli kere",
          "Channarayapatttana kere",
          "Chigaluru Kere (Lake)",
          "Chikka Anekere,",
          "Daivi Vana",
          "Dindaguru Kere",
          "Forest Patch, Bugadalli",
          "Gendekatte forest",
          "Hale Mane Homestay",
          "Hemavathi backwater-Madihalli",
          "Hemavathi Backwaters- Nagavara",
          "Hemavathi Backwaters-Shettihalli",
          "Hemavathi Backwaters- Ulivala",
          "Hemavathi Reservoir, Gorur",
          "Hosakere, Changadahalli",
          "Hosur Estate-Sakaleshpura",
          "Hoysala Village Resort",
          "Hoysaleshwara Temple",
          "Hulugundi",
          "Hunasina Kere",
          "Jambur Amanikere",
          "Janivara Kere",
          "Kaanana Home Stay",
          "Kadumane Estate--General Area",
          "Kadumane Estate--KT and KDM",
          "Kadumane Estate--MH",
          "Kadumane Estate--Near Entrance (includes canteen, factory, Sinna Dorai Bungalow)",
          "Kadumane Estate--VG (including VG-MG Road and Doraigundi)",
          "Kirkatte Kere, Jambur",
          "Kodanahalli",
          "Kottaragandi Kaadu, Kiruhunse",
          "Kumbarahalli - Sakleshpur",
          "Linger, Camrose, Sakleshpura",
          "Linger, Varshini Estate",
          "Lotus Pond (Sakleshpur-Hassan Road)",
          "Maharaja Park, Hassan",
          "Manjarabad Fort",
          "Mugilu Homestay",
          "Mulugade Pradesha (Submerged Area), Magalu",
          "Nagapuri Forest",
          "Navilahalli",
          "Neharu Nagara circle area,Belur",
          "Nidanur Kere",
          "Rachenahalli Kere",
          "Rudrapatna",
          "Sakleshpur",
          "Sakleshpura",
          "Sakleshpura Railway Station",
          "Shanthrigama Kere",
          "Shravanabelagola Temple",
          "Somapura Kere-Doddakanagal Road",
          "Thavarekere, Near Jambur",
          "Vajarahalli",
          "Vishnu Samudra (Bistammana Kere)",
          "Vishnu Samudra Kere",
          "Yagachi Reservoir, Belur",
        ],
      },
      {
        district: "Vijayapura",
        localities: [
          "Ainapur Tank Ã Â²ÂÃ Â²Â¨Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Å¸Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€šÃ Â²â€¢Ã Â³Â",
          "Almatti Backwaters--Benal R.C Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Â¬Ã Â³â€¡Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€ Ã Â²Â°Ã Â³Â.Ã Â²Â¸Ã Â²Â¿.",
          "Almatti Backwaters--Chimmalagi Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Å¡Ã Â²Â¿Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â²Ã Â²â€”Ã Â²Â¿",
          "Andian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Angadageri Kere Ã Â²â€¦Ã Â²â€šÃ Â²â€”Ã Â²Â¦Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMPÂ³â€ ",
          "Arakeri Kere Ã Â²â€¦Ã Â²Â°Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Asangi Kere Ã Â²â€ Ã Â²Â¸Ã Â²â€šÃ Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ã ÂªÂ¸Ã Â«ÂÃ ÂªÂ°Ã ÂªÂ¾Ã ÂªÂ¸Ã ÂªÂ¾Ã ÂªÂ®Ã ÂªÂ³, Ã Âªâ€”Ã Â«ÂÃ ÂªÅ“Ã ÂªÂ°Ã ÂªÂ¾Ã ÂªÂ¤, IN (22.685, 72.969)",
          "Ã Â¤Â¤Ã Â¥ÂÃ Â¤Â°Ã Â¤Â¿Ã Â¤Â¨Ã Â¥â€¡Ã Â¤Â¤Ã Â¥ÂÃ Â¤Â°Ã Â¥â‚¬, Pirkone, Mahara",
          "Ã Â¤Â¤Ã Â¥ÂÃ Â¤Â°Ã Â¤Â¿Ã Â¤Â¨Ã Â¥â€¡Ã Â¤Â¤Ã Â¥ÂÃ Â¤Â°Ã Â¥â‚¬, Pirkone, Maharas",
          "Babaleshwar Kere Ã Â²Â¬Ã Â²Â¬Ã Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bableshwar Kere Tq. Sindagi  Ã Â²Â¬Ã Â²Â¬Ã Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²Â¤Ã Â²Â¾. Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â¦Ã Â²â€”Ã Â²Â¿",
          "Balaganur Kere Ã Â²Â¬Ã Â²Â³Ã Â²â€”Ã Â²Â¾Ã Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Banthanal Mahila Vidyapeetha Ã Â²Â¬Ã Â²â€šÃ Â²Â¥Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²Â®Ã Â²Â¹Ã Â²Â¿Ã Â²Â³Ã Â²Â¾ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²ÂªÃ Â³â‚¬Ã Â²Â ",
          "Baratagi Kere Ã Â²Â¬Ã Â²Â°Ã Â²Å¸Ã Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Basavana Bagewadi Ã Â²Â¬Ã Â²Â¸Ã Â²ÂµÃ Â²Â¨ Ã Â²Â¬Ã Â²Â¾Ã Â²â€”Ã Â³â€¡Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿",
          "Basavasagar Dam Ã Â²Â¬Ã Â²Â¸Ã Â²ÂµÃ Â²Â¸Ã Â²Â¾Ã Â²â€”Ã Â²Â° Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Begum Talab Ã Â²Â¬Ã Â³â€ Ã Â³â€¢Ã Â²â€”Ã Â²â€š Ã Â²Â¤Ã Â²Â²Ã Â²Â¾Ã Â²Â¬Ã Â³Â",
          "Benapatti cross roadÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Benapatti Uncle's wellÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Bhima River--Khedagi Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿--Ã Â²â€“Ã Â³â€¡Ã Â²Â¡Ã Â²â€”Ã Â²Â¿",
          "Bhima River--Miragi Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿--Ã Â²Â®Ã Â²Â¿Ã Â²Â°Ã Â²Â¾Ã Â²â€”Ã Â²Â¿",
          "Bhima River- -Rodagi  Ã Â²Â­Ã Â³â‚¬Ã Â²Â®Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿- -Ã Â²Â°Ã Â³â€¹Ã Â²Â¡Ã Â²â€”Ã Â²Â¿",
          "Bhutnal Kere  Ã Â²Â­Ã Â³â€šÃ Â²Â¤Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bhutnal Kere  Ã Â²Â­Ã Â³â€šÃ Â²Â¤Ã Â²Â¨Ã Â²Â¾Ã ÂÃ°Å¸ÂÂ¤Ã°Å¸ÂÂ¦",
          "Bhutnallil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â°Ã ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Âµâ",
          "Bommanahalli Kere Ã Â²Â¬Ã Â³Å Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bommanajogi Kere Ã Â²Â¬Ã Â³Å Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Å“Ã Â³â€¹Ã Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bommanalli Kere (Devara Hipparagi) Ã Â²Â¬Ã Â³Å Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  (Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â°Ã Â²Â¹Ã Â²Â¿Ã Â²ÂªÃ Â³ÂÃ Â²ÂªÃ Â²Â°Ã Â²â€”Ã Â²Â¿)",
          "Chamundi Vana Ã Â²Å¡Ã Â²Â¾Ã Â²Â®Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¨",
          "Chinnakkuttai (Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã ",
          "Dawal Malik Dargah, Domanal Ã Â²Â¦Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â®Ã Â²Â²Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â¦Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾, Ã Â²Â¦Ã Â³â€¹Ã Â²Â®Ã Â²Â¨Ã Â²Â¾Ã Â²Â³",
          "Deserted Monuments, Ittangihal Rd Ã Â²Â¨Ã Â²Â¿Ã Â²Â°Ã Â³ÂÃ Â²Å“Ã Â²Â¨ Ã Â²Â¸Ã Â³ÂÃ Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â²â€¢Ã Â²â€”Ã Â²Â³Ã Â³Â, Ã Â²â€¡Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²â€šÃ Â²â€”Ã Â²Â¿Ã Â²Â¹Ã Â²Â¾Ã Â²Â³ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ ",
          "Devoor Kere Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â³â€šÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Dhanargi Kere Ã Â²Â§Ã Â²Â¨Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Domanal Kere Ã Â²Â¡Ã Â³â€¹Ã Â²Â®Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Doni River Bridge, Sarwad Ã Â²Â¡Ã Â³â€¹Ã Â²Â£Ã Â²Â¿ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ , Ã Â²Â¸Ã Â²Â¾Ã Â²Â°Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡",
          "Dudihal Kere Ã Â²Â¦Ã Â³â€šÃ Â²Â¡Ã Â²Â¿Ã Â²Â¹Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Gagan Mahal Ã Â²â€”Ã Â²â€”Ã Â²Â¨ Ã Â²Â®Ã Â²Â¹Ã Â²Â²Ã Â³Â",
          "Garasangi Kere Ã Â²â€”Ã Â²Â°Ã Â²Â¸Ã Â²â€šÃ Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Giribettada Kere Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Govt. ITI, Vijayapura Ã Â²Â¸Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²Â¿ Ã Â²Â.Ã Â²Å¸Ã Â²Â¿.Ã Â²Â, Ã Â²ÂµÃ Â²Â¿Ã Â²Å“Ã Â²Â¯Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Hanchinal Kere Ã Â²Â¹Ã Â²â€šÃ Â²Å¡Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Honnutagi Kere Ã Â²Â¹Ã Â³Å Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³ÂÃ Â²Å¸Ã Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA 13.02312, 77.56792",
          "Jalageri Kere Ã Â²Å“Ã Â²Â¾Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Jambagi-Aheri Kere Ã Â²Å“Ã Â²â€šÃ Â²Â¬Ã Â²â€”Ã Â²Â¿-Ã Â²â€ Ã Â²Â¹Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Jigajeevani Kere Ã Â²Å“Ã Â²Â¿Ã Â²â€”Ã Â²Å“Ã Â³â‚¬Ã Â²ÂµÃ Â²Â£Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kadlewad Kere Ã Â²â€¢Ã Â²Â¡Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kaggod Kere Ã Â²â€¢Ã Â²â€”Ã Â³ÂÃ Â²â€”Ã Â³â€¹Ã Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kamalapura Kere Ã Â²â€¢Ã Â²Â®Ã Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kapanimbargi Kere Ã Â²â€¢Ã Â²ÂªÃ Â²Â¨Ã Â²Â¿Ã Â²â€šÃ Â²Â¬Ã Â²Â°Ã Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Karjol Grassland- Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²Å“Ã Â³â€¹Ã Â²Â³ Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â",
          "Katral Kere  Ã Â²â€¢Ã Â²Â¾Ã Â²Â¤Ã Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "KLEU Campus Ã Â²â€¢Ã Â³â€ Ã Â²Å½Ã Â²Â²Ã Â³ÂÃ Â²â€¡Ã Â²Â¯Ã Â³Â Ã Â²â€ Ã Â²ÂµÃ Â²Â°Ã Â",
          "Kotnal Kere Ã Â²â€¢Ã Â³Å Ã Â²Å¸Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Krishna River Bridge, Kolhar Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ , Ã Â²â€¢Ã Â³Å Ã Â²Â²Ã Â³ÂÃ Â²Â¹Ã Â²Â¾Ã Â²Â°",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â°Ã ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Lalasangi Kere Ã Â²Â²Ã Â²Â¾Ã Â²Â³Ã Â²Â¸Ã Â²â€šÃ Â²â€”Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Makhanapur Kere Ã Â²Â®Ã Â²â€“Ã Â²Â£Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Malghan Kere Ã Â²Â®Ã Â²Â²Ã Â²ËœÃ Â²Â¾Ã Â²Â£ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mamadapur Kere (East) Ã Â²Â®Ã Â²Â®Ã Â²Â¦Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  (Ã Â²ÂªÃ Â³â€šÃ Â²Â°Ã Â³ÂÃ Â²Âµ)",
          "Mamadapur Kere (West) Ã Â²Â®Ã Â²Â®Ã Â²Â¦Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  (Ã Â²ÂªÃ Â²Â¶Ã Â³ÂÃ Â²Å¡Ã Â²Â¿Ã Â²Â®)",
          "Mannur Kere Ã Â²Â®Ã Â²Â£Ã Â³ÂÃ Â²Â£Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ ",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Mullakulam Pond (Ã Â®Â®Ã Â¯ÂÃ Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯Ë† Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®",
          "Mysore City Bird Atlas 17B, Bogadi Kere. Ã Â²Â¬Ã Â³â€¹Ã Â²â€”Ã Â²Â¾Ã Â²Â¦Ã Â²Â¿",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€š€¢Ã Â³â€ Ã Â²Â°Ã²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 32C, Dalvoy Kere, Mysore Ã Â²Â¦Ã Â²Â³Ã Â²ÂµÃ Â²Â¾Ã Â²Â¯Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nagaradinni Kere Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²Â°Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nagathan Kere Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²Â Ã Â²Â¾Ã Â²Â£ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nagawad Kere Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Padeknur Kere  Ã Â²ÂªÃ Â²Â¡Ã Â³â€¡Ã Â²â€¢Ã Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Periyakuttai (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã ",
          "Rajanal Kere Ã Â²Â°Ã Â²Â¾Ã Â²Å“Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Sankanal Kere Ã Â²Â¸Ã Â²â€šÃ Â²â€¢Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Savalsang Grassland Ã Â²Â¸Ã Â²Â¾Ã Â²ÂµÃ Â²Â³Ã Â²Â¸Ã Â²â€šÃ Â²â€” Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ ",
          "Somadevarahatti Kere Ã Â²Â¸Ã Â³â€¹Ã Â²Â®Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â°Ã Â²Â¹Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Sri Nagalingeshwara Waterbody Ã Â²Â¶Ã Â³ÂÃ Â²Â°Ã Â³â‚¬ Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¹Ã Â³Å Ã Â²â€šÃ Â²Â¡",
          "Sri Revanasiddeshwara Hill, Nagawad Ã Â²Â¶Ã Â³ÂÃ Â²Â°Ã Â³â‚¬ Ã Â²Â°Ã Â³â€¡Ã Â²ÂµÃ Â²Â£Ã Â²Â¸Ã Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡, Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡",
          "State Highway 55--Shegunashi Ã Â²Â°Ã Â²Â¾Ã Â²Å“Ã Â³ÂÃ Â²Â¯ Ã Â²Â¹Ã Â³â€ Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â°Ã Â²Â¿ 55--Ã Â²Â¶Ã Â³â€¡Ã Â²â€”Ã Â³ÂÃ Â²Â£Ã Â²Â¸Ã Â²Â¿",
          "Summer Palace Ã Â²Â¬Ã Â³â€¡Ã Â²Â¸Ã Â²Â¿Ã Â²â€”Ã Â³â€  Ã Â²â€¦Ã Â²Â°Ã Â²Â®Ã Â²Â¨Ã Â³â€ ",
          "Utnal Bridge Ã Â²â€°Ã Â²Â¤Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Vijayapura--Shivagiri Ã Â²ÂµÃ Â²Â¿Ã Â²Å“Ã Â²Â¯Ã Â²ÂªÃ Â³ÂÃ Â²Â°--Ã Â²Â¶Ã Â²Â¿Ã Â²ÂµÃ Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿",
          "Vijayapura--Yogapur Ã Â²ÂµÃ Â²Â¿Ã Â²Å“Ã Â²Â¯Ã Â²ÂªÃ Â³ÂÃ Â²Â°--Ã Â²Â¯Ã Â³â€¹Ã Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Wade Kere Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Yakkundi Chikka Neeravari Kere Ã Â²Â¯Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢ Ã Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â²Â¾Ã Â²ÂµÃ Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "ZP Road Ã Â²Å“Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾ Ã Â²ÂªÃ Â²â€šÃ Â²Å¡Ã Â²Â¾Ã Â²Â¯Ã Â²Â¤Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ ",
        ],
      },
      {
        district: "Udupi",
        localities: [
          "Malebettu, Durga Village, Karkala, Karnataka",
          "Magadha Vana, Pernoli",
          "Shettybettu 3 Parkala School Side",
          "Hoode beach",
          "Varanga Jain temple tank",
          "Vedvyasanagar, Udyavar, Karnataka, IN (13.313, 74.743)",
          "Kemthoor -- Hanging Bridge and Check Dam",
          "Barkur Wetlands",
          "Pelagic HotSpot, Udupi, In: 13.4-74.5",
          "Mattu- Beach and Padukere",
          "Kodi bengre-Kemmannu , Udupi, Karnataka",
          "Vanake Abbey falls (foot)",
          "Anejari Butterfly Camp--Mookambika WLS",
          "TAPMI Road",
          "Bhandarkeri Matha, Barkur",
          "Kemmannu -- Gujjarabettu Paddyfields",
          "TAPMI Campus",
          "Kurkalu Reserve forest, Udupi, Karnataka, IN (13.285, 74.775)",
          "The Concerned for Working Children Namma Bhoomi, Kundapura",
          "Preethi Farms",
          "Mookambika Wildlife Sanctuary",
          "Brahmalingeswara Temple, Seethanadi",
          "Durga Village, Karkala",
          "Barkur Wetlands--Saasthana",
          "Kudremukh -- Kurinjal Peak",
          "Makodi - shirur",
          "Turtle Bay Beach Resort",
          "Badanidiyoor-Paddy fields-Wetlands",
          "Babbukudru Island",
          "Pandeshwara, Sasthana",
          "yerlapady, Karakala",
          "Rajeev Nagar School Road - Manipal",
          "Seethanadhi Nature camp, Someshwara, Karnataka",
          "Kabbinale",
          "Kalmady Bridge, Udupi-Malpe road",
          "Karwal Dumping Yard",
          "Pelagic HotSpot, Udupi, In: 13.4-74.1",
          "Kapu (Kaup) Beach",
          "D'Sa Camp Site- Unnamed Road, Moodalkatte, Karnataka, IN (13.611, 74.725)",
          "Indrali Temple Road",
          "Gundupade Hill and surrounds, Marne",
          "Vidyarathna Nagar --  Behind Nakshatra Apartments",
          "Gujjarbettu Beach",
          "Pancha gangavalli",
          "Mookambika Temple, Kollur",
          "Eshwar Nagar, Manipal",
          "Yerlapady, Karkala",
          "Irvatthur",
          "Sharmada, Olachil, near Babbuswamy Temple, Kemthoor",
          "Manchi Road",
          "Basil Cafe, manipal",
          "Belman",
          "Udupi--General Area (OBI)",
          "Dashrath Nagar - MIT laterite slope",
          "Kodi Delta Point & Sea Walk",
          "SIBM -- vicinity",
          "Golikatte",
          "Herga",
          "Gangoli",
          "Kundapur Coast (OBI)",
          "Kudremukha NP--Maala Village",
          "Yerlapady",
          "Badagabettu",
          "Hirebettu Road",
          "Nakre - Karkala",
          "Kemthur-PaduAlevoor Gadi (Border), Udupi",
          '"Sharmada", Olachil, near Babbuswamy Temple, Kemthoor',
          "Tallur Mangrove Patch",
          "Shirva--General Area (OBI)",
          "Kurkal (Kurkalu) -- River",
          "Baje Dam",
          "Gundmi Village -- General",
          "Shanthi Nagar - Navarra Colony",
          "Kolachekambala Mulki",
          "Vinayak Temple, Manipal",
          "Perampalli Marga -- Manipal",
          "Parkala-Devasthanabettu",
          "Malyadi lake, Kundapura",
          "Parkala Ã¢â‚¬â€ Garson Junction",
          "Pottu Kere",
          "Karkala--General Area (OBI)",
          "St. Mary's Island, Udupi",
          "Arasinagundi Falls trekking/jeep track",
          "Valley Flats and Marena Slope",
          "Manipal--General Area (OBI)",
          "Manipal Institute of Communication",
          "Eedu, Kudremukh NP, Karnataka",
          "Agumbe--Barkana View Point",
          "Sasihitlu Beach & Estuary",
          "Pelagic HotSpot, Udupi, In: 13.4-74.4",
          "Pithrody village",
          "Kallianpura, Udupi",
          "Muniyal, Karkala (OBI)",
          "Barkur Wetlands -- Bobbarya Temple",
          "Pragathi Nagar",
          "Manipal Institute of Technology",
          "Pithrody Village Grasslands",
          "Belmar -- General",
          "Durga Reserved Forest - temple area, Karkala",
          "Sharadanagar",
          "Kome -- General Area",
          "Vidyarathna Nagar -- Acharya Compound",
          "Herga Temple Road, Herga",
          "Kasturba Medical College",
          "Someshwara - Byndoor",
          "Pelagic HotSpot, Udupi, In: 13.4-74.3",
          "SDM Ayurvedic College Campus, Udupi",
          "Gujjarbettu Paddyfields",
          "Hosala, Barkur.",
          "Pelagic HotSpot, Udupi Coast, Karnataka, IN (13.35, 74.66)",
          "Mahatma Gandhi Memorial College, Udupi",
          "Ancestral Naga Temple and surrounds - Badagabettu",
          "Manipal Pre University College (aka MJC)",
          "Agasana Kere",
          "Manipal--End Point",
          "Shanthi Nagar -- Laterite Patch",
          "Kambala Katta Trail, Havanje",
          "Nitte",
          "Durga-Ambha Bhavani Temple Road, karkala, Karnataka",
          "Sasihithlu Vehicle Parking Lot",
          "Pelagic HotSpot, Udupi, In: 13.4-74.6",
          "Anekere Lake, Karkal, Karnataka",
          "Kodi--Kundapura",
          "Uppoor",
          "Hoode - Kodi Bengare Road - Delta Beach",
          "Ramasamudra , karkala",
          "Dasharath Nagar -- Arbi Falls side",
          "Karkala--Durga Harihareshwara Temple",
          "Shettybettu 2",
          "Upper Durga Village, Karkala, Karnataka",
          "Eshwar Nagar - Kela Perkala",
          "Malpe port, Udupi, Karnataka",
          "Padubidri End Point",
          "Manipal Lake",
          "Dalantre Lake, Yallur, Mudarangadi",
          "Kanda (Paddyfields), Kemthoor",
          "Saralebettu, Manipal",
          "Kodi Beach",
          "Kemthur Jelly Crusher Area",
          "St Mary's Island",
          "Badthingale Trail",
          "Durga Water Falls",
          "Seethanadi Nature Camp",
          "Kundapura Inland Jetty",
          "Manchikere",
          "maravanthe beach, kundapura",
          "Sri Bhuvanendra College, College Road, Karkala, Karnataka",
          "Shettybettu 1 -- Bobbarya Sthana temple side",
          "Dashrath Nagar",
          "Mattu -- Bridge Area",
          "Pelagic HotSpot, Udupi, In: 13.4-74.2",
          "Byndoor Village",
          "Koodluthirtha (Seetha Falls) , Someshwara, Karnataka",
          "Kurkalu temple, Udupi, Karnataka, IN (13.285, 74.775)",
          "Pethri",
          "Bantakal (OBI)",
          "Shravani Homestay",
          "Malpe-Mattu Road (spot 2), Mattu",
        ],
      },
      {
        district: "Mysuru",
        localities: [
          "Karanja Reservoir Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Kempannana Kere Ã Â²â€¢Ã Â³â€ Ã Â²â€šÃ Â²ÂªÃ Â²Â£Ã Â³ÂÃ Â²Â£Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kabini Reservoir",
          "Linganakatte, Pillahalli (Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¨ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³â€ )",
          "Magadi Bird Sanctuary Ã Â²Â®Ã Â²Â¾Ã Â²â€”Ã Â²Â¡Ã Â²Â¿ Ã Â²ÂªÃ Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²",
          "Mysore City Bird Atlas 7A, Hebbala 1st stage, kalyana mantapa",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€š€¢Ã Â³â€ Ã Â²Â°Ã²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 21D location name",
          "SMB layout",
          "Honnapura",
          "Mysore City Bird Atlas 24C, Two inlet feeders to Lingambudhi",
          "Abbur kere",
          "Nursery, Dhanvanthri Arogyashrama Nanjangudu",
          "Malavalli Kere Ã Â²Â®Ã Â²Â³Ã Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "21-23 Ã Â¤Â¶Ã Â¤Â¾Ã Â¤Â°Ã Â¤Â¦Ã Â¤Â¾ Ã Â¤Â¨Ã Â¤â€”Ã Â¤Â°, Hyderabad, Telangana, IN (17.36, 78.531)",
          "Vijayanagar 4th. stage Mysore",
          "Mysore City Bird Atlas 24A, Nagalinga -Deshalingeshwara Temple surroundings",
          "Mysore City Bird Atlas 41C",
          "Mysore City Bird Atlas 24D, RK Nagar, behind Ganesh Temple drain",
          "Mudalakoppalu",
          "Gullebail Ã Â²â€”Ã Â³ÂÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€¡Ã Â²Â¬Ã Â³Ë†Ã Â²Â²Ã Â³Â",
          "Mysore City Bird Atlas 22A, Teresian College surroundings",
          "Varuna kKere  Ã Â²Â¹Ã Â³â€ Ã Â²â€”Ã Â³ÂÃ Â²â€”Ãâ€¢Ã Â³â€ Ã Â²Â°Â¿ Ã Â²â",
          "Mysore City Bird Atlas 21C, Horse Park",
          "Mysore City Bird Atlas 13C, Valmiki Road",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â°Ã ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ",
          "Mysore City Bird Atlas 3A Mogarahalli",
          "Hulimavu Gudda  Ã Â²Â¹Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â³Â Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 16B, VTU behind marsh Waterbody",
          "Sindhuvalli, Nanjanagudu (Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â§Ã Â³ÂÃ Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â",
          "Mysore City Bird Atlas 23B, Sharada Public School, Kanakadasa nagara, Mysore",
          "Suradhenupura Kere (Ã Â²Â¸Ã Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¨Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã",
          "Sindhuvalli, Nanjanagudu (Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â§Ã Â³ÂÃ Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿)",
          "Mysore City Bird Atlas 27A, Chamundi Hill-North west",
          "Arkeshwara Temple (Yadathore)",
          "Gommatgiri, Bettadur, Karnataka",
          "Tonnuru Kere Ã Â²Â¤Ã Â³Å Ã Â²Â£Ã Â³ÂÃ Â²Â£Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ â€ Ã Â²Â°Ã Â³â€ ",
          "Bettada Mata ML Hundi",
          "varakodu lake",
          "Varakodu Scrub Jungle Ã Â²ÂµÃ Â²Â°Ã Â²â€¢Ã Â³Å Ã Â²Â¡Ã Â³Â Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "MUDA Park, Jodi Bevina Mara, Dattagalli",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³",
          "Seebi Forest Ã Â²Â¸Ã Â³â‚¬Ã Â²Â¬Ã Â²Â¿ Ã Â²â€ Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã ",
          "Mysore City Bird Atlas 6C, in and around Hootgalli 220 KV subsustaion",
          "Viveka Tribal Center for Learning Hosahalli, Beeramballi Post, H",
          "Roopa Nagar - Amritha kripa Hospital",
          "Mysore City Bird Atlas 3C, Bharuka Triveni Industries",
          "Avaragere kere, Ã Â²â€¦Ã Â²ÂµÃ Â²Â°Ã Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 22D location name",
          "Mysore City Bird Atlas 38A, Public road, agriculture field",
          "Yelwala, Karnataka",
          "Mysore City Bird Atlas 45C, Madagalli Doddakere Eastern area",
          "Basappana Katte,Manadavadi Road, Mysore",
          "Aswal Kere  Ã Â²â€ Ã Â²Â¸Ã Â³ÂÃ Â²ÂµÃ Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "JLR Kings Sanctuary,Nagarahole",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³",
          "Chamundi Vana Ã Â²Å¡Ã Â²Â¾Ã Â²Â®Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¨Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 45D, Manasa Serene Public Road",
          "Mysore City Bird Atlas 58C, Mandakalli",
          "TVS Factory Campus",
          "Mathru mandali circle",
          "Mysore City Bird Atlas 33C, Near Utanahalli,",
          "Giribettada Kere Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã",
          "JSS Campus Ã Â²Å“Ã Â³â€ Ã Â²Å½Ã Â²Â¸Ã Â³ÂÃ¢â‚¬Å’Ã Â²Å½Ã Â²Â¸Ã Â³Â Ã Â²â€¢Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€šÃ Â²ÂªÃ",
          "Bannur Heggere Ã Â²Â¬Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²Â¹Ã Â³â€ Ã Â²â€”Ã Â³ÂÃ Â²â€”Ã Â³â€ Ã Â²Â°Ã Â",
          "Ajjayyanahundi Kere, Ring Road",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿)€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Daivivan park",
          "Santhe Gadde Ã Â²Â¸Ã Â²â€šÃ Â²Â¤Ã Â³â€ Ã Â²â€”Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â³â€  Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ) Â²Â§Ã Â²Â¿",
          "Ramar Patham (Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â¾Ã Â®Â¤Ã Â®Â®Ã Â¯Â)",
          "Sivaranthagam Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®ÂµÃ Â®Â°Ã Â®Â¾Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®",
          "Karanji Kere Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Karanji Kere Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°",
          "Mysore City Bird Atlas 44D, Rammanahalli Christian Cemetery",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 30D, Sacred Grove, Parasaiyyana Hundi",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿Â°Ã Â³â€ ",
          "Benapatti Baya ColonyÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Kalihundi, Karnataka, IN (12.065, 76.696)",
          "Ayurvedic hospital, Arogyashrama",
          "Mysore City Bird Atlas 17C, People for Animals, RR Nagar, Mysore City",
          "Mysore City Bird Atlas 59C, Makanahundi",
          "Mysore City Bird Atlas 31A, Devayyana Hundi, Mysore",
          "Talakadu Kaveri River beach",
          "Mysore City Bird Atlas 58D,Mandakalli kere or Shetty Kere Ã Â²Â®Ã Â²â€šÃ Â²Â¡Ã Â²â€¢Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bettada beedu Scrub Forest , Mandahalli beat",
          "Mysore City Bird Atlas 5A, Besides Hyundai Showroom",
          "Mysore City Bird Atlas 37D, Public road with agricultural land",
          "Nugu Backwaters Ã Â²Â¨Ã Â³ÂÃ Â²â€”Ã Â³Â Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â",
          "Madduru Kere or Vadavina Katte, near Malleshwara gudda",
          "Tirumakudalu Narasipura",
          "Mysore City Bird Atlas 8D, Around IOCL  complex",
          "Mysore University",
          "Bastar, Chhattisgarh, IN Ã Â¤â€¢Ã Â¥â€¹Ã Â¤Â²Ã Â¤Â¾Ã Â¤Â¸Ã Â¥â€¡Ã Â¤Â°Ã Â¥â‚¬ Ã Â¤Â¨Ã Â¤Â¾Ã Â¤Â²Ã Â¤Â¾",
          "Bylakuppe",
          "Chamanahalli Kere Ã Â²Å¡Ã Â²Â¾Ã Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Belakavadi Kere  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â²â€¢Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kokkarehundi/Devvada halla (Near Bonthagahalli)",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã  Â²Â²Ã Â²Â¾Ã Â²ÂÃ Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Basavapatna village",
          "Konanur Kere",
          "HPO and RMS Layout",
          "Mysore City Bird Atlas 49D Nanjarajanahundi Fields",
          "Mysore City Bird Atlas 45B, Basaveshwara Badavane, M.U.D.A Park",
          "Metagalli, Mysore",
          "Handiganala,  Ã Â²Â¹Ã Â²â€šÃ Â²Â¡Ã Â²Â¿Ã Â²â€”Ã Â²Â¨Ã Â²Â¾Ã Â²Â³",
          "Maratikyathana Halli Kere",
          "Nature Conservation Foundation office",
          "Mysore City Bird Atlas 13B, Vivekananda Road, Yadavagiri",
          "Handigundi Betta Ã Â²Â¹Ã Â²â€šÃ Â²Â¦Ã Â²Â¿Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿  Ã Â²Â¬Ã ",
          "Dindagadu, Kodagu Dt",
          "Mysore City Bird Atlas 31D, JP Nagar, Koppalur, Mysore",
          "Chowkahalli Kere",
          "Arasanakere Ã Â²â€¦Ã Â²Â°Ã Â²Â¸Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Metikuppe",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)",
          "Thalavaipatty Lake (Mettupalayam Tank) Ã Â®Â¤Ã Â®Â³Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®ÂªÃ Â",
          "Mysore City Bird Atlas 22B location name",
          "Doddamakali Ã Â²Â¦Ã Â³Å Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â²Â®Ã Â²Â¾Ã Â²â€¢Ã Â²Â³Ã Â²Â¿",
          "Sharada Devi Nagara",
          "Mysore City Bird Atlas 4A, Nagahanahalli Paddyfields",
          "Thalavaipatty Lake (Mettupalayam Tank) Ã Â®Â¤Ã Â®Â³Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã ",
          "Mysore City Bird Atlas 28A, Chamundi Hill-Horsetrack Lalitadripura Rd Jn",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Malleshwara Gudda, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Mysore City Bird Atlas 30B, Srirampura 2nd Stage",
          "Mysore City Bird Atlas 29A South of Water Tank RT Nagar",
          "GSSS Institute of Engineering and Technology for Women",
          "Karanji Kere Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "19Ã‚Â°10'44.2\"N 73Ã‚Â°14'31.5\"EValivali Gaon, Manjarli, Badlapur, Maharashtra 421503s://goo.gl/maps/ZqimM7GtL3aKtDFc6",
          "chidralli lake",
          "RIE MYSURU",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ ",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³",
          "Muddahalli--Mooganakere Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿--Ã Â²Â®Ã Â³â€šÃ Â²â€”Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mudukuthore hill",
          "Mysore University--University Agriculture Farms",
          "Mallapur Island Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¨Ã Â²Â¡Ã Â³ÂÃ Â²â€”Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â³â€ ",
          "Dadadahalli fields    Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²Â®Ã Â³Ë†Ã Â²Â¦Ã Â²Â¾Ã Â²Â¨",
          "N. Begur range",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kalkeri Forest Ã Â²â€¢Ã Â²Â²Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mallapur Island Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¨Ã Â²Â¡Ã Â³ÂÃ Â²â€”Ã Â²Â¡Ã ",
          "Hosaholau kere Ã Â²Â¹Ã Â³Å Ã Â²Â¸Ã Â²Â¹Ã Â³Å Ã Â²Â³Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 30C, Areca Grove, Behind Rajaji Layout",
          "Aathikuppam Lake (Ã Â®â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â",
          "Danishpet Big Tank Ã Â®Å¸Ã Â¯â€¡Ã Â®Â©Ã Â®Â¿Ã Â®Â·Ã Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â",
          "Pungamaduvu Area Ã Â®ÂªÃ Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Karanja Reservoir Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯¿",
          "Mysore City Bird Atlas 11C, Vijayanagar 4th Stage",
          "Hampi--Matanga Hill Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Â®Ã Â²Â¾Ã Â²Â¤Ã Â²â€šÃ Â²â€” Ã Â²ÂªÃ Â²Â°Ã Â³ÂÃ Â²ÂµÃ Â²Â¤",
          "Hanumatharayana kere  Ã Â²Â¹Ã Â²Â¨Ã Â³ÂÃ Â²Â®Ã Â²â€šÃ Â²Â¤Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³",
          "Padma Farm",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA 13.02312, 77.56792",
          "Hyrige kere,Manandavadi Road",
          "Kodagalli Kere (Ã Â²â€¢Ã Â³Å Ã Â²Â¡Ã Â²â€”Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Kalale Kere, Nanjangud (Ã Â²â€¢Ã Â²Â³Ã Â²Â²Ã Â³â€ )",
          "Mysore City Bird Atlas 16A, Sathgalli, DTS Rao Layout, Mysore city",
          "Huyilalu, Karnataka",
          "Gudumanahalli Kere Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â®Ã Â²Â¾Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 11B, Vijayanagar 3rd Stage",
          "Mysore City Bird Atlas 38D, Public road, Naganahalli main road",
          "Dadadahalli Kere (Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â",
          "Harathale Kere, Nanjanagudu (Ã Â²Â¹Ã Â²Â°Ã Â²Â¤Ã Â²Â²Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Mysore City Bird Atlas 45A, Area behind Sharmada Farm",
          "Ramakrishna Institute of Moral and Spiritual Education (RIMSE) & RKM Vidyashala",
          "Basavanahalli",
          "Blackbuck Resort  Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â®Ã Â³Æ’Ã Â²â€” Ã Â²Â°Ã Â³â€ Ã ",
          "Kannali kere Ã Â²â€¢Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Blackbuck Resort  Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â®Ã Â³Æ’Ã Â²â€” Ã Â²Â°Ã Â³â€ Ã Â²Â¸Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ",
          "PHC Hadinaru",
          "Mysore City Bird Atlas 33D, Uthanahalli Temple",
          "Mysore City Bird Atlas 9D, Shobha Gardens",
          "Kamana kere hundi (Kesare Doddakere) Mysore City Bird Atlas 10B Ã Â²â€¢Ã Â²Â¾Ã Â²Â®Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²Â¹Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿",
          "Mysore City Bird Atlas 20D, Race Course Road, Mysore",
          "Marehalli Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³â€ Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kaggalipura Road, Bengaluru, Karnataka, IN (12.794, 77.537) Ã Â²Â¤Ã Â²Â°Ã Â²Â³Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Karanji Kere Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Kirugavalu kere Ã Â²â€¢Ã Â²Â¿Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°",
          "Yuvarajas College, Mysuru",
          "Mysore Zoo, Mysore City",
          "Madagalli, Ã Â²Â®Ã Â²Â¡Ã Â²â€”Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¿",
          "Mysore City Bird Atlas 18C, CFTRI Layout, Mysore City",
          "Mysore City Bird Atlas 20A, Sayyaji Rao Road",
          "Naganahalli Kere, H D Kote",
          "Somanathapura",
          "Kanagan Lake (Ã Â®â€¢Ã Â®Â©Ã Â®â€¢Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kapila River--Madapura to Chikkadevammana betta",
          "Mysore--General Area (OBI)",
          "Bilikere Kere",
          "Hinkal",
          "Haradur Lake, Karnataka, IN (12.486, 76.107)",
          "Hima Deepa Farm",
          "Kaniyanahundi Kere",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 43A",
          "Mysore City Bird Atlas 58B,Gudumadanahalli",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ ",
          "Indraprastha Ã Â²â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¥",
          "Mysore City Bird Atlas 33B, Chamundi Hill South-East",
          "Aimangala Scrubland Ã Â²ÂÃ Â²Â®Ã Â²â€šÃ Â²â€”Ã Â²Â² Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â",
          "Mysore City Bird Atlas 29D, Off Yedahalli Road, KBL Layout, Mysore",
          "Mysore City Bird Atlas 5C, Belwadi Village",
          "Jodi Aaladamara kere   Ã Â²Å“Ã Â³â€¹Ã Â²Â¡Ã Â²Â¿ Ã Â²â€ Ã Â²Â²Ã Â²Â¦Ã Â²Â®Ã Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bison Wildlife Resort, Kabini, Karnataka, IN (11.938, 76.244)",
          "Mysore City Bird Atlas 17A, Deepa-Tranquail",
          "Omkar range--Bandipur TR",
          "Belakavadi Kere  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â²â€¢Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-Karnataka 13.02100, 77.57068",
          "Mysore City Bird Atlas 32C, Dalvoy Kere, Mysore Ã Â²Â¦Ã Â²Â³Ã Â²ÂµÃ Â²Â¾Ã Â²Â¯Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bhutnal Kere  Ã Â²Â­Ã Â³â€šÃ Â²Â¤Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nugu Backwaters Ã Â²Â¨Ã Â³ÂÃ Â²â€”Ã Â³Â Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬",
          "Taraka Reservoir",
          "Mysore City Bird Atlas 14B location name",
          "Mysore City Bird Atlas 3B, Shyadana halli",
          "Galigarahundi",
          "Hampi--Matanga Hill Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Â®Ã Â²Â¾Ã Â²Â¤Ã Â²â€šÃ Â²â€” Ã Â²ÂªÃ Â²Â°Ã Â³ÂÃ Â²ÂµÃ Â²",
          "Ramanuja road - Yele thota circle",
          "Mysore City Bird Atlas 4B, Behind Kasthuri Nivas hotel",
          "Bilugali Kabini Bridge - North Side",
          "Bettadapura Lake",
          "Hampapura Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Mysore City Bird Atlas 60A, Hadajana Kere",
          "Thalavaipatty Lake (Mettupalayam Tank) Ã Â®Â¤Ã Â®Â³Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kadathanamale Kere (Ã Â²â€¢Ã Â²Â¡Ã Â²Â¤Ã Â²Â¨Ã Â²Â®Ã Â²Â²Ã Â³â€  Ã Â²â€¢",
          "Talakadu Ã Â²Â¤Ã Â²Â²Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "Mysore City Bird Atlas 9A: Ring Road, Siddiqui Nagar West, Mysore",
          "Koppa Kere   Ã Â²â€¢Ã Â³Å Ã Â²ÂªÃ Â³ÂÃ Â²Âª  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Varuna kere (Ã Â²ÂµÃ Â²Â°Ã Â³ÂÃ Â²Â£Ã Â²Â¾ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Mysore City Bird Atlas 11A, Beh KSRTC bus depot, VNagar 4th stage",
          "Sule Kere Ã Â²Â¸Ã Â³â€šÃ Â²Â³Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ravandur Kere Ã Â²Â°Ã Â²Â¾Ã Â²ÂµÃ Â²â€šÃ Â²Â¦Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ring road, Narayana Hrudalaya Hospital, Mysore",
          "Ã Â¤ÂªÃ Â¥ÂÃ Â¤Â£Ã Â¥â€¡, Ã Â¤Â®Ã Â¤Â¹Ã Â¤Â¾Ã Â¤Â°Ã Â¤Â¾Ã Â¤Â·Ã³â€ Ã Â²Â°Ã Â³â€Ã Â¤Â°, IN Arenshwar  (18.487, 73.847)",
          "Mysore City Bird Atlas 29B, RT Nagar",
          "Lingapura Forest Gate",
          "Giribettada Kerea, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Harave Ã Â²Â¹Ã Â²Â°Ã Â²ÂµÃ Â³â€ ",
          "Kambalahalli Hills Ã Â²â€¢Ã Â²â€šÃ Â²Â¬Ã Â²Â¾Ã Â²Â²Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿²Â§Ã Â²Â¿",
          "Silent Shores Resort & Spa",
          "Mysore City Bird Atlas 20B, Hotel Siddartha Road",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-Karn",
          "Mysore City Bird Atlas 58A, Gejjagalli( near Varuna Canal)",
          "Kannali kere Ã Â²â€¢Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â³ÂÃ Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 13A, Gokulam 1st Main Road",
          "Mysore City Bird Atlas 20C, MG Rd, Agrahara, Mysore",
          "Kaveri Bridge, Bannur",
          "Kadathanamale Kere (Ã Â²â€¢Ã Â²Â¡Ã Â²Â¤Ã Â²Â¨Ã Â²Â®Ã Â²Â²Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â",
          "Bettadabeedu-Chikkanahalli scrub forest Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¡Ã Â³Â- Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "Benapatti cattle trailÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Devirammanahalli Hundi, Nanjanagudu (Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â³â‚¬Ã Â²Â°Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²Â¹Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿)",
          "Coconut Farm, Bilugali, Karnataka, IN - Ã Â²ÂÃ Â²Â¸Ã Â²Â¿Ã Â²Â°Ã Â²Â¿ Ã Â²Â¤Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â¿Ã Â²Â¨ Ã Â²Â¤Ã Â³â€¹Ã Â²Å¸ (12.183, 76.821)",
          "Bhutnal Kere  Ã Â²Â­Ã Â³â€šÃ Â²Â¤Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â³Ã Â²Â¿",
          "Aloka Palace scrub jungle, Mysore",
          "Gadijogihundi Kere",
          "Mysore City Bird Atlas 4C, Dattatreya Mutt surroundings",
          "Mysore Palace",
          "Gaddige-Hunsur : Chowdi katte",
          "Sulekerekaval Ã Â²Â¸Ã Â³â€šÃ Â²Â³Ã Â³â€ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²â€¢Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â",
          "Bannur Canal",
          "Ranamangala Subramanya Temple Road, Ã Â¤â€¢Ã Â¤Â°Ã Â¥ÂÃ Â¤Â¨Ã Â¤Â¾Ã Â¤Å¸Ã Â¤â€¢, IN (12.636, 75",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³Â",
          "Nuggikeri Kere Ã Â²Â¨Ã Â³ÂÃ Â²â€”Ã Â³ÂÃ Â²â€”Ã Â²Â¿Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Koorgalli",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 6D, BEML plantations",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Mysore City Bird Atlas 56D, Farmland Behind Rayana Kere",
          "Talakadu River bed",
          "Mysore City Bird Atlas 12D, Law School, UOM",
          "Mysore City Bird Atlas 55C",
          "Mysore City Bird Atlas 33A, Chamundi Hill -South",
          "Husainpura Bridge   Ã Â²Â¹Ã Â³ÂÃ Â²Â¸Ã Â³â€¡Ã Â²Â¨Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Madhava Manthri Dam, Talakadu Ã Â²Â®Ã Â²Â¾Ã Â²Â§Ã Â²ÂµÃ Â²Â®Ã Â²â€šÃ Â²Â¤Ã Â³ÂÃ Â²Â°Ã Â²Â¿ Ã Â²â€¦Ã Â²Â£Ã Â³â€ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Hanumatharayana kere  Ã Â²Â¹Ã Â²Â¨Ã Â³ÂÃ Â²Â®Ã Â²â€šÃ Â²Â¤Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mallapur Island Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¨Ã Â²Â¡Ã Â³ÂÃ Â²â",
          "Kunteri kere, Kalale, Nanjanagudu",
          "Attur Lake Ã Â®â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ÂÃ Â²Â³Ã Â²Â¿",
          "Halli Kere Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kyathanahalli kere (Harathale)",
          "Mysore City Bird Atlas 4D, KR Mills behind",
          "Mysore City Bird Atlas 31B, JP Nagar, Opp Brigade",
          "Mysore City Bird Atlas 26D, Ooty Road, behind IOC bunk",
          "Makanahundi, Mysore",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kadanahalli Gudda Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Marur lake Ã Â²Â®Ã Â²Â°Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hullahalli Nale (water canal)",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Mysore City Bird Atlas 13D location name",
          "Mysore City Bird Atlas 32A, Link Main Road, Visveshwara Nagar, Mysore",
          "Mysore City Bird Atlas 2D location name",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMPÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Katte thore  Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³â€  Ã Â²Â¤Ã Â³Å Ã Â²Â°Ã Â³â€ ",
          "Giribettada Kere Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²",
          "Mysore City Bird Atlas 14A location name",
          "Nuggikeri Kere Ã Â²Â¨Ã Â³ÂÃ Â²â€”Ã Â³ÂÃ Â²â€”Ã Â²Â¿Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²",
          "Mysore City Bird Atlas 57C,  Rayana Kere Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ariyapalayam Tank Ã Â®â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°",
          "Bilikere Watershed Project",
          "Mysore City Bird Atlas 54A, Open Layout Devagalli",
          "Nushikote Mangroves Ã Â²Â¨Ã Â³ÂÃ Â²Â¶Ã Â²Â¿Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€ ",
          "Mysore City Bird Atlas 50A: Thippayyana kere Ã Â²Â¤Ã Â²Â¿Ã Â²ÂªÃ Â³ÂÃ Â²ÂªÃ Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bandipur-Kabini Perifery",
          "Ukkalagere kere",
          "Mysore City Bird Atlas 18B, Manasagangothri,Physics Dept. Mysore University",
          "Karigala Kere (Doddakere) Ã Â²â€¢Ã Â²Â°Ã Â²Â¿Ã Â²â€”Ã Â²Â¾Ã Â²Â²¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 26C, Burial Ground, Mysore",
          "Vijayanagar Extension, Mysore (OBI)",
          "Kutwadi Kere Ã Â²â€¢Ã Â³ÂÃ Â²Å¸Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Gaddige-Hunsur",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã",
          "Mysore City Bird Atlas 25A,  Kaveri School and Twin Parks",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã ",
          "Ingalakere, Piriyapattana Tk, Mysore Dt",
          "Mysore City Bird Atlas 28B, Nethaji Nagar South",
          "Chittanahalli Lake, Hemmige",
          "Mysore City Bird Atlas 12A, Kodava Samaja surroundings, Vijayanagar 1st Stage",
          "Vadgal Ranganathaswamy Betta",
          "Chidravalli Kere",
          "Karapur Village (OBI)",
          "Mysore City Bird Atlas 1D, L & T -Infosys-DFRL complexes",
          "V-lead, 3rd Main Road, Mysuru, Karnataka, IN (12.354, 76.614)",
          "Mysore City Bird Atlas 21A Around Postal Training Centre",
          "Mysore City Bird Atlas  54D, Small Pond Dhanagalli",
          "NRH farm",
          "Periyapatna",
          "Kabini River, Nanjangudu nr Srikanteshwara temple",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â",
          "Chamundi Vana Ã Â²Å¡Ã Â²Â¾Ã Â²Â®Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¨€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Periyapattana Chikere Ã Â²ÂªÃ Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â¯Ã Â²Â¾Ã Â²ÂªÃ Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â£ Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Shakthi Nagar, Mysuru, Karnataka, IN (12.32, 76.697)",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Harohalli kere,Mananthvady Road",
          "Mysore City Bird Atlas 25B, Jayanagar railway line, Mysore",
          "Deputy Drugs Control Office Vijayanagar 4 Stage  Mysore  Karnataka IN",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Kalale--Kerehundi, Karnataka, IN (12.061, 76.664)",
          "Dornahalli Kere",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP²Â°Ã Â³â€ ",
          "Kabini River Resort, Karapura",
          "Mysore City Bird Atlas 8B BMS NAGAR",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â",
          "Bandipur tiger reserve--Moliyur range",
          "Cheluvamba Park, Mysore",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã",
          "Vijaya bank circle to kamakshi hospital",
          "Needhipuram Lake Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®",
          "Mysore City Bird Atlas 53C: Yandahalli kere, Mysore",
          "Doora Hosakere  Ã Â²Â¦Ã Â³â€šÃ Â²Â° Ã Â²Â¹Ã Â³Å Ã Â²Â¸Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hebbal 3rd Stage, Mysore",
          "Mysore City Bird Atlas 10A, Opp Narayana Hrudayalaya",
          "Mysore City Bird Atlas 23D, Somanatha Nagar, Mysore",
          "Mysore City Bird Atlas 41D",
          "Matakere",
          "Mysore City Bird Atlas 10C, Devnur tank",
          "Mysore City Bird Atlas 55A, Nagarthahalli",
          "Antharsanthe Kere, Ã Â²â€¦Ã Â²â€šÃ Â²Â¤Ã Â²Â°Ã Â²Â¸Ã Â²â€šÃ Â²Â¤Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã",
          "Mysore City Bird Atlas 10D, Rajiv Nagar",
          "Halekamana Koppalu, Karnataka",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿) Â²Â°Ã Â³â€ )",
          "Santhe Gadde Ã Â²Â¸Ã Â²â€šÃ Â²Â¤Ã Â³â€ Ã Â²â€”Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â³â€ ",
          "Betkuli Gazani Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€”Ã Â²Å“Ã Â²Â",
          "Chimili Kere",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â",
          "Gaddige-Hunsur : Devi Kere",
          "Marallu Oni, Gopalagowda Nagara Ã Â²Â®Ã Â²Â°Ã Â²Â³Ã Â³Â Ã Â²â€œÃ Â²Â£Ã Â²Â¿ Ã Â²â€”Ã Â³â€¹Ã Â²ÂªÃ Â²Â¾Ã Â²Â²Ã Â²â€”Ã Â³Å’Ã Â²Â¡ Ã Â²Â¨Ã Â²â€”Ã Â²Â°  (12.267, 76.708)",
          "Mysore City Bird Atlas 6A, Around Rane Madras factory complex",
          "Kirugavalu kere Ã Â²â€¢Ã Â²Â¿Ã Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "BIS CampusÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œ scrublandÃ¢â‚¬â€œPMPBIS CampusÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œ scrublandÃ¢â‚¬â€œPMP",
          "Benapatti Uncle's wellÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Doddakanya",
          "Orange County, Kabini",
          "Mysore City Bird Atlas 15A location name",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿)",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€š",
          "Hanchinal Kere Ã Â²Â¹Ã Â²â€šÃ Â²Å¡Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Madduru sanna kere",
          "Arakeri Kere Ã Â²â€¦Ã Â²Â°Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ghataprabha Bird Sanctuary Ã Â²ËœÃ Â²Å¸Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â­Ã Â²Â¾ Ã Â²ÂªÃ Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Mysore City Bird Atlas  44A, Public road",
          "Lingapura Lake (Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â Â²Â°Ã Â Ã Â²Â°Ã Â³â€ )",
          "Mysore City Bird Atlas 7D, Vidhya vardhaka",
          "Marasettihalli",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 51C: Mugana Hundi Kere & Down stream Ã Â²Â®Ã Â³â€šÃ Â²â€”Ã Â²Â¨ Ã Â²Â¹Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 31C, Mahadevapura, Behind CSRTI, Mysore",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â",
          "Mysore City Bird Atlas 8A, Near GRS Fantasy Park.",
          "Nandigunda Seasonal Lake and Scrub patch",
          "Kaggalipura Kere Ã Â²â€¢Ã Â²â€”Ã Â³ÂÃ Â²â€”Ã Â²Â²Ã Â³â‚¬Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â",
          "Varuna Canal Grasslands",
          "Karigala Kere (Doddakere) Ã Â²â€¢Ã Â²Â°Ã Â²Â¿Ã Â²â€”Ã Â²Â¾Ã Â²Â²",
          "Nagarahole National Park--Kabini Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²Â°Ã Â²Â¹Ã Â³Å Ã Â²Â³Ã Â³â€  Ã Â²Â°Ã Â²Â¾Ã Â²Â·Ã Â³ÂÃ Â²Å¸Ã Â³ÂÃ Â²Â°Ã Â³â‚¬Ã Â²Â¯ Ã Â²â€°Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â¨Ã Â²ÂµÃ Â²Â¨--Ã Â²â€¢Ã Â²Â¬Ã Â²Â¿Ã Â²Â¨Ã Â²Â¿",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA 13.02116, 77.57016",
          "Hadinarui cattle trailÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Chittikyathanahalli kere",
          "Shettanakere, Jantahalli",
          "Great Banyan Tree, Manasa Gangotri Mens Hostel, Mysore",
          "Mysore City Bird Atlas 6B, Bommanahalli Kere & BEML staff quarters",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€",
          "Kampalapura Kere  Ã Â²â€¢Ã Â²â€šÃ Â²ÂªÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 41A",
          "Mysore City Bird Atlas 27B, Chamundi Hill-North",
          "Kenchanakere, Hunsur (Ã Â²â€¢Ã Â³â€ Ã Â²â€šÃ Â²Å¡Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Arasanakere Ã Â²â€¦Ã Â²Â°Ã Â²Â¸Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Kabini Backwaters",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)",
          "Kalalavadi Farms and Fields, Mysore",
          "Dadadahalli Kere (Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Chamanahalli Kere Ã Â²Å¡Ã Â²Â¾Ã Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ §Ã Â²Â¿",
          "Nanjanagud Kabini river",
          "Mysore outskirts (OBI)",
          "Mysore City Bird Atlas 55D, Kenchalagudu",
          "Kempanahally",
          "Arabithittu Wildlife Sanctuary    Ã Â²â€¦Ã Â²Â°Ã Â²Â¬Ã Â²Â¿Ã Â²Â¤Ã Â²Â¿Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â Ã Â²ÂµÃ Â²Â¨Ã Â³ÂÃ Â²Â¯Ã Â²Å“Ã Â³â‚¬Ã Â²ÂµÃ Â²Â¿ Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Maraluru",
          "Chamundi Hill Nandi Temple",
          "JC Golf Club, Mysore",
          "Mysore City Bird Atlas 23A, Badagalahundi, Jayapura Hobli, Mysore",
          "Mysore City Bird Atlas 28D, Lalitadripura western part",
          "Manapet Lake (Ã Â®Â®Ã Â®Â£Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kallur Kere Ã Â²Â¦Ã Â²Â°Ã Â³â€¹Ã Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 52A: Chamundi Hill- Uttanahalli Lalitadripura Road",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Hairige  Ã Â²Â¹Ã Â³Ë†Ã Â²Â°Ã Â²Â¿Ã Â²â€”Ã Â³â€ ",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿)Â²Â°Ã Â³â€ ",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)°Ã Â³â€ ",
          "Regional Museum of Natural History (RMNH), Siddartha Layout",
          "Mysore City Bird Atlas 49C Ramanahundi Brick Factory & Layout",
          "Husainpura Bridge   Ã Â²Ã Â§ÂÃ Â¦Â°Ã Â¦â€”Ã Â¦Â¤Ã Â¦Â¿ Ã Â¦ÂªÃ Â¦Â¾Ã Â¦Â Ã Â¦Â¾â€¡Ã Â²Â¤Ã Â³ÂÃ¦Â°)",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA 1",
          "Thondamanatham Lake (Ã Â®Â¤Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â®Â¾Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "JSS College of Arts, Commerce and Science Campus",
          "Mysore City Bird Atlas 19D, Manuvana Park",
          "Mysore City Bird Atlas 47A, Ganagarahundi",
          "Doora Kere Ã Â²Â¦Ã Â³â€šÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Harohalli kere, Bannur Road",
          "Mysore City Bird Atlas 19C, Saraswathipuram Park, Mysore",
          "Mysore City Bird Atlas 52C: Uttanahalli Kere",
          "Mysore City Bird Atlas 51B South Of Ramanahundi",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP",
          "Ariyapalayam Tank Ã Â®â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã ÂÂ³ÂÃ Â²Â§Ã Â²Â¿ Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ramayana Theme Park -- Ã Â¤â€¦Ã Â¤Â®Ã Â¤Â°Ã Â¥â€šÃ Â¤Â¤Ã Â¤Â¹Ã Â¤Â¿Ã Â¤Â¯Ã Â¤Â¾ (Gau",
          "Vaddaragundi Kere ,Gaddige - Hunsur Ã Â²ÂµÃ Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â²Â°Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 27C, Chamundi Hill- Around Temple Ã Â²Å¡Ã Â²Â¾Ã Â²Â®Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸ Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨",
          "Halbhavi Village Ã Â²Â¹Ã Â²Â¾Ã Â²Â²Ã Â²Â­Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿ Ã Â²â€”Ã Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²",
          "Kottigehara Ã Â²â€¢Ã Â³Å Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿Ã Â²â€”Ã Â³â€ Ã Â²Â¹Ã Â²Â¾Ã Â²Â°",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã",
          "Mysore City Brid Atlas 18D, RTTC & surroundings, TK Layout, Mysore",
          "Koppa Kere   Ã Â²â€¢Ã Â³Å Ã Â²ÂªÃ Â³ÂÃ Â²Âª  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kalbetta Forest",
          "Karanji Kere Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 59D, Chikkagowdana Hundi.",
          "Bommanahalli Kere",
          "Mavina Kere Ã Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Arasanakere Gudda Ã Â²â€¦Ã Â²Â°Ã Â²Â¸Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Mysore City Bird Atlas 22C Lalitha Mahal Ã Â²Â²Ã Â²Â²Ã Â²Â¿Ã Â²Â¤ Ã Â²Â®Ã Â²Â¹Ã Â²Â²Ã Â³Â",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿³â€ Ã Â²Â°Ã Â³â€ ",
          "Karigala-Scrub patch",
          "Piriyapattana kere Ã Â²ÂªÃ Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â¯Ã Â²Â¾Ã Â²ÂªÃ Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³ÂÃ Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 19B location name",
          "KC Layout, Mysore",
          "Arasanakere Ã Â²â€¦Ã Â²Â°Ã Â²Â¸Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 12B, MUDA Sports Ground",
          "Mysore City Bird Atlas 27D, Chamundi Hill -Shiva Temple",
          "Mysore City Bird Atlas 9B, Kesare Sewage Treatment Plant, Mysore",
          "Alada Hesrukatte",
          "Mysore City Bird Atlas 32D,Farmland across from Dalvoy kere, Mysore",
          "Manchedevanahalli Lake",
          "Acharampattu Lake (Ã Â®â€¦Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã ",
          "Dadadahalli Kere (Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )Â²Â°Ã Â³â€ ",
          "Sittanur Area Ã Â®Å¡Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã",
          "Mysore City Bird Atlas 47B, Trendz East",
          "Bettadahalli kere Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Daithyana Kere  Ã Â²Â¦Ã Â³Ë†Ã Â²Â¤Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)ÂÃ Â²Å¸Ã Â³â€ )",
          "Mysore City Bird Atlas 25D, Railway Quarters, Mysore",
          "Mydanahalli",
          "Rangachari Hundi Kere",
          "Mysore City Bird Atlas 3D, RBI Note Press",
          "Mysore City Bird Atlas 15C KSRP Police quarters",
          "Ramakrishnanagar Mysore",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿)²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 9C, Bannimantapa -Balabhavan",
          "Mysore City Bird Atlas 11D, Hinkal kere, Vijayanagar 3rd Stage",
          "Karasur Lake (Ã Â®â€¢Ã Â®Â°Ã Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Someshwar Kere Ã Â²Â¸Ã Â³â€¹Ã Â²Â®Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²",
          "Bopanahalli kere",
          "Sindhuvalli, Nanjanagudu (Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â§Ã € Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²â€¢Ã",
          "Hirekeri Ã Â²Â¹Ã Â²Â¿Ã Â²Â°Ã Â³â€ Ã Â²â€¢Ã Â³â€ Ã Â",
          "Malleshwara Gudda, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â³ÂÃ Â²Â§Ã Â²Â¿€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Tailuru Kere Ã Â²Â¤Ã Â³Ë†Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Â²Â¾Ã Â²Â¨",
          "Hulimavu Gudda  Ã Â²Â¹Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â³Â Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Bannur Heggere Ã Â²Â¬Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²Â¹Ã Â³â€ Ã Â²â€”Ã Â³ÂÃ Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "4th Stage, Vijaynagar",
          "Mysore City Bird Atlas 7C, Gen Thimmaiah Road, Vijayanagar 1st stage",
          "Kudakuru Lake",
          "Mysore City Bird Atlas 12C, SJCE Cricket Ground",
          "Karanja Reservoir Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Shreesha Hill View Layout, Lalithadripura",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â³ÂÃ Â²Â¡",
          "Halalu Ã Â²Â¹Ã Â²Â²Ã Â²Â¾Ã Â²Â³Ã Â³Â",
          "Yoga narsimha temple, Vijay nagar Mysore",
          "Mysore City Bird Atlas 24B A&B Block adj Vishwamanava Double Road",
          "Omalur Tank Ã Â®â€œÃ Â®Â®Ã Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas Second 38C, next to railway line",
          "Mysore City Bird Atlas 51A Muganahundi Kere Backwaters",
          "Bettada beedu Scrub Forest , Mahadevapura beat",
          "Korkadu Lake (Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mysore City Bird Atlas 44C, Pond",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢",
          "Karehundi Kere",
          "Mysore City Bird Atlas 28C, Chamundi Hill - western side of Lalitadripura",
          "Mysore City Bird Atlas 55B, Halalu",
          "Daithyana Kere  Ã Â²Â¦Ã Â³Ë†Ã Â²Â¤Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Gaddige-Hunsur : Santhe Kere",
          "Immavu Industrial Area-KAIDB Ã Â²â€¡Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â³Â Ã Â²â€¢Ã Â³Ë†Ã Â²â€”Ã Â²Â¾Ã Â²Â°Ã Â²Â¿Ã Â²â€¢Ã Â²Â¾ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Mydanahalli Lake",
          "Ahalya Grama-Scrub patch",
          "Hullahalli Dam",
          "Suttur Kabini Bridge",
          "Mysore City Bird Atlas 49A, Kemmannupura Kunte & Marshes",
          "Mysore City Bird Atlas 17D, Thibbegowda Farm, Bapuji Layout, Mysore City",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Marehalli Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³â€ Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â§Ã Â²Â¿",
          "Government House, Mysore",
          "Mysore City Bird Atlas 5D, Housing Board Colony",
          "Kalkeri Forest Ã Â²â€¢Ã Â²Â²Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯",
          "Mysore City Bird Atlas 15B location name",
          "Madhava park 5th cross, R K  Nagar",
          "Kaveri Bridge & Surrounds Ã Â®â€¢Ã Â®Â¾Ã Â®ÂµÃ Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Salumarada Thimmakka Park",
          "Mysore City Bird Atlas 8C, PK Sanitorium, KRS Road",
          "Nathamedu Lake (Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Marur lake Ã Â²Â®Ã Â²Â°Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â§Ã Â²Â¿)",
          "Golden Landmark Resort",
          "Nagathan Kere Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²Â Ã Â²Â¾Ã Â²Â£ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 18A, RIE campus, Manasa Gangothri, Mysore City",
          "Andolana circle",
          "Gundupatna Kere, Lingapura Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° (Kiran",
          "Garike Katte  Ã Â²â€”Ã Â²Â°Ã Â²Â¿Ã Â²â€¢Ã Â³â€  Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³â€ ",
          "Ramakrishnagar I block",
          "Hunsur--Lakshmana Tirtha bridge",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³Â",
          "Shree Bheereshwara Temple, Nanagalli,",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€šÃ Â²Â°Ã ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿â€ ",
          "Mysure Suburb Bus Stand",
          "Kabini River lodge",
          "Mullur Kere",
          "Karadilakkana Kere, Kushalnagar",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â",
          "Mysore City Bird Atlas 17B, Bogadi Kere. Ã Â²Â¬Ã Â³â€¹Ã Â²â€”Ã Â²Â¾Ã Â²Â¦Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Sathi Lake",
          "Tailuru Kere Ã Â²Â¤Ã Â³Ë†Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nagappayyana kere",
          "Karimuddanahalli Kere, Ã Â²â€¢Ã Â²Â°Ã Â²Â¿Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Gaddige",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€š",
          "hosakamanakoppal",
          "Muchakandi Kere Ã Â²Â®Ã Â³ÂÃ Â²Å¡Ã Â²â€¢Ã Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°",
          "Nanjangud--General Area (OBI)",
          "Giribettada Kere Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 14D location name",
          "Kaggalipura Kere Ã Â²â€¢Ã Â²â€”Ã Â³ÂÃ Â²â€”Ã Â²Â²Ã Â³â‚¬Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kumarapuram/Manalmantha Ã°Å¸ÂÂ¡Ã°Å¸ÂÂ£Ã°Å¸ÂÂ¥Ã°Å¸ÂÂ¤Ã°Å¸ÂÂ¦",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€",
          "Mysore City Bird Atlas 25C",
          "Kapila River Bridge, Hullahalli",
          "Mysore City Bird Atlas 29C, Behind Yedahalli Village",
          "Mysore City Bird Atlas 16D, KSFC Layout",
          "Devalapura pond",
          "Kikkeri Kere Ã Â²â€¢Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â",
          "Hulimavu Gudda  Ã Â²Â¹Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â³Â Ã Â²â€”Ã Â³ÂÃ Â²Â¡",
          "Mysore City Bird Atlas 47C, Maratikyathanahalli",
          "19Ã‚Â°10'44.2\"N 73Ã‚Â°14'31.5\"EValivali Gaon, Manjarli, Badlapur, Maharashtra 421503",
          "Kabini Mysore--OBI",
          "Mudran Nagar, Mysore",
          "Mysore City Bird Atlas 49B, Kamadhenu Layout, Badagalahundi",
          "Bastar, Chhattisgarh, IN Ã Â¤â€¢Ã Â¥â€¹Ã Â¤Â²Ã Â¤Â¾Ã Â¤Â¸Ã Â¥â€¡Ã Â¤Â°Ã Â¥â‚¬ Ã Â¤Â¨",
          "Sittanur Area Ã Â®Å¡Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Mysore City Bird Atlas 23C, Kergalli kunte downstream, Mysore",
          "Malleshwara Gudda, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡¿",
          "Mysore City Bird Atlas 16C, Yeraganahalli village & farms, Mysore City",
          "Railway Health Unit, Ashokapuram",
          "Nallathur Lake (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°",
          "Vaddagere, Choganahalli",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 2C, Hebbal kere,",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€šÃ Â²Â°Ã",
          "Blackbuck Resort  Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â®Ã Â³Æ’Ã Â²â€” Ã Â²Â°Ã Â³â€ Ã Â²Â¸Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Mysore City Bird Atlas 32B, Chamundi Hill- South west - Prasunayakana Kere western side",
          "Deputy Commissioner's Residence, Mysore",
          "Arakeri Kere Ã Â²â€¦Ã Â²Â°Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²Â¿)",
          "Mysore City Bird Atlas 54B, Open layout near Airforce and Naval Housing Board",
          "Kabini backwater viewpoint",
          "Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨ Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â²Â¿ Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¦Ã Â²Â¿, Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â.",
          "Piriyapattana kere Ã Â²ÂªÃ Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â€¦Ã Â²Â°Ã Â³ÂÃ ²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³ÂÃ Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Chamundi Vana Ã Â²Å¡Ã Â²Â¾Ã Â²Â®Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¨",
          "Mysore City Bird Atlas 51D: M hundi Village S-W",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â¿",
          "Ghat, Bopgaon, Ã Â¤Â®Ã Â¤Â¹Ã Â¤Â¾Ã Â¤Â°Ã Â¤Â¾Ã Â¤Â·Ã Â¥ÂÃ Â¤Å¸Ã Â¥ÂÃ Â¤Â°, IN (18.423, 73.941)",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²Â³Ã Â²Â¿",
          "Acharampattu Lake (Ã Â®â€¦Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mysore City Bird Atlas 26B, Harischandra Ghat Crematorium, Mysore",
          "IN-Andhra Pradesh-Guntur-Ã Â°â€¦Ã Â°Â®Ã Â°Â°Ã Â°Â¾Ã Â°ÂµÃ Â°Â¤Ã Â°Â¿ Ã Â°Â°Ã Â±â€¹Ã Â°Â¡Ã Â±ÂÃ Â°Â¡Ã Â±Â - Hosanna church",
          "Konanur Scrub Jungle, Ã Â²â€¢Ã Â³Å Ã Â²Â£Ã Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "Mysore City Bird Atlas 47D, Dasanakoppalu",
          "Dadadahalli Kere (Ã Â²Â¦Ã Â²Â¡Ã Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )Ã Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 54C, Pond Dhanagalli",
          "Danishpet Big Tank Ã Â®Å¸Ã Â¯â€¡Ã Â®Â©Ã Â®Â¿Ã Â®Â·Ã Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mysore City Bird Atlas 19A, Kukkarahalli kere  Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Saligrama Kere",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³",
          "Mysore City Bird Atlas 14C location name",
          "N Begur range--Bandipura TR",
          "Sanskrit Patashala, New Sayaji Rao Road , Mysuru, Karnataka, IN (12.299, 76.653)",
          "Mysore City Bird Atlas 7B, Kumbara koppalu Tollgate area",
          "Arasana kere Periyapatna",
          "Kumarapuram/Manalmantha Ã°Å¸ÂÂ¡Ã°Å¸ÂÂ£Ã°Å¸ÂÂ¥Ã°Å¸ÂÂ¤Ã°Å¸ÂÂ¦Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Heggadapura Lake",
          "Mysore City Bird Atlas 21B, Yaraganahalli",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hanchinal Kere Ã Â²Â¹Ã Â²â€šÃ Â²Å¡Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â",
          "Ariyapalayam Tank Ã Â®â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã ",
          "Lingambudhi Kere--Main Entrance. Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿  Ã Â²Â®Ã Â³ÂÃ Â²â€“Ã Â³ÂÃ Â²Â¯ Ã Â²Â¦Ã Â³ÂÃ Â²ÂµÃ Â²Â¾Ã Â²Â°",
          "Chamundi Hill Uttanahalli Road",
          "Baradanpura (Ã Â²Â¬Ã Â²Â°Ã Â²Â¡Ã Â²Â¨Ã Â²ÂªÃ Â³ÂÃ Â²Â°)",
          "Mysore City Bird Atlas 26A, SJH Rd, Vidyaranya puram, Mysore",
          "Mysore City Bird Atlas 15D location name",
          "Gundupatna Kere, Lingapura Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° (Kirangoor Lake)",
        ],
      },
      {
        district: "Bengaluru Urban",
        localities: [
          "Satanur Kere",
          "Shivakote Kere",
          "Kowdenahalli Kere",
          "Backside of DMart (Agricultural fields)",
          "Varthur Kere",
          "Hessaraghatta--Hessaraghatta Scrub Forest",
          "Thirumenahalli Kere--2",
          "Swamy Vivekananda BBMP Park",
          "IDBI Layout Kere, Gottigere",
          "Silvepura Lake",
          "Jarakbandekaval",
          "Lalbagh Botanical Gardens--Lalbagh Lake",
          "Basapura Kere--2",
          "Byatarayana Doddi Kere",
          "Friends of Camphill India",
          "Sree Narayana Nagar--Weaver Pond",
          "Ramohalli / Bheemanakuppe Kere",
          "JB Butterfly Garden and Tree Park",
          "Bagmane Lake",
          "Vibhutipura Kere",
          "Bidre Kere",
          "Gunjur Kere",
          "Doddibetta Trek--Shivanahalli (Restricted Access)",
          "Ragihalli--Lotus Pond",
          "Indian Institute of Horticultural Research",
          "Ullal Kere",
          "RV University",
          "Singanayakanahalli (Nagadasanahalli) Kere",
          "Hessaraghatta--Nrityagram Road",
          "Sarala Birla Academy",
          "Nayandanahalli Kere",
          "Sompura Kere",
          "Rayasandra Kere",
          "Kadiranapalya Kere",
          "Kudusiddanapalya Grassland Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³ÂÃ Â²Â¸Ã Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¨Ã Â²ÂªÃ Â²Â¾Ã Â²Â³Ã Â³ÂÃ Â²Â¯ Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â",
          "Byatha Lake",
          "Hessaraghatta--ICAR area",
          "Kaikondrahalli Kere / Kaikondanahalli Kere",
          "Hanumagiri Hill",
          "Bannerghatta Zoo",
          "Champaca Bookstore",
          "Inventure Academy, Bangalore",
          "Adugodi Police Grounds",
          "Sadaramangala Kere",
          "Ramagondanahalli Kere",
          "Turahalli State Forest, Bangalore",
          "ESI Rajaji Nagar",
          "Bellandur Kere",
          "Varasandra / Hemmigepura Kere",
          "Lingadeeranahalli Kere (Pavamanapura)",
          "Pearl Valley Dam",
          "Sobha Suburbia Apartment complex",
          "JCR Layout Marsh",
          "Doddakammanahalli Lake",
          "Hadosiddapura Lake",
          "Halanayakanahalli Kere",
          "Subramanya lake",
          "Horamavu Agara Kere",
          "Durga Rock--Quarry View Point",
          "Sunkadakatte",
          "Samvriddhi Gardenia",
          "Benapatti Outer farmÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Sreenivasa Garden",
          "Kasavanahalli Kere",
          "Muthyalamaduvu",
          "Doresanipalya forest campus",
          "Saul Kere / Sowl Kere",
          "National Gallery of Modern Art (NGMA)",
          "Omkar Hills Forest",
          "Nagaruru Kere",
          "Mahadev Kodigehalli (Gummanahalli) Kere",
          "Devasandra Lake",
          "Doddabidara Kallu Kere",
          "Chokkanahalli Forest",
          "Thirumenahalli Kere--1",
          "Adde Vishwanathapura kere",
          "Rajiv Gandhi Nagar, Bangalore",
          "Sondekoppa Kere",
          "Poornaprajna park",
          "Horamavu Kere",
          "Bangalore International Center (BIC)",
          "Royal Lakefront Residency Phase III",
          "Mylappanahalli lake",
          "St. Joseph's College",
          "BBMP Park",
          "Indira Gandhi Musical Fountain Park, Bangalore",
          "Devara Kere / ISRO Layout Lake",
          "L&T Raintree Boulevard",
          "Rachenahalli Kere",
          "Sahakaranagara, Bangalore",
          "Raman Research Institute",
          "St. Patrick's Church and surrounding areas",
          "RAGI KANA AT SRI RAMAKRISHNA SAMAGRA SIKSHANA kendra, Kalena Agr",
          "Mekhri Circle",
          "Kammgondahalli Lake",
          "Yeswanthpur Railway Station",
          "Jayanagar Bavi Park (Green Walkers Park), Bangalore",
          "Basapura Kere--1",
          "Prestige Garden Bay",
          "Puttenahalli Kere, Yelahanka",
          "Anjanapura Central Park",
          "Wildlife Rescue And Rehabilitation Centre (WRRC), Bannerughatta",
          "Aagara-BM Kaval Kere (near Valley School)",
          "Garvebhavi Palya Kere",
          "Dodda Begur Kere",
          "Jalvayu Towers (JVT) - Apartment Complex",
          "Konasandra Kere",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)",
          "Guddahatti Kere (Western) Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â²Â¹Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  (Ã Â²ÂªÃ Â²Â¶Ã Â³ÂÃ Â²Å¡Ã Â²Â¿Ã Â²Â®)",
          "Byalkere Lake (now dry)",
          "Kadugodi Tree Park",
          "Mitganahalli Lake",
          "Kempambudhi Kere",
          "Turahalli park",
          "Dodda Thimmasandra Road, Bengaluru, Karnataka, IN (12.863, 77.797)",
          "Hosa Kere",
          "Marenahalli Bande Lake",
          "Kumara Park West",
          "Kadathanamale Kere (Ã Â²â€¢Ã Â²Â¡Ã Â²Â¤Ã Â²Â¨Ã Â²Â®Ã Â²Â²Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Singasandra Kere",
          "IISc Bangalore--Nursery",
          "Sunkalpalya Kere",
          "AECS Layout - D Block, Road Around The Park",
          "Vasanthpura Lake (Janardhana kere)",
          "Kannahalli Kere",
          "Canara Bank Layout",
          "Bendiganahalli Kere",
          "Seegehalli Kere (Basavanapura)",
          "Bannerghatta National Park",
          "Allalasandra/Judicial Layout",
          "Jawaharlal Nehru Centre for Advanced Scientific Research (JNCASR)",
          "Thottikallu Falls",
          "Indian Institute for Human Settlements (IIHS) Kengeri campus",
          "Yeshwanthpur",
          "Begur grazing grounds",
          "Gottigere Kere",
          "Valley School",
          "Krishnarajapuram Railway Station",
          "BEL Estate Office",
          "PES Institute of Technology (PESIT) South Campus",
          "National Center For Biological Sciences -- Mandara Housing Complex, Yelahanka",
          "Indian Institute of Management (IIM)-Bangalore, New Campus",
          "Vikasa Soudha",
          "Suvidha Retirement Village",
          "Dayananda Sagar College of Engineering",
          "Akshara Montessori School",
          "Waterbody beside PESIT/APU (Overlflow of Konappana Agrahara lake)",
          "Honniganahatti Kere",
          "Gold Coins Club & Resort",
          "National Centre for Biological Sciences (NCBS), GKVK Campus",
          "Sangam The Land, Silvepura",
          "Cheemsandra Mound",
          "FERAL",
          "Hennagara Lake",
          "Indian Institute of Horticultural Research (IIHR) Farm",
          "HP Enterprise (HP STSD), Mahadevpura",
          "Brigade Exotica",
          "Gulakamale Kere",
          "Prestige Shantiniketan and surrounding area",
          "Karnataka Golf Course (Restricted access)",
          "Maaramma Temple",
          "Devar Kere",
          "Bommenahalli Kere",
          "Area 83 Adventure Resort",
          "Kuduregure Kere",
          "Kumaraswamy Layout Water Tank",
          "Foundation for the Revitalization of Local Health Traditions (FRLHT)",
          "Iblur Lake",
          "Thubarahalli Lake",
          "Bandemutt",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP)",
          "Chikkanekkundi Grassland",
          "Anekal Dodda Kere",
          "Yelachenahalli lake (Annappana kere)",
          "Goudanakere",
          "Institute of Public Health",
          "JP Park and Lake",
          "Suradhenupura Kere (Ã Â²Â¸Ã Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¨Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Doddanekundi Kere",
          "Tharalu Kere",
          "Krishna Nagar (Kothnur) Kere",
          "Subramanyapura Kere",
          "Shanitpura, Electronic City Phase -2",
          "Global Village Tech Park",
          "Delhi Public School Bangalore North",
          "Basaveshwara circle",
          "Meenakunte Kere",
          "Judicial layout--BBMP Park",
          "Tammanayakanalli, BNP",
          "Doddabommasandra Kere",
          "Kommaghatta Kere",
          "Prakruti Farms",
          "Jogi Kere",
          "Pu Ti Narasimhachar park",
          "Thigalarapalya Lake",
          "Institute of Wood Science and Technology",
          "Vrishabhavati River",
          "Kommasandra Lake",
          "T Dasarahalli Kere",
          "ISRO Layout Lake (Deverakere)",
          "Kengeri Kere",
          "Mylasandra Kere",
          "BHCS Layout Park",
          "Thattaguppe/Mariapura Kere",
          "Siddapura Lake",
          "RMV Extension, Bangalore",
          "Chokkanahalli Kere",
          '"Bannerghatta\'s Badger" Field Station',
          "Muthanallur Sports Ground (Sachin Tendulkar Cricket Ground)",
          "Malathahalli lake",
          "Yerappanahalli Kere",
          "Gantiganahalli Kere",
          "Channasandra Marsh",
          "Chikka Begur Kere--2",
          "Bannerghatta Hilltop",
          "Whitefield Mini Forest",
          "Cantonment Railway Station",
          "Sadashivnagar Park, Bangalore",
          "The Martin Farm, Chikkabellandur, Bangalore",
          "Dore Kere Hill Spot",
          "Rayaradoddi Hillock",
          "Manchappanahalli Kere (Kotnathevaru Kere)",
          "Kannamangala lake",
          "Haragadde Kere",
          "Brigade Meadows",
          "Dore Kere",
          "Banagiri Park",
          "Rampura-Kalkere Lake, Bangalore",
          "Kundalahalli Kere",
          "Udayapur--MTR Restaurant",
          "Munnekolala Kere",
          "Chandapura Kere",
          "Thimmasandra Kere",
          "Hal Office quarters (near Hal market)",
          "Gunjur Palya Kere",
          "Jakkur Airfield",
          "Hosakerehalli Kere",
          "The Outback Farm",
          "Haraluru",
          "Srigandhakavalu Park",
          "ICTS Campus",
          "Settahalli",
          "Kakolu Scrub Forest",
          "SG Palya",
          "Bannerghatta National Park--JLR Camp",
          "Sapthagiri Splendour",
          "Avalahalli Forest",
          "Ragihalli, Bangalore",
          "Chikkatogur Lake",
          "RMZ Ecospace, Bangalore",
          "CK Palya-2 Kere",
          "GKVK Campus",
          "Kadugodi (OBI)",
          "Bannerghatta National Park--Bear Rescue Centre",
          "Mahadevpura Kere",
          "Bilwaradahalli",
          "Durga Rock",
          "Lalbagh Botanical Gardens",
          "Eagle Ridge Layout",
          "Nice link road",
          "Gunduru Kere",
          "Hessaraghatta Kere",
          "Christ University Kengeri Campus",
          "Brigade meadows- Pipeline Rd",
          "Tata Elxsi",
          "NCF, Banashankari",
          "B M Kavalu",
          "Chikkajala scrub forest",
          "Narasipura Kere",
          "ATREE",
          "Veerasagara Kere",
          "Good Earth Enclave",
          "AECS Layout Brookefields",
          "Somanahalli Kere",
          "Embassy Riding School, Bangalore",
          "Sahakaranagara Park, Bangalore",
          "Wipro EC4, Electronic City",
          "Aurobindo marg,Jayanagar 5th block",
          "IISc Bangalore",
          "Byrohalli Village",
          "MN Krishna Rao Park, Bangalore",
          "Jodi Aaladamara kere   Ã Â²Å“Ã Â³â€¹Ã Â²Â¡Ã Â²Â¿ Ã Â²â€ Ã Â²Â²Ã Â²Â¦Ã Â²Â®Ã",
          "Hulimavu Kere",
          "Art of Living, Kanakapura campus",
          "Hoodi Lake",
          "Sri Nivasa Reddy Layout, 166",
          "Kodathuru Kere",
          "Hulimangala Kere",
          "Attur Lake",
          "National Institute of Advance Studies (NIAS)",
          "Margosa Avenue, Bellanduru",
          "Mango & Coconut Orchard, Chanasandra",
          "Concorde Silicon Valley",
          "Pattanduru Agrahara Kere",
          "Sampigehalli Kere",
          "Vakil Garden City",
          "Kannuru Kere",
          "Buragunte Savannah",
          "IIM Bangalore",
          "Tavarakere Park, Bangalore",
          "Palanahalli Kere",
          "Fireflies - off Kanakpura road, Bangalore",
          "Sompura, Bangalore",
          "Hosakere (M Krishnasagar)",
          "Ganapathihalli kere Ã Â²â€”Ã Â²Â£Ã Â²ÂªÃ Â²Â¤Ã Â²Â¿Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Benniganahalli Lake",
          "Jalahalli, Bangalore",
          "Yellamallappa Chetty (Yele Mallappa Shetty) Kere",
          "Bhattrahalli Kere",
          "Gunjur Palya Reserve Forest",
          "CK Palya-1 Kere",
          "Kalkere State Forest",
          "Cubbon Park, Bangalore",
          "Ganapathihalli kere",
          "Kannamangala Kere",
          "Harohalli Kere (North)",
          "Gulakmale Kere",
          "Sarakki Kere",
          "Sulikere, Kenchanapura",
          "Christ University-Main Campus",
          "Devarabisana halli lake",
          "Gundanjeneya Swamy Temple",
          "Hunasamaranahalli Lake",
          "Nallurhalli Kere",
          "Abandoned quarry, Vabasandra",
          "Location Indian Institute for Human Settlements (IIHS) Kengeri c",
          "BBMP Park HBR Layout",
          "Yellamallappa Chetty (Yele Mallappa Shetty) Kere--North",
          "Halagevaderahalli Kere",
          "Amruthahalli Lake",
          "Vaikuntha Hill",
          "Arashinakunte Kere",
          "Ulsoor Kere",
          "Jakkur Kere",
          "Chikka Begur Kere--1",
          "Mutturaya",
          "Sadenahalli Kere (Ã Â²Â¸Ã Â²Â¾Ã Â²Â¦Ã Â³â€¡Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Anjanapura / Avalahalli Kere",
          "Haraluru Lake",
          "Gangondanahalli Kere",
          "Arekere Kere",
          "Aivarakhandapura Kere",
          "Avalahalli State Forest",
          "Bagaluru Kere (Lake)",
          "Chelekere Lake",
          "Yelahanka Railway Station",
          "RNS Vidyaniketan Campus",
          "tharalu",
          "Mallasandra, Bangalore",
          "Jakkur--GKVK Layout and Nursery",
          "Madivala Kere",
          "Doddajala Kere",
          "Sankey Tank",
          "Kachohalli Kere",
          "Turahalli Forest Periphery",
          "Bugle Rock Park",
          "Ferns Residency",
          "Kaggalipura Kere",
          "St. John's Medical College Hospital",
          "Chaithanya Greenfort 1",
          "Raastra Kavi Kuvempu Park",
          "OB Chudanahalli kere",
          "BEML Layout, RajaRajeshwari Nagar",
          "Krishnarajapuram--ITI Colony",
          "Good Earth Malhar",
          "K R Puram (Krishnarajapuram) Kere",
          "Nimbekaipura Kere",
          "Nelaguli Kere",
          "Parangi Palya, HSR Layout",
          "Singapura Lake",
          "Adigondanahalli Wetlands--Handenahalli Bridge Marshes",
          "Kakolu Kere",
          "People for Animals, Bangalore (PFA)",
          "Kaggadasapura Kere",
          "Banashankari",
          "Marasandra Lake",
          "Institute of Public Health Bangalore (IPH)",
          "Trans Indus",
          "Somapura",
          "Cariappa Park, Bangalore",
          "Augusta Golf Course",
          "Hinnaki Kere",
          "Nallathur Lake (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®",
          "Arasanakere Gudda Ã Â²â€¦Ã Â²Â°Ã Â²Â¸Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²",
          "Yelahanka Kere",
          "Mini Lalbagh, Kanamangala",
          "Satisha Sarakki Hide, Tataguni",
          "Turahalli BBMP Water Tank",
          "Deer Park, Bangalore",
          "BMP park, 34th cross, 18th main, Jayanagar",
          "Bingipura Lake",
          "Chunchugatta Kere",
          "Mallathahalli Kere",
          "Classic Orchards Layout",
          "Azim Premji University",
          "Gubbalala Lake",
          "Herohalli Kere",
          "Thalaghattapura Kere",
          "Sakalvara-2 Kere",
          "Vasanth Nagar",
          "Hulimavu Lake--rock area",
          "GKVK Campus--Botanical Garden",
          "Bharathnagar Lake",
          "Akshayanagara Kere",
          "Banaswadi Railway Station",
          "Carmelaram Railway Station",
          "Jaipurdoddi",
          "Allalasandra Kere",
          "Konanakunte (Doddakallasandra) Kere",
          "JavaregowdanaDoddi",
          "Ajmera Stone Park",
          "Kadabagere Kere",
          "Honnasandra Kere",
          'Sangam "The Land", Silvepura',
          "Dayananda Sagar Institutions",
          "ChikkaNagamangala Kere",
          "Institute of Bioinformatics & Applied Biotechnology (IBAB)",
          "Sakalvara-1 Kere",
          "Claret Niwas",
          "Chikkajala Kere",
          "Bidaraguppe Lake",
          "Hennur Lake Biodiversity Park",
          "Maragondanahalli Kere",
          "Hulimangala, Bangalore",
          "Ramakrishna mission",
          "Venkattapa Art Gallery",
          "Visthar",
          "Seetharamapalya Kere",
          "Bangalore University Campus",
          "Mount Carmel College",
          "The Big Barn Farm",
          "Kalyan Nagar Bus stop, Ring road, Bangalore",
          "Veerayodharavana Park/Lake",
          "Deepanjalinagar Kere",
          "Road to Lotus Pond",
          "Muthanallur village",
          "Agara Kere (HSR Layout)",
          "Hasiru Holidays",
          "KIOCL Colony",
          "Koramangala 3rd block park",
          "Byrasandra BMP park, Jayanagar",
          "Evershine Greenwood Premises",
          "Somapura Kere, NICE Road",
          "Kogilu Kere",
          "Byatha Scrub Forest",
          "Amanikere (Avalahalli lake) Singanayakanahalli",
          "Big Banyan, Bangalore",
          "Sobha Orchid",
          "Prani - The Pet Sanctuary",
          "Al-Ameen Collage Campus",
          "Kannamangala",
          "Rayarakere Lake",
          "Hebbal Kere",
          "Chinnayyanapalya Silk Farm Bus Stop",
          "Yelenahalli lake",
          "HAL coconut grooves, yemalur",
          "B. Hosahalli Kere",
          "Jigani Kere",
          "Bommasandra Kere",
          "Mullur Kere",
          "Byapanahalli railway/metro station",
          "Marasandra Amaanikere",
          "Kithaganur Kere",
          "M.S. Ramaiah City",
          "Institute of Bioinformatics and Applied Biotechnology",
          "Infosys Electronic City Kamath FoodCourt",
          "Anchepalya Kere",
          "Chikkona Kere, kethohalli",
          "Sheelavantha Kere",
          "Kengeri Upanagara Kere",
          "Marenahalli, Bangalore",
          "Singena Agrahara",
          "Mantapa Kere",
          "Avalahalli Lake -- adjoining areas",
          "Jigala Lake (tank)",
          "Kattigenahalli Kere",
          "Kodigehalli Railway Station",
          "City Railway Station",
          "Sadashivnagar",
          "Tarahunise Lake",
          "BMP park, 38th cross, 18th main",
          "Thippenahalli",
          "Arkavathy river bridge",
          "Madhavan Park, Bangalore",
          "Vishwa Vihar, CV Raman Nagar",
          "Nagawara Kere / Nagavara Kere",
          "Pushpanjali Park",
          "Chinnappanahalli Kere",
          "Kembathhalli Kere",
          "Doddagubbi Kere",
          "Huskur Kere",
          "Seegehalli Kere",
          "Pramiti School",
          "Muthanallur Kere",
          "Hennur Bamboo Forest",
          "PES University",
          "Kattamanallur Kere",
          "Puttenhalli Kere (Puttakere)",
          "Indian Institute of Astrophysics",
          "Azim Premji University (APU)--UG Campus",
          "Chikkakammanahalli Pond",
          "Karnataka Veterinary Animal and Fisheries Sciences University, Hebbal",
          "Tatteguppa",
          "Bandekodigehalli Kere",
          "Chikkabanavara Kere",
          "Piriyapattana kere Ã Â²ÂªÃ Â²Â¿Ã Â²Â°Ã Â²Â¿Ã Â²Â€¦Ã Â²Â°Ã Â³ÂÃ ²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³ÂÃ Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hessaraghatta--Mathkur Area",
          "Vajram Essenza Road",
          "Byrasandra Kere",
          "Avalahalli Forest--West Tank",
          "Kalkere Kere",
          "Ambalipura Kere",
          "Yediyur Kere",
          "Radha Kunj Kere",
          "Shivanahalli, Bangalore",
          "GBJ quarters( HAL), marathahalli",
          "Uttarahalli Kere",
          "Ferns Meadows",
          "Ramakrishnapura Lake",
          "ST Bed extension, Koramangala",
          "AECS Layout - C Block, 749",
          "Abbigere Lake",
          "Maralakunte (M Hosahalli) Kere",
          "IISc Bangalore--Jubilee Gardens",
        ],
      },
      {
        district: "Bengaluru Rural",
        localities: [
          "Sanne Amanikere",
          "Channarayapatna Kere",
          "Gunjur Kere",
          "Avathi Kere",
          "Nelamangala Kere--2",
          "Dodda Tumakuru Kere",
          "Halu Chilume Gange",
          "Maralenahalli Kere (Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Â²Ã Â³â€ Ã Â²Â¨ Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Nalluru Tamarind Grove, Devanahalli",
          "Hill Jain Temple",
          "Centre for Research and Education in Science and Technology",
          "Devanahalli, Bangalore",
          "Chikkasanne Kere",
          "Thyamagondlu Amanikere",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Thimmarayanaswamy Betta",
          "T Begur Dodda Kere",
          "Mugabala Cultivation",
          "Dod Belvangala Kere",
          "Veerapura Lake (Ã Â²ÂµÃ Â³â‚¬Ã Â²Â°Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Sadahalli Kere",
          "Thavarekere",
          "Kollur Kere (Lake)",
          "Kuluvanahalli Kere",
          "Hegganahalli / Nagamangala Kere",
          "Our Native Village resort",
          "Shivapura Kere",
          "Doddaballapura Kere",
          "Bashettihalli Kere (Ã Â²Â¬Ã Â²Â¾Ã Â²Â¶Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "T Begur Chikka Kere",
          "Madhure Kere",
          "Mallepura Kere",
          "School of Ancient Wisdom, Bangalore",
          "Nelamangala Taluk (OBI)",
          "Makalidurga",
          "Ardeshanahalli Kere",
          "Meiosys Biotech Pvt. Limt. Field Station 1",
          "Avathi Rocky Outcrop",
          "Sonnenahalli",
          "Kesturu Kere (Ã Â²â€¢Ã Â³â€ Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Muddalinganhalli Kere",
          "Bengaluru Airport (Kempegowda International Airport)",
          "Happy Valley, Makalidurga",
          "Doddaballapur--Railway station",
          "Hoskote--Chikka Amani Kere",
          "Hulukadi Hillock (Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€¢Ã Â²Â¡Ã Â²Â¿ Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸)",
          "Lingapura Lake (Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â Â²Â°Ã Â Ã Â²Â°Ã Â³â€ )",
          "Thippagondanahalli (TG Halli) Reservoir",
          "Rajaghatta Kere (Ã Â²Â°Ã Â²Â¾Ã Â²Å“Ã Â²â€”Ã Â²Å¸Ã Â³ÂÃ Â²Å¸ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Shivagange Betta",
          "Hoskote / Hosakote Kere",
          "Dibbagiri Swamy Betta",
          "Lingapura Lake (Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Maragondanahalli Kere (Ã Â²Â®Ã Â²Â°Ã Â²Â¾Ã Â²â€”Ã Â³Å Ã Â²â€šÃ Â²Â¡Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Singavara Betta, Huralagurthi",
          "Doddasanne Kere",
          "Gundamagere Lake (Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â®Ã Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Benakana Halla Forest Patch",
          "Narasapura Kere",
          "Hanabe Lake",
          "Venkagirikote Kere",
          "Junnasandra Mini Forest",
          "Muttur Lake (Ã Â²Â®Ã Â³ÂÃ Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Nrityagrama",
          "Hessaraghatta--Hessaraghatta Grassland",
          "Budigere Kere",
          "Thippuru Kere (Ã Â²Â¤Ã Â²Â¿Ã Â²ÂªÃ Â³ÂÃ Â²ÂªÃ Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Kuligere Kere, (Ã Â²â€¢Ã Â³â€šÃ Â²Â²Ã Â²Â¿Ã Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Suvarnavathi Backwaters - Kulluru/Ã Â²â€¢Ã Â³ÂÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³ÂÂ°Ã Â³â€ )",
          "Jalige Kere",
          "Nelamangala Kere--1",
          "Bettakote Kere",
          "Thippasettihalli Kere",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Karenahalli Tank",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Doddabommasandra",
        ],
      },
      {
        district: "Gadag",
        localities: [
          "Akkigund Tanda Forest Area Ã Â²â€¦Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â¿Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¦ Ã Â²Â¤Ã Â²Â¾Ã Â²â€šÃ Â²Â¡Ã Â²Â¾ Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Belur Kere Ã Â²Â¬Ã Â³â€¡Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Bhishma Kere Ã Â²Â­Ã Â³â‚¬Ã Â²Â·Ã Â³ÂÃ Â²Â® Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Binkadakatti Zoo Ã Â²Â¬Ã Â²Â¿Ã Â²â€šÃ Â²â€¢Ã Â²Â¦Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â®Ã Â³Æ’Ã Â²â€”Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Karanji Kere Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â²â€šÃ Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Khanapur Ã Â²â€“Ã Â²Â¾Ã Â²Â¨Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Kutwadi Kere Ã Â²â€¢Ã Â³ÂÃ Â²Å¸Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Gaddige-Hunsur",
          "Laxmeshwar Ã Â²Â²Ã Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â³ÂÃ Â²Â®Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â°",
          "Magadi Bird Sanctuary Ã Â²Â®Ã Â²Â¾Ã Â²â€”Ã Â²Â¡Ã Â²Â¿ Ã Â²ÂªÃ Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿§Ã Â²Â¾Ã Â²Â®",
          "Mulgund Ã Â²Â®Ã Â³ÂÃ Â²Â³Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¦",
          "Shettikeri Kere Ã Â²Â¶Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Singatalur Dam, Hammigi Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Å¸Ã Â²Â¾Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯, Ã Â²Â¹Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¿Ã Â²â€”Ã Â²Â¿",
          "Tailuru Kere Ã Â²Â¤Ã Â³Ë†Ã Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
        ],
      },
      {
        district: "Dakshina Kannada",
        localities: [
          "Sri Venkataramana Swami College",
          "Kolambe, Bajpe area",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.9-74.7",
          "Padushedde Wetlands, Mangalore",
          "Permude, Mangalore, Karnataka",
          "Zoology Dept, Mangalore University",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.8N 74.6E",
          "Mangalore University--Boys Hostel Forest",
          "Netravathi river bridge area",
          "Karinjeshwara temple area, bantwala",
          "Boremaru, bantwal",
          "Addu, Mangaluru",
          "Shishileshwara Temple, Shishila.",
          "Beeramale Hill",
          "Chitrapu Estuary, Mulki",
          "Pilikula Nisarga Dhama",
          "Yekkar",
          "Mangalore University--MUEC co-op society",
          "Kandrappady",
          "Mangalore University--Kallugundi",
          "Kollamogaru, Subramanya",
          "Alva's Institute of Engineering and Technology--Naturapathy Treatment Centre",
          "Panjikallu",
          "Jokatte",
          "Srinivas Institute of Technology, Valachil",
          "Kanyana, Hebri",
          "Paduvannur,",
          "Old Port Road, Bunder",
          "Polali--Tankaulipady, Mangalore",
          "uruvalu, uppinandgadi",
          "Moodambailu school, vitla",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.7N 74.6E",
          "Kateelu and surroundings",
          "Mangalore University--BCM Hostel",
          "Mangaluru--PVS Circle",
          "Mangalore Institute of Technology and Engineering (MITE) College Campus, Mijar",
          "Uppukallu Viewpoint",
          "Mangalore University--Admin Forest",
          "Mangalore University--Main Campus",
          "Kavoor Wetlands (Kunjathbail)",
          "Mangalore University--Konaje End",
          "Tannirbavi Beach",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.9N 74.6E",
          "Kongur Mutt Area, Mangalore",
          "Nettana Forest Depot",
          "Navuru Forest IB",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.7N 74.5E",
          "Gundya Nature Camp",
          "Thokur Wetland, Mangaluru",
          "Tagore Park, Mangaluru, Karnataka, IN (12.872, 74.844)",
          "Hosabettu Beach junction, suratkal",
          "Mangalore University quarters area 1, Mangaluru",
          "Mangalore University--Botanical Garden",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.9N 74.6E",
          "Manjalpaade, Mangalore",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.9N 74.7E",
          "Sasihitlu -- Beach and Estuary",
          "Off the coast Mangalore (OBI)",
          "Pelagic HotSpot, Mangalore Offshore, Arabian Sea - 12.96N x 74.50",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.8N 74.8E",
          "Koila, Uppinangadi",
          "Biladwara, Subramanya",
          "Navuru trekking path",
          "Chelairu Wetlands",
          "Beluvai",
          "Bengre, Mangalore",
          "Padenkallu Surroundings",
          "Alva's Institute of Engineering and Technology",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 13.0N 74.5E",
          "Yedapadavu, mangalore",
          "St Agnes College (Autonomous) Mangalore",
          "Pavanje River Left Bank, Pavanje",
          "Manchi, Mangalore",
          "Hosabettu Beach area, Suratkal",
          "Kadri park spot #2, Mangalore",
          "Old Mangalore Port (Dakke)",
          "Sri Chowdeswari Temple and Surroundings",
          "Gurupur River, Mangalore",
          "Infosys Mangalore",
          "Mangalore University--Forest behind Library",
          "Mangalore University--Journalism Block",
          "Kulai Thokur Inner Road",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.9N 74.5E",
          "Kulshekar chowki",
          "Sholakili Spot",
          "Kenjar Wetlands, Mangalore",
          "Kadalakere, moodabidre",
          "JLR Phalguni River Lodge",
          "Kottara",
          "Gundya Forest I.B",
          "Kelanjimale, Vittal",
          "NITTE University--Paneer Campus",
          "Mangaluru Port",
          "Mudipu",
          "Taanirbavi, mangalore",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.9-74.6",
          "Pelagic HotSpot, Mangalore, In: 12.9-74.5",
          "Pelagic HotSpot, Mangalore , IN (12.85, 74.79)",
          "St Aloysius College, Mangalore",
          "Kudremukha NP--Yelneeru Waterfall",
          "Mangalore University--Mangalore to Mudipu Road",
          "Sammilan Shetty's Butterfly Park, Belvai - 13.124x74.994",
          "Maidaadi Hills",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 12.6N 74.4E",
          "NITTE University--New Campus",
          "Kanaka Majalu, Puttur",
          "Gurupura Kaikamba Electric Sub-station, Kandavara, Mangalore",
          "Ucchila transient estuary, Mangalore",
          "Pallakere Wetlands",
          "Pelagic HotSpot, Mangalore Offshore, Arabian Sea - 12.77N x 74.75",
          "Mangalore--General Area (OBI)",
          "Mangalore University--Arboretum",
          "National Institute of Technology (NITK), Surathkal",
          "Munchur Wetlands, Surathkal",
          "Mangalore University--Boys Hostel",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.8N 74.7E",
          "kannadikatte",
          "~18 km off the coast of Mangaluru (OBI)",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.9N 74.5E",
          "Pathaje",
          "K S Hegde Academy",
          "Punjalkatte",
          "Someshwara Ullal beach, Mangaluru",
          "Pelagic HotSpot, Mangalore, Arabian Sea: 13.0N 74.6E",
          "Aruvalu",
          "Pilikula Arboretum",
          "Kolambe",
          "Pelagic HotSpot, Arabian Sea, IN (12.90, 74.65)",
          "Soorinje",
          "Adyar, Mangalore",
          "sampaje-urubail road",
          "Mangalore University--Zoology Block",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.9N 74.4E",
          "Mudipu Ira Road, Mangaluru",
          "St Aloysius Institute of Management and Information Technology (AMIT)",
          "Car Street",
          "Ajjisana Area",
          "Mangalore Railway station",
          "Guruvayana Kere",
          "Mangalore University--Administrative Block Forest",
          "Pelagic HotSpot, Mangalore, IN: 12.8-74.4",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.9N 74.8E",
          "Govinda Dasa Degree College",
          "Pelagic HotSpot, Mangalore , IN (12.85, 74.71)",
          "Pelagic HotSpot, Mangalore , Arabian Sea: 12.8N 74.5E",
          "Surathkal Beach",
        ],
      },
      {
        district: "Davanagere",
        localities: [
          "Anagodu Forest",
          "Anagodu Park",
          "Anaji Lake",
          "Avaragere kere, Ã Â²â€¦Ã Â²ÂµÃ Â²Â°Ã Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bathi Kere, Harihara Road",
          "Bheemeshwar Temple",
          "Bukkambudhi Kere -Bukkaraya Kere (Karkikere)",
          "Davangere University",
          "Devarabelakere",
          "Elathur Pond (Ã Â®ÂÃ Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã",
          "Hadadi kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hallikere Lake",
          "Harihar--Tungabhadra bridge",
          "Hiremegalagere Lake",
          "Holesirigere kere",
          "Kalkere",
          "Kalleshwara Temple ( Ancient)",
          "Kalleshwara Temple,Bagali",
          "Kamalapura Kere",
          "Kariganuru",
          "Kondajji Kere",
          "Kunduwada Lake",
          "Rangaiahnadurga Kondukuri (Four Horned Antelope) Wildlife Sanctuary",
          "Savalanga Kere (Lake)",
          "Shree Theertha Rameshwara Betta",
          "Siddiveerappa Badavane",
          "Small Arecanut Plantaion near Yakkegondi Village",
          "SOOLEKERE (SHANTHI SAGARA)",
        ],
      },
      {
        district: "Chikkaballapura",
        localities: [
          "Achaganapalli",
          "Achaganapalli Hill",
          "Achaganapalli Kere",
          "Adiyogi Sadhguru Sannidhi",
          "Ajjakadirenahalli Ã Â²â€¦Ã Â²Å“Ã Â²â€¢Ã Â²Â¡Ã Â³Ë†Ã Â²Â°Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Amma Cheruvu Kere",
          "Ammana Kere, Sidlaghatta",
          "Anjaneya Swami Temple",
          "Anjani extension (Tank bund road)",
          "ATREE-Chikkaballapura FS",
          "Avalabetta",
          "Baiyannagarapalli",
          "Bandahalli Scrub",
          "Bandollapalli Kere",
          "Bannigana Kere/Peresandra lake",
          "Bhairasagara Kere",
          "Bodaguru Kere",
          "Boyipalli Kere",
          "Chandur kere",
          "Chennagiri",
          "Chintopu, near Achaganapalli",
          "Chitravathi River Reservoir",
          "Chowdasandra Ã Â²Å¡Ã Â³Å’Ã Â²Â¡Ã Â²Â¸Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°",
          "College of Sericulture, Chintamani",
          "Dandiganahalli Reservoir",
          "Darinaikanapalya (DN Palya) Kere",
          "Devarabetta Reserve Forest",
          "Eagaletpalli Cheruvu",
          "Eegaletihalli Kuntte (on hillock)",
          "Ganjigunte Kere",
          "Gantal Mallamma Forest",
          "Gauribidanur--Penneru bridge",
          "Gopasandra Kere,Chintamani",
          "Gudisihalli Kere",
          "Gullutummanahalli Kere",
          "Gulur Lake",
          "Guruthatha Bhavi (Achaganapalli)",
          "Handiganala,  Ã Â²Â¹Ã Â²â€šÃ Â²Â¡Ã Â²Â¿Ã Â²â€”Ã Â²Â¨Ã Â²Â¾Ã Â²Â³",
          "Hosapete (Venkatapura) Kere",
          "Kadumalleshwara Swamy Hill (Chintamani)",
          "Kaiwara",
          "Kaiwara Kere",
          "Kalkunte kere (near Ballagere)",
          "Kamat Upachar, Chikkaballapur",
          "Kambalahalli Hills Ã Â²â€¢Ã Â²â€šÃ Â²Â¬Ã Â²Â¾Ã Â²Â²Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Kanampalli Kere",
          "Kannampalli Lake and Hillock",
          "Lanjja Cheruvu",
          "Makireddypalli",
          "Malligurki Kere",
          "Manchenahalli Kere",
          "Meruvappalli",
          "Moodachintapalli",
          "Mustoor Kere",
          "Mysore City Bird Atlas 17B, Bogadi Kere. Ã Â²Â¬Ã Â³â€¹Ã Â²â€”Ã Â²Â¾Ã Â²Â¦Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Nallapareddypalli Kere",
          "Nandi Hills",
          "Nandi hills--Foothills",
          "Nandi Hills (to merge)",
          "Nandi Hills--Trail from Sultanpet village to top",
          "Narasimha Devara Betta Forest--Kethabyraveshwara Temple",
          "Narasimha Devara Betta Forest--Pavandahalli",
          "Narasimha Devara Betta Forest--Sri Kanive Veeradhimmamma Temple",
          "Papagni Math",
          "Papagni - Sri Durga Devi Temple",
          "Parikrma Oxygen",
          "Pidachalahalli Scrub",
          "Pond near Anjaneya Swami Temple, Ramapatna Rd",
          "Sabbanahalli Hills",
          "Sabbanahalli (Vatadahosahalli) Kere",
          "Sadali Lake (Ã Â²Â¸Ã Â²Â¦Ã Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Setticheruvu",
          "Settikere Kere",
          "Sidlaghatta town",
          "Silver Oak Farm, Sultanpet Village",
          "Skandagiri trek Road",
          "Soma Vineyards, Sonnenahalli",
          "Sonnapura",
          "Srinivasa Sagara, Nallakadirenahalli",
          "Talakayila Konda Kere",
          "Thondebhavi",
          "Timmasandra Cheruvu",
          "Tondebavi Railway Station",
          "Varlabetta Fort,Varlakonda",
          "Venkatapura Ridge",
          "Yadaralahalli Kere",
        ],
      },
      {
        district: "Koppal",
        localities: [
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMPÃ Â²ÂµÃ Â²Â¾Ã Â²Â²Ã Â²Â¯, Ã Â²â€¡Ã Â²Å¸Ã Â²â€”Ã Â²Â¿",
          "Bukkasagara Bridge, Hampi Ã Â²Â¬Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â¸Ã Â²Â¾Ã Â²â€”Ã Â²Â° Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ , Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿",
          "Hemagudda Kere Ã Â²Â¹Ã Â³â€¡Ã Â²Â®Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hulikere Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â²Â¿Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Irkalgada Kere Ã Â²â€¡Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kanakagiri Kere Ã Â²â€¢Ã Â²Â¨Ã Â²â€¢Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mahadeva Temple, Itagi Ã Â²Â®Ã Â²Â¹Ã Â²Â¾Ã Â²Â¦Ã Â³â€¡Ã Â²Âµ Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¾Ã Â²Â²Ã Â²Â¯, Ã Â²â€¡Ã Â²Å¸Ã Â²â€”Ã Â²Â¿",
          "Malakasamudra Kere, Yelaburga Ã Â²Â®Ã Â²Â²Ã Â²â€¢Ã Â²Â¸Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Ã Â²Â¯Ã Â²Â²Ã Â²Â¬Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾",
          "Male Mallappa Temple Ã Â²Â®Ã Â²Â³Ã Â³â€  Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²ÂªÃ Â³ÂÃ Â²Âª Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨",
          "Mixed Fields and Scrub - Hatti (North) Ã Â²Â®Ã Â²Â¿Ã Â²Â¶Ã Â³ÂÃ Â²Â° Ã Â²Å“Ã Â²Â¾Ã Â²â€” Ã Â²Â®Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³Â Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â - Ã Â²Â¹Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ (Ã Â²â€°Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â²Â°)",
          "Navabrindavana Ã Â²Â¨Ã Â²Âµ Ã Â²Â¬Ã Â³Æ’Ã Â²â€šÃ Â²Â¦Ã Â²Â¾Ã Â²ÂµÃ Â²Â¨",
          "Nidasheshi Grasslands & Fields Ã Â²Â¨Ã Â²Â¿Ã Â²Â¡Ã Â²Â¶Ã Â³â€¡Ã Â²Â·Ã Â²Â¿ Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â Ã Â²Â®Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³Â Ã Â²Â¹Ã Â³Å Ã Â²Â²Ã Â²â€”Ã Â²Â³Ã Â³Â",
          "Nidasheshi Kere Ã Â²Â¨Ã Â²Â¿Ã Â²Â¡Ã Â²Â¶Ã Â³â€¡Ã Â²Â·Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Venkatagiri Kere Ã Â²ÂµÃ Â³â€ Ã Â²â€šÃ Â²â€¢Ã Â²Å¸Ã Â²â€”Ã Â²Â¿Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
        ],
      },
      {
        district: "Shivamogga",
        localities: [
          "Agumbe--Agumbe Rainforest Research Station",
          "Agumbe--Grassland",
          "Agumbe--Jogi Gundi Waterfall",
          "Agumbe--Onake Abbi Trail",
          "Agumbe--Rural & Surrounding",
          "Ambligola Reservoir",
          "Beguvalli Lake",
          "Bharathipura, Yadagudde",
          "Bypass Bridge, Bhadravathi",
          "Daralagodu",
          "Gaani Kere, Anandapuram",
          "Gajanur State Forest",
          "Gondi Checkdam, kaiga",
          "Gowri Kere",
          "Gudavi Bird Sanctuary",
          "Gudavi Bird Sanctuary, Karnataka",
          "Harnahalli",
          "Heggodu",
          "Hidlumane Falls",
          "highschool garden THIRTHAHALLI",
          "Hingaara - The Nature Stay",
          "Hire kere",
          "Hire Kere Ã Â²Â¹Ã Â²Â¿Ã Â²Â°Ã Â³â€¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hosanagara",
          "Ikkeri bellur",
          "JLR, Sakrebyle",
          "Jog Colony-Ã Â²Å“Ã Â³â€¹Ã Â²â€” Ã Â²â€¢Ã Â²Â¾Ã Â²Â²Ã Â³â€¹Ã Â²Â¨Ã Â²Â¿",
          "Jog Falls",
          "Kalinga Centre for Rainforest Ecology",
          "Kavaledurga Fort",
          "Kavaledurga Kere",
          "Keladi Hire Kere",
          "Khaji Garden Park",
          "Kodachadri, Kaarekatte",
          "Kundadri Hills",
          "Kuntinamadu Ã Â²â€¢Ã Â³ÂÃ Â²â€šÃ Â²Å¸Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â®Ã Â²Â¦Ã Â³Â",
          "Kuppali",
          "Kuvempu University Campus",
          "Malanduru",
          "Matthur Village",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Mukthi Hariharapura Ã Â²Â®Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ Â²Â¤Ã Â²Â¿ Ã Â²Â¹Ã Â²Â°Ã Â²Â¿Ã Â²Â¹Ã Â²Â°Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Muppane, Shimoga",
          "Nagara fort",
          "Navilkall",
          "Neechadi",
          "NeeralaKoppa, Bhadravathi",
          "Nittur",
          "Paradise Wild Hills Resort",
          "Play ground highschool",
          "Prashanta Vanam",
          "Purdal Dam",
          "Sagara",
          "Sakrebyle Elephant Camp",
          "Sharavathi Wildlife Sanctuary",
          "Sharavati Valley WLS",
          "Sharavati Valley WLS--KB Circle",
          "Talakalale Balancing Reservoir, Sagara",
          "Thunga River -Kushavati , Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ -Ã Â²â€¢Ã Â³ÂÃ Â²Â¶Ã Â²Â¾Ã Â²ÂµÃ Â²Â¤Ã Â²Â¿",
          "University of Agricultural and Horticultural Sciences--Guest House area, Shivamogga",
          "University of Agricultural and Horticultural Sciences--IFS Unit, Shivamogga",
        ],
      },
      {
        district: "Kolar",
        localities: [
          "Ammerahalli lake",
          "Angidappa Kunte",
          "Antara Gange",
          "Avanibetta",
          "Bachubetta Scrub Forest,  Ã Â²Â¬Ã Â²Â¾Ã Â²Å¡Ã Â³ÂÃ Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸ Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "Bangarpet Railway Station",
          "Bengaluru North University, PG Center, Mangasandra",
          "Bethamangalam Reservoir, Kolar Dt",
          "Coromandel",
          "Cyanide mountains",
          "Devarayasamudra Kere",
          "Doddakere",
          "Doddakere--Patalamma Temple path (water canal)",
          "dyapasandra",
          "dyapasandra tank",
          "Dyapasandra Tank",
          "Ekalavya Residential School, Gangannagaripalli",
          "Gajaldinne Forest",
          "Gandhi circle, Malur",
          "Hanumanahalli Betta/Kere",
          "Holali Lake (Ã Â²Â¹Ã Â³Å Ã Â²Â²Ã Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Holur Forest",
          "Honnasetthalli lake",
          "Hosakere Lake (Ã Â²Â¹Ã Â³Å Ã Â²Â¸Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Hudukula Lake",
          "Kaji Kalla Halli Betta",
          "Kamasamudra Kere",
          "Kashettipalli Kere Ã Â²â€¢Ã Â²Â¸Ã Â³â€ Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â²Â¿Ã Â²ÂªÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "KGF grasslands",
          "Kolaramma lake",
          "Kotaballapalli Forest Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â²Â¬Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²ÂªÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¿ Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "Lakshi Sagara Lake",
          "Lakshmipura Kere",
          "Madarankapalli Tank",
          "Malleshwaraswamy hills, kamasamundra",
          "Malur Railway Station",
          "Mango Orchards, Kotaballapalli Ã Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿Ã Â²Â¨Ã Â²Â¤Ã Â³â€¹Ã Â²Å¸ Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â²Â¬Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²ÂªÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¿",
          "Mulbagal (OBI)",
          "Mushtaralli Lake Kolar Dist Karnataka",
          "Mustrahalli Nagesh Dam",
          "Nandikamanahalli Hill",
          "Narsapura Kere, Kolar Dist",
          "Perjenahalli Lake",
          "Ramasagara Lake",
          "Ramasagar Lake--Northern bank",
          "Rayalpad",
          "Sonappanahatti Lake",
          "Srinivaspura",
          "Thimmanayakahalli",
          "Thirumalahatti",
          "Venkatapura",
          "Vokkaleri Chikkere",
          "Ysandra",
        ],
      },
      {
        district: "Chikkamagaluru",
        localities: [
          "Ayyana Kere",
          "Ã Â²Â¨Ã Â²Â¿Ã Â²Â°Ã Â³ÂÃ Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â²Â°, Ã Â²Â®Ã Â³â€šÃ Â²Â¡Ã Â²Â¿Ã Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Bababudan Giri Peak",
          "Bababudangiri Road",
          "Bababudan Hills--Kemmanagundi",
          "Bababudan Hills--Lingdahalli Kemmanagundi Road",
          "Bababudan Hills--overall hotspot",
          "Balehonnur",
          "Balur Estate (Linger)",
          "Basavanahalli kere",
          "Basur Kaval",
          "Bettagudda Retreat, Mularahalli",
          "Bhadra Buffer & Backwaters, Saluru, Narasimharajapura",
          "Bhadra Reservoir",
          "Bhadra River, Kudremukh NP",
          "Bhadra Wildlife Sanctuary",
          "Bhadra Wildlife Sanctuary--Lakkavalli",
          "Bhadra Wildlife Sanctuary--Muthodi",
          "Bhadra Wildlife Santuary",
          "Bidare Kere",
          "Chikkamagaluru--GOPALA COLONY",
          "Chikmagalur",
          "Chikmagalur Golf Club",
          "Coffee Gudda Estate",
          "Coffee Valley Homestay",
          "Divine Homestay",
          "Durgadahalli",
          "Flameback Lodges",
          "Foothills Homestay Coffee Estate",
          "Gaaligandi Village",
          "Ganganahalli Cross",
          "Ganganahalli Kere",
          "Giri Elite Homestay",
          "Hireammajji Kere, Narasimharajapura",
          "Hirekolale Kere",
          "Horanadu",
          "Hulikhan Estate",
          "Jammatige",
          "Kambaragodu Homestay",
          "Kambihalli Estate (Halli Berri)",
          "Kedigere, Karnataka, IN",
          "Kedigere  kere, Ã Â²â€¢Ã Â³â€ Ã Â²Â¦Ã Â³â‚¬Ã Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kemmangundi Road",
          "Kere Eri Lake (Magadi Lake)",
          "Keremane Homestay",
          "Kesave, Koppa",
          "Koove",
          "Kottigehara Ã Â²â€¢Ã Â³Å Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿Ã Â²â€”Ã Â³â€ Ã Â²Â¹Ã Â²Â¾Ã Â²Â°",
          "Kudremukha National Park",
          "Kudremukha NP--Bhagavathi Nature Camp",
          "Kudremukha NP--Gangadikal Peak",
          "Kudremukha NP--General Area",
          "Kudremukha NP--KIOCL Township",
          "Kudremukha NP--Peak and thereabouts",
          "Kudremukh Peak Trek APC",
          "Kuteera Home Stay",
          "Lakya Dam and Surroundings",
          "Madagada Kere",
          "Malligehani Estate",
          "Malligere Kere, Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¿Ã Â²â€”Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mandagadde Bird Sanctuary, Shimoga, Karnataka",
          "Mullayangiri",
          "Mullodi Home Stay",
          "Nalluru Kere",
          "NAL sarovar bird sanctuary 16 nala Ã Âªâ€”Ã Â«ÂÃ ÂªÅ“Ã ÂªÂ°Ã ÂªÂ¾Ã ÂªÂ¤, IN (22.833, 72.029)",
          "Nanya Bhairaweshwara Temple, Bhairapura",
          "Narasimharajapura - Bhadra WLS",
          "Primrose Villas",
          "Rain Tree Homestay",
          "River Tern Lodge",
          "Sampigekatte-Haluvalli (Kalasa-Balehonnuru Rd)",
          "Shanthi kunnj Homestay",
          "Silent Valley Resort, Kuduremukha",
          "Sitalayyanagiri Temple",
          "Sringeri",
          "Suvarnavana (Tree park), Narasimharajapura",
          "The Mellows Homestay",
          "The Serai Chikmagalur",
          "Trivik Hotels and Resorts",
          "Valley of Birds Homestay",
          "Vatakodige, Balehonnuru,",
          "Weekender Homestay",
          "Z Point Path",
        ],
      },
      {
        district: "Ramanagara",
        localities: [
          "Arkavathi Reservoir",
          "Bannerghatta National Park--Bilikal State Forest, Kodihalli range",
          "Banthimari Betta",
          "Belaguli Hills",
          "Bhimenahalli lake",
          "B.Hosur lake",
          "Bidadi--General Area (OBI)",
          "Bidadi-Kenchanakuppe",
          "Bilikal Rangaswamy Betta",
          "Byjapura Scrub and Grassland",
          "Byramangala Lake, Bangalore",
          "Camp Gee Dee / Vishnu Farm, Ragihalli - 12.721x77.559",
          "Cauvery WLS--Arkavathi Bridge",
          "Cauvery WLS--Benagodu Scrub",
          "Cauvery WLS--Bommasandra",
          "Cauvery WLS--Galibore",
          "Cauvery WLS--General Area",
          "Cauvery WLS--Hegeneru State Forest",
          "Cauvery WLS--Mekedatu",
          "Cauvery WLS--Sangama",
          "Centre for Learning (CFL), Bengaluru",
          "Chalamasandra, Tumkur Dt",
          "Channapatna",
          "Channapatna--Kunniru katte",
          "Chiguru Farms",
          "Chikka Byramangala",
          "Chowkahalli lake",
          "Chunchi falls",
          "Dabbaguli",
          "Doddibetta Peak, Near Bannerghatta Forest",
          "Dubguli Road",
          "Gabbadi Kere",
          "Gabbadi Nursery",
          "Gollaradoddi",
          "Gopalpura Kere",
          "Gullahatti Lake",
          "Gundemaranahalli Dodda Kere",
          "Halagur, near Kanakapura (OBI)",
          "Handigundi Betta Ã Â²Â¹Ã Â²â€šÃ Â²Â¦Ã Â²Â¿Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¿  Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "Harohalli (South) Kere",
          "Helligehalli Checkdam  (Yellamma Gulaganji Dam) Ã Â²Â¹Ã Â³â€ Ã Â²Â²Ã Â²Â¿Ã Â²â€”Ã Â³â€ Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¦Ã Â²Â£Ã Â³â€ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Hombalammanapet Kere",
          "Honganuru Kere",
          "Huyalappanahalli",
          "Jalamangala Karnataka",
          "KabbalDurga",
          "Kaggalahalli Bande",
          "Kaggalahalli Kere",
          "Kallahalli Kere",
          "Kamat Lokaruchi restaurant, Ramanagara",
          "Kanakapura-Arkavathi River",
          "Kanakapura, Bangalore",
          "Kanakapura--Gadasalli Kere",
          "Kanva Reservoir",
          "Kanva Reservoir North Side",
          "Kengel Hanumanthaiah Tree Park",
          "Kumbalagodu lake",
          "Madiwala Shivanankareshwara Temple, Dodda koppa, Kanakapura",
          "Magadi Lake",
          "Managallu Rocky Hillock Ã Â²Â®Ã Â²Â¾Ã Â²Â¨Ã Â²â€”Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³Â Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "Manchanabele Reservoir, Bangalore",
          "Maralebekuppe Kere",
          "Mavathur Kere, Kanakapura",
          "Mudagere",
          "Muninagara Kere & Surroundings",
          "Muthurayapura",
          "Mutturayanaswami Betta Ã Â²Â®Ã Â³ÂÃ Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Â¸Ã Â³ÂÃ Â²ÂµÃ Â²Â¾Ã Â²Â®Ã Â²Â¿ Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "Nayakanahalli--The Backyard Camp",
          "Nayakanapalya Lake",
          "Nelligudde Kere",
          "Nelligude Kere--Kakramanahalli side",
          "Nethenahalli Kere  Ã Â²Â¨Ã Â²Â¿Ã Â²Â¤Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Odakae Kere",
          "Pyramid Valley",
          "Ragihalli",
          "Ragihalli Sunrise point",
          "Ramamman Kere",
          "Ramanagara--Powlidoddi kere",
          "Ramanagara Tree Park Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯ Ã Â²â€¡Ã Â²Â²Ã Â²Â¾Ã Â²â€“Ã Â³â€  Ã Â²â€°Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â¨Ã Â²ÂµÃ Â²Â¨",
          "Ramdevara Betta, Ramanagara",
          "Ramdevara Betta, Ramanagara--Ramanagar Climbing Spot",
          "Ravagodlu Betta",
          "Ravuthenahalli Lake",
          "Sangama - Mutthatthi Road",
          "Savanadurga Forest - Manchanabale Road",
          "Savanadurga Peak",
          "Savanadurga State Forest",
          "School of Engineering and Technology - (SET JU)",
          "Seshagirihalli lake",
          "Shree Gavi Ranganathaswami Gudi",
          "Shyanumangala Kere, Ã Â²Â¶Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â¨Ã Â³ÂÃ Â²Â®Ã Â²â€šÃ Â²â€”Ã Â²Â²  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Siddadevarabetta State Forest",
          "Sri Flora Farm",
          "Sri Revanasideshwara (SRS) Hills",
          "Suddakatte Kere",
          "Suvarnamukhi Reservoir",
          "Thattekere Lake",
          "Thenginakallu State Forest , Ã Â²Â¤Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â¿Ã Â²Â¨Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³Â Ã Â²Â¸Ã Â³ÂÃ Â²Å¸Ã Â³â€¡Ã Â²Å¸Ã Â³Â Ã Â²Â«Ã Â²Â¾Ã Â²Â°Ã Â³â€ Ã Â²Â¸Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Thimmappa Swamy Temple",
          "Thippasandra Lake, Ramnagara Dist.",
          "Uyyamballi Kere (Temple on Lake)",
          "Vaderahalli tank, off Kanakapura Road",
          "Vrishabhavathi River Viewpoint",
          "Yadamaranahalli Lake",
        ],
      },
      {
        district: "Uttara Kannada",
        localities: [
          "Subhadra Hotel",
          "Old Magazine House, Ganeshgudi",
          "Birds Paradise",
          "Devinilaya,Sirsi",
          "Jamba Road",
          "Shirali--Neerkanta",
          "Kerwadi Lake, Kaiga",
          "Sykes Point",
          "Wild Kasarwadi",
          "Baithkol Harbour , uttarakannada",
          "Sindhuvalli, Nanjanagudu (Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â§Ã Â³ÂÃ Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿)",
          "Aresami Lake and surroundings",
          "Honnavara--Ramteertha",
          "Anagod Reserve Forest",
          "Idgundi-Kaiga road",
          "Mattighatta",
          "Kavla Caves",
          "Pragati Home Stay,Nagarkodi",
          "Pavinkurva South (Hanging Bridge), Karki",
          "Irpage, Kaiga",
          "Kodibag forest office, karwar",
          "Thunga River -Kushavati , Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²",
          "Uttarakoppa Forest",
          "Yellapura Garbage Dump",
          "Eco Beach Kasarkod",
          "Devbagh--Mudflats",
          "Karwar Beach - Pavilion Area, uttarakannada",
          "Kadra Dam",
          "Kaankere",
          "Kadumane Homestay",
          "Gudnapura lake,Sirsi-Banavasi Road,Karnataka",
          "Nakhuda Beach",
          "Nisarga Guest House",
          "Kali Estuary, Karwar",
          "Hornbill River Resorts",
          "Hartuga",
          "Sirsi--Konesar",
          "Kadra Depot",
          "Kudle beach,Gokarna,Uttarkannada,Karnataka,India",
          "Hotegali Dam",
          "Syntheri Rocks, Gund, Karnataka, India",
          "Sonda Kere",
          "Thunga River -Kushavati , Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦",
          "Kagal, kumta",
          "Honnavara--Pavinakurve Paddyfields",
          "Shiraley water falls - Yellapura",
          "Kadra  Dam Road, Kaiga, karnataka",
          "Hosalli Kere",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP Â²Å“Ã Â²Â¨Ã Â²Â¿",
          "Heepalli, Sirsi, Karnataka",
          "Jatayu Teertha",
          "Dandeli",
          "Majali Pond",
          "Vrukshagandha, Kaadumane",
          "Shantadurga Mineral Industries (Restricted access)",
          "Towards Hunli, National Highway 313, Ã¦Å¾â€”Ã¨Å ÂÃ¥Â¸â€š (28.317, 95.9",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Anshi NP",
          "Gopashitta, Karwar  Ã Â²â€”Ã Â³â€¹Ã Â²ÂªÃ Â²Â¶Ã Â²Â¿Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "Kulgi Nature camp",
          "Madhuvan Homestay",
          "Karwar Beach--North End",
          "Dandeli--Timber Depot",
          "Kali Tiger Reserve",
          "Mirjan - Khandagar Road, Nagur",
          "Hampi--Matanga Hill Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Â®Ã Â²Â¾Ã Â²Â¤Ã Â²â€šÃ Â²â€” Ã Â²ÂªÃ Â²Â°Ã Â³ÂÃ Â²ÂµÃ Â²Â¤",
          "Toppalakeri, Karki, Honnavara",
          "Kulgi nature camp trail",
          "Gokarna--Sanikatta",
          "Usoda, road towards Dandeli",
          "Kaiga",
          "Gokarna Main Beach",
          "Anshi Nature Camp",
          "Old Magazine House",
          "Tilmati Beach, Karwar",
          "Kali Tigre Reserve",
          "Dandeli Wildlife Sanctuary",
          "Sharavathi Wildlife Sanctuary",
          "Karwar Beach--General Area (OBI)",
          "Kodasalli Reservoir",
          "Kodibag Jetty",
          "Yeramukh",
          "LTM Eco Camp",
          "Anmod",
          "The Niche - Malnad Farmstay",
          "Haliyal Kote (Fort)",
          "ARS Paddyfields, Sirsi",
          "Muroor, Kumta",
          "Aghanashini River Bank",
          "Prashu's Home, Kaiga Township",
          "Hornbill Dust Bath Site",
          "AMARA Homestay, Kariyadi, Karnataka",
          "Dhobighat Road (Hill trail)",
          "Dharma Reservoir",
          "Jackwell, Near Supa Dam, Ganeshgudi",
          "Supa Dam, Ganeshgudi",
          "Kaduvinakatte Dam",
          "Kota, Anantawadi village",
          "Gokarna Beach",
          "Castle Rock Forest Range",
          "Kanasgiri",
          "Ganeshgudi village",
          "Kali River Bridge, Badgund",
          "Honnavara--Royalkeri",
          "Oyster Rocks (Devgad Island)",
          "Shingewadi",
          "Dandeli Milind's Hideout, Kondapa, Dandeli, Karnataka, IN",
          "Kali Tiger Reserve--Kulgi",
          "Naval Beach Karwar",
          "Jungle Lodges and Resort, Dandeli",
          "Haliyal",
          "Nushikote Mangroves Ã Â²Â¨Ã Â³ÂÃ Â²Â¶Ã Â²Â¿Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€ ",
          "Green World Resort",
          "Betkuli Gazani Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€”Ã Â²Å“Ã Â²Â¨Ã Â²Â¿",
          "Timber Depot Dandeli",
          "Koti Teertha, Gokarna",
          "Dept of Marine Biology, Kodibag, uttarakannada",
          "Kaiga power  station, kaiga",
          "Chittekambi Ã Â²Å¡Ã Â²Â¿Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³â€ Ã Â²â€¢Ã Â²â€šÃ Â²Â¬Ã Â²Â¿",
          "Om Beach, Gokarna",
          "Mudgeri Dam",
          "Karwar Beach - South End",
          "Jamba",
          "Sahasralinga",
          "Ganeshgudi Jungle Lodge",
          "Bekkinajaddi",
          "Mallapur Island Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¨Ã Â²",
          "Konekarwada, Karwar",
          "Hayagreeva Samudra, Sonda",
          "Yana Caves",
          "Santhe Gadde Ã Â²Â¸Ã Â²â€šÃ Â²Â¤Ã Â³â€ Ã Â²â€”Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â³â€ ",
          "Govt Arts & Science College, karwar",
          "Devbagh -- Devbagh Beach Resort (Jungle Lodges)",
          "Govt. Arts & Science College, Karwar, uttarakannada",
          "Red Earth - Gokarna",
          "Gersoppa",
          "Seegekeri, Yellapura Taluk",
          "Nagarkodi Pragati Home stay",
          "Kerwadi",
          "Shirve",
          "Dandeli WLS, Karnataka",
          "Bada Kanshirada Lake, Dandeli",
          "Kharge Joog",
          "Mundigekere Bird Sanctuary",
          "Medini Cross",
          "Flycatcher Adventures & Resorts, Dandeli",
          "Kasurkud (OBI)",
          "Karwar Post Office, uttarakannada",
          "Kaiga Township",
          "Isloor Forest, Sirsi",
          "Virje, Kaiga, Karnataka",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )",
          "Bison River Resorts",
          "Castle Rock",
          "Dandeli Wildcraft Resort Trail",
          "Off The Grid Homestay",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP",
          "Chapakhand Village",
          "Honnavar -Tolasani Road",
          "Karikana Forest Range, Honnavara",
          "Hosad Forest, Kumta",
          "Sharavathi WLS--Malemane",
          "Asnoti",
          "APMC Siddapura",
          "Honnavar (OBI)",
          "Kumta--Masur Wetlands",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²",
          "Tadri Estuary",
          "Hegde, Kumta",
          "Kaiga Township, Kaiga",
          "Nivli-Shirve",
          "Devbag Creek",
          "Haroor",
          "Kelaginakeri Wetlands and Paddyfields",
          "Murudeshwar",
          "Hawgi Kere, Haliyal",
          "Isloor Kere",
          "Kavadikere lake, Magod falls road, Sirsi",
          "Aligadda Beach, Karwar",
          "College of Forestry",
          "Honnavara--Kellengere",
          "Devkar Falls",
          "Kadumane Homestay, Dandeli",
          "Yellapura",
          "Amarde, Dandeli",
          "Kerakai Forest --Sirsi",
          "Kavalwada Kere",
          "Dandeli--Circuit House",
          "Ã Â¤Â°Ã Â¤Â¿Ã Â¤â€šÃ Â¤â€” Ã Â¤Â°Ã Â¥â€¹Ã Â¤Â¡  Ã Â¥Â®Ã Â¥Â­ Road, Bastar, ",
          "Ulvi , Anshi Tiger Reserve",
          "Sathoddi Falls",
          "Bommanahalli Reservoir",
          "Kampalapura Kere  Ã Â²â€¢Ã Â²â€šÃ Â²ÂªÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€",
          "Mysore City Bird Atlas 17B, Bogadi Kere. Ã Â²Â¬Ã Â³â€¹Ã Â²â€”Ã Â²Â¾Ã Â²Â¦Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kumta--Arre Grasslands",
          "Kulgi Nature Camp",
          "Kumta--Kadnir",
          "Devbag Beach",
          "Attiveri Bird Sanctuary",
          "Honnavara--Vilayti",
          "Kulgi",
          "Vibhuthi Falls",
          "Kumta--Kallabbe Cross",
          "Nagarakodi",
          "Nilkod Ã Â²Â¨Ã Â³â‚¬Ã Â²Â²Ã Â²â€¢Ã Â³â€¹Ã Â²Â¡Ã Â³Â",
          "Kali Adventure Camp, Dandeli",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â",
          "Magod Falls, Sirsi, Karnataka",
          "Ã Â¤Â®Ã Â¤Â¿Ã Â¤Â Ã Â¤Â¾Ã Â¤â€”Ã Â¤Â°, Navi Mumbai, Maharashtra, IN (18.907, 72.972)",
          "Murkwada  kere",
          "kuchegar , kaiga",
          "Dandeli Jungle Safari",
          "Halga and Ulaga village, Kaiga",
          "Sonarwada Baad",
          "Dandeli, Anshi WLS, Karnataka",
          "Sunkeri Backwaters",
          "Karwar Beach - Garden",
          "Kadra Park",
          "Gundupatna Kere, Lingapura Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° (Kirangoor Lake)",
        ],
      },
      {
        district: "Haveri",
        localities: [
          "Adavisomapur Kere Ã Â²â€¦Ã Â²Â¡Ã Â²ÂµÃ Â²Â¿Ã Â²Â¸Ã Â³â€¹Ã Â²Â®Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Airani Fort, Hirebidari Ã Â²ÂÃ Â²Â°Ã Â²Â£Ã Â²Â¿ Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€ , Ã Â²Â¹Ã Â²Â¿Ã Â²Â°Ã Â³â€¡Ã Â²Â¬Ã Â²Â¿Ã Â²Â¦Ã Â²Â°Ã Â²Â¿",
          "Bankapur Peacock Sanctuary Ã Â²Â¬Ã Â²â€šÃ Â²â€¢Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¨Ã Â²ÂµÃ Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Durgadevi Kere Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Galageshwara Temple, Galaganath Ã Â²â€”Ã Â²Â³Ã Â²â€”Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨, Ã Â²â€”Ã Â²Â³Ã Â²â€”Ã Â²Â¨Ã Â²Â¾Ã Â²Â¥",
          "Gangajala Kere, Ranebennur Ã Â²â€”Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²Å“Ã Â²Â² Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ , Ã Â²Â°Ã Â²Â¾Ã Â²Â£Ã Â³â€ Ã Â²Â¬Ã Â³â€ Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â",
          "Heggeri Kere  Ã Â²Â¹Ã Â³â€ Ã Â²â€”Ã Â³ÂÃ Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kaginele Kere Ã Â²â€¢Ã Â²Â¾Ã Â²â€”Ã Â²Â¿Ã Â²Â¨Ã Â³â€ Ã Â²Â²Ã Â³â€  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kumarapuram/Manalmantha Ã°Å¸ÂÂ¡Ã°Å¸ÂÂ£Ã°Å¸ÂÂ¥Ã°Å¸ÂÂ¤Ã°Å¸ÂÂ¦",
          "Mukteshwara Temple, Chaudayyanapura Ã Â²Â®Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ Â²Â¤Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨, Ã Â²Å¡Ã Â³Å’Ã Â²Â¡Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Ranebennur Blackbuck Sanctuary Ã Â²Â°Ã Â²Â¾Ã Â²Â£Ã Â²Â¿Ã Â²Â¬Ã Â³â€ Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â®Ã Â³Æ’Ã Â²â€” Ã Â²â€¦Ã Â²Â­Ã Â²Â¯Ã Â²Â¾Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯",
          "Ranebennur Blackbuck Sanctuary--Medleri Kere Ã Â²Â°Ã Â²Â¾Ã Â²Â£Ã Â²Â¿Ã Â²Â¬Ã Â³â€ Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â®Ã Â³Æ’Ã Â²â€” Ã Â²â€¦Ã Â²Â­Ã Â²Â¯Ã Â²Â¾Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯--Ã Â²Â®Ã Â³â€ Ã Â²Â¡Ã Â³ÂÃ Â²Â²Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Salu Marada Thimmakka Nature Park",
          "Savanur Kere Ã Â²Â¸Ã Â²ÂµÃ Â²Â£Ã Â³â€šÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Someshwara Temple, Haralahalli Ã Â²Â¸Ã Â³â€¹Ã Â²Â®Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨, Ã Â²Â¹Ã Â²Â°Ã Â²Â²Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Tadas Ã Â²Â¤Ã Â²Â¡Ã Â²Â¸",
          "Timmarpur Kere Ã Â²Â¤Ã Â²Â¿Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Tungabhadra River Bridge Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€  NH48, Harihar",
          "Varada River--Hosaritti Ã Â²ÂµÃ Â²Â°Ã Â²Â¦Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿--Ã Â²Â¹Ã Â³Å Ã Â²Â¸Ã Â²Â°Ã Â²Â¿Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â²Â¿",
          "Varuna kKere  Ã Â²Â¹Ã Â³â€ Ã Â²â€”Ã Â³ÂÃ Â²â€”Ãâ€¢Ã Â³â€ Ã Â²Â°Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
        ],
      },
      {
        district: "Bagalkote",
        localities: [
          "Agastya Kere & Badami Caves Ã Â²â€¦Ã Â²â€”Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³ÂÃ Â²Â¯ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€  Ã Â²Â®Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³Â Ã Â²Â¬Ã Â²Â¾Ã Â²Â¦Ã Â²Â¾Ã Â²Â®Ã Â²Â¿ Ã Â²â€”Ã Â³ÂÃ Â²Â¹Ã Â³â€ Ã Â²â€”Ã Â²Â³Ã Â³Â",
          "Alamatti Backwaters-Anagawadi, Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â-Ã Â²â€¦Ã Â²Â¨Ã Â²â€”Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿",
          "Alamatti Backwaters--Beerkabbi Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Â¬Ã Â³â‚¬Ã Â²Â°Ã Â²â€¢Ã Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¿",
          "Alamatti Backwaters--Herkal  Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Â¹Ã Â³â€ Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â",
          "Alamatti Backwaters--Rolli Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Â°Ã Â³Å Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Almatti Dam, Bagalkot Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Â¡Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²â€š, Ã Â²Â¬Ã Â²Â¾Ã Â²â€”Ã Â²Â²Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€ ",
          "Almatti Reservoir--Solapur Highway Ã Â²â€ Ã Â²Â²Ã Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯ --Ã Â²Â¸Ã Â³â€¹Ã Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¹Ã Â³â€ Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â°Ã Â²Â¿",
          "Arshibidu Kere Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¶Ã Â²Â¿Ã Â²Â¬Ã Â³â‚¬Ã Â²Â¡Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Badami Ã Â²Â¬Ã Â²Â¾Ã Â²Â¦Ã Â²Â¾Ã Â²Â®Ã Â²Â¿",
          "Bagalkot Railway Station Ã Â²Â¬Ã Â²Â¾Ã Â²â€”Ã Â²Â²Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€  Ã Â²Â°Ã Â³Ë†Ã Â²Â²Ã Â³Â Ã Â²Â¨Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â£",
          "Benakatti Ã Â²Â¬Ã Â³â€ Ã Â²Â¨Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿",
          "Benakatti West Hillock Ã Â²Â¬Ã Â³â€ Ã Â²Â¨Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²ÂªÃ Â²Â¶Ã Â³ÂÃ Â²Å¡Ã Â²Â¿Ã Â²Â® Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Budihal Tq. Badami Ã Â²Â¬Ã Â³â€šÃ Â²Â¦Ã Â²Â¿Ã Â²Â¹Ã Â²Â¾Ã Â²Â³ Ã Â²Â¤Ã Â²Â¾. Ã Â²Â¬Ã Â²Â¾Ã Â²Â¦Ã Â²Â¾Ã Â²Â®Ã Â²Â¿",
          "Chinkara WLS--Bilgi  Ã Â²Å¡Ã Â²Â¿Ã Â²â€šÃ Â²â€¢Ã Â²Â¾Ã Â²Â° Ã Â²ÂµÃ Â²Â¨Ã Â³ÂÃ Â²Â¯Ã Â²Â§Ã Â²Â¾Ã Â²Â®-Ã Â²Â¬Ã Â³â‚¬Ã Â²Â³Ã Â²â€”Ã Â²Â¿",
          "Didaga Waterfalls and Pool Ã Â²Â¦Ã Â²Â¿Ã Â²Â¡Ã Â²â€” Ã Â²Å“Ã Â²Â²Ã Â²ÂªÃ Â²Â¾Ã Â²Â¤ Ã Â²Â®Ã Â²Â¤Ã Â³ÂÃ Â²Â¤Ã Â³Â Ã Â²â€¢Ã Â³Å Ã Â²Â³",
          "Ghataprabha River Bank, Bagalkote Town Ã Â²ËœÃ Â²Å¸Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â­Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²Â¦Ã Â²â€šÃ Â²Â¡Ã Â³â€ , Ã Â²Â¬Ã Â²Â¾Ã Â²â€”Ã Â²Â²Ã Â²â€¢Ã Â³â€¹Ã Â²Å¸Ã Â³â€  Ã Â²ÂªÃ Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â£",
          "Ghataprabha River Bridge, Mudhol  Ã Â²ËœÃ Â²Å¸Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â­Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ , Ã Â²Â®Ã Â³ÂÃ Â²Â§Ã Â³â€¹Ã Â²Â³",
          "Guledagudda Kere Ã Â²â€”Ã Â³ÂÃ Â²Â³Ã Â³â€¡Ã Â²Â¦Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Guledagudda Road Railway Station Ã Â²â€”Ã Â³ÂÃ Â²Â³Ã Â³â€¡Ã Â²Â¦Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€  Ã Â²Â°Ã Â³Ë†Ã Â²Â²Ã Â³Â Ã Â²Â¨Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â£",
          "Halakurki Kere Ã Â²Â¹Ã Â²Â²Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Handekolla Hanamappa Forest Ã Â²Â¹Ã Â²â€šÃ Â²Â¡Ã Â³â€ Ã Â²â€¢Ã Â³Å Ã Â²Â³Ã Â³ÂÃ Â²Â³ Ã Â²Â¹Ã Â²Â¨Ã Â³ÂÃ Â²Â®Ã Â²ÂªÃ Â³ÂÃ Â²Âª Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "Handekolla Hanamappa Kere Ã Â²Â¹Ã Â²â€šÃ Â²Â¡Ã Â³â€ Ã Â²â€¢Ã Â³Å Ã Â²Â³Ã Â³ÂÃ Â²Â³ Ã Â²Â¹Ã Â²Â¨Ã Â³ÂÃ Â²Â®Ã Â²ÂªÃ Â³ÂÃ Â²Âª Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Haveli Ã Â²Â¹Ã Â²ÂµÃ Â³â€¡Ã Â²Â²Ã Â²Â¿",
          "Hirekeri Ã Â²Â¹Ã Â²Â¿Ã Â²Â°Ã Â³â€ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â²Â¿",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA 13.02314Ã Â³ÂÃ Â²Â¡Ã Â³â€ ",
          "Indraprastha Ã Â²â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²â€”Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â³â€ ",
          "Industrial Area (Lakes on both sides of the road) Ã Â²â€¢Ã Â³Ë†Ã Â²â€”Ã Â²Â¾Ã Â²Â°Ã Â²Â¿Ã Â²â€¢Ã Â²Â¾ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶ (Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€  Ã Â²Â¬Ã Â²Â¦Ã Â²Â¿Ã Â²Â¯ Ã Â²Å½Ã Â²Â°Ã Â²Â¡Ã Â³â€š Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²â€”Ã Â²Â³Ã Â³Â)",
          "Kadathur Kulam (Ã Â®â€¢Ã Â®Å¸Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Katti Keri, Jamkhandi Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â²Â¿, Ã Â²Å“Ã Â²Â®Ã Â²â€“Ã Â²â€šÃ Â²Â¡Ã Â²Â¿",
          "Kelgeri Kere Ã Â²â€¢Ã Â³â€ Ã Â²Â²Ã Â²â€”Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "KJSEM School, Sameerwadi Ã Â²â€¢Ã Â³â€ .Ã Â²Å“Ã Â³â€ .Ã Â²Å½Ã Â²Â¸Ã Â³Â.Ã Â²â€¡.Ã Â²Å½Ã Â²â€š. Ã Â²Â¶Ã Â²Â¾Ã Â²Â²Ã Â³â€ , Ã Â²Â¸Ã Â²Â®Ã Â³â‚¬Ã Â²Â°Ã Â²ÂµÃ Â²Â¾Ã Â²Â¡Ã Â²Â¿",
          "Kovalli Nursery Ã Â²â€¢Ã Â³â€¹Ã Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²Â¨Ã Â²Â°Ã Â³ÂÃ Â²Â¸Ã Â²Â°Ã Â²Â¿",
          "Kudala Sangama Ã Â²â€¢Ã Â³â€šÃ Â²Â¡Ã Â²Â² Ã Â²Â¸Ã Â²â€šÃ Â²â€”Ã Â²Â®",
          "Kumarapuram/Manalmantha Ã°Å¸ÂÂ¡Ã°Å¸ÂÂ£Ã°Å¸ÂÂ¥Ã°Å¸ÂÂ¤Ã°Å¸ÂÂ¦Â¨Ã Â²Â°Ã Â³ÂÃ Â²Â¸Ã Â²Â°Ã Â²Â¿",
          "Mahakoota Ancient Temple Ã Â²Â®Ã Â²Â¹Ã Â²Â¾Ã Â²â€¢Ã Â³â€šÃ Â²Å¸ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²Å¡Ã Â³â‚¬Ã Â²Â¨ Ã Â²Â®Ã Â²â€šÃ Â²Â¦Ã Â²Â¿Ã Â²Â°",
          "Mahalingapur Kere Ã Â²Â®Ã Â²Â¹Ã Â²Â¾Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mallapura Railway Bridge Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â°Ã Â³Ë†Ã Â²Â²Ã Â³Â Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Mallapur Island Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²Â¨Ã Â²Â¡Ã Â³ÂÃ Â²â€”Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â³â€ ",
          "Muchakandi Kere Ã Â²Â®Ã Â³ÂÃ Â²Å¡Ã Â²â€¢Ã Â²â€šÃ Â²Â¡Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿ Â²Â¡Ã Â²Â¿",
          "Pattadakal Ã Â²ÂªÃ Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³Â",
          "Picheli's Farm House, Mudhol Ã Â²ÂªÃ Â²Â¿Ã Â²Å¡Ã Â³â€¡Ã Â²Â³Ã Â²Â¿ Ã Â²Â«Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Â®Ã Â³Â Ã Â²Â¹Ã Â³Å’Ã Â²Â¸Ã Â³Â, Ã Â²Â®Ã Â³ÂÃ Â²Â§Ã Â³â€¹Ã Â²Â³",
          "Rangasamudra Dam--Mangalagudda Bridge  Ã Â²Â°Ã Â²â€šÃ Â²â€”Ã Â²Â¸Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â° Ã Â²â€ Ã Â²Â£Ã Â³â€ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â- Ã Â²Â®Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Shivayoga (Govinakki)  Mandira  Ã Â²Â¶Ã Â²Â¿Ã Â²ÂµÃ Â²Â¯Ã Â³â€¹Ã Â²â€” -Ã Â²â€”Ã Â³â€¹Ã Â²ÂµÃ Â²Â¿Ã Â²Â¨Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â¿ Ã Â²Â®Ã Â²â€šÃ Â²Â¦Ã Â²Â¿Ã Â²Â°",
          "Shri Nattadavi Naganatha Temple  Ã Â²Â¶Ã Â³ÂÃ Â²Â°Ã Â³â‚¬ Ã Â²Â¨Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¡Ã Â²ÂµÃ Â²Â¿ Ã Â²Â¨Ã Â²Â¾Ã Â²â€”Ã Â²Â¨Ã Â²Â¾Ã Â²Â¥ Ã Â²Â®Ã Â²â€šÃ Â²Â¦Ã Â²Â¿Ã Â²Â°",
          "Siddeshwara Temple Lake Ã Â²Â¸Ã Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â®Ã Â²â€šÃ Â²Â¦Ã Â²Â¿Ã Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "S Nijalingappa Medical College Ã Â²Å½Ã Â²Â¸Ã Â³Â Ã Â²Â¨Ã Â²Â¿Ã Â²Å“Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²ÂªÃ Â³ÂÃ Â²Âª Ã Â²ÂµÃ Â³Ë†Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²â€¢Ã Â³â‚¬Ã Â²Â¯ Ã Â²Â®Ã Â²Â¹Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Thondamanatham Lake (Ã Â®Â¤Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â®Â¾Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Unkal Kere Ã Â²â€°Ã Â²Â£Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Yadahalli Ã Â²Â¯Ã Â²Â¡Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
        ],
      },
      {
        district: "Ballari",
        localities: [
          "Ankasamudra Bird Sanctuary Ã Â²â€¦Ã Â²â€šÃ Â²â€¢Ã Â²Â¸Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â° Ã Â²ÂªÃ Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Benapatti cross roadÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Bhandri Sanna Kere Ã Â²Â¬Ã Â²â€šÃ Â²Â¡Ã Â³ÂÃ Â²Â°Ã Â²Â¿ Ã Â²Â¸Ã Â²Â£Ã Â³ÂÃ Â²Â£ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Dandanayakana Kere Ã Â²Â¦Ã Â²â€šÃ Â²Â¡Ã Â²Â¨Ã Â²Â¾Ã Â²Â¯Ã Â²â€¢Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Daroji Bear Sanctuary--Sitarama Tanda Ã Â²Â¦Ã Â²Â°Ã Â³â€¹Ã Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â²Â°Ã Â²Â¡Ã Â²Â¿ Ã Â²Â§Ã Â²Â¾Ã Â²Â®--Ã Â²Â¸Ã Â³â‚¬Ã Â²Â¤Ã Â²Â¾Ã Â²Â°Ã Â²Â¾Ã Â²Â® Ã Â²Â¤Ã Â²Â¾Ã Â²â€šÃ Â²Â¡",
          "Daroji Bear Sanctuary--Upparahalli Ã Â²Â¦Ã Â²Â°Ã Â³â€¹Ã Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â²Â°Ã Â²Â¡Ã Â²Â¿ Ã Â²Â§Ã Â²Â¾Ã Â²Â®--Ã Â²â€°Ã Â²ÂªÃ Â³ÂÃ Â²ÂªÃ Â²Â¾Ã Â²Â°Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Daroji Forest Guest House Ã Â²Â¦Ã Â²Â°Ã Â³â€¹Ã Â²Å“Ã Â²Â¿ Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯ Ã Â²â€¦Ã Â²Â¤Ã Â²Â¿Ã Â²Â¥Ã Â²Â¿Ã Â²â€”Ã Â³Æ’Ã Â²Â¹",
          "Daroji Kere Ã Â²Â¦Ã Â²Â°Ã Â³â€¹Ã Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Daroji Sloth Bear Sanctuary Ã Â²Â¦Ã Â²Â°Ã Â³â€¹Ã Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â²Â°Ã Â²Â¡Ã Â²Â¿Ã Â²Â§Ã Â²Â¾Ã Â²Â®",
          "Forest Guest house, Kamalapur (OBI)",
          "Halli Kere Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Hampi Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿",
          "Hampi-Hotel Mayura Bhuvaneshwari Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Â¹Ã Â³â€¹Ã Â²Å¸Ã Â³â€ Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â®Ã Â²Â¯Ã Â³â€šÃ Â²Â° Ã Â²Â­Ã Â³ÂÃ Â²ÂµÃ Â²Â¨Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â°Ã Â²Â¿",
          "Hampi--Jungle Lodges Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Å“Ã Â²â€šÃ Â²â€”Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â²Ã Â²Â¾Ã Â²Â¡Ã Â³ÂÃ Â²Å“Ã Â³â€ Ã Â²Â¸Ã Â³ÂÃ¢â‚¬Å’",
          "Hampi--Kannada University  Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²â€¢Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¡ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Hampi--Matanga Hill Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Â®Ã Â²Â¾Ã Â²Â¤Ã Â²â€šÃ Â²â€” Ã Â²ÂªÃ Â²Â°Ã Â³ÂÃ Â²ÂµÃ Â²Â¤",
          "Hampi--Nimbapura Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Â¨Ã Â²Â¿Ã Â²â€šÃ Â²Â¬Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Hampi--Royal Enclosure  Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿--Ã Â²Â°Ã Â²Â¾Ã Â²Å“Ã Â²Â¾Ã Â²â€šÃ Â²â€”Ã Â²Â£",
          "Kallur Kere Ã Â²Â¦Ã Â²Â°Ã Â³â€¹Ã Â²Å“Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kamalapura Kere Ã Â²â€¢Ã Â²Â®Ã Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kamalapur--General Area (OBI)",
          "Kannada University Kere Ã Â²â€¢Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¡ Ã Â²ÂµÃ Â²Â¿Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Kolagal Kere Ã Â²â€¢Ã Â³Å Ã Â²Â³Ã Â²â€”Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Malavi Dam View Point Ã Â²Â®Ã Â²Â¾Ã Â²Â²Ã Â²ÂµÃ Â²Â¿ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯ Ã Â²ÂµÃ Â³â‚¬Ã Â²â€¢Ã Â³ÂÃ Â²Â·Ã Â²Â£Ã Â²Â¾ Ã Â²Â¤Ã Â²Â¾Ã Â²Â£",
          "Malyavanta Raghunatha Temple, Hampi Ã Â²Â®Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²Â¯Ã Â²ÂµÃ Â²â€šÃ Â²Â¤ Ã Â²Â°Ã Â²ËœÃ Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Â¥ Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨, Ã Â²Â¹Ã Â²â€šÃ Â²ÂªÃ Â²Â¿",
          "Mariammanahalli Ã Â²Â®Ã Â²Â°Ã Â²Â¿Ã Â²Â¯Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Nandibanda Kere Ã Â²Â¨Ã Â²â€šÃ Â²Â¦Ã Â²Â¿Ã Â²Â¬Ã Â²â€šÃ Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Navaloor Kere Ã Â²Â¨Ã Â²ÂµÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â²ÂªÃ Â²Â°Ã Â³ÂÃ Â²ÂµÃ Â²Â¤",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)Ã Â²ÂµÃ Â²Â¤",
          "Obulapura Kere Ã Â²â€œÃ Â²Â¬Ã Â²Â³Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Papinayakanahalli Betta Ã Â²ÂªÃ Â²Â¾Ã Â²ÂªÃ Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â¯Ã Â²â€¢Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²Â¬Ã Â³â€ Ã Â²Å¸Ã Â³ÂÃ Â²Å¸",
          "Raw Water Pond, Vidyanagar Township Ã Â²â€¢Ã Â²Å¡Ã Â³ÂÃ Â²Å¡Ã Â²Â¾ Ã Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â²Â¿Ã Â²Â¨ Ã Â²â€¢Ã Â³Å Ã Â²Â³, Ã Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â¨Ã Â²â€”Ã Â²Â° Ã Â²ÂªÃ Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â£",
          "Sitarama Tanda Ã Â²Â¸Ã Â³â‚¬Ã Â²Â¤Ã Â²Â¾Ã Â²Â°Ã Â²Â¾Ã Â²Â® Ã Â²Â¤Ã Â²Â¾Ã Â²â€šÃ Â²Â¡Ã Â²Â¾",
          "Sovenahalli Kere Ã Â²Â¸Ã Â³â€¹Ã Â²ÂµÃ Â³â€¡Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Sri AB Vajapayee Zoological Park, Kamalapur Ã Â²Â¶Ã Â³ÂÃ Â²Â°Ã Â³â‚¬ Ã Â²Å½Ã Â²Â¬Ã Â²Â¿ Ã Â²ÂµÃ Â²Â¾Ã Â²Å“Ã Â²ÂªÃ Â³â€¡Ã Â²Â¯Ã Â²Â¿ Ã Â²Å“Ã Â³â€šÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¾Ã Â²Å“Ã Â²Â¿Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²ÂªÃ Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â³ÂÃ¢â‚¬Å’, Ã Â²â€¢Ã Â²Â®Ã Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Sri Vijay Vitthala Temple Area Ã Â²Â¶Ã Â³ÂÃ Â²Â°Ã Â³â‚¬ Ã Â²ÂµÃ Â²Â¿Ã Â²Å“Ã Â²Â¯ Ã Â²ÂµÃ Â²Â¿Ã Â²Â Ã Â³ÂÃ Â²Â Ã Â²Â² Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Swamimalai Forest Range- -Kumaraswamy Temple Ã Â²Â¸Ã Â³ÂÃ Â²ÂµÃ Â²Â¾Ã Â²Â®Ã Â²Â¿Ã Â²Â®Ã Â²Â²Ã Â³Ë† Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯ Ã Â²ÂµÃ Â²Â²Ã Â²Â¯- -Ã Â²â€¢Ã Â³ÂÃ Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²ÂµÃ Â²Â¾Ã Â²Â®Ã Â²Â¿ Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨",
          "Thimmalapura Ã Â²Â¤Ã Â²Â¿Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Thunga River -Kushavati , Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿ -Ã Â²â€¢Ã Â³ÂÃ Â²Â¶Ã Â²Â¾Ã Â²ÂµÃ Â²Â¤Ã Â²Â¿",
          "Tungabhadra Backwaters--Bannigola Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Â¬Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â²Â¿Ã Â²â€”Ã Â³â€¹Ã Â²Â³",
          "Tungabhadra Backwaters--Nakaralu Tanda Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²Â¨Ã Â²â€¢Ã Â²Â°Ã Â²Â¾Ã Â²Â³Ã Â³Â Ã Â²Â¤Ã Â²Â¾Ã Â²â€šÃ Â²Â¡Ã Â²Â¾",
          "Tungabhadra backwaters--Varadapura Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Â¹Ã Â²Â¿Ã Â²Â¨Ã Â³ÂÃ Â²Â¨Ã Â³â‚¬Ã Â²Â°Ã Â³Â--Ã Â²ÂµÃ Â²Â°Ã Â²Â¦Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Tungabhadra High-level Canal Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Â®Ã Â³â€¡Ã Â²Â²Ã Â³ÂÃ Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²â€¢Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Tungabhadra High Level Canal--Tejaswiloka  Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Â®Ã Â³â€¡Ã Â²Â²Ã Â³ÂÃ Â²Â®Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â²Â¦ Ã Â²â€¢Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²ÂµÃ Â³â€ --Ã Â²Â¤Ã Â³â€¡Ã Â²Å“Ã Â²Â¸Ã Â³ÂÃ Â²ÂµÃ Â²Â¿Ã Â²Â²Ã Â³â€¹Ã Â²â€¢",
          "Tungabhadra Reservoir Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Tungabhadra Reservoir-Gunda Tree Park Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯ -Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â¾ Ã Â²Â¸Ã Â²Â¸Ã Â³ÂÃ Â²Â¯Ã Â³â€¹Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â¨",
          "Tungabhadra Reservoir- Munirabad Bridge, Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯- Ã Â²Â®Ã Â³ÂÃ Â²Â¨Ã Â²Â¿Ã Â²Â°Ã Â²Â¬Ã Â²Â¾Ã Â²Â¦Ã Â³Â Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Tungabhadra Right Canal--Kamalapur Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Â¬Ã Â²Â²Ã Â²Â¦Ã Â²â€šÃ Â²Â¡Ã Â³â€  Ã Â²â€¢Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²ÂµÃ Â³â€ --Ã Â²â€¢Ã Â²Â®Ã Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Vidyanagar Township, Toranagallu Ã Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â¨Ã Â²â€”Ã Â²Â° Ã Â²Å¸Ã Â³Å’Ã Â²Â¨Ã Â³ÂÃ¢â‚¬Å’Ã Â²Â¶Ã Â²Â¿Ã Â²ÂªÃ Â³Â, Ã Â²Â¤Ã Â³â€¹Ã Â²Â°Ã Â²Â£Ã Â²â€”Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³Â",
        ],
      },
      {
        district: "Raichur",
        localities: [
          "Agriculture College Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â²Â¿  Ã Â²Â®Ã Â²Â¹Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿Ã Â²Â¦Ã Â³ÂÃ Â²Â¯Ã Â²Â¾Ã Â²Â²Ã Â²Â¯",
          "Antaragangi Ã Â²â€¦Ã Â²â€šÃ Â²Â¤Ã Â²Â°Ã Â²â€”Ã Â²â€šÃ Â²â€”Ã Â²Â¿",
          "Bedar Karlkunti Ã Â²Â¬Ã Â³â€¡Ã Â²Â¡Ã Â²Â° Ã Â²â€¢Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²Â²Ã Â²â€¢Ã Â³ÂÃ Â²â€šÃ Â²Å¸Ã Â²Â¿",
          "Bolmanadoddi Temple Ã Â²Â¬Ã Â³â€¹Ã Â²Â³Ã Â²Â®Ã Â²Â¾Ã Â²Â¨Ã Â²Â¦Ã Â³Å Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â²Â¿ Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨",
          "CEO ZP Residence Ã Â²Å“Ã Â²Â¿Ã Â²ÂªÃ Â²â€š Ã Â²Â¸Ã Â²Â¿Ã Â²â€¡Ã Â²â€™ Ã Â²Â¨Ã Â²Â¿Ã Â²ÂµÃ Â²Â¾Ã Â²Â¸",
          "Chandana Kere Ã Â²Å¡Ã Â²â€šÃ Â²Â¦Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Chikka Kotnekal Ã Â²Å¡Ã Â²Â¿Ã Â²â€¢Ã Â³ÂÃ Â²â€¢ Ã Â²â€¢Ã Â³Å Ã Â²Å¸Ã Â³ÂÃ Â²Â¨Ã Â³â€ Ã Â²â€¢Ã Â²Â²Ã Â³Â",
          "Don Bosco School Road, Devadurga Ã Â²Â¡Ã Â²Â¾Ã Â²Â¨Ã Â³ÂÃ¢â‚¬Å’Ã Â²Â¬Ã Â²Â¾Ã Â²Â¸Ã Â³ÂÃ Â²â€¢Ã Â³Å  Ã Â²Â¶Ã Â²Â¾Ã Â²Â²Ã Â³â€  Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ , Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²â€”",
          "Googal Bridge Ã Â²â€”Ã Â³â€šÃ Â²â€”Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Gundalbanda Falls Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â²Ã Â²Â¬Ã Â²â€šÃ Â²Â¡Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²ÂªÃ Â²Â¾Ã Â²Â¤",
          "Gurgunta Amareshwara Temple Area Ã Â²â€”Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Å¸Ã Â²Â¾ Ã Â²â€¦Ã Â²Â®Ã Â²Â°Ã Â³â€¡Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨ Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¶",
          "Huligudda Kere Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â²Â¿Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ishwara Temple, Bolmanadoddi Road Ã Â²Ë†Ã Â²Â¶Ã Â³ÂÃ Â²ÂµÃ Â²Â° Ã Â²Â¦Ã Â³â€¡Ã Â²ÂµÃ Â²Â¸Ã Â³ÂÃ Â²Â¥Ã Â²Â¾Ã Â²Â¨, Ã Â²Â¬Ã Â³â€¹Ã Â²Â³Ã Â²Â®Ã Â²Â¾Ã Â²Â¨Ã Â²Â¦Ã Â³Å Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â²Â¿ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ ",
          "Kolanki Ã Â²â€¢Ã Â³Å Ã Â²Â³Ã Â²â€šÃ Â²â€¢Ã Â²Â¿",
          "Krishna River--Naradagadde island Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â¾ Ã Â²Â¨Ã Â²Â¦Ã Â²Â¿--Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â²Â¦Ã Â²â€”Ã Â²Â¡Ã Â³ÂÃ Â²Â¡Ã Â³â€  Ã Â²Â¦Ã Â³ÂÃ Â²ÂµÃ Â³â‚¬Ã Â²Âª",
          "Kumarapuram/Manalmantha Ã°Å¸ÂÂ¡Ã°Å¸ÂÂ£Ã°Å¸ÂÂ¥Ã°Å¸ÂÂ¤Ã°Å¸ÂÂ¦Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Lingsugur Kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¸Ã Â²â€”Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Maliyabad Gowshala Scrub Ã Â²Â®Ã Â²Â²Ã Â²Â¿Ã Â²Â¯Ã Â²Â¾Ã Â²Â¬Ã Â²Â¾Ã Â²Â¦Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€”Ã Â³â€¹Ã Â²Â¶Ã Â²Â¾Ã Â²Â²Ã Â³â€  Ã Â²â€¢Ã Â³ÂÃ Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â²Â²Ã Â³Â Ã Â²â€¢Ã Â²Â¾Ã Â²Â¡Ã Â³Â",
          "Maliyabad Kere Ã Â²Â®Ã Â²Â²Ã Â²Â¿Ã Â²Â¯Ã Â²Â¾Ã Â²Â¬Ã Â²Â¾Ã Â²Â¦Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mallayyana Gudda, Maski Ã Â²Â®Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡, Ã Â²Â®Ã Â²Â¸Ã Â³ÂÃ Â²â€¢Ã Â²Â¿",
          "Manslapur Kere Ã Â²Â®Ã Â²Â¨Ã Â³ÂÃ Â²Â¸Ã Â²Â²Ã Â²Â¾Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Maski Nala Dam Ã Â²Â®Ã Â²Â¸Ã Â³ÂÃ Â²â€¢Ã Â²Â¿ Ã Â²Â¨Ã Â²Â¾Ã Â²Â²Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Mavina Kere Ã Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â²Â¿Ã Â²Â¨ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Merched Kere Ã Â²Â®Ã Â²Â°Ã Â³ÂÃ Â²Å¡Ã Â³â€¡Ã Â²Â¡Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Narayanapura Dam Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Â£Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€ Ã Â²Â£Ã Â³â€ Ã Â²â€¢Ã Â²Å¸Ã Â³ÂÃ Â²Å¸Ã Â³Â",
          "Nathamedu Lake (Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)ÂµÃ Â²Â¾Ã Â²Â¸",
          "PG Center (Raichur University), Mantralaya Rd Ã Â²ÂªÃ Â²Â¿.Ã Â²Å“Ã Â²Â¿. Ã Â²â€¢Ã Â³â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°, Ã Â²Â®Ã Â²â€šÃ Â²Â¤Ã Â³ÂÃ Â²Â°Ã Â²Â¾Ã Â²Â²Ã Â²Â¯ Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ ",
          "Potgal grasslands Ã Â²ÂªÃ Â³â€¹Ã Â²Â¤Ã Â²â€”Ã Â²Â²Ã Â³Â Ã Â²Â¹Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³ÂÃ Â²â€”Ã Â²Â¾Ã Â²ÂµÃ Â²Â²Ã Â³Â",
          "Potnal Ã Â²ÂªÃ Â³â€¹Ã Â²Â¤Ã Â³ÂÃ Â²Â¨Ã Â²Â¾Ã Â²Â³Ã Â³Â",
          "Raichur Bypass Road, Eklaspur Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Å¡Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²Â¬Ã Â³Ë†Ã Â²ÂªÃ Â²Â¾Ã Â²Â¸Ã Â³Â Ã Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¤Ã Â³â€ , Ã Â²ÂÃ Â²â€¢Ã Â³ÂÃ Â²Â²Ã Â²Â¾Ã Â²Â¸Ã Â²ÂªÃ Â³ÂÃ Â²Â°",
          "Raichur Railway Station Ã Â²Â°Ã Â²Â¾Ã Â²Â¯Ã Â²Å¡Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²Â°Ã Â³Ë†Ã Â²Â²Ã Â³Â Ã Â²Â¨Ã Â²Â¿Ã Â²Â²Ã Â³ÂÃ Â²Â¦Ã Â²Â¾Ã Â²Â£",
          "Rajolibanda Diversion Scheme Canal Bridge (Rajalbanda Barrage) Ã Â²Â°Ã Â²Â¾Ã Â²Å“Ã Â³â€¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â²Â¬Ã Â²â€šÃ Â²Â¡Ã Â²Â¾ Ã Â²Â¤Ã Â²Â¿Ã Â²Â°Ã Â³ÂÃ Â²ÂµÃ Â³Â Ã Â²Â¯Ã Â³â€¹Ã Â²Å“Ã Â²Â¨Ã Â³â€  Ã Â²â€¢Ã Â²Â¾Ã Â²Â²Ã Â³ÂÃ Â²ÂµÃ Â³â€  Ã Â²Â¸Ã Â³â€¡Ã Â²Â¤Ã Â³ÂÃ Â²ÂµÃ Â³â€ ",
          "Rampur Kere Ã Â²Â°Ã Â²Â¾Ã Â²â€šÃ Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Yargunti kere Ã Â²Â¯Ã Â²Â°Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Å¸Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
        ],
      },
    ],
  },
  {
    state: "Kerala",
    districts: [
      {
        district: "Thiruvananthapuram",
        localities: [
          "Arabian Sea: 8.5N 76.8E",
          "University College, Palayam.",
          "Kallattumukku",
          "Thiruvananthapuram Cental Railway Station",
          "Neyyar WLS--Aananirathy",
          "Neyyar WLS--Theerthumkara",
          "Putharikandam Maidanam (Green Patch)",
          "Neyyar WLS--Meenmutty",
          "Trivandrum (OBI)",
          "Arabian Sea: 8.7N 76.6E",
          "Puthiyathura Beach",
          "Neyyar WLS--Kombai",
          "St. Andrews Beach",
          "Trivandrum International Airport",
          "Bonafalls",
          "Elikkunnam",
          "Museum Bain's Compound",
          "Agasthiyamalai",
          "Amballoor Paddy Fields",
          "Neyyar WLS--Kappukadu",
          "Peppara WLS--Peppara",
          "Government Women's College, Vazhuthacaud",
          "Veli--Lake and Beach",
          "Jersey Farm, Vithura",
          "Varkala Beach",
          "University of Kerala--Center for Evolutionary and Integrative Biology",
          "Poovar North Beach",
          "Kanakakunnu Palace",
          "Infosys Campus",
          "Goverment College Hostel For Men, Palayam",
          "CSM Nagar",
          "Akathumuri Railway Station",
          "Kallar",
          "Jawaharlal Nehru Tropical Botanic Garden & Research Institute",
          "Arabian Sea: 8.5N 76.7E",
          "Shasthampara",
          "Ashramam Road",
          "Opposite CRPF camp",
          "Devaswom Board Junction",
          "Kadinamkulam Lake--Perumathura",
          "Puthenthope",
          "Thiruvananthapuram Zoo",
          "Lecole Chembaka",
          "Thirumala-Valiyavila Paddyfields",
          "Nettayam Moonnammoodu Road",
          "Govt VHSS For Girls, Manacaud",
          "Poovar--Beach (Pozhikkara side)",
          "Kovalam (Grove) Beach",
          "Perumathura Beach",
          "Kadinamkulam Lake-- Perunguzhi",
          "Sasthamangalam",
          "Kaithappacha",
          "Chungankadavu",
          "Kappil Beach",
          "Kovalam",
          "Vizhinjam Bus Stand",
          "Regional Ayurveda Research Institute For Lifestyle Related Disorders",
          "Rock Cut Cave Temple",
          "Thaannimoodu",
          "Institute Of Animal Health And Veterinary Biologicals - Palode",
          "Christ Nagar International School, Kazhakuttam",
          "Neyyar WLS--Varyattumudi",
          "ICAR-Central Tuber Crops Research Institute",
          "Sainik School Kazhakootam",
          "Ponmudi Hills",
          "Pakalkury Mahavishnu Temple",
          "Arabian Sea: 8.4N 76.9E",
          "Poovar--Backwater",
          "State Public Health Laboratory",
          "IISER Thiruvananthapuram",
          "Government Paddy Field, Kesavadasapuam",
          "Puthenthope Beach",
          "Adimalathura Beach",
          "Poojapura",
          "Mahatma Gandhi College, Thiruvananthapuram",
          "Neyyar WLS--Athirumala",
          "Vazhuvanthol",
          "Elamparakode Temple",
          "Kachani Ayiravalli",
          "Konni",
          "Kochuveli Beach",
          "VSSC Guest House Veli Kadinamkulam Road, Thiruvananthapuram",
          "Vayanashala, Thiruvallam",
          "NCESS Akkulam",
          "Neyyar Dam",
          "Sree Gokulam Medical College & Research Foundation",
          "Anchuthengu Fort",
          "Akkulam Lake",
          "Vellayani--Punchakkari Wetlands",
          "Chemmankala",
          "kaithappacha",
          "Arabian Sea: 8.4N 76.8E",
          "Nellikkunnu",
          "Government College Kariavattom",
          "Loyola School",
          "Club Mahindra, Poovar",
          "Kaniyapuram",
          "Arappuramukku",
          "Varkala Railway Station",
          "Elamparakode Mala",
          "Anappara, Vazhayanki, Near Kallar",
          "Thempamoodu",
          "College of Engineering Trivandrum",
          "Valiyathura Beach,Thiruvananthapuram",
          "Valayanki",
          "Kadinamkulam Lake--Channankara",
          "Thirupalkadal Sree Krishna Swami Temple",
          "All Saints College Campus",
          "Venpakal--Pond",
          "Kannamoola",
          "Upper Sanatorium, Ponmudi",
          "Kalipara",
          "Government Medical College",
          "Kulamada",
          "Arabian Sea: 8.6N 76.6E",
          "Varkala Beach and Clifftop",
          "State Forest Training Institute Road, Arippa",
          "Sree Ulakudayaperumal Kshetram, Kesari Nagar",
          "Kadinamkulam Lake--Azhoor",
          "Railway Colony, Thampanoor",
          "Bhagavathinada-Peringamala, Vizhinjam",
          "Peppara WLS--Bonaccord Picket",
          "Marappalam-- Canal Bund Road",
          "Aruvikkara Reservoir",
          "Technopark",
          "Braemore Estate",
          "Kochuveli Railway Station",
          "KAU Cropping System Research Center, Nedumcadu",
          "NSS College Karamana",
          "Neyyar WLS--Nariyappu",
          "Peppara WLS--Pandipath",
          "Sacred Groove--Manaccaud Durga Temple",
          "Pulluvila Beach and Estuary",
          "Perumkulam Pond",
          "Pallippuram Paddyfields",
          "State Central Library",
          "Madavoorpara Ancient Rock Cut Shiva Temple",
          "Manchadininnavila",
          "Kerala University of Digital Sciences, Innovation and Technology",
          "Kanjiramkulam",
          "Keezhattingal",
          "University of Kerala-- Kariavattom Campus",
          "The Oxford School Trivandrum",
          "Poovar--Mangroves",
          "Arabian Sea: 8.6N 76.7E",
          "Bonaccord Tea Estate",
          "Peppara Reservoir",
          "Shanghumugham Beach",
          "Palayam",
        ],
      },
      {
        district: "Wayanad",
        localities: [
          "North Wayanad FD--Periya",
          "Wayand South Forest Division--Puthoorvayal - Manikkunnumala",
          "Wayand North Forest Division--Vallakkode Campshed",
          "North Wayanad FD--Dasankatte (Tholpetty Range)",
          "Tholpetty",
          "North Wayanad FD--Valanthode",
          "Tholpetty WLS--Ayyappanpara",
          "Wayand South Forest Division--Kuricharmala IB - Hilltop",
          "Karapuzha Earth Dam",
          "Wayand South Forest Division--Karuvam thodu towrds Vannathimala",
          "Wayanad WLS--Kaimaram",
          "Edakkal Caves",
          "Wayanad WLS--Doddady",
          "Wayanad WLS--Odappallam",
          "Wayanad WLS Punchavayal - Chambaalam",
          "Panamaram",
          "Punchavayal",
          "Wayand South Forest Division--Mallika Towards Mookkan puzha",
          "Padinjarethara",
          "SCHSS Payyampally",
          "Vemom",
          "Wayand North Forest Division--Chandanathode - Pine Plantation",
          "Banasura Hill Resort",
          "Wayand North Forest Division--Neikkuppa",
          "North Wayanad FD--Kunnam-Kungichira Route",
          "Agustian's Villa,n Periya",
          "Coffee Acres Resort, Kalpetta",
          "Karapuzha Nellarachal",
          "117,25,1",
          "Wayanad WLS--Kuppadi",
          "South Wayanad FD--Parappanpara (Vaduvanchal)",
          "Wayanad WLS--Ottipara",
          "Moolahalla",
          "Wayanad WLS--Gumshi Vayal, Kurichyad Range",
          "Vythiri Village Resort",
          "Pulpally",
          "Wayanad WLS--Nallathanny",
          "Wayand South Forest Division--Kombian",
          "Kottathara",
          "KALPETTA",
          "Wayanad WLS--Kurichyat - Towards Doddakkulassi",
          "Payyampally, kuruva road.",
          "North Wayanad FD--Kurusumala",
          "Wayanad WLS--Dasanaghatta",
          "Wayanad WLS--Ottippara - Kaithallam",
          "Wayanad WLS--Muthanga towards Nalloorvayal",
          "North Wayanad FD--Churuli",
          "Sultan Bathery",
          "Vythiri Tea Garden Retreat",
          "North Wayanad FD--Boys Town",
          "Wayanad WLS--Kallumukku",
          "Panamaram Heronry",
          "Wayand North Forest Division--Chandanathode MPCA",
          "Government Engineering College Wayanad",
          "Kaniyambetta Site: Kaniyambetta Town",
          "vaduvanchal",
          "Wayanad WLS (OBI)",
          "Panamaram- Panamaram-nadavayal road",
          "Wayand WLS--Doddakolussy",
          "Wayanad WLS--Sulthan Bathery Range",
          "Wayand South Forest Division--Junglepark EFL area",
          "118,25,6",
          "Hanumanmala",
          "Kattikkulam",
          "Wayand South Forest Division--Nellimalam - Manikkunnumala",
          "Thalappuzha",
          "North Wayanad RF--Begur (Tholpetty range)",
          "North Wayanad FD--Tholpetty",
          "South Wayanad FD--Kurichiyarmala",
          "College of Veterinary and Animal Sciences, Pookode",
          "Kuruva Island",
          "Wayanad WLS--Punchavayal - Begur",
          "117,24,9",
          "Muttil (OBI)",
          "Pookkode Lake",
          "South Wayanad FD--Manikunnumala",
          "Kuruva Island Resort & Spa",
          "SKMJ School--Vanadevatha Trail",
          "Wayanad WLS--Dasanghatta-Undichira",
          "Wayand South Forest Division--900 Acre - Aranmala",
          "Wayanad Wild",
          "North Wayanad FD--Kuttanad Estate (Makkiad)",
          "Thennal jungle camp",
          "DM WIMS, Arapetta",
          "Wayanad WLS--Doddakolassi",
          "Karapuzha Reservoir--Nathamkuni",
          "Wayanad WLS--Mangalamkunnu",
          "Centre for Wildlife Studies",
          "North Wayanad FD--Chandanathodu",
          "Wayanad WLS--Ottippara",
          "Wayanad WLS--Muthanga",
          "South Wayanad FD--Padiri",
          "Thollayiram Estate",
          "Meenangadi canal road",
          "Tholpetty WLS--Bavali Station",
          "Banasura Sagar Reservoir",
          "Wayanad Atlas-107,17,2",
          "Anjukunnu",
          "Wayand South Forest Division--Chembra Watch tower towards Akkaalippallam",
          "Wayand South Forest Division--Chembra Watch Tower - Lake",
          "Wayanad WLS--Towards Thenkuzhi",
          "North Wayanad RF--Tirunelly-Pakshipathalam Route",
          "South Wayanad FD--Lady Smith",
          "Olivumala Ayisha Plantation Bungalow, Wayanad County, Kerala, IN",
          "Wayanad WLS--Border - Nalloorvayal",
          "Thollaryiram kandi trail",
          "Chembra Peak",
          "Forest Watch Base Camp",
          "South Wayanad FD--Vellarimala",
          "118,23,2",
          "WSSS Mananthavady",
          "Kalloor (OBI)",
          "M S Swaminathan Botanical Garden",
          "Sarvodaya HSS Echome",
          "Fringeford resort",
          "waynard windflower resort",
          "Wayanad WLS--Kurichithodu - Mangalam kunnu",
          "Gurukula Botanical Sanctuary",
          "Kenichira",
          "North Wayanad FD--Valanthode Checkpost",
          "Wayanad WLS--Sulthan Bathery",
          "Pozhuthana",
          "Thrissilery",
          "choottakadavu",
          "Wayand North Forest Division--Thirunelli Watchtower towards Pakshipathalam",
          "Kottathara Site: Hamsakavala-Kottathara Road",
          "Govt. College, Mananthavady",
          "Wayanad WLS--Kurichyat",
          "Karapuzha",
          "Wayanad WLS--Tholpetty Range",
          "South Wayanad FD--Kuttiyamvayal",
          "Greenex Farms, Chundale",
          "Nadavayal",
          "Wayand North Forest Division--Chirakkara tea factory",
          "Forest Watch base camp Ayilamoola",
          "Wayanad WLS--Mudumala - Nalloorvayal",
          "Wayand South Forest Division--Mallika - Trans Asia Estate",
          "Vythiri, Wynaad, Kerala",
          "Wayand North Forest Division--Kunkichira - Towards Vilangad",
          "Payyampalli",
          "Hume Centre for Ecology and Wildlife Biology, Kalpetta",
          "Wayanad WLS--Ottippara - Ethanamudi",
          "Thrikkaipatta-Patch Monitoring Site",
          "Wayand South Forest Division--900 Acre - Nirupama",
          "Wayand South Forest Division--Westland - Kaithakkolli",
          "Wayanad WLS--Alathoor Jn - Kurichyat",
          "North Wayanad FD--Kambamala",
          "North Wayanad FD--Thrishilery",
          "Meppadi",
          "Wayand North Forest Division--Kuruva Island",
          "North Wayanad FD--Kunhome-Churuli Track",
          "Naikatty, Sulthan Bathery (OBI)",
          "Valliyoorkavu-Kabini River Bank",
          "Morickap Resort",
          "Wayanad WLS--Chethalayam",
          "Uruva,trikkaipetta",
          "South Wayanad--Banasura Peak",
          "Wayand South Forest Division--Kurichyarmala IB - Pond",
          "Lantern Stay Meppadi (OBI)",
          "Thalappuzha Poyil",
          "ambalavayal bypass road",
          "Wayand North Forest Division--Edakkolli - Sooryamudi",
          "Wayand North Forest Division--Churuli",
          "Wayanad WLS--General Area",
          "Wayand South Forest Division--8th mile Peedikappullu",
          "Wayand South Forest Division--Uthiranchery - Elikkal",
          "Wayand North Forest Division--Makkimala",
          "Thirunelli",
          "Wayanad WLS--Begur",
          "South Wayanad FD--Naikuppa",
          "Kunnamangalam Vayal",
          "Waynad WLS--Begur Forest Station",
          "Oorppally",
          "Panavally",
          "Wayand South Forest Division--Junglepark EFL - Towards Pannippara",
          "Wayand North Forest Division--Thirunelly - Theerthakkundu",
          "Kanthanpaara Waterfalls",
          "Kaniyaram",
          "Wayand South Forest Division--Amba",
          "Kalpetta",
          "Kalladi (OBI)",
          "South Wayanad FD--Thollayiram",
          "Arattuthara Paddyfield",
          "Noolppuzha - Kerala Border",
          "Wayand South Forest Division--Soochippara - Thadaakam",
          "Wayanad WLS--Ottippara - Manjaal vayal",
          "Wayanad WLS--Tholpetty",
        ],
      },
      {
        district: "Ernakulam",
        localities: [
          "Paniyeli Poru--Panamkuzhi Kadav",
          "Perumbavoor--Town Area",
          "Marampally--MES College",
          "IMA House, Palarivattom, Kochi",
          "Edapally--AIMS",
          "Kochi--Cheranalloor(Blayikadavu Rd)",
          "Varapuzha--Market",
          "Mattummal",
          "Edamalayar Range--Thalippara-Villukuthi-Kappadan [SubCell No.65,27,5]",
          "Panangad--Madavana",
          "Vengola",
          "Sub Cell No 64,22,6(Thundathil Range)",
          "Bhoothathankettu--Reservoir",
          "Pathaalam--Kachappilly Farm Field",
          "SSUS Campus, Kalady",
          "Aluva--Rly. Station Area",
          "Aluva--Manappuram",
          "Maradu--Boat Jetty",
          "Kanayannoor--Vattukkunnu",
          "Kalady--Okkal Thuruthu",
          "Sub Cell No 64,23,4(Thundathil Range)",
          "Adooparambu--[Sub Cell-56,16,2]",
          "Thevakkal",
          "Idamalayar--Idamalayar Rd.",
          "Kalamassery--Peringazha Area",
          "Thattekad--near Hornbill Camp",
          "Mudavoor(Sub Cell-57,14,2)",
          "Manjalloor-Maniyanthiram Hiltop",
          "Tripunithura--Kidangu Rd(Valia Kadu)",
          "Ayyampuzha--Uppukallu Jn.",
          "Thattekad--CIAL Signal Station",
          "North Paravur--Ezhikkara bridge",
          "Thattekkad Bird Sanctuary--General Area",
          "Kurbana Para(10.158, 76.597)",
          "Thattekad--Velielchal",
          "Kothamangalam--Municipal Park",
          "Kochi--Thoppumpady(Chemmeens)",
          "Chellanam--Fishing Harbour",
          "Kuttampuzha Range--Eettacholayar SubCell (62,25,3)",
          "Kalamboor [Sub Cell-52,8,5]",
          "Kakkanad--Infopark Restricted Access",
          "Aluva--Private Bus Stand",
          "Kochi--Pullepady",
          "Fort Kochi--Beach Area",
          "Nedumbassery Wetlands--Opposite CIAL TradeFair and Exhibition Centre",
          "Kakkanadu--Municipal Ground",
          "Thattekad--Soma Birds Lagoon",
          "Mangalavanam Bird Sanctuary",
          "Aikaranad South--Kakkattupara(Subcell 55,11,8)",
          "Panangad--SN Jn.(Subcell 53,7,5)",
          "Asamannoor--Cherukunnam(Sub Cell 60,14,2)",
          "Kakkanad--Recca valley",
          "Kanayannoor-- Eruveli",
          "Sub Cell No 64,21,7(Thundathil Range)",
          "Irumalappady(Sub Cell 59,15,5)",
          "Thripunithura--Pavamkulanagara",
          "Aluva--Muppathadam--Megalaya Rd",
          "Aluva--Edayapuram",
          "Ernamulam--Near Kottayilkovilakam Rd",
          "Vypin--Pallathamkulangara",
          "Kundannoor--Willingdon Island road",
          "Pooyamkutty--Bridge",
          "Thoongali [Sub Cell-61,14,7]",
          "Inchathotty--Forest Cottage",
          "Alangadu 33kv Substation Area",
          "Puthuvype--Beach",
          "Nadukani",
          "Pooyamkutty--Forest Check Post",
          "Kuttampuzha - Mamalakandom Road, Urulanthanni",
          "Kodanadu--Kaprikad",
          "Mamalakkandam",
          "Neriamangalam--Chembankuzhy(Painavu Rd)",
          "Chottanikkara--Atiyakapadam",
          "Malayattur Division--Kappayam",
          "Puthuvype--LNG Terminal Area",
          "Karicode--Karicode Paadam[Subcell 53,9,9]",
          "SubCell (65,17,4)-Kallala Estate 6th Block",
          "Malayattoor--Perumthode Forest Station Area",
          "Arabian Sea: 10.0N 76.1E",
          "Ernakulam--Cherai Beach",
          "Kakkanad--Rajagiri Valley",
          "Thattekad--Punnekkad",
          "Thoppikkallu- Pattaampilly Forest Road",
          "Malayattur Division--Bhoothathankettu",
          "Kochi--Vaduthala (JM Spot)",
          "Pandrimala Rd",
          "Mulanthuruthy--Koramkottu Temple Karinilam",
          "Kunjattukara--Near Vettakkorumakan Temple",
          "Malayattor RF--[Sub Cell 63,15,4]",
          "Moongakunnu",
          "Kalady--Adi Shankara Janmabhoomi Ashram",
          "North Paravur--Santhivanam",
          "Aluva--Kadungalloor(Ambady Green Meadows area)",
          "Eravikulam NP--Kolukkan",
          "Muvattupuzha--Eattappilly(Mookambika Technical Campus)",
          "Boothethankettu--Old dam path",
          "Kochi--NH47(near Lakeshore Hospital)",
          "Malayattur Division--Perinthodu",
          "Kochi--Cherai Beach",
          "Kothamangalam--Elambra ByePass",
          "Thattekad Bird Sanctuary--Urulanthanni",
          "Angamaly--Rly. Stn",
          "Eloor--Udyogamandal(FACT)",
          "Mamala--Kakkad General Area",
          "Chalakkudy--Ezhattumugham",
          "Palamattam(Sub Cell-60,19,6)",
          "Mulanthuruthy--Pallithazham(Chottanikkara Rd)",
          "Thengod--D D Diamond Valley",
          "Kallekandam--Ayyampuzha",
          "Bosco Road End View Point",
          "Chelethe Chera--Kolengad Padam(Sub Cell-53,12,9)",
          "Malayidomthuruth--(Pukkattupady-Perumbavoor Rd)",
          "Kothamangalam--Malayinkeezhu (OBI)",
          "Kolenchery",
          "Pallikkara--Kumarapuram(Sub Cell-57,9,9)",
          "Arabian Sea: 9.9N 76.2E",
          "Kottapady - Plamudy - Thodakayam Road, Kannakada, Kerala, IN (10.153, 76.6)",
          "Chambakkara Fish Market(Subcell 55.7.5)",
          "Aluva--Karumalloor",
          "Illithode(Sub Cell-63,13,5)",
          "Rajagiri Valley",
          "Kalamassery--HMT Estate Area",
          "Iringole Kavu",
          "Arabian Sea: 10.0N 76.0E",
          "Kadamakudy--Valiya Kadamakkudy",
          "Malayattur Division--Koompanpara(SubCell 61,24,6)",
          "Thuthiyoor(Sub Cell-56,7,6)",
          "Kalluvettamada-Elanji Rd [Sub Cell-52,9,5]",
          "Vypin-Fort Kochi Junkar service",
          "Sub Cell No 64,19,7(Thundathil Range)",
          "Nedumbassery--Ebenezer Plaza Area",
          "Nedumbassery--Suvarnodyanam",
          "Kochi--Njarakkal (Matsyafed Fish farm)",
          "Karukutti--Palissery",
          "Thattekkad--Keerampara",
          "Arabian Sea: 9.9N 75.9E",
          "Kalappara--Near Eldhose birding lodge",
          "Allungal",
          "Eloor--Forest Depot",
          "Neriamangalam(Sub Cell- 58,21,7)",
          "Vengoor West--Anakallu [Sub Cell-61,13,6]",
          "Memmury--(SubCell 54,13,4)",
          "Arabian Sea: 9.9N 76.1E",
          "Thiruvaniyoor--Varikoli Leprosy Hospital",
          "Kadayiruppu--Spice Villas",
          "Malayattur Division--Malakkappara",
          "Kanayannoor--Ayyappa Temple Rd",
          "Malayattur Division--Karimpani",
          "Ambalamugal--Kodinjur Temple( Subcell -55,9,8)",
          "Inchathotty Suspension Bridge",
          "Kodavakad--Kottuvally South",
          "Thekkummala--Piralimattom",
          "Ayavana",
          "Malayattur Division--Ponganchodu",
          "Kochi--Elamakkara(Puthukkalavattom Rd-Canal area)",
          "Udayamperoor",
          "Hill Palace Museum Area",
          "Manjapra--Puthoorppilly Sreekrishnaswamy Temple Area",
          "Sub Cell No 65,22,3(Thundathil Range)",
          "Adivad-Oonnukal Rd",
          "St. Peter's College, Kolanchery",
          "Kuzhuppilly Beach",
          "Alapuram--Elanji-Monippally Rd [51,10,3]",
          "Puliyanam-- Painkavu",
          "Aluva--Panayikulam(grass field)",
          "URULANTHANNI--Knachary Sree Durga Temple",
          "Kadayirippu--Thonikka Paddy Feilds",
          "Kannamali(near Police Station)",
          "Thattekkad--Bird Songs Homestay",
          "Kombanad [Sub Cell-62,14,6]",
          "Varapuzha",
          "Cochin University, Kalamassery",
          "Nayarambalam--Nedungad Wetlands",
          "Kalady--Sandflats",
          "Idamalayar--Dam",
          "Kochi--ICT Rd(Mulavukad)",
          "St.Xaviers College for Women, Aluva",
          "Kottappady--Moonnamthodu",
          "Kuttampuzha Forest Range--Eettacholayar SubCell  [62,25,3]",
          "Ponjikkara",
          "Vatattupara--Ennakkal Forest Station Area(10.184, 76.721)",
          "Kochirika(Sub Cell-60,6,4)",
          "Thiruvankulam--Kavaleeswaram (Paddy Field)",
          "Perumpallor--Peringuzha(Sub Cell-55,16,4)",
          "Memmukhom--Vettikkal Piravom Rd.(Subcell 53,11,8)",
          "Thattekad--Tapovan Green County Resorts",
          "Eloor-- Pathalam(Regulator Bridge)",
          "Dr. Salim Ali Bird Sanctuary",
          "Thiruvaniyur--Global Public School Area",
          "Malayattur Division--Therakudi",
          "Rajagiri Hospital area--[Sub Cell-59,9,8]",
          "mattummal",
          "Malayattur Division--Kunchiyar",
          "Kuzhuppilly Beach--Pallathankulangara Beach Road",
          "Ayroor--Attupuram(Link Rd)",
          "Adichilthotty Tribal Colony",
          "Eroor--Ansal Riverdale",
          "Arabian Sea: 10.1N 76.1E",
          "Varapuzha--Devaswompadam Fish Landing",
          "Ezhikkara(Sub Cell-60,4,2)",
          "Pattimattom--Chottupara[Canal Rd-Sub Cell-57,11,8]",
          "Aluva--Veliyathunadu",
          "Thirumarayoor [Sub Cell-52,7,5]",
          "Eranakulam--South Rly. Station Area",
          "Pallikkara--Vempilly[Sub Cell-57,10,7]",
          "Fort Kochi--Tower Rd(near Koder House)",
          "Pattoorkunnu",
          "Pallippuram--Kovilakathumkadavu area",
          "Manjally--Madhurappuram Wetlands",
          "Paingottoor",
          "Kochi--Mattancherry Palace Area",
          "Thettali",
          "Birds Murmur Camp - Thattekkad",
          "Thripunithura--Thattapillikattupuzha Bridge",
          "Malayattoor--Arattukadavu",
          "Thoppikkallu-Itinjakuthu- Kaanjiravalavu (SubCell 63,20,6)",
          "Fort Kochi--Ferry Area",
          "FACT--Ambalamedu Lake(restricted access)",
          "Kochi--South Chittoor(Near Aster Medicity)",
          "Malayattoor RF--Edekallana(SubCell 63,16,9)",
          "Koonammavu--Kavilnada",
          "Chalakka-Kuthiathode Rd--Pandiyolichal(wetland)",
          "Nellikuzhi(Sub Cell-59,16,4)",
          "Kuttampuzha Forest Range--Eettacholayar Area[SubCell-62,25,3]",
          "Kochupurackal Kadavu[Sub Cell-62,15,8]",
          "Paniyeli Poru",
          "Chelad--St.Gregorios Dental College(near)",
          "Aluva--Keezhmadu (Thulapadam)",
          "Kangarappady--Krishibhavan Rd.",
          "Vazhachal Malkkapara Rd Subcell : 65,26,7",
          "Kanjiramattom--Thundilangadi",
          "Panichayam[Sub Cell-60,15,4]",
          "Kochi--Eranakulam Market(Basin Rd)",
          "Kalamasserry--Palakkamugal",
          "Malayattur Division--Variyam",
          "Kalappara--Eldhose Birding Lodge(Restricted Access)",
          "Kizhakombu [Sub Cell-52,11,4]",
          "Purva Eternity",
          "Malayattoor--General Area",
          "Nedumbassery--Angamali-Airport Road",
          "Palluruthy--Chirakal",
          "Mazhuvannoor--Kadakkanad Paddies",
          "Malavana(Sub Cell-62,5,4)",
          "Ernakulam Town (North) Railway Station",
          "Kalady Manjapra Road--Kuzhiampadam",
          "Kuthirakoor Kari--Pandarakari",
          "Tripunithura--Irimpanam",
          "Nadettuchaal--Charupara",
          "Nedumbassery--CIAL Area",
          "Kochi--Chilavannur",
          "Kallil Bhagavathi Temple, Methala",
          "Ooramana--Puthukol Rd",
          "Dr.Salim Ali Bird Sanctuary--Kallippara",
          "Thengod--[Sub Cell-58,8,3]",
          "Vazhachal - Malkkapara Rd",
          "Varapuzha--Meen Thodu(Sub Cell-59,5,7)",
          "Kuthirakoor Kari",
          "Veliyathunadu--Thadikkakadavu Jn.",
          "Brahmapuram--Chellipadam Fields",
          "Manikantanchal Area [SubCell 62,22,2]",
          "Chembarakky(Sub Cell--59,10,9)",
          "Aluva--St. Mary's School",
          "Aluva--Thaikatukara(Punja Paadam)",
          "Ayyampuzha--Prakruthi Gramam",
          "Perumpadavam--[Sub Cell-51,9,9]",
          "Kalamassery--CUSAT Campus",
          "Chowara--Neduvannoor(Railway Gate)",
          "Ernakulam--Queen's Walkway",
          "Kulangarapadi [Sub Cell-52,10,7]",
          "Aluva--Manakkappdy(area)",
          "Arabian Sea: 10.0N 75.9E",
          "Nedumbassery--Maikave",
          'Kakkanad--Infopark "Restricted Access"',
          "Ambunadu--Chemmalappady[Sub Cell- 58,10,7]",
          "Puthuvype--Mangroves",
          "Padamugal--DLF New Town Heights",
          "Cheramala",
          "Arabian Sea: 9.9N 75.8E",
          "Aluva--West Kadungalloor",
          "Koovappara--SubCell (62,21,4)",
          "Ayyampuzha--Ezhattumugam Forest Station",
          "Ramamangalam--Valloor-Srikrishna Temple(Subcell 55,13,1)",
          "Ezhattumugham--Malayattoor Rd.(Oil Palm Plantation)",
          "Veliyathunadu[Sub Cell-61,7,4]",
          "Arabian Sea: 10.1N 76.0E",
          "Malayattur Division--Eramukom",
          "Arakuzha--Meenkunnam Rd [Sub Cell-54,15,9]",
          "Kalamassery--HMT Colony(Mattakadu)",
          "Avolichal(Sub Cell-59,20,6)",
          "Vaduthala--Pushpaka Rd",
          "Maneed--Piravom Rd.",
          "Anicadu",
          "Malayattoor--Aarattukadavu",
          "Malayattur Division--Idamalayar Reserve Forest",
          "Pindimana(Sub Cell- 60,17,4)",
          "Thripunithura--Maradu(Champakkara Canal banks)",
          "Aluva--Elookkara(Madirath Padam)",
          "Pizhala",
          "Kureekkad",
          "Pooyamkutty--Kuttianchal Forest Station",
          "Sub Cell No 64,20,4(Thundathil Range)",
          "South Kalamassery--Kudili Rd(area)",
          "Athirappilly--Water Falls",
          "Kadamakudy--Thundathumkadavu",
          "Aluva--Neericode",
          "Idukki--Edamalakudy",
          "Kadayiruppu-- Mazhuvannoor",
          "Edavanakkad--Aniyal Beach Road",
          "Thoppikkallu-Itinjakuthu- Kaanjiravalavu Area [SubCell 63,20,6]",
          "Edakochi--Pampayimoola",
          "Kottappady--Moonnamthode(10.159, 76.598)",
          "Kuttampuzha Forest Range--Eettacholayar area[SubCell 62,25,3]",
          "Thevara--SH College Area",
          "Kadambrayar(Sub Cell-58,9,2)",
          "Mudikkal",
          "Perumpadappu--Kollassery area",
          "Muvattupuzha--Karukadom",
          "Moonnamthodu--[Sub Cell-61,15,3]",
          "Chelamattom Heronry",
          "ICT Road--Mulavukadu Area",
          "Kochi--Kandakkadavu Wetland",
          "Thattekad--Kalappara",
          "Maradu--Thamarassery Rd",
          "Mundamveli--Thonithodu Bridge",
          "Fort Kochi--Pattalam (OBI)",
          "Kochi--Marine Drive Walkway",
          "Angamaly--Kidangoor",
          "Edamalayar--Vadattupara Waterfalls",
          "Kanayannoor--Vattakkunnu",
          "Chottanikkara",
          "Kakkanad -- Chittethukara Wetlands",
          "Angamaly--Mookkannoor",
          "Mulanthuruthy",
          "Malayattor--Manappattu Chira",
          "Thrippunithura--Eroor South",
          "Malayattoor--MahagonyThottam(Eco Tourism Area)",
          "Vypin beach road",
          "Piravom East [Sub Cell-53,13,2]",
          "Kummanodu-East(Sub Cell--58,11,3)",
          "Eravikulam NP -- Eravikulam Hut",
          "Moozhikkulam--Jaiva Eco Camp",
          "Arabian Sea: 10.0N 76.2E",
          "Kalappara(Sub Cell-60,18,8)",
          "Thrikkariyoor(Sub Cell-60,16,2)",
          "Kothamangalam--Mathirappally(Diana Villa)",
          "Arabian Sea: 9.9N 76.0E",
          "Anakkayam",
          "Memury--Kayanadu",
          "Kadambrayar Wetlands",
          "Kalamaserry Polytechnic Area",
          "Kadayiruppu--Indranchira",
          "Manjummel--Janatha Rd",
          "Subhash Bose Park",
          "Panambukad(Sub Cell-57,4,3)",
          "Thundathil Range--Bhoothathan-Karimpani  Rd [SubCell 62,17,6]",
          "Eloor--Vadakumbhagam[Sub Cell-59,6,7]",
          "Chowara--Rly.  Stn",
          "Vazhachal - Malkkapara Rd.",
          "Perumbavoor--Valyanchirangara",
          "Kunnukara--Vayalkara",
          "Idamalayar--Reservoir",
          "Aluva--Edayapuram(West)",
          "Ambunadu--Thaqdees Hospital",
        ],
      },
      {
        district: "Malappuram",
        localities: [
          "Velimukku Chali",
          "Kole Wetlands--Maranchery",
          "Wandoor--Ambalappadi",
          "Nilambur--Chaliyar Mukku",
          "Kakkadampoyil--Thottappally",
          "Melekkavu",
          "Kadakkattu Para",
          "Valanchery",
          "KCAET Tavanur campus - (10.855, 75.986)",
          "Nilambur North--Nilambur",
          "Tirur Railway Station",
          "Venkulam Water bodies",
          "Thenhipalam",
          "Myladi",
          "Pulpatta, Karaparamba",
          "Valarad",
          "Off Chavakkad",
          "Vazhoorhills,Vazhayur, Kerala",
          "Thazhamon",
          "Thirumandhamkunnu Temple compound",
          "Vazhikkadavu",
          "Murukanjiram",
          "Arabian Sea: 10.8N 75.8E",
          "Thiruvaly",
          "Chekanoor",
          "Nilambur North--Olimala",
          "Kole Wetlands--Beeyam Kayal",
          "Nilambur North--Aadyanpaara",
          "Muthrukunnu Temple",
          "Wandoor--Bus stand",
          "Thunchan parambu",
          "Karulai cell",
          "Thiruvonamala",
          "Iringavoor",
          "Atlas cell [82,5,2]",
          "Arabian Sea: 10.7N 75.8E",
          "Nilambur North--Bhoodanam",
          "Perunthallur",
          "Nilambur South--Paattakkarimpu -  Saivila",
          "Tanalur",
          "Karma road, Ponnani, Malappuram",
          "MES Mampad College Campus",
          "Palakkal",
          "Ambalapparambu Jn. - Valanchery",
          "Paddy field near MES Mampad College Campus.",
          "Nilambur South--Panapuzha",
          "Nilambur North--Meenmutti",
          "Pallipadi [94,13,2]",
          "Ganapathikallu, Nadukani",
          "Panakkad",
          "Mampad, Nilambur",
          "Edupark Road, Pappinippara, Kerala, IN (11.09, 76.079)",
          "Kavumpuram",
          "Nilambur--Aaravallikkavu",
          "Thrikkannapuram",
          "IN-Kerala-Thenhipalam - 11.136x75.891 - 23 Nov 2016 8:46 pm",
          "Kottoor",
          "Kadalundy Estuary (Kadalundi Bird Sanctuary)",
          "Thurakkal--Airport jn",
          "Nilambur South--T K Colony",
          "Parichappilly temple Road",
          "Kondotty--Moinkutty Vaidyar Smaraka Mappila Kala Padana Kendram",
          "Nilambur South--Pulimunda",
          "Kizhakkethala",
          "Vallikkunnu beach",
          "Elankur-Shappinkunnu Rd [94,14,4]",
          "Arimbrakuth Forest",
          "Melattur--Police Station",
          "Mukkodu, Karipur.",
          "Nilambur--Conolly Plot",
          "Pullippadam",
          "Kuttippuram--Railway Station",
          "Kakkadampoyil--Nilambur Bypass Forest Patch",
          "Akkachola Road [93,13,1]",
          "Nilambur North--Nadukani",
          "Naduvath",
          "Neerolppalam",
          "Kakkadampoyil--Pazhassi Cave",
          "Neerolpalam",
          "Manjeri",
          "PSMO College Backyard",
          "Puthuparambu",
          "Thekkinkode, Angadippuram",
          "Maravattam [88,10,2]",
          "Angadipuram",
          "Aripra [89,14,2]",
          "Klari MSP Camp",
          "Calicut University Campus--Alakapuri",
          "Valiyakunnu",
          "Chembancolly",
          "Thalappara",
          "Calicut University campus Botanical Garden",
          "Nilambur North--Nellikuth",
          "Nilambur North--Palakkadavu",
          "Nilambur North Division--Vazhikadavu",
          "Nilambur--Green Valley Road",
          "Calicut University Campus",
          "Kalpalli",
          "Mini Ooty",
          "Nedumkayam--Elephant Kraal",
          "Nadakkavu",
          "Palad",
          "Valluvambram",
          "Mannarmala",
          "Nilambur--DFO's Bungalow",
          "Parappanangadi Heronry",
          "Vilayil",
          "Anakkayam Agricultural Research Station",
          "Cherukara",
          "Vazhakkad",
          "Nilambur South--Kariri",
          "Ottumpuram Estuary",
          "Manikkinar",
          "Vallikkunnu [92,3,3]",
          "Nilambur South--Singakkallu",
          "Vattamkulam [82.6.1]",
          "Nellissery [81.5.6]",
          "Pullara",
          "Panayi",
          "Arabian Sea: 11.0N 75.7E",
          "Nilambur North--Vellarampuzha",
          "Nilambur--Teak Museum & KFRI",
          "Kuttippuram--JTS",
          "Randathani",
          "Veliyankode Estuary",
          "Villoonniyal",
          "Nilambur North--Punjakolly",
          "Puthanathani - Athirumada",
          "Chalippadam",
          "Bungalow kunnu",
          "Kodinhi",
          "Thavanur--Pappinikavu",
          "Nilambur--Kulakandom",
          "Kettungal Beach",
          "Amarambalam",
          "Chamravattom Bridge",
          "Panampad",
          "Nilambur",
          "Nilambur North--Olimpara towards Chempumkallu",
          "Karulai",
          "Al Shifa College",
          "Nilambur South--Nedumkayam IB-Towards Kannankara",
          "Kelappaji College of Agricultural Engineering and Technology",
          "Alathiyoor Sri Hanuman Swamy Temple",
          "Tanur beach",
          "Melattur",
          "Kanchippura [86,10,4]",
          "Nilayoram--Nila River",
          "Kuzhimanna",
          "Valamboor",
          "PSMO College",
          "Arabian Sea: 10.8N 75.7E",
          "Nilambur North Division--Munderi",
          "Pang chendi [88,12,1]",
          "Chembra",
          "Mookuthala Temple",
          "Maranchery [80,3,6]",
          "Kodasseri",
          "Kavathikkulam Padasekharam",
          "Azhinjilam Wetlands",
          "Nilambur North--Iruttukuthy",
          "Kalpuzha--Pacharipadam",
          "Arabian Sea: 11.1N 75.8E",
          "Kandanakam",
          "Panampad Kole",
          "Mampattumoola",
          "Ayyappankunnu Temple",
          "Ozhur",
          "Parappuram",
          "Chungathara",
          "Manjeri--Cherani",
          "Palemad",
          "Odakkayam [97,17,5]",
          "Kole Wetland--Kattakambal",
          "Kole Wetland--Cheravalloor",
          "Nilambur North--Vaniyampuzha",
          "Kole Wetlands--Uppungal (Malappuram)",
          "Purathur Estuary",
          "Kalpakancheri",
          "Pakaravoor shiva temple",
          "Vilayil Cell 2",
          "Erumamunda",
          "Thirunavaya Temple",
          "Kakkadampoyil--Vendekumpoil",
          "Kolappuram",
          "Kollikkara",
          "Pachattiri",
          "Kambanikundu",
          "Othayi [96,12,3]",
          "Nilambur South--Atti",
          "Nilambur South--Panappuzha - Panappuzhamukku",
          "Pandikkad--Kizhakke Pandikkad",
          "Alungal",
          "Pang [88,12,1]",
          "Manaliyarkkavu Padam",
          "Kuttippuram--Sandflats",
          "Villathoor, Pathappiriyam",
          "Ayinichira",
          "Thooval Theeram Bridge, Malappuram",
          "Athaloor Paddy Fields",
          "Bharathapuzha Mudflat_Ponnani",
          "Wandoor--Kottammal",
          "Marakkara",
          "Nilambur--AR Camp",
          "Kakkadampoyil--Kozhippara Waterfalls",
          "Kundukadu, Thalakkassery",
          "Silent Valley NP--Sispara towards Bankithapal",
          "Kizhisseri",
          "Elachola Farm",
          "Nilambur--Parakkadavu",
          "Kozhikode International Airport",
          "Varanakkara",
          "Kakkadampoyil",
          "Kottola",
          "Naduvath--Pottippara",
          "Valiya Parappoor Kayal",
          "West Chathalloor",
          "Wandoor--Puliyakkode",
          "Kodumpuzha",
          "Thirunavaya--Bandar kadavu",
          "Nilambur--Puchakuth",
          "Tavanur BSNL Telephone Exchange Back Side",
          "Pandikkad--Bus Stand",
          "Parappanangadi Town",
          "Niramkaithakotta",
          "Nannammukku",
          "Ullanam Paddyfield",
          "Chembankolly",
          "Punchapadam",
          "Konamunda",
          "Ponnani Beach",
          "Atlas cell [82,4,3]",
          "Vadakkangara [92,13,6]",
          "Kurumbalangode",
          "Valenchery, Morayoor",
          "Kodikuthimala",
          "Tripuranthakam Temple",
          "Ambal Mill Road",
          "Nilambur South--Panappuzha towards Kidakkamala",
          "Thattan Thiruthi",
          "Kottappuram",
          "Nilambur South--Nedumkayam",
          "Nilambur--Chaliyar Mukku Atlas Cell",
          "Kakkadampoyil--S valavu",
          "Kondotty",
          "Shanthitheeram Park",
          "Thirunavaya Railway Station",
          "Arabian Sea: 11.0N 75.8E",
          "Oravampuram",
          "Thavanoor",
          "Biyyam Kayal",
          "Pookkarathara",
          "Nilambur South--Vattakkallu",
          "Arabian Sea: 10.8N 75.9E",
          "Mundithodika [92,12,5]",
          "Valiyaparappur Thamara Kayal (Lotus Lake)",
          "Moolepadam",
          "Vendalur",
          "Pattikkad",
          "Naranippuzha Bridge",
          "Mulliakurssi",
          "Kuvakkunnu",
          "Nadugani Churam",
          "Irimbiliyam - Valanchery",
        ],
      },
      {
        district: "Thrissur",
        localities: [
          "C.Achutha Menon Memorial Govt. College",
          "Karakulathunadu Paddy Filed",
          "Mannuthy--General Area",
          "Cherakazha",
          "Ayanthole--General Area",
          "Kole Wetlands--Vallur Padavu",
          "Thrissur Government Medical College",
          "Vazhachal Forest Division--Sholayar View Point",
          "Vettilappara",
          "Kerala Police Academy Campus",
          "Meloor-Pallappilli Road",
          "Kuttumuck-Nellankara Paddy Field",
          "Vellanipacha Trek Path",
          "Vattoli--Attor RF",
          "Kerala Forest Research Institute--General Area",
          "Arattukadavu,Koodappuzha",
          "Chermanangad",
          "Panangadavu-  Ambalappara IB rd",
          "Pushpagiri Agraharam",
          "West Fort Junction",
          "Thumboormuzhy River Garden",
          "Potta",
          "Mathaichira",
          "Thazhaekkaad Kinur Bus Stop",
          "Devi Temple, Pazhayannur",
          "Kizhakkumpattukara--General Area",
          "Killimangalam",
          "Porkulam",
          "Koorikuzhi Beach",
          "Virakuthodu-- Chimmini Dam",
          "Poomala Reservoir",
          "Rice Bazar (Ariyangadi)",
          "Varadiyam",
          "Kotatt Wetlands",
          "Kerala University of Health Sciences (KUHS)",
          "Mala-Vadama Paddy Fields",
          "Vazani Dam",
          "Kattappuram",
          "Jubily Lane--Koratty",
          "Palappally",
          "Vazhachal Forest Division--Sholayar",
          "Chelakkara",
          "kanakamala kurishumudi",
          "Parakkad",
          "Korumbissery Paddy Field",
          "Kole Wetlands--Padiyam",
          "Vadakkumnatha Sree Mahadeva Temple",
          "Mundupalam --Near Sakthan bus station",
          "Pullu Paddy Fields",
          "Vazhachal Forest Division - Pukalappara",
          "Vazhakode Valavu",
          "Raman Chira Temple",
          "Anakkayam Forest Station2",
          "Kole Wetlands--Karanchira Paddy Field",
          "Villadam",
          "Vazhachal Forest Division--Vachumaram",
          "Chimmini WLS--Murikinpuzha",
          "Paddy Field--Vidya Nagar",
          "Kole Wetlands--Perumbuzha Padam (Enamavu--AWC Site)",
          "Chimmony WLS--Poomala",
          "Cheppara--General Area",
          "Vadakkethara",
          "Kole Wetlands--Alapad",
          "Sholayar",
          "Arewsaram Hill",
          "Chavakkad Puthan Kadappuram",
          "Puzhakkal",
          "Moonnumury-Mattathur",
          "Vallakunne Anandhapuram Rd,Muriyade Wetlands",
          "Pazhambalacode",
          "Ariyannur",
          "Panthallur",
          "Parvathi Lane",
          "Cheeni-- Chimmony Dam",
          "Puthan Vettuvazhi",
          "Parekkattukkara--Alatur",
          "Kurumala",
          "Madakkathara",
          "Vadanapally Beach",
          "Kavanaadu--Mattathurkunnu",
          "Chimmony WLS--Murukinpuzha",
          "Christ College",
          "Chazhoor",
          "AXIS college-Thaloopadam",
          "Peechi-Vazhani WLS--Varayanpara",
          "Lakshmi Kulam",
          "Adat Kole Wetlands",
          "Udalakavu Temple--Adat",
          "Pudukkad-Mannampetta Rd",
          "Mattom",
          "Attoor",
          "Mandikunnu",
          "Meloor",
          "Sholayar [66,25,4]",
          "Thiruvilwamala",
          "Varakkara--Behind Church",
          "Kachithodu Checkdam",
          "College of Veterinary and Animal Sciences, Mannuthy",
          "Kerala Agricultural University--Krishi Vignyan Kendra",
          "Kole Wetlands--Mulloorkayal",
          "Peechi WLS--Palakkuzhy",
          "Kole Wetlands Enammavu-II",
          "Kole Wetlands--Thommana",
          "Sholayar RF--Mechappullikuthu Tree Hut",
          "Vadekkekad",
          "Mechira",
          "Kondayur_paddy fields",
          "Kole Wetlands--Marekad",
          "Vazhachal Forest Division--Vazhachal",
          "Kulathur-Anandapuram",
          "Karuvannur -- Rajha co.",
          "Kole Wetlands--Pullu",
          "Poonkody Paddy Filed",
          "Kodungalloor Backwaters",
          "Vallissery",
          "Manalitara-Punnamparambu",
          "Thiruvathra",
          "Pazhul, Kizhakkummuri",
          "Thrissur Railway Station",
          "Kole Wetlands--Kanjani--General Area",
          "Pathazakkundu--General Area",
          "Anapantham APC--Vellikulangara RF",
          "Kuttumukku--General Area",
          "Pallippuram-Chenthuruthi",
          "Charpa Falls",
          "Krishnapuram Village",
          "Kole Wetlands--Perumpuzha Bridge",
          "Paratha",
          "Mannalamkunnu Beach",
          "Sowhrdhatheeram Park",
          "Gourami fish Farm",
          "Zoo and Museum",
          "Kole Wetlands--Panthode",
          "Kole Wetlands--Puzhakal",
          "Konnakuzhy, Chalakudy - Anamala Road",
          "Vazhachal Waterfalls",
          "Thalssery RF",
          "Peechi-Vazhani WLS -- General Area",
          "Kothakulam Beach",
          "Peruvanam Chira",
          "Valapad beach",
          "Sholayar RF--Parappanppara medicinal plantation area",
          "Nelluwaya--Erumapetty",
          "Mudikkode Shiva Temple",
          "Chirakkakode Paddy Field",
          "Chicklayi",
          "Arabian Sea",
          "Thekkinkadu Maidanam",
          "Chenthrapini",
          "Peechi Dam Site",
          "Vaniyampara",
          "Elanad",
          "Narayanamangalam",
          "Pariyaram RF--Vettilappara",
          "Kodunthirapully",
          "Vazhachal RF--Konnakuzhi Plantation Rd",
          "Vazhachal Riparian Forest",
          "Thottippal",
          "Sholayar Power house",
          "Chemmanthatta",
          "Malakkappara Town",
          "Vellarakkad",
          "Mayilattumpara",
          "Mulangue",
          "Ponganamkadu",
          "Sholayar Reservoir Area",
          "Ayroor",
          "Guruvayur--General Area",
          "Chimmony WLS--Mangalamkavu",
          "Peechi WLS--Mampara",
          "Vellukkara [67,13,3]",
          "Chimmony Reservoir",
          "Old Tramway Road--Sholayar RF",
          "Kazhimbram Beach",
          "KSRTC Bus Stand",
          "Kole Wetlands--Valoor",
          "Kole Wetlands--Irimbranallur",
          "Kole Wetlands--Guruvayur",
          "Arabian Sea: 10.1N 75.9E",
          "Vazhani Reservoir",
          "Andathodu",
          "Pachakkad",
          "Kole Wetlands--Manakodi",
          "Munippara",
          "Arabian Sea: 10.1N 75.6E",
          "Kole Wetands--Paralam",
          "Puzhakkal Public Library",
          "Kachithodu",
          "Kallettumkara Wetland",
          "Kole Wetlands--Uppungal 1",
          "Sholayar--Old Tramway Road",
          "Ollukkara--General Area",
          "Vazhani",
          "Kallayi",
          "KONNAKUZHI ROAD",
          "Mundathikode",
          "Eyyal, Kechery Akkikkavu Road",
          "Kuthiran",
          "Vellikulangara Kuttichira Rd--Pariyaram",
          "Kole Wetlands--North Pullu",
          "Kadukassery",
          "Vallathol Nagar",
          "Kothakulam Arappa",
          "Akamala [78,13,8]",
          "Kole Wetlands--Enamavu",
          "Kole Wetlands--Parppakadavu",
          "Mammiyoor--Little Flower College",
          "Akalad Beach",
          "Kole Wetlands--Chitilappilly",
          "Puliyani parambu",
          "Sholayar [66,25,6]",
          "Palappilly RF--Pothumchal",
          "Thanikudam,Madakkathara",
          "Paddy Fileds--Dhanya Mission Hospital",
          "Erayamkudy Fields",
          "Chengotta Beach",
          "KSEB Colony--Pandiparambu",
          "Mullur Road",
          "Vadakke Chira Lake",
          "Chimmony--General Area",
          "Pampadi-Peringottukurissi Rd",
          "Thiruthekad",
          "Vazhachal Division",
          "Choorakkattukara Sree Durga Temple",
          "Chaipankuzhi-Choolakadavu Rd",
          "Tribal Colony--near Mukumpuzha",
          "Vilangan hills",
          "Ellikkathara Paddy Field",
          "Kuttumucku-Nettissery Paddy Field",
          "Mundathikkode",
          "Kole Wetlands--Mullurkayal",
          "Elanad--West",
          "Kole wetlands - Thommana",
          "Vellakkarithadam--General Area",
          "Chamakkala beach",
          "Puliyala",
          "Kerala Sahitya Academy",
          "Thiruwilwamala-Punarjani Rock Faces",
          "Thanikkamunnayam",
          "Vadanappalli Beach",
          "Chuvanna Mannu",
          "Chittanda RF",
          "Varavoor",
          "Pariyaram RF",
          "Chembukkav--General Area",
          "Palappilly- Kundai",
          "Chempuchira",
          "Chaipankuzhy--Vellikulangara RF",
          "Kole Wetlands--Nedumbal",
          "Wadakanchery Railway Station",
          "Kerala Agricultural University--General Area",
          "Poonilarkavu Bagavathy temple",
          "Puranattukkara--General Area",
          "Thanniyam",
          "Thumboormuzhi Garden",
          "Sholayar RF [68,19,6]",
          "Edakulam Wetland",
          "Karamuck North",
          "Kole Wetlands--Aranattukara",
          "Poringal",
          "Thichoor",
          "Mankidi",
          "Pazhayannur--General Area",
          "Panajikkal Temple",
          "Tennis Club",
          "Chimminy WLS--General Area",
          "Punnamparambu-Manalithara",
          "Kole Wetlands--Ayanikkad",
          "Pattikkad--Chanoth",
          "Nambian Kavu Temple Rd,Madayikonam",
          "Poovathussery, North Poovathusssery - Meladoor Road",
          "Ollur Railway Station",
          "Kole Wetands --Chenam",
          "Sankarankulangara Temple",
          "Chimmony WL--Mankuzhi",
          "Thalore Paddyfield",
          "Peechi WLS--Munipara",
          "Chimmony WLS--Kanjirappara",
          "Anapantham [69,17,5]",
          "Sholayar Reservoir",
          "Mayannur",
          "Orukombankutti, Vazhachal",
          "Iron Bridge Vazhachal",
          "Sholayar [66,24,6]",
          "Canara Bank",
          "Kole Wetlands--Pullazhi",
          "Chettuva--Mangrove Forest",
          "Anakkayam Forest Station1",
          "Ollur -- General Area",
          "Vellanipacha",
          "Chimmini Dam Road",
          "Munakakkadavu",
          "Munambam Beach",
          "Chokkana",
          "Meladoor",
          "Malakkappara",
          "Kunnathara",
          "Koorkenchery Saw Mill Road",
          "Wadakanchery Rly Stn",
          "Oalakkayam",
          "Kerala Agricultural University--Arboretum",
          "Pillappara, Athirappilly Water falls",
          "Mankuzhi--Chimmony WLS",
          "Peechi WLS--Vellikkayam",
          "Chemmappilly--General Area",
          "Tramway Road",
          "Randukai RF",
          "Aramba Nagar Rd",
          "Cheeny Valavu Forest",
          "Upper Sholayar",
          "Peechi Reservoir [74,18,4]",
          "Pattalam Rd",
          "Vellanikkara",
          "Kondazhi",
          "Punnathur Kotta",
          "Vazhachal Forest Division--Anakkayam",
          "Sholayar [67,23,3]",
          "Kallur",
          "Thanikkudam--General Area",
          "Marottichal Forest area",
          "Kannamkuzhy",
          "Punnayoor (South)",
          "Thaikkad",
          "Pattathazham Paddylands",
          "Timber Depot",
          "Kole Wetlands--Enadi",
          "Cheppilakkode Pulpwood Plantation",
          "Munambam--Munakkal Beach",
          "Chimmini Dam",
          "Kailasanadha Vidya Nikethan School",
          "Chengaloor-Suryamangalam rd",
          "Vazhachal Forest Division--Karanthodu",
          "Karumathra",
          "Pallimanna Siva Temple",
          "Kole Wetlands--Adat--General Area",
          "Nelluwaya",
          "Sholayar RF--Aanakkayam Forest Station",
          "Chimmony WLS--Virakuthode",
          "Civil Station--Heronry",
          "Vengassery",
          "Kallayikunnu, Marottichal",
          "Kanakamala, Perambra, Chalakudy",
          "Parappuram",
          "Vazhachal Forest Division--Poringal",
          "Vazhachal Forest Division--Malakkappara",
          "Sholayar [67,24,1]",
          "Vazhachal Forest Divison--General Area",
          "Konoor",
          "Pancavadi--Edakkazhiyur",
          "Kadukutty",
          "Nehru Park",
          "Kole Wetlands--Kanjani (Pullu Side)",
          "Edakkazhiyur Beach",
          "Karuvankadu--General Area",
          "First Break",
          "Kole Wetlands--Thottippal",
          "Peechi WLS--Moodal",
          "Traffic Police Station",
          "Vazhachal Forest Division--Athirapilly",
          "Kerala Agricultural University--Botanical Garden",
          "Apollo Tyres",
          "Varakkara-Pallikkunnu Rd",
          "Chavakkad Beach",
          "Manjakalpara",
          "Perumala",
          "Shakthan Thampuran Bus Stand",
          "Kallettumkara",
          "Vellkulangara Range Forest Office",
          "Wetlands--Kallettumkara",
          "Vellichamukku",
          "Athirapally Waterfalls",
          "Paralam",
          "Peringandoor",
          "Nedumbal",
          "Nuttudumban Waterfalls",
          "Rajah Company --Arattupuzha Temple Rd",
          "Chowannur",
          "Kattilappoovam-Kundukad Rd [76,14,3]",
          "Mukkattukara--General Area",
          "Vazhachal Forest Division--Thavalakkuzhipara",
          "Peechi WLS--Mambara East",
          "Kavala--Vellikkulangara RF",
          "Kole Wetlands--Muriyad",
          "Vettikuzi",
          "Ubhayoor Temple",
          "Marathakkara",
          "Chimmony WLS--Mulappara",
          "Kozhukkulli - Nadathara",
          "Kole Wetlands-Kuttoor",
          "Kole Wetlands--Choorakkattukara",
          "Kattilappoovam [76,15,4]",
          "Kalletumkara, SH61",
          "Kole Wetlands--Padiyur",
          "Pathiyarkulangara, Parappur",
          "Kammalam",
          "Chimmony WLS--Vavala",
          "Peechi WLS--Karadipara",
          "Tholur",
          "Chimmony WLS--Payampara",
          "Malkkappara Road--Aanakkayam Bridge",
          "Peechi WLS--Vazhani",
          "Nalukettu",
          "Near Insha hotel",
          "Koranchira",
          "Palappilly RF--Chennaayikulam",
          "Kole Wetlands--Ambakkad",
          "Kerala Institute of Local Administration (KILA)",
          "Chiraparambu",
          "Pandiparambu",
          "Palappilly Rubber Estate",
          "Kallayai Tekkinkad",
          "Pariyaram",
          "Behind Sobha City--Puzhakkal Kole Wetlands",
          "Vadanappally",
          "Velumampadi",
          "Kole Wetlands--Enamavu Regulator Bridge",
          "Kole Wetlands--Konthipulam II",
          "Government Engineering College",
          "Elavoor",
          "Chiramanangad",
          "Vadakkekad, Koukanapetty Road",
          "Manathala --Chavakkad beach Rd",
          "Chattukulam",
          "Chemboothra",
          "Ashtamichira Chalakudi Road",
          "Kurinjakkal Thuruthu",
          "Chuloor",
          "Ollukkara Paddy Field",
          "Cheppara Rock",
          "Chitilappilly",
          "Olari",
          "Kole Wetlands--Puranattukara",
          "Chiramanangad, Unnamed Road",
          "Arabian sea: 10.1N 75.7E",
          "Peechi Reservoir",
          "Athirappilli Road-1",
          "Cherur--General Area",
          "Karanthodu",
          "Venganellur Temple Road",
          "Peechi Reservoir [73,17,6]",
          "Kole Wetlands--Palakkal",
          "Kole Wetlands--Akamthuruthu",
          "Kole Wetlands--Kondhipulam I",
          "Ramavarmapuram",
          "Mulagunnathukavu",
          "Kakkinikad",
          "Annamanada",
          "Sholayar dam gate Rd",
          "Kole Wetlands--Chiranellur",
          "Thalore Deepthi High School",
          "Adhoor--Thejus Engineering College",
          "Peechi WLS--Olakara",
          "Pullut",
          "Kole Wetlands--Elthuruth",
          "Panchavadi Beach",
          "Pullu",
          "Kannamkuzhi Thodu [67,18,2]",
          "Kole Wetlands--Murppara",
          "Perumbilavu",
          "Peechi--General Area",
          "Pathazhakundu Dam",
          "Kadikkad",
          "Kolathur - Anandapuram",
          "Guruvayoor Temple",
          "Munippara - Chalakudy Athirapilly Rd",
          "Kumbalangad [78,12,1]",
          "Kerala Agricultural University--ACCER",
          "Kerala Kalamandalam",
          "Arangottukara",
          "Chettuva--Rajah Island",
          "Chenathu Nadu",
          "Chowka",
          "Vazhachal Forest Division--Kundur Medu",
          "Vellikulangara",
          "Gandhi Nagar-Vadookkara",
          "Pallur",
          "Cheerakavu",
          "Vazhachal Forest Division--Sholayar Reservoir",
          "Chorumthanni",
          "Amaballur",
          "Kottapadi",
          "Karikulam",
          "Thejus Enginnering College",
          "Mattathur",
          "Ollur--General Area",
          "Palakkal kole wetland pond",
          "Thumboormuzi",
          "Kole Wetlands--Kuroor Para",
          "Kole Wetlands--Chenam",
          "Trikkur-Kaloor",
          "kollathirumedu",
          "Vellukkara",
          "Kottappuram Muziris Fort(remnants)",
          "Vazhachal- Malakkappara Road. Subcell : 65,25,7",
          "Kannambra",
          "Kainoor",
          "Sholayar RF--General Area",
          "Chimmony WLS--Anapporu",
          "Kole Wetlands--Puzhakkal",
          "Wetlands-Eyyal",
          "Kannankulagara",
          "Malakkappara RF--General Area",
          "Punnakuzhi Bridge, Tramway rd",
          "Kunnamkulam",
          "Wetlands--Valoor",
          "Kadavallur",
          "Chelakode",
          "Thumboormuzhi Dam area",
          "Sholayar Dam View Point",
          "Poolachuvadu",
          "Kole Wetlands--Arimbur",
          "Rasa Gurukul Resort",
          "Sand Quarry on Kiralur-Anjur road",
          "Irinjalakkuda Railway station",
          "Koratti Angadi Railway Station",
          "Amala Nagar",
          "Viruppakka",
          "Kulathur",
          "Chandipuli Padam",
          "Kole Wetlands--Perumbuzha",
          "Kizhoore",
          "Asthra Kambanikkadav Beach",
          "Inchakundu--kumbakulangara--Kodali",
          "Arabian Sea: 10.2N 76.1E",
          "Shobha City",
          "Pariyaram RF--Mullapanakayam",
          "Moonnumuri",
          "Peechi Reservoir [74,17,2]",
          "Kerala Forest Research Institute--International Guest House",
          "Quarry near Kodassery Mala Siva Temple",
          "Vazhany Dam Gardens",
          "Elavally",
          "Parappuram - Kadavallur",
          "Pariyaram RF--Injapara",
          "Kuranjiyoor",
          "Kole Wetlands--Pallippuram",
          "Amala Institute of Medical Sciences",
          "Chettarickal",
          "Poringal Reservoir",
          "Peechi WLS--Kombazha",
          "Kole Wetlands--Tholur",
          "kunnathukad",
          "Parambikulam Tiger Reserve--Kothala",
          "Pazhuvil--General Area",
          "Vettingappadam",
          "Karikkadavu, Anappantham",
          "good earth/anamala farm house",
          "Thozhiyur",
          "Kadangode",
          "Poyya",
          "Ayyanthole--Costford",
          "Punnayurkulam",
          "Pottore",
          "Kavala",
          "Kole Wetlands--Anthikkad Padasekharam",
          "Vaduvan chira -- Kallettumkara",
          "Kole Wetlands--Madhukkara",
          "Arabian Sea: 10.1N 75.8E",
          "Paliyekkara toll plaza",
          "Mala Town",
          "Kole Wetlands-Madhukkara",
          "Kumbalangad",
        ],
      },
      {
        district: "Kasaragod",
        localities: [
          "Bekal Fort",
          "Anodi Palla Dam",
          "Heroor Wetland",
          "Poinachi",
          "G L P S, Peral Kannur",
          "Padannakkad Hanging Bridge",
          "GHS,Thachangad",
          "Allakodan Vallappu, Near Pulari Samskarika Kendram, Aravath Vayal, Pallikkara II, Kerala, IN (12.417, 75.041)",
          "Aravathvayal Wetland",
          "Ranipuram Reserve Forest",
          "Pelthaje",
          "Kannol Vayal",
          "Battekala Wetlands (Vorkady)",
          "Mappittacherry Kavu, Putilothu, Kasaragod",
          "Kanhangad Railway Station",
          "Ayetti Kavu",
          "Varanjoor",
          "Kumbla-Shiriya Estuary",
          "A U P School Mulleria",
          "Mogral Estuary",
          "Bejja",
          "Karimbinthodi",
          "Kamanabail",
          "Thachiru Mala, Koliyar",
          "Kuniyan Wetland-Main",
          "Janma Kadappuram, Udma",
          "Arabian Sea: 11.9N 74.6E",
          "Vadake Kadu Island",
          "Thirthankara",
          "Palavayal",
          "Kuruvappalli Wetlands",
          "Kuniya",
          "Kasaragod Tourist Guest House",
          "Cheemeni",
          "Edattumal - Kuniyan Sub",
          "Central University of Kerala, Periye",
          "Arabian Sea: 11.9N 74.5E",
          "Kasaragod Forest Division--Parappa (Kanhangad)",
          "Puthiya  Kotta",
          "Kunjathur",
          "Talekala",
          "Government Higher Secondary School Balanthode",
          "Karindalam",
          "Vilakkumadam,Karichery",
          "Ariyitupara - Pothamkandam",
          "GHSS Mogral Puthur",
          "St. Bartholomeas School, Bela",
          "Govt.College, Kasaragod",
          "Poothakkal",
          "Arikady",
          "Kanathur",
          "Aravath Vayal",
          "Bheemanadi Reserve Forest--Plachikkara",
          "Edayilakkadu Kavu",
          "Udinur Vayal",
          "Karakode",
          "Thalabail Village",
          "Katukukke Cell",
          "Bandiyod Heronry",
          "Kuttamath Vayal",
          "Parappa (Mulleria)",
          "Bedur-General Area",
          "Mulinja",
          "Kubanoor",
          "Delampady",
          "Arabian Sea: 12.6N 74.5E",
          "Nellikatte",
          "Atyadka",
          "Ravaneshwaram Vayal",
          "Valiyaparamba Beach",
          "Odayamchal",
          "Pady",
          "Chemmattamvayal",
          "Ambiladka Pump House",
          "Manjeshwar Estuary",
          "Urmi",
          "Kanhangad Heronry",
          "Arabian Sea: 12.2N 75.1E",
          "Bekal Beach Park",
          "Ananthan palla",
          "Manhampothi hills",
          "Chithari River",
          "Periya Bazar",
          "Arikkana",
          "Shiribaagilu-Maippady",
          "Kokkal-Kavvayikayal",
          "Kanva Theertha,Manjeshwar.",
          "LBS College of Engineering Povval",
          "Cheemeni Sacred Grove",
          "Attegoli",
          "Hosangady Heronry",
          "Bayar padavu",
          "Cherkala",
          "Mandekapu",
          "Bedur Forest",
          "Model College Madikai",
          "Azhithala Beach, Nileshwaram",
          "Molothumkavu",
          "Ananthapura",
          "Beripadav",
          "Kammadam kavu",
          "Navajeevana School, Perdala",
          "Panayal Vayal",
          "Chandragiri Estuary",
          "Arayi Kadavu",
          "G H S S Angadimoger",
          "Nalanda college perla",
          "Kasaragod Forest Division--Mauthom Popular",
          "Kannankai Wetlands",
          "AUPS,Kuntar",
          "M S C H School,Neerchal",
          "Banam",
          "Thaikadapuram",
          "Edattummal",
          "Nehru College, Padanakkad",
          "Muroor",
          "Kidoor",
          "Puthige Wetlands",
          "Kottancheri Reserve Forest",
          "Thalpanaje",
          "Bengapadav",
          "Perne",
          "Kaattikkaja-Pulipparamba--Reserve Forest",
          "Vanashree Complex Kasaragod",
          "Mangalpady",
          "Chithari Estuary",
          "Periadka,Bela",
          "Kambar Dam",
          "Karakka Kavu, Pilicode, Kasaragod",
          "Paivalike",
          "Kammadathukavu",
          "Permude Pond-Gumpe Hills",
          "Bambrana Wetlands",
          "Kerala , Kollangana- Aranthode",
          "Kulangarapara Kavu---Kolathur",
          "Arabian Sea: 11.9N 74.7E",
          "Kattipoyil",
          "Sunkathakatte",
          "Oriyara-Mavillakadapuram",
          "Peralathvayal",
          "Government Higher Secondary School, Balanthode",
          "Puthrakkala - Yethadka",
          "Birikulam Kannur Rd",
          "Theerthankara Pond",
          "Koyithatta Para",
          "Cheruvathur",
          "Nileshwar",
          "Champilamkai",
          "Meenja",
          "Aranthode",
          "Moolakandam",
          "Puthilot Vayal",
          "Gumpe Hills",
          "Pulimoot Beach",
          "Kanyapadi",
          "Kodlamogaru",
          "Kukkangoodlu",
          "Kanhirapoyil",
          "Uliyathadukka",
          "Wetland--Kalluravi--Kanhangad",
          "Permude",
          "Holy Family School, Kumbla",
          "G H S S Kumbla",
          "Kadambar",
          "Padannakkad",
        ],
      },
      {
        district: "Kannur",
        localities: [
          "Kannur University",
          "Thadikadavu",
          "Madakkara",
          "Arabian Sea: 11.8N 75.3E",
          "Thekkumbad",
          "Arabian Sea: 11.8N 74.6E",
          "Kanjirakolli",
          "Manekkara",
          "Kunniyan Wetland",
          "Chal Beach",
          "Arabian Sea: 11.8N 74.7E",
          "Arabian Sea: 11.8N 74.9E",
          "Kannur Kandal Project",
          "Mundapram",
          "POYILOOR SREE MUTHAPPAN  MADAPPURA",
          "Naniyoor",
          "Chera--Panthottam",
          "Paalchuram Falls",
          "Vayakkara Kandam",
          "Narikode Vayal",
          "Muttilpalam",
          "St. Angelo Fort",
          "Kunnirikka",
          "Govt. Ayurveda College Campus, Pariyaram",
          "Paalchuram",
          "Cheruthazham Vayal",
          "Periya Ghats",
          "Aralam WLS--Chavachi",
          "Arabian Sea: 11.9N 74.9E",
          "Arabian Sea: 11.8N 75.1E",
          "Paithalmala",
          "Panthrandaam Chaal",
          "Chengoorichal",
          "Madayi Para",
          "Someswari devi temple, Padappengad",
          "kuniyan wetland",
          "Stadium (Heronry)",
          "Maryamgalam",
          "Paliyeri vayal",
          "Sir syed college, thaliparamba",
          "Punnol",
          "Arabian Sea: 11.9N 75.1E",
          "Aanakkulam pond & Makkani Urban forest- South Bazar",
          "Arabian Sea: 11.9N 75.2E",
          "Sivapuram (Heronry)",
          "Arabian Sea: 12.0N 75.1E",
          "Arabian Sea: 11.8N 74.5E",
          "Kokkara Vayal (OBI)",
          "Kanichar",
          "Chirakkal Chira",
          "Aralam WLS",
          "Arabian Sea: 12.0N 75.2E",
          "Kakkara",
          "Pavannur Kadav",
          "Vattappoyil",
          "Ezhimala",
          "Monthal",
          "Caltex (Heronry)",
          "Chirakkuni",
          "Nadal",
          "Kattaampally--Kattampally Dam",
          "Edakkanam view point",
          "Karinkkankandi Chira",
          "Kottiyoor RF",
          "Maniyara",
          "Arabian Sea: 11.8N 74.8E",
          "Kunnumkai",
          "Sree Sankaracharya University of Sanskrit Kalady, Regional Campus",
          "Kakkad",
          "Chempanthotty",
          "Mullakkodi",
          "Pala",
          "Karikkankandichira",
          "Pana Vayal",
          "Chavanapuzha",
          "Kottayam Chira",
          "Mayyil",
          "Sooryamudi",
          "Arabian Sea: 11:6N 75.1E",
          "Arabian Sea: 11.9N 75.3E",
          "Karippal para",
          "Kunnaru",
          "Bakkalam Vayal",
          "Arabian Sea: 11.8N 75.2E",
          "Muzhappilangad Estuary",
          "Mappila Bay",
          "Porora",
          "Keecheri Vayal",
          "Edakkad",
          "athirakam",
          "Thazhe Chovva",
          "Kanjirakkunnu",
          "Mangatuparambu Neeliyar Kottam",
          "at sea off the coast of Kannur (OBI)",
          "Iriveri Pulideva Kshetram",
          "Chalode",
          "Arabian Sea: 11.9N 75.0E",
          "Payyambalam Beach",
          "Kadamboor",
          "Chemmadam",
          "Kolathuvayal",
          "Kallettumkadavu",
          "Elapeedika",
          "Pazhassi Reservoir",
          "Payyanur Railway Station",
          "Arabian Sea: 11.9N 74.8E",
          "Nirmalagiri campus",
          "Neeliyarkottam",
          "Pulloopikadavu Palam",
          "Chamakkavu",
          "Kannur International Airport",
          "Madakkad",
          "SN College",
          "MUZHAPPILANGAD BEACH",
          "Sir Syed College, Taliparamba",
          "Kanayi Kanam",
          "Kannur Forest Division--Punneri",
          "Pazhayangadi--Kakkara Vayal",
          "Kunhimangalam Mangroves",
          "Keeriyad",
          "Kannur--SBI",
          "Kannur Forest Division--Chokkadumpara - Perumalmudy",
          "Moidu Bridge",
          "Koduvally  Wetlands",
          "Ezhome",
          "Edapara Chamundeshwari Kshetram",
          "Kannur Forest Division--Parakkadu",
          "Kattampally",
          "Adikadalayi Beach",
          "Munderi Kadavu Bird Sanctuary",
          "Arabian Sea: 12.0N 75.0E",
          "Aralam WLS--Valayanchal",
          "Sreelayam",
          "Government Brennan College, Thalassery",
          "Ayyoth",
          "Aralam WLS--Paripputhodu",
          "Azhikkal Beach",
          "Ettikulam Beach",
          "Manjuppalam Road",
          "Chempallikundu",
          "Podikkalam",
          "Olackal Estuary",
          "Thekkevayal, Kunhimangalam",
          "Kappimala",
          "Chathurampuzha",
          "Pazhassi Kovilakam",
          "Wayand North Forest Division--Thirunelli IB to Watch Tower",
          "Kannur Railway Station",
          "Dharmasala",
          "Dalil",
          "Mambaram",
          "KANAKAMALA",
          "Kannur Forest Division--Ambayathodu - Chokkadanpara",
          "Payyanur College",
          "Kottukapara",
          "Kizhunna Beach",
          "Dharmadam Beach",
          "Mangalasseri",
          "Keezhara",
          "Kannur Forest Division--Kandappunam FS - Ambayathodu",
          "Aralam WLS--Ambalappara",
          "Poyiloor",
          "Mungam Bridge",
        ],
      },
      {
        district: "Idukki",
        localities: [
          "Kajanappara [56,33,5]",
          "Udayagiri [52,27,1]",
          "Panchalimedu--Kappaluvenga",
          "Anchiri Paddyfields, Thodupuzha - Aanakkayam Rd.",
          "Thekkady--Bamboo Grove Hotel",
          "Periyar Tiger Reserve--Boat Landing",
          "Manale Tea Bungalow, Devikulam",
          "St Augustine's Hospital",
          "Kattadikkadavu Hills, Vannappuram",
          "Anakkara--Chellarcovil",
          "Vayanakavu Temple--Kudayathur",
          "Mathikettan Shola NP--Aduvilathan",
          "Munnar--Dam Area",
          "Poomala--Njandirukki Waterfalls",
          "Periyar Tiger Reserve--Paalkkachimala",
          "Kallar--Vattiyar",
          "Ecotones Camps",
          "Pullikkanam--Nallathanni Viewpoint",
          "Munnar--KDHP Tea Plantations",
          "Rajakumari North [56,34,1]",
          "Malayattur Division--Uriyampetty",
          "Idukki WLS--Chambakassery thodu",
          "Uluppuni--Tunnel and Forest",
          "Periyar Tiger Reserve--Aruviyoda",
          "Thadiyampad [53,27,6]",
          "Cheeyappara Waterfalls",
          "Kanthalloor Panchayat Office area",
          "Periyar Tiger Reserve--Manammuttimala",
          "Carmelia Haven Resorts, Vandanmedu",
          "Kannimalai Govt UP School",
          "Periyar Tiger Reserve--Moolavaiga",
          "Chinnar WLS--Palappetty",
          "Meesappulimala--Rhodo Mansion",
          "Periyar Tiger Reserve--Sathram",
          "Pampadum Shola NP(2)",
          "Chinnar WLS--Olikkudi",
          "Thokkupara [57,29,8]",
          "Kumarikal Mala",
          "Kanthalloor - Thoppan's Orchard and Farm Stay",
          "Periyar Tiger Reserve--Thamarakandam",
          "Murickassery [54,28,3]",
          "Uluppuni--Top Station",
          "Vallakkadavu Town",
          "Idukki--GEC Campus",
          "Munnar--Blackberry Hills Munnar Resort",
          "Menonmedu--Chakkupallam",
          "Munnar--Meenthotty",
          "Mankulam Forest Division--Viripara",
          "Santhigiri",
          "Pettimudi",
          "Arivilamchal, Rajakumari - Mangathotty Rd.",
          "Olive Brook Resort, Pothamedu",
          "Deep Woods Resort",
          "Chinnakanal--Club Mahindra Serene Rd",
          "Konnathady [55,29,6]",
          "Aanakkayam--near Cooperative Bank",
          "Chakkupallam - Kumily",
          "Nayamakad, Munnar",
          "Munnar--Tea County Resorts",
          "Glenmary Kodua Road",
          "Vayanasala Rd [57,31,7]",
          "Munnar--near Abad Resort",
          "Malankara Park and Dam",
          "Anamudi Shola NP--Methappu",
          "Periyar Tiger Reserve--Brandipara",
          "Kallippara, Santhanpara GP",
          "Kovilkadav, Kanthalloor-Marayoor Rd.",
          "St Antony's College, Peruvanthanam",
          "Chathamattom-Mullaringad Rd",
          "Eravikulam NP--Kolukkan",
          "Karadippara, Kallar, Munnar",
          "Periyar Tiger Reserve--Kokkara",
          "Reen Resorts, Aanavilasam",
          "Mattupetty Dam",
          "Ellackal [57,30,3]",
          "Idukki WLS--Kettuchira",
          "Periyar Tiger Reserve--Uppuppaara",
          "Munnar--Letchmi Estate Rd.",
          "Near Indo Swiss Project, Munnar-Topstation Rd",
          "Periyar Tiger Reserve--Mangaladevi Trail",
          "Pampupara",
          "Koompanpara - Mankadavu Rd.",
          "Mankulam Forest Division--Kadalar",
          "JPM Arts & Science College, Kanchiyar",
          "Chinnar WLS--Vannamthura",
          "Pullikkanam--DCSMT College",
          "Eravikulam NP--Eravikulam Hut",
          "Eravikulam NP--Parking",
          "Idukki--Kalvary Mount",
          "Kundala Reservoir",
          "Lockhart Gap Viewpoint (NP Viewpoint), Munnar-Kumily Hwy",
          "Munnar--Deshadan Hills",
          "Munnar 8th mile",
          "Kulamavu Forest Trail",
          "Munnar--Monsoon Grande Hotel",
          "Muttukad--Societymedu [57,33,5]",
          "Camp Lights, Chillithode",
          "Thannikadam [52,23,8]",
          "Idukki--Ellapatty",
          "Adimali (town)",
          "Chinnar WLS--General Area",
          "Kottakamboor",
          "Mankulam Forest Division--Kozhiyalakudi",
          "Chinnar WLS--Thayannankudy Road",
          "Periyar Tiger Reserve--Thannikkudy",
          "Kannimala",
          "Thekkady--Aranya Nivas",
          "Aanakkoodu Wetlands, Vengalloor, Thodupuzha",
          "Munnar - Top Station Rd.",
          "Peruvanthanam-Meloram [42.22.8]",
          "Pulimoottil Estates, Munnar",
          "Anayiragal Dam",
          "Periyar Tiger Reserve--Azhutha",
          "Chokramudi--Shola",
          "Vakka City [55,32,5]",
          "Vagamon--General Area",
          "Aanappara Viewpoint, Ezhalloor",
          "KFD IB, Munnar",
          "Veloor Ayyappa Temple",
          "Kambilikandam-Murickassery Rd [54,29,5]",
          "Eravikulam NP--Varattukulam",
          "Rajappara, Santhanpara GP",
          "Elappara--Bonami",
          "Anamudi Shola NP--Kanthaloor",
          "Marayoor--General Area",
          "Periyar Tiger Reserve--Thamara",
          "Perunvanthanam-Amalagiri [42,23,7]",
          "Devikulam--General Area",
          "Idukki Dam--Boating Point",
          "Periyar Tiger Reserve--Aruvipalam",
          "Koviloor",
          "Kulamavu--Greenberg Jn. in SH33",
          "KLDB Farm Kolahalamedu",
          "Kanthaloor",
          "Vagamon--Palette People Artists Residency",
          "Uravappara Subrahmanya Temple, Thodupuzha",
          "High Range Club, Munnar",
          "Karadippara View Point--Kallar",
          "Sleevamala--Vattakkannippara [54,32,8]",
          "Attukadu Waterfalls",
          "Malayattur Division--Koompanpara",
          "Granby Estate Vandiperiyar",
          "Neriamangalam Bridge",
          "Muthuvaankudi [56,30,8]",
          "Pullumedu - [46,27,2]",
          "Chinnar WLS--Thoovanam Falls",
          "KLDB farm, Mattupetty",
          "Hill View Park, Idukki",
          "Idukki WLS--Kizhukanam",
          "Memala [44.24.7]",
          "Upputhode [52,25,9]",
          "Munnar--Kannan Devan Hills Club",
          "Kulamavu--Nadukani Pavilion",
          "Anchuruli Tunnel",
          "Marayoor--Anakalpetty Sandal Forest",
          "Periyar Tiger Reserve--Periyar Lake",
          "Silent Valley Road, Munnar",
          "Chinnar WLS--Chinnar",
          "Periyar Tiger Reserve--Anchuruli Rd.",
          "Ankamvetty Proposed Cricket Ground Site",
          "Marakkanam [55,30,5]",
          "Kumily",
          "Springdale Heritage",
          "Ponnumpoojary Temple - Attikulam [53,28,7]",
          "Periyar Tiger Reserve--Pugmark Trail",
          "Thommankuthu--Chappathu",
          "Forest Haven Resort--Kallar",
          "Alampatty",
          "Rajakkad--Mangathotty Rd -- Kanthippara [55,33,4]",
          "Eravikulam NP -- Waterfalls",
          "Chinnar WLS--Alampatty",
          "Mankulam Forest Division--Urallukuthy",
          "Idukki WLS--Vellakkamaly",
          "Uppukunnu Viewpoint, SH 41",
          "Anamudi Shola NP",
          "Pattayakudy [56,24,8]",
          "Amaramkavu Vanadurga Temple, Thodupuzha",
          "Periyar Tiger Reserve--Anjuruli",
          "Cassells Resorts",
          "Muthaliyarmadom Temple--Thodupuzha, IN-KL",
          "Periyar Tiger Reserve--Mlappara",
          "Marayoor--Karimutty Waterfalls",
          "Senapathy--Ambalapady [55,34,4]",
          "Munnar--Pothamedu",
          "Kurinjimala Sanctuary--Kadavari",
          "Mistletoe Homestay, Iruttukanam",
          "Periyar Tiger Reserve--Interpretation Centre and Dormitory",
          "Gundumalai",
          "Panchalimedu Hills",
          "Munnar - Near hotel Dream Palace",
          "Leaf Munnar Resorts",
          "Periyar Tiger Reserve--General Area",
          "Kurisupara - Kallar-Anakkulam Rd",
          "Josgiri-Kochuppu [56,31,8]",
          "Kurinjimala Sanctuary--General Area",
          "Pampadum Shola NP--General Area",
          "Kiliyarkandam [52,26,8]",
          "Periyar Tiger Reserve--Vallakkadavu Range",
          "Periyar Tiger Reserve--Mullakudi",
          "MBC College of Engineering, Peermade [43.24.8]",
          "Mankulam Forest Division--Kallar",
          "Eravikulam NP--Poovar Campshed",
          "Thommankuthu Waterfalls--Trek Path",
          "Punnayar Waterfalls",
          "Munnar---8th mile Forest Camping Station",
          "Bridge, Chottupaara - Uluppuni Rd.",
          "Manjumala [42.26.2]",
          "Kannimalai Chapel, Munnar",
          "Periyar Tiger Reserve--Green Walk",
          "Valara Waterfalls",
          "Periyar Tiger Reserve--Vellimala",
          "Periyar Tiger Reserve--Upper Manalar",
          "Pulikunnu Viewpoint, Manakkadu",
          "Bamboo Dale Resort, Bison Valley",
          "Periyar Tiger Reserve--Eravangalar",
          "Munnar--Nullatanni KFD Dormitory",
          "Thommankuth--Santhikkadu Temple",
          "Periyar Tiger Reserve--Medakanam",
          "Eravikulam NP--Checkpost",
          "Pullumedu--Pettimudi Checking Station",
          "Marayoor--Nature Tones Mudcamp",
          "Meesappulimala--Viewpoint",
          "Chinnar WLS--Champakkadu",
          "Selliampara--Vellathooval Rd [56,29,6]",
          "Mankulam Forest Division--Pambadumpara",
          "Eravikulam NP--Rajamala",
          "Puttady Temple area",
          "Periyar Tiger Reserve--Manakavala",
          "Letchmi Estate Post Office",
          "Eravikulam NP--General Area",
          "Chinnar WLS--Karimutty",
          "Munnar--Pallivasal Rd.",
          "Idukki--Valanjamkanam",
          "Pampadum Shola NP--Bison Log House",
          "Kallar--Birding with Kuttettan",
          "Chinnar WLS--Thoovanam Viewpoint",
          "Kurinjimala Wildlife Sanctuary--Neduvarpp",
          "Idukki WLS--Vakavanam",
          "Thekkady--Cardamom County Resort",
          "Echo point",
          "Vagamon--Town",
          "Madathikandam Paddyfield, Thodupuzha",
          "Ramakkalmedu Hilltop",
          "Periyar Tiger Reserve--Thekkady",
          "Malayattur Division--Anakkulam",
          "Munnar-Pulippara - Nature zone resorts Rd",
          "Thekkady--Vanasree Dormitory",
          "Periyar Tiger Reserve--Edapalayam",
          "Vattavada--General Area",
          "Mankulam Forest Division--Munippara",
          "lakkamkudi",
          "Vagamon--Meadows",
          "Neriamangalam",
          "Kanthallor",
          "Munnar--Camelot Resort",
          "Munnar--Deshadan Mountain Resort, Pothamedu",
          "Parunthumpara Viewpoint",
          "Vagamon--Adventure Zone",
          "Ilaveezhapoonchira Hilltop (Idukki Co.)",
          "Gundumalai Town",
          "Munnar--General Area",
          "Mathikettan Shola NP--Pethotty",
          "Kumily--Abad Green Forest Resort",
          "Chinnar WLS -- Churulippetty",
          "Munnar--Windermere Estate, Pothamedu",
          "Peermadu Guest House",
          "Pazhathottam--Koviloor",
          "Jungle Traveller's Hostel, Kallar - Mankulam Road",
          "Meenuliyan Para, Vannappuram",
          "Chokramudi--Trekpath",
          "Edavetty Vanam, Thodupuzha",
          "Kulamavu--Vadakkanar Lake",
          "Upputhode [53,29,2]",
          "Kumily -- Spice Village",
          "Sengulam Dam",
          "Munnar -- Bison Valley Road",
          "Chinnar WLS--Aanakkunnu",
        ],
      },
      {
        district: "Alappuzha",
        localities: [
          "Valiazheekal siva temple",
          "Mithrakari,Kidangara",
          "Thycattussery",
          "Trikunnapuzha beach",
          "Ulavaipu Wetlands -- Thykattussery",
          "Kuttanad Wetlands--H-Block",
          "Vederaplavu",
          "(9.668, 76.322)Homeyard Karuvayil Bhagom",
          "Venmony-Kochumuri Kadavu",
          "Alappuzha Railway Station",
          "Kainakary kayal",
          "Valiyazheekkal",
          "Thykkoodam Kadavu",
          "Puthuppally kayal",
          "Kurattikadu",
          "Varenickal",
          "NTPC Ground",
          "Alappuzha-Kuttanad Road",
          "Thottappally beach",
          "Kavalam",
          "Puthupally",
          "Punnapra",
          "Kayamkulam Railway Station",
          "R Block 14000",
          "chengannur railway station",
          "Kainady North",
          "Aroor pally",
          "Bharanikkavu",
          "Bishop Moore College, Mavelikara",
          "Rajagiri School Campus Area - Manappuram (Limited Access)",
          "Mambalappadom - Cherthala",
          "Thottappally east",
          "Vallikunnam",
          "perumpally beach",
          "Kuttanad Wetlands--Vaniamkadavu",
          "Kodamthuruth Wetlands",
          "Vidyadhiraja School",
          "Kallummoodu",
          "Kuttanad Wetlands--Kudavellari",
          "Parayakad-kannat wetlands -- Thuravoor",
          "Pullattuveli",
          "Vayalar temple",
          "Kavumpuram",
          "Arabian Sea: 9.2N 76.3E",
          "Muttar",
          "Karur",
          "Muhamma",
          "Veeyapuram-Karuvatta Road",
          "Karumadi",
          "Pallippad",
          "Kuttanad Wetlands--Karingalichal Lake",
          "Kannamangalam Kayal",
          "Kandalloor",
          "Kuttanad Wetlands-Pallippad Parayankari Puncha",
          "Vaduthala",
          "Thandanuvila",
          "Karumadi kari",
          "Vadackal",
          "Parakulangara",
          "Thaiyil Chira",
          "Vembanad Wetlands--Pathiramanal Island",
          "Alappuzha beach",
          "Changappaadam, Venmani",
          "Chengannur Railway Station, Platform 3",
          "Moolakalam -- Pallithode South",
          "Kainakary North",
          "Valiyakulangara",
          "Arabian Sea: 9.2N 76.4E",
          "panoor beach",
          "Punnakunnam",
          "Moncompu",
          "Kareelakulangara",
          "Andhakaranazhi beach",
          "Ayyappanchery",
          "Puthiyavila",
          "Kainady",
          "Koduppunna",
          "Kuttanad Wetlands--Melpadam",
          "pullinkunnu",
          "Ulavaipu Wetlands (West Block) - Thykattussery",
          "ilippakulam",
          "Kuriyannoorkattil",
          "Kuttanad Wetlands--Moorthitta",
          "Panayil",
          "Purakkad South",
          "kondailip Resort (limited Access)",
          "Kannamangalam",
          "Chunakkara Wetlands",
          "Venmony Thazham",
          "kallu mala",
          "Thekkekara",
          "Veeyapuram",
          "Kuttand Wetlands--Thevarkadavu Puthanar",
          "karakkad",
          "Kidangara",
          "Mannarasala Sree Nagaraja Temple",
          "Kainakary South",
          "Mullakkal kayal",
          "Kannankara Wetlands  - Thaneermukkam",
          "Vembanad Wetlands--Narakathara",
          "Kuttanad Wetlands--Karuvatta",
          "H-block East",
          "Karipuzha",
          "Nedumudy - Bhoothapandam Kayal",
          "Autokast Area - Cherthala (Limited Access)",
          "Puliyoor",
          "Chembakasseri Wetlands - Pattanakkad",
          "Mangalam",
          "Thiruvanvandoor Sugarcane fields",
          "Vettikottu chal",
          "NSS College Campus - Cherthala (Limited access)",
          "Varanad Devi temple",
          "Purakkad",
          "Pallathuruthy North -- Alappuzha",
          "Mampra Paadam, Cheriyanad",
          "Kunnumma",
          "Aleppey Backwaters",
          "Pallana Beach",
          "Vettakkal B Block",
          "Cheruthana",
          "Mullakkal",
          "Kannanakuzhi",
          "Gandhi Smrithivanam -- Purakkad",
          "Bhudhanoor,Thazhamthara",
          "Thazhathampalam",
          "Kadakkarappally",
          "Arthunkal",
          "Kuttanad Wetlands--Mankottachira",
          "Mahadevikadu",
          "Kattanam St. thomas hospital",
          "Kochurani padam Illichira",
          "Kallumala",
          "venettukad",
          "Kuttemperoor",
          "Elippanam Wetlands -- Mannanchery",
          "Karthikapally",
          "Kunthirickal",
          "Pandarakulam",
          "Parayakad--Thuravoor",
          "Kanjippadam",
          "Vembanad Wetlands--Nedumudy",
          "Kurichimuttom",
          "Thinkalamuttam",
          "Changaram Wetlands -- Ezhupunna",
          "Arrattuvazhi Wetlands - Vettackal",
          "Beach Marari",
          "Arookutty - Odampally",
          "Kuttanad Wetlands--Ayikkara Mukku-Vaniyamkadavu",
          "Thampakachuvadu",
          "St. Monica's Church Thuravoor and general area",
          "punnapra beach",
          "Alleppey Beach",
          "Marari Beach Resort",
          "Pallathuruthy - Near Houseboat Terminal",
          "kottalappadam wetlands - Pattanakkad",
          "Cheppad",
          "pandy",
          "Christian College, Chengannur",
          "Puthenchantha",
          "Kattady Kadappuram - Cherthala",
          "Thirunallur",
          "Vembanad Wetlands--Pallathuruthy",
          "Varanad",
          "Kottamukku",
          "Kurattissery",
          "Pallithode wetlands -- Thuravoor",
          "Ennakkad",
          "Valavanadu",
          "SN College, Cherthala(Restricted access)",
          "Neendakara Wetlands -- Ezhupunna",
          "Perumbalam",
          "Mavelikkara Railway Station",
          "Vayyankara chira",
          "Nadubhagam",
          "Ayaparampu",
          "Aaryad",
          "KTR Wetlands, Cherthala, Kerala, IN (9.649, 76.347)",
          "Thonackadu",
          "R block kuttanad",
          "Vettampallil",
          "Govimdamuttom",
          "Pallipuram",
          "Pavukkara,Karinilath",
          "Elanji Padam, Areeparambu - Cherthala",
          "Keerikkad",
          "Poyyakkara",
          "Kuttanad Wetlands--Vallomkadavu",
          "Kunnuparambu",
          "Karuvatta Railway station",
          "Chempumpuram",
          "Haripad (OBI)",
          "Aravukadu",
          "Perumbalam North",
          "Chettikulangara temple",
          "Mulakuzha",
          "Kaithavalappu",
          "Parayakkadavu beach",
          "CHC Road, Thycattussery, Kerala, IN (9.779, 76.34)",
          "R block",
          "Mannanchery",
          "Erpathinaalayriam Kayal Paddyfield (North Kainady)",
          "Near Private Bus Stand",
          "Alappuzha KSRTC Bus Station",
          "Vayalar",
          "Kommady",
          "Venmony",
          "kuttanadu",
          "Edathua",
          "Kuttanad Wetlands-Karippuzha Puncha",
          "Manapra",
          "Venattukad",
          "Arthungal Beach",
          "Pattoor",
          "Ottamassery",
        ],
      },
      {
        district: "Kottayam",
        localities: [
          "Mundakyam",
          "pallom",
          "Kattadikunnu",
          "Kottayam--Thalanad",
          "Idukki--Punchavayal--Karisseri",
          "Kuttanadu Wetlands--Kallara--Mudakkali",
          "Kottayam--Mannanam--K.E College",
          "Irumpuzhikkara",
          "46,12,4 ettumanoor",
          "Kuttanad Wetlands--Poovathum Kari East",
          "Attipedika",
          "Kottayam--Chempanthuruthu",
          "Vembanad Wetlands--KTDC",
          "Kottayam--Kallara--St. Mathews Church",
          "Kuttanadu Wetlands-Kolathu Kari-Kallara",
          "nedumala estate",
          "Pala-Lake near KC Sebastian Sq",
          "Kottayam--Puthuppally-Paloorpady road",
          "Kumarakom--Vayitharamattom",
          "Boat Jetty Road, Kumarakom",
          "MG UNIVERSITY",
          "Sasthankal paddy fields",
          "Thaazhathangaady",
          "IN-KL - 9.6954x76.4593 - 07-Apr-2014 7:18 am",
          "Vembanad Wetlands--Kaippuzha  Muttu",
          "Kottayam--Erattupetta--Fire Station",
          "Methran  Kayal",
          "Palathuruthu",
          "Kottayam--Kaippuzhamuttu Cheepunkal Wetlands",
          "Kottayam--Anickadu Sree Bhagavathi Temple",
          "Kottayam Medical College Campus",
          "Good Shepherd College",
          "Kottayam--CMS College (Restricted access)",
          "IN-Kerala--Kottayam--Parampuzha--Muzhoor Paddy Fields",
          "Kumarakom Bird Sanctuary tower",
          "Poonjar--Valathooku",
          "Ambal Field, Eravinalloor",
          "Kuttanad Wetlands--Poovathumkari",
          "a42",
          "Vaikom--Kottarappally",
          "Kidangoor",
          "Kottayam--Kariyampaadam",
          "Kottayam--Bye-pass road",
          "Kuttanadu Wetlands--Poovathikkari",
          "Kottayam--Kanakappalam Forest",
          "Palai--St. Thomas College (Restricted Access)",
          "Kottayam - Cheepunkal, Maniyaparambu Road",
          "Kuttanad Wetlands--Mudakkali Paadam",
          "Kuttanad Wetlands--Kallara",
          "Perumthuruthu, Kallara",
          "Cheeppunkal",
          "Erattupetta--Pwd Rest House",
          "Kottayam--Veloor Byepass road",
          "Kodimattom",
          "Kalaketty Sivaparvathy Temple",
          "Kottayam--Vechoochira--Nadakkavuvayal",
          "Kottayam--Marmala Waterfalls [47.20.8]",
          "Vembanad Wetlands -- Narakathara",
          "Saintgits College of Engineering, Kottayam",
          "Coconut Lagoon Hotel",
          "Idukki--Mukkada Rubber Board Nursery",
          "Channanikadu",
          "Kottayam--Eranjal",
          "Oorvayal",
          "IN, Kerala--Thalanadu--(9.723, 76.811)--Kavumbhagam",
          "Kallara--Pravattom",
          "st thomas mount, valavoor",
          "edayazham",
          "Kariyam Paadam",
          "Kurisumala Base [46.21.4]",
          "Kottayam--KSRTC Bus Stand",
          "26th mile [42.18.9]",
          "Kuttanad Wetlands--Perunna Paddy Fields",
          "Kumarokam",
          "Kottayam--Mararcadu",
          "Poovathum Kari-Kallara",
          "Karikadu View Point, Vagamon",
          "Kottayam--Erayilkadavu Bypass Road",
          "Chempinthuruthu",
          "Kanjirapally--Parathode",
          "Kuttanad Wetlands--Ezhumaanthuruthu",
          "Vembanad Wetlands--Kumarakom Paddy fields",
          "Kuttanad Wetlands--Kuttompuram Bridge",
          "Kottayam--Kottayam Railway station",
          "Aanakkallu--Kuzhimavu [39.23.5]",
          "Vembanad Lake--Kollaam-Kottappuram Waterway",
          "edakkunnam",
          "Idukki--Ilaveezha Poonchira",
          "kanthari kadavu ayamkudi",
          "Kallara Paravanthuruthu Raptor Point",
          "Kuzhithadam Paddy Fields, Parackal Kadavu",
          "Kottayam--Private Bus Stand",
          "Fire Station Road, Erattupetta",
          "Aarayiram Kayal paddy field",
          "Aanakkulam, Vandanpathal",
          "KOMBUKUTHY",
          "Kuttanad Wetlands--Kallara--Ayyanadu Puthenkari",
          "Kuruvamoozhy [40.19.8]",
          "Madukka-Kombukuthy Road",
          "St Jude Chapel",
          "Kumarakom--Cheepunkal",
          "Ayyampara",
          "Kottayam--Velloor--Near Pirvam Rd Railway Stn",
          "Kottayam--Brahmamangalam",
          "Kottayam--Neelamperoor--Thekkemaliyekkal",
          "Vembanad Wetlands--Thanneermukkam Bund",
          "Chanakakkulam",
          "Kumarakom",
          "Kottayam--Neelamperoor--Neendakara House",
          "Kuttanad Wetlands--Mariyamthuruthe",
          "Sasthankal Paddy fields",
          "SJCET, Palai",
          "Kuttanadu Wetlands--Kallara--Paravanthuruthu",
          "Pallom Pulinthara Temple Road",
          "Vembanad Wetlands --The Lake",
          "Kottayam--PWD Rest House",
          "Peruva Paddam",
          "Kottayam--Kuravilangad--Kozha Junction",
          "Vaikom--T V Puram",
          "Kottayam--Chettuthodu",
          "Kottayam--Veloor Paddy Fields",
          "Coconu Lagoon",
          "Munroe Light House Pallam",
          "Vaikom--Brahmamangalam LP School",
          "mukkali",
          "Kottayam--Karimpinthodu",
          "Kapickad-Mundar Road",
          "Kottayam--Adichira",
          "Kurinjikkavu Sacred Grove--Kurinji",
          "Vaikom--Moothedathukavu Road",
          "Kottayam--Aruvikuzhy Waterfalls",
          "Vellikulam Valley",
          "Kottayam--Puthuppally Church",
          "aravinda school",
          "Kilikulam, Mannam",
          "Vaikom--Adoration Convent",
          "mundupalam, pala",
          "Velichiyany",
          "Kottayam--Mattakkara Thuruthipalli Temple",
          "Kuttanad Wetlands--Kumarakom Bird Sanctuary",
          "Kottayam--Nehru Stadium",
          "Kottayam--Changanacherry Bye-pass road",
          "Kaippally [45.20.8]",
          "Pampavally",
          "Paipad",
          "Kottayam--Thalayazham--Koovam Road",
          "vaikom",
          "Kottayam--Thalayazham--Panachithuruthel Road",
          "Pallikkayal",
          "Kottayam--Kooroppada--Kaiyoori",
          "CMS College Kottayam (Autonomous)",
          "Vembanad Wetlands --Thollayiram Kayal",
          "Vandanpathal Forest Office",
          "Maniyaparambu",
          "Kuttanad wetlands--Sasthankal",
          "Kumarakom--Konchumada",
          "Koratty Old Church [41.20.4]",
          "Thambalakad--Thampalakadu Ponkunnam Road",
          "Ramapuram",
        ],
      },
      {
        district: "Pathanamthitta",
        localities: [
          "38.29.1 (ptr kochupampa)",
          "38.29.6 Kochupamba",
          "Achankovil Forest Division--Achankovil",
          "Achankovil Forest Division--Chittar",
          "Achankovil Forest Division--Devarmala",
          "Achankovil Forest Division--Kanayar",
          "Achankovil Forest Division--Kottavasal",
          "Achankovil Forest Division-Nedumkulam CS, Kallar Range",
          "Achankovil Forest Division--Toovalmala",
          "Achankovil Forest Divison--Pulikayam",
          "Adavi echo tourism",
          "Adoor",
          "Angamoozhi",
          "Aranmula -Nalkalikkal Wetland",
          "Azhutha--Sabarimala Traditional Trekking Path",
          "Believers Church Medical College Hospital",
          "Catholicate College, Pathanamthitta",
          "Chenneerkkara Vayal",
          "Chuttippara",
          "Collectorate Compound",
          "Elicode",
          "Ezhinjillam South",
          "GAVI DAM, GAVI, KERALA",
          "Gavi, Pattanamthita County, Kerala, IN",
          "Kakki - Goodrickal Forest",
          "Kakki Reservoir--Anathodu Flanking Dam",
          "Kalleli",
          "Kallidamkunnu, Sabarimala Traditional Trekking Path",
          "Karimpanakuzhy",
          "Karingali Puncha, Pandalam",
          "Kaviyoor puncha",
          "KILIVAYAL, ADOOR, PATHANAMTHITTA DISTRICT",
          "Konni IBA",
          "Konni IBA- Naduvathumoozhy Range",
          "Konni - Kalleli",
          "Konni- Kokkathodu",
          "Konni - Kummannur",
          "Konni - Paadam",
          "Kumbazha",
          "kuppannur puncha",
          "Laha",
          "Macfast College, Thiruvalla",
          "Mar Thoma College, Thiruvalla",
          "Mavara Puncha",
          "MEPRAL - THANANGADU COLONY",
          "Moozhikkal",
          "Moozhiyar - Goodrickal Forest Range",
          "Moozhiyar Top",
          "Mukkuzhy",
          "Naalumanikkattu, Kozhenchery",
          "Near Manjinakarra church,Omalloor",
          "Nedumonkavu",
          "Nilackal",
          "niranam",
          "Njalloor",
          "NSS College, Pandalam",
          "Omallor Wetlands",
          "Paadam Forest Station, Konni",
          "Pamba River, Aranmula",
          "Pandalam",
          "pannivelichira",
          "Pannivelichira, Aranmula Wetlands",
          "Periyar Tiger Reserve--Aanathodu",
          "Periyar Tiger Reserve--Ezhukamannu",
          "Periyar Tiger Reserve--Kalaradichan",
          "Periyar Tiger Reserve - Kochupamba",
          "Periyar Tiger Reserve--Meenar",
          "Periyar Tiger Reserve--Moozhickal",
          "Periyar Tiger Reserve--Pamba",
          "Periyar Tiger Reserve--Sabarimala",
          "Perumthenaruvi",
          "Perumthuruthy",
          "Plamthode area",
          "Plappally Forest Station",
          "Puthuval",
          "Ranni IBA- Vadasserikkara Forest",
          "Sabarimala_temple",
          "Seethathode",
          "St.Stephens college",
          "St. Thomas College Kozhenchery",
          "Subcell 32,18,4 Manneera",
          "Thiruvalla Railway Station",
          "Thiruvamanapuram-West Othera",
          "Thonnallur Ela",
          "Thoompakulam, Konni",
          "Ulanadu Chira and associated wetlands",
          "Ulanadu - Polachira",
          "Vadakkadathukavu Wetlands",
          "Vallikkodu Paddyfields",
          "vettuvelipadashekharam randampuncha",
        ],
      },
      {
        district: "Palakkad",
        localities: [
          "Kawa Forests",
          "Pattambi Railway Station",
          "Toothampara Forest Estate IB",
          "Padukutty River ",
          "Mundur IRTC (KBA Cell)",
          "Palakkad Hills - Kava-Right-side of Nightjar Valley.",
          "Elavanchery",
          "Karuvappara West",
          "Malampuzha -Eagle Owl Point",
          "Mukkai Rites Ground",
          "Arangottukkara",
          "Kadampazhipuram-Vengasseri Road",
          "Nemmara--Mannam(Nairthara Area)",
          "Para",
          "Muthuthala",
          "Pattanchery",
          "Silent Valley National Park--General Area",
          "Varagaliar Elephant Camp, Annamalai Wildlife Sanctuary",
          "Manthakkad",
          "DFO Office, Olavakode, Palakkad",
          "Kambalathara Vengalakkayam Reservoir",
          "Kallipara Camp shed- Paramikulam Tiger Reserve",
          "Sholaiyur, Agali Forest Range",
          "Palakkuzhi PCA1",
          "Meeraflores Estate",
          "Pallassana Parakkalam",
          "View Point (Near Ayyappan Temple)",
          "Plazhy",
          "Mangalamkunnu",
          "Silent Valley NP--Anavai - Hawlock",
          "Kozhinjamparambu Paddy Fields",
          "Elappully",
          "Walayar Motels Wetland (Historical)",
          "Pallathery",
          "Silent Valley NP--Poochippara towards Neelikkal",
          "Koottilakkadavu-Elambulasseri Road",
          "Vengalakayam Reservoir--East Side",
          "Veezhumala Hillocks, RF",
          "Pittupeedika",
          "Mangalam Dam Forest Station",
          "71,26,6 PKM-Anappady",
          "Vengalakayam Reservoir (10.667, 76.823)",
          "Mampad, Kizhakkencherry",
          "Peringodu-Pulapatta Road",
          "Pulpurumannam Paddy Fields.",
          "Nombikode Road",
          "Mannarkkad Forest Division--Mele Mulli towards Dabbakkadavu",
          "Palakkad Hills--Dhoni",
          "Attappady--General Area",
          "Mulli",
          "Malampuzha Reservoir",
          "Palakkad Fort",
          "KLDB Dhoni Farm Palakkad",
          "Brahmakulam Tank",
          "Chulliyar dam",
          "Parambikulam Tiger Reserve--Thekkady",
          "Nelliampathy Hills--82 Plantations",
          "Nelliampathy Hills--Karimala",
          "Chambrakulam, Nombikode",
          "Ottapalam Railway Station",
          "Nelliampathy Hills--Seetharkundu",
          "Peradikunnu, Pathiripala, Palakkad",
          "Mannarkkad Forest Division--Chundappatti towards Thazhe Mulli",
          "Thenidukku",
          "Vengodi-Ramassery Openlands",
          "Canal Road, Kadampazhipuram",
          "Malampuzha Reservoir (North)",
          "Thavalam, Attappady",
          "Mannannur",
          "Muthikulam(87,29,7)",
          "Cherottuveli Eri",
          "Astamgam Ayurveda Vidyapeedham, Vavanoor",
          "Mangottukavu temple",
          "Malampuzha Reservoir--Anakkal Forest Station Viewpoint",
          "Kallandichalla",
          "Olavakkod-Dhoni (KBA Cell)",
          "Walakkad Section Camp",
          "Pattambi",
          "Carmel CMI School, Kallippadam, Shoranur",
          "Malampuzha Reservoir (North) Secondary Forest  Area.",
          "Naragampalli river, Pudusseri",
          "Victoria Bus Terminus, Nelliyampathy",
          "Mannarkkad--Cherumkulam Road Checkpost",
          "Koodam(88,28,9)",
          "Mundoor junction",
          "Thenari Theerthampadam",
          "Parassery",
          "Parambikulam Tiger Reserve--Orukkombankutti",
          "Nightjar Valley (Road Side)",
          "Mattumala Hillock",
          "Alexandria Estate Sub-cell No.  71,22,5",
          "Kayyeni Nature Camp",
          "Karappara Road 1st Culvert",
          "Siruvani Reserve Forest--Kuravampady",
          "Merryland Resort Front View to stream",
          "Parambikulam-Anappady-Office complex",
          "Pudusseri South",
          "Kolakkode",
          "Anakkara",
          "Mathur East,  Palakkad",
          "Palakkad Fort East Yard Bridge",
          "Nelliampathy Hills--Rosary Estate",
          "Parambikulam Tiger Reserve--Peruvaripallam",
          "Manappully Bhagavathy/ Ayyappa temple",
          "Chengamkunnu River Bed",
          "Kalchadi",
          "Olavakkode Railway Station Road",
          "Kavasseri-I",
          "Jellippara Range",
          "Dhoni hills",
          "Mundaya",
          "Parambikulam Tiger Reserve- Pulikkal SO",
          "North of Kadampazhipuram Hospital Junction",
          "Thanneerpandal",
          "Paddy fields near Ilayabhagavathy temple, Kanjikode, Kerala, IN",
          "Pudanur Estate",
          "Poochipara Trek Path",
          "Palli junction, Tharoor",
          "Kava hillside (Nightjar valley), Malampuzha - (10.832, 76.728)",
          "Near Ullas Nagar",
          "Kottamala Ayyappaswamy Temple",
          "Nemmara Bye-Pass",
          "Pallippuram Railway Station",
          "Mangalam RF--General Area",
          "Silent Valley NP--Sispara",
          "Parambikulam Tiger Reserve--General Area",
          "Ullanoor",
          "Govt. Engg College, Mannambatta, Sreekrishnapuram",
          "Karakkad Railway Station",
          "Silent Valley NP--Walakkad towards Poochippara",
          "Marutharoad-Kasaba",
          "Kottathara Hillocks",
          "Kakkupathi Pirivu,  Goolikadavu",
          "Vattapara, Walayar",
          "Verkoli",
          "Kallukoottiyal",
          "Muthalamada",
          "Kaikatty Halfway",
          "Peringottukurissi--Paddy Fields",
          "Kollengode Forest Range Office",
          "Seetharkundu Waterfalls",
          "Nelliampathy Hills--Victoria",
          "Kombidithirivu",
          "Palakkad Forest Division--Chavadippara towards Parappatti",
          "Chandranagar Colony",
          "Koduvayur-Thrippalur Road (KBA Cell)",
          "Kadampazhipuram Junction",
          "Mundur",
          "Thuthampara Estate",
          "Malampuzha Left RR-Stream Cross Point 1",
          "Palakkad Hills--Elival Foothills",
          "Anakkappara",
          "Ahalia",
          "Thirumullapully Mahadeva Temple--Karalmanna",
          "Neithala East",
          "Kinfra industrial park Lake,Kanjikode",
          "Chithali palam",
          "Dhonigundu",
          "Thassarak,  Palakkad",
          "Nelliampathy Hills--KFRI Seed Station",
          "Nelliampathy Hills--Pothundu Estate",
          "Arikkadu",
          "Mangalam palam",
          "Puzhakkalthara Road, Nenmara, Kerala, IN (10.588, 76.594)",
          "Malampuzha Reservoir (North side)",
          "Athippotta junction",
          "Amayoor",
          "Rayiranalloor",
          "Vadanamkurussi",
          "Meenkara Reservoir",
          "Gopalakrishna Swamy Temple Pond, Pallanchathanur",
          "Thuthampara Estate (Cell No.  71.23.2)",
          "Sreekrishnapuram Central School",
          "Cheriya Walakkad-Kunthi River",
          "Padagiri, Nelliyampathy",
          "Ravanakkunnu Para, Valara",
          "Pattiyar (88,29,6)",
          "Yakkara, Palakkad",
          "Polyclinic Junction",
          "Ozhalapathy",
          "Anamooly",
          "Kava (Malampuzha) Nightjar Valley Right Side.",
          "1st Stream Birding Patch, Ring road, Malampuzha",
          "Parambikulam Tiger Reserve--Thellikkal",
          "Chulanur Peafowl Sanctuary (Entrance)",
          "Centre for Harmonious Living, Kumbidi",
          "Mathur, palakkad",
          "Chulliyar Reservoir",
          "Chandranagar Bye-pass beginning",
          "Pudussery",
          "Siruvani Reserve Forest--Karimala",
          "Poodur-Palakkad (KBA Cell)",
          "Neytharakode",
          "Churupara--Mangalam Dam RF",
          "Silent Valley NP--Neelikkal",
          "Thirunellai Bridge",
          "Rock Garden, Malampuzha",
          "Parambikulam Tiger Reserve--Muthuvarachal",
          "Malampuzha Reservoir (West)",
          "Koottupatha BPL Factory",
          "Anaikatti - Sholayoor Rd",
          "Kolluparambu (Verkoli)",
          "Nelliampathy Hills--Golden Valley Estate",
          "Fig Tree in front of Hemambika Temple, Malampuzha.",
          "Silent Valley-Panthanthode stream",
          "Pannimada: Kanjikode-Malampuzha Road",
          "Parambikulam Tiger Reserve- HQ-Anappadi",
          "Nelliampathy Hills--Kaikatty",
          "Venthapalayam",
          "malampuzha2",
          "Eduppukulam Tank",
          "Malampuzha Spill-way Bridge Roadside",
          "Local Road, Nhangattiri, Kerala, IN (10.793, 76.181)",
          "Parambikulam Tiger Reserve--Thunakkadavu",
          "Selvapalayam",
          "Puliyappatta kayal, Koottanad",
          "Silent Valley NP--Punnamala",
          "Vijnanaramaneeyam, Sri Ramanasramam-Palakkad",
          "Mannarkkad Forest Division--Muthikulam towards Singappara",
          "Meera Floor Estates",
          "Chulannur",
          "Alayar East",
          "Cheriya Kutrippallam",
          "Chembalod wetlands",
          "Orukomban Camp Shed",
          "Holy Trinity School Kanjikode",
          "Parambikulam Tiger Reserve--Parambikulam",
          "Ottapalam",
          "Vandithode-Paddico",
          "Thodukappu Kunnu Eco Tourism",
          "Ezhakkad, Kongad, Palakkad",
          "Sholayur",
          "Kalmandapam Paddyfields",
          "Nehru Colony",
          "Silent Valley NP--Walakkad",
          "Meenvallam Waterfalls",
          "Chadanamkurissi - Thirunellayi road",
          "Kalladikode-Sreekrishnapuram Road",
          "Agali",
          "Silent Valley-Mukkali Road",
          "Siruvani Reserve Forest--Pattiyar",
          "New Colony, Kalmandapam",
          "Nelliampathy Hills--Thoothanpara Estate",
          "Nelliampathy--Minnampara Forests",
          "Ramasseri Sivan Kovil Pond, Elappully",
          "Karikatti Meenvallom",
          "Malampuzha Reservoir (North)  Secondary Forest -Breakfast point.",
          "Sai Junction Heronry",
          "Palakkad Junction Railway Station",
          "Kongakulam Pond,Nombikode",
          "Mannanur Railway station",
          "Puduppariyaram",
          "Paalanamkurissi Lotus Pond",
          "Nelliampathy Hills--Victoria road",
          "SVNP-Mukkali Road",
          "Nellimedu Colony",
          "Munsiff Challa",
          "Bharathappuzha River check dam",
          "Siruvani Reserve Forest--General Area",
          "Chembana Hillocks, Malampuzha",
          "Parambikulam Tiger Reserve--Bagapallam",
          "Melapunchapadam-Kattukulam Road",
          "Vallapuzha",
          "Nemmara--Ayyappankunnu",
          "Palakkad Forest Division--Kanjikkadavu towards Palamala",
          "Noorady",
          "Muringamala Hillock, Chitalipalam, Kerala, IN (10.68, 76.595)",
          "Nelliyampathy Orange Farm",
          "Meenkara Reservoir.1",
          "Pazhambalakode jn",
          "Chokkanatha Puram, Palakkad",
          "Kadalakurishi, Palakkad",
          "Thuppalangode Paddy Fields, Palakkad, Kerala",
          "Silent Valley NP - Karuvara",
          "Shokanashini Riverine Wetland",
          "Meenkara II",
          "Choolanur Peafowl Sanctuary",
          "Walayar Reserve Forest",
          "Kalluvazhi-Karinchetti Road",
          "Komangalam wetland and adjacent woody zone, Koottanad",
          "Chirankara pond",
          "Silent Valley NP--Koomban",
          "Pallassana, Palakkad",
          "Chulliyar Dam, Vellaramkadavu Entry, Kerala, IN (10.589, 76.763)",
          "Pattambi Guruvayoor Temple",
          "Puthunagaram town",
          "Pothundi gardens",
          "Meenkara Dam Dual Shutters",
          "R V Pudur East",
          "Nutmeg Valley, Mailampully, Puthuppariyaram, Palakkad",
          "RARS, KAU, Pattambi",
          "Silent Valley NP--Panthamthode towrds Mukkali",
          "Anakkal-Malampuzha Range",
          "IRTC Mundoor, Palakkad",
          "Valara Kulam",
          "Kandancode Road,  Kodumbu-Olassery Road",
          "Ecocity, Kousapara, Kanjikode, Palakkad",
          "Thekkady Forest Station",
          "Puthiya Road",
          "Nelliampathy Hills--Lower Nelliampathy",
          "Nelliampathy Hills--Pothumala",
          "Elivalmala",
          "Anakkal Vayal",
          "Nelliyampathy Ghat 2nd Bend from Pothundy Dam",
          "Ayalamudi",
          "Palghat Lions School",
          "Ananganadi",
          "Mucheery Road - Keralassery",
          "Pezhumkara (Mepparamba Kavilpad Bye Pass Road)",
          "Fig Tree Spot-Nombicode Road",
          "Thenkurisi Paddy Fields, Palakkad",
          "Nursing Home, Pattambi",
          "Nelliampathy Hills--Pakuthippalam",
          "Silent Valley NP--Poonchola",
          "Cheerath Eri, Eduppukulam, Elappully, Palakkad, Kerala, IN (10.781, 76.801)",
          "Thenkurissi-Vakkathara (KBA Cell)",
          "Annanthode Palayampallam",
          "Kottassery South",
          "Lakkidi Railway Station",
          "Kuruvattoor",
          "Nooradi Brookland Road",
          "Siruvani Reserve Forest--Muthikulam",
          "Parali Kadavath",
          "Silent Valley, Chindakki-Treetop Resort",
          "Akathethara",
          "Maranatty Waterfalls FootHills, Near Goolikadavu Junction",
          "K.P. Estate",
          "Chillikkal Thodu(Kongad)",
          "College of Avian Sciences and Management Campus , Kerala",
          "Rosefinch Valley-Nelliyampathy Ghats",
          "Ponnamkode-Koottilakkadavu Road",
          "Railway Park, Institute Road",
          "Karalmanna",
          "Thavalam, Mulli Road",
          "Karkidamkunnu--Kara",
          "Kumaranellur",
          "Veliyampallam Road wih Pottakkulam",
          "Ayyappanpara Temple, Vellarimedu, Payyalore, Palakkad, IN (10.57, 76.682)",
          "Vengassery",
          "Seethargundu-car parking area",
          "Killikurissimangalam",
          "Mathur",
          "Mannarkkad--Cherumkulam",
          "Puthur NH Junction",
          "Paddy Fields, Alangad, Palakkad",
          "Bypass Junction to Bus stand, Vadakkemcherry",
          "Neranganpara hillocks, Muppuzha road, Palakkad, Kerala, IN (10.775, 76.654)",
          "Parali Railway Station",
          "Pittupeedika, Koduvayur",
          "Silent Valley NP--Sairandhri towards Punnamala",
          "Shoranur Railway Station",
          "Arogyampadavu Lake",
          "Peringodu-Pulapatta Road Junction",
          "Karimkulam Tank",
          "Choorakkode palam",
          "Nelliyampathy Hills--Road to Thookupalam",
          "Thathengalam,Mannarkkad",
          "Bodichala-Sholayur Road, Kerala",
          "Silent Valley NP--Koombanmala",
          "Manakkad mariyamman Kovil, Padoor",
          "Siruvani Reserve Forest--Siruvani Dam",
          "Karippode Thara",
          "Parambikulam Tiger Reserve--Anapaddy",
          "Silent Valley National Park--Mukkali",
          "Thrithala",
          "Koottupatha",
          "Paehathuruthu Nallepilly",
          "Kalkandy - Bhavani River Bank",
          "Tattamangalam Pond",
          "Sunrise rocks, Malampuzha",
          "Vallappuzha Railway Gate",
          "Mannarkkad Forest Division--Bommiyampathi towards Doddagatti Colony",
          "Mannarkkad Forest Division--Puthoor Forest Station - Mallikathottam",
          "Mangalam Reservoir",
          "Kadambazhippuram",
          "Chembana, Kava (Malampuzha) Water Front.",
          "Arangottukara heronry",
          "Malampuzha canal Chandranagar Bridge",
          "Menna Nagar Colony, 20/262",
          "Parambikulam Tiger Reserve- Tramway - Pulikkal SO",
          "Karashoori Temple",
          "Puthur",
          "Nelliampathy Hills--Nelliampathy Hill Top",
          "Thennilapuram, Alathur, Palakkad",
          "1st river feeding Malampuzha dam, Kava",
          "Pothundy Dam Above Bend",
          "Kalpathy New Bridge",
          "Padukutty",
          "Meenakshipuram",
          "Kakkayur II",
          "Muthukkurissy",
          "Karippodu junction",
          "Nelliampathy Hills--Mattumala",
          "Karappara Bus Waiting Shed",
          "Jellippara",
          "Eruthenpathy Road",
          "Perumudiyoor School Woods",
          "Nelliampathy Hills",
          "Kunnam Pitari Reservoir",
          "Thiruvizhamkunnu",
          "Government College, Chittur",
          "Chulliyar Dam, Vellaramkadavu Mariamman Temple Entry, IN (10.582, 76.77)",
          "Edathara, Palakkad",
          "Elival Entry point",
          "Thenkurussi Paddyfields",
          "Service Co-operative Bank, Pengattiri",
          "Kulamukku Kaayal",
          "Kalladikkodan Foothills, Puthuppariyaram, Near Kottakkundu falls",
          "Palakkad KSRTC Bus Stand",
          "Koottilakadavu Bridge",
          "Varagampadi",
          "Othalur",
          "Kalmandapam",
          "Siruvani Reserve Forest--Singappara",
          "Vaadapparambu, Thirunellayi",
          "North-West of Vakkada",
          "Parambikulam Tiger Reserve--Poopara",
          "Kaatadikkunnu,Cheekuzhi Road, Akathethara, Kerala, IN",
          "Silent Valley NP--Neelikkal Hut",
          "Kottassery Paddyfields",
          "Kadamkkode",
          "Parambikulam Tiger Reserve--Kuriyarkutty",
          "Malampuzha Gardens",
          "Karimpuzha Bridge",
          "Pattambi Town",
          "Sri Ramanasramam",
          "Kalluvazhi-Karinchetti Road PaddyFields",
          "Kalmandapam Paddy Fields",
          "Poochippara 93,22,7 First cell4/4",
          "Mankara Railway Station",
          "Kerala Medu-Siruvani Forest",
          "River Bharathappuzha, Kerala (OBI)",
          "Silent Valley NP--Poochippara towards Sairandhri",
          "Ahalia Campus--Eye Hospital and nearby pond",
          "Railway Coach Factory Site, Kanjikode",
          "Kuruvanpady",
          "Nelliampathy Reserve Forest--Karappara",
          "Kollengode-Kunissery Road (KBA Cell)",
          "Kazhani Chungam junction",
          "Silent Valley NP--Poochappara",
          "Poothani-Perumkulam  Tank",
          "Mannarkkad Forest Division--Mutikulam - Elivaal",
          "Kozhiyamala,  Chembana, Kava, Malampuzha",
          "Kanjikode West Post Office Road",
          "Mannathu Kavu",
          "Kacheriparambu",
          "Kinarpallam",
          "Kallukoottiyal, Polpully",
          "Pudusseri (KBA Cell)",
          "Kava (Chembana )  Rock Point.",
          "Koduvayoor- Pallassana road",
          "Deer park walayar",
          "Agali - Sambarcode",
          "Nelliampathy--Karappara",
          "Walayar Reservoir",
          "Mepparamba Paddy Fields",
          "Thanisseri, Palakkad",
          "Walayar Reserve Forest--MCL Mines",
          "Manalaroo Estate Lily Division",
          "Manali",
          "Kaila's Forest Resort",
          "Adichira",
          "Kannadi",
          "Ahalia Campus--Pond near IIT hostel",
          "Salamath Nagar, Tank",
          "Punchapadam",
          "Komangalam hill area, Koottanad",
          "Kalyanapetta",
          "Silent valley Mukkali gate",
          "Pulayanpara, Nelliampathy",
          "Kesavan para, Near AVT Estate",
          "Koduvayoor--Palakkad Road",
          "Maruthi Bhagavathy temple",
          "Nerangampara Hillocks",
          "Pakuthippalam KFDC Estate",
          "Malampuzha Reservoir - Palmyra Point",
          "Kizhakkethala, near Koymor padam",
          "Shiva Kshethram",
          "Pallimedu",
          "Churupara--Mangalam RF",
          "Parakkulam hill",
          "Malampuzha Main Canal Paddyfields (Historical?)",
          "Chaalaayi-Playampallam",
          "Thottuvaya Siva Temple Road",
          "Malampuzha-Incomplete Bridge Area",
          "Mattumala",
          "Paramibikulam Tiger Reserve--Parambikulam Reservoir",
          "Kumbidi, Barathapuzha",
          "Karkkidamkunnu",
          "Vannamada",
          "71,27,1 PKM-Anappady",
          "Kakkayur",
          "Kava (Young Birders' Club) spot - right hand side",
          "Thirunellai",
          "Adakkaputhur",
          "Puliyankavu Temple, Karingarapully, Kodumbu",
          "Mukkali Mathruka Forest Station",
          "Ashtamgam Ayurveda Chikitsalayam & Vidyapeedham",
          "Palappuram Railway Station",
          "Pervampu",
          "Kanjikode Railway Station North",
          "Pooncode",
          "Kanjikode V.V. College Area",
          "Pappadappara",
          "Ponnamkode-Koottilakkadavu-Elambulasseri Roads Junction",
          "Chambrakulam Tank",
          "Mannarkkad Forest Division--Singappara towards Koodam",
          "KSEB Walayar 110 KV Sub Station Road",
          "Kodikkunnu",
          "Padikkal padam",
          "Karimpuzha Co-Operative Urban",
          "Pambampallam-BEML",
          "Parambikulam TR - Koochimudi Camp Shed",
          "Pattikkayal,Thrithala",
          "Kattukulam Road",
          "Kunnamkattupathy bridge",
          "Mudavannur Sivakshethram",
          "Siruvani Reserve Forest--Koodam",
          "Srambi",
          "Pulayampara KSEB Sub Station",
          "Palakkad Forest Division--Elival towards Paalamala",
          "Bharathapuzha ,Velliyankallu , Thrithala",
          "Para (KBA Cell)",
          "Silent Valley NP--Sairandhry",
          "Koottanad",
          "Thrippalamunda Mahadeva Temple Road",
          "Nelliyampathy Ghat Road",
          "Malampuzha SW",
          "Mangottukavu temple, Tarur",
          "Victoria,  Nelliyampathy  Hills",
          "Kuzhalmannam (KBA Cell)",
          "Palakkad Hills--Kava",
          "Pothundy Reservoir",
          "Anamada Estate",
          "Kava, Malampuzha, Palakkad",
          "Malampuzha Ring Road (Left hand side)",
          "Elambulasseri",
          "Nelliampathy Hills--General Area",
          "Silent Valley NP--Anavai towards Thodukki",
          "Kava Water Front Hills,  Malampuzha",
          "Kanjirapuzha Reservoir",
          "Bharathapuzha, Pattambi",
          "Paalana",
          "Regional Agricultural Research Station, Pattambi",
          "Nelliyampathy Forest Reserve",
          "Kunnamkattupathy",
          "Siruvani - Forest Camp/Staion",
        ],
      },
      {
        district: "Kollam",
        localities: [
          "Shendurney WLS--Forest Information Centre, Kattilappara Station",
          "Thundil kavu",
          "Anayadi",
          "Tenmala--Ecotourism Promotion Society.",
          "Avaneeswaram railway station",
          "Malamel Rock",
          "Ottakal lookout",
          "St. Gregorius College, Kottarakara",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â°Ã ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Aylara",
          "Ashtamudi--Kureepuzha Bridge",
          "Shendurney WLS--Dharbakulam",
          "Ashtamudi--General Area",
          "Pavubma-Thodiyoor Bridge Road, Pavumba, Kerala, IN",
          "Vayyanam Grama Panchayath Office",
          "Polachira--Malayankulam Bridge",
          "Suradhenupura Kere (Ã Â²Â¸Ã Â³ÂÃ Â²Â°Ã Â²Â¦Ã Â³â€¡Ã Â²Â¨Ã Â²ÂªÃ Â³ÂÃ Â²Â° Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )¾Ã Â´Â²Ã Â´â€š",
          "KSRTC Bus Station, Karunagappally",
          "Arabian Sea: 9.0N 76.4E",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Å¸Ã ÂµÂ Ã Â´ÂªÃ Â´Â¾Ã Â´Â²Ã Â´â€š",
          "Kattilappara--Reservoir Edge",
          "Tenmala--Wildlife Warden Office to Deer Park",
          "Chitra, Ashtamudi backwaters",
          "Shendurney WLS--Idimuzhangan Para",
          "Perumpuzha-Dullu",
          "Tenmala--Opposite Deer Park",
          "Thodiyoor",
          "Chittumala Chira, Kollam, Kerala",
          "Palaravi",
          "Kattilkadavu Backwaters",
          "Guhanandapuram Temple",
          "Idimuzhangan Camp Shed",
          "Chemmana Wetlands",
          "Paravur Rail Bridge",
          "Palaruvi Waterfalls",
          "Koshnakkavu",
          "Mar Ivanios Bethany School",
          "Shendurney WLS--General Area",
          "Mampazhathara",
          "Arabian Sea: 8.8N 76.5E",
          "Ochira south",
          "Arabian Sea: 8.9N 76.4E",
          "kottiyam",
          "Kalkeri Forest Ã Â²â€¢Ã Â²Â²Ã Â²â€¢Ã Â³â€¡Ã Â²Â°Ã Â²Â¿ Ã Â²â€¦Ã Â²Â°Ã Â²Â£Ã Â³ÂÃ Â²Â¯Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Shendurny Wildlife Sanctuary--Pandimotta",
          "Thenmala",
          "Fatima College, Kollam",
          "ThurayilKadavu Wetlands",
          "Tenmala--Dam Entrance",
          "Mayyanad Beach",
          "Shendurney WLS--Kattilappara Picket Station to Kallar Road",
          "Ayilara",
          "Kollam railway station",
          "Ashtamudi--Karali Wetlands",
          "Thenmala, Look Out.",
          "Pozhikkara",
          "Pavumba Bridge_Bund Road (Ã Â´ÂªÃ Â´Â¾Ã Â´ÂµÃ ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Â´Â¾ Ã Â´ÂªÃ Â´Â¾Ã Â´Â²Ã Â´â€š_Ã Â´Â¬Ã Â´Â£Ã ÂµÂÃ Â´Å¸Ã ÂµÂ Ã Â´Â±Ã Âµâ€¹Ã Â´Â¡Ã ÂµÂ)",
          "Tenmala--Deer Park Road",
          "Apooppan Kavu",
          "Paravur Lake",
          "Ashtamudi--Munroe Island",
          "Arabian Sea: 9.0N 76.5E",
          "Ayyenkoikkal Temple",
          "Shendurney WLS--Neduvannoorkadavu Kattalappara road",
          "Punalur Suspension Bridge",
          "Arabian Sea: 8.8N 76.2E",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â¾ Ã Â²â€¢Ã Â³â€ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Karunagappally Railway Station",
          "Kandachira",
          "Arabian Sea: 8.9N 76.5E",
          "Konni Forest Division--Mannarappaara",
          "Tenmala--Deer Park Entrance Gate",
          "Vallikkavu Wetlands",
          "Mayyanad Paddy Field",
          "Ambanadu- Anachadi road",
          "St Stephen's College, Maloor",
          "Munroethututhu Railway Station",
          "muttathumannel",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Arabian Sea: 8.9N 76.3E",
          "Zed Earth (Ã Â²Å“Ã Â²Â¼Ã Â³â€ Ã Â²Â¡Ã Â³Â Ã Â²â€¦Ã Â²Â°Ã Â³ÂÃ Â²Â¥Ã Â³Â)Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Chelithod Palam, Mannoorkavu | Ã Â´Å¡Ã Âµâ€ Ã Â´Â³Ã Â´Â¿Ã Â´Â¤Ã ÂµÂÃ Â´Â¤Ã Âµâ€¹Ã Â´Å¸Ã ÂµÂ Ã Â´ÂªÃ Â´Â¾Ã Â´Â²Ã Â´â€š",
          "Manappally West",
          "Fragrant Nature Paravur",
          "pozhikkara",
          "Hatgunda Lake Ã Â²Â¹Ã Â²Â¤Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¦ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Amrita University",
          "Mangalath Compound",
          "Shendurney Wildlife Sanctuary--Kattilappara",
          "Chungathara",
          "Madannada Wayal Mynagappally_Ã Â´Â®Ã Â´Â¾Ã Â´Å¸Ã ÂµÂ»Ã Â´Â¨Ã Â´Å¸ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ Ã Â´Â®Ã ÂµË†Ã Â´Â¨Ã Â´Â¾Ã Â´â€”Ã Â´ÂªÃ ÂµÂÃ Â´ÂªÃ Â´Â³Ã ÂµÂÃ Â´Â³Ã Â´Â¿",
          "Pathimoonu Kannara Bridge",
          "Neduvannoorkadavu--Tenmala Road",
          "Vellanathuruthu",
          "Shendurney WLS--Kattilappara-Reservoir Trek Path",
          "Fatima Mata National College (FMNC)",
          "SN College, Kollam",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP",
          "Konni Forest Division--Thura",
          "Tenmala--Wildlife Warden Office",
          "Arabian Sea: 8.8N 76.1E",
          "Shendurney WLS--Umayar",
          "Kulappadam",
          "Kollam Olayil Boat Jetty",
          "Punalur Railway Station",
          "Elamkulam Temple",
          "Malavalli Kere Ã Â²Â®Ã Â²Â³Ã Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Polachira",
          "SN College",
          "Needhipuram Lake Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Umayanalloor Ela",
          "Kadakkal",
          "Ashtamudi--Kandachira",
          "Achankovil Forest Division--Priya Estate",
          "Tadimalangi  Ã Â²Â¤Ã Â²Â¡Ã Â²Â¿Ã Â²Â®Ã Â²Â¾Ã Â²Â²Ã Â²â€šÃ Â²â€”Ã Â²Â¿",
          "Konni Forest Division--Mannarappara",
          "Alumpeedika",
          "Shantiniketan International School, Nilamel",
          "Pandithitta",
          "Markalu Kere Ã Â²Â®Ã Â²Â¾Ã Â²Â°Ã Â³ÂÃ Â²â€¢Ã Â²Â²Ã Â³Â  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯Ã ÂµÂ½ )",
          "Ashtamudi--Thekkumbhagam",
          "Benapatti cattle trailÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Achankovil, Sri Dharmasastha Temple",
          "Kottukal Cave Temple",
          "Mathra, Punalur",
          "Malumel bund road",
          "Kuttikkattu Sree Bhadra Devi Temple",
          "Vavvakkavu East",
          "Ashramam",
          "Kottarakara",
          "Perinad Railway Station",
          "Schendurney WLS--Kattilapara",
          "Sasthamcotta Lake",
          "Manchalloor",
          "Arabian Sea: 8.7N 76.5E",
          "Arippa - Edapana Forest patch",
          "Poruvazhy Peruviruthy Malanada Temple",
          "NSS College Ground, Nilamel",
          "AchanKovil Road Forest",
          "Panikkarkadavu",
          "Puthiyakavu - Sanghapura Mukku -Kattilkadavu Road, Karunagappally, Kerala, IN",
          "Arabian Sea: 8.9N 76.2E",
          "Tenmala--Assistant Wildlife Warden Office",
          "Arippa",
          "Thangassery",
          "Ayyenkoikkal Temple. (Original) Previous Hotspot Is Few Hundred Meters Away From This Place.",
          "Arabian Sea: 8.8N 76.6E",
          "Lal Bahadur Stadium",
        ],
      },
      {
        district: "Kozhikode",
        localities: [
          "Aavalapandi [108,7,3]",
          "ACHILMALA HILLS, KAVUMTHARA",
          "Akalapuzha",
          "Anakkampoyil",
          "Anakkuzhikkara",
          "Annassery",
          "Arabian Sea: 11.2N 75.8E",
          "Arabian Sea: 11:5N 75.5E",
          "Arambram",
          "Arayidathupalam",
          "Auto selected",
          "Avala",
          "Avani Institute of Design, Thamarassery",
          "Balussery",
          "Beypore Beach",
          "Chakkittapara",
          "Chaliyam",
          "Chathanadath Kadavu",
          "Chelapram",
          "Chelavur Sacred Grove",
          "Chembanoda",
          "Cherandathoor",
          "Cherandathur",
          "Cheruvennur",
          "Chevarambalam",
          "Chevayur,  calicut",
          "Chullikkaparamba",
          "College of engineering, Vadakara",
          "Craft Village",
          "East nadakkavu",
          "Eduthuvacha Paara (Narikode)",
          "Elathur",
          "Elathuruthupandy",
          "Eleyembra Hills",
          "Eranhippalam",
          "Feroke",
          "Feroke bridge",
          "Government Medical College Campus, Kozhikode",
          "Govt. Arts & Science College",
          "Govt. College Kodanchery",
          "Housing Board Colony, GMC Kozhikode",
          "IPM",
          "JNV Calict, Maniyur",
          "Kadiyangad",
          "Kakkadampoyil--Karimbu Road",
          "Kakkayam",
          "Kakkayam Ghat Road",
          "Kakkur",
          "Kallayi Estuary",
          "Kambilipadam",
          "Kandamchira",
          "Kannur Forest Division--Narikkodu",
          "Kappad Beach",
          "Kappad--Thuvappara",
          "Kappal Chali",
          "Kariyathan Kavu, Padinhattumuri, Kerala, IN (11.328, 75.817)",
          "Kariyathumpara (11.536, 75.887)",
          "Kayyelikal, Thamarassery",
          "Koduvally",
          "Kolavi Beach",
          "Koothali",
          "Koovapoyil",
          "Korapuzha Estuary",
          "Kottappalli",
          "Kottappuzha Estuary",
          "Kottooli Wetlands",
          "Koyilandy Railway Station",
          "Kozhikode Beach",
          "Kozhikode Forest Division--Anakkampoil",
          "Kozhikode Forest Division--Janakikkad",
          "Kozhikode Forest Division--Kakkadampoil",
          "Kozhikode Forest Division--Kakkad teak road Sindicate",
          "Kozhikode Forest Division--Maruthilavu",
          "Kozhikode Forest Division--Pannikkotoor Vayal - Undammoola",
          "Kozhikode Forest Division--Seethappara - Karinkanni",
          "Kozhikode Forest Division--Thusharagiri",
          "Kozhikode railway station",
          "Kozhikode Railway Station",
          "Kunduparambu-Eranjikal",
          "Kuniyilkadavu, Atholi",
          "Kuttikkattoor",
          "Kuttyadi",
          "Lokanarkavu Temple",
          "Maanamkunnu",
          "Madoppani Kunnu",
          "Mailadippara",
          "Makkoottam",
          "Malabar Medical College, Modakkallur, kozhikode Kerala",
          "Malabar WLS--Ampalappara",
          "Malabar WLS--Changarampuzha",
          "Malabar WLS--Choothupara",
          "Malabar WLS--General Area",
          "Malabar WLS--Kakkayam Dam - Devayani Estate",
          "Malabar WLS--Kakkayam Dormitory and Checkpost",
          "Malabar WLS--Kakkayam Dormitory to Urakkuzhi/Dam site",
          "Malabar WLS--Kakkayam Reservoir",
          "Malabar WLS--Raveendra--Transact 1",
          "Malabar WLS--Raveendra Transact 2",
          "Malabar WLS--Sankaranpuzha",
          "Malabar WLS--Surge Road",
          "Mandamala",
          "Mandayad",
          "maruthonkar Chempanoda road",
          "Mavoor, Mananthalakkadavu",
          "Mavoor Wetlands",
          "Meenchanda",
          "Moopan Kuzhi",
          "Mullankunnu",
          "Muthuvannacha",
          "Naduvannur",
          "Nanminda, Kunnatheru",
          "nellikkunnu",
          "Nirakaithakotta",
          "NIT Calicut",
          "Nochat Higher Secondary School",
          "North Wayanad FD--Kuttyadi",
          "Olavanna Botanical Garden",
          "Palery",
          "Pallikkara",
          "Pannikkottoor",
          "Pannimukku, Avala",
          "Pantheerankave HSS Ground",
          "Panthirikkara",
          "PARAMBINKAD HILLS, Kavumthara,Kozhikode, IN-KL",
          "Payamkutti Mala",
          "Payyoli Railway Station",
          "Perambra",
          "Peruvannamoozhi",
          "Peruvannamoozhi Reservoir",
          "Ponkunnu Hill, Kakkur",
          "Poovattuparamba",
          "Pulathuruthupandy",
          "Puliyottumukku",
          "Puthiyappa",
          "Ramanattukara",
          "Sanketham Wetlands",
          "Sarovaram Biopark, Kottoooli",
          "Shri Ayyappan Kavu, Poovangal",
          "Stone quarry, Kaiveli",
          "Thamarassery",
          "Thamarassery Churam",
          "Thekkeppuram",
          "Thengilakkadavu",
          "Thikkodi Beach",
          "Thikkoti Railway Station",
          "Thorayi Kadavu",
          "Ulliyeri, Kozhikode",
          "Unnikulam--Thechi",
          "Vadakara town",
          "Valook",
          "Vanaparvam",
          "Vanaparvam biodiversity park",
          "Vavul Mala [102.20.9]",
          "veliiyamkallu",
          "Vellarimala",
          "Vilangad",
          "Walkway, MahÃƒÂ©",
          "Westhill",
          "Zamorin's Guruvayurappan College",
        ],
      },
    ],
  },
  {
    state: "Ladakh",
    districts: [
      {
        district: "Kargil",
        localities: [
          "Baroo Tsok",
          "Chadar Trek",
          "Damsna Village",
          "Drass, J&K (OBI)",
          "Garkhon Phu",
          "Guntung, Kargil",
          "Kargil",
          "Kargil War Memorial",
          "Kharbu",
          "Mulbekh",
          "Mushko valley",
          "Panikhar Village",
          "Parkachik Village",
          "Rangdum",
          "Sangrah Thang, Suru valley",
          "Sani Village",
          "Sany wetland",
          "Sapi La",
          "Yunnan River",
        ],
      },
      {
        district: "Leh",
        localities: [
          "Akher Valley",
          "Alchi",
          "Basgo",
          "Blind Lake, Shigar",
          "Bogdang",
          "Bogdan village",
          "Borit Lake",
          "Chagar Tso",
          "Chalungkha, Nubra, Ladakh",
          "Changla Pass",
          "Changthang Cold Desert WLS",
          "Chhamugarh",
          "Choglamsar - Indus belt",
          "Choglamsar--Mahabodhi",
          "Choglamsar Village",
          "Choglamsar--Zampa",
          "Chuchot",
          "Chuchot Bridge area",
          "Chumathang",
          "Chumathang Hot Spring",
          "Chushot, Indus belt, Ladakh, India",
          "Chushot Shamma",
          "Chushot Yokma",
          "Chushul",
          "Dar",
          "Deosai National Park",
          "Diskit",
          "DISKIT",
          "Diskit Monastery",
          "Durbuk",
          "Eagle's Nest, Hunza Valley",
          "Enroute to Tso Kar (OBI)",
          "Fairy Meadows NP",
          "Gata Loops",
          "Geik",
          "Gilgit",
          "Gilgit--General Area",
          "Gulmit",
          "Guphuks Pond, Spituk",
          "Gurudwara Pathar Sahib",
          "Gya",
          "Hanle",
          "Hanle village",
          "Hanupatta",
          "Haramosh",
          "Harong Wetland II",
          "Harong Wetland (OBI)",
          "Hemis Gompa",
          "Hemis National Park--Hankar",
          "Hemis National Park--Skiu",
          "Hemis NP--Hankar to Nimaling",
          "Hemis NP--Markha Valley",
          "Hemis NP--Nimaling",
          "Hemis NP (please use more precise location)",
          "Hemis-Shukpachan",
          "Hemya--Rong Valley",
          "Himya",
          "Hopar Glacier",
          "Hunder",
          "Hussaini",
          "Kalaktartar",
          "Kargah Nulla",
          "Kargil-Leh Road",
          "kargyam",
          "Karimabad/Gilgit",
          "Karu, Leh-Manali highway",
          "Karzok",
          "Karzu Pond--Leh",
          "Khalsar",
          "Khalsi",
          "Kharddong village",
          "Khardong village",
          "Khardung La",
          "Khunjerab National Park",
          "Kiari",
          "Kidmang",
          "Kyagar Tso",
          "Kyungyam Dho, Rong",
          "Kyungyam village",
          "Lal Pahari",
          "Lamayuru Gompa",
          "Latho",
          "Lato Village",
          "Leh, Ladakh",
          "Likir - Spango Road",
          "Lingshed (OBI)",
          "Lingti Plain",
          "Loma bend",
          "Lower Spango Valley",
          "Lukung",
          "Mahe Bridge",
          "Mahe Bridge, Ladakh",
          "Mangue",
          "Matho",
          "Merak campites",
          "Minapin",
          "Miru",
          "Misgar",
          "Mood",
          "Muglib",
          "Naltar Valley",
          "Netherlands Karakoram Expedition Camp 58",
          "Ney",
          "Nimmoo",
          "North Pullu",
          "Nubra Valley",
          "Nubra valley--Panamik",
          "Nubra valley--Sumur",
          "Nurla",
          "Nyoma",
          "Panamik Hot Springs, Nubra",
          "Pang",
          "Pangong Lake",
          "Pangong Lake, Ladakh, India",
          "Pangong Tso--Man",
          "Pangong Tso--Spangmik",
          "Passu",
          "Phey Junction",
          "Photoksar",
          "Phyang Gonpa, Leh Ladakh, India",
          "PK: Fairy Meadows NP--Fairy Meadows Trail",
          "Polokong La",
          "Puga",
          "Rhongo",
          "Ri-Marpo, Rongo-Hanle Road",
          "Rong Valley, Ladakh",
          "Rong Valley--Ligtse",
          "Rong Village",
          "Rumbak Valley",
          "Rumtse",
          "Saboo",
          "Saboo--Leh",
          "Sadpara Lake",
          "Saspochey",
          "Saspol",
          "Saspotse Village",
          "Serchu Yunnan River",
          "Shang Valley",
          "Shara",
          "Sheosar Lake",
          "Shey--Holy Fish Pond",
          "Shey Manla",
          "Shey Marshes",
          "Shey Smanla",
          "Sindhu Ghat",
          "Skara Spang--Leh",
          "South Pullu",
          "Spango Valley",
          "Spituk",
          "Spituk--Indus roadbridge",
          "Stakspi la",
          "Stanba House, Ranbirpor, Thiksay, Ladakh",
          "Startsapuk Tso",
          "Stok Kangri Base Camp",
          "Stokrange",
          "Sumdo",
          "Taglang La",
          "Tagste/ Tangste (OBI)",
          "Tangste Cafe",
          "Tangtse",
          "Tar",
          "Tarchit Rong",
          "Terchay vilalge",
          "Thiksey",
          "Thiksey Monastery",
          "Thiksey Ringing Centre",
          "Thukjay--Tsokar Lake",
          "Tiggur",
          "Tsaka La",
          "Tsarab Bridge",
          "Tso Kar",
          "Tsokar Lake",
          "Tso Moriri, Ladakh",
          "Tso Moriri--North End",
          "Tukla",
          "Tunah",
          "Turtuk",
          "Uley",
          "Upshi",
          "Wari La Pass",
          "Watch Tower, Tasabuk, Tsokar Lake",
          "Whisky Nala",
          "Zingral Transit Check Post",
        ],
      },
    ],
  },
  {
    state: "Lakshadweep",
    districts: [
      {
        district: "Lakshadweep",
        localities: [
          "Agatti Island--General Area",
          "Bangaram Island",
          "Kavaratti Island",
          "Minicoy Island--General Area",
          "Pitti Island",
          "Tinnakara Island",
        ],
      },
    ],
  },
  {
    state: "Madhya Pradesh",
    districts: [
      {
        district: "Balaghat",
        localities: [
          "Bagh Tola Resort, Kanha",
          "Bagh Villas-- Baherakhar (Lake)",
          "Bagh Villas Jungle Camp",
          "Banda Tola Lake",
          "Banjaar Tola Lodge, Kanha",
          "Banjar River, near Mukki Gate",
          "Basinkhar Lake",
          "Bhimodi Talab",
          "Camp Dev Vilas",
          "Digoda, Balaghat Road",
          "Jamunia dam, katangi, Seoni",
          "Jamunia River, Bhimlat Village",
          "Kanha",
          "Kanha Jungle Camp--Mukki gate (Santapur)",
          "Kanha Jungle Lodge",
          "Kanha NP--Mukki Zone",
          "Kanha NP Outskirts--Mukki to Baihar",
          "Kanha NP Outskirts--Mukki to Bhaisanghat",
          "Kanha NP--Supkhar Range",
          "Kanha Tiger Reserve--Khapa Zone (Buffer)",
          "Kanha Tiger Reserve--Mukki Gate",
          "Kanha TR--Bamhni Nature Trail",
          "Kanha TR (Core)--Garhi Road",
          "KanhaTR--Khapa (Buffer)--Rajma",
          "Kanha TR--Sondar",
          "Kanha TR--Supkhar Rest House & Surroundings",
          "Kareli",
          "Kinhi village, Balaghat",
          "Ladsa Tal Lanji, Balaghat",
          "Managarh, Balaghat",
          "Manji tola to Supkhar (Forest Road)",
          "Moti Talab, Balaghat",
          "Najul Lake, Katangi",
          "Patharwada, katangi",
          "Pench NP",
          "Phen Wildlife Sanctuary",
          "Pond east of Bagh Retreat",
          "Rajiv Sagar Dam (Kudwa), Balaghat",
          "Saccharum Safari Lodge",
          "Salban Homestay, Kanha",
          "Saleteka village, Balaghat",
          "Sarai Kothi Homestay",
          "Sarai Kothi, Kanha",
          "Shergarh Tented Camp",
          "Sihar Paath Mandir, Baihar",
          "Singinawa Jungle Lodge",
          "Sitapathor",
          "The Bagh A Forest Retreat",
        ],
      },
      {
        district: "Betul",
        localities: [
          "Abandoned Coffee Plantation (Forest), Kukru",
          "Bakud Reservoir",
          "Betul Railway Station",
          "Bhondyakund Village and River, Kukaru Khamla",
          "BORI SAFARI LODGE",
          "Chunalohma Forest, Betul",
          "Chunalohma Forest--North of River",
          "Coffee Bagaan & Forest Guest House, Kukru Khamla",
          "Dhamnya--Tapi River Crossing",
          "Forest Guest House, Ladi",
          "Forest Rest House, Sanpna Dam",
          "Ghodadongri Railway Station",
          "Khedla Fort",
          "Kosmi Dam",
          "Kursi Jheel",
          "Ladi Lake",
          "Maramjhiri Railway Station",
          "Mathardev Baba Tekri",
          "Muktagiri",
          "Pachdhar Reservoir--Deodongri",
          "Pachdhar Reservoir--Garwha Yog Parisar Temple",
          "Prachin Shiv Mandir, Bhainsdehi",
          "Rajdoh(Tawa River), Sarni",
          "Ratamati fields",
          "Ridge trail west of Kukaru Khamla",
          "Sanpna Dam",
          "Satpura Dam, Sarni",
          "Satpura Forest Guest House, Betul",
          "Sipnadarshan Viewpoint (Sunset Point, Kukru)",
          "Sunset Point East of Kukru Khamla",
          "Sunset Point near Sirasitand, Kukaru Khamla",
          "Tapti Kund, Multai",
          "VACA School, Athner",
        ],
      },
      {
        district: "Satna",
        localities: [
          "Chitrakoot (OBI)",
          "Govt.Ex.Hr.Sec Venkat No-01, Satna",
          "Maihar Railway Station",
          "Maihar Temple",
          "Nayagaon",
        ],
      },
      {
        district: "Seoni",
        localities: [
          "Lakhnadon",
          "Payli, Bargi Reservoir",
          "Pench Jungle Camp, Kohka",
          "Pench Tiger Reserve- -Baaz Jungle Resort",
          "Pench Tiger Reserve- -Baghvan, A Taj Safari",
          "Pench Tiger Reserve--Bijamatta lake",
          "Pench Tiger Reserve--Bodanala Reservoir",
          "Pench Tiger Reserve- -Chhindimatta",
          "Pench Tiger Reserve- -Dhudhiya Lake",
          "Pench Tiger Reserve--Junewani Lake",
          "Pench Tiger Reserve--Karmajhiri Zone",
          "Pench Tiger Reserve- -Khawasa Zone",
          "Pench Tiger Reserve- -Kohka Lake",
          "Pench Tiger Reserve--Malkundam",
          "Pench Tiger Reserve--MP Overall",
          "Pench Tiger Reserve- -Pench Tree Lodge",
          "Pench Tiger Reserve- -Rukkhad Zone",
          "Pench Tiger Reserve- -Tathastu Resort",
          "Pench Tiger Reserve--Telia Buffer",
          "Pench Tiger Reserve- -The Riverwood Forest Retreat",
          "Pench Tiger Reserve--Turia Rural & Surroundings",
          "Pench Tiger Reserve--Turia Zone",
          "Pench , Turia gate",
          "Seoni (OBI)",
          "Sonewani Wildlife Sanctuary",
        ],
      },
      {
        district: "Dewas",
        localities: [
          "Chidawad/Bhima Talab, Ujjain",
          "Dhaturiya, Ujjain",
          "Khategau (OBI)",
          "kheoni Wildlife Sanctuary",
          "Khini Bujurg, Madhya Pradesh, IN (22.836, 76.885)",
          "Nemawar, Narmada River (OBI)",
          "Nipaniya Talab, Dhaturiya, Ujjain",
          "Palsawad/Updi, Ujjain",
          "Shipra River Bridge, Dewas",
        ],
      },
      {
        district: "Niwari",
        localities: [
          "Bank of Betwa River (OBI)",
          "Karipadar Patrolling Camp , Orchha Wildlife Sanctuary",
          "Orachha",
          "Orccha--Royal Chhatris",
          "Orchha Wildlife Sanctuary",
        ],
      },
      {
        district: "Indore",
        localities: [
          "Agriculture College, Indore",
          "Ambajhar Mandir Hill--Patal Pani",
          "Army Public School, Mhow",
          "Ashapur, Madhya Pradesh, IN (22.499, 75.741)",
          "Badgonda Forest Nursery, Mhow",
          "Baroda daulat",
          "Baroda Daulat Village Pond",
          "Bilavali lake",
          "Buranakhedi Wetland",
          "Chohan khedi Talaab",
          "City Forest--Smiti Van Public Park, Indore",
          "Classic Purnima Park Colony",
          "Daly College campus",
          "Devi Ahilya Vishwavidyalaya (DAVV), Indore",
          "Dhanora Farms",
          "Dhulet",
          "Forest Campus, Indore",
          "Forest Department Office, Indore",
          "Gandhi Hall, Indore",
          "Govindram Seksaria Institute of Management & Research (GSIMR)",
          "Govt. Middle School No. 40",
          "Holkar Science College",
          "Horticulture dept Nursery",
          "IIM Indore",
          "Indian Institute of Technology (IIT), Indore",
          "Indian Institute of Technology Indore",
          "Indore Railway Junction",
          "Indore Tennis Club",
          "Indore Zoo",
          "Institute of Engineering and Technology (IET), Indore",
          "Kajligarh Fort",
          "Kajligarh Fort and surrounding forests",
          "Kajligarh Valley",
          "Kalakund, Mhow",
          "Kishanpura (Machal) Wetland, Indore",
          "Krishi Vigyan Kendra (KVK) Kasturbagram, Indore",
          "Lake View",
          "Lalbagh Garden",
          "machal",
          "Mahatma Gandhi Memorial (MGM) Medical College",
          "Malendi",
          "Malwa Institute of Technology",
          "Manav Chetna Vikas, Pivday",
          "Medi-Caps University, Indore",
          "Mhow--Army War College",
          "Mhow (OBI)",
          "Military College of Telecommunication Engineering (MCTE)",
          "Nahar Jhabua",
          "New Digamber Public School (NDPS), Indore",
          "Pipliyapala Lake",
          "Ralamandal Sanctuary",
          "Ralamandal town",
          "Residency Kothi",
          "RRCAT Colony",
          "RRCAT Lake",
          "Shri Govindram Seksaria Institute of Technology and Science (SGSITS)",
          "Signal Vihar, Mhow",
          "Sirpur Lake",
          "St. Mary's Higher Secondary School, Mhow",
          "Sukhniwas tank, RRCAT, Indore",
          "Talawali Chanda Lake",
          "The Daly College",
          "Tillore Buzurg",
          "TINCHA WATER FALL RD TOWARDS UDAYNAGAR",
          "Yashwant Sagar Dam",
          "Yeshwant Sagar",
          "Yeshwant Sagar--Gulawat",
        ],
      },
      { district: "Shajapur", localities: [] },
      {
        district: "Jabalpur",
        localities: [
          "Bagdari Water Fall, Jabapur",
          "Balsagar Lake",
          "Bargi Backwaters",
          "Bargi Dam",
          "Barnu tank, Jabalpur",
          "Beohar Sarovar, Gandhigram, Jabalpur",
          "Bhedaghat Boating Area",
          "Chattarpur, Jabalpur",
          "Deotal, Jabalpur",
          "Dhuadhar Falls, Jabalpur (OBI)",
          "Diwari, Jablpur",
          "Dumna Nature Reserve",
          "Dumna Nature Reserve Park",
          "Gandhigram",
          "Gol Bazar, Jabalpur",
          "Harrai Agro Farm",
          "Husain's Farms",
          "Jabalpur (OBI)",
          "Jabalpur Railway Station (OBI)",
          "Jalpari",
          "Jalpari lake",
          "JNKVV, Jabalpur",
          "Kakartala Pond, Jabalpur",
          "Kaladumar",
          "Kaladumar, Jabalpur",
          "Kathoda treatment plant, Jabalpur",
          "Khairani ghat, Jabalpur",
          "Kundwara",
          "Lamheta Ghat, Jabalpur",
          "Lower Gour",
          "Madai Kalan",
          "Madai khurd",
          "Madai Makhrar",
          "Mahanadi bridge, Padariya (Kundam)",
          "Marble Rocks, Bedhaghat (OBI)",
          "Narrai Reserve Forest",
          "Nasirabadi's Farm",
          "NSCB Medical College",
          "Nunsar",
          "Padariya",
          "Pariyat Dam, Jabalpur",
          "Robertson Lake, Jabalpur",
          "Sangram Sagar Lake, Bajnamath, Jabalpur",
          "SFRI, Jabalpur",
          "Shail Parn Udyan, Jabalpur",
          "Silver Oak Compound, Russel Chowk, Jabalpur",
          "Singod, Jabalpur",
          "Tewar, Madhya Pradesh",
          "Thakur Taal, Jabalpur",
          "Tilhari Lake, Bhatauli, Jabalpur",
          "Trishul Bhed",
          "Tropical Forest Research Institute (TFRI)",
          "Vijay Nagar, Jabalpur",
          "Yadav Homoeopathic Clinic",
        ],
      },
      { district: "Harda", localities: [] },
      {
        district: "Shivpuri",
        localities: [
          "Karera Bird Sanctuary",
          "Karera Rockface",
          "Madhav National Park",
          "Sankhya Sagar Lake",
          "Sankhya Sagar, Shivpuri",
          "Shivpuri (OBI)",
        ],
      },
      { district: "Narsinghpur", localities: [] },
      { district: "Alirajpur", localities: [] },
      {
        district: "Narmadapuram",
        localities: [
          "Adamgadh Hills",
          "Bija Kheri tank",
          "Bineka",
          "Bison Lodge, Pachmarhi",
          "Bori WLS (OBI)",
          "Chaar (4) Number Canal (STR Buffer)",
          "Cheetal Resort, Madhai",
          "Dehliya Camp",
          "Denwa Backwater Escape (Pugdundee Resorts)",
          "Forest Department Guest House, Matkuli",
          "Forest Department Office, Mahadeo Road, Pachmarhi",
          "Forsyth Lodge",
          "Forsyth lodge to Madai mudflat",
          "Golf View Resort, Pachmarhi",
          "Itarsi (OBI)",
          "Jatashankar Temple",
          "Matkuli Forest Guest House",
          "NCBS-TIFR Field Station, Pachmarhi",
          "Nibhora",
          "Pachmahri--Rajendra Giri Garden",
          "Pachmarhi Biosphere Reserve -- Apsara Vihar",
          "Pachmarhi Biosphere Reserve -- Bee Falls",
          "Pachmarhi Biosphere Reserve -- Dhupgarh",
          "Pachmarhi Biosphere Reserve -- Dutchess Fall",
          "Pachmarhi Lake",
          "Pachmarhi--Overall",
          "Pachmarhi--Pandav Caves",
          "Pachmarhi--Twynam Pool",
          "Pachmari Biosphere Reserve",
          "panchmarhi Hill Station",
          "Panchmarhi (OBI)",
          "Pipariya Railway Station",
          "Reni Pani Jungle Lodge",
          "Road to Bineka",
          "Satpura Tiger Reserve--Dhain",
          "Satpura Tiger Reserve--Overall",
          "Satpura TR--Adi Denwa Camp",
          "Satpura TR--Badkachhar",
          "Satpura TR--Churna",
          "Satpura TR--Denwa River--Madai to Sonbhadra",
          "Satpura TR--Dhava Camp",
          "Satpura TR--Gutti Baba",
          "Satpura TR--Jaharghat Camp",
          "Satpura TR--Kankdi",
          "Satpura TR--Madai",
          "Satpura TR--Nimghan",
          "Satpura TR--Panara",
          "Satpura TR--Paraspani",
          "Satpura TR- -Parsapani",
          "Satpura TR--Parsapani entrance gate",
          "Satpura TR--Parsapani Range",
          "Satpura TR--Sakot Camp",
          "Satpura TR--Sonbhadra Confluence",
          "Satpura TR--Ticket Counter Madai",
          "Sehra",
          "Silk Plantation- -Pachmarhi",
          "Taku Railway Station",
          "Tawa Bridge (NH 22)",
          "Tawa Reservoir--General Area (OBI)",
          "Tawa Reservoir--Kotmimal",
          "Tawa Reservoir--Ranipur",
          "Tekapar to Kamti",
        ],
      },
      {
        district: "Khargone",
        localities: ["Jam Gate, Mhow", "Sahastradhara Waterfalls"],
      },
      {
        district: "Sagar",
        localities: [
          "Nauradehi Wildlife Sanctuary",
          "Nauradehi wild life sanctuary Sagar",
          "Nauradehi WLS--Chevla Talab",
          "Nauradehi WLS--Jamraasi Talaab",
          "Rajghat Dam, Sagar",
          "Sagar University",
        ],
      },
      {
        district: "Anuppur",
        localities: ["Amarkantak", "Damgadh, Amarkantak"],
      },
      { district: "Jhabua", localities: ["rathamba grasslands"] },
      {
        district: "Panna",
        localities: [
          "Hinouta Plateau, Panna National Park",
          "Ken River and it Valley, Madla, Panna National Park",
          "Ken River Lodge (OBI)",
          "Nirpat Sagar, Panna",
          "NMDC Reservoir, Hinauta",
          "Panna (OBI)",
          "Panna Tiger Reserve",
          "Panna Tiger Reserve--Hinnauta Gate",
          "Pashangarh, Taj Safari Lodge, Panna",
          "Ranipur Nature Trail",
        ],
      },
      {
        district: "Ratlam",
        localities: [
          "Lesser Florican Sanctuary, Sailana, Ratlam",
          "Sailana Wildlife Sanctuary",
        ],
      },
      {
        district: "Mandla",
        localities: [
          "Bamni Banjar (OBI)",
          "Banjar River, Chaukitola",
          "Budbudi Nala, Mocha Road",
          "Chhichhari",
          "Dak Bungalow, Kanha-Kisli",
          "Dhauranala, Mandla",
          "Flame of the Forest Safari Lodge",
          "Gove Nature Trail from inside Khatia Gate to back of Tuli Resort",
          "Govt. Arts College, Niwas, Harisingori",
          "Hirdeynagar, Mandla (OBI)",
          "Jangal Nala Jelwara",
          "Jhangul Chatanvaale forest",
          "Kanha Jungle Camp (Khatiya Gate)",
          "Kanha Kiskindha Resort",
          "Kanha NP--Batwar",
          "Kanha NP--Kanha Zone",
          "Kanha NP--Kisli Zone",
          "Kanha Tiger Reserve",
          "Kanha Tiger Reserve--Parasatola Protection camp",
          "Kanha Tiger Reserve--Sarhi zone",
          "Kanha TR--Jamun Tola Range",
          "Kanha TR--Kisli, Karaighatia road",
          "Kenapi walk, Sarhi",
          "Khairmai Nala Atariya",
          "Kopenala, Chaukitola",
          "KTR--Kanha zone--Mundi dadar",
          "Kutwahi",
          "Kutwahi to Chownki tola",
          "Luhari Ryt",
          "Mahua Tiger Resort, Mocha",
          "Mandla, MP (OBI)",
          "Mogli Resort, Kanha Tiger Reserve",
          "Narrai",
          "Niwas, Mandla",
          "Phen Wildlife Sanctuary",
          "Rathnahipat, Rata, Kanha",
          "Reta Nala, Sarahi",
          "Sahastradhara Fall, Mandla",
          "Sautia Rice Fields, Mocha",
          "Sterling Kanha",
          "Taktauwa forest",
          "Thanvar Reservoir, Madla",
          "Tuli Tiger Resort, Kanha",
          "Village Heerapur Bhurka Pond",
          "Wild Chalet Resort",
        ],
      },
      { district: "Datia", localities: [] },
      {
        district: "Raisen",
        localities: [
          "Ashapuri Temple and Dam",
          "Bamnai Village Forest",
          "Bhimbetka",
          "Bhojpur",
          "Bhoot Palasi Village Forest",
          "Koka kho vilage,Ratapani",
          "Lakhadwar forest beat Kesalwada",
          "Nasipur village forest",
          "Raisen Fort",
          "Ratapani Sanctuary",
          "Ratapani WLS--Barkhera forest area",
          "Ratapani WLS--Dhundhwani",
          "Ratapani WLS--Jawara _Imlana camp, Jabra Malkhar",
          "Ratapani WLS--Kherwana dham",
          "Sanchi",
        ],
      },
      {
        district: "Gwalior",
        localities: [
          "Ammasahab Ki Chattri",
          "Badhavna (OBI)",
          "Blue lotus lake",
          "Deokho, Gwalior (OBI)",
          "Gajra Raja Medical College",
          "Ghatigaon WLS",
          "Gwalior Fort",
          "Gwalior (OBI)",
          "Gwalior Railway Station",
          "Jiwaji university",
          "Murar (OBI)",
          "Ramaua Dam",
          "Scindia School, Gwalior (OBI)",
          "Sirol Pahadi, Gwalior City Forest",
          "Tapovan (OBI)",
          "Tighra (OBI)",
        ],
      },
      {
        district: "Neemuch",
        localities: [
          "Besala Talab",
          "Gandhisagar Forest Resthouse",
          "Gandhisagar Sanctuary",
          "Gandhisagar Sanctuary--Chainpuriya Block",
          "Harkiyakhal Dam",
          "Rampura Bus Station, Jamalpura, Gandhisagar Back Water",
          "Rampura Pathar , Forest RestHouse , Karanpura Block, Madhya Pradesh, IN (24.695, 75.553)",
        ],
      },
      {
        district: "Khandwa",
        localities: [
          "Abana Forest Nursery",
          "Bheem kund",
          "Indira Sagar Reservior",
          "Jaswadi Filter Plant",
          "Nagchun Talab",
          "Sailani island",
          "Shuri FR, Khandwa-Betul road",
        ],
      },
      {
        district: "Mandsaur",
        localities: [
          "Gandhisagar WLS--Dhanga",
          "Gandhisagar WLS--Hinglajgarh",
          "Gandhisagar WLS--Sawant Kothadi",
          "GandhiSagar WLS--Taksheshwar Takhaji",
          "Surya Talab, Hinglajgarh",
        ],
      },
      {
        district: "Sidhi",
        localities: [
          "FH2 Guest House Sidhi Cement Plant",
          "Sanjay Dubari (Dubri) Tiger Reserve",
          "Sanjay Dubri TR--Dubri Core",
          "Sidhi--General Area (OBI)",
          "Son Gharial Sanctuary",
        ],
      },
      { district: "Barwani", localities: [] },
      {
        district: "Bhopal",
        localities: [
          "Ajnar Lake, Bhopal-Raisen Road",
          "Army Golf Course",
          "Barkatullah University",
          "Barkatullah University--Department of Zoology,",
          "Bhadbhada Dam, Bhopal",
          "Bhoj Wetland",
          "Bhoj Wetland--Bisankhedi",
          "Bhoj Wetland--Boat Club",
          "Bisankhedi",
          "Boorvan Park, Upper lake, Bhopal",
          "Chinar Park",
          "Habibganj Railway Station, Bhopal",
          "Hatai Kheda Lake, Bhopal",
          "IIFM Colony",
          "IIFM, Van Vihar, Bhopal (OBI)",
          "IISER Bhopal",
          "Indian Institute of Forest Management (IIFM)",
          "Ivy Suites",
          "Jagran Lakecity University road, Bhopal",
          "Kaliyasot Dam",
          "Karondiya Talab",
          "Kerwa Dam",
          "Kerwa Dam--Kerwa Nursery",
          "National Law Institute University Campus, Bhopal (OBI)",
          "Rajabhoj International Airport",
          "RIE campus, Bhopal",
          "Sanskaar Valley School Campus",
          "Shri Guru Gobind Singh 'Ekant' Park",
          "Shyampur, Bhopal",
          "Van Vihar NP",
          "WWF India, MP & CG State Office, Bhopal",
        ],
      },
      { district: "Tikamgarh", localities: [] },
      {
        district: "Rajgarh",
        localities: [
          "Chhawada Road, Kotra",
          "Narsinghgarh Wildlife Sanctuary (Chidi Kho)",
        ],
      },
      {
        district: "Bhind",
        localities: [
          "Forest Guest House, Barahi",
          "Gauri Lake",
          "National Chambal Sanctuary -- Chambal River near Ater Road",
          "National Chambal Sanctuary -- Jaitpur (MP Side)",
          "National Chambal Sanctuary--Jaitpur (MP Side)",
        ],
      },
      {
        district: "Rewa",
        localities: [
          "Bela Cement Works Mines area Road side, Sonara, Rewa",
          "DFO Office REWA",
          "Purwa Falls",
        ],
      },
      {
        district: "Dindori",
        localities: [
          "Amarkantak--Kapildhara",
          "Kabir Chabutara, Amarkantak rd",
        ],
      },
      {
        district: "Umaria",
        localities: [
          "Aranyak Resort, Kuchwahi Village, Bandhavgarh",
          "Bagh Tola Jungle Lodge, Parasi Buffer zone-Bandhavgarh",
          "Bandhavgarh NP",
          "Bandhavgarh NP--Fort",
          "Bandhavgarh Tiger Reserve--Khitauli Gate",
          "Bandhavgarh Tiger Reserve--Khitauli Zone",
          "Bandhavgarh Tiger Reserve--Magdhi Zone",
          "Bandhavgarh Tiger Reserve--Tala Gate",
          "Jungle Lodge, Bandhavgarh (OBI)",
          "Kings Lodge, Bandhavgarh",
          "Monsoon Jungle Lodge, Bhandhavgarh",
          "Nature Heritage Resort, Bandhavgarh Tiger Reserve",
        ],
      },
      {
        district: "Burhanpur",
        localities: [
          "Dargah e Hakimi",
          "Sonud Village",
          "Tapi River Bridge, Dedtalai",
        ],
      },
      {
        district: "Chhatarpur",
        localities: [
          "Buxwaha",
          "Govt. Maharaja PG College",
          "Hanuman Toriya",
          "Ken Gharial Sanctuary",
          "Khajuraho--Khodar River",
          "Khajuraho--Shiv Nagar",
          "Khajuraho Temples",
          "Panna Tiger Reserve--Karnavati Interpretation Centre",
          "Radha Madhav Temple Garden, Rajnagar",
          "Ranguwan Dam--Silon side",
          "Sarai at Toria, Madla",
          "Toria Village",
        ],
      },
      { district: "Katni", localities: [] },
      {
        district: "Sheopur",
        localities: [
          "Basundar Ka Tapra, Sheopur",
          "Bawanda Kalitalai, Sheopur",
          "Castaway Cay",
          "Devnarayan Talab, Kalmi forest beat, Goras",
          "Forest Rest House, Sheopur",
          "Hanuman Kheda, Sheopur",
          "Kalmi Forest Beat, Goras, Sheopur",
          "Kuno National Park--Durrendi Forest Camp",
          "Kuno National Park--Palpur",
          "Mukundpura",
          "Rambadi Forest Beat",
          "Rameshwar Ghat, National Chambal Sanctuary",
        ],
      },
      {
        district: "Sehore",
        localities: [
          "Forest Chauki, Shahganj road, Sehore",
          "Hoshang Shah Fort",
          "Khanda Bad Talab",
          "Kheoni Wildlife Sanctuary",
          "Kheoni WLS--Dolatpur",
          "Kolar Dam",
          "Madhuban Resort By Somaiya",
          "Mid Ghat Shrine (Dargah)",
          "Ratapani WLS--Delawadi",
          "Ratapani WLS--Delawadi Camp",
          "Ratapani WLS--South Mathar",
          "Talpura Talab",
        ],
      },
      { district: "Agar Malwa", localities: [] },
      { district: "Shahdol", localities: [] },
      {
        district: "Ashoknagar",
        localities: ["Amraee Rural Resort, Chanderi", "Rajghat Dam, Chanderi"],
      },
      { district: "Guna", localities: ["Bajrangarh Fort"] },
      { district: "Singrauli", localities: [] },
      {
        district: "Chhindwara",
        localities: [
          "Border Plateau",
          "Chhindwara (OBI)",
          "Chindwada (OBI)",
          "Jamtara village",
          "Machagora Dam, Chhindwara",
          "Pandhurna Railway Station",
          "Patalkot, MP",
          "Pench Tiger Reserve--Chhediya",
          "Pench Tiger Reserve--Haldu Tola",
          "Pench Tiger Reserve--Jamtara-Kumbhpani Road",
          "Pench Tiger Reserve--Jamtara Rural & Surrounding",
          "Pench Tiger Reserve- -Jamtara Wilderness Camp",
          "Pench Tiger Reserve--Kumbhpani Buffer Zone",
          "Pench Tiger Reserve--Kumbhpani Lake",
          "Pench Tiger Reserve- -Seetaghat",
          "Satpura TR--Binora Camp",
          "Satpura TR--Satdhara",
          "Tamia (OBI)",
        ],
      },
      {
        district: "Ujjain",
        localities: [
          "Gaowadi pond, Ujjain",
          "Kalidas Academy",
          "Kshipra river bank, near Bhartari Cave, Ujjain",
          "Naulakhi Eco Tourism Park, Ujjain",
          "Navlaki Eco-Park",
          "Sahabkhedi Lake, Ujjain",
          "Undasa Lake",
          "Vikram Vatika, Ujjain",
          "Village Sadawal, Ujjain",
        ],
      },
      {
        district: "Dhar",
        localities: [
          "Mandu",
          "Sagar Lake",
          "Sanjay Jalashay, Pithampur, Indore",
          "Susari, Kukshi",
        ],
      },
      {
        district: "Damoh",
        localities: [
          "Barpati Pond, Damoh",
          "Damoh Circuit Hose",
          "Mangarh, Damoh",
          "Rajnagar Lake",
          "Rani Durgawati WLS",
          "Rani Durgawati WLS--Vultures Point",
          "Singrampur, Jabalpur-Damoh Road",
          "Telan Ghat",
        ],
      },
      {
        district: "Morena",
        localities: [
          "Chambal River, Morena",
          "Morena (OBI)",
          "National Chambal Gharial Sanctuary--Morena (MP side)",
          "National chambal sanctuary",
          "National Chambal Sanctuary--Deori Eco Centre",
          "Nurabad Railway Station",
        ],
      },
      { district: "Vidisha", localities: ["Halali Dam", "Udayagiri Caves"] },
    ],
  },
  {
    state: "Maharashtra",
    districts: [
      {
        district: "Sangli",
        localities: [
          "Aamrai Garden, Sangli",
          "Annaso Patil Nagar birding area",
          "Aralwadi lake, Sangli",
          "Arundaya Ganpati Mandir",
          "Atpadi Dhabai Kuran Grassland",
          "Banewadi lake",
          "Bapat Mala",
          "Bhagyanagar Talav, Sangli, Maharashtra.",
          "Bhose lake",
          "Bird Song, Haripur",
          "Chintamanrao College Of Commerce, Sangli",
          "Dandoba Hills",
          "Dandoba hill, Sangli",
          "DES Campus (Restricted access)",
          "Fields near Ramnagar, Sangli",
          "Gajanan colony, Haripur road, Sangli",
          "Ganesh Talav, Miraj",
          "Girling birding area",
          "Girling Temple, Sangli, Maharashtra",
          "Government Medical College (Restricted area)",
          "Haroli lake",
          "Ingale Pazar Talav, Palus",
          "Janraowadi Dam, Sangli",
          "Jogalekar Mala tasgaon",
          "Jogalekar Mala, Tasgaon (Restricted access)",
          "Kalptaru Colony birding area",
          "Kavathe Mahankal",
          "Khanderajuri",
          "Krishna river banks -- Anugadewadi",
          "Krishna River Dam, Sangli",
          "Kumbhar mala birding area",
          "Laxmi Mandir, Santoshgiri, Sangli, Maharashtra",
          "Lingayat crematorium, Sangli",
          "Lingnur lake",
          "Madhuban colony, Sangli",
          "Mallikarjun Hills",
          "Manik Nal Grassland",
          "Mhaisal lake",
          "MIDC, Miraj",
          "Miraj canal",
          "Miraj Canal",
          "Rajarshi Chatrapati Shahu Maharaj Krushi V V Mahavidyalaya Sangli",
          "Rama Udyan Garden",
          "Ramling Bet-Bahe, Sangli",
          "Rotary Hall, Vishrambag (Restricted access)",
          "Sagareshwar WLS",
          "Sangli City (OBI)",
          "Sangli willingdon college",
          "Sangmeshwar temple -- River bank, Haripur",
          "Sanjay Nagar, Sangli",
          "Santoshgiri Hills",
          "Shahi A Gat Dam",
          "Shamrao Nagar Ghuntewari, Sangli",
          "Shantiniketan School",
          "Shikhoba Hills",
          "Shivajinagar, Miraj",
          "Shree Dargoba Mandir, Pare",
          "Siddhewadi Lake",
          "Siddhewadi (Shingare vasti) Grasslands",
          "Vaddi canal",
          "Vinayaknagar, haripur road, Sangli",
          "Walchand college sangli",
          "Warna Kath, Haripur",
          "Warnali Sangli",
          "Willingdon college, Sangli",
        ],
      },
      {
        district: "Ahmednagar",
        localities: [
          "Ahmednagar fort",
          "Bhandardara Lake",
          "Canal Road, Awhane Kh",
          "Chandbiwi's mahal",
          "Dongargan forest",
          "Gajadipur, Parner, Ahmednagar",
          "Gorakshanath Fort",
          "Harishchandragad",
          "Kapurwadi lake",
          "Loni Pravara (OBI)",
          "Nandan Kanhan Farm",
          "Panoli-Parner road",
          "Pansare Nursery",
          "Pimpalgaon Lake, Imampur, Maharashtra, IN (19.21, 74.763)",
          "Ramdoh, Jaykwadi Dam",
          "Rashin Outskirts",
          "Rehekuri WLS, Ahmednagar",
          "Saiban Agro Tourism Centre",
          "Saiban, Ahmednagar",
          "Sandhan valley, Ahmadnagar",
          "Sanjivani College Of Engineering, Kopargaon (Restricted access)",
          "Sarola Baddi road",
          "Sharda English Medium School, Kopargaon",
          "Siddheshwar Wadi, Parner",
          "Somaiya Vidya Mandir, Laxmiwadi",
          "Wadgaon Darya, Parner, Ahmednagar",
          "Walki Lake, Ahmednagar",
        ],
      },
      {
        district: "Amravati",
        localities: [
          "Aamzari Sankul, Melghat, Chikhaldara",
          "Bamboo Garden, near Wadali Talav",
          "Basalapur lake, Amravati",
          "Bhimkund View Point, Chikhaldara, Amravati",
          "Bhivapoor dam",
          "Bopapur Dam, Devgaon, Amravati Dt.",
          "Bor river Dam",
          "Chhatri Lake--Backwaters, Amravati",
          "Chikaldara",
          "Chikhaldara Gardan",
          "Chikhaldara Garden",
          "Dhabhi lake, Amravati, Maharastra",
          "Doma Reservoir",
          "Ghatkhed Dam, Spillway and Reservoir",
          "Gondvihir Reservoir",
          "Government College of Engineering (GCOEA)",
          "Government Polytechnic Amravati",
          "Government Vidarbha Institute of Science and Humanities (GVISH)",
          "Govt Vidarbha Institute Of Science and Humanities",
          "Jalaka Reservoir",
          "Jalka Lake, Amravati Dt.",
          "Jalka Reservoir, Amravati",
          "Kekatpur Lake",
          "kumagad",
          "Mahendri Reserve Forest",
          "Malkhed Reservoir",
          "Melghat-Dhargad FRH",
          "Melghat Tiger Reserve",
          "Melghat Tiger Reserve -- Harisal",
          "Melghat Tiger Reserve--Kolkaz",
          "Melghat Tiger Reserve--Semadoh",
          "Melghat Tiger Reserve--Semadoh Forest Rest House",
          "Melghat Tiger Reserve -- Wan Sanctuary",
          "Mixed bamboo forest near Bhulori",
          "Mozari Point, Chikhaldara",
          "Muthwa Community Resource Centre",
          "near chatri lake",
          "Old MTDC Resort, Chikhaldara",
          "Panchbol Point",
          "paratwada",
          "Pohra forest, Amravati",
          "Pohra Lake, Amravati",
          "Prof. Ram Meghe Institute of Technogy and Research, Badnera",
          "PR Pote College Amravati",
          "Rajendra Garden, Amravati",
          "Rajura Lake, Amravati",
          "Rangoli lawns",
          "Reserve line Amravati",
          "Samajik Wanikaran, Wadali",
          "Sant Gadge Baba Amravati University",
          "Sant Gadge Baba Amravati University, Amravati",
          "Sant Gadge Baba Amravati University Lake Area, Amravati",
          "Savanga Lake, Amravati",
          "Sawardi Reservoir, Amravati",
          "Shekdari Dam, Gavankund, Maharahtra",
          "Shivneri colony Ground",
          "Shri Shivaji Science College Amravati",
          "State bank of India",
          "Tapi River Bridge",
          "Upper Shewati Lake",
          "Upperwardha Dam, Morshi, Amravati Dt",
          "Virshi, Amravati Dt",
          "Wadali lake",
          "Wazzar Dam",
        ],
      },
      {
        district: "Ratnagiri",
        localities: [
          "Adishti Temple birding area",
          "Alawa, Ratnagiri Dt",
          "Amba ghat",
          "Anjanvel lighthouse",
          "Anjarle Beach",
          "Anjarle Creek Bridge",
          "Arjuna Dam",
          "Asagani Forest",
          "Bharne Naka",
          "Bhatye Bridge, Ratnagiri",
          "Bhatye, Ratnagiri",
          "Chandranagar, Maharastra (Historical)",
          "Chikhalgaon birding area",
          "Chiplun",
          "Chiplun-Shiravali",
          "College of Fisheries, Shirgaon",
          "College of Forestry--Nursery, Dapoli",
          "Dairy Dept, KKV, Dapoli",
          "Dapoli (OBI)",
          "District Court, Ratnagiri",
          "Dongarmatha Eco Care Resort",
          "Fanashi, Ratnagiri",
          "Fansat forest, Ratnagiri",
          "Ganesh Visarjan Ghat, Devrukh",
          "Garva Agro Tourism",
          "Gavhe - Jalgaon, Dapoli",
          "Golap Grassland",
          "Guhagar",
          "Harnai Beach",
          "Juikar Mohalla, Maharashtra, IN (17.844, 73.093)",
          "Kalavande outskirts",
          "Kalbadevi Beach",
          "Karde Murud beach",
          "Karde, near Dapoli (OBI)",
          "Kazarghati, Ratnagiri",
          "Kelshi Village, Ratnagiri",
          "Keshavraj Temple",
          "Khau Gali, Ratnagiri",
          "Khed Railway Station",
          "Khed, Ratnagiri",
          "Kodjai river bank",
          "Kokan krishi vidyapith, Dapoli",
          "Kolkewadi Reserve",
          "Kolthare Beach",
          "Kondye Dam & Forest area",
          "KonkanKilbil, Palpene, Maharashtra, IN (17.523, 73.253)",
          "Koyna WLS -- Ghatmatha",
          "Kudavale Bhairi Devi Mandir (Sacred Grove)",
          "Kumbharli Ghat",
          "Ladghar Beach",
          "Lote Parshuram Industries Association area, Khed",
          "Mahalakshmi Temple",
          "Maharshi Parashuram College of Engineering, Velneshwar",
          "Mal Naka, Ratnagiri",
          "Mandangad",
          "Mandavi, Ratnagiri",
          "Mandavi Tourist Spot, Ratnagiri",
          "Marleshwar Sacred Grove",
          "Narayanmali river banks",
          "Nargoli",
          "Palande Beach, Dapoli",
          "Parshuram Ghat Bypass Road, Lote",
          "Parshuram hill, Chiplun, Ratnagiri",
          "Ram Teerth lake and surroundings",
          "Ratnadurg Fort",
          "Ratnagiri General (OBI)",
          "Ratnagiri Railway Station",
          "Ratnasagar Complex, Ratnagiri",
          "Samarth Krupa Woodland, Khed",
          "Savanekareen Devi Mandir",
          "Shiravali",
          "Shirgaon Mangroves",
          "Suvaranadurg, Harne (OBI)",
          "Swim Center, Salve Stop, Ratnagiri",
          "Tamastirtha beach",
          "Tivre-Medhe Viroba Temple",
          "Vanand plateau, Ratnagiri",
          "Vanand Village (OBI)",
          "Varshavan Nature Villa (Restricted access)",
          "Vashishthi River bank, Kaluste, Chplun",
          "Vashishti river banks, Matwadi",
          "Velas beach",
          "Velneshwar Beach",
          "Vindhyawasini Temple, Chiplun",
          "Ware beach",
          "Watershed Park Pond",
          "Whisting Farm Agrotourism Centre",
        ],
      },
      {
        district: "Osmanabad",
        localities: [
          "Apsinga-Begad lake, Tuljapur",
          "Chandani Dam",
          "Chilam kheda dam, Osmanabad Dt",
          "Dagad-dara dam, Tuljapur, Osmanabad",
          "Dahivadi lake, Tuljapur, Osmanabad",
          "Gangewadi",
          "Hatla Devi Hills",
          "Kalegaon dam, Arali",
          "Kamta lake, Osmanabad Dt",
          "Katgaon Lake, Osmanabad",
          "Kawaldara dam, Tuljapur, Osmanabad",
          "Masala lake, Tuljapur",
          "Naldurg Fort, Solapur",
          "Osmanabad lake",
          "Ram-dara dam, Tuljapur, Osmanabad Dt",
          "Ramling station, Yedshi",
          "Ruibhar-Khamaswadi Reservoir",
          "Shri Sidha Nagnath mandir, Kasari, Tuljapur",
          "Tata Institute of Social Sciences, Tuljapur",
          "Tirth Bhairoba temple Dam, Osmanabad Dt",
          "Warwanti dam, Osmanabad",
          "Yamai devi lake, Osmanabad Dt",
        ],
      },
      {
        district: "Latur",
        localities: [
          "Hati Lake, Latur",
          "Kava Lake, Latur",
          "Kingaon outskirts",
          "Latur city",
          "Masalga Dam, Latur",
          "Rena lake",
        ],
      },
      {
        district: "Mumbai Suburban",
        localities: [
          "Angriya Cruise , Mumbai, Maharashtra, IN",
          "BPT Park, Colaba",
          "Byculla Zoo & Museum Complex",
          "Colaba, Bombay",
          "Colaba Dock",
          "D.G. Ruparel College",
          "Gateway of India",
          "Girgaon Beach",
          "Grand Paradi, Mumbai",
          "Haji Ali Dargah",
          "Hindu Colony (Restricted access)",
          "Institute of Science, Fort, Mumbai",
          "J.J. Hospital Campus--New Establishment Quarters",
          "Kala Ghoda, Mumbai",
          "K J Somaiya, Mumbai",
          "Lalbaug, Mumbai",
          "Mahalaxmi Race Course, Mumbai",
          "Mahim Nature Park, Mumbai",
          "Malabar Hill, Mumbai (OBI)",
          "Mantralaya, Mumbai",
          "Mumbai -- Hanging Garden",
          "Nariman Point",
          "off Mumbai Harbour (OBI)",
          "Sassoon Dock",
          "Sewri Jetty, Mumbai",
          "Sewri Mudflat",
          "Shivaji park",
          "TIFR Grounds, Colaba",
          "Veer Mata Jijabai Bhosale Udyan & Zoo",
        ],
      },
      {
        district: "Jalgaon",
        localities: [
          "80 Feet Road Area",
          "Akulkheda outskirts",
          "Anjani Dam",
          "Anubhuti Residential School, Nagziri",
          "Badgujar Galli",
          "Bahula Dam",
          "Balya Maruti Temple Lake",
          "Bhavani Mandir, Jamner",
          "Bhusaval city",
          "Bhusawal Thermal Power Station (BPTS)--Wetland",
          "Bhusawal Thermal Power Station (BTPS)--500MW Premises",
          "Bhusawal Thermal Power Station (BTPS)--Between 210MW & 500MW",
          "Bhusawal Thermal Power Station (BTPS)--Deepnagar",
          "Bhusawal Thermal Power Station (BTPS)--Ganesh Mandir & 400KV",
          "Bhusawal Thermal Power Station (BTPS)--Ganesh Mandir & EM Building Area",
          "Bhusawal Thermal Power Station (BTPS)--Sewage Treatment Plant",
          "Bor Ajanti Ghat",
          "Chalisgaon--Hatale Dam",
          "College of Social Work, Chopda",
          "Dargah, Lalmati",
          "Dharangaon outskirts",
          "Dharangaon--Teli Talav",
          "Dr. Shyamaprasad Mukharji garden",
          "Farkande",
          "Fields near Chopda",
          "Garbage Depot",
          "Garbage Depot,Shivajinagar",
          "Garbage Depot, Shivaji Nagar",
          "Garbaldi Dam",
          "Golani Complex",
          "Gul dam",
          "Hartale Lake",
          "Hatnur Dam--General",
          "Hatnur Dam (IBA) - Changdev Mandir , IN (21.092, 76.007)",
          "Hatnur Dam (IBA) -- Muktai Mandir",
          "Hatnur Dam--Khamkhed",
          "Hatnur Dam--Mehun Mukatai Mandir",
          "Hatnur Dam--Tandalwadi",
          "Kandari, Bhusawal",
          "Kanva Ashram, Kanalda",
          "Kolhe Hills, Wagh Nagar, Jalgaon",
          "Krushi Vidnyan Kendra, Mamurabad Road",
          "Landorkhori Garden",
          "Mamlade Field (Restricted)",
          "Manudevi lake area",
          "Manudevi Temple forest area, Jalgaon",
          "Manyarkheda Lake",
          "Manyar Khede",
          "Mehrun Lake, Jalgaon",
          "MGSM's Arts, Science & Commerce College. Chopda",
          "Mhasave Lake, Mhasave",
          "Mohjay Talav, Bhusaval",
          "Muktai-Bhavani Conservation Reserve and Wildlife Sanctuary",
          "Nasirabad",
          "Nimbadevi Dam",
          "Ordnance Factory, Varangaon",
          "Ozarkheda Dam",
          "Pal Wildlife Sanctuary--View Point",
          "Patna devi, Jalgaon",
          "Pimprisekam-River Bhogawati",
          "R K Patel Nagar, Amalner",
          "RR Vidyalay, Jalgaon",
          "Satpuda Ghat area",
          "Seasonal lake near Akulkheda",
          "Smruti Udyan (Shivaji Udyan)",
          "Suki Dam--Back Water",
          "Tapti BTPS Reservoir",
          "Tapti Ghat, Bhusawal",
          "Tapti River Bank, Jalod",
          "Tapti River--behind BTPS",
          "Vellhale Lake, Jalgaon",
          "Waghur Dam",
          "Waghur dam back waters, Kandari.",
          "Waghur Dam -- Hingane",
        ],
      },
      {
        district: "Sindhudurg",
        localities: [
          "Aarosbag",
          "Amboli Forest",
          "Amboli Forest Park",
          "Amboli Ghat Waterfall, Amboli, Maharashtra",
          "Amboli Trail, Sawantwadi",
          "Amboli Village",
          "Bhide's hill top home-stay, Banda",
          "Bowlekar Wadi",
          "Charathe, Sawantwadi",
          "Chipi",
          "Chipi Grassland",
          "Chorla Ghat, MH section",
          "Deoolwada, Malvan",
          "Desai wadi Forest",
          "Devbag Beach",
          "Devgad (OBI)",
          "Devsu birding area",
          "Dhamapur Lake",
          "Dusankarwadi Grasslands",
          "Gothane outskirts",
          "Hublicha Maal, Nerur paar",
          "Jamsande, Devgad",
          "Kaleshwar, Nerur",
          "Kalse forest, Kalse",
          "Kalyadevachi Vanrai",
          "Kapil Nagar",
          "Khanoli Grasslands",
          "Kudal",
          "Kumbharli, Sawantwadi",
          "Kumbharmath swamps, Malvan",
          "Kunkeshwar Mandir",
          "Madkhol Dam",
          "Mahadevgad",
          "Mahadevgad Point Road",
          "Majgaon, Sawantwadi",
          "Malvan, Konkan Coast (OBI)",
          "Mathewada Road, Sawantwadi",
          "Moti lake",
          "Narendra Dongar, Sawantwadi",
          "Nayekhol satbai camp site",
          "Pangrad forest, Pangrad",
          "Paradise Lake Resort, Harkul Dam",
          "Parikshit Point, Amboli",
          "Parpoli",
          "Pat Parule Lake",
          "Pat Talav (Lake), Malvan",
          "Pendur (OBI)",
          "Run, Dangmode",
          "Sawantwadi",
          "Sawantwadi Dumping Ground",
          "Shipgram Hotel (Restricted)",
          "Shiravali outskirts",
          "Shiroda Bandwada school area",
          "Shiroda Beach",
          "Shiroda saltpan",
          "Sindhudurga Fort",
          "Talashil Beach, Sindhudurg",
          "Talkat Vanudyan",
          "Tarkarli Beach, Malvan",
          "Terwan Medhe Dam, Sindhadurg",
          "Tilari Conservation Reserve",
          "Tilari Reserve Forest, Sindhudurg",
          "Tulas-Ansur-Pal, Maharashtra, IN",
          "Valivande-Desai Wadi",
          "Vanoshi Forest Homestay",
          "Velagar Beach",
          "Vengurla Beach",
          "Vengurla Lighthouse",
          "Virdi Satre",
          "Whistling Woods, Amboli",
          "Whistling Woods Resort",
          "Wildernest Resort",
        ],
      },
      {
        district: "Raigad",
        localities: [
          "Akshi Beach",
          "Alibag",
          "Alibag beach",
          "Alibag--Gokuleshwar Lake",
          "Alibag Lake",
          "Alibag-Pen Road",
          "Aravi Beach",
          "Awalipada, Alibag Taluka (OBI)",
          "Awas Village, Alibaug",
          "Ballakeshwar Lake (Panvel Pond)",
          "Belpda birding area",
          "Chidipada, Alibag Taluka (OBI)",
          "Chirgaon, Raigad Dt",
          "Dhakate Shahapur (OBI)",
          "Dhamandanda",
          "Diveagar beach, Raigad",
          "Driving Range, Kharghar",
          "Elephanta Island, Mumbai",
          "Funde, Uran",
          "Ganesh pond, Sector 37 Kharghar",
          "Garudmaachi (Restricted access)",
          "Gotheghar (OBI)",
          "Harigram, New Panvel, Kalundre River",
          "Harihareshwar MTDC & Temple",
          "Iora cottages",
          "Jasai, Near Uran, Thane",
          "Jaskhar, Uran",
          "JNPT Area, Near Uran, Maharashtra",
          "Kalamboli water body",
          "Kalinje Mangoves Ecotourism",
          "Kalundre River near Rajiv Gandhi Park, Panvel",
          "Kalundre River, Vadghar Panvel",
          "Kankeshwar",
          "Karle Khind (OBI)",
          "Karnala Bird Sanctuary",
          "Karnala Bird sanctuary--Gaarmal_Ransai",
          "Karnala Bird Sanctuary--Hariyal trail",
          "Karnala Bird Sanctuary--Karnala Fort",
          "Kashid Beach",
          "Khanda Colony Lake",
          "Khanderi Fort",
          "Khargar Hills",
          "Kharghar Bird Watching Area",
          "Kharghar Creek, Sector 10",
          "Kharghar Creek--sector 1 wetlands",
          "Kharghar grasslands and freshwater wetlands",
          "Kharghar wetlands, Kharghar",
          "Kokandiva Fort",
          "Kurul Lake",
          "Kurul (OBI)",
          "Mandwa, Alibagh",
          "Mangaon",
          "Matheran",
          "Matheran Park",
          "Monkey point, Matheran",
          "Morapada--General Area (OBI)",
          "Murud Beach",
          "Murud-Janjira Fort",
          "Nadgaon",
          "Nagaon Beach",
          "Nagaon coastal area",
          "Owe dam",
          "Pagote - Uran",
          "Panje, Uran",
          "Panorama point, Matheran",
          "Phansad Wildlife Sanctuary",
          "Phansad WLS -- Chikhalgaon trail",
          "Phansad WLS -- Devrai trail",
          "Phansad WLS -- Supegaon",
          "Prabalgad Foothills",
          "Raigad Fort (OBI)",
          "Ramdharneshwar, Alibag",
          "Ramdharneshwar Hill (OBI)",
          "Ramdharneshwar (OBI)",
          "Ransai Reservoir",
          "Rasani Hill",
          "Revdanda Beach",
          "Rocky Shore Alibaug-Varsoli",
          "Roha- Varse",
          "Saaral Beach",
          "Saguna Baug, Neral",
          "Samadhan Pawar Birding Spot",
          "SansKruti Suites & Resort (Restricted Access)",
          "Saral bridge birding area",
          "Sarasgad Fort",
          "Sawarkhar Mangrove, Uran",
          "Sector 17, Kharghar",
          "Sector 24, Kharghar",
          "Sector 25, Kharghar",
          "Sector-25,Kharghar",
          "Sector 28, Kharghar",
          "Sector 29 grasslands, Kharghar",
          "Shilim Lonavala",
          "Shrivardhan",
          "Shrivardhan--Danda beach",
          "Shrivardhan--Shrivardhan beach",
          "Simson Tank, Matheran",
          "Taloja Creek",
          "Titave Forest",
          "T.S Rahaman",
          "Underi Island",
          "Uran, near Mumbai (OBI)",
          "Uran (OBI)",
          "Vadghar, Panvel",
          "Vaijanath (OBI)",
          "Vaje, Thane Dt",
          "Varsoli Beach, Near Alibag",
          "Veshvi, Alibag (OBI)",
        ],
      },
      {
        district: "Bhandara",
        localities: [
          "Banvantharti Dam, River side",
          "Bhondki Lake",
          "Canal near VaibhavLaxmiNagri",
          "Chandpur Dam forest trail",
          "Chargaon Lake",
          "Chichad Village near Indra Sagar Dam",
          "Chicholi wetland",
          "Dongargaon Lake",
          "Ekata Colony, Sakoli",
          "Gadkumbhali lake & surroundings",
          "Gundhari Reservoir",
          "Indira Sagar Dam - Gosekhurd",
          "Jamnapur Lake, Sakoli",
          "Khandala Wetland",
          "Koka WLS--Khurshipar Reservoir",
          "Koka WLS--Koka",
          "Korambhi-Salebardi Wetland",
          "Kurmuda Lake",
          "Lakhni Lake",
          "Lawari Lake",
          "Nagzira Wildlife Sanctuary--Jambhali",
          "Nagzira Wildlife Sanctuary--Pitezari",
          "Nursar Colony Near Bus stand Sakoli",
          "Nursery Khairlanji, Maharashtra, IN (21.103, 79.989)",
          "Old Railway Track, Ganeshpur, Bhandara.",
          "Panchgaon Lake",
          "Pauni Gate, Umred Karhandala Wild Life Sancturary",
          "Pauni-Nilaj road, Near Bus Stand",
          "Pindkepar Lake",
          "Rawanwadi Reservoir",
          "Rengepar Reservoir",
          "Right Bank Canal Sub Doors",
          "Satona Lake, Bhandara Ramtek Highway",
          "Sawarla Lake",
          "Selari Forest",
          "Shirsala Lake",
          "Sitepar wetland",
          "Ukara Lake",
          "Umari Lake",
          "Umred/Karhandala WLS --Pauni region",
          "Umred/Karhandla WLS- -Backwaters area",
          "Wahi Water Reservoir",
          "Waijeshwar Mandir, Wainganga River, Pauni",
          "Wainganga Protection Wall",
          "Walmazari Lake",
        ],
      },
      {
        district: "Kolhapur",
        localities: [
          "Adur birding area",
          "Alamprabhu Temple",
          "Ambabai temple sacred grove",
          "Ambeohal Dam, Maharashtra",
          "Anuskura",
          "Balikre Lake",
          "Barki",
          "Chandoli national park",
          "Chavare outskirts",
          "Dajipur WLS",
          "Danoli Warna dam area",
          "Dhuloba Hill",
          "Ghatge Lake",
          "Kalamba Reservoir",
          "Kalleshwar Talav, Shirol",
          "Kandvan spot",
          "Kurundwad Ghat",
          "Kutwad Marsh",
          "Mahalaxmi lake",
          "Manoli Dam",
          "Mhaisal Dam",
          "Mugali outskirts",
          "Nagnath temple, Kolhapur",
          "Nandyal-Mugali Bridge, Chikotra River",
          "Narsoba Wadi Temple",
          "near Kolhapur, Karnataka (OBI)",
          "near Kolhapur (OBI)",
          "New Palace Zoo, Kolhapur",
          "Palsambe sacred grove",
          "Panchganga river bank",
          "Panhala fort",
          "Patane--General Area (OBI)",
          "Pawankhind Resort (Restricted access)",
          "Radhanagari WLS",
          "Rajaram Talav",
          "Ramling",
          "Rankala Lake, Kolhapur",
          "Shendri Talav",
          "Shinoli (OBI)",
          "Shivaji University campus, Kolhapur",
          "Siddhoba Temple, Kolhapur, Maharashtra",
          "Sidhoba Mandir Narande, Sangli",
          "Sri Katyayani Temple",
          "Terani Dam",
          "Tilari dam Sindhudurg",
          "Tukai Hill",
          "Tulashi Dam",
          "Vedganga riverbanks - Shengaon",
          "Vishnu Lake",
          "Wild Dajipur Home Stay (Restricted Access)",
        ],
      },
      { district: "Gadchiroli", localities: ["Mendha Village"] },
      {
        district: "Solapur",
        localities: [
          "Ashti lake or lake of yeoti",
          "Bormani Grassland",
          "Ekruk Lake",
          "Goshala, Yamai Talao, Pandharpur",
          "Great Indian Bustard Sanctuary, Nannaj",
          "Hiraj Grasslands",
          "Kuduwadi",
          "Old Kambar Talav",
          "Rampur Lake",
          "Rupabhavani Plantation Solapur",
          "Sambhaji Lake, Solapur",
          "Shevte village",
          "Shukacharya",
          "Siddheshwar temple",
          "Siddheshwar Vanvihar",
          "Siddhivinayak lake",
          "Suncity Apartments (Restricted access)",
          "Vishnupad temple, Solapur",
          "Wadval (Solapur)",
          "Walchand College of Arts and Science",
          "Yamai Lake Pandharpur",
          "Yavli",
        ],
      },
      {
        district: "Chandrapur",
        localities: [
          "Agarzari Gate",
          "Alizanza Gate",
          "Anandvan, Warora",
          "Asola Mendha Lake",
          "Balharshah Railway Station, Chandrapur",
          "Bikli Lake",
          "Chandrapur--General Area",
          "Chandrapur (OBI)",
          "Devada- Adegaon Gate",
          "Drc pahadi",
          "Ghodazari Lake",
          "Jatepar Talav",
          "Junona Gate",
          "Junona lake, Chandrapur",
          "Kasarla Lake",
          "Kharabi Lake",
          "Khutwanda Gate",
          "Kolara Buffer Zone",
          "Korambi Lake",
          "Lake near Adhyad Hill, Brahmapuri",
          "Lawari lake",
          "Mamala Gate",
          "Mindala lake",
          "Moharli, Chandrapur",
          "Moharli Gate",
          "MTDC Resort, Moharli, Chandrapur",
          "Navtala Lake",
          "Pandhar pavni",
          "Pangadi",
          "Sarangadh",
          "Sath Bahini Temple Lake",
          "Somnath project, Chandrapur",
          "Sonapur Lake",
          "Tadoba -- Alijanza Buffer Zone",
          "Tadoba Andhari reserve -- Kolara gate",
          "Tadoba Andhari reserve -- Madnapur Gate",
          "Tadoba Andhari Tiger Reserve",
          "Tadoba Andhari Tiger Reserve--Dewada Buffer Area",
          "Tadoba Andhari Tiger Reserve--Katezari trail",
          "Tadoba Andhari Tiger Reserve--Tadoba lake",
          "Tadoba -- Erai Dam",
          "Tadoba -- fuljhari lake",
          "Tadoba National Park",
          "Tadoba National Park - Pangdi Gate",
          "Tadoba--Navegaon Gate",
          "Tadoba--Navegaon-Ramdegi Gate",
          "Tadoba NP",
          "Tadoba NP -- Pangadi Gate",
          "Tadoba NP -- Shirkada Gate",
          "Tadoba NP -- Water Reservoir, Sirkada",
          "Tadoba -- Red earth tadoba",
          "Tadoba -- Svasara Lodge",
          "Tadoba -- TAARU VANN",
          "TATR--Tiger Heaven Resort Navegaon Ramdegi Gate Tadoba",
          "Telia Lake, Moharli",
          "The Bamboo Forest Safari Lodge",
          "tigress@ghosri",
          "United-21 Tiger Camp Resort, Bhamdeli, Moharli",
          "Zari- Peth Gate",
        ],
      },
      {
        district: "Aurangabad",
        localities: [
          "Ajanta-andhari dam",
          "Ajanta Caves",
          "Ajanta Village",
          "Aurangabad Caves",
          "Bibi Ka Maqbara",
          "Botanical Garden (BAMU), Aurangabad",
          "Daulatabad Fort",
          "Dr. Babasaheb Ambedkar Marathwada University, Aurangabd",
          "Ellora Caves",
          "Gandheli Dam Aurangabad",
          "Gautala Wildlife Sanctuary",
          "Gogababa, Aurangabad",
          "Himayat Bagh",
          "Jaikwadi Bird Sanctuary",
          "Janjala fort, Aurangabad Dt",
          "Khandi pimpalgaon",
          "Khelna dam, Aurangabad",
          "Mahatma Gandhi Mission (MGM) Campus, Aurangabad",
          "Mukpath village, Aurangabad",
          "Navkhanda College, Aurangabad",
          "Nehru Udyan",
          "Palaskheda dam",
          "Palaskheda, Jalgaon",
          "Pitalkhora caves, Jalgaon",
          "Salim Ali Lake, Aurangabad",
          "Sillod, Aurangabad Dt",
          "Sukhana Dam, Aurangabad",
          "View Point Ajanta Caves, Aurangabad",
          "Wadi fort, Aurangabad Dt",
        ],
      },
      {
        district: "Wardha",
        localities: [
          "Alodi",
          "Bajaj Institute of Technology Backside",
          "Borkhedi Talav, Wardha",
          "Bor Tiger Reserve --Manoli",
          "Dhotewade Dam",
          "Digraj, Wardha",
          "Dongargaon Dam",
          "Giroli",
          "Government Polytechnic College, Arvi",
          "Hanuman mandir, Laprasy Foundation, Swastik Colony",
          "Itaki doh, Wardha",
          "Lal Nalla Reservoir, Wardha",
          "Lanco Vidarbha Thermal Power Plant",
          "Madan Dam",
          "Madan Dam, Wardha",
          "Malegaon Theka dam, Wardha",
          "New Bor WLS",
          "Pavnar River, State Hwy 204, Wardha",
          "Pothara Dam,Hinganghat",
          "Ring road, Sindhi Meghe",
          "Rotha-II Dam and Reservoir, Wardha",
          "Rotha Reservoir I, Wardha",
          "Sarangpuri Lake",
          "Sindhi Meghe, Wardha",
          "Sukali (Ubar), Wardha",
          "Waghdhara talao Ã Â¤ÂµÃ Â¤Â¾Ã Â¤ËœÃ Â¤Â§Ã Â¤Â°Ã Â¤Â¾ Ã Â¤Â¤Ã Â¤Â²Ã Â¤Â¾Ã Â¤Âµ",
        ],
      },
      {
        district: "Washim",
        localities: [
          "Chausala Forest Range",
          "Ekbhurgi dam, Washim",
          "R.T.O. Office, Washim",
          "Washim Railway station",
        ],
      },
      {
        district: "Yavatmal",
        localities: [
          "Bahiram Hill, Jam Range, Yvatmal",
          "Bembla Dam -- Malapur wetland",
          "Bembla Dam-- Panas",
          "Bembla wetland",
          "Borgaon Dam, Babhulgaon Tahsil",
          "Chapdoh Dam",
          "Chausala forest road",
          "Chintamani Temple, Darwha",
          "Darwha Grasslands",
          "Deogiri parvat, Darwha, Yavatmal",
          "Deulgaon Valsa Van Udyan",
          "Dumnapur Forest Nursery, Yavatmal (OBI)",
          "Durge Forest and Dam, Yavatmal",
          "East side wetland area of Nilona dam",
          "Echori dam and Grasslands",
          "Echori - Jamwadi road,Yavatmal",
          "Echori road stream, Yavatmal",
          "Forest colony, Darwha",
          "Ghatana lake Hiwari, Yavatmal",
          "Goki Dam",
          "Heti Spillway and Reservoir (OBI)",
          "Indira Mahavidyalaya",
          "Jam Forest Range, Yavatmal (OBI)",
          "Jamwadi Tank",
          "Khateshwar",
          "Khateshwar Spillway and Reservoir (OBI)",
          "Kolhi, Yavatmal",
          "Mahur Bridge, Painganga River",
          "Mandev Spillway and Reservoir (OBI)",
          "Mucchi Talav (Lake)",
          "Nilona dam -- Grasslands",
          "Nilona dam - North side",
          "Nilona Dam spillway and reservoir",
          "Nilona dam -- wall side, Yavatmal",
          "Nilona dam -- West South",
          "Northeast side of Bembla Dam Yavatmal",
          "Painganga WLS",
          "Pathrad Dam",
          "Pathradgole Spillway and Reservoir (OBI)",
          "pathrat lake, Darwha",
          "Prasha Van, Yavatmal",
          "Prayas Van, Yavatmal",
          "Saikheda Dam",
          "Shindi Lake",
          "Singhandov Spillway & Reservoir, Yavatmal (OBI)",
          "South east area of Nilona dam",
          "Tembhurni lake",
          "Tipeshwar WLS, Yavatmal",
          "Umerda Forest area, Yavatmal (OBI)",
          "Umerda forest nursery, Yavatmal",
          "Van Udyaan, Darwha",
          "Van vibhag udyaan",
          "Wai Talav",
        ],
      },
      {
        district: "Pune",
        localities: [
          "Mulshi, Pune",
          "Sai Farm Urawade",
          "Tolani Maritime Institute",
          "Quadron Business Park, Hinjewadi",
          "Jakhini-aai devrai",
          "Nanded City",
          "Katraj lake (Rajiv Gandhi Zoo), Pune",
          "Pune international Airport",
          "Netwad Dam",
          "Pabe village",
          "Tamhini Nature Nest Campus",
          "Pirangut Ghat",
          "Pashan Tekdi",
          "Raja Mantri Udyan(Garden)",
          "Aadarwadi",
          "Bhimashankar WLS--Hindola",
          "Ghoradeshwar Hills",
          "Bhigwan -- Bhandalwadi lake bird sanctuary",
          "Baba Bhide Bridge, Pune",
          "Peruchi Baug(Guava garden),Kothrud",
          "makkah shahi masjid",
          "Bavdhan (PVPIT) ravine",
          "Wageshwar Lake",
          "Blue Ridge Township",
          "Kalkai Devrai (sacred grove), Pune",
          "Uksan Lake",
          "Manakeshwar",
          "Thokarwadi Lake, Lonavala",
          "Ramdara Lake, Pune",
          "Bhandara Hill",
          "Jubilee Park Warje",
          "Lakaki Lake, Pune",
          "Banyan tree area,Symbiosis International University",
          "Bhigwan -- Grasslands",
          "Bhimashankar WLS--Blue Mormon Hotel",
          "Ambavane pass(Ghoralyachi Naal-source-tributary to Amba river",
          "Pune University--Alice Garden",
          "Mohammed wadi Forest Area",
          "Lavasa Lake",
          "Torna Fort",
          "Saswad-Bhongaleshwar Temple",
          "Sumanth Sarovar",
          "Khadakwasla Reservoir -- Malkhed area",
          "Kasarsai Dam, Pune",
          "Forest Garden near Omega Retreat, Lonavala",
          "Kurtuwadi stream",
          "Sinhgad Fort",
          "Salumbre village,(Mawal Taluka),Pune",
          "Panchavati Hill",
          "ST Arnold's Central School",
          "Sarasbaug, Pune",
          "Parinche",
          "Dalaj",
          "Mula River",
          "Shivtirth Nagar, Kothrud",
          "NDA Road, Pune",
          "Manjari Bridge, Pune",
          "Splendour Country, Pune",
          "The Machan, Lonavla",
          "Kudje-Mutha Road",
          "Shive Devrai",
          "Chittaranjan Vatika",
          "Bhor",
          "Parinche (OBI)",
          "Hadashi surroundings",
          "Bhimashankar WLS--Lahuri dongar (Hill)",
          "Elysium Society (Restricted access)",
          "David Sassoon Asylum,Navi Peth",
          "Sawarkar Nagar, Daund",
          "Nerhe Hills",
          "Mulaai devrai",
          "Dr. Salim Ali bio diversity park",
          "Kondhanpur",
          "Morachi Chincholi",
          "Velhe, Pune (OBI)",
          "Dingrajwadi - Old Neem Tree",
          "Jovan-Pawna lake environs",
          "Paschimanagari",
          "Rajyog Society",
          "Tasu bai Temple, Kalhat",
          "Tatya Saheb Thora Udyan, Pune",
          "Rihe Khind",
          "Khandala Ghat area",
          "Phirangai-devi hill, Pune",
          "Bhimashankar WLS--Omkar Hotel area",
          "Sant Tukaram Bridge, Ravet",
          "Taljai Tekdi, Pune Dt",
          "Sinhagad Fort",
          "International Institute of Information Technology",
          "Saswad, Pune Dt",
          "Indapur RF (OBI)",
          "Kundalika View Point",
          "Riverside road, Anandnagar, Pune",
          "Lohagad Fort forest",
          "Pashan Lake near Outlet Point",
          "Vidya Pratishthan's ASC college baramati",
          "Ujani Dam",
          "Ambavane:Forest bungalow to Korai fort gate.",
          "Rajgad fort, Pune",
          "Veer grasslands",
          "IMDR Gate, Fergusson college, Pune",
          "Warwadi",
          "Jambhulwadi Lake, Ambegaon",
          "Shree Chintamani Temple, Theur",
          "poonawala stud farms, theur, hadapsar",
          "Mastani lake, Pune",
          "Daund scrubland",
          "Lonavala (OBI)",
          "MIT World Peace University",
          "Bhaje Caves, Lonavala",
          "Bird valley lake, nigadi, pune",
          "Mutha river -- Mhatre bridge",
          "Gokulnagar plateau near NDA",
          "NIBM Campus, Pune",
          "Talegaon lake",
          "MES Abasaheb Garware College",
          "National Centre for Radio Astrophysics, TIFR",
          "Bopalghar devrai(sacred grove),Tal.Velhe,Dist.Pune",
          "Forest Area Wanowrie",
          "Shri Dongarachi Aai Mandir",
          "Nana nani park, Nigdi",
          "Lavasa Road",
          "Sopan Baug Housing Society",
          "Pimple Nilakh",
          "Dighi Hills",
          "Jogging track by canal, Hingne Khurd",
          "Symbiosis International University--Director's Bungalow",
          "Bhigwan -- Kumbhargaon Bird Sanctuary",
          "Old Aundh bridge",
          "Tamhini Nature Nest",
          "Walhe, Jejuri",
          "IMDR Canteen, Pune",
          "Bhatghar Dam, Pune",
          "Kavdi Pat",
          "Kranti Flamingo Point",
          "Pune (560m) (OBI)",
          "Sahyadri School (KFI), Pune",
          "Udachiwadi",
          "Baner Hill, Pune",
          "Kurtuwadi Devrai",
          "Botanical Garden, Bopodi, Pune",
          "Hilton Shillim Resort",
          "Erandawane-Canal jogging path",
          "Pataleshwar Temple",
          "River Beautification Project Park",
          "Water body-Symbiosis International University",
          "Agharkar Institute campus",
          "Askarwadi",
          "Panshet-Khalapur road",
          "Poona Club Golf Course",
          "Dahanukar Colony Garden, Pune",
          "CTW Lake (Restricted Access)",
          "Pune University--Botanical Garden, Botany Department",
          "Raj baug eco-village, patharshet, Pune Dt",
          "IUCAA-Inter-University Centre for Astronomy and Astrophysics",
          "Wardayani devrai, Kurtuwadi",
          "Bhimashankar WLS--Gupt Bhimashankar",
          "Mula River, Pimple Nilakh",
          "Aundh military camp",
          "Shivam Hospital, Near Loni",
          "Kaldari",
          "Ghorwadeshwar hill, Bhegdewadi, Pune Dt",
          "NCL Colony",
          "Mahatma Society, Kothrud",
          "National Centre For Cell Science, Pune, Maharashtra",
          "ARAI Hills (Vetal Tekdi)",
          "Bhigwan -- Agneepankh birding area",
          "Army Land",
          "CME Campus (Restricted access)",
          "Panshet Dam",
          "Pabe ghat",
          "Ambavane Forest Bungalow compound",
          "Kare Ayurveda & Yoga Center",
          "Mayureshwar WLS",
          "Shaniwar Wada",
          "Fergusson College Campus",
          "Wipro Campus Pune",
          "Bhimashankar WLS--Machaan",
          "Pashan Lake Nilgiri Plantation",
          "Waghapur",
          "damodar nagar",
          "Bhimashakar WLS--Bailghat",
          "Kothrud, Pune (OBI)",
          "Papal Seminary Woodland area",
          "Sahajanand Tekdi",
          "The London Bridge",
          "Anandvan, Kondhwa",
          "Ahupe village",
          "Bhukum, Pune",
          "Osho Teerth Park",
          "Degaon",
          "KPIT Technologies Campus (Restricted access)",
          "Infosys DC, Pune",
          "Jambhvali birding area",
          "Sinhagad Valley",
          "MIT Institute of Design, Loni Kalbhor",
          "Mula River -- Baner",
          "The Bishops School, Pune",
          "Symbiosis International University, Lavale",
          "Warwand (Victoria) lake, Pune",
          "Upper lake , CME, Pune (Restricted access)",
          "Kalewadi",
          "Pusane dam",
          "Mulkhed",
          "Sangamwadi Bridge, Pune",
          "Panchawati, Pune (OBI)",
          "Kumar Prerana Society",
          "National Defence Academy (Restricted Access)",
          "Eco Park - Nanded City - Pune",
          "Ness Wadia College of Commerce Campus",
          "Parvati temple, Pune",
          "Aga Khan Palace",
          "Southern Command",
          "Indian Institute of Tropical Meteorology, Pune",
          "Pasure",
          "Peshwa's Katraj Lake",
          "Valvan dam reservoir, Pune",
          "Khadakwasla Dam",
          "Pune University",
          "Kudje",
          "CPS  campus",
          "Tropical Camping, Khulshi",
          "Tukaai Temple",
          "CME periphery (Restricted access)",
          "Kadave Ghat",
          "Mahatma Hill",
          "Bedse Buddhist Caves area",
          "Udhyog Nagar, Chinchwad",
          "Baneshwar",
          "Wadgaon Sheri(18.545, 73.927)",
          "Bavdhan Hill",
          "Vishrambaug Wada",
          "NIBM Sub Post Office",
          "Hanuman tekdi",
          "Takrarwadi-Madanwadi",
          "Duke's Nose trek",
          "Gokulnagar Hill",
          "Shriram Society (Restricted access)",
          "Malhargad",
          "Vetal Hill",
          "Dongerwadi",
          "Mahindra United World College of India",
          "Muredha Nala Park",
          "Panawri Ghat (OBI)",
          "Thoran Village, Kamshet, Maharashtra",
          "Pingori village(Jejuri)",
          "Dive Ghat",
          "Tolani Maritime Institute--Lake",
          "CME- Ganesh Nagar edge (Restricted access)",
          "Shirsai Bird Sanctuary",
          "Bhigwan -- Vitthalwadi",
          "Cemetery Road, Riverside",
          "Sus Hill",
          "Aghane village,Pune",
          "Ela Habitat, Jejuri",
          "Teak County",
          "Bhugaon",
          "Morgaon",
          "Bhuleshwar Temple",
          "Tamhini forest, Pune",
          "Mhatoba hill",
          "Automotive Research Association of India, Pune (OBI)",
          "Club Mahindra Lonavala",
          "Khandoba maal",
          "Bund Garden - Ambedkdar Bridge",
          "Bhimashankar WLS",
          "Giant Metrewave Radio Telescope",
          "Bhigwan -- Diksal",
          "Anandvan Mitra Mandal Park",
          "Jambhulwadi (Asane)",
          "Shirsuphal pond",
          "IISER Pune",
          "Datta Madir-Symbiosis International University",
          "Bhimashankar WLS--Yelavali",
          "Rajewadi Lake",
          "Vanpuri",
          "Kirkat Wadi (OBI)",
          "Tathawade Garden",
          "Bhorgiri fort area",
          "Mandvi riverside and farms",
          "Tata Dam, Lonavala",
          "Girawali Observatory - IUCAA",
          "Saswad-Parinche road",
          "Kolvan",
          "Forest Trails, Saffronstays, Lonavala (Restricted Access)",
          "Bhigwan Flamingo Bird Sanctuary",
          "Pashan Lake, Pune",
          "Chas Kaman Dam",
          "Mhalunge",
          "Shambhoji Mahadeo Devrai",
          "Bharati Vidyapeeth Institute of Environment Education & Research (BVIEER)",
          "Rajiv Gandhi Zoological Park, Pune",
          "Race course",
          "Kesnand, Wadebolai",
          "Camp 365",
          "mhasoba temple",
          "Gavkos Resort",
          "Nala Park",
          "Ecological society restoration site, Panshet",
          "Nigade",
          "Fields North of PVPIT",
          "Bhimashankar WLS--Kondava",
          "Bhigwan -- Dhumalwadicha Naalaa",
          "Nere",
          "Bhigwan -- Bhigwan Hills",
          "Patas Lake",
          "Shirsuphal Lake",
          "Uday baug",
          "Shree Shiv Chatrapati Sports Complex, Balewadi",
          "MIDC Dry lands and dry deciduous forest",
          "Durga tekdi (Hill), Pune",
          "Peacock Bay, NDA, Pune",
          "Pune (General) (OBI)",
          "Purandar fort & environs",
          "Girivan Glory",
          "Matoba Lake",
          "Gulmohar Orchids",
          "Hinjewadi, Pune",
          "Vitthal Mandir Riverstretch",
          "Sanewadi Park",
          "Kanifnath Temple",
          "Pu la Deshpande garden",
          "Manas Lake, Bhugaon, Pune",
          "Pawana Lake, Landing zone",
          "Vadgaon (OBI)",
          "Katarkhadak-Andhale, Pune",
          "Empress Botanical Garden",
          "Plateaue above Vasistha Guesthouse",
          "Sakaar Organics, Pavhar BK",
          "Rutuparna Society, Baner",
          "NCL School, Pune",
          "Shah, Indapur",
          "Dr Salim Ali Bird Sanctuary",
          "Kamalini Kutir (Restricted access)",
          "joggers park",
          "Pimpelgaon Joga Dam",
          "Kandalgaon, Ujjani Reservoir",
          "Chinmaya Vibhooti",
          "SM Joshi Bridge",
          "Magarpatta City",
          "Warje Hill",
          "Karvenagar Nala",
          "Mutha River, Nanded City",
          "Prabhat Road, Lane no. 10, Pune 411004",
          "Madhe Ghat",
          "Bopdeo Ghat, Pune",
          "Mastani lake near Patas, Pune",
          "Satalwadi",
          "Gawade Ghat, Chinchwadgaon",
        ],
      },
      {
        district: "Nagpur",
        localities: [
          "Adegaon",
          "Ajni Van",
          "Ambazari Garden",
          "Ambazari Lake--Backwaters, Nagpur",
          "Ambazari Lake, Nagpur",
          "Ambedkar International Airport, Nagpur",
          "bandra,Nagpur,Maharashtra",
          "Central Mine Planning & Design Institute (CMPDI)",
          "Chandrabhaga reservoir",
          "Chandrakiran Nagar, Nagpur",
          "Chargaon lake",
          "Cherry Farm Camp, Ramtek",
          "Chikhali",
          "Children's Traffic Park, Nagpur",
          "Dahegaon Lake",
          "Degma forest, Nagpur Dt",
          "Degma, Village",
          "Dhantoli -- General",
          "Dongargaon Lake",
          "Dudha Lake",
          "Gandhi Sagar Lake",
          "Gorewada Lake, Nagpur",
          "Gothangaon outskirts",
          "Go-Vigyan Anusandhan kendra, Deolapar",
          "Hazaripahad, Nagpur Dt",
          "Hingna, Nagpur",
          "Javahar Navodaya Vidyalaya,  Bhivagad",
          "Jhulelal Institute of Technology",
          "Junapani lake, Amravati highway",
          "Kamptee Ash Pond",
          "Kamptee--dry area",
          "Karhandla wildlife lake",
          "Karhandla WLS -- Lake",
          "Kekra Nala Dam",
          "Khapari lake, Amravati highway",
          "Khapri lake",
          "Khursapur Gate, Pench TR",
          "Kirmati village",
          "KORADI",
          "Koradi, Nagpur Dt",
          "Lake View Enclave (Restricted access)",
          "Lonare lake",
          "Ludra Mata Mandir, Nagpur",
          "Maharajbagh zoo, Nagpur",
          "Makardhokda Dam",
          "Mansar Excavation Site, Nagpur Dt",
          "Matkazari Lake",
          "Mihan, Nagpur",
          "Nagpur Outskirts (OBI)",
          "Nagpur Railway Station",
          "Nandera Dam and Reservoir.",
          "National Environmental Engineering Research Institute (NEERI)",
          "Paradgaon Dam, Nagpur",
          "Pench Dam",
          "Pench National Park--Chorbahuli Gate",
          "Pench National Park -- Totladoh reservoir",
          "Pench Tiger Reserve, Khubala Safari Gate",
          "Pench Tiger Reserve--Kolitmara",
          "Pench Tiger Reserve, Maharashtra",
          "Pench Tiger Reserve, Salama Check Point",
          "Pench Tiger Reserve--saleghat",
          "Pench Tiger Reserve, Sillari Gate, Maharashtra",
          "Phutala Lake, Nagpur",
          "Police Line Tank, Nagpur",
          "Raj Bhavan, Nagpur",
          "Ramtek, Nagpur Dt",
          "Sahakar Nagar birding area",
          "Saiki Lake, Nagpur",
          "Salai Mendha dam and forest, Nagpur",
          "Sasegaon Lake",
          "Satpuda Botanical Garden",
          "Seminary Hills, Nagpur",
          "Shankarnagar, Nagpur (OBI)",
          "Shri Ramdeobaba College of Engineering and Management",
          "Sirpur",
          "Sivana Greens, Kondhali",
          "Sonegaon Lake, Nagpur",
          "Surabardi Lake, Nagpur Dt.",
          "Suradevi pond",
          "Telangkhedi Lake--Agroforestry side",
          "Telankhedi Garden, Nagpur",
          "Telhara Reservoir, Nagpur",
          "Umred Karhandla WLS -- Gothangaon Range",
          "Umrer Karhandla WLS",
          "Vena dam, Nagpur Dt",
          "VIMS Hospital area",
          "VNIT Campus",
          "Wadadh Lake 1",
          "Wakeshwar Dam, Nagpur",
          "Wildlife Conservation Trust campus",
          "Yerla, Nagpur",
          "Zari",
          "Zilpi lake and forest, Nagpur",
        ],
      },
      {
        district: "Nandurbar",
        localities: [
          "Tokartalav area",
          "Toranmal Reserve Forest, Nandurbar Dt",
          "Toranmal WLS -- Kelapani-Kotbandhani Area",
          "Toranmal WLS -- Machindranath",
        ],
      },
      {
        district: "Gondia",
        localities: [
          "Bandh talao",
          "Bandhtalao, Gondiya",
          "Bazartola, Gondiya",
          "Bazartola Lake",
          "Chulband Dam",
          "Dahegaon, Gondia",
          "Gauri doh(pond),Nagzira outskirt",
          "Ghiwari lake",
          "Gondia Railway Station",
          "Gonditola Lake",
          "Gothangaon area",
          "Hanuman Mandir, Kalimati",
          "Itiadoh dam",
          "kHADBANDA DAM",
          "Lohara Lake, Gondia",
          "Lohara Village, Gondia",
          "Makadi Lake",
          "Nagzira WLS",
          "Nagzira WLS--Chorkhamara",
          "Nagzira WLS--Nagzira Lake",
          "Navegaon bandh Lake",
          "Navegaon Nagzira tiger Reserve Gondiya",
          "Navegaon National Park--Dam",
          "Nawegaon Bandh (OBI)",
          "Nilaj Lake",
          "Palasgaon Wetland",
          "Pangadi Reservoir",
          "Pindakepar lake,Nagzira outskirt",
          "Rengepar tank,Nagzira outskirt",
          "Salagtola Lake",
          "Serpur bird sanctuary, Paraswada Lake",
          "Sioni Lake",
          "Soundad Lake",
          "Zilmili Lake",
        ],
      },
      {
        district: "Beed",
        localities: [
          "Beed Dt.",
          "Bendura River",
          "Guttewadi, Parli, Beed",
          "Morachi Chincholi",
          "Mukundraj Swami Valley, Ambajogai",
          "Mukundraj Valley Ambajogai",
          "Pus, Ambajogai",
          "Sindphana Dam -- Madhmapuri Trail",
          "Tagadgaon Dam, Shirur (kasar)",
        ],
      },
      {
        district: "Nashik",
        localities: [
          "Amrutdham",
          "Anjaneri Hill",
          "Ankai Tankai Fort",
          "Borgad Conservation Reserve",
          "Club Mahindra, Hatgad",
          "College road",
          "Deolali Cantonment",
          "Dugarwadi",
          "Flyash Pond, Eklahare, Nashik",
          "Gangapur Dam Back water",
          "Gangapur Dam backwaters, Nashik",
          "Gangapur Forest Nursery, Nashik",
          "Goda kath nursery",
          "Goda Park",
          "Grape County",
          "Harihar fort",
          "Harsul forest",
          "Harsul Lake",
          "Kashyapi Dam",
          "Mahatma Nagar",
          "Mamdapur Conservation Reserve, Rajapur",
          "Markhandya Fort",
          "Nandur Madhmeshwar Bird Sanctuary (Watch Tower 1)",
          "Nandur Madhmeshwar (Manjargaon)",
          "Nandur Madhyameshwar Bird Sanctuary",
          "Nashik (OBI)",
          "Pandavlena Caves, Nasik",
          "Parijat nagar",
          "Pathardi, Nashik Annex - I",
          "Pathardi Nashik annex - II",
          "Pathardi Phata",
          "Pratap Enclave, Deolali",
          "Rohile outskirts",
          "Sandip University campus",
          "Trimbakeshwar Forest",
          "Vaitarna Dam",
          "Vijay Nagar Garden, Dindori Road",
          "Vulture Restaurant, Khoripada",
          "Waghad Dam",
          "Windmill Farm, Sinnar",
          "Yashwantrao Chavan Maharashtra Open University (YCMOU)",
        ],
      },
      {
        district: "Akola",
        localities: [
          "Akot Forest Office",
          "Andura, Near Akola",
          "Borgaon Manju Reservoir",
          "Chaitanya's Farmhouse (Restricted access)",
          "Dagadparwa Dam, Akola",
          "GNA Science and Art College Barshitakli",
          "Kapsi Talao (lake), Akola",
          "Karanja, Akola (OBI)",
          "Katepurna WLS, Maharashtra.",
          "Kumbhari, Akola",
          "kumbhari lake, Akola",
          "Masa Grassland, Akola, Maharashtra",
          "Melghat Tiger Reserve -- Shahanur",
          "Narnala Fort",
          "Nursery PDKV",
          "Panjabrao deshmukh agri university",
          "Patur suvarna river, Akola",
          "Popatkhed Reservoir",
          "RanpiseNagar open ground",
          "Shankarlal Khandelwal Arts, Science And Commerce College",
          "Shiwar khadan lake, Akola",
          "Sisa Udegaon Lake",
          "Sukali Talaw",
        ],
      },
      {
        district: "Satara",
        localities: [
          "Agashiv Hill",
          "Ajinkyatara Fort",
          "Ambamata Mandir Koyana Nagar",
          "Ambheri Ghat",
          "Arthur's Seat, Mahabaleshwar",
          "Avakali, Panchgani, Maharashtra",
          "Blue Park Nature resort",
          "Chalkewadi",
          "Chandoli NP -- Helwak range",
          "Chandoli WLS -- khundlapur gate",
          "Darjai Lake",
          "Dhamani birding area",
          "Dhondewadi Lake",
          "Diamond Hill Resort",
          "Dragon Lake Ranand",
          "Forest Trail, Old Mahabaleshwar",
          "Hatti Talav, Satara",
          "Jog Math Datta Mandir",
          "Kaas  Plateau, Satara",
          "Kankatre dam",
          "Karvat forest area",
          "Kass Lake",
          "Khinger Ring Rd",
          "Kiraksal Biodiversity Region-- Dambi Hills",
          "Kiraksal Biodiversity Region-- Kuran Forest Range",
          "Kiraksal Biodiversity Region--Nali grasslands",
          "Koyana WLS",
          "Koyna WLS--Kathi",
          "Koyna WLS -- Ozarde Trail",
          "Koyna WLS -- Tapola",
          "Krishna River banks, Karad",
          "Kumbharoshi, Pratapgad",
          "Kumthe Nagache Mountain",
          "Kuroli Reservior, Varud",
          "Lingmala Waterfall",
          "Lodhawade Lake",
          "Mahabaleshwar",
          "Mahabaleshwar--Babington hose trail",
          "Mahabaleshwar -- Gureghar Nature Trail",
          "Mahabaleshwar--Robber's cave trail",
          "Mayani Bird Sanctuary--CR",
          "Mayureshwar Sanctuary",
          "MRA PACHGANI",
          "Ner Dam,Satara.Maharashtra",
          "Old Karad-Patan bridge, Karad",
          "Panchgani",
          "Pargaon Talav",
          "Pateshwar Temple, Degaon",
          "Pedgaon Lake",
          "Pingali Lake",
          "Rajewadi Dam",
          "RiversideInn",
          "Sahyadri Tiger Reserve",
          "Sajjangad, Parali",
          "Sangam Mahuli",
          "Satara District Veterinary Polyclinic",
          "Satara (OBI)",
          "Shri Datta Mandir Math, Bamnoli",
          "Sindola plateau area-Wilson Point",
          "Sundargad Fort",
          "Suryachiwadi Dam",
          "Table Land, Panchgani",
          "Tambave Lake, Satara",
          "Tarkeshwar, Vaduj",
          "Thoseghar, Satara",
          "Triveni Sangam,Bamnoli",
          "Umbarde Bhavling Mountain",
          "Valley Point, Old Mahabaleshwar",
          "Vansanshodhan Kendra",
          "Vardhangad Fort",
          "Veer Dam",
          "Wai-Panchgani-Pangari (Satara)",
          "Yeliv Talav",
          "Yerala River, Vaduj",
          "Yeralwadi Dam",
        ],
      },
      {
        district: "Jalna",
        localities: [
          "Dhawda village, Jalna",
          "Jwala Lawns, Jalna",
          "Moti Talab (Lake)",
          "Nangar Taas Temple",
          "Prithisudhaji Nagar, Jintur Road, Jalna",
          "Priyanka Residency, Jalna",
          "Shelud Dam, Jalna",
        ],
      },
      {
        district: "Mumbai",
        localities: [
          "Angriya Cruise , Mumbai, Maharashtra, IN",
          "BPT Park, Colaba",
          "Byculla Zoo & Museum Complex",
          "Colaba, Bombay",
          "Colaba Dock",
          "D.G. Ruparel College",
          "Gateway of India",
          "Girgaon Beach",
          "Grand Paradi, Mumbai",
          "Haji Ali Dargah",
          "Hindu Colony (Restricted access)",
          "Institute of Science, Fort, Mumbai",
          "J.J. Hospital Campus--New Establishment Quarters",
          "Kala Ghoda, Mumbai",
          "K J Somaiya, Mumbai",
          "Lalbaug, Mumbai",
          "Mahalaxmi Race Course, Mumbai",
          "Mahim Nature Park, Mumbai",
          "Malabar Hill, Mumbai (OBI)",
          "Mantralaya, Mumbai",
          "Mumbai -- Hanging Garden",
          "Nariman Point",
          "off Mumbai Harbour (OBI)",
          "Sassoon Dock",
          "Sewri Jetty, Mumbai",
          "Sewri Mudflat",
          "Shivaji park",
          "TIFR Grounds, Colaba",
          "Veer Mata Jijabai Bhosale Udyan & Zoo",
        ],
      },
      {
        district: "Nanded",
        localities: [
          "Ishwar Nagar",
          "Limbgaon Lake",
          "Mundkhed Station, Nanded",
          "Nanded Railway station",
          "Patnur Temple",
          "RAMESHWAR",
          "Ratneshwari Temple",
          "Shikhachi vadi, Nanded",
          "Sitakhandi Lake",
          "Trikut, Nanded",
        ],
      },
      {
        district: "Parbhani",
        localities: [
          "Marathwada agriculture university, Purbhani",
          "Niwali Lake, Maharashtra",
          "Purna Junction, Purbhani Dt",
        ],
      },
      {
        district: "Palghar",
        localities: [
          "Gaskopari Wetlands",
          "Zai Beach, Palghar",
          "Vasai Fort, Vasai",
          "Nareshwadi Learning Centre (NLC)",
          "Tungareshwar NP",
          "Bhuigaon Beach Vasai",
          "Unbhat Beach",
          "Virar, Palghar Dist (OBI)",
          "Pelhar Dam",
          "Matrix School, Virar",
          "Nirmal Lake, Nalsopara",
          "Krishna Township, Vasai West",
          "Atmalingeshwar Temple, Shivansai",
          "Mangrove & Marine Biodiversity Information Center, Marambalpada Jetty",
          "Chikhal Dongari, Virar",
          "Mamachi Wadi Beach, Arnala",
          "Arnala Beach",
          "Viva wetlands, Virar",
          "Murba khadi",
          "Shirgaon Raypada Talav",
          "Papad khindi dam, Virar",
          "Gogte saltpans vasai",
          "Tungareshwar WLS -- Waterhole",
          "Umroli wetlands",
          "Datiware Beach, Palghar, Maharashtra",
          "Virar--Sri Prastha",
          "Kurze dam, Thane",
          "Bena, Near Suruchi Beach",
          "Tarapur Creek",
          "Rajavali Marshes, Vasai",
          "Umela Wetland, Vasai",
          "Chinchani",
          "Padmavati Nagar wetland, Virar",
          "Waghoba",
          "Kelwa Beach",
          "Chinchani beach",
          "Jyoti Wetlands, Virar",
          "Jawhar Ghat",
          "Datiware",
          "Suruchi beach",
          "Burmapada near Bhuigoan beach",
          "Usarni Beach",
          "Murba creek",
          "Merces, Near Suruchi beach",
          "Bhuigaon Village-- Vasai",
          "Takmak Gadh, Sakawar",
          "Hira Dongari, Vasai",
          "Vaitarna Nagar",
          "Nala Sopara General (OBI)",
        ],
      },
      {
        district: "Thane",
        localities: [
          "Chikloli Dam, Thane",
          "Lonad Caves Trail",
          "Dawdi hill",
          "Thane east saltpans",
          "Dahagaon",
          "Khoni Pond",
          "Haji Malang",
          "Thane creek Flamingo sanctuary, Airoli",
          "Dombivli (OBI)",
          "Sanjay Gandhi NP--Tikujiniwadi",
          "Vashi Lake",
          "Tahuli foothills, Badlapur",
          "Nisarga Mitra Farm area, Badlapur",
          "Hill Grange Garden",
          "Kolsewadi to Kalyan Station, Maharashtra, IN (19.231, 73.137)",
          "Kalsubai Harischandragad WLS--Valmiki Ashram,Dehene",
          "Ulhas River, Diva, Dombivli",
          "Sagar vihar",
          "Seawoods--Navi Mumbai",
          "Siddhagad Fort",
          "DAKS lake, Navi Mumbai",
          "Aai waghjai temple",
          "Diva - Kopar Estuary",
          "Padle Village (OBI)",
          "Gothegar--General Area",
          "Agro Garden, Belapur",
          "Bhopar grasslands, Thane",
          "Thane (OBI)",
          "Thakurli Wetlands",
          "Talawe Wetlands (OBI)",
          "Ambaji Gau Shala, CBD Belapur",
          "Nilje Lake,",
          "Sanjay Gandhi NP -- Yeoor hills",
          "Harshgiri-gota Lake",
          "Green Valley Park, Belapur (OBI)",
          "Tansa WLS--Quaripada",
          "Vasantdada Patil Marg Sewage",
          "Karave Wetlands",
          "Haji Malang Road, DombivlI (OBI)",
          "SGNP--Borivade, Kasarwadawli",
          "Umbarli gaon",
          "Belapur bush lake",
          "Raheja complex",
          "Sunshine Elegance - Thakurli (Restricted Access)",
          "Vashi Holding Pond, Vashi, Mumbai, Maharasthra",
          "Nagla trail, Sanjay Gandhi NP",
          "Satpool Wetlands",
          "Parsik Hills, Thane (OBI)",
          "Kharghar Hills",
          "Badlapur Hills--proposed panvel highway",
          "Kalyan East Railway Area",
          "Kopargaon",
          "Kalher Bunder, Bhiwandi",
          "Umbarli hills",
          "Mothi Desai Village Park (Padle)",
          "Flamingo Watching Point",
          "Gandhari Forest Land",
          "Mahuli Fort Base",
          "TS Chanakya College, Navi Mumbai",
          "Panvel Creek View - Sector 11",
          "Shahapur, Thane (OBI)",
          "Nerul Wetland Flamingo Point",
          "Malshej",
          "Karnala devi kalyan",
          "Navi Mumbai (OBI)",
          "Mothi Desai Lake",
          "Coastal and marine biodiversity center",
          "Flamingo point, Nerul",
          "Khidkali lake, Dombivli",
          "Kalwa Hills (Mountain next to fast track), Kalwa",
          "Kalyan Gandhari (OBI)",
          "Ganesh Ghat",
          "Jambul pada, ulhas river",
          "Sulochana Singhania School",
          "Rotary garden",
          "Bhimashakar WLS--Gorakhgad",
          "Manda Birding Area, Titwala",
          "Gavdevi Mandir, Kharad-Malang",
          "Tansa Wildlife Sanctuary",
          "Talao Pali Lake, Thane",
          "Kachrali Lake, Thane",
          "Ambivli Riverside Area, Thane",
          "Uttan Jetty",
          "Kachore Hills",
          "Dandepada, Chinchani",
          "Kopar Wetlands- Juni Dombivli -- Dombivali",
          "Lake DPS school, Navi Mumbai",
          "Kachore Pond, Kalyan",
          "Barvi Lake",
          "CHANAKYA COLLEGE ROAD",
          "Kedar Bunglows, D'souza Wadi",
          "NMMC Head Office Pond",
          "Vashi mangrove",
          "Mohraan Farms",
          "Palu Village, Murbad",
          "Sanjay Gandhi NP--Yeoor",
          "NAUPADA GREEN PATCH",
          "Tansa Forest Office",
          "Bamandev Mandir",
          "Sanpada Mangrove, Navi Mumbai",
          "Ghansoli Ram Mandir birding area",
          "Thane Creek",
          "B.N. Bandodkar College of Science",
          "DPS Lake",
          "Barvi Forest Range",
          "Pajar lake",
          "Sparrow Park (Chiu Park)",
          "Belapur, Navi Mumbai (OBI)",
          "Kopar Creek-- Dombivali",
          "Khoni Wetland",
          "Kolshet Creek",
          "Mahuli Fort",
          "Titwala Mandir Garden",
          "Ovalekar Wadi Butterfly Garden",
          "Ganeshghol temple, Shilphata",
          "Seawoods Flamingo Refuge",
          "Mumbra Hills, Mumbai",
          "Reti Bandar Ulhas River Dombivli West",
          "Mumbra Mudflats",
          "Dawadi",
          "Lotus Pond Ambernath",
          "Green Valley Park",
          "Diva Dumping Area",
        ],
      },
      {
        district: "Hingoli",
        localities: [
          "Audha nagnath, Hingoli",
          "Bhategaon Dam",
          "Chirag Shash Darga",
          "Chudawa station, Purbhani Dt",
          "Hingoli Railway station",
        ],
      },
      {
        district: "Buldhana",
        localities: [
          "Anand Sagar, Shegaon",
          "Botha Reservoir and Spillway",
          "Buldhana City",
          "Dyanganga WLS, Buldhana Dt",
          "Dyanganga WLS--Dam",
          "Garadgaon Reservoir",
          "Hiwarkhed Reservoir and Spillway",
          "Janephal",
          "Januna Lake, Khamgaon",
          "Khamgaon, Buldhana Dt",
          "Lonar lake, Buldhana",
          "Malkapur, Buldhana Dt",
          "Mas Reservoir and Spillway",
          "Mehkar (OBI)",
          "Nandura town, Buldhana",
          "Patoda Dam & Spillway",
          "Pentakli Reservoir and Spillway",
          "Shirla Dam",
          "Sindkhed Raja",
          "Waghali temple and lake area",
          "Wasali, Ambabarawa",
          "Yelgaon dam, Buldana",
        ],
      },
      {
        district: "Dhule",
        localities: [
          "Aarbhuja mata mandir",
          "Aner Reservoir, Jalgaon",
          "Dedorgaon Talaw",
          "Dr. Panjhara Road, Dhule",
          "Gartad",
          "Gondur Lake",
          "Gondur Lake Road",
          "Haranmal lake & surroundings",
          "Hingne Lake, Pimperkhede",
          "Khamkheda Reservoir",
          "Laling Fort Reserve Forest",
          "Laling Reserved Forest, Dhule",
          "Lamkani grassland, Dhule Dt",
          "Nakane Lake",
          "Nimdale Forest",
          "Nimdale Lake",
          "Nimdale shiwar",
          "Sonvad Reservoir, Dhule",
          "Tikhi New Dam",
          "Wadel Grassland",
        ],
      },
    ],
  },
  {
    state: "Manipur",
    districts: [
      { district: "Pherzawl", localities: [] },
      {
        district: "Imphal West",
        localities: [
          "Changangei (OBI)",
          "Iroisemba (OBI)",
          "Khumbong (OBI)",
          "Lamphelpat",
          "Lamphelpat (OBI)",
          "Langol Reserved Forest",
          "Manipur University--Main Campus",
          "National Institute of Technology, Manipur",
          "Phoijing, Manipur (OBI)",
          "Regional Institute of Medical Sciences",
          "Yaiskul Police Lane, Imphal (OBI)",
        ],
      },
      { district: "Tengnoupal", localities: ["Kwatha"] },
      { district: "Thoubal", localities: [] },
      {
        district: "Kakching",
        localities: ["Kharung Pat (OBI)", "Wabagai (OBI)"],
      },
      { district: "Chandel", localities: ["Tangjeng Pat (OBI)"] },
      {
        district: "Tamenglong",
        localities: [
          "Azuram (OBI)",
          "Charinapang (OBI)",
          "Chiulaun (OBI)",
          "Zeilad Wildlife Sanctuary (OBI)",
        ],
      },
      {
        district: "Senapati",
        localities: ["Senapati Dist (OBI)", "Tungjoy (OBI)"],
      },
      { district: "Noney", localities: [] },
      { district: "Churachandpur", localities: ["Lamdan (OBI"] },
      { district: "Ukhrul", localities: ["Shirui Kashong Range (OBI)"] },
      { district: "Kangpokpi", localities: ["Sadu Chiru Waterfall"] },
      {
        district: "Imphal East",
        localities: ["Imphal East District (OBI)", "Matai village (OBI)"],
      },
      { district: "Jiribam", localities: [] },
      { district: "Kamjong", localities: [] },
      {
        district: "Bishnupur",
        localities: [
          "Keibul Lamjao NP",
          "Khoijuman Village (OBI)",
          "Kumbi Pat (OBI)",
          "Loktak Lake",
          "Moirang (OBI)",
          "Nambol (OBI)",
          "Nambol Phoijing (OBI)",
          "Naorem (OBI)",
          "near Saiton (OBI)",
          "Phubala (OBI)",
        ],
      },
    ],
  },
  {
    state: "Meghalaya",
    districts: [
      {
        district: "East Garo Hills",
        localities: ["Nokrek NP--Darengiri-Jetragiri Route"],
      },
      {
        district: "East Jaintia Hills",
        localities: [
          "East Jaintia Hills (OBI)",
          "Narpuh Reserved Forest",
          "Saibual",
          "Saipung",
        ],
      },
      { district: "North Garo Hills", localities: [] },
      {
        district: "West Jaintia Hills",
        localities: [
          "Dawki",
          "Dawki River--Dawki Bridge",
          "Haven's Cove, Jowai",
          "Kopili Dam--Saphai",
          "Salaroh, Jowai (OBI)",
          "Tuber (OBI)",
        ],
      },
      {
        district: "South Garo Hills",
        localities: [
          "Baghmara--Simsang Bridge",
          "Balpakram NP (general area)",
          "Balpakram Tourist Lodge",
          "Bhowanipur",
          "Karwani",
          "Karwani Village",
          "Nokrek NP--Darangiri to Nokrek Range",
          "Nokrek NP--Darengiri Village",
          "Nokrek NP (general area)",
          "Panda",
          "Pilot Project, Ampanggiri",
          "Siju",
          "Siju Bird Sanctuary",
          "Siju Bridge Trail",
        ],
      },
      {
        district: "West Garo Hills",
        localities: [
          "Chandigre",
          "Mesak Falls Trail",
          "Nokrek NP--12 Year Rested Jhum",
          "Nokrek NP--Daribokgre (Daribok Village)",
          "Sasatgre",
          "Tura Peak RF",
        ],
      },
      { district: "South West Khasi Hills", localities: ["Rangthong"] },
      {
        district: "West Khasi Hills",
        localities: ["Maweit", "Ukium Picnic Spot"],
      },
      { district: "South West Garo Hills", localities: [] },
      {
        district: "East Khasi Hills",
        localities: [
          "Arwah Lumshynna Cave",
          "Blueberry Inn Resort",
          "Cherrapunjee Holiday Resort",
          "Cherrapunji (Sohra)",
          "Cherrapunji (Sohra)--Pomsohmen Viewpoint",
          "Dawki River--Dawki",
          "Duwan Sing Syiem Viewpoint",
          "Elephant Falls",
          "Happy Valley--ARC Hillocks",
          "Itshyrwat Reserved Forest",
          "Laitkinsew (OBI)",
          "Laitlum Canyons--Smit Valley",
          "Mawlynnong",
          "Mawphlang Sacred Grove",
          "Mawryngkneng",
          "Mawsmai Cave",
          "Mawsynram",
          "Mynteng--Single Root Bridge",
          "NEHU--Shillong Campus",
          "NEHU--Shillong Campus--EFL University",
          "NEHU--Shillong Campus--Old Guest House Trail",
          "Nohkalikai Falls",
          "Seven Sisters Waterfall, Nohsngithiang",
          "Shillong",
          "Shillong (OBI)",
          "Trail from Cherrapunji to Double Decker Bridge",
          "Trail from Nongriat to Double Root Bridge/Rainbow Falls",
          "Tripura Castle, Cleave Colony",
          "Upper Shillong PF",
          "Upper Shillong PF--Laitkor Peak",
          "Upper Shillong PF--Lawsohtun",
          "Upper Shillong PF--Lumparing",
          "Upper Shillong PF--Malki",
          "Upper Shillong PF--Rhododendron Trek",
          "Upper Shillong PF--Risa Colony",
          "Ward's Lake and Botanical Garden",
        ],
      },
      {
        district: "Ri-Bhoi",
        localities: [
          "Byrnihat",
          "Guwahati-Shillong Road (OBI)",
          "Jirang Community Reserve & Amkyrbai Lake",
          "Nongkhyllem WLS",
          "Rani RF, Meghalaya (OBI)",
          "Ri Shat Sngi Orchid Resort, Umiam",
          "Rosie Homestay, Jyntru",
          "The Sanctuary Song Resort",
          "Umiam Lake (Dam sait)",
          "Umiam Lake--Nehru Park",
          "Umiam Lake--Orchid Lake Resort",
          "University of Science and Technology Meghalaya",
        ],
      },
    ],
  },
  {
    state: "Mizoram",
    districts: [
      {
        district: "Lawngtlai",
        localities: [
          "Phawngpui Blue Mountain National Park",
          "Rangamati (near India-Myanmar border) (OBI)",
          "Sangau (OBI)",
        ],
      },
      { district: "Siaha", localities: [] },
      { district: "Khawzawl", localities: [] },
      { district: "Saitual", localities: ["Tamdil Lake"] },
      { district: "Lunglei", localities: [] },
      { district: "Serchhip", localities: [] },
      { district: "Hnahthial", localities: [] },
      {
        district: "Champhai",
        localities: [
          "Lengteng Wildlife Sanctuary",
          "Murlen National Park--General Area",
        ],
      },
      {
        district: "Kolasib",
        localities: ["Bairabi", "Serlui", "ZeroPoint to Buhchang"],
      },
      {
        district: "Aizawl",
        localities: [
          "Aizawl Zoological Garden",
          "Bungbangla",
          "Dilkawn, Aizwal (OBI)",
          "Hmuifang",
          "Mizoram University Campus",
          "Pachhunga University College",
          "Sailam",
          "Sialsuk",
          "Tourist Resort Lengpui (OBI)",
        ],
      },
      {
        district: "Mamit",
        localities: [
          "Dampa Tiger Reserve",
          "Dampa Tiger Reseve--Damparengpui",
          "Reiek",
          "West Phaileng to Teirei Road",
          "Zawlnuam, Mizoram (OBI)",
        ],
      },
    ],
  },
  {
    state: "Nagaland",
    districts: [
      { district: "Mokokchung", localities: [] },
      {
        district: "Dimapur",
        localities: [
          "Dimapur Airport",
          "Dimapur (OBI)",
          "Forest Office, Dimapur",
          "Nagaland Zoological Park",
          "National Institute of Technology (NIT) Nagaland",
          "Niathu Resort",
          "Rangapahar, Dimapur (OBI)",
          "Unity College, Dimapur",
        ],
      },
      {
        district: "Wokha",
        localities: [
          "Doyang",
          "Doyang Beat Office, Wokha",
          "Forest Office, Wokha",
          "near Benreu (OBI)",
          "Neepco",
          "Pangti Village (OBI)",
          "VKV Campus, Doyang",
        ],
      },
      {
        district: "Peren",
        localities: ["Benrue (OBI)", "Southern Nagaland (OBI)"],
      },
      {
        district: "Kohima",
        localities: [
          "Dziileriidi Trail",
          "Dzukou Valley",
          "Dzuleke--2.5km towards Heunambe Village/Benreu",
          "Dzuleke--4kms",
          "Dzuleke--Welcome Signboard",
          "Dzuluke",
          "Khonoma--Adler Cottage Trail (Merhu Zie)",
          "Khonoma--Govt. High School",
          "Khonoma--Native Lodge",
          "Khonoma Nature Conservation and Tragopan Sanctuary (KNCTS)--Parking Lot",
          "Khonoma--NSF Memorial (Monolith)",
          "Puliebadze WLS",
          "Stream between Khonoma & Dzuleke",
          "Tragopan Breeding Centre, Kohima",
          "View Point before Khriesaneisa Memorial",
        ],
      },
      { district: "Phek", localities: ["Kade, Kami Village", "Phek (OBI)"] },
      {
        district: "Kiphire",
        localities: [
          "Fakim Wildlife Sanctuary",
          "Government Middle School, Pungro",
          "Pungro (OBI)",
          "Thanamir Village",
          "Wuhrihtoh",
        ],
      },
      { district: "Zunheboto", localities: [] },
      { district: "Longleng", localities: [] },
      { district: "Mon", localities: ["eastern Nagaland, India (OBI)"] },
    ],
  },
  {
    state: "Odisha",
    districts: [
      {
        district: "Angul",
        localities: [
          "chhotkei nature camp , satkosia",
          "Rantalei",
          "Satkosia NP--Tikarpada Char",
          "Satkosia NP--Tikarpada Nature Camp",
          "Satkosia Tiger Reserve",
          "Tikarpada WLS -- George view point",
        ],
      },
      {
        district: "Balangir",
        localities: [
          "Deogaon Sagar, Deogaon, Balangir",
          "Fuljharan and Taljharan Forests",
          "Jiramaya Sagar",
          "khujenpali",
          "Krupasindhu Dam",
          "Larkipali Dam",
          "Upper Jiramaya Forest, Balangir",
        ],
      },
      {
        district: "Baleswar",
        localities: [
          "Bichitrapur Mangrove Sanctuary",
          "Chandipur Beach",
          "Kuldiha",
          "Kuldiha Wildlife Sanctuary",
          "Panchalingeswar Forest",
          "Rissia Dam",
          "Rissia Nature Camp Gohirabhola",
          "Rissia Nature Camp, Kuldiha",
          "Talasari--Udaypur",
          "Talsari",
        ],
      },
      {
        district: "Bargarh",
        localities: [
          "Ambabhona",
          "Debrigarh wildlife sanctuary",
          "Debrigarh WLS",
          "Hirakud, Burla",
          "Right Dyke Road",
        ],
      },
      {
        district: "Bhadrak",
        localities: [
          "Bhitarakanika Jetty point near Chandbali",
          "Pantha Nibasa, Chandbali ghat",
          "Pirahat, Bhadrak",
        ],
      },
      {
        district: "Boudh",
        localities: ["Gopalpur lake", "Kantamal, Bauda, Odisha"],
      },
      {
        district: "Cuttack",
        localities: [
          "Anshupa Lake",
          "CDA SEC 10",
          "Chhatisa Pato, Odisha, IN (20.541, 85.867)",
          "Cuttack-Athagarh Rd",
          "Cuttack Town",
          "Dalijoda Forest (20.633, 85.862)",
          "Dalijoda Forest, Odisha, IN (20.621, 85.87)",
          "Dompara-Arachandi Wetlands, Odisha, IN",
          "Gayala Banka",
          "Gayala Bank, Banki - Cuttack Road, Odisha",
          "Mahalahat, Jagatsinghpur, Odisha",
          "Mahanadi Bridge-- Jobra Anicut",
          "Mundali",
          "Naraj",
          "Naraj Barrage",
          "Netaji Subhas Bose Setu, Kathajodi River, Cuttack",
          "Ravenshaw University",
          "Sri Sri university",
        ],
      },
      { district: "Deogarh", localities: [] },
      {
        district: "Dhenkanal",
        localities: [
          "Dandadhara Dam",
          "Jamusara Pond",
          "Khalpal Reserve Forest",
          "Koriapal School",
          "Shyamcharanpur",
        ],
      },
      {
        district: "Gajapati",
        localities: ["Khasada waterfall", "Lakhari Wildlife Sanctuary"],
      },
      {
        district: "Ganjam",
        localities: [
          "Berhampur University",
          "Chilika Lake - Narayani Temple trail, Chhatargarh",
          "Chilika Lake--Rambha",
          "Chilika Lake--Rambha Jetty",
          "Chilika Lake--Swosti Resort",
          "Gahirmatha Marine Wildlife Sanctuary",
          "Ganjam--Potagarh fort",
          "Gopalpur Junction",
          "Gopalpur on sea",
          "Gopalpur-on-Sea (OBI)",
          "Potagarh",
          "Rushikulya Beach",
          "Rushikulya River mouth",
          "Samala",
        ],
      },
      {
        district: "Jagatsinghpur",
        localities: [
          "Bandar, Jagatsinghpur, Odisha, India",
          "Devi River Estuary Bandar, Odisha",
          "Fishing Harbour, Mahanadi River Estuary, Paradeep, Jagatsinghpur,",
          "Paradeep Sea Beach, Paradeep, Odisha, India",
          "PPL Plant waterbody, Paradeep, Odisha, India",
          "Tarapur, Jagatsinghpur, Odisha, India",
        ],
      },
      { district: "Jajpur", localities: [] },
      {
        district: "Jharsuguda",
        localities: [
          "Belpahar, Jharsuguda",
          "Hirakud Dam Reservoir and Mudflats",
          "Singhabaga",
          "Unnamed Road, Singhabaga, Odisha, IN (21.821, 83.97)",
        ],
      },
      {
        district: "Kalahandi",
        localities: [
          "Jakam, Karlapat Wildlife Sanctuary, Kalahandi, Odisha",
          "Karlapat WLS",
          "Niyamgiri",
        ],
      },
      {
        district: "Kandhamal",
        localities: [
          "Charichhak, Boudh",
          "Coffee Garden, Daringbadi",
          "Coffee Garden Daringibadi",
          "Mandasaru Nature Camp",
          "Putudi, Kandhamal, Odisha",
        ],
      },
      {
        district: "Kendrapara",
        localities: [
          "Bhitarakanika NP -- Satavaya",
          "Bhitarkanika",
          "Bhitarkanika-Dangamal",
          "Bhitarkanika--Dangamal Jetty",
          "Bhitarkanika-- Khola",
          "Bhitarkanika-Khola Gate",
          "Bhitarkanika National Park, Odisha, IN",
          "Bhitarkanika NP--Satabhaya",
          "Bhitarkanika Sanctuary, Odisha",
          "Kendrapara Autonomous College, Kendrapara",
          "Satabhaya, Bhitarkanika National Park, Kendrapara, Odisha",
        ],
      },
      {
        district: "Kendujhar",
        localities: [
          "Hadagarh wildlife Sanctuary",
          "Kundapitha, Ghatagaon, Keonjhar, Odisha",
        ],
      },
      {
        district: "Khordha",
        localities: [
          "Barbara forest",
          "Barbera Forest, Salia, Khorda",
          "Behind Trident Galaxy",
          "Bhubaneswar Railway Station",
          "BJB (Autonomous) College, Lewis Road, BJB Nagar, Bhubaneswar, Od",
          "Buddha Jayanti Park, Bhubaneshwar",
          "Buddha Statue Park, Bhubaneshwar",
          "Centurion University of Technology and Management, Bhubaneswar",
          "Chandaka--Jhumka Dam Road, Godibari, Odisha, India",
          "Chandaka Reserve Forest, Bhubaneswar, Odisha",
          "Chandaka Reserve Forest--Deras Dam",
          "Chilika Lake--Barkul",
          "Chilika Lake - Kansari",
          "Chilika Lake--Mangalajodi",
          "Daruthenga",
          "Dasapur",
          "D.A.V Chandrasekharpur Campus 2",
          "Daya River Bridge",
          "Ekamra Kanan, Bhubaneshwar",
          "Forest Park, Bhubaneshwar",
          "Ghanga patana, Near Shobha Samal Home",
          "Godwit Eco Cottage",
          "Indian Institute of Technology (IIT) Bhubaneswar",
          "Kalinga Stadium",
          "Kapileswar-Sundarpada-Madhipur Wetlands, Bhubaneswar (20.207, 85.807)",
          "Kapileswar-Sundarpada-Madhipur Wetlands, Bhubaneswar (20.229, 85.827)",
          "Kesora-Badagada-Jharpara Wetlands, Bhubaneswar (20.257, 85.866)",
          "Madhipur, Botonda, Bhubaneswar,OD IND",
          "Mangalajodi Nature Camp",
          "Mangalajodi wetland",
          "Manglajodi Eco Tourism",
          "Nandan Kanan Zoological Park, Bhubaneswar",
          "NISER Cricket Ground",
          "NISER, JATNI",
          "Orakal-Kuha-Kantilo-Padhansahi, Sundarpada-Jatni Road, Bhubaneswar, Odisha IN (20.202, 85.796)",
          "Orissa University of Agriculture And Technology",
          "Regional Plant Resource Centre campus (RPRC), Bhubaneswar (OBI)",
          "ROTI (Revenue Officers Training Institute) Gothapatana Road",
          "RPRC, EKAMRA KANAN ROAD, IRC VILLAGE",
          "SBI Colony Area, Kesora, Bhubaneshwar",
          "Utkal University",
        ],
      },
      {
        district: "Koraput",
        localities: [
          "Amtiguda, Koraput, Odisha, IN (18.782, 82.747)",
          "Central University of Odisha (CUO)--Sunabeda Campus",
          "Deomali Hill, Odisha",
          "Duduma waterfalls",
          "Gomalput Lake",
          "Gupteswar",
          "Kalapada",
          "Kotpad",
          "Machkund town",
          "Rajuguda Coffee Plantation, Odisha",
        ],
      },
      {
        district: "Malkangiri",
        localities: [
          "Balimela Dam , Chitrakonda, Malkangiri",
          "Gudiyapadar",
          "Satiguda",
          "Udaygiri caves",
        ],
      },
      {
        district: "Mayurbhanj",
        localities: [
          "Balidhia dam",
          "Bankabal Dam",
          "Barehipani Nature camp",
          "Baripada",
          "Chahala, Simlipal national park",
          "CREFTDA, Office",
          "Gurguria, Simlipal national park",
          "Jambhira (Deuli) dam",
          "Joranda waterfall , simlipal national park",
          "North Odisha University",
          "Palogoda, Simlipal",
          "Pithabata check post, Lulung",
          "Salbani Rest House, Forest Department, Odisha",
          "Similipal National Park",
          "Similipal--Pithabata forest check gate",
          "Simlipal--Barehipani",
          "Simlipal-Khairi River Bed,",
          "Simlipal National Park",
          "Sitakund",
        ],
      },
      { district: "Nabarangapur", localities: ["Ampani Corridor"] },
      {
        district: "Nayagarh",
        localities: [
          "Jiginipada",
          "Kantilo, Odisha, India",
          "Kuanria Dam, Nayagarh, Odisha",
          "Mahanadi Wildlife Division",
          "Satakosia NP -- Sandbars Anugul and Nayagarh",
          "Satkosia Gorge Sanctuary",
          "Satkosia Sands Resort",
          "Satkosia Tiger Reserve",
          "Satkosia Tiger Reserve, Badmul, Nayagarh, Odisha",
        ],
      },
      {
        district: "Nuapada",
        localities: ["Pendrawan marsh", "Sunabeda Wildlife Sanctuary"],
      },
      {
        district: "Puri",
        localities: [
          "Baliapanda Beach, Puri",
          "Balukhand - Konark Reserve Forest",
          "Brahmagiri Road, Satapada",
          "Chandrabhaga Beach",
          "Chilika Lake",
          "Chilika Lake - Barunkuda",
          "Chilika Lake - Kalijai Temple",
          "Chilika Lake - Nalabana Bird Sanctuary",
          "Chilika Lake--New Sea Mouth",
          "Chilika Lake - Parikud",
          "Chilika Lake--Rajhans Island",
          "Chilika Lake--Satapada",
          "Chilka Sea mouth Beach",
          "Dhaudia River Muhana",
          "Dhauligiri",
          "Golara",
          "Janhikuda, Odisha",
          "Keutakuda",
          "Nature Camp, Konark",
          "Puri (OBI)",
          "Puri Panthanivas, Odisha",
          "Rajhans beach, Odisha",
          "Rajhans, Odisha, IN",
          "Satapada",
          "Satapada--Mitakuoo",
          "Satapada--Osthoperi",
        ],
      },
      {
        district: "Rayagada",
        localities: ["Kankubadi", "Kumdabali,Rayagada,Odisha"],
      },
      {
        district: "Sambalpur",
        localities: [
          "Belapada, Sambalpur",
          "Budharaja Hill",
          "Fashbuda, Mahanadi River Bank, Sambalpur, Odisha",
          "jamadarpali",
          "Pardesi Para",
          "Power Channel Road",
        ],
      },
      {
        district: "Subarnapur",
        localities: [
          "Ainlachhat Jungle",
          "Arjunpur Forest, Kalapathar",
          "Binka, Sonepur, Odisha",
          "Bisagar Bandh, Sonepur",
          "Dahaja Forest Range, Sonepur, Odisha",
          "Kharapura Jungle",
          "Patali Shreekhetra, Meghnad, Subarnapur",
          "Singhjuri Jungle, Subarnapur, Odisha",
          "Sonepur, Odisha",
          "Tel Nadi River Bank, Sonepur",
          "Tel River near Kustapali Subarnapur",
          "Thengo Dam, Subarnapur, Odisha",
        ],
      },
      {
        district: "Sundargarh",
        localities: [
          "Hemgir Forest",
          "Hemgiri Kanika",
          "Khandadhar, Sundargarh, Odisha",
          "Rai Baga",
          "Sankara Pokhri (Lake)",
          "Talsara Rengali Dam",
        ],
      },
    ],
  },
  {
    state: "Puducherry",
    districts: [
      {
        district: "Karaikal",
        localities: [
          "Government High School, Oozhiapathu, Karaikal",
          "Karaikal Beach",
          "Karaikal Railway Station",
        ],
      },
      { district: "Mahe", localities: ["MahÃƒÂ© Beach", "Mahe"] },
      {
        district: "Puducherry",
        localities: [
          "Acharampattu Lake (Ã Â®â€¦Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Annai Sivagami Girls Higher Secondary School",
          "Ansari Durai Sami GHSS, Thondamanatham",
          "Arasanakere Ã Â²â€¦Ã Â²Â°Ã Â²Â¸Ã Â²Â¨Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Arumbarthapuram-Kombakkam Road",
          "Arutchelvi Ayee Ammal GMS, Mutharaiyarpalayam",
          "Bahour Lake--PY Side (Ã Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿--Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿)",
          "Bahour Road",
          "Bed Dam Vadukuppam-Pandacholanallur Road, Vadukuppam, Tamil Nadu, IN (11.862, 79.662)",
          "Benapatti cattle trailÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Bharathidasan Government College for Women",
          "Bharathi Park",
          "Botanical Garden",
          "Botanical Garden (Ã Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â®Â°Ã Â®ÂµÃ Â®Â¿Ã Â®Â¯Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â¯â€š\bÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾)",
          "Chinna Veerampattinam Beach",
          "Chunnambar (Ã Â®Å¡Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â±Ã Â¯Â)",
          "Directorate of School Education",
          "District Institute of Education and Training, Lawspet",
          "Elathur Pond (Ã Â®ÂÃ Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Embalam Lake (Ã Â®ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Government High School, Kalmandapam",
          "Government High School, Pillaichavady",
          "Government High School, Sellipet",
          "Government Middle School, Ariyankuppam",
          "Government Middle School, Kariamanickam",
          "Government Middle School, Pitchaviranpet",
          "Government Primary School, Thengaithittu",
          "Govt. Boys Middle School, Delarshpet",
          "Govt. Girls Primary School, Bahour",
          "Govt. Higher Secondary School, Karayambuthur",
          "Govt. High School, Kanuvapet",
          "Govt. High School, Pillaiyarkuppam",
          "Govt. High School, Sandhai Pudukuppam",
          "Govt. Primary School, Ayyankuttipalayam",
          "Govt. Primary School, Boomianpet",
          "Govt. Primary School, Chinnayampet",
          "Govt. Primary School, Dharmapuri",
          "Govt. Primary School, Gopalankadai",
          "Govt. Primary School, Govindasalai",
          "Govt. Primary School, Indira Nagar",
          "Govt. Primary School, Kamaraj Nagar",
          "Govt. Primary School, Kundupalayam",
          "Govt. Primary School, Meenatchipet",
          "Govt. Primary School, Mettupalayam",
          "Govt. Primary School, Moolakulam",
          "Govt. Primary School, Murungapakkam",
          "Govt. Primary School, Muthupillaipalayam",
          "Govt. Primary School, Odiyampet",
          "Govt. Primary School, Raja Nagar",
          "Govt. Primary School, Sanarapet",
          "Govt. Primary School, Sinnakarayamputhur",
          "Govt. Primary School, Sithangudi",
          "Govt. Primary School, Sulthanpet",
          "Govt. Primary School, Thattanchavady",
          "Govt. Primary School, Vennila Nagar",
          "Indira Gandhi Govt. High School, Katterikuppam",
          "JIPMER (Ã Â®Å“Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â¯Â)",
          "Kadaperikuppam Lake (Ã Â®â€¢Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ )Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kanagan Lake (Ã Â®â€¢Ã Â®Â©Ã Â®â€¢Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Karasur Lake (Ã Â®â€¢Ã Â®Â°Ã Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Karayambuthur Chinna Eri (Ã Â®â€¢Ã Â®Â°Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Karayambuthur Lake (Ã Â®â€¢Ã Â®Â°Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Katterikuppam Lake (Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Keezh Parikalpattu Lake (Ã Â®â€¢Ã Â¯â‚¬Ã Â®Â´Ã Â¯ÂÃ Â®ÂªÃ Â®Â°Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kirumambakkam Lake (Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kodathur Lake (Ã Â®â€¢Ã Â¯Å Ã Â®Å¸Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kodathur Lake (Ã Â®â€¢Ã Â¯Å Ã Â®Å¸Ã Â®Â¾Ã Â®Â¤Ã cultivationÃ¢â‚¬šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Korkadu Lake (Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kovaipudhur (Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â¯Ë†Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Krishnapuram Lake (Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â·Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â",
          "Krishnapuram Lake (Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â·Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ ",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â°Ã ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´",
          "Madagadipet Lake (Ã Â®Â®Ã Â®Â¤Ã Â®â€¢Ã Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Manapet Lake (Ã Â®Â®Ã Â®Â£Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mannadipet Lake (Ã Â®Â®Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ ÂÂ®Â®Ã Â¯Â Ã Â®Â Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mannadipet Lake (Ã Â®Â®Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mel Sathamangalam Lake (Ã Â®Â®Ã Â¯â€¡Ã Â®Â²Ã Â¯Â Ã Â®Å¡Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "MTVS Govt. High School, Reddiarpalayam",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mullodai lagoon (Ã Â®Â®Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯â€¹Ã Â®Å¸Ã Â¯Ë† Ã Â®â€¢Ã Â®Â´Ã Â®Â¿Ã Â®Â®Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Nallathur Lake (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)Â®Â¿)",
          "Nallavadu Beach (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®ÂµÃ Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â®Å¸Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â¯Ë†)",
          "Nallavadu-- Uppanaar Bridge (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®ÂµÃ Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®â€°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â®Â¾Ã Â®Â±Ã Â¯ÂÃ Â®Â±Ã Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â®Â®Ã Â¯Â)",
          "Nallur Lake (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Nathamedu Lake (Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Nettapakkam Lake (Ã Â®Â¨Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "OM SAKTHI KOVIL TEMPLE",
          "Our Lady of Lourdes Boys Govt. Aided School, Villianur",
          "Oussudu Lake Birds Sanctuary--PY side (Ã Â®Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â®Â±Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â®Â³Ã Â¯Â Ã Â®Å¡Ã Â®Â°Ã Â®Â£Ã Â®Â¾Ã Â®Â²Ã Â®Â¯Ã Â®Â®Ã Â¯Â--Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿)",
          "Ozhandhai Lake (Ã Â®â€°Ã Â®Â´Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Panaiyadikuppam Lake (Ã Â®ÂªÃ Â®Â©Ã Â¯Ë†Ã Â®Â¯Ã Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Pandachozhanallur Lake (Ã Â®ÂªÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Å¡Ã Â¯â€¹Ã Â®Â´Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Pavithram Tank Ã Â®ÂªÃ Â®ÂµÃ Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Periyakuttai (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Perunthalaivar Kamaraj Krishi Vigyan Kendra(PKKVK)-Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â²Ã Â¯Ë†Ã Â®ÂµÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â®Â¾Ã Â®Å“Ã Â®Â°Ã Â¯Â Ã Â®ÂµÃ Â¯â€¡Ã Â®Â³Ã Â®Â¾Ã Â®Â£Ã Â¯Â Ã Â®â€¦Ã Â®Â±Ã Â®Â¿Ã Â®ÂµÃ Â®Â¿Ã Â®Â¯Ã Â®Â²Ã Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "Phawngpui National Park Ã¢â‚¬â€ hike from Far Pak to Phawngpui peakÂÃ Â®Â°Ã Â®Â¿)",
          "Pondicherry Engineering College",
          "Pondicherry Harbour and Surrounding",
          "Pondicherry University",
          "Poolampatti Ã Â®ÂªÃ Â¯â€šÃ Â®Â²Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Poolavari Tank Ã Â®ÂªÃ Â¯â€šÃ Â®Â²Ã Â®Â¾Ã Â®ÂµÃ Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Puducherry Entrance- JIPMER",
          "Puducherry--Old Port Pier (Ã Â®ÂªÃ Â®Â´Ã Â¯Ë†Ã Â®Â¯ Ã Â®Â¤Ã Â¯ÂÃ Â®Â±Ã Â¯Ë†Ã Â®Â®Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Pullalur (Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â)Â®Â¿)",
          "Puraakulam, Katterikuppam",
          "Rajiv Gandhi Institute of Veterinary Education and Research (Restricted Access)",
          "Rock Beach--Gandhi Statue",
          "Rock Beach- Puducherry",
          "Savarayalu Nayagar GGPS, Puducherry",
          "Sedarapet Lake (Ã Â®Å¡Ã Â¯â€¡Ã Â®Â¤Ã Â®Â°Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Silver Jublee birding hotspot",
          "Sindhuvalli, Nanjanagudu (Ã Â²Â¸Ã Â²Â¿Ã Â²â€šÃ Â²Â§Ã Â³ÂÃ Â²ÂµÃ Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿)",
          "Sittanur Area Ã Â®Å¡Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿ Â®Â°Ã Â®Â¿)",
          "Sivaranthagam Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®ÂµÃ Â®Â°Ã Â®Â¾Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Sorapet Lake (Ã Â®Å¡Ã Â¯â€¹Ã Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Suthukeni Lake (Ã Â®Å¡Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¡Ã Â®Â£Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Suthukeny Otteri (Ã Â®Å¡Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¡Ã Â®Â£Ã Â®Â¿ Ã Â®â€œÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿)",
          "Thalavaipatty Lake (Mettupalayam Tank) Ã Â®Â¤Ã Â®Â³Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®ÂªÃ Â®",
          "Thanthai Periyar Govt. Girls H.S.S, Manavely",
          "Thengaithittu",
          "Thengaithittu--Estuary (Ã Â®Â¤Ã Â¯â€¡Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â®Â´Ã Â®Â¿Ã Â®Â®Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Thenkumarai Tank Ã Â®Â¤Ã Â¯â€ Ã Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "The Study LÃ¢â‚¬â„¢ÃƒÂ©cole Internationale",
          "Thillaiyadi Valliammai Govt. School (TVGHS), Kathirkamam",
          "Thirubhuvanai Lake (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thirukkannur Periya Eri (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thirukkanur Lake (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thiruvandarkoil Lake (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thondamanatham Lake (Ã Â®Â¤Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â®Â¾Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thuthipet Lake (Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "T.N. Palayam Lake (Ã Â®Â¤Ã Â®Â¿Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Tungabhadra Reservoir Ã Â²Â¤Ã Â³ÂÃ Â²â€šÃ Â²â€”Ã Â²Â­Ã Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²Â¾Ã Â²Â¶Ã Â²Â¯",
          "Uruvaiyar Bridge- Gingee River",
          "Utchimedu Lake (Ã Â®â€°Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vadhanur Lake (Ã Â®ÂµÃ Â®Â¾Ã Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vambupet Lake (Ã Â®ÂµÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Veerampattinam (Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â©Ã Â®Â®Ã Â¯Â)",
          "Veerampattinam Harbour",
          "Veerampattinam Pier",
          "Velrampet Lake (Ã Â®ÂµÃ Â¯â€¡Ã Â®Â²Ã Â¯ÂÃ Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Veppampoondi Tank Ã Â®ÂµÃ Â¯â€¡Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Villianur Railway Station (Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®Â¯Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Â°Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â)",
          "VVRGTHSS, Lawspet",
          "Yercaud Lake, Salem Ã Â®ÂÃ Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ ",
        ],
      },
      {
        district: "Yanam",
        localities: [
          "Govt. Primary School, Abishekapakkam",
          "Neelapalli Area",
          "Neelapalli--Island Opp Church",
        ],
      },
    ],
  },
  {
    state: "Punjab",
    districts: [
      {
        district: "Amritsar",
        localities: [
          "Amritsar Cantonment",
          "Company Gardens",
          "Dhanoakalan, Pul Kajri",
          "Golden Temple",
          "Guru Nanak Dev University Campus- Amritsar",
          "Kakkar",
          "Near Kohali on Amritsar-Chogawan Road",
          "Wagah Gate & Border Stadium India",
        ],
      },
      { district: "Barnala", localities: [] },
      {
        district: "Bathinda",
        localities: ["Dera Bhiana Sahib", "Nehian Wala"],
      },
      {
        district: "Faridkot",
        localities: ["bir sikhanwala", "Veere Wala Khurd"],
      },
      {
        district: "Fatehgarh Sahib",
        localities: [
          "BaÃ‰â€“ali Maiki",
          "BhagÃ‰Â½aÃ‰Â³a",
          "Biromajri",
          "Chandumajra Village Pond",
          "Cholti Kheri",
          "Sanghol Buddhist stupa ruins",
          "Sirhind Outskirts--Punjab",
        ],
      },
      {
        district: "Fazilka",
        localities: ["Arniwala", "Kundal Wetland--Punjab", "Village Odian"],
      },
      {
        district: "Ferozepur",
        localities: [
          "Churia--Harike",
          "Dineke- Harike Barrage",
          "Harike Bird Sanctuary",
          "Harike Bird Sanctuary--Kot Qaim Khan",
          "Harike Bird Sanctuary--Makhu Canal",
          "Harike Bird Sanctuary--Maujgarh",
          "hussainiwala",
          "Mamnewala",
          "Maujgarh near Harike Barrage",
          "Near Kot Qaim Khan on Left Bank of Sutlej River",
        ],
      },
      {
        district: "Gurdaspur",
        localities: [
          "BEAS RIVER CONSERVATION RESERVE (SHRIHARGOBINDPUR) GURDASPUR",
          "Gurudaspur--General Area",
          "Jagatpur Kalan--Punjab",
          "Keshopur",
          "Keshopur Chhamb Community Reserve",
          "KESHOPUR CHHAMB COMMUNITY RESERVE (GURDASPUR)",
          "KESHOPUR CHHAMB COMMUNITY RESERVE-MAGARMOODIAN WETLAND",
          "Keshopur wetlands Gurdaspur",
          "NAUSHEHRA MAJJA SINGH (BATALA)",
          "SHALLA PATTAN WETLAND (KESHPUR CHHAMB COMMUNITY RESERVE) GURDASP",
        ],
      },
      {
        district: "Hoshiarpur",
        localities: [
          "Beas river Hargobindpur",
          "Damsal Dam-- Punjab",
          "Dasuya--Punjab",
          "Jaijon- Hoshiarpur",
          "Jajjo",
          "Talwara",
        ],
      },
      {
        district: "Jalandhar",
        localities: [
          "Bhagwanpur--Punjab",
          "Dr B R Ambedkar National Institute of Technology",
          "Jalandhar",
          "Ludhiana Metropolitan Area",
          "Phillaur Divisional Forest",
          "Satluj River- Punjab",
          "Urban Estate Area Jalandhar Punjab",
          "Vasant Avenue, Jalandhar",
        ],
      },
      {
        district: "Kapurthala",
        localities: {
          localities: [
            "Chitti Bein - Near ChiheÃ‰Â½u Bridge",
            "Hari ke Pattan-Kapurthala",
            "Jalandhar Metropolitan Area",
            "Jhal Thikriwala- Punjab",
            "Kanjli Wetland",
            "Khara--Harike",
            "Mand Kirian--Punjab",
            "Near LPU Phagwara",
          ],
        },
      },
      {
        district: "Ludhiana",
        localities: [
          "Gobindgarh--Ludhiana",
          "Guru Nanak Dev Engineering College(GNE), Ludhiana",
          "Khaira Bet",
          "Ludhiana satluj Bed",
          "Mattewara Forest",
          "Nanak Nagar, Karabara Basti",
          "Neelon",
          "PAU Ludhiana - Floriculture farm",
          "Punjab Agricultural University",
        ],
      },
      { district: "Mansa", localities: [] },
      { district: "Moga", localities: [] },
      {
        district: "Pathankot",
        localities: ["KATHLAUR KUSHLIAN WILDLIFE SANCTUARY (PATHANKOT)"],
      },
      {
        district: "Patiala",
        localities: [
          "Banur-Patiala",
          "Baradari Garden",
          "Beerh Bhadson Wildlife Sanctuary",
          "Environment Park - Civil Lines",
          "Jangipur",
          "Kishangarh  birding hotspot",
          "Punjabi University, Patiala",
          "Rajpura--Patiala",
          "Thapar Polytechnic College",
        ],
      },
      {
        district: "Rupnagar",
        localities: [
          "Anandpur Vidi, near Jasdan (OBI)",
          "Bardar-- Punjab",
          "Bari Mandauli",
          "Ghanauli",
          "Gurudwara Baba Deep Singh Ji Saheed",
          "Haripur",
          "Jhajjar Bachauli wildlife",
          "Ropar-Indus Valley Civilization",
          "Ropar (OBI)",
          "Rupnagar",
          "Village Bani,  Anandpur sahib Punjab",
          "Village Sandhuan",
        ],
      },
      {
        district: "Sahibzada Ajit Singh Nagar",
        localities: [
          "Abhipur Pond",
          "Aerocity to Banur-Landran Road",
          "Attawa Choe Sector 62",
          "Bari--Punjab",
          "Birding in Jawaharpur-Ambala Punjab",
          "Bir Pir Machhalia--Punjab",
          "Chandigarh Airport",
          "Chappar Chiri",
          "Chattbir Zoo",
          "Chhatt Beerh",
          "Chhoti Bari Parachh--Punjab",
          "City Park Sector 68",
          "Daffarpur-- Punjab",
          "Dharak Khurd",
          "DLF Mullanpur",
          "Ghaggar Barrage",
          "Gudana",
          "Gurudwara Sachkhnad Dwar (Jhanda Sahib)",
          "IISER Basketball Court--Mohali (Restricted access)",
          "IISER Mohali",
          "Indian Institute of Science Education and Research (IISER) Mohali -- AB1",
          "Indian Institute of Science Education and Research (IISER) Mohali--Playground",
          "Jawaharpur,Derabassi,near Chandigarh",
          "Jawaharpur--Punjab",
          "Jayanti Devi Dam",
          "JLPL Industrial Area, Manauli",
          "Karoran",
          "Makandpur--Punjab",
          "Mirzapur Check Dam",
          "Mote Majra",
          "Mote Majra Lake",
          "MoteMajra Lake",
          "Mullanpur Garibdass",
          "Mullanpur - Pond near Omaxe",
          "National Institute of Pharmaceutical Education and Research",
          "Near Lakhnaur Sector 90 Mohali",
          "Near Village Dulwan",
          "Near Village Kurrdi",
          "Palheri Pond",
          "Parchh Dam",
          "Peer Mucha Wala Forest",
          "Perch Check Dam",
          "Raipur--Punjab",
          "Sahibzada Ajit Singh Nagar--Punjab",
          "Sahibzada Singh Nagar",
          "Samgauli-- Punjab",
          "Sector 64, Phase 10",
          "Silvi Park, Sector 64",
          "Siswan",
          "Siswan Dam",
          "Siswan Lake",
          "Siswan-Old Siswan road",
          "Siswan-Siswan forest",
          "Sohali--Punjab",
          "Tangori",
          "Togan",
        ],
      },
      { district: "Sangrur", localities: [] },
      { district: "Shaheed Bhagat Singh Nagar", localities: [] },
      { district: "Sri Muktsar Sahib", localities: [] },
      {
        district: "Tarn Taran",
        localities: [
          "Abhipur Pond",
          "Aerocity to Banur-Landran Road",
          "Attawa Choe Sector 62",
          "Bari--Punjab",
          "Birding in Jawaharpur-Ambala Punjab",
          "Bir Pir Machhalia--Punjab",
          "Chandigarh Airport",
          "Chappar Chiri",
          "Chattbir Zoo",
          "Chhatt Beerh",
          "Chhoti Bari Parachh--Punjab",
          "City Park Sector 68",
          "Daffarpur-- Punjab",
          "Dharak Khurd",
          "DLF Mullanpur",
          "Ghaggar Barrage",
          "Gudana",
          "Gurudwara Sachkhnad Dwar (Jhanda Sahib)",
          "IISER Basketball Court--Mohali (Restricted access)",
          "IISER Mohali",
          "Indian Institute of Science Education and Research (IISER) Mohali -- AB1",
          "Indian Institute of Science Education and Research (IISER) Mohali--Playground",
          "Jawaharpur,Derabassi,near Chandigarh",
          "Jawaharpur--Punjab",
          "Jayanti Devi Dam",
          "JLPL Industrial Area, Manauli",
          "Karoran",
          "Makandpur--Punjab",
          "Mirzapur Check Dam",
          "Mote Majra",
          "Mote Majra Lake",
          "MoteMajra Lake",
          "Mullanpur Garibdass",
          "Mullanpur - Pond near Omaxe",
          "National Institute of Pharmaceutical Education and Research",
          "Near Lakhnaur Sector 90 Mohali",
          "Near Village Dulwan",
          "Near Village Kurrdi",
          "Palheri Pond",
          "Parchh Dam",
          "Peer Mucha Wala Forest",
          "Perch Check Dam",
          "Raipur--Punjab",
          "Sahibzada Ajit Singh Nagar--Punjab",
          "Sahibzada Singh Nagar",
          "Samgauli-- Punjab",
          "Sector 64, Phase 10",
          "Silvi Park, Sector 64",
          "Siswan",
          "Siswan Dam",
          "Siswan Lake",
          "Siswan-Old Siswan road",
          "Siswan-Siswan forest",
          "Sohali--Punjab",
          "Tangori",
          "Togan",
        ],
      },
    ],
  },
  {
    state: "Rajasthan",
    districts: [
      {
        district: "Pali",
        localities: [
          "Bera",
          "Bera Safari Lodge",
          "Chhatra Sagar, Nimaj",
          "Desuri Lake",
          "Ghanerao and surrounds - Kumbalgarh",
          "Jawai Bandh/ Dam",
          "Jawai Dam",
          "Jawai--General",
          "Kumbhalgarh NP",
          "Megh Malhar Midway",
          "Mihirgarh Fort",
          "Phulad Dam",
          "Rajpura Dam",
          "Ranakpur",
          "Ranakpur Jain Temple",
          "Sardarsamand",
          "Sardar Samand Lake",
          "Sena, Pali, Rajasthan",
          "Shivika Lake Resort",
          "Sujan Jawai Camp",
        ],
      },
      { district: "Jalore", localities: ["Jagnath WLS (Dhawla Village)"] },
      { district: "Banswara", localities: ["Mahi River"] },
      {
        district: "Udaipur",
        localities: [
          "Badi Talab",
          "Bala Vera - Kumbalgarh",
          "Balicha, Udaipur (OBI)",
          "Bharmela Pond Menar",
          "Bhatewar (OBI)",
          "Bujh Ka Naka",
          "Chirwa village pond",
          "City Palace, Udaipur",
          "Czar Palace Resort",
          "Dilip Niwas Homestay",
          "Fateh Sagar Lake",
          "Garbage dump near IIM Udaipur",
          "Govardhan Sagar Lake",
          "Gulab Bagh and Zoo",
          "Himmato Ki Bhagal",
          "Hotel Rajmahal Bhindar",
          "Jaisamand Island Resort",
          "Jhameshwar Mahadev",
          "Jogi Talaab",
          "Jorji ka khera",
          "Karni Mata Temple walking path, Udaipur",
          "Kurra Bokhara",
          "Menar Lake",
          "Mewar Biodiversity Park",
          "Mohanlal Sukhadia University Campus, Udaipur",
          "Nela Pond",
          "Pahada lake",
          "Pichola Lake, Udaipur",
          "Prakriti Saadhna Kendra, Vidya Bhawan",
          "Purohit Ki Talab",
          "Rundera (OBI)",
          "Sahelion Ki Bari",
          "Sajjangarh Wildlife Sanctuary",
          "Salumbar Lake",
          "Shilpgram",
          "Sukhadia Science College, Zoology Department",
          "Swaroop Sagar",
          "Udaipur--Jungle Safari Park",
          "Udaipur, Rajasthan (OBI)",
          "Udai Sagar Lake",
          "Vallabh Nagar and surrounding area",
          "Vallabhnagar Dam",
        ],
      },
      {
        district: "Rajsamand",
        localities: [
          "Club Mahindra Kumbhalgarh",
          "Club Mahindra--Kumbhalgarh",
          "Eklingji lake",
          "Eklingji temple lake",
          "Kumbalgarh Fort",
          "Kumbalghad Forest",
          "Rajsamand Lake",
          "swadadi B",
        ],
      },
      {
        district: "Dholpur",
        localities: [
          "Dholpur-Bund Baretha Road",
          "Dholpur--Nibhi",
          "Dholpur, Rajasthan (OBI)",
          "Dholpur - Western scrub",
          "National Chambal Sanctuary--Dhaulpur (RJ side)",
          "Ramsagar Wildlife Sanctuary",
          "Talab Shahi",
          "Van Vihar Hunting Lodge",
        ],
      },
      {
        district: "Bhilwara",
        localities: [
          "Badi Ka Baadiya, Karera-Bhim Rd, Rajasthan",
          "Chavandia Pond",
          "Dhandolai",
          "Gurlan Pond",
          "Guwardi Dam",
          "Jaleshwar Pond",
          "Ladpura",
          "Sareri Dam, Bhilwara",
          "Shahpura",
          "Shahpura Bagh Hotel",
          "Smriti Van Garden",
          "Umaid Sagar (OBI)",
          "Ummed Sagar",
        ],
      },
      {
        district: "Sirohi",
        localities: [
          "Aarna Campsite",
          "Anil Mathur's garden, Mount Abu (OBI)",
          "Azim Premji School (Restricted)",
          "Bailey's Nature Trail",
          "Goumukh--Mt.Abu",
          "Guru Shikar Temple",
          "Javai--Mt. Abu",
          "Mount Abu",
          "Mount Abu--Sunset Point",
          "Mt.Abu--Guruskikhar",
          "Mt. Abu Wildlife Sanctuary",
          "Nakki Lake",
          "Opposite Safari Cottage, Achalgadh",
          "Oriya Road",
          "Oriya Village",
          "Oriya Village Peace Park",
          "Platform Overbridge, Abu Road, Rajasthan, IN (24.482, 72.785)",
          "Raniya Khera",
          "St.Mary High School Road",
          "Swami Vivekanand Institute",
          "Trevor's Tank",
        ],
      },
      {
        district: "Baran",
        localities: [
          "Shahbad, Baran (OBI)",
          "Sorsan Grassland",
          "Sorsan--Niyana Wetland",
          "Wetland near Ramgarh Village, Baran (OBI)",
        ],
      },
      {
        district: "Bundi",
        localities: [
          "Bardha Dam",
          "Devpura Bundi",
          "Guda Dam",
          "Jet Sagar",
          "near Chambal River, Kota (OBI)",
          "Ramgarh Vishdhari Wildlife Sanctuary",
          "Ramnagar Wetland",
          "Rampuriya",
          "Ram Sagar Jheel",
          "Taragarh Fort",
          "wetland near Ramnagar Village (OBI)",
        ],
      },
      {
        district: "Jaisalmer",
        localities: [
          "Akal Wood Fossil Park",
          "Amar Sagar",
          "Asoo Ka Tala",
          "Bada Bagh",
          "Bandah (OBI)",
          "Bhadasar (OBI)",
          "Bhuwana",
          "Chandan",
          "Chhor Talab, Devikot",
          "Damodara Gaushala",
          "Damodara (OBI)",
          "Dandewala (OBI)",
          "Dandewala, Thar Desert (OBI)",
          "Deghrai Mata Temple",
          "Degray Mata Mandir",
          "Desert National Park",
          "Desert NP (DNP) & Surroundings--General Area",
          "Desert NP (DNP) & Surroundings--Khuri",
          "Desert NP (DNP) & Surroundings--Sam To Khuri Road",
          "Desert NP (DNP) & Surroundings--Sudasari",
          "Desert NP--Giant Moringa Dune",
          "Desert NP--Sam Sand Dunes",
          "Desert NP--Sam to Sudasari Road",
          "Dhanana (OBI)",
          "DNP, Sudasari, Rajasthan, IN (26.819, 70.518)",
          "Gadisar Lake",
          "Gajroop Sagar",
          "Gamnewala (OBI)",
          "Ghotaru (OBI)",
          "International Border, Dandewala (OBI)",
          "Jaisalmer Airport",
          "Jaisalmer Fort",
          "Jaisalmer, Rajasthan (OBI)",
          "Jiya Desar Rai Temple",
          "Kale Dungar, Jaisalmer (OBI)",
          "Khabha Fort",
          "Khetolai",
          "Khetolai Lake",
          "Khetolai (OBI)",
          "Khuri (OBI)",
          "Khyala Mutt Lake, Myajlar",
          "Kuldhara",
          "Mohangarh (OBI)",
          "Nabhdungar",
          "near Ramgarh, Jaisalmer (OBI)",
          "Netsi Lake",
          "Netsi Village",
          "Pokaran, Jaisalmer (OBI)",
          "Pokaran Salt Lake",
          "Ramgarh--General Area (OBI)",
          "Sagron Ki Basti",
          "Sam Road, Jaisalmer (OBI)",
          "Seuwa",
          "Sunset Point and Vyas Chhatari, Jaisalmer",
          "Thar Desert (OBI)",
        ],
      },
      {
        district: "Dungarpur",
        localities: [
          "Dungarpur Bird Sanctuary and Flower Garden",
          "Khera Kachawasa",
        ],
      },
      {
        district: "Hanumangarh",
        localities: ["Badopal", "Dabli Khurd", "Manaktheri waterbody"],
      },
      {
        district: "Sikar",
        localities: [
          "Basri Kalan",
          "Chitarwal Balaji Mandir",
          "Chopta (Sikar)",
          "Jaipur to Gurgaon Highway (OBI)",
          "Lalolav Balaji",
          "Mount Harsh & Surroundings",
          "National Highway 52, near Sikar (OBI)",
          "Nechwa, Sikar Road, Rajasthan",
          "Raiwasa Lake & surroundings",
          "Shakambhari (Sakrai)",
          "Sikar--Smriti Van",
        ],
      },
      {
        district: "Tonk",
        localities: [
          "Bisalpur Dam Water Catchment Area",
          "Mundiya Village, Tonk (OBI)",
          "Ranathambore Zone 9",
          "Sawai Madhopur (OBI)",
          "The Lotus Pond/ Mundiya Pond",
        ],
      },
      {
        district: "Bikaner",
        localities: [
          "Bikaner (OBI)",
          "Bikaner Sewage Treatment Plant",
          "City Drainage & Sewerage Water, Bikaner, Rajasthan",
          "College of Veterinary and Animal Science Bikaner",
          "Darbari Pond",
          "Gajner",
          "Gajner Bird Sanctuary",
          "Guda",
          "IGNP Escape way - RD 750",
          "Jodbeed Colony",
          "Jodbeer",
          "Jorbeer Vulture Conservation Centre",
          "Lalgarh Palace Garden",
          "Lunkaransar",
          "Lunkaransar Salt Lake",
          "Maharaja Gangasingh University, Bikaner",
          "Raneri Talaab (km 73 from Bikaner on NH15)",
          "RD507 (Wetlands)",
          "RD507 (Woodlands)",
          "Sacha Soudha Plantation Km81",
          "Seruna, Rajasthan",
          "Shiv Gorakh Mandir, Goyalri",
          "Vallabh Gardens, Bikaner",
          "Vesta Bikaner Palace",
        ],
      },
      {
        district: "Bharatpur",
        localities: [
          "Bagh Hotel",
          "Bayana",
          "Bayana Cliffs",
          "Bayana Tank",
          "Bharatpur",
          "Bharatpur--Keoladeo Ghana NP",
          "Bharatpur--Keoladeo Ghana NP--Sapan Mori",
          "Bharatpur--Malah Road Marsh",
          "Bharatpur--Pride Kadamb Kunj",
          "Bharatpur--Saras Hotel",
          "Bund Baretha Wildlife Sanctuary",
          "Dar Barahna",
          "Jatoli road",
          "Keoladeo National Park",
          "Kot Bayana",
          "Kumher area",
          "Malaha Water plants (Keoladeo)",
          "outskirts of Bharatpur (OBI)",
          "Rarah",
        ],
      },
      {
        district: "Barmer",
        localities: [
          "Badmer (Barmer) (OBI)",
          "Gangawas Pond, near Korna",
          "Korna",
          "Korna Village (OBI)",
          "Mala Ji Jaal and Malde Temple",
          "Mekam singh ji Pond",
          "Pachpadra Village (OBI)",
          "Rann of Redana",
          "Siana Lodges and Camps",
        ],
      },
      {
        district: "Churu",
        localities: [
          "Chappar Village (OBI)",
          "Charwas",
          "Charwas Village Pond",
          "Chhapar Village Chowk and Pond",
          "Leelki Beed",
          "Naatho Talaab",
          "Padihara Village, Rajasthan",
          "Ratangarh, en route to Tal Chappar (OBI)",
          "Tal Chhapar Bypass Road",
          "Tal Chhapar Sanctuary",
          "Tal Chhapar WLS--Gaushala Area",
          "Tal Chhapar WLS--Salt Pans",
        ],
      },
      {
        district: "Alwar",
        localities: [
          "Algual road - Sariska Raj.",
          "Algual- Sariska Raj.",
          "Bala Quila, Alwar RJ/IN",
          "Bandipul forest- Sariska Raj.",
          "Bandipul nallah- Sariska Raj.",
          "Behlor (OBI)",
          "Bhaironghati hill- Sariska Raj.",
          "Bhaironghati- Sariska Raj.",
          "Biodiversity Park (Alwar) Rj/IN",
          "Brahmnath - Sariska Raj.",
          "Budha Hanuman - Sariska Raj.",
          "Chamari-ka-beda- Sariska Raj.",
          "Ghandka road - Sariska Raj.",
          "Haripura hill Sariska",
          "Haripura temple - Sariska Raj.",
          "Kabri gate- Sariska Raj.",
          "Kalighati - Sariska Raj",
          "Kali Radi- Sariska Raj.",
          "Kankwari- Sariska Raj.",
          "Kankwari valley- Sariska Raj.",
          "Karnakawas hill Sariska",
          "Kundali- Sariska Raj.",
          "Makar-da-pulia - Sariska Raj",
          "Mangalsar Dam, Alwar RJ/IN",
          "Nayapani- Sariska Raj.",
          "Neemrana Fort Palace Area haryana",
          "Pheta-ki-pal- Sariska Raj.",
          "Sariska",
          "Sariska - Governor's Road",
          "Sariska NP--Mansarovar Lake (Mangalsar Dam)",
          "Sariska - Shankar's exclosure",
          "Sariska Sterling Resort (OBI)",
          "Sariska Tiger Reserve",
          "Sariska Tiger Reserve--Karna ka Vas",
          "Sili Sedh, edge of Alwar (OBI)",
          "SiliSerh",
          "Tarunda - Sariska",
          "Tehla nallah- Sariska Raj.",
          "Tehla, Sariska HR/IN",
          "Tehla- Sariska Raj.",
          "Tijara Fort Palace",
          "Umri hill- Sariska Raj.",
          "Umri nallah- Sariska Raj.",
          "Umri - Sariska Raj",
        ],
      },
      {
        district: "Jhunjhunu",
        localities: [
          "Birla Yoga Kendra",
          "BITS Pilani",
          "Desert Resort, Mandawa",
          "Jherli Pilani",
          "Khetri",
          "Kot Baandh",
          "Pilani Helipad",
        ],
      },
      {
        district: "Jodhpur",
        localities: [
          "AFRI Campus, Jodhpur (OBI)",
          "Agolai Talab",
          "Ajit Bhawan Hotel",
          "Akreja Talab",
          "Arna Jharna, Moklavas",
          "Balsamand Lake Palace",
          "Barli Lake",
          "Bawarli, Bombor (OBI)",
          "Bio Waste And Tannery ",
          "Bishnoi Village Safari (Jodhpur)",
          "Chandelao, Jodhpur (OBI)",
          "Dechu",
          "Devi Kunj Guest House",
          "Guda Bishnoiyan, Rajasthan State Highway 68",
          "Guda Vishnoi Conservation Reserve",
          "Herbal Park/ Botanical Park (OBI)",
          "Indian Institute of Technology Jodhpur",
          "Jaswant Thada",
          "Jodhpur Carcass Dump",
          "Jodhpur Golf Course",
          "Jodhpur--Vivanta By Taj Hotel",
          "Kailana Lake",
          "Kanasar",
          "Kayalana Lake",
          "Keru Landfill",
          "Kheechan Village (OBI)",
          "Khichan",
          "Khichan--Demoiselle Crane Feeding Station",
          "Khichan--Vijaysagar Lake",
          "Khidrat to Kanji Ki Sird Grasslands",
          "Korna",
          "Lake near to Machiya Biological Park",
          "Luni (OBI)",
          "Machia Biological Park",
          "Mamaji Temple",
          "Mandore Garden",
          "Meghlasiya Talao",
          "Meglasiya (OBI)",
          "Mehrangarh Fort, Jodhpur",
          "Olvi (OBI)",
          "Osian (OBI)",
          "Ramasani (OBI)",
          "Rao Jodha Desert Rock Park, Jodhpur",
          "Sambodhi Lake",
          "Soorsagar Lake",
          "Umaid Bhawan Palace, Jodhpur",
        ],
      },
      {
        district: "Dausa",
        localities: [
          "Chand Baori Stepwell",
          "Ed Zed Balaji",
          "Gatolav Pond",
          "Kalakho Dam",
          "Madhogarh",
          "Morel Dam Road",
          "New Colony--Lalsot",
        ],
      },
      {
        district: "Jaipur",
        localities: [
          "Achrol Niwas (OBI)",
          "Amagarh Leopard Reserve",
          "Amber Fort, Jaipur",
          "Ambikeshwar Mahadev Temple",
          "Amer Fort",
          "Babaji Ki Paal--Kanota Dam",
          "Bairath (OBI)",
          "Barkhera",
          "Bhaskar Enclave 2",
          "Bhuteswar Nath Mahadev Seesyawas",
          "Borkheda, Jaipur (OBI)",
          "Chak Charanwas Village (OBI)",
          "Chaksu Lake",
          "Chandlai Lake",
          "Clark's Amer Hotel, Jaipur",
          "Dhantala Meena (OBI)",
          "Ganpati Residency",
          "Hotel Jai Palace",
          "Jaigarh Fort",
          "Jaipur--Amber Palace vicinity",
          "Jaipur--Central Park",
          "Jaipur--Floating Palace",
          "Jaipur Grass Nursery Farm",
          "Jaipur International Airport",
          "Jaipur (OBI)",
          "Jamwa Ramgarh WLS",
          "Jantar Mantar",
          "Jawahar Circle Garden",
          "Jawaharnagar Forest Nursery",
          "Jhalana Safari Park",
          "Kaladera, Jaipur (OBI)",
          "Kanota Dam",
          "Kishen Bagh",
          "Lake Man Sagar",
          "Manipal University, Jaipur",
          "Maotha Lake",
          "Monkey Temple",
          "Nahargarh Biological Park",
          "Nahargarh Biological & Zoological Park",
          "Nahargarh Forest",
          "Nahargarh Fort",
          "Nahargarh Fort, Jaipur",
          "Naliasar Lake",
          "Nasiya",
          "Nasiya (Manpur Doongri)",
          "Nature Farms (OBI)",
          "Nemi Nagar Extension (OBI)",
          "Neota Talav",
          "Outskirts of Jaipur (OBI)",
          "Papad Waley Hanuman ji",
          "Rajasthan University Campus",
          "Rambagh Palace, Jaipur",
          "Ramniwas Garden (OBI)",
          "Rose Garden, Jaipur",
          "Sagar Lake, Amber, Jaipur",
          "Sambhar Lake",
          "Sambhar Lake--South",
          "Sambhar Salt Lake",
          "Samode Palace",
          "Saptashakti Nature Park",
          "Shiksha Sankul",
          "Shivdaspura, Near Jaipur (OBI)",
          "Shyampura (OBI)",
          "Sitla Mata, near Chaksu (OBI)",
          "Smriti Van",
          "State Agricultural Research Institute Campus",
          "Sushant City 1",
          "Tal Katora Lake",
          "The Tree of Life Resort & Spa",
          "Todamina, near Jaipur (OBI)",
          "Vastu shree colony Mangyawas",
        ],
      },
      {
        district: "Karauli",
        localities: [
          "Banas River b/w Ranthambhor NP & Keladevi Sanctuary (OBI)",
          "River bed between Keladevi Sanctuary and Ranthambhore NP (OBI)",
        ],
      },
      {
        district: "Ajmer",
        localities: [
          "Anasagar Barahdari",
          "Anasagar Lake",
          "Birla Public School (Restricted Access)",
          "Central University Of Rajasthan",
          "Chat Village",
          "Gundolav Lake",
          "Keetap (General)",
          "Kishangarh Darbar",
          "Loharwara--Quartz and Mica Mines",
          "Maharishi Dayanand Saraswati (MDS) University",
          "Mehendi Khola Temple",
          "Nag Parbat",
          "Near Nasirabad (OBI)",
          "Pushkar Ghat",
          "Pushkar Heritage",
          "Pushkar Lake",
          "Pushkar Resorts",
          "Rajgarh",
          "Savitri Mata Temple",
          "Shokaliya",
          "Suspension Pond, Mundoti",
          "wetland near Sawar (OBI)",
        ],
      },
      {
        district: "Jhalawar",
        localities: [
          "Barbeli",
          "Khandiya Wetland (OBI)",
          "Terrace Garden, Gurha",
        ],
      },
      {
        district: "Kota",
        localities: [
          "Abheda Biological Park",
          "Abheda Mahal Talab",
          "Alania Dam",
          "Borabas",
          "Chambal Gorge, Kota",
          "Chambal River Gorge, near Garadia Mahadev Temple",
          "Chambal River, Kota (OBI)",
          "Dharampura Road, Kota",
          "Garadia Mahadev Temple",
          "Kota Cantonment",
          "Left Main Canal of Chambal River, Kota (OBI)",
          "Mandirgarh",
          "Mukundara Hills Tiger Reserve, Kota",
          "Nayapura",
          "Outskirts of Kota City (OBI)",
          "Pond at Morpa Village (OBI)",
          "Rajasthan Technical University",
          "Rajgarh Village Wetland (OBI)",
          "Ranpur Wetland, Kota (OBI)",
          "Rawatbhata to Kota (OBI)",
          "Right Main Canal of Chambal River, Kota (OBI)",
          "Sakatpura Pond",
          "Sawan Bhado Dam",
          "Seven Wonder Park, Kota",
          "Udpuria Bird Sanctuary",
          "Udpuriya Village, Kota (OBI)",
          "Udpuriya Wetland",
          "Umedganj Talab",
          "Ummedganj Wetland",
          "University of Kota",
        ],
      },
      {
        district: "Pratapgarh",
        localities: [
          "Pratapgarh, RJ (OBI)",
          "Seetamata Forest Reserve",
          "SItamata Wildlife Sanctuary",
        ],
      },
      {
        district: "Sawai Madhopur",
        localities: [
          "Banas River",
          "Bhomiya Ji Maharaj Temple",
          "Castle Jhumar Baori",
          "Gangapur (OBI)",
          "Jungle Retreat Resort",
          "Khem Villas Jungle Camp",
          "Khilchipur Village",
          "Lahpur--Ranthambhore National Park",
          "Lakarda, Ranthambore National Park",
          "Lake Mansarovar",
          "MAA Farm (OBI)",
          "National Chambal Sanctuary--Pali",
          "Pali Farm, Chambal",
          "Palighat, Chambal River (OBI)",
          "Pali Ghat (OBI)",
          "Ranthambhore Heritage Haveli hotel",
          "Ranthambhore NP--Zone 3",
          "Ranthambhore NP--Zone 5",
          "Ranthambore Kothi Lodge",
          "Ranthambore NP",
          "Ranthambore NP--entry road & fort",
          "Ranthambore NP--Ranthambore Fort",
          "Ranthambore NP--Zone 1",
          "Ranthambore NP--Zone 2",
          "Ranthambore NP--Zone 4",
          "Ranthambore NP--Zone 4--Kachida",
          "Ranthambore NP--Zone 4--Rajbagh",
          "Ranthambore NP--Zone 6",
          "Sawai Madhopur",
          "Sawai Madhopur Railway Station",
          "Sawai Mansingh Wildlife Sanctuary",
          "Soorwal Lake",
          "Sujan Sher Bagh",
          "The Oberoi Vanyavilas",
          "The Oberoi Vanyavilas (Restricted)",
          "The Pugmark Hotel",
          "The Ranthambore Bagh",
          "Tiger Den Resort",
          "Tiger Moon Hotel",
          "Tiger Moon Hotel-- Dry Hillock",
        ],
      },
      { district: "Sri Ganganagar", localities: [] },
      {
        district: "Nagaur",
        localities: [
          "Chinpolai Talab, Parbatsar",
          "Didwana Wetland",
          "Jaswantgarh Flats",
          "Khimsar (OBI)",
          "Sambhar Lake--Nava Zone",
        ],
      },
      {
        district: "Chittorgarh",
        localities: [
          "Amba pani",
          "Bassi Fort Palace",
          "Bassi Wildlife Sanctuary",
          "Bhainsrorgarh (OBI)",
          "Bhupal Sagar lake",
          "Chittorgarh Fort",
          "Dorai Dam upstream",
          "Mangalwad/ Mangalwar Lake",
          "Menal",
          "Menal Pond",
          "Nagawli Talab",
          "Pangarh Lake",
          "Rana Pratap Sagar Dam (OBI)",
          "Thukrai Talab",
        ],
      },
    ],
  },
  {
    state: "Sikkim",
    districts: [
      {
        district: "East Sikkim",
        localities: [
          "15th Mile",
          "Agomlok",
          "Aritar",
          "Aritar--British Graveyard (Cultivation Field)",
          "Assam Lingzey Forest",
          "Baba Harhajan Singh Temple (OBI)",
          "Bedang Tso (Elephant Lake)",
          "Bedang Tso (Elephant Lake) View Point",
          "Budang Gadi Forest",
          "Changthang-Naitam-Pabyuik",
          "Dikling Pacheykhani Forest",
          "Dzuluk (Zuluk)",
          "East Pendam (Karmithang)",
          "East Sikkim, India (OBI)",
          "Fambong Lho Wildlife Sanctuary",
          "Ganek View Point",
          "Gangtok--Bakthang Waterfall",
          "Gangtok--Banjhakri Waterfalls",
          "Gangtok--Chongay-Tathangchen",
          "Gangtok--Deorali",
          "Gangtok--General Area",
          "Gangtok--Hanuman Tok",
          "Gangtok--Jawaharlal Nehru Botanical Garden",
          "Gangtok--Plant Conservatory",
          "Gangtok--Ranka-Parbing",
          "Gangtok--Ridge Park (Chogyal Memorial Park)",
          "Gangtok--Sikkim Himalayan Zoological Park",
          "Gangtok--Sikkim University Guest House",
          "Gangtok--Tashi View Point (Bojoghari)",
          "Gnathang Valley",
          "Hangu Lake (Tanyek Tso)--Sherathang",
          "Kashyone Khasmahal",
          "Kue Khola Falls",
          "Kumrek--4 Mile",
          "Kupup, East Sikkim  (OBI)",
          "Kyongnosla Alpine Sanctuary",
          "Lakshman Chowk",
          "Lampokhari Lake",
          "Lingtam",
          "Lungthung (Longthu)",
          "Mulukey",
          "Nanthang Valley View Point",
          "Nathu La (Pass)",
          "Nimachen",
          "Old Baba Mandir (Harbhajan Baba Mandir)",
          "Pabyuik Monastery",
          "Pachak Gompa",
          "Padamchen (OBI)",
          "Pakyong--Dugalakha",
          "Pakyong--General Area",
          "Pakyong--Namchey Bung",
          "Pangolakha WLS--General Area",
          "Phadamchen",
          "Phusray Lake (Mulkharka)",
          "Rache La (Rachela Pass)",
          "Rangpo Mining Forest",
          "Rangpo Town",
          "Ranipool--Saramsa",
          "Reshi Khola",
          "Rhenak",
          "Rhenock (OBI)",
          "Rhenock--Saoreni Gaon",
          "Rishi (Bengal-Sikkim border post) KP",
          "Rolep, East Sikkim (OBI)",
          "Rongli",
          "Rongli--Kalikhola",
          "Rumtek Monastery",
          "Sherathang--Manju Lake",
          "Singtam",
          "Sudunglakha",
          "Tadong--General Area",
          "Tadong--Sikkim Government College",
          "Tadong--Sikkim University Current Campus",
          "Talkharka",
          "Thambi View Point",
          "Tsomgo (Changu Lake)",
          "West Pendam (Sisney Sautar)",
          "Youlas View Point",
        ],
      },
      {
        district: "North Sikkim",
        localities: [
          "Bakcha Bridge",
          "Bansoi Bridge",
          "Beechu Khedum Forest",
          "Chungthang",
          "Dzongu--Upper (Lingthem)",
          "Dzongu--Upper (Tingbong)",
          "Gurudongmar Army Checkpost",
          "Gurudongmar Lake",
          "Gurudongmar Plateau",
          "Gurudongmar Plateau--Last Point (Restricted Access)",
          "Kabi",
          "Lachen",
          "Lachen Choling Monastery",
          "Lachen--Lower (Latong)",
          "Lachen--Zemu Chu Bridge",
          "Lachung",
          "Lachung--Upper (Katao)",
          'Lashar Valley "Restricted Access"',
          "Lashar Valley Restricted Access",
          "Lhonak Valley--Muguthang",
          "Mangan",
          "Mangan--Helipad",
          "Meyong",
          "North Sikkim plateau (OBI)",
          "Shingba Rhododendron Sanctuary",
          "Sikkim, India (OBI)",
          "Singhik Reserve Forest",
          "Thangu--Lower (13,000 ft)",
          "Thangu--Upper (15,500 ft)",
          "Thangu Valley (OBI)",
          "Thangu Valley--Talam Samdong",
          "Thangu--Yongdi",
          "Tso Lhamo (Restricted Access)",
          "Tumlong--Phodong",
          "Tung--Naga Falls",
          "Yumesamdong",
          "Yume Samdong (OBI)",
          "Yumthang Valley",
        ],
      },
      {
        district: "South Sikkim",
        localities: [
          "Barfung--Doling Monastery",
          "Borong",
          "Jorethang",
          "Kewzing",
          "Kewzing--Bon Monastery",
          "Kewzing--Lower (Samsing-Dalep)",
          "Kitam Bird Sanctuary",
          "Kitam Village",
          "Maenam WLS",
          "Mt. Tendong",
          "Namchi--Char Dham Temple Complex",
          "Namchi (OBI)",
          "Namchi--Rock Garden",
          "Namchi--Samdruptse Monastery",
          "Rangit Nagar--General Area (OBI)",
          "Ravangla",
          "Ravangla--Barfung",
          "Satmile Village (OBI)",
          "Tashiding Lower (Legship)",
          "Temi--Biodiversity Park",
          "Temi--Damthang",
        ],
      },
      {
        district: "West Sikkim",
        localities: [
          "Barnyak Martam Forest",
          "Barsey Rhododendron Sanctuary",
          "Biksthang",
          "Biksthang (OBI)",
          "Club Mahindra, Baiguney",
          "Daramdin, Sikkim (OBI)",
          "Darap",
          "Dentam--Mangmung Khola",
          "Geyzing--Langang",
          "Geyzing, Sikkim (OBI)",
          "Hee Burmiok (Hee Patal)",
          "Hilley (OBI)",
          "Kaluk",
          "Khangchendzonga Falls",
          "Khangchendzonga NP--Bakhim Camp",
          "Khangchendzonga NP--Bikbari",
          "Khangchendzonga NP--Dzongri Camp",
          "Khangchendzonga NP--Dzongri La (Black Kabru Pass)",
          "Khangchendzonga NP--HMI Base Camp",
          "Khangchendzonga NP--Phedang Camp",
          "Khangchendzonga NP--Rathong Glacier Lake",
          "Khangchendzonga NP--Sachen Camp",
          "Khangchendzonga NP--Tsoka Camp",
          "Khecheopalri Lake",
          "Labdang Gangyep Forest",
          "Lamuney Camp (OBI)",
          "Okhrey",
          "Pelling City",
          "Pelling (OBI)",
          "Pelling--Pemayangtse Monastery",
          "Pelling--Rabdentse Bird Park",
          "Pelling--Sangachoeling Monastery",
          "Phamrong Falls",
          "Rangeet Dam/ Rangit Nagar Power Station (OBI)",
          "Rimbi Orange Garden",
          "Rinchenpong",
          "Soreng Forest Block",
          "Tashiding--Rangit Forest",
          "Uttarey",
          "Yuksam (OBI)",
          "Yuksom",
          "Yuksom--Dubdi Monastery",
          "Yuksom--Kathok Lake",
        ],
      },
    ],
  },
  {
    state: "Tamil Nadu",
    districts: [
      {
        district: "Theni",
        localities: [
          "Adukkam Road, at foothills",
          "Aruvikuzhi Waterfalls, Chellarcovil",
          "Bodi Ghat",
          "Chinnamanur Sengulam",
          "Chinna Suruli",
          "Colonel Penny Kuick Bridge, Surulipatti",
          "cumbum mettu road",
          "Endapuli Tank",
          "Genguvarpatti Lake",
          "Highwavys",
          "John Pennycuick Memorial, Lowercamp",
          "Kailasapati Malai Kovil",
          "Kardana estate,Meghamalai",
          "Koduvilarpatty Reserve Forest--Veerappa Ayyanar Temple",
          "Kumily Ghat Road, In between TNSTC bus station and Murugan temple",
          "kurangani",
          "Kurangani Hills",
          "Kurangani -Top Station Trail",
          "Manjalar Dam, Palni Hills foothills",
          "Manjanuthu Check Post",
          "Meenakshipuram Tank",
          "Meghamalai",
          "Merusamuthira Kulam, Theni",
          "Odappadi Kulam",
          "Ottangulam",
          "Ottukulam",
          "Periyakulam Tank",
          "Periya Kulam, Thevaram",
          "Ramakkalmedu View point",
          "Road to Vannathiparai Rf from Lower Camp",
          "Sri Adi Chunchanagiri Women's College, Cumbum",
          "Sri Thirumalairaya perumal temple ( Commencement of Ramakkalmedu trek path",
          "Tamil Nadu Forestry college RF",
          "Thamaraikulam, Ramasamy Nayalan Patty",
          "Thamaraikulam Tank, Periyakulam",
          "Thevaram Mettu",
          "Uthamapalayam ( Cultivated fields to the west )",
          "Vaigai Dam",
          "Vaigai River Bridge, Vaigai Dam",
          "Vairavanar, below, Periyar Power station, KeelGudalur, Theni District ( Eagle OWL)",
          "Veera Naicken Kulam",
          "Veerapandi Riverview point",
          "Velimalai forest, Meghamalai Sanctuary",
          "Vellimalai",
        ],
      },
      {
        district: "Thiruvarur",
        localities: [
          "Jambuvanodai",
          "Kannarapettai Lake",
          "Nallur Farmlands",
          "Sengankadu",
          "Thiruthanam Kulam",
          "Udhayamarthandapuram Bird Sanctuary",
          "Vadapathy Village, Near Mannargudi",
          "Vaduvur Bird Sanctuary",
        ],
      },
      {
        district: "Kallakurichi",
        localities: [
          "Aandimalai Jain Beds, Sholavandipuram",
          "Aathu Kaadu, Manmalai",
          "AKT Memorial School Campus Kallakurichi",
          "Alathur Lake",
          "Alathur -- Puthanur Road",
          "Boat House, Kariyalur",
          "Chinnasalem Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "CholavandipuramTemple",
          "Emapper Lake, Kallakurichi",
          "Eravalam Lake",
          "Esandai Lake",
          "Ettiyaaru Falls",
          "Gomuki Dam",
          "Gomuki River Kallakurichi",
          "Government Middle School,Siruvangur",
          "Kalamarudur Lake, Kalamarudur",
          "Kallakurichi Lake",
          "Kallakurichi Town",
          "Kottaimedu, Kallakkurichi",
          "Kottaimedu, Kallakurichi",
          "Kottaimedu -- Surroundings",
          "Kuraal Tank Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â®Â¾Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kurur Lake",
          "Malaikottalam R.F",
          "Malliampadi",
          "Melathayanur Lake",
          "Melnariyappanur Village",
          "Melparigam R.F",
          "Mount Carmel School",
          "Mount Carmel School Road",
          "Neelamangalam Lake, Kallakurichi",
          "Palengur Big Lake",
          "Palengur Small Lake",
          "Panchayath Union Middle Class, Manmalai",
          "Parigam Road, Gomuki Dam",
          "Pasumaimangalam Road",
          "Peruvangur Village",
          "Pridivimangalam Lake",
          "Puthagaram lake (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Puthur Lake (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Rangeppanur R.F",
          "Rishivandiyam Lake, Tamil Nadu, IN (11.806, 79.094)",
          "Sandapet Lake (Periya Yeri)",
          "Sinnavaram Tank",
          "Siruvangur Village",
          "Sitheri Lake",
          "Thagarai Forest",
          "Thagarai Lake",
          "Thandalai lake",
          "Thirukoilure Temple Area",
          "Thiyagapadi Amman Temple,Gomuki Dam",
          "V. Alambalam Lake",
          "Vedapoondi",
          "Veerapayangaram Lake",
          "Veerattagaram Lake",
          "Vellimalai Forest Guest House, Tamil Nadu, IN (11.808, 78.702)",
          "Vengur Big Lake",
          "Venkatampatty Lake",
          "Vilambaur Lake",
          "Vilambur",
          "V. Krishnapuram Lake, Tamil Nadu, IN (11.57, 78.904)",
        ],
      },
      {
        district: "Ariyalur",
        localities: [
          "Arulmozhi Lake Ã Â®â€¦Ã Â®Â°Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â®Ã Â¯Å Ã Â®Â´Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ã Â¤Â¸Ã Â¥â€¹Ã Â¤Â¨Ã Â¤Â¿Ã Â¤Â¯Ã Â¤Â¾Ã Â¤Â¨Ã Â¤Â¾, Ã Â¤Â°Ã Â¤Â¾Ã Â¤Å“Ã Â¤Â¸Ã Â¥ÂÃ Â¤Â¥Ã Â¤Â¾Ã Â¤Â¨, IN (25.282, 75.093)",
          "Elakurichi Lake",
          "Elandaikudam Lake",
          "Gangai Konda Cholapuram Ã Â®â€¢Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Ë†Ã Â®â€¢Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸ Ã Â®Å¡Ã Â¯â€¹Ã Â®Â´Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â",
          "Govindaputhur Lake Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Karaikurichi Lake",
          "Karaivetti Bird Sanctuary Ã Â®â€¢Ã Â®Â°Ã Â¯Ë†Ã Â®ÂµÃ Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â®Â±Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â®Â³Ã Â¯Â Ã Â®Å¡Ã Â®Â°Ã Â®Â£Ã Â®Â¾Ã Â®Â²Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "Karigala Kere (Doddakere) Ã Â²â€¢Ã Â²Â°Ã Â²Â¿Ã Â²â€”Ã Â²Â¾Ã Â²Â²°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kollidam River Bridge, Ramanallur",
          "Kollidam river-- Thirumanur",
          "Kundhapuram Lake Ã Â®â€¢Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Marungoor East & Surrounds",
          "Palanganatham Lake",
          "Ponneri Lake",
          "Sripuranthan Lake",
          "Sukran Lake, Kamarasavalli",
          "Thamaraipoondi Lake Ã Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Ë†Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thoothur Lake",
          "Vadugapalayam Pond",
          "Venganur Lake Ã Â®ÂµÃ Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
        ],
      },
      {
        district: "Tirunelveli",
        localities: [
          "Agasthiyamalai Hill top",
          "Agasthiyar Falls",
          "Agasthyamalai Community Conservation Centre, Manimutharu",
          "Alagankulam Pond",
          "Anuvijay Township, Chettikulam",
          "Ariyanayagipuram Kulam",
          "Brancherri",
          "Central Excise Office (Ã Â®Â®Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â®Â²Ã Â®Â¾Ã Â®Â²Ã Â¯Â Ã Â®â€¦Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â²Ã Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Chepparai Nataraja Temple",
          "Chettikulam (OBI)",
          "Emankulam Tank",
          "Gangaikodan",
          "Gangaikondan Kulam",
          "Indira Kulam (South Pond)",
          "Jayamathapuram Pond and wind farm",
          "Kalakkad Mundanthurai Tiger Reserve",
          "Kaluvoor Kulam",
          "Kandan Kulam",
          "Karayar Dam",
          "Karungulam-Kabaliparai",
          "KMTR Research Centre (ATREE)",
          "Kolunthu Mamalai",
          "Koonikulam, Moolaikaraipatti",
          "Koonthakulam Bird Sanctuary",
          "Koonthankulam Grasslands",
          "Kottai Karungulam",
          "Kottaikarungulam- Nambi Dam Rd",
          "Kottai Karungulam/Vadivamman (Ã Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†Ã Â®â€¢Ã Â®Â°Ã Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Kuppakurchi Kulam",
          "Maanur Pond",
          "Madura Coats Colony",
          "Malayala Medu Kulam",
          "Manimuthar Dam",
          "Manimuthar Right Canal road",
          "Manimutharu Forest Guest House",
          "Manur Lake",
          "Moolakaraipatti Pond",
          "Morukulam-Kannamkulam-Namparchikulam Ponds, Jayamathapuram",
          "Mukkudal Pond (Korung Kulam)",
          "Mundanthurai Forest Guest House",
          "Mundanthurai Tiger Reserve--Forest Rest House",
          "Naalumukku, Singampatti Zamindar Forest",
          "Nainar Kulam, Tirunelveli",
          "Nallur East",
          "Nambi dam (Ã Â®Â¨Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¿ Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†) (Ã Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†Ã Â®â€¢Ã Â®Â°Ã Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Nambi Kovil Road",
          "Nambikurichi Kulam",
          "Nambiyar Dam",
          "Narasinganallur Pond",
          "Nellaiappar Temple",
          "NGO Colony Tank, Thirunelveli",
          "Oochikulam Periyakulam",
          "Oosikulam Periyakulam",
          "Paalaamadai Lake",
          "Padmaneri",
          "Palamadai/Rajavallipuram",
          "Pandipathu Forest camp",
          "Paraikulam Lake",
          "Parappadi Lake",
          "Pirancheri Periya Kulam",
          "PSN College and surrounds",
          "PSN College of Engineering and Technology, Melathediyoor",
          "PSN College Road",
          "Pudhukulam",
          "Rajapudhur Lake",
          "rajavallipuram",
          "Reddiarpatti Hill--Sai Baba Temple",
          "Saral Kulam",
          "Sarall Kulam",
          "Sengulam",
          "Sengulam, Tirunelveli",
          "Servalaru Dam",
          "Setruthamarai Kulam, Nanguneri",
          "Shifa Golden City, Tirunelveli (OBI)",
          "Sivandipatti Lake & Grassland",
          "Sivanthipatti Lake",
          "Sivanthipatti Pond",
          "Soorangudi Tank",
          "Spotted Deer Sanctuary, Gangaikondan",
          "St. Xavier's College, Palayamkottai",
          "Thamirabarani River, Kodaganallur",
          "Thamirabharani River, Near Kasyapanathar Temple, Ambasamuthiram",
          "Tharuvai Grassland",
          "Thirukurungudi Lake",
          "Thirunelveli Medical College Men's hostel",
          "Thiruppudaimaruthur Conservation Reserve",
          "Thiruvenkatanathapuram (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®ÂµÃ Â¯â€¡Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¸Ã Â®Â¨Ã Â®Â¾Ã Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â)",
          "Tirunelveli Railway Station",
          "Vadakkuvalliyur Pond (Ã Â®ÂµÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂµÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Vadukachimathil Pond (Dohnavur)",
          "Valliyur Kulam",
          "Valliyur Railway station",
          "Veinthaan Kulam (Pond), Palayamkottai",
          "Vijaya Achampaadu Kulam (Ã Â®ÂµÃ Â®Â¿Ã Â®Å“Ã Â®Â¯ Ã Â®â€¦Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Vijaya Achampadu Kulam",
          "Vijayanarayanam Tank",
        ],
      },
      {
        district: "Perambalur",
        localities: [
          "Annamangalam Scurb Forest (RF)",
          "Aranaarai Lake",
          "Avvaiyar Nagar Village Pond, Perambalur",
          "Eraiyur Lake",
          "Kalarampatti Lake",
          "Kurumbalur Palayam Lake",
          "Ladapuram Lake",
          "Mangalamedu Lake, Thevaiyur North",
          "Mayil Ootru Waterfalls",
          "National Fossil Wood Park, Sathanur",
          "Peraiyur Lake",
          "Pillankulam Tank Ã Â®ÂªÃ Â®Â¿Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sathanur Periya Eri",
          "Senjeri Lake",
          "Thuraimangalam Lake, Perambalur",
          "T. Keeranur Lake",
          "Vella Thangiyamman Lake, Perambalur",
          "Visvakudi Dam",
        ],
      },
      {
        district: "Tiruchirappalli",
        localities: [
          "Alangudimahajanam Lake",
          "Alathudaiyanpatti lake",
          "Andharapatti",
          "Anna Science Centre Planetarium",
          "Arasangudi Lake",
          "ARK Nagar Area",
          "Bharathidasan Institute of Management (BIM) Campus",
          "Bharathidasan University Campus",
          "BHEL Nehru Nagar Township",
          "Bishop Heber College",
          "Devarayaneri Lake",
          "Gundur Lake",
          "IIM Trichy",
          "Inamkulathur Lake",
          "Kambarasampettai Thaduppanai",
          "Kandhalur Scrub",
          "Kannanur Semi Arid Grasslands,",
          "Kannoothhu Lake",
          "Karattumalai Scrub Forest",
          "Kaveri River Bridge, Thiruchirapalli",
          "Kaveri River, Musiri",
          "Kiliyur Tank",
          "K.Kallikudy Lake",
          "K. K. Nagar Area",
          "Koothappar Tank",
          "Kovandakurichi Pond (Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿)",
          "Mahadevipudur Tank Ã Â®Â®Ã Â®â€¢Ã Â®Â¾Ã Â®Â¤Ã Â¯â€¡Ã Â®ÂµÃ Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mamarathu Solai, Solamathi",
          "MAM College Of Engineering",
          "MANIGANDAM TANK",
          "Manikandam Lake",
          "Marsh Land (Near Cauvery College for Women), Woraiyur",
          "Mavadi Kulam",
          "Melakulam Lake, Mudikandam",
          "National Institute of Technology (NIT), Trichy",
          "Navalpattu Lake",
          "Nehru Memorial College",
          "Neiveli Lake, Tiruchirappalli",
          "Padugai Lake",
          "Panjapur Lake",
          "Perur Lake, Tiruchirappalli",
          "Pillaiyarkulam, Koothappar",
          "Poolangudi Lake",
          "Poongudi Lake",
          "Poovalur Madanam Lake",
          "Punganur Lake",
          "Punjai Sangenthi Tank",
          "Puthanampatti Lake",
          "Putharchikulam",
          "Railway Mixed Higher Secondary School, Tiruchirappalli",
          "Reddiyarpatti Lake, Eragudi South",
          "Sangendhi Grassland",
          "Sengkurrichi Tank",
          "Settiyarpettai Tank",
          "Srirangam Railway Station",
          "Thachankurichi RF",
          "Thinnanur lake",
          "Thiruverumbur Big Tank",
          "Thiruverumbur Lake",
          "Thuraiyur Lake",
          "Thuvakudi Lake",
          "Tiruchirappalli Corporation Sewage Water Treatment Plant",
          "Tiruchirappalli Junction Railway Station",
          "Top Sengattupatti",
          "Tropical Butterfly Conservatory (Butterfly Park)",
          "Uchi Pillaiyar Kovil / Malaikottai / Rock Fort",
          "Upper Anaicut / Mukkombu Dam",
          "Uyyakondan Canal, Raja Colony",
          "uyyakondan river",
          "Vadakupatti Lake, Eragudi North",
        ],
      },
      {
        district: "Chengalpattu",
        localities: [
          "Vadanamelli Village",
          "Kalpakkam",
          "Naduvaneri Tank Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kovalam Surfing Point",
          "Thaiyur lake",
          "Odiyur Lake-- Paramankeni Bridge",
          "Mannivakkam Lake",
          "MCC Campus-- Matriculation Higher Secondary School (Restricted Access)",
          "Agaram Village-- Chithamur (Kanchipuram) (Ã Â®â€¦Ã Â®â€¢Ã Â®Â°Ã Â®Â®Ã Â¯Â-- Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Pattaravakkam (Kanchipuram) (Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â°Ã Â®ÂµÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)-- near Anjaneyar Temple",
          "pandur",
          "Vandalur Repeater Road",
          "Erandamkattalai",
          "SRM University Main Campus",
          "Odiyur Lake",
          "Hasthinapuram Lake",
          "Veeraganur Lake Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Karikili Birds sanctuary - Pond 2",
          "Nanmangalam Lake",
          "Kamalapuram Tank Ã Â®â€¢Ã Â®Â¾Ã Â®Â®Ã Â®Â²Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mahabalipuram Beach",
          "Mahabalipuram Light House",
          "Ammanambakkam Panchayat (Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â©Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Vengapakkam--ECR",
          "Kumizhi Lake, Nallambakkam",
          "Periyar Lake, Pallavaram",
          "Pathashaala, KFI",
          "Thalavaipatty Lake (Mettupalayam Tank) Ã Â®Â¤Ã Â®Â³Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®",
          "The School-KFI campus - (Restricted) - Thazhambur (Ã Â®Â¤Ã Â®Â¾Ã Â®Â´Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Ariyapalayam Tank Ã Â®â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â",
          "Thiruneermalai Periya Eri",
          "Kadaperi lake",
          "Agaramthen Lake",
          "SRM Lake, Potheri",
          "Polacheri Lake",
          "Madayathur Lake",
          "Kelambakkam",
          "Sithalapakkam Lake and Reserve forest (Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â®Â²Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Ã Â®Â®Ã Â®Â±Ã Â¯ÂÃ Â®Â±Ã Â¯ÂÃ Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â)",
          "Thaiyur Lake",
          "OMR Kelambakkam Marsh",
          "Mongoose Campsite",
          "Nemmeli Salt Pans",
          "Koovathur",
          "Mudhaliarkuppam- Muttukadu Backwaters - Edaikazhinadu Lagoon",
          "Pallambakkam--Saltpans (Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â, Ã Â®â€°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Illavampattu Lake (Ã Â®â€¡Ã Â®Â²Ã Â®ÂµÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å",
          "Arambakkam Lake",
          "Kolapakkam (Vandalur-Kelambakkam Rd.)",
          "Kokkare Bellur Ã Â²â€¢Ã Â³Å Ã Â²â€¢Ã Â³ÂÃ Â²â€¢Ã Â²Â°Ã Â³â€  Ã Â²Â¬Ã Â³â€ Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â³",
          "Muvarasanpattu Eri",
          "Mudichur Lake",
          "Vivanta by Taj",
          "Arignar Anna Zoological Park (Vandalur Zoo)--Otteri Lake",
          "Kelambakkam Marsh lands",
          "Mahabalipuram Backwaters",
          "Madurapakkam Reserve Forest (Ã Â®Â®Ã Â®Â¤Ã Â¯ÂÃ Â®Â°Ã Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â)",
          "Kalyanagiri Tank Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â¯Ã Â®Â¾Ã Â®Â£Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Chengalpattu Medical College",
          "Kanchathumalai (Ã Â®â€¢Ã Â®Â¾Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â®Â²Ã Â¯Ë†) Rocky Scrub Forest",
          "Kattangulathur Reserve Forest",
          "Dakshina Chitra Museum",
          "Thirukalukundram Hill",
          "Madippakkam Lake",
          "Selaiyur Lake",
          "Hasthinapuram Lake Canal",
          "Ninnakarai Lake, Maraimalai Nagar",
          "Othivakkam Tank",
          "Vedanthangal Scrubland (Ã Â®ÂµÃ Â¯â€¡Ã Â®Å¸Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®Â®Ã Â¯ÂÃ Â®Â³Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â®Â³Ã Â¯Â)",
          "Thazhambur (Ã Â®Â¤Ã Â®Â¾Ã Â®Â´Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â°Ã Â¯Â) Lake",
          "PGRIAS - Kattupakkam",
          "Kalpakkam--Bridge",
          "Alamparai Fort--Estuary",
          "Kilkattalai Lake",
          "Vedanthangal Bird Sanctuary",
          "Kolavai Lake",
          "Valudavur Lake (Ã Â®ÂµÃ  Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯Â°Ã Â¯Â Ã Â®ÂÃ Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Madras Christian College (MCC)",
          "Irumbuliyur Lake (North tank)",
          "Kolapakkam lake",
          "Manampathy Lake",
          "Chennai Mathematical Institute",
          "Nedumgundram Lake",
          "Koppa Kere   Ã Â²â€¢Ã Â³Å Ã Â²ÂªÃ Â³ÂÃ Â²Âª  Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Ã Â¤Â¸Ã Â¥â€¹Ã Â¤Â¨Ã Â¤Â¿Ã Â¤Â¯Ã Â¤Â¾Ã Â¤Â¨Ã Â¤Â¾, Ã Â¤Â°Ã Â¤Â¾Ã Â¤Å“Ã Â¤Â¸Ã Â¥ÂÃ Â¤Â¥Ã Â¤Â¾Ã Â¤",
          "Sirukalathur",
          "Buckingham Canal",
          "Arignar Anna Zoological Park (Vandalur Zoo)",
          "SSN Institution",
          "Chettipunniyam Lake",
          "Perumbakkam Village",
          "Karadi Malai Camp",
          "Tiruneermalai",
          "Madras Crocodile Bank Trust",
          "Nallur Lake Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Odiyur Lake Bridge",
          "Echoor Lake",
          "Karikili Bird Sanctuary, Kancheepuram",
          "Vembedu lake",
          "Thirukachur Lake",
          "Pudupakkam, Tamil Nadu, IN (12.791, 80.205)",
          "Nanmangalam Reserve Forest",
          "Siruseri Lake",
          "Kalpakkam Township",
          "Kattur RF",
          "Venganur Lake Ã Â®ÂµÃ Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kattur Lake",
          "Munnur Lake (Ã Â®Â®Ã Â¯ÂÃ Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kattangulathur Lake",
          "Alanthur RF- trail",
          "Amoor Lake",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)",
          "Sri Nithya Kalyana Prasanna Venkatesa Perumal temple (Restricted access)",
          "Kavinadu Kanmai  (Ã Â®â€¢Ã Â®ÂµÃ Â®Â¿Ã Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â®",
          "Pallavaram-Thuraipakkam 200 feet Radial Road (Vels Point)",
          "Pooncheri Wetland and Scrub Jungle",
          "Palar Check dam",
          "Muttukadu Boat House area",
          "Purvankara Swanlake, Padur, Chennai",
          "Pudupattu Lake",
          "Paranur Lake",
          "Pancha Rathas, Mahabalipuram",
          "Thirukazhukundram Lake",
          "Air Force Station, Tambaram",
          "Maruntheeswarar Temple Girivalam Path, Thirukachur",
          "Vandalur Lake",
          "Illalur Reserve Forest",
          "Madurantakam Lake",
          "Muttukadu Backwaters, Kovalam",
          "Kelambakkam Backwaters",
          "Maraimalai Nagar Railway Station, Kanchipuram Dt",
          "Alathur Lake",
          "Nandhivaram Lake",
          "Kasthuripatti Tank Ã Â®â€¢Ã Â®Â¸Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â",
          "Hasthinapuram Lake Road (Ã Â®Â¹Ã Â®Â¸Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â©Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¾Ã Â®Â²Ã Â¯Ë†)",
          "Venbedu Lake",
          "Rajakizhpakkam Lake",
          "Perungalathur Lake",
          "Zamin Endathur",
          "Siruseri-- Eden Park Avenue",
          "Kattur lake",
          "Kasbapuram",
          "Erumapalayam Tank Ã Â®Å½Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Indraprastha Ã Â²â€¡Ã Â²â€šÃ Â²Â¦Ã Â³ÂÃ Â²Â°Ã Â²ÂªÃ Â³ÂÃ Â²Â°Ã Â²Â¸Ã Â³ÂÃ Â²Â¥ÂÃ Â®Â°Ã Â®Â¿)",
          "Tiger Lily lake",
          "Nemilicheri",
          "Arappedu village (Ã Â®â€¦Ã Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯Â)",
          "Muttukadu Backwaters",
          "Kayar Lake",
          "Irumbuli Scrub",
          "Potheri Lake",
          "Gendracheri Lake (Ã Â®â€¢Ã Â¯â€ Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â°Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿) Tamilnadu",
          "Moolacheri Lake",
          "Chitlapakkam Lake",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ",
          "Pachaimalai",
          "SIPCOT Pond (Restricted access)",
          "SP Kovil - Hanumanthapuram Road",
          "Kolathur R.F",
          "Naachipudur Tank Ã Â®Â¨Ã Â®Â¾Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã",
          "Padalam-Vedanthangal Road",
          "Nallavadu Beach (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®ÂµÃ Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â®Å¸Ã Â®Â±Ã Â¯ÂÃ Â®â€",
          "Cheyyur (Town)",
          "Grassland behind ECR Dhaba",
          "BIS CampusÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œmixed (built-up, playground, trees along road) 1Ã¢â‚¬â€œPMP",
          "Thaamarai Kuttai (towards Tharamangalam) Ã Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Ë†Ã Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Sri Sairam Engineering College",
          "Acharampattu Lake (Ã Â®â€¦Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Illalur Lake",
          "Nettapakkam Lake (Ã Â®Â¨Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®",
          "Balaji Nagar Park, Irumpuliyur",
          "Kovalam Beach",
          "Arignar Anna Zoological Park (Vandalur Zoo)-- Birds and Butterflies (Ã Â®â€¦Ã Â®Â±Ã Â®Â¿Ã Â®Å¾Ã Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾ Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®Â¯Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾)",
        ],
      },
      {
        district: "Thanjavur",
        localities: [
          "Allur Lake",
          "Chellappanpettai Lake, Thanjavur",
          "Ganapathi Agraharam - Manalur",
          "Ideal Riverview Resort",
          "Indeco Hotels Swamimalai",
          "Iyanar Temple Area, Nanjikottai",
          "Kaatuthottam Farmlands",
          "Kalancheri Village Farmlands",
          "Kallanai Dam",
          "Kallaperambur Lake",
          "Karambai, Mannankadu",
          "Karuppur Village",
          "Kaveri River Trail, Veppathur",
          "Keezhathottam Mangrove Swamp",
          "Kollidam River, Anaikarai",
          "Kollidam River, Anaikudi",
          "Kollidam River, Keezharamanallur",
          "Kollidam River, Kudithangi",
          "Kollidam River, Thirupurambiyam",
          "Kollidam River, Thiruvaikavur",
          "Kollidam River, Vazhkai",
          "Korukkai Village",
          "Kudamuruty River Trail, Thirupalaithurai",
          "Kulichapattu Pond",
          "Lotus Pond, Punnainallur",
          "Manaiyeripatti Scrubland and Lake",
          "Maravakkadu Mangroves",
          "Maravakkadu Salt pan",
          "Maruthakudi Lake",
          "Nanjikottai Lake",
          "Papanasam - Kabisthalam Road, Thanjavur Dt.",
          "Papanasam Railway Station",
          "Parisutham Nagar",
          "Periyakulam Area",
          "Periyar Nagar, Sakkottai",
          "Perumangudi Village Farmlands, Thanjavur Dt",
          "Ponnan Lake",
          "Puthambur Scrub",
          "Radiant School, Thanjavur",
          "Rayamundanpatti Lake",
          "River Thirumalairajan, Papanasam",
          "Samuthiram Lake, Thanjavur",
          "SASTRA University",
          "Sivagangai Park, Thanjavur",
          "Solagampatti Lake",
          "Solagampatti Scrubland",
          "Solagampatti Scrubland--North",
          "Sorakudipatti Lake",
          "Tamil University Campus",
          "Thanjavur Big Temple",
          "Thanjavur Junction Railway Station",
          "Thanjavur Medical College",
          "Thatumaal Padugai to Kudikadu",
          "Thiruvisanallur Farmlands, Thanjavur Dt",
          "Veeraiyan Kulam, Atthivetti",
          "Veppankulam (Pond), Goburajapuram",
          "Veterinary College and Research Institute, Orathanadu",
        ],
      },
      {
        district: "Kancheepuram",
        localities: [
          "Adayar River-- Channel (Neelamangalam)",
          "Adayar River-- Channel (Overbridge)",
          "Adhanur lake",
          "Adhanur Lake--East",
          "Adhanur Lake--West",
          "Amarambedu Lake",
          "Amarambedu Scrub Land",
          "Athananchery Lake",
          "Auro Vanam Ã Â®â€ Ã Â®Â°Ã Â¯â€¹ Ã Â®ÂµÃ Â®Â©Ã Â®Â®Ã Â¯Â (Restricted  Access",
          "Ã Â®Å½Ã Â®Â´Ã Â®Â¿Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â®Â®Ã Â¯Â Ã Â®Â¸Ã Â¯ÂÃ Â®Â°Ã Â¯â‚¬Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â(Ezhilivanam, Sriperumbudur)",
          "Ã Â®â€¡Ã Â®Â³Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â (Ilaneerkulam)",
          "Chembarambakkam Lake",
          "Chembarambakkam Lake, Irungattukottai",
          "Erumaiyur",
          "Good Earth School",
          "Guduvanchery Lake",
          "Guruvanmedu Eri(Lake)",
          "Irungattukotai Lake -(East side) Ã Â®â€¡Ã Â®Â°Ã Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kancheepuram (OBI)",
          "Karai Lake",
          "Kavanur Lake",
          "Lake Neat VGN Brixton, Mevalurkuppam",
          "Le Chalet Road, Mevalurkuppam",
          "Mahanyam Lake",
          "Malaipattu",
          "Manimangalam lake",
          "Manimangalam Lake",
          "Mannur Lake (Ã Â®Â®Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mevalurkuppam",
          "Mevalurkuppam-- VGN Brixton road (13.006, 79.987)",
          "Nathapettai Lake",
          "Nattarasanpattu-- Arambakkam Road",
          "Neerkundram",
          "Nemili Lake Ã Â®Â¨Ã Â¯â€ Ã Â®Â®Ã Â®Â¿Ã Â®Â²Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nemili Twin Lake Ã Â®Â¨Ã Â¯â€ Ã Â®Â®Ã Â®Â¿Ã Â®Â²Ã Â®Â¿ Ã Â®â€¡Ã Â®Â°Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿Ã Â®â€¢Ã Â®Â³Ã Â¯Â",
          "Oragadam Lake",
          "Pennalur Lake (Behind EB office)",
          "Pillaipakkam/Navalur Lake",
          "Ponneri Lake",
          "Pullalur (Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Serappanachery",
          "Sethupet",
          "Shri Perumbudur Lake",
          "Sivanthangal Pond, Sriperumputhur",
          "Somangalam",
          "Somangalam Periya Eri",
          "Somangalam Quarry",
          "Sriperumputhur Lake (Thiruvallur Road)",
          "Sri Venkateswara College of Engineering, Chennai",
          "Suramenikuppam Lake",
          "Thandalam Lake (Ã Â®Â¤Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thenneri Lake",
          "Thimmarajampet",
          "Uthiramerur Lake",
          "Vaiyavoor Lake",
          "Valarpuram Lake",
          "West Tambaram -- Casa Grande Environs",
        ],
      },
      {
        district: "Kanniyakumari",
        localities: [
          "Alur Pond and Paddy fields",
          "Anantya Resort Survey",
          "Andivilai Salt Pans",
          "Atchankulam Pond",
          "Balamore Estate road",
          "Beemanagari Lotus Pond, Nagercoil",
          "Chengulam",
          "Chittar dam 1",
          "Chittar Dam 2",
          "Chunkankadai Lake",
          "Cinnapatta Malai, Kottavilai",
          "Dwarakapathi beach",
          "Elayanainarkulam Lake",
          "Erachakulam Lake",
          "Eraviputhoor  Lake",
          "Kalikesham, Kanyakumari wildlife sanctuary",
          "Kanniyakumari Beach",
          "Kanniyakumari-- Vempanoor-- Perumselvavilai",
          "Karumparai (Black Rock)",
          "Keezhamanakkudi salt pan",
          "Keezha Puthalam Salt Pans",
          "Kothayar Lower Camp Road",
          "Kovalam salt pan",
          "Kuthrapaanjan Falls",
          "Kuzhithurai Bridge",
          "Kuzhithurai Cultivations",
          "Maharajapuram-Kannankulam Ponds, Anjugramam",
          "Manakudi River Mouth",
          "Manakudy Backwater Bridge",
          "Manakudy Beach",
          "Manakudy Mangrove Park",
          "Manavalakurichi Kulam / Periya Kulam",
          "Manikaputhery Lake",
          "Marundhuvazh Malai",
          "Marundhuvazh Malai forest",
          "Mukkadal Dam",
          "Nagercoil-- Parvathipuram Lake (South)",
          "Nagercoil Rly Jn",
          "Nalloor Lake",
          "Ozhuginasery Graamam",
          "Paddy fields near New highway",
          "Parakkai Lake",
          "Pathukani",
          "Periya Kulam",
          "Perunchani Dam",
          "Perunkulam",
          "Ponmanai Dam",
          "Puthalam Salt Pans",
          "Putheri Lake",
          "Puthukulam",
          "Rajakamangalam Lake",
          "Salt Pans Near Sunset Point",
          "Salt Pans, Swamithoppu",
          "Suchindram Lake",
          "Sunset Point",
          "Surulode",
          "Thanumalayanputhoor Pond",
          "Thengamputhoor Lake",
          "Theroor",
          "Theroor Lake",
          "Thoothoor Beach",
          "Thovalai Hills",
          "Udhayagiri Fort - Bio-diversity Park, Puliyurkurichi",
          "Vallavilai Beach",
          "Vazhukamparai Pond",
          "Velimalai Rubber Plantation",
          "Vembanur Wetland Complex",
        ],
      },
      {
        district: "Tenkasi",
        localities: [
          "Adaichani Periyakulam",
          "Amar Seva Sangam",
          "AMC Government Higher Secondary School",
          "Anthanpatti lake",
          "Attakulam Lake, Kadayanallur",
          "Ayikudi Kulam",
          "Elathur Lake",
          "Hadinaru Kere Ã Â²Â¹Ã Â²Â¦Ã Â²Â¿Ã Â²Â¨Ã Â²Â¾Ã Â²Â°Ã Â³Â Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ ",
          "Ilanji Kulam",
          "Kadana Nadhi Dam",
          "Kadayanallur Paddy Fields",
          "Kambley Tank",
          "Kizhla kadaiyam (Ã Â®â€¢Ã Â¯â‚¬Ã Â®Â´Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Kotta Kulam",
          "Kundaru Dam",
          "Maranthai Kulam",
          "Maranthai Lake",
          "Melappavoor Kulam",
          "Parasakthi Nagar",
          "Puliyur Kulam",
          "Rettaikulam",
          "Shenkottai (Ã Â®Å¡Ã Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¡Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Sundarapandiyapuram Kulam",
          "Surandai Kulam",
          "Thalaiyanai Theerthaparai Falls",
          "Thirumalai Kovil",
          "Thuppakudi Kulam",
          "Thuppakudi Kulam (Ã Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿)",
          "Vaghai Kulam",
          "Virasigamani Periyakulam",
        ],
      },
      {
        district: "Virudhunagar",
        localities: [
          "Alathan Kulam, Rajapalayam",
          "Alathur Lake",
          "Anaikottam Reservoir, Sivakasi",
          "Arulmigu Angala Eeswari Temple, Mamsapuram",
          "Ayodhiram Nagar",
          "Ayya Nadar Janaki Ammal College",
          "Ayyanar falls , Rajapalayam",
          "Chinmaya Vidyalaya PACRR Matric HS School",
          "Chinnavalikulam Rd",
          "Dry Lands, Singammalpuram",
          "Grizzled Squirrel Wildlife Sanctuary, Srivilliputhur",
          "Irukkankudi Reservoir",
          "Kadamankulam (Thoppupatti)",
          "Kalasalingam Academy of Research and Education",
          "Karungulam Lake",
          "Katalagar Temple Nupura Gangai Theertham",
          "Katalagar Waterfalls, Srivilliputhur Grizzled Squirrel Sanctuary",
          "Kizhavankovil Karuppasamy Kovil",
          "Kondaneri Lake, Rajapalayam",
          "Koomapatti Lake",
          "Kousiga River, Virudhunagar",
          "Krishnappery",
          "Kullursandai Reservoir",
          "Maamarathu Keni, Sastha Koil, Settur RF",
          "Melapattam Karisalkulam",
          "Mudhaliarkulam",
          "New Bus Stand Lake, Rajapalayam",
          "PAC Ramasamy Raja Polytechnic College",
          "Palavanatham - Gopalapuram Road",
          "Patchaimadam Lake",
          "Patchaimadam (Thoppupatti)",
          "Pilavakkal Dam",
          "Puliyamkulam",
          "Puthukulam Lake",
          "Rajapalayam Railway Station",
          "Rajapalayam Rajus' College",
          "Rajapalayam Water Reservoir",
          "Rakatchi Amman Kovil, near Srivilliputhur GSWS",
          "Rakatchi Amman Kovil, Valaikulam RF",
          "Ramco Institute of Technology",
          "Sappaniparambu",
          "Sathakovil Dam",
          "Sengulam Lake, Thiruthangal",
          "Shenbagathoppu",
          "Sri Mathi Lingammal Ramarajhu Matriculation HSS",
          "Suryankallu Camp",
          "Tambathan Urani",
          "Thaaniparai, Virudhunagar Dt",
          "Twin Tank / Puliyan Kulam, Rajapalayam",
          "Valaikulam Reservoir",
          "Valukuparai",
          "Vellaichamy Nadar Polytechnic College",
          "Virudhunagar Hindu Nadars' Senthikumara Nadar College (VHNSN)",
        ],
      },
      {
        district: "Dharmapuri",
        localities: [
          "Ammapalaiyam Reserve Forest",
          "Annasagaram Lake, Dharmapuri",
          "Baisuhalli Lake",
          "Dharmapuri Lake, Mathikonpalayam",
          "Dhippampatti Lake",
          "Green Park School, Kadathur",
          "Hanging Bridge-- Hogenakkal (Ã Â®Â¹Ã Â¯â€¡Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®â„¢Ã Â¯Â Ã Â®ÂªÃ Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å“Ã Â¯Â-- Ã Â®Â¹Ã Â¯â€¹Ã Â®â€¢Ã Â¯â€¡Ã Â®Â©Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â)",
          "Hogenakal Waterfalls",
          "Hogenakkal-- Children's Park (Ã Â®Â¹Ã Â¯â€¹Ã Â®â€¢Ã Â¯â€¡Ã Â®Â©Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â)",
          "Ilayakkimpatti Lake, Dharmapuri",
          "Kadagathur Lake",
          "Kalrayan Hills",
          "Kalrayan Hills--Sittling Bridge",
          "Kattamedu Social Forestry Centre (and surrounding scrub)",
          "Malaiyur Hill",
          "Modakeri Malai Birding Area",
          "Moongilpatti Area",
          "Nachiampatti Lake",
          "Nagamathi Hillock",
          "Nagavathi Reservoir",
          "Panchapalli Dam, Dharmapuri",
          "Pennagaram Lake",
          "Periyanahalli Lake",
          "Puvidham Campus",
          "Settikarai Lake",
          "Thoppaiyaru Reservoir",
          "Valasamalai RF (Iora habitat)",
          "Vallimadurai Reservoir",
          "Vanapa Natural & Sustainable Farm",
          "Vathalmalai Hills, Dharmapuri",
          "Vellolai Road Forest Area",
        ],
      },
      {
        district: "Thiruvallur",
        localities: [
          "Alamathi Cattle Farm",
          "Ambattur",
          "Ambattur Lake",
          "Annamalaicherry wetland & grassland (OBI)",
          "Annamalaichery Watch Tower (Ã Â®â€¦Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Â®Ã Â®Â²Ã Â¯Ë†Ã Â®Å¡Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â)",
          "Annanur Railway Station Marsh Land",
          "Arani River",
          "Avadi Paruthipattu Lake Green Park",
          "Avadi-- Viraraghavapuram- Marshes",
          "Avoor- II (Ã Â®â€ Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â- II)",
          "Avurivakkam (Ã Â®â€¦Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â®Â¿Ã Â®ÂµÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Ayapakkam Lake",
          "Ã Â¤Â¬Ã Â¤Â²Ã Â¥â€¡Ã Â¤Â¸Ã Â¤Â°Ã Â¤Â¿Ã Â¤Â¯Ã Â¤Â¾, Ã Â¤Â°Ã Â¤Â¾Ã Â¤Å“Ã Â¤Â¸Ã Â¥ÂÃ Â¤Â¥Ã Â¤Â¾Ã Â¤Â¨, IN (25.539, 74.608)",
          "Buckingham canal, Manali",
          "Cholavaram Lake (Ã Â®Å¡Ã Â¯â€¹Ã Â®Â´Ã Â®ÂµÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Edoor",
          "Ennore Bridge",
          "Ennore Creek",
          "Ernakuracheri-- Lotus Pond",
          "Erumaivettipalayam RF",
          "Giri Nagar, Ramapuram",
          "Guidyam Caves",
          "Kanavanthurai (Ã Â®â€¢Ã Â®Â©Ã Â®ÂµÃ Â®Â¾Ã Â®Â©Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â±Ã Â¯Ë†)",
          "Kannadapalayam Lake",
          "Karungali",
          "Kattoor Lake -Thiruvallur (Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ - Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®ÂµÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Kaveri Bridge & Surrounds Ã Â®â€¢Ã Â®Â¾Ã Â®ÂµÃ Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Konimedu Lake",
          "Korattur Lake",
          "Kosasthalai River-- Vayalur (Ã Â®ÂµÃ Â®Â¯Ã Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Kosasthalaiyar River -Athipattu Main Rd.",
          "Kosathalaiyar River, Kaatupalli",
          "Kovil Kuppam, Tamil Nadu",
          "Krishna Water Canal",
          "Livestock Farm Complex Madhavaram",
          "Madhavaram Botanical Garden",
          "Methur Grasslands",
          "Minjur suburbs",
          "Mittanamalli Lake",
          "Mittanamalli Wetland",
          "Nayapakkam Lake",
          "Neithavayal Lotus Pond",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)",
          "Oomayampatti Tank Ã Â®Å Ã Â®Â®Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°",
          "Pallavada Lake",
          "Pandeswaram Lake",
          "Pandur Lake",
          "Pazhaverkadu Beach",
          "Pazhaverkadu Lake",
          "Periyakuttai (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†) Â®Â°Ã Â®Â¿)",
          "Perunjeri Lake",
          "Poompozhil Nagar Lake",
          "Poondi Lake",
          "Port Access Road, Puzhuthivakkam",
          "Pulicat Birding Site",
          "Pulicat Fish market",
          "Pulicat Lake--Annamalaicheri",
          "Pulicat Lake (TN section)",
          "Putlur lake",
          "Ramapuram Lake",
          "Red Hills (Puzhal) Lake",
          "Redhills (Puzhal) Lake-- View Point",
          "Retteri Lakewalk",
          "Sadayankuppam Canal",
          "Seganimedu Lake",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Sri Ramachandra Medical College",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€",
          "Thandurai lake",
          "Thanneer Kulam Lake",
          "Thiruninravur (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â±Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Thirupalaivanam",
          "Thirutani Hill Foot Path way (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â£Ã Â®Â¿ Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®Â¨Ã Â®Å¸Ã Â¯Ë†Ã Â®ÂªÃ Â®Â¾Ã Â®Â¤Ã Â¯Ë† Ã Â®ÂµÃ Â®Â´Ã Â®Â¿)",
          "Thirutani Hill Road (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â£Ã Â®Â¿ Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®ÂªÃ Â®Â¾Ã Â®Â¤Ã Â¯Ë†)",
          "TVS Avenue Area",
          "Vadaranyeswarar Swamy Temple",
          "Vediyangadu (Ã Â®ÂµÃ Â¯â€¡Ã Â®Å¸Ã Â®Â¿Ã Â®Â¯Ã Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â)",
          "Vellanur Lake",
          "VelTech MultiTech Engineering College & surroundings",
          "Vilinjiyambakkam Lake",
          "Wetland Opposite to Toshiba",
        ],
      },
      {
        district: "Ranipet",
        localities: [
          "Arakkonam Lake",
          "Arakkonam Rly Jn",
          "Arumbakkam Koot Road",
          "Banavaram Reserve Forest",
          "Kalavai Lake",
          "Kaveripakkam Lake",
          "Kuppathu Mottur Kulam (Ã Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®Â®Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Lakshmi Nagar/Arakkonam Lake",
          "Natham-Villapakkam range",
          "Sathambakkam",
        ],
      },
      {
        district: "Erode",
        localities: [
          "Anthiyur Periya Yeri Lake",
          "Arasannamalai",
          "Arepalayam, Erode",
          "Avalpoondurai Lake",
          "Bannari Forest Check Post",
          "Bargur Cattle Research Station",
          "Bargur, Erode Dt",
          "Bhavanisagar Backwaters, Pethikuttai",
          "Chennimalai",
          "Dhimbam - Bannari Ghat Rd",
          "Dhimbam -- Kottadai",
          "Dhimbham",
          "Elathur Pond (Ã Â®ÂÃ Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Erode Railway Junction",
          "Erode Silver Jubilee Bus Stand",
          "Gethasal, Hasanur",
          "Gobichettipalayam Buglowpudur Road",
          "Hassanur",
          "Jeerahalli",
          "Karachikorai Forest Check Post--STR",
          "Karuvilparai Valasu Kulam",
          "Kavulipalayam Lake",
          "Kongahalli",
          "Kottadai",
          "Kunderipallam Dam",
          "Maniyachi Pallam Bridge",
          "Mayar Valley--STR",
          "Nagamalai (Ã Â®Â¨Ã Â®Â¾Ã Â®â€¢Ã Â®Â®Ã Â®Â²Ã Â¯Ë† - Ã Â®ÂÃ Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â°Ã Â®Å¸Ã Â¯Â)",
          "Neithalapuram Pond",
          "Odathurai Lake (Ã Â®â€œÃ Â®Å¸¬Ã Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®† Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Â¯Â)",
          "Odathurai Lake (Ã Â®â€œÃ Â®Å¸Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â±Ã Â¯Ë† Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Orathupalayam Dam",
          "Ottakuttai Road, Sathyamangalam",
          "Pudubeerkadavu Farmlands",
          "Pudupeerkadavu",
          "Sathiyamangalam Wildlife Sanctuary--Hasanur",
          "Sathyamangalam Bus Stand",
          "Sathyamangalam Tiger Reserve--Germalam",
          "Sathyamangalam Tiger Reserve-- Jeerakahalli",
          "Sathyamangalam Tiger Reserve--Koolithuraipatti",
          "Sathyamangalam Tiger Reserve--Mangalapatti",
          "Sathyamangalam Tiger Reserve--Thalamalai",
          "Sathyamangalam TR - APW Watchtower - Thengumarahada",
          "Sri Karuvannarayar Temple Area--STR",
          "Sugai Lake",
          "Thamarakarai",
          "Vellalar College for Women",
          "Vellode Bird Sanctuary, Erode",
          "Vellode Kulam",
        ],
      },
      {
        district: "Dindigul",
        localities: [
          "Adalur",
          "Adukkam forest",
          "Adukkam Forest",
          "AKKAMAR TEMPLE",
          "Akkamar Temple, Ayyampalayam",
          "Alagarkovil-- Thethampatti (Western slopes -1)",
          "Alagarkovil-- Thethampatti (Western Slopes- 2)",
          "Alagar Malai",
          "Alternate Route to Bison valley from Kallalagar Temple",
          "Ambathurai Railway Station(Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â°Ã Â¯Ë† Ã Â®â€¡Ã Â®Â°Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â)",
          "Anaipatti kulam",
          "aranmanai kulam",
          "Aranmanai Kulam",
          "Attur Kamarajar Sagar Dam",
          "Ayakudi Kulam",
          "Ayallore Area",
          "Ayyalur",
          "Ayyampulli Kulam",
          "Bear Shola Stream, Kodaikanal",
          "Berijam Lake, Palani Hills",
          "Berijam Range, Kodaikanal Div.",
          "Bodiya Koundanpatti Lake",
          "Bombay Shola",
          "Bombay Shola, Kodaikanal",
          "Bombay Shola--Upper Shola (lake view) Road",
          "Bryant Park, Kodaikanal",
          "Chettiyar Park",
          "Chinnamalai, Alagarkovil Western slopes",
          "Coaker's walk, Kodaikanal",
          "Dindigul-- Periyur",
          "Dindigul-- Vembarpatti-- Kalathu Kottam-Agriculture Farm",
          "Dindugal Fort",
          "Elephant Valley",
          "Elephant Valley Eco Farm (OBI)",
          "Fairy Falls Road",
          "Forest Rest House Surroundings, Kodaikanal",
          "Grey Wagtail Falls, Palani Hills",
          "Gudalur Sellakulam",
          "Guna Caves",
          "Idayakottai",
          "Idumban Kulam, Palani",
          "Indian Grey Hornbill Spot",
          "Kalari Forest School",
          "Kamarajar Sagar Dam , Palni Hills foothills",
          "Kanalkadu",
          "Kannapatti Tank",
          "Karandamalai General Area",
          "Karungkuttu Karuppanasami Temple Sacred Grove, Natham Dindigul",
          "Kasampatti Veera Koil Sacred Grove ( Veera Bahu Thevar Koil )",
          "Kesavan/ Thesam Koil Kaadu Sacred Grove",
          "Kilisholai parai, Palani Hills",
          "KIS Guest House (Restricted Access)",
          "Kodai Foothills",
          "Kodaikanal-- Kodaikanal International School (Lochend)",
          "Kodaikanal Lake Road",
          "Kodaikanal Municipality- General area",
          "Kodaikanal Solar Observatory Area",
          "Kodaikanal to Mannavanur road",
          "Kookal, Kodaikanal",
          "Kothaimangalam Wetlands",
          "Kudhirayar Dam, Palani Hills",
          "Kumbakarai Falls",
          "Madurai Veeran Koil Sacred Grove, Sakkilian Kodai",
          "Mangalamkombu",
          "Mannavanur / Elumpallam Lake",
          "Maruthanathi Dam , Palni Hills foothills",
          "M. Vadipatti tank",
          "Nanganji dam near",
          "Oorkalan Temple Sacred Grove, Odugampatti, Natham'",
          "Palani city view point near Poombarai, Palani Hills",
          "Palani Hills-- 3rd Hairpin bend",
          "Palani Hills-- Palar Dam view",
          "Palani-Kodaikanal Hill Road",
          "Palani Murugan Temple Hillock",
          "Palar  Dam, Palni Hills Foothills",
          "Palar Dam View Point",
          "Pallangi, Palani Hills",
          "Pannapatti Kombai Dam , Palni Hills foothills",
          "Panrimalai",
          "Pantrimalai",
          "Parappalaru Forest, Palani Hills",
          "Peria aruvi valley, Alagarkoil hills, Natham tk, Dindigul dt",
          "Peria Aruvi Valley, Transect 2, Alagarkovil range, Natham Taluk,",
          "Periyur, on Oddanchatram-Pachalur- Adalur Road",
          "Periyur, Palani Hills",
          "Perumal Malai, Palani Hills",
          "Perumparai",
          "Perumparai Environmental Centre",
          "Pethuparai Valley",
          "Poombarai Town",
          "Poombarai View Point",
          "Pothi Alagar Sacred Grove",
          "Rajalakshmi nagar",
          "Ramayyan Patti Kulam",
          "Sadaiyankulam, Oddanchatram",
          "Sadayandi Temple, Maruthanathi Dam  , Palni Hills foothills",
          "Savarikaddu",
          "School ground,Vellagavi",
          "Sholai School",
          "Sholai School, Kodaikanal",
          "Silambaru II, above Pazhamuthircholai",
          "Silambar Valley Transect 1",
          "Silver Cascade Waterfall",
          "Sirumalai--Mavoor Dam",
          "Sirumalai Reserve Forest- General area",
          "Sivagiripatti--River confluence (Palani)",
          "Sothuparai Dam, Periakulam",
          "tannery kulam",
          "Tapovan (Restricted Access)",
          "Thadiyankudisai, Palani Hills",
          "Thalaiyuthu Falls",
          "Thalapathi Veeran Sundaralingam lake",
          "Thandikudi",
          "Thandikudi Forest",
          "Thathala Appuchi Koil Sacred Grove, Sakkilian Kodai",
          "Thattankulam Tank",
          "The Gandhigram Rural Institute, Chinnalapatti",
          "Thekkanthottam on Palani Ghat Road ( Kodaikanal to Palani Road ), Palani Hills",
          "Vaaranam Farm",
          "Vadamalai",
          "Vaiyapuri Tank Palani city",
          "Varathamanathi Dam, Palani Hills",
          "Varathapattinam, Dargah",
          "Vattakanal",
          "Vayalur",
          "Vazhakaatu Odai",
          "Veeramudayan Koil Sacred Grove",
          "Veerapannai Medu Continous with Alternate Route to Bison Valley",
          "Vellagavi - Kodaikanal Trail",
          "Vembarali Forest Bungalow",
          "vengadasthirikottai",
          "Vilpati Forest Area",
          "Vilpatti-Palani Rd.",
          "Wetland to the east of rope car station,Palani",
        ],
      },
      {
        district: "Tiruvannamalai",
        localities: [
          "Adaiyur Lake",
          "Agricultural College, Vazhavachanur",
          "Amirthi Forest",
          "Amirthi Forest-- Location 2",
          "Annamalai RF",
          "Ariyakunjur (Near Sathanur dam)",
          "Arunachaleswara Big Temple, Thiruvannamalai",
          "Arunagiri Park",
          "Athipakkam R.F. (Ã Â®â€¦Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â)",
          "Ã Â®ÂªÃ Â®Â¿Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Pinjur Lake)",
          "Beeman Falls",
          "Desur Lake",
          "Kannamadai Forest",
          "Karumarpati lake",
          "kendriya vidyalaya",
          "Kendriya Vidyalaya School-- Tiruvannamalai",
          "Kilnakkarai Lake (Behind Shanmuga College), Thiruvannamalai",
          "Kilnathur Lake",
          "Kilsathamangalam Lake",
          "Kilsirupakkam Lake",
          "Komutteri Lake Park",
          "Kootathur Pond",
          "Kuppanatham Dam",
          "Mamandur Lake",
          "Mangalam Village,Tiruvannamalai",
          "Mangalam Village, Tiruvannamalai",
          "Marudam Farm School",
          "Melpattu Forest Road",
          "Melsirupakkam Lake",
          "Moranam Lake",
          "Mounayogi Vidobanandha Ashram, Kadalaadi",
          "Mountain path Arunachala",
          "Murugan Kovil Hill, Kiliyapattu",
          "Nedungavadi",
          "Nochimalai Lake",
          "Palayanur Hill",
          "Parvathamalai Reserve Forest",
          "Perumuttam Forest Road",
          "Ponnur Lake",
          "Samudram Lake",
          "Sathanur Dam",
          "Sonagiri Sacred Grove",
          "Sri Ramanasramam, Thiruvannamalai",
          "Terminalia Arjuna (Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â°Ã Â¯Â Ã Â®Â®Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿/Ã Â®Â®Ã Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯Â)",
          "Thamarai Kulam (Lotus Pond)",
          "Thiruvannamalai RF--Skandashram Trail",
          "Yama Lingam Temple",
        ],
      },
      {
        district: "Salem",
        localities: [
          "Nottakaran Kuttai, Thangayur Ã Â®Â¨Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â°Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Samuthiram Tank behind Periyar University Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿Â®Â¿",
          "Panchayat Union Middle School, Krishnampudur",
          "Karumandurai-Vellimalai Road",
          "Desavilakku Kaspa Tank Ã Â®Â¤Ã Â¯â€ Ã Â®Å¡Ã Â®ÂµÃ Â®Â¿Ã Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â®Â¸Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Dasagasamudram Tank Ã Â®Â¤Ã Â®Â¾Ã Â®Å¡Ã Â®â€¢Ã Â®Â¾Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Palamalai Reserve Forest Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â",
          "Pungan Eri/Rangasamudram Lake, Kurumbapatti, Edappadi Ã Â®ÂªÃ Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿/Ã Â®Â°Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Korkadu Lake (Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vellalagundam Circle",
          "Thukkiyampalayam",
          "18th HB--Yercaud",
          "Mallikuttai Tank Ã Â®Â®Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Chetti Eri, Vedapatti Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®ÂµÃ Â¯â€¡Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Pappanaickenpatti Kariya Kovil Dam Ã Â®ÂªÃ Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†",
          "Karikalan Kuttai, Ulipuram Ã Â®â€¢Ã Â®Â°Ã Â®Â¿Ã Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€°Ã Â®Â²Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â",
          "Maangkuttapatti Periyeri Lake Ã Â®Â®Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Avaniperur (West) Chinna Eri Ã Â®â€ Ã Â®ÂµÃ Â®Â£Ã Â®Â¿Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â¯â€šÃ Â®Â°Ã Â¯Â (Ã Â®Â®Ã Â¯â€¡Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â¯Â) Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "21-23 Ã Â¤Â¶Ã Â¤Â¾Ã Â¤Â°Ã Â¤Â¦Ã Â¤Â¾ Ã Â¤Â¨Ã Â¤â€”Ã Â¤Â°, Hyderabad, Telangana, IN (17.36, 78.531)",
          "Panchayat Union Middle School, Thalavaipatty",
          "Kiliyur Road to Kiliyur Falls, Yercaud",
          "Papparapatti Tank Ã Â®ÂªÃ Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Annamalaiar Temple, Thalaicholai",
          "Semmanoor Lake Ã Â®Å¡Ã Â¯â€ Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vaniyambadi (Salem) Small Tank Ã Â®ÂµÃ Â®Â¾Ã Â®Â£Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Abhinavam Lake Ã Â®â€¦Ã Â®ÂªÃ Â®Â¿Ã Â®Â¨Ã Â®ÂµÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kamalapuram Periyeri Tank Ã Â®â€¢Ã Â®Â¾Ã Â®Â®Ã Â®Â²Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Selliyampalayam Lake Ã Â®Å¡Ã Â¯â€ Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vellalapuram Big Tank--Paappampadi New Tank Area Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Â³Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿--Ã Â®ÂªÃ Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Mettupatti Tank Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Padmavani Women's College, Salem",
          "Stanley Reservoir--Koonandiyur",
          "Pallikondan Parai",
          "Amarakundhi Lake",
          "Chetty Lake, Seshanchavadi Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Å¡Ã Â®Â©Ã Â¯ÂÃ Â®Å¡Ã Â®Â¾Ã Â®ÂµÃ Â®Å¸Ã Â®Â¿",
          "Kaalaran Kuttai near Nagiyampatti/Ulipuram Ã Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â®Â°Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¨Ã Â®Â¾Ã Â®â€¢Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿/Ã Â®â€°Ã Â®Â²Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â",
          "Ayyanar Kovil Lake Ã Â®â€¦Ã Â®Â¯Ã Â¯ÂÃ Â®Â¯Ã Â®Â©Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "19th HB--Yercaud",
          "Between 40 feet to 60 feet Bridge",
          "Ramakavundan Valavu Kuttai, Kannantheri Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯Â Ã Â®ÂµÃ Â®Â³Ã Â®ÂµÃ Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Eenjan Lake, Dadapuram, Edappadi Ã Â®Ë†Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â¤Ã Â®Â¾Ã Â®Â¤Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Sivaranthagam Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®ÂµÃ Â®Â°Ã Â®Â¾Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Iluppanatham Tank Ã Â®â€¡Ã Â®Â²Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pallapatti Tank Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Senkadu to Keeraikadu (Enroute)",
          "Puliyankurichi Lake Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Â¯Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thailaakavundanur Lake Ã Â®Â¤Ã Â¯Ë†Ã Â®Â²Ã Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Panangadu (Pudhu Eri) Lake near Chettimankurichi Ã Â®ÂªÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿), Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â®Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®â€¦Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®Â²Ã Â¯Â",
          "Kollapatty Lake/Pond Area, Kolathur",
          "Sokkanur Tank Ã Â®Å¡Ã Â¯Å Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Lathuvadi Tank Ã Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Paavayi Kuttai or Paarai Kuttai, Olakkachinnanur Ã Â®ÂªÃ Â®Â¾Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† (Ã Â®â€¦) Ã Â®ÂªÃ Â®Â¾Ã Â®Â±Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€™Ã Â®Â²Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Puliyampatti Karuppusamy Temple & Surrounds",
          "Somampatti Tank Ã Â®Å¡Ã Â¯â€¹Ã Â®Â®Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Veeraganur Lake Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thedavoor (Thedaur) Tank Ã Â®Â¤Ã Â¯â€ Ã Â®Å¸Ã Â®Â¾Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Stanley Reservoir--Moolakkadu Area",
          "Nattarsai Lake",
          "Vellalapuram Big Tank Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Â³Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Panjanga Kuttai, Olakkachinnanur Ã Â®ÂªÃ Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€™Ã Â®Â²Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Sankagiri Fort (Ã Â®Å¡Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®Â®Ã Â®Â²Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Sittanur Area Ã Â®Å¡Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°ÃÂ²Â°Ã Â²Â£Ã Â³Ââ€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kullampatti Tank Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vaazhakkombai Lake Ã Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Aatukkaran Kuttai, Thangayur Ã Â®â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â°Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Kamalapuram Tank Ã Â®â€¢Ã Â®Â¾Ã Â®Â®Ã Â®Â²Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "PMP Nagar Tank or P. Nattamangalam Tank",
          "Vaikundam-Hotel Saravana Bhavan Main Road",
          "Kundrivalavu Tank, Kunjandiyur Ã Â®â€¢Ã Â¯ÂÃ Â®Â©Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®ÂµÃ Â®Â³Ã Â®ÂµÃ Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â¯ÂÃ Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â¾Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Kolathukombai RF Checkdam Ã Â®â€¢Ã Â¯â€¹Ã Â®Â²Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯Ë† (Ã Â®ÂµÃ Â®Â©Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â±Ã Â¯Ë†) Ã Â®Â¤Ã Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â£Ã Â¯Ë†",
          "Ammapalayam Lake, Iruppali Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¡Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â®Â¿",
          "Omalur Tank Ã Â®â€œÃ Â®Â®Ã Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Blackbuck Resort  Ã Â²â€¢Ã Â³Æ’Ã Â²Â·Ã Â³ÂÃ Â²Â£Ã Â²Â®Ã Â³Æ’Ã Â²â€” Ã Â²Â°Ã Â³â€ Ã Â²Â¸Ã Â",
          "Panaiyadikuppam Lake (Ã Â®ÂªÃ Â®Â©Ã Â¯Ë†Ã Â®Â¯Ã Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Nathakadu Bharathi Nagar Kuttai Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂªÃ Â®Â¾Ã Â®Â°Ã Â®Â¤Ã Â®Â¿ Ã Â®Â¨Ã Â®â€¢Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Ninankarai to Nallamathi, Belur South",
          "Selatthampatti Tank Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Govt. Arts College, Salem-7 Ã Â®â€¦Ã Â®Â°Ã Â®Å¡Ã Â¯Â Ã Â®â€¢Ã Â®Â²Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â-7",
          "Edappadi Periya Eri Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pond near Mettur East Canal, Poolampatti Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â¿Ã Â®Â´Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®â€¦Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®Â²Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®ÂªÃ Â¯â€šÃ Â®Â²Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Bomma Samuthiram Tank, Dadapuram, Edappadi Ã Â®ÂªÃ Â¯Å Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â¤Ã Â®Â¾Ã Â®Â¤Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Ammampalayam Tank Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kolanthampatty Lake, Latchumayur Ã Â®â€¢Ã Â¯Å Ã Â®Â³Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â²Ã Â®Å¸Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Malaiyalapatti Pond Ã Â®Â®Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â³Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â",
          "Panampet Lake (Ã Â®ÂªÃ Â®Â¾Ã Â®Â©Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã ",
          "Thevur Eri Valavu Tank Ã Â®Â¤Ã Â¯â€¡Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Ã Â®ÂµÃ Â®Â³Ã Â®ÂµÃ Â¯Â",
          "Kallanatham Tank Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¾Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Samba Nagar Old Lake, Pethanaickenpalayam Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾ Ã Â®Â¨Ã Â®â€¢Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â´Ã Â¯Ë†Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®ÂªÃ Â¯â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "Sampalli & Moolakkadu Area (enroute)",
          "Ayyan Kuttai, Iruppali, Edappadi Ã Â®â€¦Ã Â®Â¯Ã Â¯ÂÃ Â®Â¯Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€¡Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â®Â¿, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Samuthiram Tank behind Periyar University Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Chinna Thambi Valavu",
          "Keeraipappampadi Tank Ã Â®â€¢Ã Â¯â‚¬Ã Â®Â°Ã Â¯Ë†Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Aandikaattu Kuttai, Olakkachinnanur Ã Â®â€ Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€™Ã Â®Â²Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Stanley Reservoir--Sethukkuzhi",
          "Otteri, Kuppandiyur Ã Â®â€™Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Ponnankaadu Tank",
          "Manivilundhan Old Tank (Manivizhundhan Lake) Ã Â®Â®Ã Â®Â£Ã Â®Â¿Ã Â®ÂµÃ Â®Â¿Ã Â®Â´Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®ÂªÃ Â®Â´Ã Â¯Ë†Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ismail Khan Tank Ã Â®â€¡Ã Â®Â¸Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Oothumedu Lake, Ramanayakkanpalayam Ã Â®Å Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "M. Kalipatti Kuttai Ã Â®Å½Ã Â®Â®Ã Â¯Â. Ã Â®â€¢Ã Â®Â¾Ã Â®Â³Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Nallathangal Kuttai, Ulipuram Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¤Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€°Ã Â®Â²Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â",
          "Naachampatti Tank Ã Â®Â¨Ã Â®Â¾Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â",
          "Sentharapatti Tank Ã Â®Å¡Ã Â¯â€ Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Five Roads Area",
          "Thethigiripatti Lake Ã Â®Â¤Ã Â¯â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kurumbapatti Zoological Park Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€°Ã Â®Â¯Ã Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾",
          "Vashishta River near Periyeri, Aragalur",
          "Poolampatti Ã Â®ÂªÃ Â¯â€šÃ Â®Â²Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Korakuttapatti Eri/Kuttai, Kachupalli Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿/Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿",
          "Vannan Kuttai, Thangayur Ã Â®ÂµÃ Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Kalleripatty Lake Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Panchayat Union Middle School, Sengodanur",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿",
          "Sedapatti Tank Ã Â®Å¡Ã Â¯â€¡Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Koneri Valavu Bridge",
          "Lakkampatti Bridge, on the way to Needhipuram (or Thengal Pallam Bus Stop)",
          "Kuppampatty Kuttai, Surappalli Ã Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿",
          "Stanley Reservoir--Thelunganur Area",
          "Panchayat Union Middle School, A.P. Vattam",
          "Salem Old Bus Stand Area",
          "Stanley Reservoir--Thanamoorthikadu Main Area",
          "Tharamangalam-West Car Street",
          "Rangan Kuttai, Thangayur",
          "Kattaampatti Lake, Surapalli Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿",
          "Stanley Reservoir--between Chettipatty & Kottaiyur Parisalthurai",
          "Arasan Kuttai Lake, Konasamudram Ã Â®â€¦Ã Â®Â°Ã Â®Å¡Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â¯â€¹Ã Â®Â£Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â",
          "Veeranam Tank Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®Â¾Ã Â®Â£Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Government College of Engineering, Salem--Staff Quarters",
          "Shevaroy Temple & View Point, Yercaud",
          "Thoppalampatti Kattuvadavu",
          "Ervadi Tank Ã Â®ÂÃ Â®Â°Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Etti Kuttai, Vaikundam Ã Â®Å½Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â",
          "Godumalai Foothills (around Perumal Temple)",
          "M. Kalipatti Chinna Eri Ã Â®Å½Ã Â®Â®Ã Â¯Â. Ã Â®â€¢Ã Â®Â¾Ã Â®Â³Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Eragundappatti Lake Ã Â®Å½Ã Â®Â±Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Naduvalur Periyeri Lake Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "AVS Engineering College",
          "Panampet Lake (Ã Â®ÂªÃ Â®Â¾Ã Â®Â©Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)Â¿",
          "Pungamaduvu Area Ã Â®ÂªÃ Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kariya Ramar Temple Area Ã Â®â€¢Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Selavadai Tank Ã Â®Å¡Ã Â¯â€ Ã Â®Â²Ã Â®ÂµÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sankagiri Foothills",
          "Navalur Lake Ã Â®Â¨Ã Â®Â¾Ã Â®ÂµÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kalikavundanur Kuttai, Vellar Ã Â®â€¢Ã Â®Â¾Ã Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Â°Ã Â¯Â",
          "Samba Kuttai Tank, Chinna Vellalapatti Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Âª Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Â³Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Anaimedu, Tharamangalam",
          "Panamarathupatti--Bothamalai II [RESTRICTED ENTRY] Malaiyalapatti to Jambuthumalai Ã Â®ÂªÃ Â®Â©Ã Â®Â®Ã Â®Â°Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿--Ã Â®ÂªÃ Â¯â€¹Ã Â®Â¤Ã Â®Â®Ã Â®Â²Ã Â¯Ë† II, Ã Â®Â®Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â³Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â®Â²Ã Â¯Â Ã Â®Å“Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®ÂµÃ Â®Â°Ã Â¯Ë†",
          "Panangadu Tank near valasaiyur, Ã Â®ÂªÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†,Ã Â®ÂµÃ Â®Â²Ã Â®Å¡Ã Â¯Ë†Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Inam-Bairoji Tank (Pudhupalayam Tank) Ã Â®ÂªÃ Â¯Ë†Ã Â®Â°Ã Â¯â€¹Ã Â®Å“Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pudhukuttai, Manivilundhan Pudhur Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â®Ã Â®Â£Ã Â®Â¿Ã Â®ÂµÃ Â®Â¿Ã Â®Â´Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Sanyasi Varadar Temple Area, Thalavaipatty",
          "Swetha River--adjacent to Iluppathoppu Bridge on the way to Valasakkalpatti",
          "Children's Park (Old), Mohan Nagar",
          "Kumarapalayam Pond, Manivilundhan Ã Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â°Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â®Ã Â®Â£Ã Â®Â¿Ã Â®ÂµÃ Â®Â¿Ã Â®Â´Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â",
          "Gangavalli Forest Quarters",
          "Kaveripuram--enroute Kolathur Area to Kottaiyur/Chettipatty Area",
          "Kullan Kinaru Pond, Chinthamaniyur Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â®Â¿Ã Â®Â£Ã Â®Â±Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Å¡Ã Â®Â¿Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â®Â£Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Aragalur Sitheri Lake Ã Â®â€ Ã Â®Â±Ã Â®â€¢Ã Â®Â³Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Muthunaickenpatti Chinneri Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vaikundam Periyeri Lake Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Muyal Karadu Tank, Kaattukkottai Ã Â®Â®Ã Â¯ÂÃ Â®Â¯Ã Â®Â²Ã Â¯Â Ã Â®â€¢Ã Â®Â°Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Unathur Lake Ã Â®Å Ã Â®Â©Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pudhur-Alagappampalayam Tank Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â- Ã Â®â€¦Ã Â®Â´Ã Â®â€¢Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vengikal Lake Ã Â®ÂµÃ Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "The Central Law College & Surrounds, Salem",
          "Allikuttai Tank (or Thathampatti Tank) Ã Â®â€¦Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®Â¤Ã Â®Â¾Ã Â®Â¤Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kannantheri Tank Ã Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "M. Chettipatty Lake Ã Â®Å½Ã Â®Â®Ã Â¯Â. Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Palar Bridge, Tamil Nadu side (enroute--from Karaikadu Police Check Post till Palar Bridge-5km stretch)",
          "Kapparayan Kuttai or Thoppam Kuttai, Thangayur",
          "Deviyakurichi Lake Ã Â®Â¤Ã Â¯â€¡Ã Â®ÂµÃ Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Naachampatti Tank Ã Â®Â¨Ã Â®Â¾Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mandhakaadu Scrub Patch Ã Â®Â®Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â, Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Periyeri Tank, Aragalur Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿, Ã Â®â€ Ã Â®Â±Ã Â®â€¢Ã Â®Â³Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Govt. Industrial Training Institute (ITI), Salem-7 Ã Â®â€¦Ã Â®Â°Ã Â®Å¡Ã Â¯Â Ã Â®Â¤Ã Â¯Å Ã Â®Â´Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â®Â¯Ã Â®Â±Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®Â¨Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â®Â®Ã Â¯Â, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â-7",
          "Ponnu Samudram Tank Ã Â®ÂªÃ Â¯Å Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â¯Â Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Periyeripatty Tank Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kavarpanai Tank Ã Â®â€¢Ã Â®ÂµÃ Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Veerappan Kuttai, Thangayur Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Settukarankadu Tank Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â°Ã Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Government Hospital, Salem",
          "Atchengadu",
          "Thalavaipatty Lake (Mettupalayam Tank) Ã Â®Â¤Ã Â®Â³Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kasuvarettipatti Lake Ã Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®ÂµÃ Â®Â°Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nagireddipatti Melakarattur Lake Ã Â®Â¨Ã Â®Â¾Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Â®Ã Â¯â€¡Ã Â®Â²Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vaathipatti Lake, Surappalli Ã Â®ÂµÃ Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mannadipet Lake (Ã Â®Â®Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ ÂÂ®Â®Ã Â¯Â Ã Â®Â Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Nagiyampatti New Tank Ã Â®Â¨Ã Â®Â¾Ã Â®â€¢Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kumaragiri Tank, Ammapet Ã Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Club Mahindra Yercaud (Restricted Access)",
          "Amman Lake, Dadapuram, Edappadi Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â¤Ã Â®Â¾Ã Â®Â¤Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Vellakkalpatti Tank (or Gundan Eri) Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®â€¦) Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ettimanickampatti Pond Ã Â®Å½Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â®Ã Â®Â¾Ã Â®Â£Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Semmang Kuttai (or Kuttakarai Tank) Ã Â®Å¡Ã Â¯â€ Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mettur Bus Stand Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "Kadathur Tank Ã Â®â€¢Ã Â®Å¸Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Periya Urani Lake, Jalakandapuram Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®Å Ã Â®Â°Ã Â®Â£Ã Â®Â¿ Ã Â®Å“Ã Â®Â²Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Reddipatti Kuttai, Chittoor Bit 1, Edappadi Ã Â®Â°Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â¿Ã Â®Å¸Ã Â¯Â 1, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Kandhanvalavu & Narasukkadu Area",
          "Vannan Kuttai, Vaikundam Ã Â®ÂµÃ Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â",
          "Kailash Nagar, Tharamangalam",
          "Kalyanagiri Tank Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â¯Ã Â®Â¾Ã Â®Â£Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Danishpet Small Tank Ã Â®Å¸Ã Â¯â€¡Ã Â®Â©Ã Â®Â¿Ã Â®Â·Ã Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pongavalli Tank Ã Â®ÂªÃ Â¯Å Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂµÃ Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thenkumarai Tank Ã Â®Â¤Ã Â¯â€ Ã Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Marakkottai Lake Ã Â®Â®Ã Â®Â°Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ Â®Â°Ã Â®Â¿",
          "Govt. Primary Health Centre, Kolathur Ã Â®â€¦Ã Â®Â°Ã Â®Å¡Ã Â¯Â Ã Â®â€ Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®Âª Ã Â®Å¡Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¤Ã Â®Â¾Ã Â®Â° Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â, Ã Â®â€¢Ã Â¯Å Ã Â®Â³Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Panjukalipatti Lake (Chinthamaniyur Lake) Ã Â®ÂªÃ Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â³Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®Å¡Ã Â®Â¿Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â®Â£Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vaazhakuttapatti Tank Ã Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Panangattu Eri or Thaai Eri (Chinneri) Ã Â®ÂªÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®â€¦) Ã Â®Â¤Ã Â®Â¾Ã Â®Â¯Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿)",
          "Gundukkal Tank Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Attayampatti Tank Ã Â®â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Agriculture Training Center, Salem",
          "Kanjamalai--Sadayan Ootru Malai Ã Â®â€¢Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â®Ã Â®Â²Ã Â¯Ë†--Ã Â®Å¡Ã Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â©Ã Â¯Â Ã Â®Å Ã Â®Â±Ã Â¯ÂÃ Â®Â±Ã Â¯Â Ã Â®Â®Ã Â®Â²Ã Â¯Ë†",
          "Vanavasi Lake Ã Â®ÂµÃ Â®Â©Ã Â®ÂµÃ Â®Â¾Ã Â®Å¡Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kanakkan Tank Ã Â®â€¢Ã Â®Â£Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Bharathiyar Educational Institutions for Women Ã Â®ÂªÃ Â®Â¾Ã Â®Â°Ã Â®Â¤Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®Â®Ã Â®â€¢Ã Â®Â³Ã Â®Â¿Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¿ Ã Â®Â¨Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â³Ã Â¯Â",
          "Elavampatti Tank Ã Â®â€¡Ã Â®Â²Ã Â®ÂµÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thaekkampatti Vaaikaal Kuttai Lake Ã Â®Â¤Ã Â¯â€¡Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Neermullikuttai Therkukadu Lake",
          "Kottamedu",
          "VIMS Hospital Campus",
          "Swetha River--near Veeraganur Periya Angalamman Area",
          "Anayampatti Lake",
          "Muttal Tank, Manivilundhan Ã Â®Â®Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â®Ã Â®Â£Ã Â®Â¿Ã Â®ÂµÃ Â®Â¿Ã Â®Â´Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â",
          "Mettur Dam Park Area Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â£Ã Â¯Ë† Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾ Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kandarkula Manickam Tank Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â² Ã Â®Â®Ã Â®Â¾Ã Â®Â£Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Salem Sowdeswari College & Surrounds",
          "Elayaramar Temple Tank Ã Â®â€¡Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Bodinayakanpatti Tank Ã Â®ÂªÃ Â¯â€¹Ã Â®Å¸Ã Â®Â¿Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kakapalayam Tank (Kanagagiri Tank) Ã Â®â€¢Ã Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®â€¢Ã Â®Â©Ã Â®â€¢Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Valasakkalpatti Lake or Veera Ramar Dam",
          "Krishnampudur Area",
          "Stanley Reservoir--Kottaiyur Parisalthurai to East Kottaiyur Area",
          "Kumaranallipalayam New Tank Ã Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Shri Ram Chandra Mission, Salem",
          "Attur Lake Ã Â®â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ammal Tank Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â³Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "K Hemmanahalli  Ã Â²â€¢Ã Â³â€  Ã Â²Â¹Ã Â³â€ Ã Â²Â®Ã Â³ÂÃ Â²Â®Ã Â²Â¨Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿Â¿",
          "Othiyathur Tank Ã Â®â€™Ã Â®Â¤Ã Â®Â¿Ã Â®Â¯Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kumarapalayam Check Dam near Belur",
          "Lakshumayur to K.R Thoppur Road",
          "Kanjeri RF Lake, Danishpet",
          "Universal Matriculation School",
          "Siruvachur Lake Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kottachedu, Yercaud Ã Â®â€¢Ã Â¯Å Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â, Ã Â®ÂÃ Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â",
          "Pethanaickenpalayam Lake Ã Â®ÂªÃ Â¯â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Chetti Eri, East Rajapalayam, Salem Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â®Â¿Ã Â®Â´Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Å“Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Panchayat Union Middle School, Mattayampatti",
          "Aandikkuttai, Nagiyampatti Ã Â®â€ Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¨Ã Â®Â¾Ã Â®â€¢Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Chekkanur Small Dam Area, Mettur RS-Poolampatti Road",
          "Periyakuttai (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Stanley Reservoir--Keerakaranoor",
          "Elampillai Tank Ã Â®â€¡Ã Â®Â³Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kottanathan Tank Ã Â®â€¢Ã Â¯Å Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Navakurichi Lake Ã Â®Â¨Ã Â®Â¾Ã Â®ÂµÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pullagoundampatti Tank Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Chinna Pethanaickenpalayam Lake or Chinnamasamudram Lake Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂªÃ Â¯â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â (Ã Â®â€¦) Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â®Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Geetha Nagar Annex & Surrounds",
          "Achampatti Lake, Avaniperur West Ã Â®â€¦Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€ Ã Â®ÂµÃ Â®Â£Ã Â®Â¿Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Â®Ã Â¯â€¡Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â¯Â",
          "Govt. Arts College for Women, Salem-8 Ã Â®â€¦Ã Â®Â°Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â®Â°Ã Â¯Â Ã Â®Â®Ã Â®â€¢Ã Â®Â³Ã Â®Â¿Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â²Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â-8",
          "Vashishta River near Thulukkanur",
          "Kondayampalli Tank Ã Â®â€¢Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nalla Thanneer Kuttai, Vaikundam Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â² Ã Â®Â¤Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â¯â‚¬Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â",
          "Periyar University Campus Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â²Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â´Ã Â®â€¢ Ã Â®ÂµÃ Â®Â³Ã Â®Â¾Ã Â®â€¢Ã Â®Â®Ã Â¯Â",
          "Ezharaimathikkadu Thumbal Kaattu Pallam Tank Ã Â®ÂÃ Â®Â´Ã Â®Â°Ã Â¯Ë†Ã Â®Â®Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â²Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Puthur Lake Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pachakattu Vayal",
          "Mecheri Sheep Research Station",
          "Sengadu Lake or Jangama Samudram Lake Ã Â®Å¡Ã Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â/Ã Â®Å“Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vadakumarai Tank Ã Â®ÂµÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Panampet Lake (Ã Â®ÂªÃ Â®Â¾Ã Â®Â©Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Manathal Tank",
          "Nagasamudram Kuttai, Dadapuram, Edappadi Ã Â®Â¨Ã Â®Â¾Ã Â®â€¢Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®Â¾Ã Â®Â¤Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Vembaneri Big Tank Ã Â®ÂµÃ Â¯â€¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Monnanjcheri Kuttai, Chittoor Bit 1, Edappadi Ã Â®Â®Ã Â¯Å Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â¿Ã Â®Å¸Ã Â¯Â 1, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Tharamangalam Bus Stand Ã Â®Â¤Ã Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "Seelaiyampatti Tank Ã Â®Å¡Ã Â¯â‚¬Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sengodanoor",
          "Godumalai Foothills (via Nadupatti)",
          "Eechampatti Tank Ã Â®Ë†Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sandhapatti Tank Ã Â®Å¡Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Manjakuttai View Point, Yercaud",
          "Sarvoy Big Tank Ã Â®Å¡Ã Â®Â¾Ã Â®Â°Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Â¯Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Earipudur Check Dam Ã Â®ÂÃ Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â³Ã Â®ÂµÃ Â¯Â Ã Â®â€œÃ Â®Å¸Ã Â¯Ë† Ã Â®Â¤Ã Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â£Ã Â¯Ë†",
          "Govt. Mohan Kumaramangalam Medical College, Salem",
          "Manakkadu Area, Salem",
          "Kurumbapatti Zoo to Danishpet (enroute)",
          "Veethan Valavu Area",
          "Chinna Mettur Scrub Area Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Aragalur Govt. Higher Secondary School",
          "Kundal Dongar ( Ã Â¤â€¢Ã Â¥ÂÃ Â¤â€šÃ Â¤Â¡Ã Â¤Â² Ã Â¤Â¡Ã Â¥â€¹Ã Â¤â€šÃ Â¤â€”Ã Â¤Â°)",
          "Bommidi Sub Road",
          "Ramireddipatti Lake near Aarurpatti, Tharamangalam Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®Â¿Ã Â®Â°Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€ Ã Â®Â°Ã Â¯â€šÃ Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿, Ã Â®Â¤Ã Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Vadamaneri Big Tank Ã Â®ÂµÃ Â®Å¸Ã Â®Â®Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Bomma Kuttai Ã Â®ÂªÃ Â¯Å Ã Â®Â®Ã Â¯ÂÃ Â®Â® Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Jamboothumalai Area (enroute--from foothills to P.U.P. School and Surrounds)  Ã Â®Å“Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kuppanur Ghat Road to Soora Muniyappan Temple",
          "11th HB--Yercaud",
          "Ayyampalayam Tank, Kachupalli Ã Â®â€¦Ã Â®Â¯Ã Â¯ÂÃ Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿",
          "Tharalakuttai Lake Ã Â®Â¤Ã Â®Â¾Ã Â®Â°Ã Â®Â¾Ã Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vetraya Perumal Kovil Area Ã Â®ÂµÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Â°Ã Â®Â¾Ã Â®Â¯ Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â³Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Puthirakavundan Street Area, Periya Pudhur Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯Â Ã Â®Â¤Ã Â¯â€ Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿, Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "ACSEN Tex Pvt. Ltd. Campus",
          "Peththaampatti Tank, Mullichettipatti Ã Â®ÂªÃ Â¯â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â®Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Etti Kuttai, Thangayur Ã Â®Å½Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Paravai Kuttai, Mariyamman Pudhur, Valappadi Ã Â®ÂªÃ Â®Â±Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â®Ã Â®Â¾Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â©Ã Â¯Â Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â, Ã Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Aandipatti Lake Ã Â®â€ Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Chittoor Tank Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Stanley Reservoir--16 Gate Ellis Surplus Sluices Bridge, Mettur",
          "Nattar Agraharam Pond Ã Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â®Â¹Ã Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Navalur Tank, Thumbipadi Ã Â®Â¨Ã Â®Â¾Ã Â®ÂµÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Mahendra College of Engineering, Minnampalli, Salem",
          "Govt. Tribal Residential Middle School, Kunnur",
          "Muttal Foothills to Muttal Waterfalls (enroute)",
          "District Collector's Office, Salem Ã Â®Â®Ã Â®Â¾Ã Â®ÂµÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸ Ã Â®â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â¯Ã Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â²Ã Â®â€¢Ã Â®Â®Ã Â¯Â, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Nallur Lake Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Narasingapuram Idaiyaneri Tank Ã Â®Â¨Ã Â®Â°Ã Â®Å¡Ã Â®Â¿Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®â€¡Ã Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Govt. Primary Health Centre, Tholasampatti",
          "Mount Mary Matriculation Higher Secondary School, Salem-8",
          "Sengan Kuttai, Vaikundam Ã Â®Å¡Ã Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â",
          "Vairan Lake, Periyasoragai Ã Â®ÂµÃ Â¯Ë†Ã Â®Â°Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®Å¡Ã Â¯â€¹Ã Â®Â°Ã Â®â€¢Ã Â¯Ë†",
          "Umayalpuram Raja Eri Ã Â®â€°Ã Â®Â®Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â³Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®Â°Ã Â®Â¾Ã Â®Å“ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Narasambudhi, Nanjangud (Ã Â²Â¨Ã Â²Â°Ã Â²Â¸Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿)",
          "Govt. Primary Health Centre, Kannankurichi, Salem",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Chinna Mangalam Lake Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sri Vidya Mandir Matric HSS, Steel Plant",
          "Periyakuttimaduvu  Area Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â®Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kollapatti Tank, Kachupalli Ã Â®â€¢Ã Â¯Å Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿",
          "Karuppur Tank Ã Â®â€¢Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Salem Central Bus Terminus (New Bus Stand)",
          "Pottaneri Tank Ã Â®ÂªÃ Â¯Å Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Stanley Reservoir--Thalavadi Area",
          "Arathi Agraharam Tank Ã Â®â€ Ã Â®Â°Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿ Ã Â®â€¦Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â®Â¹Ã Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sadayan Chetti Tank Ã Â®Å¡Ã Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â©Ã Â¯Â Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Stanley Reservoir--Thanamoorthikadu (Sub) Area",
          "Aragalur Tank Ã Â®â€ Ã Â®Â±Ã Â®â€¢Ã Â®Â³Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nehru Park, Salem Steel Plant Ã Â®Â¨Ã Â¯â€¡Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®â€°Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â¯Ë†",
          "Thondamanatham Lake (Ã Â®Â¤Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â®Â¾Ã Â®Â¨Ã Â®Â",
          "Thekkalpatty Lake Ã Â®Â¤Ã Â¯â€¡Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Naachinaampatti Tank Ã Â®Â¨Ã Â®Â¾Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Periya Eri, Olakkachinnanur Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€™Ã Â®Â²Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Amarakundhi Pudhu Eri Ã Â®â€¦Ã Â®Â®Ã Â®Â°Ã Â®â€¢Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿ Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "1st HB--Yercaud",
          "Pappanaickenpatti",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿ÂÃ Â®Â°Ã Â®Â¿",
          "Aachampalli Tank, Vettukkadu, Thangayur Ã Â®â€ Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®ÂµÃ Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Karigala Kere (Doddakere) Ã Â²â€¢Ã Â²Â°Ã Â²Â¿Ã Â²â€”Ã Â²Â¾Ã Â²Â²",
          "Kacharaayan Kuttai, Edappadi Ã Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â°Ã Â®Â¾Ã Â®Â¯Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Paithur South",
          "Kandan Valavu",
          "Kurumbolil wayal Mynagappally (Ã Â´â€¢Ã ÂµÂÃ Â´Â°Ã ÂµÂÃ Â´Â®Ã ÂµÂÃ Â´ÂªÃ Âµâ€¹Ã Â´Â²Ã Â´Â¿Ã ÂµÂ½ Ã Â´ÂµÃ Â´Â¯",
          "Pacchakattu  Vayal",
          "Erumaipatti Tank, Edappadi Ã Â®Å½Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯Ë†Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Periyeri Tank, Sellapillai Kuttai Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€ Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯Ë† Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Stanley Reservoir--Mettur Dam View Point",
          "Palani Kavundan Kuttai, Thangayur Ã Â®ÂªÃ Â®Â´Ã Â®Â©Ã Â®Â¿ Ã Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Thevur Tank Ã Â®Â¤Ã Â¯â€¡Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Maangkuttapatti Small Tank Ã Â®Â®Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Belur Kissan Big Tank Ã Â®ÂªÃ Â¯â€¡Ã Â®Â³Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â¿Ã Â®Å¡Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)",
          "Stanley Reservoir--Pannavadi, Mettur",
          "Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â®Â¿ (Periyakuyili)Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Varadhappa Kuttai or Kalikavundan Kuttai, Thangayur Ã Â®ÂµÃ Â®Â°Ã Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®Âª Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† (Ã Â®â€¦) Ã Â®â€¢Ã Â®Â¾Ã Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Masinaickenpatti or Varagampadi Tank Ã Â®Â®Ã Â®Â¾Ã Â®Å¡Ã Â®Â¿Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ (Ã Â®â€¦) Ã Â®ÂµÃ Â®Â°Ã Â®â€¢Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "60 Feet Bridge",
          "AVS Arts and Science College, Kanjanayachanpatti",
          "Gundiyampatti Tank Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kannankurichi Mookaneri Tank Ã Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®Â®Ã Â¯â€šÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Pannaikkadu Reserve Forest",
          "Kannamoochi to Ramanpatti Area",
          "Kuppanur Forest Check Post Area",
          "Varatteri or Periyeri, A. Thalaiyur",
          "Sengkuttai Lake, Konasamudram Ã Â®Å¡Ã Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€¢Ã Â¯â€¹Ã Â®Â£Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â",
          "Thalaivasal Lake Ã Â®Â¤Ã Â®Â²Ã Â¯Ë†Ã Â®ÂµÃ Â®Â¾Ã Â®Å¡Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Peththaampatti Tank Ã Â®ÂªÃ Â¯â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "London Ortho Speciality Hospital",
          "Pavalathanoor Lake, Tharamangalam Ã Â®ÂªÃ Â®ÂµÃ Â®Â³Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â¤Ã Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Ramayana Theme Park -- Ã Â¤â€¦Ã Â¤Â®Ã Â¤Â°Ã Â¥â€šÃ Â¤Â¤Ã Â¤Â¹Ã Â¤Â¿Ã Â¤Â¯Ã Â¤Â¾ (Gauva Plantations)¿",
          "Panangadu Tank near Seelanaickenpatti Bypass Area Ã Â®ÂªÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â‚¬Ã Â®Â²Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Nochimedu Lake (Ã Â®Â¨Ã Â¯Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â¯â€ Ã Â®Å¸Ã Â¯Â Ã Â®Å½Ã Â®Â°Ã Â®Â¿)¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "District Institute of Education and Training (DIET), Salem Ã Â®Â®Ã Â®Â¾Ã Â®ÂµÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸ Ã Â®â€ Ã Â®Å¡Ã Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¿ & Ã Â®ÂªÃ Â®Â¯Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®Â¨Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â®Â®Ã Â¯Â, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Muthunaickenpatti Periyeri Lake Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sarkar Nattamangalam Upper Lake Side, Ayothiyapattinam",
          "Katheri Tank Ã Â®â€¢Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Kattinaikanpatti Lake Ã Â®â€¢Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "6th HB--Yercaud",
          "Yercaud, Salem Ã Â®ÂÃ Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Pond opp. to Vaitheeswaran Temple near Indira Nagar Rd, Puliyankurichi",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP",
          "Gangadharan Pond, Sellapillai Kuttai Panchayat Ã Â®â€¢Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¤Ã Â®Â°Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Å¡Ã Â¯â€ Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯Ë† Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®Å Ã Â®Â°Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿",
          "Edappadi Bus Stand Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "Meera Sahib Tank Ã Â®Â®Ã Â¯â‚¬Ã Â®Â°Ã Â®Â¾ Ã Â®Å¡Ã Â®Â¾Ã Â®Â¹Ã Â®Â¿Ã Â®ÂªÃ Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ariyapalayam Tank Ã Â®â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã ÂÂ³ÂÃ Â²Â§Ã Â²Â¿ Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ã Â¤Â­Ã Â¥â‚¬Ã Â¤Â²Ã Â¤ÂµÃ Â¤Â¾Ã Â¤Â¡Ã Â¤Â¼Ã Â¤Â¾, Ã Â¤Â°Ã Â¤Â¾Ã Â¤Å“Ã Â¤Â¸Ã Â¥ÂÃ Â¤Â¥Ã Â¤Â¾Ã Â¤Â¨, IN (25.521, 74.656)†",
          "Govindampalayam Lake Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Punalvasal Lake Ã Â®ÂªÃ Â¯ÂÃ Â®Â©Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Å¡Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Valappadi Pappan Tank Ã Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Athunoorpatti Kisan Small Tank Ã Â®â€¦Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â®Â¿Ã Â®Å¡Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Veerapandi Tank Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®ÂªÃ Â®Â¾Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sri Seshaas International Public School & Surrounds",
          "Yercaud Lake, Salem Ã Â®ÂÃ Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯Â",
          "Queen Mary's College (Ã Â®Â°Ã Â®Â¾Ã Â®Â£Ã Â®Â¿ Ã Â®Â®Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â®Â¿)",
          "Iruppali Lake (Mettupatti Lake) Ã Â®â€¡Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Naduvaneri Tank Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Tholasampatti Tank Ã Â®Â¤Ã Â¯Å Ã Â®Â³Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vellarivelli Tank Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â°Ã Â®Â¿Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Periyapatti Tank Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Jairam Arts & Science College, Chinna Thirupathi, Salem",
          "Navalur Small Tank Ã Â®Â¨Ã Â®Â¾Ã Â®ÂµÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Naduvalur Sitheri Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â",
          "Rakkipatti Pond Ã Â®Â°Ã Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Kottapuliyur Lake, Chettimankurichi Ã Â®â€¢Ã Â¯Å Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â®Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿",
          "Egapuram Small Lake Ã Â®ÂÃ Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mallamoopampatti Tank Ã Â®Â®Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â®Ã Â¯â€šÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ezhuthukkal Seradi Check Dam Ã Â®Å½Ã Â®Â´Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®Å¡Ã Â¯â€¡Ã Â®Â°Ã Â®Å¸Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†",
          "Kottangal Lake Ã Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Suramangalam-Reddipatti Circle Area",
          "Surakkapatti Tank Ã Â®Å¡Ã Â¯ÂÃ Â®Â°Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kanjeri RF, Danishpet",
          "Manmalai Foothills to Mini Waterfalls (enroute)",
          "Rayappan Lake, M. Kalipatti Ã Â®Â°Ã Â®Â¾Ã Â®Â¯Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å½Ã Â®Â®Ã Â¯Â. Ã Â®â€¢Ã Â®Â¾Ã Â®Â³Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Kootathupatti Tank Ã Â®â€¢Ã Â¯â€šÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Hasthampatti Roundana Circle Area",
          "Soora Muniyappan Temple",
          "Rangan Kuttai, Thangayur Ã Â®Â°Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Durgai Amman Temple Tank Ã Â®Â¤Ã Â¯ÂÃ Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯Ë† Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Stanley Reservoir--Chinna Mettur Area",
          "Salem Railway Junction",
          "Tharamangalam Lake Ã Â®Â¤Ã Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pudhukuttai Tank, Senaipalayam, Selliampalayam Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â©Ã Â¯Ë†Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â, Ã Â®Å¡Ã Â¯â€ Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â",
          "Needhipuram Lake Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kaaththaan Kuttai or Veppang Kuttai, Thangayur Ã Â®â€¢Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† (Ã Â®â€¦) Ã Â®ÂµÃ Â¯â€¡Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Nathuram Ji Ka Kheda Begun, Ã Â¤Â°Ã Â¤Â¾Ã Â¤Å“Ã Â¤Â¸Ã Â¥ÂÃ Â¤Â¥Ã Â¤Â¾Ã Â¤Â¨, IN (25.103, 75.036)",
          "PerumalMalai, Narasothipatti, Salem",
          "Thennampillaiyur Tank Ã Â®Â¤Ã Â¯â€ Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Semmalai Tank, Tharkadu  Ã Â®Å¡Ã Â¯â€ Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â¤Ã Â®Â¾Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â",
          "Chinna Pudhur Mariyamman Temple Area",
          "9th HB--Yercaud",
          "Shevaroy Hills (camps at varying altitudes)",
          "Kannankurichi Pudhu Eri (Lake) Ã Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Veppampoondi Tank Ã Â®ÂµÃ Â¯â€¡Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Valaiyachettipatti Kuttai Ã Â®ÂµÃ Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Puthiragoundapalayam Lake Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Rose Garden, Yercaud",
          "Vettukkadu Small Tank near Danishpet Ã Â®ÂµÃ Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¸Ã Â¯â€¡Ã Â®Â©Ã Â®Â¿Ã Â®Â·Ã Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®â€¦Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®Â²Ã Â¯Â",
          "Puzhuthikuttai Dam or Anaimaduvu Reservoir",
          "Moolavittam Kuttai, Olakkachinnanur Ã Â®Â®Ã Â¯â€šÃ Â®Â²Ã Â¯Ë†Ã Â®ÂµÃ Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€™Ã Â®Â²Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Ayothiyapattinam Tank Ã Â®â€¦Ã Â®Â¯Ã Â¯â€¹Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â©Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Micro Forest, Salem",
          "Sattur Tank Ã Â®Å¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nangavalli Lake Ã Â®Â¨Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂµÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Periya Krishnapuram Lake Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â·Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thennangudipalayam Lake Ã Â®Â¤Ã Â¯â€ Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Yercaud, Salem Ã Â®ÂÃ Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯ÂÂ Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Goodamalai Lake Ã Â®â€¢Ã Â¯â€šÃ Â®Å¸Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sellappa Kavundan Kuttai",
          "Subbayyana kere (Shanubhogana Kere) Ã Â²Â¸Ã Â³ÂÃ Â²Â¬Ã Â³ÂÃ Â²Â¬Ã Â²Â¯Ã Â³ÂÃ Â²Â¯Ã Â²Â¨ Ã Â²â€¢Ã Â",
          "Thalavaipatty Lake (MetªÃ Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿Â¿",
          "Korkadu Lake (Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Benapatti Outer farmÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMPÂ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Konda Samuthiram Tank Ã Â®â€¢Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Aariya Kuttai, Koranampatti Ã Â®â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â®Â£Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Sathapadi Tank Ã Â®Å¡Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Periya Kadampatti Lake Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "A.E.T. College of Arts & Science",
          "Sengunthar Public School, Tharamangalam",
          "Agrahara Nattamangalam (A.N. Mangalam) Lake Ã Â®â€¦Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â®Â¹Ã Â®Â¾Ã Â®Â° Ã Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã Â²Â¿¿",
          "Godumalai Foothills (via Sivasakthi Nagar)",
          "Pulichapallam Lake (Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â",
          "Panamarathupatti Lake Ã Â®ÂªÃ Â®Â©Ã Â®Â®Ã Â®Â°Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Karupana Eri (Lake) Ã Â®â€¢Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thiyaganur Tank Ã Â®Â¤Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®â€¢Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Gorimedu to Yercaud Foothills (enroute)",
          "Thottil Lake, Sukkampatti Ã Â®Â¤Ã Â¯Å Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Muttal Lake Ã Â®Â®Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Annai Indra Nagar & Surrounds",
          "Hillock near A.N. Mangalam Lake",
          "Mecheri Lake Ã Â®Â®Ã Â¯â€¡Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Cauvery River--Chettipatty Area Ã Â®â€¢Ã Â®Â¾Ã Â®ÂµÃ Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®â€ Ã Â®Â±Ã Â¯Â--Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kaveri Bridge & Surrounds Ã Â®â€¢Ã Â®Â¾Ã Â®ÂµÃ Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kolathur to Thanamoorthikadu (enroute)",
          "Erumapalayam Tank Ã Â®Å½Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Oddapatti Tank Ã Â®â€™Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Raayar Kuttai, Thangayur Ã Â®Â°Ã Â®Â¾Ã Â®Â¯Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "40 Feet Bridge",
          "K. Vadugapatti Tank Ã Â®ÂµÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®â€¦) Ã Â®â€¦Ã Â®Â¯Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Periya Puthur Tank Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ayyaneri Lake, Avadathur Ã Â®â€¦Ã Â®Â¯Ã Â¯ÂÃ Â®Â¯Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿, Ã Â®â€ Ã Â®ÂµÃ Â®Å¸Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Vattakadu Lake Small Dam Ã Â®ÂµÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†",
          "Veppadi Nallamathi Way & Surrounds",
          "Andavar Nagar & Surrounds, Manivilundhan South, Sarvoy Main Road",
          "Egapuram Tank Ã Â®ÂÃ Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kalleri Chokkanathapuram Tank Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®Å¡Ã Â¯Å Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¨Ã Â®Â¾Ã Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Naduvalur Sitheri Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Thirumanur Area",
          "Sri Saalaipaarai Muniappan Temple",
          "Vaithiyakavundan Pudhur Tank Ã Â®ÂµÃ Â¯Ë†Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯Â Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Suthukeni Lake (Ã Â®Å¡Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯",
          "Kurichi Tank, Belur Ã Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®ÂªÃ Â¯â€¡Ã Â®Â³Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Sengalathu Padi, Yercaud",
          "Kamaneri Lake Ã Â®â€¢Ã Â®Â¾Ã Â®Â®Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Stanley Reservoir--Thippampatty",
          "Kachupalli Tank, Edappadi Ã Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿",
          "Godumalai foothills --Mannayakanpatti",
          "Southkadu Pond, Kachupalli Ã Â®Â¤Ã Â¯â€ Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®â€¢Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿",
          "Selathampatti Tank--Tamarind Tree Area Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Â¯ Ã Â®Â®Ã Â®Â°Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®â€¡Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®ÂªÃ Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤ Ã Â®â€™Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€š",
          "Sandhumalai Area Ã Â®Å¡Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â®Â²Ã Â¯Ë†Ã Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "DSK Thirumana Mahal",
          "Bhutnal Kere  Ã Â²Â­Ã Â³â€šÃ Â²Â¤Ã Â²Â¨Ã Â²Â¾Ã Â²Â³ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Korapallam Small Dam Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†",
          "Omalur Circle & Bus Stand Area",
          "Poolavari Tank Ã Â®ÂªÃ Â¯â€šÃ Â®Â²Ã Â®Â¾Ã Â®ÂµÃ Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kurinji Nagar Park and Surrounds",
          "Pannappatti Tank Ã Â®ÂªÃ Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pillukurichi Tank, Chittoor Bit 1, Poolampatti Ã Â®ÂªÃ Â®Â¿Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â¿Ã Â®Å¸Ã Â¯Â 1, Ã Â®ÂªÃ Â¯â€šÃ Â®Â²Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Kondayampalayam Lake Ã Â®â€¢Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pagoda Point, Yercaud",
          "Kombu Thukki",
          "Balaji Nagar Area & Surrounds, Amarakundhi",
          "Panchayat Union Middle School, Latchumayur",
          "Motturkadu & Elumalaiyan Kadu",
          "Karasur Lake (Ã Â®â€¢Ã Â®Â°Ã Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vellalagundam Kannimar Temple Area Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â¿Ã Â®Â®Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)®Â¿",
          "Sittanur Area Ã Â®Å¡Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Kottaimedu",
          "Yercaud Road (after Muniyappan Temple)",
          "S.E.M Road Lake",
          "Amman Lake near Aathi Kattur Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®â€ Ã Â®Â¤Ã Â®Â¿ Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®Â²Ã Â¯Â",
          "Vellaiyur Lake Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kuppanur Toll Gate to Forest Check Post (enroute)",
          "Pachanampatty Moongil Lake Ã Â®ÂªÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â©Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Â®Ã Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vattakadu Lake Ã Â®ÂµÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thirumanur To Mamarathu Odai",
          "Kapparayan Kuttai, Thangayur Ã Â®â€¢Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â°Ã Â®Â¾Ã Â®Â¯Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†, Ã Â®Â¤Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Koranampatti Lake (Nadupatti Lake) Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â®Â£Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿ (Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kolathur Area (after Forest Check Post till Kolathur Market via Kolathur Bus Stand)",
          "Chinna Punalvasal Lake Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂªÃ Â¯ÂÃ Â®Â©Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Å¡Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Stanley Reservoir--Ukkamparuthikadu Area",
          "Thuttampatti Tank, Desavilakku Ã Â®Â¤Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿, Ã Â®Â¤Ã Â¯â€ Ã Â®Å¡Ã Â®ÂµÃ Â®Â¿Ã Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Â",
          "Pachagoundanoor Tank Ã Â®ÂªÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®â€¢Ã Â®ÂµÃ Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Singarathoppu to Siddeshwaran Temple",
          "Thaamarai Kuttai (towards Tharamangalam) Ã Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Ë†Ã Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Sivasankarapuram Tank Ã Â®Å¡Ã Â®Â¿Ã Â®ÂµÃ Â®Å¡Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Amarakundhi Kaathaan Eri Ã Â®â€¦Ã Â®Â®Ã Â®Â°Ã Â®â€¢Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿ Ã Â®â€¢Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vadapatty Area",
          "K. N. Pudhur Tank Ã Â®â€¢Ã Â¯â€¡. Ã Â®Å½Ã Â®Â©Ã Â¯Â. Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vadakumarai Tank Ã Â®ÂµÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®Â®.55021, 79.19862®ÂÃ Â®Â°Ã Â®Â¿",
          "Edappadi Small Tank Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Gendhiyan Kuttai (or Vannan Kuttai), Olakkachinnanur",
          "Benapatti cross roadÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Kapputhi",
          "Palaniyapuri Tank Ã Â®ÂªÃ Â®Â´Ã Â®Â©Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Danishpet Big Tank Ã Â®Å¸Ã Â¯â€¡Ã Â®Â©Ã Â®Â¿Ã Â®Â·Ã Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pachamalai Ã Â®ÂªÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â®Ã Â®Â²Ã Â¯Ë†",
          "Singipuram Tank Ã Â®Å¡Ã Â®Â¿Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Four Roads Area, Salem",
          "Vellaiyur Panchayat Via Mamarathukkadu Bus Stop",
          "Kolathur Area (after Forest Check Post till Govt. PHC Area via Thanda Four Roads)",
          "Ayyaneri Tank Ã Â®â€¦Ã Â®Â¯Ã Â¯ÂÃ Â®Â¯Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿",
          "Utthumalai Ã Â®Å Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â®Â²Ã Â¯Ë†",
          "Ariyapalayam Tank Ã Â®â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mettukotai, Chinnathanda",
          "Chinna Pillaiyur Tank Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂªÃ Â®Â¿Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ariyampatti Pond Ã Â®â€¦Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†",
          "Lourdhu Mary Malai Kovil Church, A.N.Mangalam",
          "Samuthiram Panangkuttai Lake Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kolathur to Pannavadi Road--enroute (after Kolathur Market till Kaveri Amman Temple--5 km stretch)",
          "Vellalagundam Tank Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
        ],
      },
      {
        district: "Viluppuram",
        localities: [
          "Ã°Å¸Å’Â³Ã Â¤Å“Ã Â¥ÂÃ Â¤Â¨Ã Â¤Â¾ Ã Â¤â€¢Ã Â¤Â¡Ã Â¥ÂÃ Â¤Â²Ã Â¤Â¿Ã Â¤â€šÃ Â¤Â¬ z.p.school dingrajwadi",
          "Aalangkuppam Lake (Ã Â®â€ Ã Â®Â²Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Aalathoor Lake (Ã Â®â€ Ã Â®Â²Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Aanaangoor Lake (Ã Â®â€ Ã Â®Â©Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Aanpaakkam Lake (Ã Â®â€ Ã Â®Â£Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Aapirambattu Lake (Ã Â®â€ Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Aasaarankuppam Village (Ã Â®â€ Ã Â®Å¡Ã Â®Â¾Ã Â®Â°Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â)",
          "Aathikuppam Lake (Ã Â®â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Aathur Lake (Ã Â®â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Acharampattu Lake (Ã Â®â€¦Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Adanapattu Lake (Ã Â®â€ Ã Â®Â¤Ã Â®Â©Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Alamarathukuppam Lake (Ã Â®â€ Ã Â®Â²Ã Â®Â®Ã Â®Â°Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Andiyarpalayam Lake (Ã Â®â€ Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Annamputhur Lake (Ã Â®â€¦Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Arakandanallur Lake (Ã Â®â€¦Ã Â®Â°Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Aranya Forest and Sanctuary (Restricted Access)",
          "Asappur Lake (Ã Â®â€¦Ã Â®Å¡Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Auro Vanam Ã Â®â€ Ã Â®Â°Ã Â¯â€¹ Ã Â®ÂµÃ Â®Â©Ã Â®Â®Ã Â¯Â (Restricted  Access)",
          "Auroville",
          "Auroville Botanical Gardens",
          "Avarappakkam Lake (Ã Â®â€¦Ã Â®ÂµÃ Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Ã Â¤Â®Ã Â¤Â¿Ã Â¤Â Ã Â¤Â¾Ã Â¤â€”Ã Â¤Â°, Navi Mumbai, Maharashtra, IN (18.907, ",
          "Ã Â¤Å“Ã Â¥Ë†Ã Â¤Â¨Ã Â¤â€”Ã Â¤Â°, Ã Â¤Â°Ã Â¤Â¾Ã Â¤Å“Ã Â¤Â¸Ã Â¥ÂÃ Â¤Â¥Ã Â¤Â¾Ã Â¤Â¨, IN (25.018, 74.991)",
          "Botheri Lake, Thindivanam",
          "Brammadesam Lake (Ã Â®ÂªÃ Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â¤Ã Â¯â€¡Ã Â®Å¡Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Buckingham Canal--Kaipanikuppam",
          "Elrampattu Lake",
          "Enthur Lake (Ã Â®ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Eraiyanur Lake (Ã Â®Å½Ã Â®Â±Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Eraiyanur Sitheri (Ã Â®Å½Ã Â®Â±Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿)",
          "Govt. Primary School, Navarkulam",
          "Hotel Purple Lake View, Chettinagar, Koonimedu",
          "Illavampattu Lake (Ã Â®â€¡Ã Â®Â²Ã Â®ÂµÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Irumbai Lake (Ã Â®â€¡Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kaliveli (Kazhuveli) Lake",
          "Kaliveli (Kazhuveli) Lake--Koonimedu entrance",
          "Kallur Kere, Kollegal (Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ Â²Â²Ã Â³â€šÃ Â²Â°Ã Â³Â Ã Â²â",
          "Kappiampuliyur Lake (Ã Â®â€¢Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Karai RF",
          "Kattrampakkam Lake (Ã Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kattupaiyur Lake",
          "Kaveripakkam Lake (Ã Â®â€¢Ã Â®Â¾Ã Â®ÂµÃ Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kazhuperumbakkam Lake (Ã Â®â€¢Ã Â®Â´Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Keezhkoothapaakkam Lake (Ã Â®â€¢Ã Â¯â‚¬Ã Â®Â´Ã Â¯ÂÃ Â®â€¢Ã Â¯â€šÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kiliyanur Periya Eri (Ã Â®â€¢Ã Â®Â¿Ã Â®Â³Ã Â®Â¿Ã Â®Â¯Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kiliyanur Sitheri (Ã Â®â€¢Ã Â®Â¿Ã Â®Â³Ã Â®Â¿Ã Â®Â¯Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿)",
          "Kilsithamur Lake (Ã Â®â€¢Ã Â¯â‚¬Ã Â®Â´Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kodur Iyyanaar Koil Lake (Ã Â®â€¢Ã Â¯Å Ã Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â¯Ã Â¯ÂÃ Â®Â¯Ã Â®Â©Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kodur Lake (Ã Â®â€¢Ã Â¯Å Ã Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kolapakkam Lake",
          "Kondamur Lake (Ã Â®â€¢Ã Â¯Å Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Konjimangalam Lake (Ã Â®â€¢Ã Â¯Å Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kothampakkam Lake (Ã Â®â€¢Ã Â¯Å Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kottamaruthur Lake",
          "K Resort (Restricted Access)",
          "Krishnagiri Fort, Gingee (Senji)",
          "Kunnam Lake (Ã Â®â€¢Ã Â¯ÂÃ Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kurumbaram Lake (Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kurumbaram Reserve Forest (Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â)",
          "Maanoor Lake (Ã Â®Â®Ã Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Madavanthaangal Lake (Ã Â®Â®Ã Â®Å¸Ã Â®ÂµÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mandagappattu Lake (Ã Â®Â®Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®â€¢Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Marakkanam",
          "Maruthur Lake (Ã Â®Â®Ã Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Miracle Farm and surrounding area",
          "Mudhalur Lake",
          "Munnur Lake (Ã Â®Â®Ã Â¯ÂÃ Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Murukkam Lake (Ã Â®Â®Ã Â¯ÂÃ Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Murungapakkam Lake (Ã Â®Â®Ã Â¯ÂÃ Â®Â°Ã Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Muttakadu RF",
          "Muttrampet Lake (Ã Â®Â®Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Mysore City Bird Atlas 30A, Lingambudhi kere Ã Â²Â²Ã Â²Â¿Ã Â²â€šÃ Â²â€”Ã Â²Â¾Ã Â²â€šÃ Â²Â¬Ã Â³ÂÃ Â²Â§Ã Â²Â¿",
          "Nallapareddy Palayam Lake (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â°Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Nallavoor Lake (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¾Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Navalur Lake Ã Â®Â¨Ã Â®Â¾Ã Â®ÂµÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nesal Lake (Ã Â®Â¨Ã Â¯â€ Ã Â®Å¡Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Omandhur Lake (Ã Â®â€œÃ Â®Â®Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Ottai Lake (Ã Â®â€™Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Oussudu Lake Birds Sanctuary--TN side (Ã Â®Å Ã Â®Å¡Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â®Â±Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â®Â³Ã Â¯Â Ã Â®Å¡Ã Â®Â°Ã Â®Â£Ã Â®Â¾Ã Â®Â²Ã Â®Â¯Ã Â®Â®Ã Â¯Â--Ã Â®Â¤Ã Â®Â®Ã Â®Â¿Ã Â®Â´Ã Â®â€¢Ã Â®ÂªÃ Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿)",
          "Ozhandhai Lake (Ã Â®â€°Ã Â®Â´Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)ss)",
          "Paakkam Lake (Ã Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Padipallam Forest and Hills",
          "Pakkamalai RF",
          "Pakkam Lake",
          "Pallipudhupattu Lake (Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Panamalai Lake (Ã Â®ÂªÃ Â®Â©Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Panampet Lake (Ã Â®ÂªÃ Â®Â¾Ã Â®Â©Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Paranur Lake",
          "Pennaivalam Lake",
          "Peraavoor Lake (Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â®Â¾Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Periyakuttai (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â",
          "Perumbakkam Lake",
          "Perumukkal Lake (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Perungapur Lake",
          "Pitchandikulam Forest",
          "Poyyapakkam Lake (Ã Â®ÂªÃ Â¯Å Ã Â®Â¯Ã Â¯ÂÃ Â®Â¯Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â/Ã Â®ÂªÃ Â¯Å Ã Â®Â¯Ã Â¯ÂÃ Â®â€¢Ã Â¯Ë†Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Pudhunagar Lake (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¨Ã Â®â€¢Ã Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Pulichapallam Big Tank (Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Pulichapallam Lake (Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Putthupattu Beach (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â®Å¸Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â¯Ë†)",
          "Roya Ottai Lake (Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Â¯ Ã Â®â€™Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Royapudupakkam (Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Â¯Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Salavaadhi Lake (Ã Â®Å¡Ã Â®Â²Ã Â®ÂµÃ Â®Â¾Ã Â®Â¤Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Semangalam Lake (Ã Â®Å¡Ã Â¯â€¡Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Serenity Beach",
          "Singanandhal Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¨Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â",
          "Sirudhavur Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯ÂÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)Â¿)",
          "Siruvaadi Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Siruvadi RF",
          "Sithamburmalai/Periyamoor Forest",
          "Sorappur Lake",
          "Suthukeni Lake (Ã Â®Å¡Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¡Ã Â®Â",
          "Suthukeni Lake (Ã Â®Å¡Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¡Ã Â®Â£Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Tailapuram Lake (Ã Â®Â¤Ã Â¯Ë†Ã Â®Â²Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Terkunam Lake (Ã Â®Â¤Ã Â¯â€¡Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â£Ã Â®Â®Ã Â¯Â  Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thamarai Kulam Ã Â®Â¤Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Ë†Ã Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â",
          "Thazhankadu (Ã Â®Â¤Ã Â®Â¾Ã Â®Â´Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â)",
          "Thenkodipakkam Lake (Ã Â®Â¤Ã Â¯â€ Ã Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thennagaram Lake (Ã Â®Â¤Ã Â¯â€ Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®â€¢Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thiruchitrambalam lake (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â±Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Thondamanatham Lake (Ã Â®Â¤Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â®Ã Â®Â¾",
          "Thookanaampaakkam Lake (Ã Â®Â¤Ã Â¯â€šÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â£Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Tindivanam Big Tank (Ã Â®Â¤Ã Â®Â¿Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂµÃ Â®Â©Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Tindivanam--General Area",
          "Tindivanam Thaangal Eri (Ã Â®Â¤Ã Â®Â¿Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂµÃ Â®Â©Ã Â®Â®Ã Â¯Â Ã Â®Â¤Ã Â®Â¾Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "T. Konalavadi Lake",
          "T. Kunnathur Lake",
          "T. Pudupalayam Lake",
          "Ulagaapuram Perumal Lake (Ã Â®â€°Ã Â®Â²Ã Â®â€¢Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â³Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Urapanur Kanmaai (Ã Â®â€°Ã Â®Â±Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â¯Ã Â¯Â)",
          "Vaakkoor Lake (Ã Â®ÂµÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vaazhakkombai Lake Ã Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Â®Ã Â¯ÂÃ",
          "Vada Aalappakkam Lake (Ã Â®ÂµÃ Â®Å¸ Ã Â®â€ Ã Â®Â²Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vada Kodipakkam Lake (Ã Â®ÂµÃ Â®Å¸Ã Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vada Kodippaakam Sitheri (Ã Â®ÂµÃ Â®Å¸Ã Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿)",
          "Vadamalaiyanur Lake",
          "Vallavanur Lake (Ã Â®ÂµÃ Â®Â³Ã Â®ÂµÃ Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "VALUDAVUR LAKE (Ã Â®ÂµÃ Â®Â´Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Valudavur Lake (Ã Â®ÂµÃ Â®Â´Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Valudavur Lake (Ã Â®ÂµÃ  Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®ÂµÃ Â¯Â°Ã Â¯Â Ã Â®ÂÃ Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vandipalayam Bridge",
          "Vanur Lake (Ã Â®ÂµÃ Â®Â¾Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "V. Budhur Lake (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Veedur Dam (Ã Â®ÂµÃ Â¯â‚¬Ã Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†)",
          "Vengai Lake (Ã Â®ÂµÃ Â¯â€¡Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vepperi Big Tank (Ã Â®ÂµÃ Â¯â€¡Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vepperi Sitheri (Ã Â®ÂµÃ Â¯â€¡Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿)",
          "Vilvanatham Big Tank (Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Vilvanatham Small Tank (Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "V. Mathur Lake (Ã Â®Â®Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "V.Pudupakkam Lake (Ã Â®ÂµÃ Â®Â¿.Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Yedaiyanthittu Estuary (Ã Â®â€¡Ã Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â©Ã Â¯Â Ã Â®Â¤Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®â€¢Ã Â®Â´Ã Â®Â¿Ã Â®Â®Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)",
        ],
      },
      {
        district: "Krishnagiri",
        localities: [
          "Achettipalli Lake",
          "Aiyur Forest Rest House",
          "Aiyur Reserve Forest",
          "Amman Nagar Lake",
          "Anandavanam Eco Farms",
          "Anebiddahalla, Melagiri Hills",
          "Attibele / Zuzuwadi Lake",
          "Avathanapatti Lake, Krishnagiri",
          "Bagalur",
          "Balakadapallam, Melagiri Hills",
          "Bala Kondarayana Swamy Temple",
          "Barur Lake",
          "Barur Tank",
          "Betamugilalam, Melagiri Hills",
          "Bhoomi Organic Ashram, Gummalapuram",
          "Biligundlu, Melagiri Hills",
          "Chandramuki Lake",
          "Chitrashila Ashrama",
          "Dargah Lake (Chandramkudi Eri)",
          "Devasamudram (Krishnagiri) Lake",
          "Deverbetta, Melagiri Hills",
          "Dheeran Chinnamalai International School, Uthangarai",
          "Dubbagauli",
          "Hosur (OBI)",
          "Jamedhar Medu, Kaveripattinam",
          "Jowlagiri, Melagiri Hills",
          "Kaveripattinam Lake",
          "Kelamangalam",
          "Kelavarapalli Reservoir",
          "Kodekarai, Melagiri Hills",
          "Kodiyalam",
          "Konganapalli",
          "Krishnagiri Dam (KRP Dam)",
          "Manchi, Melagiri Hills",
          "Maruderi Lake",
          "Matham Lake",
          "Mathigiri Cattle Farm Lake",
          "Melagiris--General Area",
          "Melur, Melagiri Hills",
          "Mudhuganapalli Lake",
          "Natrampalayam",
          "Navadarshanam",
          "Navadhi Lake",
          "Panai Dam (Akkathangai Eri)",
          "Panchapalli, Melagiri Hills",
          "Pearl Valley (Tamil Nadu Side)",
          "Perandapalli Forest",
          "Ramanaicken Lake, Hosur",
          "Rasimanal",
          "Samaa Kuteeram Farm",
          "Samalpatti Railway Station",
          "Samieri, Melagiri Hills",
          "Shanthapuram (Nallur) Lake",
          "Singarapettai Lake",
          "Sulumalai Hillocks",
          "Thally Lake",
          "TVS Academy (School)",
          "TVS Factory, Hosur",
          "Udubaranai Reserve Forest",
          "Uganiyam, Melagiri Hills",
          "Uthengarai",
          "Varatanapalli RF",
          "Vilangamudi Lake",
        ],
      },
      {
        district: "The Nilgiris",
        localities: [
          "Adasolai Hill",
          "Anaikatty FRH",
          "Aravenu",
          "Aravenu Village",
          "Arboretum Tree Garden",
          "Avalanche Forest Guest House",
          "Avalanche Reserve Forest",
          "Avalanche--Upper Bhavani Road",
          "Banagudi Shola",
          "Beramukku Temple",
          "Boothanatham, Moyar",
          "Botanical Garden, Ooty",
          "Burliyar - Coonoor Rd",
          "Burliyar-- Kurumba Lodge (Restricted Access)",
          "Cairn Hill, Ooty",
          "Catherine Waterfalls",
          "Central Potato Research Institute, Muthorai",
          "Cherambadi",
          "Coonoor-- Erin Villa (Restricted Access)",
          "Coonoor--General Area (OBI)",
          "Coonoor-Kotagiri Road",
          "Coonoor Railway Station",
          "Devala",
          "Doddabetta Peak",
          "Dolphin's Nose View Point",
          "Elk Hill--General Area (OBI)",
          "Fern Hill, Ooty",
          "Forestdale",
          "Gauri Farms, Gudalur",
          "Genepool Ecological Park",
          "Glenmorgan Estate (Restricted access)",
          "Golf Link Road, Ooty",
          "Gori Shola, Ooty",
          "Government Arts College (Ã Â®â€¦Ã Â®Â°Ã Â®Å¡Ã Â¯Â Ã Â®â€¢Ã Â®Â²Ã Â¯Ë† Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â®Â¿), Ooty",
          "Gudalur Adivasi Hospital area",
          "Gymkhana",
          "Hanging Huts Resort-- (Restricted Access)",
          "Hebron School (Private Property)",
          "Hebron School (Restricted Access)",
          "Iduhatty",
          "Kalahatty Ghat Road--Mountain`n' mist",
          "Kalhatty Falls",
          "Kalhatty Jungle Camp",
          "Kappachi Village Area",
          "Katteri Lake",
          "Katteri Valley, Coonoor",
          "Kattery Park",
          "Kattery Park Road",
          "Ketti Valley Grasslands",
          "Keystone Foundation Campus",
          "Kinnakkorai Village",
          "Kodanad--Genepool Area",
          "Kodanadu View Point",
          "Korakundah",
          "Koranur (Ã Â®â€¢Ã Â¯Å Ã Â®Â°Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Kotagiri--Franciscan Brothers Road",
          "Kotagiri--General Area (OBI)",
          "Kotagiri Road, Ooty (Ã Â®â€¢Ã Â¯â€¡Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿ Ã Â®Å¡Ã Â®Â¾Ã Â®Â²Ã Â¯Ë†, Ã Â®Å Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿)",
          "Kothagiri-Kodanadu Road",
          "Krishu Mala Road, Pandalur",
          "Kundha River Bridge, Geddai Road",
          "Lamb's Rock, Coonnoor",
          "Lawrence School, Lovedale (Ã Â®Â²Ã Â®Â¾Ã Â®Â°Ã Â®Â©Ã Â¯ÂÃ Â®Â¸Ã Â¯Â Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿)",
          "Longwood Shola",
          "Mamaram",
          "Mangorange Estate Area",
          "Maravakandi Lake",
          "Marlimund Lake",
          "Masinagudi",
          "Masinagudi-- Bamboo Banks (Restricted Access)",
          "Masinagudi-- Bokkapuram Road",
          "Masinagudi-- Cheetal Walk (Restricted Access)",
          "Masinagudi--Jungle Hut (Restricted Access)",
          "Masinagudi Scrub Jungle",
          "Masinagudi Temple Pond",
          "Masinagudi-- Wild Haven (Restricted Access)",
          "Mavanalla",
          "Monterosa Colony, Ooty",
          "mountain railway",
          "Moyar",
          "Moyar Dam",
          "Moyar Gorge, Mudumalai",
          "Moyar River, Kallampalayam",
          "Moyar Road, Masinagud",
          "Moyar Village",
          "M. Palada",
          "Mudumalai Forest",
          "Mudumalai NP",
          "Mudumalai Tiger Reserve-- Kargudi -Peacock Dormitory",
          "Mukurthi National Park",
          "Mulloor Village",
          "Muthorai--General Area (OBI)",
          "Nadugani Village",
          "Naduvattam Area",
          "Needle Rock View Point",
          "Nelliyalam - Uppatti Road",
          "Nilgiri Hills (OBI)",
          "Oland",
          "Ooty--Accord Highland Hotel",
          "Ooty-- Fingerpost",
          "Ooty--General Area",
          "Ooty Lake, Ooty",
          "Pandalur",
          "Parsons Valley",
          "Pineforest, Thalakunda Creak",
          "Porthimund Dam",
          "Prakritilaya Nature Homes",
          "Pudumund Village Area",
          "Pykara Falls",
          "Pykara, Masinagudi",
          "Radio Astronomy Centre, Muthorai",
          "Ralliah Dam",
          "Rose Garden, Ooty",
          "Sathyamangalam TR--Kallampalayam (Kelur & Melur)",
          "Satton Estate",
          "Sigur Ghat Bridge",
          "Sim's Park, Conoor",
          "Sinclair Ooty",
          "Siriyur",
          "Tan Tea Factory Road, Tiger Hill",
          "Thaishola Estate Area",
          "Thalaikundha",
          "Thankamala",
          "The Blue Mountains School",
          "Thengumarahada Forest, Nilgiris",
          "Thengumarahada Village",
          "Theppakadu Forest",
          "Theppakadu-Masinagudi Road",
          "Thorappaly Area",
          "Tiger Hill",
          "Upper Bhavani, Nilgiris",
          "Upper Bhavani Road",
          "Valley View, Ooty",
          "Vibudhimalai Murugan Temple Hill",
          "Walmer",
          "Wenlock Downs 9th Mile Shooting Point",
        ],
      },
      {
        district: "Pudukkottai",
        localities: [
          "Adappan Kulam, Pudukottai",
          "Ã Â¤Â¤Ã Â¥ÂÃ Â¤Â°Ã Â¤Â¿Ã Â¤Â¨Ã Â¥â€¡Ã Â¤Â¤Ã Â¥ÂÃ Â¤Â°Ã Â¥â‚¬, Pirkone, Maharashtra, IN (18.844, 73.011)Ã Â®Â¾Ã Â®Â¯Ã Â¯Â)",
          "ECR Road, Kottaippattinam",
          "Hulimavu Gudda  Ã Â²Â¹Ã Â³ÂÃ Â²Â³Ã Â²Â¿Ã Â²Â®Ã Â²Â¾Ã Â²ÂµÃ Â³Â Ã Â²â€”Ã Â³ÂÃ Â²Â¡Ã Â³ÂÃ Â²Â¡",
          "Indian Institute of Science, Ã Â²Â¬Ã Â³â€ Ã Â²â€šÃ Â²â€”Ã Â²Â³Ã Â³â€šÃ Â²Â°Ã Â³Â IN-KA 13.02312, 77.56792",
          "Kanappettai Kanmai",
          "Kanchathumalai (Ã Â®â€¢Ã Â®Â¾Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â®Â²Ã Â¯Ë†) Rocky Scrub Forest",
          "Kodiyamunai (Ã Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â®Â¿Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®Â©Ã Â¯Ë†)",
          "Kumran Kundru (Ã Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â©Ã Â¯ÂÃ Â®Â±Ã Â¯Â)",
          "Machuvadi Savannah (Pudukkottai Veterinary Farm)",
          "Marupani Kulam",
          "Maylapatti - Ancient Jain Temple",
          "Muthukuda Mangrove forest",
          "Muthu Munian Temple Grove",
          "Narthamalai Hillock & Forest",
          "Neerpalani Kanmai (Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â´Ã Â®Â©Ã Â®Â¿ Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â¯Ã Â¯Â)",
          "Oonaiyur Big Tank",
          "Ottangalanchi Kaadu",
          "Paavendar Bharatidasan College of Engineering Campus",
          "Palakudi Kali Kovil",
          "Panangudi Kanmai",
          "Parry Nutraceuticals Campus",
          "Ponpethi Lake",
          "Poolavari Tank Ã Â®ÂªÃ Â¯â€šÃ Â®Â²Ã Â®Â¾Ã Â®ÂµÃ Â®Â°Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pudhukulam (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â) Pond",
          "Pudhumanai Street, Aranthangi",
          "Pudukkottai Collectorate",
          "Sri Sulapidari Amman Koil Sacred Grove, Kolluppatti",
          "Sukkanendhal Kulam",
          "Thirumayam Fort",
          "Thirupunavasal lake",
          "Yemba Kanmai",
        ],
      },
      {
        district: "Namakkal",
        localities: [
          "Aagaya Gangai Waterfalls",
          "Akkaraipatti Lake",
          "A. K. Samuthiram Tank Ã Â®â€¦Ã Â®Â©Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â·Ã Â¯ÂÃ Â®Â£Ã Â®Â°Ã Â®Â¾Ã Â®Â¯ Ã Â®Å¡Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Alavaimalai foothills",
          "Anaipalayam Lake Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Arappaleeswarar Temple Ã Â®â€¦Ã Â®Â±Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯â‚¬Ã Â®Â¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â°Ã Â®Â°Ã Â¯Â Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â",
          "Ariyursolai--Kuzhivalavu [RESTRICTED ENTRY] Ã Â®â€¦Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¹Ã Â®Â²Ã Â¯Ë†--Ã Â®â€¢Ã Â¯ÂÃ Â®Â´Ã Â®Â¿Ã Â®ÂµÃ Â®Â³Ã Â®ÂµÃ Â¯Â",
          "Ariyursolai--Thegavai [RESTRICTED ENTRY] Ã Â®â€¦Ã Â®Â°Ã Â®Â¿Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¹Ã Â®Â²Ã Â¯Ë†--Ã Â®Â¤Ã Â¯â€ Ã Â®â€¢Ã Â®ÂµÃ Â¯Ë†",
          "Ã Â¤Â²Ã Â¤Â¾Ã Â¤Â¹Ã Â¤Â¾Ã Â¤Â¡Ã Â¤ÂªÃ Â¥ÂÃ Â¤Â°, Ã Â¤â€°Ã Â¤Â¤Ã Â¥ÂÃ",
          "Ã Â®â€¦Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾ Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë† Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾, Kondichettipatti Lake",
          "Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â¯â€¡Ã Â®Å“Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿ Velur",
          "Ã Â¤â€ Ã Â¤Â®Ã Â¤Â²Ã Â¥â‚¬ Ã Â¤ÂµÃ Â¤Â¾Ã Â¤Â²Ã Â¤Â¾ Ã Â¤â€¢Ã Â¥ÂÃ Â¤â€ Ã Â¤â€š",
          "Benapatti beach road Ã¢â‚¬â€œterrestrialÃ¢â‚¬â€œmixedÃ¢â‚¬â€œPMP",
          "B. Mettur--Nettavelampatti [RESTRICTED ENTRY] Ã Â®Â¨Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®ÂµÃ Â¯â€¡Ã Â®Â²Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿, Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â·Ã Â¯ÂÃ Â®Â£Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "B. Mettur--Puliyancholai [RESTRICTED ENTRY] (Balakrishnampatti Mettur) Ã Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Â¯Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¹Ã Â®Â²Ã Â¯Ë†, Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â·Ã Â¯ÂÃ Â®Â£Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Erumapatty Lake",
          "Erumappatti--Kokkuvari, Selur [RESTRICTED ENTRY] Ã Â®Å½Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿--Ã Â®â€¢Ã Â¯Å Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Â°Ã Â®Â¿, Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Ettukai Amman Temple (Ã Â®Å½Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â¯Ë† Ã Â®â€¦Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®Â¯Ã Â®Â¿Ã Â®Â²Ã Â¯Â)",
          "Idumbankulam Tank Ã Â®â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â¯Â",
          "Idumbankulam Tank Ã Â®â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Iluppili Tank Ã Â®â€¡Ã Â®Â²Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Â²Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Iruttanai Lake",
          "Iruttanai, Namakkal",
          "Jedarpalayam Check Dam",
          "Kamalaya Tank",
          "Kannurpatti Large Tank Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â¯â€šÃ Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Karumanoor Lake",
          "Kasthuripatti Tank Ã Â®â€¢Ã Â®Â¸Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Kattipalayam Lake",
          "Keerambur Country (Ã Â®â€¢Ã Â¯â‚¬Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Koattapalayam Lake",
          "Kolangondai-Kottapalayam Lake",
          "Kollapatti Lake",
          "Kolli Hills Road, Karavallicombai R.F",
          "Koneripatti Tank Ã Â®â€¢Ã Â¯â€¹Ã Â®Â©Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Madhiyampatti Tank",
          "Mahendra Engineering College  Mallasamudram",
          "Mallur I--Mamarathu Odai to Anai Pali Mudakku, Bothamalai Road [RESTRICTED ENTRY]",
          "Mallur--Palanthinnipatti [RESTRICTED ENTRY] Kottai Mariamman Kovil Surroundings",
          "Mangalapuram--Karkoodalpatti [RESTRICTED ENTRY] Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â³Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â--Ã Â®â€¢Ã Â®Â¾Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€šÃ Â®Å¸Ã Â®Â²Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿",
          "Minnakkal Tank and Surrounds Ã Â®Â®Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Mullukurichi--Nayakkancombai [RESTRICTED ENTRY] Ã Â®Â®Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿--Ã Â®Â¨Ã Â®Â¾Ã Â®Â¯Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â©Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯Ë†",
          "Mullukurichi--Varagur [RESTRICTED ENTRY] Ã Â®Â®Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â±Ã Â®Â¿Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿--Ã Â®ÂµÃ Â®Â°Ã Â®â€¢Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Naachipudur Tank Ã Â®Â¨Ã Â®Â¾Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Nallipalayam Area",
          "Namagiripettai--Bailnadu North [RESTRICTED ENTRY] Ã Â®Â¨Ã Â®Â¾Ã Â®Â®Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†--Ã Â®ÂªÃ Â¯Ë†Ã Â®Â²Ã Â¯ÂÃ Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂµÃ Â®Å¸Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Â",
          "Namagiripettai--Osakaraiyanoothu [RESTRICTED ENTRY] (Orumithithadam Area) Ã Â®Â¨Ã Â®Â¾Ã Â®Â®Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†--Ã Â®â€™Ã Â®Å¡Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â¯Ë†Ã Â®Â¯Ã Â®Â©Ã Â¯â€šÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯Â (Ã Â®â€™Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Å¸Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿)",
          "Namakkal Bus Stand Area",
          "Namakkal Lake",
          "Nehru Memorial College",
          "Nehru Park",
          "Oomayampatti Tank Ã Â®Å Ã Â®Â®Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Osakaraiyanoothu Tank",
          "Palayapalayam Big Tank Ã Â®ÂªÃ Â®Â´Ã Â¯Ë†Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â",
          "Palayapalayam Small Tank Ã Â®ÂªÃ Â®Â´Ã Â¯Ë†Ã Â®Â¯Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â© Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â",
          "Paruthipalli Tank Ã Â®ÂªÃ Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯",
          "Paruthipalli Tank Ã Â®ÂªÃ Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pavithram Tank Ã Â®ÂªÃ Â®ÂµÃ Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Peraavoor Lake (Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â®Â¾Ã Â®ÂµÃ Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)Â®ÂÃ Â®Â°Ã Â®Â¿",
          "PGP Educational Institutions",
          "Pudhuchatram Tank Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Pudhupatti East--Navapali Kinaru [RESTRICTED ENTRY]",
          "Pudhupatti West--Mathiyamadavu Dam [RESTRICTED ENTRY]",
          "Puthur Tank Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sarapalli lake Ã Â®Å¡Ã Â®Â°Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Sarkar Nattamangalam Tank Ã Â®Å¡Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¾Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Selvam Educational Institutions Campus Ã Â®Å¡Ã Â¯â€ Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®ÂµÃ Â®Â¿ Ã Â®Â¨Ã Â®Â¿Ã Â®Â±Ã Â¯ÂÃ Â®ÂµÃ Â®Â©Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â³Ã Â¯Â Ã Â®ÂµÃ Â®Â³Ã Â®Â¾Ã Â®â€¢Ã Â®Â®Ã Â¯Â",
          "Semoor Periya Yeri",
          "Sengarai--Edappulinadu [RESTRICTED ENTRY] Ã Â®Å¡Ã Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â¯Ë†--Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯Â",
          "Senthamangalam--Edapakkadu Area [RESTRICTED ENTRY] Ã Â®Å¡Ã Â¯â€¡Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â--Ã Â®Å½Ã Â®Å¸Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Senthamangalam--Naducombai [RESTRICTED ENTRY] Ã Â®Å¡Ã Â¯â€¡Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â--Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯Ë†",
          "Senthamangalam--Nainamalai Foothills [RESTRICTED ENTRY] Ã Â®Å¡Ã Â¯â€¡Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â®Â®Ã Â¯Â--Ã Â®Â¨Ã Â¯Ë†Ã Â®Â©Ã Â®Â¾Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®â€¦Ã Â®Å¸Ã Â®Â¿Ã Â®ÂµÃ Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â¯Â",
          "Sivaranthagam Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®ÂµÃ Â®Â°Ã Â®Â¾Ã Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)®Â°Ã Â®Â¿",
          "Thalamalai Foothills",
          "Thalamalai South--Vadavathur [RESTRICTED ENTRY] Ã Â®Â¤Ã Â®Â²Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®Â¤Ã Â¯â€ Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â¯Â--Ã Â®ÂµÃ Â®Å¸Ã Â®ÂµÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Thammampatti--Adukkam & Velikkadu [RESTRICTED ENTRY] Ã Â®Â¤Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿--Ã Â®â€¦Ã Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â & Ã Â®ÂµÃ Â¯â€ Ã Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Thammampatti--Perumal Malai [RESTRICTED ENTRY] Ã Â®Â¤Ã Â®Â®Ã Â¯ÂÃ Â®Â®Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿--Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â³Ã Â¯Â Ã Â®Â®Ã Â®Â²Ã Â¯Ë†",
          "Thiruchencode Arthanareeswarar Temple Area Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯â€¹Ã Â®Å¸Ã Â¯Â Ã Â®â€¦Ã Â®Â°Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¨Ã Â®Â¾Ã Â®Â°Ã Â¯â‚¬Ã Â®Â¸Ã Â¯ÂÃ Â®ÂµÃ Â®Â°Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Thoosur Tank Ã Â®Â¤Ã Â¯â€šÃ Â®Å¡Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Thottiyapatty Lake",
          "Thumbalpatti Tank Ã Â®Â¤Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â²Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Tipramahadevi Lake",
          "Valavanthinadu--Selur [RESTRICTED ENTRY] Ã Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â®ÂµÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯Â--Ã Â®Å¡Ã Â¯â€¡Ã Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Valavanthinadu--Solakkadu & Keezhsolakkadu Area [RESTRICTED ENTRY] Ã Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â®ÂµÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯Â--Ã Â®Å¡Ã Â¯â€¹Ã Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â & Ã Â®â€¢Ã Â¯â‚¬Ã Â®Â´Ã Â¯ÂÃ Â®Å¡Ã Â¯â€¹Ã Â®Â³Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Veeranam Tank Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®Â¾Ã Â®Â£Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Vennandur Lake",
          "Veterinary College and Research Institute Campus, Namakkal",
          "Vettambadi Tank",
        ],
      },
      {
        district: "Karur",
        localities: [
          "Kaatupalayam, Thennilai, Karur",
          "Kalugur Lake",
          "Karur Bus Stand",
          "Karur Railway Station",
          "Kaveri River, Lalapettai",
          "Keelaveliyur Agricultural Lake",
          "Mayanur Check Dam",
          "Noyyal-Cauvery, Noyyal Village",
          "Panjappatty Lake Ã Â®ÂªÃ Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿",
          "Ponaniyaru Dam",
          "Rangamalai Organic Farm & Traditional Seed Bank",
          "Tharagampatti Lake",
          "TNPL Quarters Area",
          "Vanagam Ecological Foundation, Kadavur",
        ],
      },
      {
        district: "Coimbatore",
        localities: [
          "Malumichampatti Pond",
          "Valparai Dump yard",
          "Kalingarayan / Kondayampalayam Kulam (Ã Â®â€¢Ã Â®Â¾Ã Â®Â³Ã Â®Â¿Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Â¾Ã Â®Â¯Ã Â®Â©Ã Â¯Â/ Ã Â®â€¢Ã Â¯Å Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Attakatti to Waterfalls",
          "Chettipalayam Grassland",
          "Periyanaikanpalyam Railway Station",
          "Aliyar View Point",
          "Thamaraikulam (Ã Â®Â¤Ã Â®Â®Ã Â®Â°Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Thaneerpanthal Anti-poaching camp, Manjoor-Mulli Road",
          "Semisanpalayam Grassland",
          "Valparai--Urulikal Checkpost to Manamboli",
          "Madukkarai",
          "Subbe Gounden Pudur",
          "Kumittipathi River Bridge",
          "Baralikadu Boat House",
          "Athikadavu River",
          "Government Arts College, Coimbatore",
          "Raakachi Garden",
          "Benapatti cattle trailÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP Â®Å¸Ã Â¯Ë†)",
          "Kallipalayam Grassland",
          "Siruvani Water Treatment Plant Surroundings",
          "Singanallur Lake, Coimbatore",
          "Perumal Kovil Pathy",
          "Peelamedu Grasslands",
          "Ardhanari Palayam, Near Anamalai Tiger Reserve",
          "Jeevanandha Puram",
          "Central GST Division Office",
          "Kamatchipuram Grassland",
          "Bilichi Grass Land",
          "Krisshna Lake",
          "Disha A Life School",
          "Loam's view  to Attakatti",
          "Ukkulam Lake",
          "Old Valparai Fragment",
          "Sirikundra Bungalow Area",
          "Air Force Naval Housing Enclave (AFNHE)",
          "Poompuhar Nagar, Thudiyalur",
          "Gopanari Reserve Forest",
          "Pooluvapatti Foothills",
          "Kannampalayam Lake, Coimbatore",
          "Ravathur Road Lake",
          "Chavadippara Cell No. ( 85.34.3)",
          "Kongunadu Arts & Science College, G.N. Mills",
          "Nesal Lake (Ã Â®Â¨Ã Â¯â€ Ã Â®Å¡Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Selvachinthamani Lake",
          "Benapatti Outer farmÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œcultivationÃ¢â‚¬â€œPMP",
          "Cinchona Govt. High School, Valparai",
          "Mettupalayam Railway Junction",
          "Amrita Vishwa Vidyapeetham",
          "Vellalore Lake",
          "Thuri Palam",
          "Valparai--Sinnadorai",
          "Navamalai Road, Aliyar Dam",
          "Marudhamalai Foothills--Temple",
          "Valparai--Monica Garden Bungalow",
          "Kumaraswamy Lake (Muthannan Kulam)",
          "NGM college of Arts and Science",
          "Pachapalayam Dry Grasslands",
          "Annur Lake (Ã Â®â€¦Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Ambarampalayam",
          "Melmudi",
          "Kosuvamadai Village",
          "Race Course,Coimbatore",
          "Valparai--Varattuparai",
          "Gaumara Madalayam",
          "Sri Vanamuthumariamman Temple",
          "Gopalapuram (Village)",
          "Venkittapuram Grassland",
          "Nilgiri Biosphere Nature Park",
          "Ramanathapuram Higher Sec School Pollachi",
          "Kammalapatti Kuttai 2",
          "Pollachi Town Area",
          "Idigarai Outskirts",
          "Mannur Village Area",
          "CSI Matriculation Hr Sec School, Race Course",
          "Kurangumudi Area",
          "Stanmore, Valparai",
          "Walayar Lake-Tamil Nadu side",
          "Alanthurai Foothills",
          "Forest College and Research institute",
          "Karumathampatti",
          "Shanthi Social Service Park",
          "Government Girls Higher Secondary School, Thondamuthur",
          "IOB Colony, Maruthamalai Foothills",
          "Vellakinar Village Area",
          "Vanam Mini Forest",
          "Anaimalai Town",
          "Devarayapuram Village",
          "Sundakkamathur (or Perur) Lake, Coimbatore",
          "Kowshika River Bridge",
          "Kallipalayam lake",
          "Anuvavi Murugan Temple",
          "Perur Puttuveeki Lake",
          "Isha Home School",
          "Aliyar dam",
          "Annamalais, Tamil Nadu (OBI)",
          "Parambikulam Tiger Reserve--Karian Shola",
          "Thappittai Kizhavan Puthur (Ã Â®Â¤Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë† Ã Â®â€¢Ã Â®Â¿Ã Â®Â´Ã Â®ÂµÃ Â®Â©Ã Â¯Â Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â)",
          "Tamil Nadu Agricultural University, Coimabtore",
          "North Coimbatore Railway Station",
          "Perumal malai (Sri Navilkundru Srinivasaperumal Kovil)",
          "Akkamalai, Valparai",
          "CMS Engineering college",
          "Karumalai Balaji Temple",
          "Gundalbanda Falls Ã Â²â€”Ã Â³ÂÃ Â²â€šÃ Â²Â¡Ã Â²Â²Ã Â²Â¬Ã Â²â€šÃ Â²Â¡Ã Â²Â¾ Ã Â²Å“Ã Â²Â²Ã Â²ÂªÃ Â²Â¾Ã Â²Â¤",
          "Panapalli",
          "Zamin Uthukuli Area",
          "Valparai--UPASI",
          "Aiyasamy Temple Foothills",
          "Vedar Colony",
          "Kaliyaperumal Kuttai (Ã Â®â€¢Ã Â®Â²Ã Â®Â¿Ã Â®Â¯Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â³Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†) / Kalapatti wetland",
          "IFGTB, Coimbatore",
          "Thenmalai Fragment",
          "Dhayanur, Anaikatti RF range",
          "Thillaiyamman Nagar",
          "Mavuthampathi Mini Forest",
          "Chettipalayam Scrub Jungle (Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â®Â°Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â)",
          "Iyerpadi - Balaji Temple Road",
          "Devambadi Valasu",
          "KPR Institute of Engineering & Technology",
          "Soolakkal River",
          "Perumal Mudi (Restricted Access)",
          "Anaikatti Ghat section",
          "Kumran Kundru (Ã Â®â€¢Ã Â¯ÂÃ Â®Â®Ã Â®Â°Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â©Ã Â¯ÂÃ Â®Â±Ã Â¯Â)",
          "Pallapalayam Lake",
          "Thiruvalluvar Nagar Area",
          "Aringar Anna Park (Ã Â®â€¦Ã Â®Â±Ã Â®Â¿Ã Â®Å¾Ã Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾ Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾ )",
          "Kasthuri Naickenpalayam Region",
          "VOC Park and Zoo, Coimbatore",
          "Anamalai Tiger Reserve--Topslip",
          "Jaganari Slopes",
          "Puravipalayam Grassland",
          "Karamadai Kuttai (Ã Â®â€¢Ã Â®Â¾Ã Â®Â°Ã Â®Â®Ã Â®Å¸Ã Â¯Ë† Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Singanallur Railway Junction",
          "Kurumbanoor, Tamil Nadu, IN (11.28, 76.924)",
          "Achipatti Region",
          "Chinnakkuttai (Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Kavarkal to Iyerpadi",
          "Karattumedu (Ã Â®â€¢Ã Â®Â°Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â)",
          "Achankulam",
          "Valparai Plateau",
          "Sri Avinashilingam Education Trust Instituitions",
          "Periyakuttai (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Narasipuram Forest Checkpost",
          "Special Economic Zone,Coimbatore",
          "Kovai Hills Grass Land",
          "Kallamadai Thottam Grassland",
          "PAP CANAL",
          "Bhavani River Bank, Nellithurai",
          "Terminalia Arjuna (Ã Â®Â¨Ã Â¯â‚¬Ã Â®Â°Ã Â¯Â Ã Â®Â®Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿/Ã Â®Â®Ã ",
          "Senjerimalai",
          "Muthugoundanur Govt High School",
          "Narasipuram--General Area (OBI)",
          "Countour Canal Road",
          "Bedathapuram Hill, Karamadai",
          "Veerappagoundanur",
          "Coimbatore Railway Junction",
          "Vadambacheri Outskirts",
          "Kadathur Kulam (Ã Â®â€¢Ã Â®Å¸Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Yuvabharathi Public School",
          "Mettupalayam (OBI)",
          "The Western Ghats International School, Ettimadai",
          "Waterfalls to Kavarkal",
          "LGB Nagar",
          "Akkamalai forest gate, Valparai",
          "Chinna Kallar, Valparai",
          "Tamil Nadu Agricultural University (TNAU)",
          "Coimbatore District Small Industries Association (CODISSIA)",
          "Amrita School of Engineering",
          "Kittampalayam Grassland",
          "Vaidyanatha Swamy Temple Lake,Sulur",
          "Sethumadai (Town)",
          "Forest College Campus, Coimbatore",
          "Velliangiri Temple Path",
          "Periyakallar Village",
          "Soolakkal Grasshland",
          "Nandankarai Check Dam, Sadivayal",
          "Kolarampathy Lake",
          "Govt. High School, Marappalam",
          "Muddahalli, Nanjangud Taluka Ã Â²Â®Ã Â³ÂÃ Â²Â¦Ã Â³ÂÃ Â²Â¦Ã Â²Â¹Ã Â²Â³Ã Â³ÂÃ Â²Â³Ã",
          "Ponnuthu",
          "Government Higher Secondary School, Arasur",
          "Attakatti",
          "Kinathukadavu Grassland",
          "Mungilmadai Kuttai",
          "Bharathiyar University (Ã Â®ÂªÃ Â®Â¾Ã Â®Â°Ã Â®Â¤Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â²Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â´Ã Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Puliyangaradu (Ã Â®ÂªÃ Â¯ÂÃ Â®Â²Ã Â®Â¿Ã Â®Â¯Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Å¸Ã Â¯Â)",
          "Forest college to Kotagiri 1st HB",
          "Madheswaran Hills Grassland",
          "Vachinampalayam",
          "Vedapatti Lake",
          "Sengulam Lake, Coimbatore",
          "Karunya University",
          "Valparai--Thalanar Road",
          "Karian Shola Indira Gandhi Wildlife Sanctuary and National Park",
          "Forest Hill Academy",
          "Kulathuppalayam Kuttai",
          "Compost Yard, Vellalore",
          "Kannampalayam Grasslands",
          "Kanamanaikanur Outskirts",
          "Rangampalayam, Grass Hills",
          "Agrahara Samakulam (Ã Â®â€¦Ã Â®â€¢Ã Â¯ÂÃ Â®Â°Ã Â®Â¹Ã Â®Â¾Ã Â®Â° Ã Â®Å¡Ã Â®Â¾Ã Â®Â®Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Feeder Canal Road",
          "Coconut Research Station, Aliyar",
          "PSG College of Arts & Science",
          "Palamalai Hills",
          "Puthu Thottam Estate, Valparai",
          "Aliyar Dam Viewpoint",
          "Sacred Heart Matric Higher Secondary School",
          "Hornbill View Point",
          "Ramapattinam Village",
          "Jadayampalayam Pudur Village - Farmlands and Wetlands",
          "Mathipalayam Foothills",
          "Karpagam Institutions, Coimbatore",
          "Kondanoor, Anaikatti",
          "Nallamudi View Point",
          "Bungalow Medu",
          "Podanur Railway Station",
          "Varagaliyar Elephant Camp",
          "Anamalai Orchidarium, Attakatti",
          "Narasampathi Lake",
          "Sundakkamuthur Area",
          "Government Boys Higher Secondary School, Thondamuthur",
          "Satchidananda Jothi Nikethan International School",
          "Belladi Lake",
          "Aathupollachi (Ã Â®â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯Å Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¾Ã Â®Å¡Ã Â¯ÂÃ Â®Å¡Ã Â®Â¿)",
          "Vellakinar pond",
          "Semmandampalayam Tank",
          "Government Higher Secondary School, Alandhurai",
          "Kovai Kutralam",
          "Muniyappan Kovil Kulam",
          "Kallar Reserve Forest",
          "Valankulam Lake",
          "Nanjundapuram Area",
          "Othimalai",
          "Vettaikaranpudur (Town)",
          "Krishnampathi Lake, Seeranaickenpalayam",
          "Chinnavedampatti Lake - General Area (Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®ÂµÃ Â¯â€¡Ã Â®Â¤Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿ Ã Â®â€¢Ã Â®Â°Ã Â¯Ë†)",
          "Kallar Horticultural Garden",
          "Thudiyalur Railway Station",
          "Petthikuttai lake",
          "Anaikatti Road",
          "Thengallkaradu (Ã Â®Â¤Ã Â¯â€ Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Å¸Ã Â¯Â)",
          "Kaliyapuram Lake",
          "Selvampathy Lake",
          "STM Bungalow,Valparai",
          "PSG Sarvajana Higher Secondary School, Coimbatore",
          "TNAU Botanical Gardens, Coimbatore",
          "Siruvani Veethi",
          "Upper Sholayar Dam",
          "Chinnampathy Road",
          "Monkey Falls",
          "Sri Krishna Arts And Science College",
          "Nirmala College for Women",
          "Disha School Backside",
          "Peelamedu Railway Station",
          "Kumaraguru College of Technology",
          "Irugur Railway Junction",
          "Avinashilingam Engineering Campus, Varappalayam",
          "Pappampatti Grassland",
          "Bharathappuzha River, TN Side",
          "Solai Malai (Ã Â®Å¡Ã Â¯â€¹Ã Â®Â²Ã Â¯Ë† Ã Â®Â®Ã Â®Â²Ã Â¯Ë†)",
          "NRI Gardens,Cheran MaNagar",
          "Mannarkkad Forest Division--Kerelamedu towards Siruvani Dam",
          "Sulur Lake, Coimbatore",
          "Chettipalayam Tank (Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Sivanadapuram",
          "Salim Ali Centre for Ornithology and Natural History (SACON)",
          "Kammalapatti Kuttai",
          "Anna University Regional Campus, Coimbatore",
          "Nayakkanur Pond",
          "Mulli Checkpost",
          "1st HB to Loam's View",
          "Sappanimadai Kuttai",
          "Keeranatham Tank",
          "Bharathapuzha -- Ambarampaayam",
          "Chinna Vedampatti Lake (Ã Â®Å¡Ã Â®Â¿Ã Â®Â©Ã Â¯ÂÃ Â®Â©Ã Â®ÂµÃ Â¯â€¡Ã Â®Å¸Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Zamin Kaliyapuram Village Area",
          "Gandhavayal, Near Lingapuram, Sirumugai",
          "Ukkadam Big Tank, Coimbatore",
          "Mudis Pond, Valparai",
          "Govt High School--Rottikadai",
          "Anaikatti, Coimbatore",
          "Govindanur",
          "Kurichi Kulam",
          "Kunjapannai Forest RF",
          "Irugur Railway Station",
          "Thandikai Aranganathar Temple",
          "Kalappa Naicken Palayam Grasslands",
          "Sennamalai Murugan Temple",
          "Walayar Reserve Forest--Chenniampathi",
          "Flushing Meadows, Globus Garden, Kaikolapalayam, Tamil Nadu",
          "Karpagam College of Engineering",
          "Unkal Kere Ã Â²â€°Ã Â²Â£Ã Â²â€¢Ã Â²Â²Ã Â³ÂÃ¢â‚¬Å’ Ã Â²â€¢Ã Â³â€ Ã Â²Â°Ã Â³â€ Â¯Ë†)",
          "Vellanaipatti Grassland",
          "Kaundanur Pond",
          "Valankulam--Chinna Kulam",
        ],
      },
      {
        district: "Madurai",
        localities: [
          "Agricultural College and Research Institute, Madurai",
          "American College, Madurai",
          "Anaiyur Tank, Madurai",
          "Arittapatti Range, Madurai",
          "Arumbanur Irrigation Tank",
          "Avaniyapuram Lake",
          "Avaniyapuram Reservoir / Iyethanendal Lake",
          "Chathuragiri",
          "Dhan Academy",
          "E. Gopalapuram (Uthapuram) Madurai",
          "Foothills of South Andipatti Hill Range near Vadakkupati",
          "Grizzled Squirrel WLS--Saptur RF Foothills",
          "Grizzled Squirrel WLS--Saptur RF (Shiva temple path)",
          "Grizzled Squirrel WLS--Yanaikasam to Kannimar Kovil, Saptur RF",
          "Harveypatti Tank",
          "Heritage Hotel, Melakkal Road,  Madurai",
          "Idaiyapatti Neer Kadambam Grove",
          "Irrigation Tank, Airport Ring Road, Madurai",
          "Kakkan Memorial",
          "Kakka Oothu , Nagamalai Hills, Madurai",
          "Kamaraj College of Engineering & Technology (KCET)",
          "Kambur",
          "Karisal Kulam Tank",
          "Kathakinaru",
          "Keelakuyilkudi Hill",
          "Kilakuyilkudi Kualm",
          "Kiluvamalai",
          "Kodikkulam tank, Melakkal, Madurai Dt",
          "Kombu Thooki Ayyanar Koil Sacred Grove, Kesampatti",
          "Koothiyarkundu Kanmai",
          "Kovilaru Dam, Karungulam",
          "Kovilpapakudi Tank",
          "Kulamangalam Tank",
          "Kunnathur Tank",
          "Kuruvithurai Guru Bhagwan Temple",
          "Lady Doak College",
          "Lake near Jain Vidayalya",
          "Maan Thalai Ayyanar Koil Sacred Grove, Kadumittanpatti",
          "Madakkulam",
          "Madura College",
          "Madurai Airport",
          "Madurai Kamaraj University",
          "mahatma school Baba Building , madurai, tamilnadu 625007",
          "Malaiyurani Sacred Grove, Sivarakottai",
          "Malaiyurani, Sivarakottai, Madurai Dist, Tamilnadu",
          "Mallapuram",
          "Manjamaliyan Kovil",
          "Maruthur Tank",
          "Meenakshipuram , Back side of Arittapatti Range",
          "Melakkal Tank",
          "Mettuneerthan Kanmai",
          "mottanoothu",
          "Mottanuthu General area",
          "Mullakulam Pond (Ã Â®Â®Ã Â¯ÂÃ Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯Ë† Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Naagar Theertham",
          "Nagamalai",
          "Nathamedu Lake (Ã Â®Â¨Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â®Ã Â¯â€¡Ã Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)Â£Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â¯Ã Â¯Â)",
          "Nesaneri Kanmai",
          "Perungudi",
          "Poonchunai field range, Tamil Nadu, IN (10.086, 78.28)",
          "Poondi Lake",
          "PTR COLLEGE OF ENGINEERING AND TECHNOLOGY ",
          "Pulloothu, Nagamalai Hills, Madurai",
          "Puthur Malai",
          "Railway Colony,Madurai",
          "Samanatham Tank",
          "Samayanallur/ Thodaneri Kulam",
          "Sathaiar Dam",
          "Sellur Kanmai",
          "Sengulam Kanmaai, Kalligudi",
          "Sevugan Nagar open Terrain",
          "Sikkandhar Chavadi Lake",
          "Sivarakkottai",
          "Somagiri Malai Range-field",
          "Sourashtra College, Pasumalai",
          "sundararajanpatti",
          "Suryanagar- ( Irrigation Tank to the east )",
          "Temporary Sewage collection pond ( ? Kilangkulam ) 8 km south of",
          "Thenkarai Pond (Lake or Tank), Sholavandan, Madurai",
          "Thenkarai Pond / Lake, Sholavandan",
          "Thiagarajar Arts College canteen Pond",
          "Thiagarajar College",
          "Thidiyanmalai Hill",
          "Thirali Tank, near alampati, Thirumangalam, Madurai dist, TN",
          "Thirukoodal malai --Thirupparankundram",
          "Thirumoor Lake, (Puthu Thamaraipatti )",
          "Thiruparankundram Tank / Thenkarai Pond",
          "Thodaneri Tank",
          "Thonthi Karuppasamy Kovil, Silambakonpatti, Kottampatti",
          "Thuvarimaan Kanmai, Thuvarimaan, Madurai",
          "TVS Sundaram Higher Secondary School",
          "Urapanur Kanmaai (Ã Â®â€°Ã Â®Â±Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â¯Ã Â¯Â)",
          "Vadakarai Tank, Tamil Nadu 625205, Thirumangalam-Pallapatti Road",
          "Vadakarai tank, Tirumangalam",
          "Vagaikulam Tank",
          "Vandiyur Lake",
          "Veeran Kovil, Pallapatti",
          "Vellaripatti Irrigation Tank",
          "Vellimalayan Kovil, Kolinchipatti",
          "Viraganoor Regulator/ Dam on River Vaigai",
          "Virathanoor Lake or Tank, Madurai district, Tamil Nadu 625022, SH 195",
          'Western slopes of Alagarkovil Range from "Hawa valley Resort "   - 10.081x78.179',
          "Yaanaimalai Hill (Ã Â®Â¯Ã Â®Â¾Ã Â®Â©Ã Â¯Ë†Ã Â®Â®Ã Â®Â²Ã Â¯Ë†)",
        ],
      },
      {
        district: "Tiruppur",
        localities: [
          "Alagumalai Temple",
          "Alagu Thirumalairaya Perumal Kovil",
          "Alangiam Canal, Dharapuram",
          "Amaravathi Dam",
          "Amaravathi River, Dharapuram",
          "Amaravathi River, Dharapuram - Alangiyam Road",
          "Amaravathi River, Madathupalayam",
          "Amaravathi River, Peramium",
          "Amaravathi River, Sankarandampalayam",
          "Amaravathy River, New Bypass Road Bridge, Dharapuram.",
          "Amaravathy River, Thillapuri Amman Temple, Dharapuram",
          "Anaikkattu, Amaravathi River, Dharapuram",
          "Anamalai Tiger Reserve--Topslip",
          "Andipalayam Lake, Tirupur",
          "Bhagawan Kovil Road, Dharapuram",
          "Cambridge Matriculation Higher Secondary School, Avinashi",
          "Chinna Kodangipalayam Grassland",
          "Chinnar-Udumalapet Check post",
          "Chinnar Wildlife Sanctuary--Chinnar",
          "Chinnar Wildlife Sanctuary, Tiruppur",
          "Chinnar WLS - Tamil nadu side",
          "Forest Check Post (Udumalpet)",
          "Ganapathi Nagar, Dharapuram",
          "Ganapathy Palayam Area",
          "Government High School, Poomalur, Tiruppur",
          "Jallipatti Lake",
          "Kadathur Archuneswarar Temple and Surrounds",
          "Kaliamman Kovil Pond",
          "Karian Shola NP, Anamalai",
          "Kongur Lake",
          "Kumar Nagar, Tiruppur, Tamil Nadu, India",
          "Kuttapalayam (Village)",
          "Lower Bhavani Project Canal, Chennimalai",
          "LRG Govt Arts College for Women",
          "Manikapuram Kulam",
          "Manjampatti-Chinnar Trek",
          "Manjampatti Valley--Indira Gandhi National Park",
          "Nallathangal Odai Reservoir, Dharapuram",
          "Nambiyampalayam",
          "Nanjarayan Tank Bird Sanctuary (Koolipalayam Reservoir), Tiruppur Ã Â®Â¨Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â®Â°Ã Â®Â¾Ã Â®Â¯Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â®Â±Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â®Â³Ã Â¯Â Ã Â®Å¡Ã Â®Â°Ã Â®Â£Ã Â®Â¾Ã Â®Â²Ã Â®Â¯Ã Â®Â®Ã Â¯Â, Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€šÃ Â®Â°Ã Â¯Â",
          "Nanjegoundenputhur Area",
          "Orathupalayam Reservoir",
          "Ottu Kulam, Udumalpet",
          "Periya Kulam, Udumalpet",
          "Pongalur Grassland",
          "Pudhupalayam Tank (Ã Â®ÂªÃ Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Pungan odai, Anamalai Tiger Reserve",
          "Rakkiyapalayam Kulam",
          "Ramaiyampalayam Kulam",
          "Sainik School Amaravathinagar",
          "Sainik School, Amaravathinagar Campus",
          "Samalapuram Kulam",
          "Samalapuram Lake, Somanur",
          "Sayapattarai Karadu",
          "Sengulam Lake",
          "Sinnegoundanpudhur Grassland",
          "Thennampalayam Pond",
          "Thirumoorthy Waterfalls Trail",
          "Thumbalapatti Karadu",
          "Tiruppur Outskirts",
          "Tiruppur Railway Station",
          "Uppar Dam",
          "Upparu River, Alampalayam, Dharapuram",
          "Uthiyur Hillock",
          "Uthuppalayam Reserve Forest, Dharapuram",
          "V Vellur Pond",
        ],
      },
      {
        district: "Cuddalore",
        localities: [
          "Annamalai University Campus Ã Â®â€¦Ã Â®Â£Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®Â®Ã Â®Â²Ã Â¯Ë† Ã Â®ÂªÃ Â®Â²Ã Â¯ÂÃ Â®â€¢Ã Â®Â²Ã Â¯Ë†Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â´Ã Â®â€¢ Ã Â®ÂµÃ Â®Â³Ã Â®Â¾Ã Â®â€¢Ã Â®Â®Ã Â¯Â",
          "Athirikuppam Pond (Ã Â®â€ Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â®Ã Â¯Â Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â )",
          "Ã Â¤Â°Ã Â¤Â¾Ã Â¤Å“Ã Â¤Â¸Ã Â¥ÂÃ Â¤Â¥Ã Â¤Â¾Ã Â¤Â¨, IN (27.386, 75.122)",
          "Bahour Lake--TN Side (Ã Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿--Ã Â®Â¤Ã Â®Â®Ã Â®Â¿Ã Â®Â´Ã Â¯ÂÃ Â®Â¨Ã Â®Â¾Ã Â®Å¸Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿)",
          "Chidambaram Bus Stand & Surrounds Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂªÃ Â¯â€¡Ã Â®Â°Ã Â¯ÂÃ Â®Â¨Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â & Ã Â®Å¡Ã Â¯ÂÃ Â®Â±Ã Â¯ÂÃ Â®Â±Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Gadilam Estuary",
          "Government Cuddalore Medical College and Hospital (Rajah Muthiah Medical College and Hospital), Chidambaram",
          "Hayagriva Temple",
          "Irumbai Lake (Ã Â®â€¡Ã Â®Â°Ã Â¯ÂÃ Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â¯Ë† Ã Â®ÂÃ Â®Â°Ã Â®Â¿) Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Kadamba Depot Road, Penh¯â‚¬Ã Â®Â°Ã Â®Â¾Ã Â®Â£Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Krishnapuram Lake (Ã Â®â€¢Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®Â·Ã Â¯ÂÃ Â®Â£Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "KSB farms",
          "Lakshmi Vilas Hotel, T. Neduncheri",
          "Mangrove Bay Ecocamp, Kanakkarapattu",
          "Marudadu Pond",
          "Meedhukudi Road",
          "Muzhukkuthurai (Killai)",
          "Nallathur Lake (Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Pallipattu Lake (Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Perumal Lake",
          "Pichavaram Mangrove Forest",
          "Rasapalayam Lake (Ã Â®Â°Ã Â®Â¾Ã Â®Å¡Ã Â®Â¾Ã Â®ÂªÃ Â®Â¾Ã Â®Â³Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "River Vadavaru, Near Veeranam Lake",
          "Singarakuppam (Ã Â®Â¨Ã Â®Å¾Ã Â¯ÂÃ Â®Å¡Ã Â¯Ë†Ã Â®Â®Ã Â®â€¢Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂµÃ Â®Â¾Ã Â®Â´Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Ë†)",
          "Sri Chithramuthu Adigalar Aruloli Gnana Nilayam, Kambalikarankuppam (Ã Â®Â¸Ã Â¯ÂÃ Â®Â°Ã Â¯â‚¬ Ã Â®Å¡Ã Â®Â¿Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯Â Ã Â®â€¦Ã Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â®Â³Ã Â®Â¾Ã Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â°Ã Â¯ÂÃ Â®Â³Ã Â¯Å Ã Â®Â³Ã Â®Â¿ Ã Â®Å¾Ã Â®Â¾Ã Â®Â© Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â¯Ë†Ã Â®Â¯Ã Â®Â®Ã Â¯Â)",
          "Tholudhur Checkdam",
          "Thookkanaampaakkam Lake (Ã Â®Â¤Ã Â¯â€šÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â£Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Tiruppanaambakkam Lake (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â£Ã Â®Â¾Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â¾Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Ulleripattu Lake (Ã Â®â€°Ã Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Usuppur",
          "Vadavadi Lake, Tamil Nadu, IN (11.56, 79.311)",
          "Veedur Dam (Ã Â®ÂµÃ Â¯â‚¬Ã Â®Å¸Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¦Ã Â®Â£Ã Â¯Ë†) Â®Â°Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Veeranam Lake (Ã Â®ÂµÃ Â¯â‚¬Ã Â®Â°Ã Â®Â¾Ã Â®Â£Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Veeranam Lake - Vazhakollai",
          "Vellar River Bridge",
          "Wellington Lake, Thittakudi",
        ],
      },
      {
        district: "Vellore",
        localities: [
          "Alamelumangapuram Village",
          "Amirthi forest",
          "AMIRTHI WATERFALL",
          "Bahadurpalli River, Pernambut",
          "Balamathi Hills, Vellore",
          "Bathalapalli--Malattaru River Bridge",
          "Christian Medical College Campus, Bagyam, Vellore (OBI)",
          "Christian Medical College (CMC), Vellore",
          "Janullah shah Hill, Pernambut",
          "Kalabairavar Temple",
          "Karigiri Hospital",
          "Katpadi Lake / Gopal Samuthram",
          "Koundinya Wildlife Sanctuary",
          "Murukkambattu, Kolaimedu",
          "Palar River, Gudiyatham",
          "Palar River, Shenbakkam, Vellore",
          "Palavanatham , Santhaimedu , K V Kuppam",
          "Sathgar Hill,Pernampet",
          "Sri Theerthagiri Vadivel Subramanyar Temple",
          "Thuthipet",
          "Vallimalai Hillock",
          "Vallimalai Temple pond",
          "Vellore Fort Round Road,",
        ],
      },
      {
        district: "Nagapattinam",
        localities: [
          "A.V.C College Campus, Mannampandhal",
          "BNHS Bird Migration Study Centre, Kodiyakarai",
          "Keeran Lake",
          "Kodiyakarai Fishing Boat Jetty",
          "Kollidam River, Aanaikaaran Chathiram",
          "Mallarpettai Village",
          "Manangkondan River",
          "Manangkondan River, Adhanur",
          "Muthupettai Lagoon",
          "Pazhaiyar Beach",
          "Point Calimere-Rettai Thevu",
          "Point Calimere Wildlife Sanctuary",
          "Point Calimere Wildlife Sanctuary--Dry Evergreen forest",
          "Point Calimere Wildlife Sanctuary--Grassland",
          "Point Calimere Wildlife Sanctuary--Mudflats",
          "Point Calimere Wildlife Sanctuary--Muniyappan Lake",
          "Point Calimere Wildlife Sanctuary--Saltpans",
          "PPN Power Plant",
          "PPN Power Plant, Sea Water Intake Area & Grasslands",
          "Ramar Patham (Ã Â®â€¡Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®Â¾Ã Â®Â¤Ã Â®Â®Ã Â¯Â)",
          "Sewage Treatment Plant, Mannampandal",
          "Thirunagari",
          "Thondiyakkadu Wildlife Sanctuary",
          "Tirumullaivasal Fishing Harbour",
          "Vaitheeswarankoil-- East Street",
          "Vettaikaraniruppu Saltpans",
        ],
      },
      {
        district: "Chennai",
        localities: [
          "Elliots Beach",
          "Lalaji Memorial Omega International School",
          "Kalakshetra Colony",
          "IIMSC Campus, Chennai (OBI)",
          "IITM--Madras Avenue",
          'Theosophical Society-- Damodar Gardens [old "The School-KFI campus]',
          "Sishya School",
          "Adayaru Creek--Foreshore Estate End",
          "Presidency College, Chennai",
          "Pallikaranai Marsh--NIOT end",
          "Uthandi - ECR",
          "Kottivakkam Beach",
          "Royapettah Clock Tower",
          "Dharmambal Polytechnic",
          "Perumbakkam Lake",
          "Velachery Lake",
          "Annakkili Amma Research Institute--(Restricted access)",
          "Adyar River--Saidapet (Ã Â®â€¦Ã Â®Å¸Ã Â¯Ë†Ã Â®Â¯Ã Â®Â¾Ã Â®Â±Ã Â¯Â Ã Â®â€ Ã Â®Â±Ã Â¯Â-- Ã Â®Å¡Ã Â¯Ë†Ã Â®Â¤Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯â€¡Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Ë†)",
          "Sithalapakkam",
          "MEASI",
          "Kailash Nagar",
          "Adayaru Estuary",
          "Loyola College",
          "St Thomas Mount",
          "Arihant marsh",
          "Anna Walkers Park, Kodambakkam",
          "Palavakkam",
          "Butt Road-Paul Wells Road Junction",
          "Chennai Airport-- Terminal building",
          "Pallikaranai-- Marshland Park",
          "Marakayar Nagar",
          "Amir Mahal, Royapettai",
          "Kallu Kuttai Lake",
          "Tholkappiar Ecological Park",
          "Pallikaranai Marsh--(Sholinganallur End) Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Â£Ã Â¯Ë† Ã Â®Å¡Ã Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â®Â®Ã Â¯Â--(Ã Â®Å¡Ã Â¯â€¹Ã Â®Â³Ã Â®Â¿Ã Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¨Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®Â®Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂµÃ Â¯Â))",
          "Kattaboman Kulam",
          "Adyar river, Back sideof YMCA ground and AU,Kotturpuram.",
          "Simpsons Estate, Sembium",
          "Madras Veterinary College-- Boys Hostel (Restricted)",
          "Pallikaranai Marsh-- Karapakkam (North end)",
          "Pallikaranai Marsh (Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Â£Ã Â¯Ë† Ã Â®Å¡Ã Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â®Â®Ã Â¯Â)",
          "VOC Nagar",
          "Kalakshetra Campus",
          "Pallavaram Forest",
          "Indian Maritime University",
          "Veteran Lines-- No Man's Land",
          "Tirusulam eri",
          "Guindy NP",
          "Adayar River Trail-- Manapakkam",
          "Tirusulam Hillock",
          "Theosophical Society",
          "Theosophical Society-- Damodar Gardens [old The School-KFI campus]",
          "Chennai--Fishing Harbour-N4 Beach",
          "Besant Nagar-- Metro Water Grounds",
          "Meenambakkam Rly Station",
          "Indira Nagar--Adyar",
          "Adayar River, Cowl Bazar",
          "Buckingham Canal, ECR to OMR Road (ISKCON)",
          "Adayaru--Elphinstone (Old) Bridge",
          "Kanathur",
          "Thiruvanmiyur",
          "Pallikaranai Land fill",
          "Besant Nagar-- 1st Cross Street",
          "Vettuvankeni",
          "Adambakkam Lake",
          "Opposite La Celeste - Porur",
          "Government Museum Chennai",
          "Pallikaranai Marshland-- Perungudi end (12.965, 80.23)",
          "Women's Christian College (WCC)",
          "Buckingham Canal--Park Town",
          "The Farm, Semmancheri",
          "D.G.Vaishnav college",
          "Lotus Pond, Pallikaranai",
          "Shri Ram Chandra Mission, World Headquarters, Chennai",
          "Sholinganallur Lake",
          "Kovilambakkam Lake",
          "Injambakkam",
          "Pallikaranai Marsh--Midlands Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Â£Ã Â¯Ë† Ã Â®Å¡Ã Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â®Â®Ã Â¯Â-- Ã Â®Â¨Ã Â®Å¸Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
          "Tech Mahindra Campus, Elcot SEZ",
          "Pallikaranai Marshland (South)",
          "Narayanapuram Lake",
          "Kotturpuram Urban Forest",
          "Adayar River Trail-- Nandambakkam",
          "Ramakrishna Mission Vivekananda College, Mylapore",
          "M.CT.M. Chidambaram Chettyar International School",
          "Panagal Park--T.Nagar (Ã Â®ÂªÃ Â®Â©Ã Â®â€¢Ã Â®Â²Ã Â¯Â Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾--Ã Â®Â¤Ã Â®Â¿Ã Â®Â¯Ã Â®Â¾Ã Â®â€¢Ã Â®Â°Ã Â®Â¾Ã Â®Â¯ Ã Â®Â¨Ã Â®â€¢Ã Â®Â°Ã Â¯Â)",
          "Chennai Airport-- North-east end",
          "Adayaru Creek--South Canal Bank Rd. Junction",
          "Marina Beach, near Dr.MGR Memorial",
          "Manali Grounds",
          "Pallikaranai Marsh--Karapakkam",
          "Semmancheri--OMR",
          "IIT Madras (Restricted Access)",
          "Gopathi Narayanaswami Park",
          "CLRI Marsh",
          "Island Grounds, Chennai",
          "Puzhuthivakkam Lake,Chennai",
          "Kendriya Vidyalaya, Meenambakkam",
          "Juhu Beach Range, Chennai",
          "Sholinganallur/Perumbakkam Marsh (Western end)",
          "Pallikaranai Marsh--Southwest Watchtower (Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Â£Ã Â¯Ë† Ã Â®Å¡Ã Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â®Â®Ã Â¯Â - Ã Â®Â¤Ã Â¯â€ Ã Â®Â©Ã Â¯ÂÃ Â®Â®Ã Â¯â€¡Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®â€¢Ã Â®ÂµÃ Â®Â©Ã Â®Â¿Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂªÃ Â¯ÂÃ Â®Â°Ã Â®Â®Ã Â¯Â)",
          "YMCA Ground (Ã Â®â€™Ã Â®Â¯Ã Â¯Â.Ã Â®Å½Ã Â®Â®Ã Â¯Â.Ã Â®Å¡Ã Â®Â¿.Ã Â®Â. Ã Â®Â®Ã Â¯Ë†Ã Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â®Â®Ã Â¯Â)",
          "Kottivakkam",
          "Kotturpuram Bridge",
          "Chetpet Lake -- Eco park (Ã Â®Å¡Ã Â¯â€¡Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿ - Ã Â®Ë†Ã Â®â€¢Ã Â¯â€¹ Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾)",
          "Adayaru Creek--Karpagam Avenue Bridge",
          "Periyar Bridge Chintadripet Station",
          "Anna University Campus",
          "Panaiyur",
          "Sholinganallur/Perumbakkam Marsh-- Embassy Residency end",
          "Akkarai Beach",
          "Ram Nagar Swamps, Madipakkam",
          "Pallikaranai Marsh- East/West Path",
          "Olcott Memorial School",
          "Queen Mary's College (Ã Â®Â°Ã Â®Â¾Ã Â®Â£Ã Â®Â¿ Ã Â®Â®Ã Â¯â€¡Ã Â®Â°Ã Â®Â¿ Ã Â®â€¢Ã Â®Â²Ã Â¯ÂÃ Â®Â²Ã Â¯â€šÃ Â®Â°Ã Â®Â¿)",
          "University of Madras, Chennai",
          "Mylapore--General Area (OBI)",
          "Kumaran Nagar",
          "Kalakshetra Foundation",
          "Adayaru--Thiru-Vi-Ka (New) Bridge",
          "Unnis Ali street",
          "Netaji Nagar Agricultural Layout",
          "Adyar River View point",
          "Medavakkam Eri",
          "Valmiki Nagar Beach & Thickets",
          "Chennai Airport-- Primary Runway",
          "Guindy Snake Park",
          "Neelankarai",
          "Officers Training Academy",
          "Arasankazhani Lake",
          "DLF Ramapuram",
          "Manapakkam-- Marvel Riverview",
          "Cowl Bazar",
          "KCG College of Technology",
          "Anna Salai--LIC Building End",
          "Anna Centenary Library",
          "Pallikaranai - Eastern side",
          "Adyar river",
          "Pallikaranai Marsh-- Southern end (Ã Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â®Â£Ã Â¯Ë† Ã Â®Å¡Ã Â®Â¤Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â¯Â Ã Â®Â¨Ã Â®Â¿Ã Â®Â²Ã Â®Â®Ã Â¯Â - Ã Â®Â¤Ã Â¯â€ Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â¯Â Ã Â®Â®Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®ÂµÃ Â¯Â)",
          "Marina Beach start",
          "Ethiraj College for Women",
          "Madras Veterinary College--Department of Wildlife Science",
          "Senganthal Poonga",
          "Sholinganallur/Perumbakkam Marshlands",
          "Stella Maris College",
          "IIT Madras--SAC (Restricted Access)",
          "Kasimedu Fishing Harbour",
          "Gerugambakkam",
          "Perungudi lake",
          "Kendriya Vidyalaya DGQA",
          "Thirisulam Railway Station",
          "Adayar River-- Buckingham Canal Junction",
        ],
      },
      {
        district: "Tirupathur",
        localities: [
          "Agasthiar Maha Pyramid Kshethram",
          "Alangayam Lake",
          "Aneri Lake",
          "Chennapamalai, Ambur",
          "Jalagamparai Water Falls Area",
          "Jolarpettai Railway Junction",
          "Kailasagiri Hill",
          "Nilavoor Lake, Yelagiri",
          "Sacred Heart College, Tirupathur",
          "Tirupathur Big Lake - Janaki Ponnusamy School Side",
          "Tirupattur Big Lake - Bus Stand Side",
          "Vainu Bappu Observatory, Kavalur",
          "Yelagiri hills",
          "Yelagiri Lake",
          "Yelagiri-- Mangalam Village",
          "Yelagiri Nature Park",
          "Yelagiri--Nilavoor Road",
          "YMCA Camp, Yelagiri",
        ],
      },
      {
        district: "Ramanathapuram",
        localities: [
          "Alathu Muniyasamy Temple",
          "Ammanpuram Beach",
          "Arichalmunai Beach",
          "Chithrankudi Bird Sanctuary",
          "CMFRI, Mandapam",
          "Devaneri",
          "Dhanushkodi Beach",
          "Dhanushkodi-- Point 2",
          "Dhanushkodi-- Point 3",
          "Gulf of Mannar Marine National Park",
          "Kanjirankulam Bird Sanctuary",
          "Karankadu Mangroves",
          "Keela kanmai, Pinnalankottai",
          "Kothandaramar Temple (Ã Â®â€¢Ã Â¯â€¹Ã Â®Â¤Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â°Ã Â®Â¾Ã Â®Â®Ã Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â¯â€¹Ã Â®ÂµÃ Â®Â¿Ã Â®Â²Ã Â¯Â)",
          "Krusadai Island, Rameswaram, Tamilnadu",
          "Marine Museum and Vivekananda Memorial",
          "Melamundhal Beach",
          "Melaparthibanur Tank",
          "Melselvanur-Keelselvanur Bird Sanctuary",
          "Mookaiyur Beach (Ã Â®Â®Ã Â¯â€šÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯Ë†Ã Â®Â¯Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®â€¢Ã Â®Å¸Ã Â®Â±Ã Â¯ÂÃ Â®â€¢Ã Â®Â°Ã Â¯Ë†)",
          "Mukundaryar Chathiram",
          "Narippaiyur Beach",
          "Narippaiyur Wetlands",
          "Olaikadu Beach, Rameswaram",
          "Olaikuda, Rameswaram, Tamilnadu",
          "Paalai Park (Ã Â®ÂªÃ Â®Â¾Ã Â®Â²Ã Â¯Ë† Ã Â®ÂªÃ Â¯â€šÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾)",
          "Pamban Bridge",
          "Periya Kanmai",
          "Pudumadam",
          "Pullangudi Saltpans",
          "Ramanathapuram Big Tank",
          "Sakkarakottai Kanmaai",
          "Sethukarai Backwaters & Thorn Forest",
          "Sevalkaadu (Ã Â®Å¡Ã Â¯â€ Ã Â®ÂµÃ Â®Â²Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Å¸Ã Â¯Â) Scrub Forest",
          "Sikkal Kanmai",
          "Somanathapuram Wetlands",
          "Thavasikuruchi Tank",
          "Therthangal Bird Sanctuary",
          "Vaigai Tharavai",
          "Valinokkam, Ramnad Dist",
          "Vedalai Backwater",
        ],
      },
      {
        district: "Sivagangai",
        localities: [
          "Andakudi Kanmai",
          "Arasanoor TanK",
          "Athalai Kanmai, Karaikudi",
          "Athikadu Thekkur Kulam",
          "Chettinad (OBI)",
          "Chettiyan Kanmai",
          "Kanadukaathan Kanmai Ã Â®â€¢Ã Â®Â¾Ã Â®Â©Ã Â®Â¾Ã Â®Å¸Ã Â¯ÂÃ Â®â€¢Ã Â®Â¾Ã Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â®Â¾Ã Â®Â©Ã Â¯Â Ã Â®â€¢Ã Â®Â£Ã Â¯ÂÃ Â®Â®Ã Â®Â¾Ã Â®Â¯Ã Â¯Â",
          "Karai Kanmaai",
          "Karungalikudi Tank",
          "Karuppu Koil Sacred Grove, Keela Vannariruppu, Minnamalaipatti",
          "Kattikulam Lake",
          "Keezhadi Archaeological Excavation Site",
          "Kondhalaiyamman Kulam",
          "Konthagai Lake",
          "Kurichi Tank",
          "Mannakudi Kulam",
          "Maranadu Tank",
          "Mariamman Kovil Tank, Sivagangai",
          "Murugan Chetti Oorani",
          "Periya Kanmaai",
          "Piravalur Kanmai",
          "Piravalur near Kaadu Kaaval Kali Koil",
          "Sadurvadamangalam Kanmai",
          "Seedhali Pond,Tirupattur",
          "Sri Sarada Niketan College for Women",
          "Thirumalai Sri Malaikountheeswarar Temple",
          "Vadakudipatti Kanmai",
          "Vaigai River Bridge, Sakkudi",
          "Valayan Kanmai, Kundrakudi.",
          "Vetriyur (Thirthakulam lake)",
          "Vettangudi Bird Sanctuary",
        ],
      },
      {
        district: "Thoothukudi",
        localities: [
          "Alampatti Lake",
          "Arumugamangalam Lake",
          "Arumuganeri Salt Pans, Thoothukudi Dt",
          "Arunjunai Kaatha Ayyanar Temple Lake",
          "Athoor Tank",
          "Authoor Kulam",
          "Beach Road, Thoothukudi",
          "Chengulam",
          "Hare Island",
          "Kadambakulam (Ã Â®â€¢Ã Â®Å¸Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Kadmbakulam (Ã Â®â€¢Ã Â®Å¸Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Karungulam",
          "Kaspa Kulam",
          "Korrampallam Tank (Ã Â®â€¢Ã Â¯â€¹Ã Â®Â°Ã Â®Â®Ã Â¯ÂÃ Â®ÂªÃ Â®Â³Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Lakshmipuram Ooruni",
          "Manjal Neer Kayal",
          "Melakulam-- Karungulam (Ã Â®â€¢Ã Â®Â°Ã Â¯ÂÃ Â®â„¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Mel Pudhukudi Sunai",
          "Mel Pudukudi Sunai",
          "Moopanpatti Lake",
          "Nallur Pond-East",
          "Nallur Pond - West",
          "Nallur Pond-West",
          "Pattinamaruthr",
          "Peikulam",
          "Periya Puthan Tharavai",
          "Periya Tharuvai",
          "PeriyaTharuvai Kulam (Ã Â®ÂªÃ Â¯â€ Ã Â®Â°Ã Â®Â¿Ã Â®Â¯ Ã Â®Â¤Ã Â®Â°Ã Â¯ÂÃ Â®ÂµÃ Â¯Ë†)",
          "Perukulam Lake",
          "Perur Kulam",
          "Pudukulam",
          "puthanthiruvai",
          "Sedarapet Lake (Ã Â®Å¡Ã Â¯â€¡Ã Â®Â¤Ã Â®Â°Ã Â®Â¾Ã Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Sivagalai",
          "Sornamalai, Kovilpatti",
          "Tenkarai Kulam",
          "Tenkari Kulam",
          "Thalaivasal Lake Ã Â®Â¤Ã Â®Â²Ã Â¯Ë†Ã Â®ÂµÃ Â®Â¾Ã Â®Å¡Ã Â®Â²Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿Â)",
          "Thandalam Lake (Ã Â®Â¤Ã Â®Â£Ã Â¯ÂÃ Â®Å¸Ã Â®Â²Ã Â®Â®Ã Â¯Â Ã Â®ÂÃ Â®Â°Ã Â®Â¿)",
          "Therikaadu--Arunjunai Kaatha Ayyanar Temple",
          "Therikaadu--Near Karkuvel Ayyanar Temple",
          "Thiruchendur Railway Station",
          "Tuticorin Port (Ã Â®Â¤Ã Â¯â€šÃ Â®Â¤Ã Â¯ÂÃ Â®Â¤Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®â€¢Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿ Ã Â®Â¤Ã Â¯ÂÃ Â®Â±Ã Â¯Ë†Ã Â®Â®Ã Â¯ÂÃ Â®â€¢Ã Â®Â®Ã Â¯Â)",
          "Valasai Bird Sancturay (Ã Â®ÂµÃ Â®Â²Ã Â®Å¡Ã Â¯Ë† Ã Â®ÂªÃ Â®Â±Ã Â®ÂµÃ Â¯Ë†Ã Â®â€¢Ã Â®Â³Ã Â¯Â Ã Â®Å¡Ã Â®Â°Ã Â®Â£Ã Â®Â¾Ã Â®Â²Ã Â®Â¯Ã Â®Â®Ã Â¯Â) (Ã Â®Â¤Ã Â®Â¿Ã Â®Â°Ã Â¯ÂÃ Â®ÂªÃ Â¯ÂÃ Â®ÂªÃ Â®Â£Ã Â®Â¿Ã Â®Å¡Ã Â¯â€ Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â¿Ã Â®â€¢Ã Â¯ÂÃ Â®Â³Ã Â®Â®Ã Â¯Â)",
          "Vallanadu Blackbuck Sanctuary",
          "Vanaramutti Kulam",
          "Velur Kasba Tank",
          "Vembar Backwaters",
        ],
      },
    ],
  },
  {
    state: "Telangana",
    districts: [
      { district: "Jayashankar", localities: [] },
      {
        district: "Sangareddy",
        localities: [
          "Ameenpur Lake, Hyderabad",
          "Anna Sagar Lake",
          "Cheruvu Katta Road, Ganjigudem",
          "ICRISAT Campus",
          "Icrisat Campus Lake",
          "IIT Hyderabad",
          "Jinnaram Medak Range",
          "Jogipet Lake",
          "Kistareddypet Lake (17.551, 78.305)",
          "Mallepally Lake",
          "Manjeera Wildlife Sanctuary",
          "Mogudampally Grasslands",
          "Mokila",
          "Mokila,17.441924, 78.220017",
          "Nagulapallil lake near kollur",
          "Narsapur -- Medalamma Temple",
          "Near Kingfisher Pond",
          "Patancheru Lake",
          "Rudraram Cheruvu",
          "Singur Dam Reservoir, Medak",
          "Singur  Reservoir - Raipally",
          "Tallapally Lake",
        ],
      },
      {
        district: "Warangal Rural",
        localities: ["Pakhala Ashoknagar", "Pakhal Lake"],
      },
      { district: "Suryapet", localities: [] },
      {
        district: "Siddipet",
        localities: [
          "Forest College and Research Institute (FCRI)",
          "Konda Pochamma Reservoir",
          "Sherpally Village, Arbor Road Estate",
        ],
      },
      {
        district: "Peddapalli",
        localities: [
          "Garrepalli Cheruvu",
          "Sri Gowthameeshwara Temple, Manthani",
        ],
      },
      {
        district: "Hyderabad",
        localities: [
          "Andhra Pradesh Horticulture Department",
          "Birla Mandir",
          "Bowrampet",
          "Chacha Nehru Park",
          "domalguda",
          "Golconda Fort",
          "Hakimpet Kunta",
          "Home - Trident Apartments, behind Brand Factory, Attapur",
          "Hussain Sagar, Hyderabad",
          "Indira Park",
          "Jubilee Hills, Hyderabad",
          "Kasu Brahmananda Reddy NP--Chiran Lake",
          "Kasu Brahmananda Reddy NP, Hyderabad",
          "Kinnera Hostel (Backside Trail)",
          "Krishna Kanth Park,Hyderabad",
          "Lotus Pond",
          "Malakpet, Hyderabad",
          "Masab Tank (OBI)",
          "Naya Quila",
          "Nehru Zoological Park",
          "Osmania University",
          "Qutb Shahi Tombs",
          "Sanjeevaiah Park",
          "State Bank Staff College",
          "Takshasila public school",
        ],
      },
      { district: "Jagitial", localities: [] },
      {
        district: "Nizamabad",
        localities: [
          "Chintala Cheruvu - Sirnapally",
          "Kanteshwar, Nizamabad",
          "Laknapur",
          "Mamidipalle, Nizamabad",
          "Manchippa forest/hills",
          "Pedda cheruvu, Rudrur",
          "Pedda Cheruvu, Rudrur",
          "Pedda Cheruvu, Rudrur, Telangana, IN",
          "Rampur Thanda - Sirnapally",
          "Sirnapally Waterfalls",
          "Sriramasagar Reservoir",
        ],
      },
      { district: "Narayanpet", localities: [] },
      {
        district: "Ranga Reddy",
        localities: [
          "Agribiodiversity park, Hyderabad, Telangana, IN (17.318, 78.418)",
          "ANGRAU (Acharya N G Ranga Agricultural University), Hyderabad",
          "BNR Hills",
          "Chikur birding spot",
          "Durgam Cheruvu",
          "Durgam Cheruvu--Secret Lake Park",
          "Durgam Cheruvu--Southern side",
          "Gangaram Cheruvu",
          "Gautami Enclave Park",
          "Himayat sagar IN (17.333, 78.369)",
          "Himayat Sagar Lake",
          "Hyderabad Airport",
          "Hyundai Motor India Engineering Pvt. Ltd",
          "Ibrahim Cheruvu",
          "Ibrahimpatnam Lake",
          "Indian Institute of Rice Research",
          "Indian Institute of Rice Research -Office",
          "Indian School of Business",
          "Injapur Lake",
          "Kanha Shanti Vanam",
          "Khajaguda Lake",
          "Khajaguda Lake (2)",
          "Kokapet Lake",
          "Kondapur Botanical Garden",
          "Kuntloor Cheruvu",
          "Laboratory for the Conservation of Endangered Species (LaCONES)",
          "Lake Kamuni Shamshabad",
          "Madinaguda Lake",
          "Mahavir Harina Vanasthali NP",
          "Malaka Cheruvu, Hyderabad",
          "MANUU (Urdu University), Hyderabad",
          "Masjidgadda Jungle Camp",
          "Meedikunta Lake",
          "Mir Alam Tank",
          "Miyapur Lake",
          "Mrugavani National Park",
          "Nallagandla Lake",
          "Narasingi Nalla Cheruvu Lake",
          "Osman Sagar Lake",
          "Pahadi Shareef (OBI)",
          "Panchavati Park, Hyderabad",
          "Pasumamula Lake",
          "Peacock Lake, University of Hyderabad",
          "Peerancheruvu",
          "Rajendranagar",
          "SVP National Police Academy",
          "Talakunta GHMC Park",
          "Thattikunta Maisamma temple",
          "Timmidkunta (Gadwall) Lake",
          "University of Hyderabad",
          "Vanasthali Hills",
          "Woods Miyawaki Forest",
          "Yerra kunta",
        ],
      },
      {
        district: "Bhadradri Kothagudem",
        localities: [
          "Bandirevu, Bhadrachalam",
          "Gubbala Mangamma Thalli Kshetram",
          "Kinnerasani Dam",
          "Manuguru",
        ],
      },
      {
        district: "Jangaon",
        localities: ["Chagal Cheruvu", "Ghanpur Cheruvu"],
      },
      {
        district: "Mahabubabad",
        localities: ["Bheemuni Paadam Waterfalls", "Pandem Forest Range"],
      },
      {
        district: "Mulugu",
        localities: [
          "Bogatha waterfalls",
          "Laknavaram",
          "Palem project",
          "Ramappa Lake",
          "Sri Hemachala Laxmi Narsimha Swamy Devalayam",
          "Tadvai Forest Camp",
        ],
      },
      {
        district: "Vikarabad",
        localities: [
          "Ananthagiri Hills, Vikarabad",
          "Bantwaram-Vikarabad",
          "Damagundam Grassland",
          "Damagundam Reserve Forest",
          "Kotepally Lake",
          "Laknapur Lake",
          "Parameshwara Hill Temple, Vikarabad",
          "Sarpanpally Lake",
          "Somaram Lake",
          "Yenkathala Grasslands",
        ],
      },
      { district: "Mahabubnagar", localities: ["Koilsagar Reservoir,"] },
      {
        district: "Khammam",
        localities: [
          "Bendalapadu",
          "Government Degree College for Women, Khammam",
          "lakaram lake",
        ],
      },
      { district: "Nalgonda", localities: ["Devaracharla temple"] },
      {
        district: "Warangal Urban",
        localities: [
          "Bhadrakali lake",
          "Dharmasagar Lake",
          "Kakatiya University",
          "Kazipet Railway Station wetland",
          "Keshavpur Lake",
          "Madikonda Lake, Warangal District",
          "National Institute of Technology (NIT) Warangal",
          "Pembarthy Cheruvu",
          "SR University",
          "Vem Cheruvu",
          "Waddepally Lake",
        ],
      },
      { district: "Karimnagar", localities: ["Lower Manair Reservoir"] },
      {
        district: "Yadadri Bhuvanagiri",
        localities: [
          "Centre for Cellular and Molecular Biology (CCMB)--New Campus site",
          "Pochampally",
          "Rachakonda Fort",
          "Rachakonda Fort Boundary",
          "Vasaalamarri - Farm",
        ],
      },
      {
        district: "Medchal Malkajgiri",
        localities: [
          "ALWAL LAKE ( BATHUKAMMA CHERUVU)",
          "Ambar Cheruvu, Hyderabad",
          "Ammuguda Hills",
          "ANNARAYAN CHERUVU",
          "AO2 Block Sahabhawana, Hyderabad",
          "Bhagyanagar Nandanavanam Deer Park",
          "BITS Pilani Hyderabad Campus",
          "cherlapally lake",
          "Dammaiguda lake",
          "Dulapally",
          "Edulabad Lake",
          "Fox Sagar Lake",
          "Geological Survey of India",
          "HMT Urban Forest , Hyderabad",
          "IDPL Township, Balanagar",
          "Infosys Campus SEZ",
          "Kandlakoya Oxygen Park",
          "Kapra Lake",
          "MCRHRDI, Telangana",
          "NISA/CISF",
          "Nizampet road-Hill county road waterbody",
          "Nizam Talab, Nizampet",
          "Pedda Cheruvu, Nacharam Hyderabad",
          "Ranganayaka IDL Lake, KPHB",
          "Safilguda lake park",
          "SA Palm Meadows",
          "Shamirpet lake",
          "Shamirpet Lake Backwaters",
          "Shamirpet Lake, Hyderabad",
          "T.S.Forest Academy",
          "Venellagada Cheruvu",
          "Yadgarpally lake",
          "yellampet reserve forest",
        ],
      },
      { district: "Rajanna Sircilla", localities: ["Upper Manair Resrvoir"] },
      {
        district: "Nirmal",
        localities: [
          "Aakonda lake",
          "Bellal Cheruvu",
          "Chinna Bellal Godavari Ghat",
          "Kaddam Project Dam, Pandwapur, Telangana",
          "Kolamguda - Kaddam backwaters",
          "KWLS - Kalpakunta",
          "Medipally lake",
          "Mootapur Cheruvu, Nirmal, TS",
          "Venganna Cheruvu",
        ],
      },
      {
        district: "Jogulamba Gadwal",
        localities: ["Beechupalli Krishna Pushkar Ghat", "Jogulamba Temple"],
      },
      { district: "Adilabad", localities: [] },
      {
        district: "Kamareddy",
        localities: ["Mysamma Temple, Gidda Sirnapally", "Nizamsagar Lake"],
      },
      {
        district: "Kumuram Bheem Asifabad",
        localities: [
          "Bejjur Forest Range",
          "Bejjur Reserved Forest",
          "Gundala Waterfalls",
          "Gundepalle, Penchikalpet Sipur Kagaznagar Kumaram Bheem Asifabad",
          "penchikakpet,bejjur and sarsala cheruvu",
        ],
      },
      { district: "Wanaparthy", localities: ["Gadwal Raja's Fort"] },
      {
        district: "Nagarkurnool",
        localities: [
          "Achampet (OBI)",
          "Amrabad Tiger Reserve",
          "Dindi Reservoir",
          "Mallela Theertham",
          "Munnanur Check Post",
          "Octopus Viewpoint",
          "Prathapa Rudruni Kota",
          "Rasool Cheruvu",
          "Saleshwaram, Srisailam Highway",
          "Uma Maheswaram",
        ],
      },
      {
        district: "Mancherial",
        localities: [
          "Godavari bank, Mancherial",
          "Kawal Tiger Reserve",
          "KWLS -- Barthanpet Kunta",
          "KWLS -- Bison Kunta",
          "KWLS -- Dongapalle",
          "KWLS -- Forest Guest House",
          "KWLS -- Ganasheti Kunta",
          "KWLS - Kadem Left Canal Road",
          "KWLS -- Maisamma Kunta",
          "KWLS -- Malliyal Beat Range",
          "KWLS -- Mancha Kunta",
          "Shivaram Crocodile Wildlife Sanctuary",
        ],
      },
      {
        district: "Medak",
        localities: [
          "Chitkul",
          "Dantepally Cheruvu (OBI)",
          "FOREST COLLEGE AND RESEARCH INSTITUTE",
          "Haldi Reservoir",
          "Mallanna Swamy Temple, Narasapur Lake, Telangana, IN (17.727, 78.292)",
          "Medak, Telangana, IN (18.044, 78.257)",
          "Narsapur Forest Urban Park",
          "Narsapur Reserve Forest",
          "Pocharam Reservoir",
          "Pocharam Wildlife Sanctuary",
          "Toopran Lake",
          "Yellamma Temple",
        ],
      },
    ],
  },
  {
    state: "Tripura",
    districts: [
      {
        district: "Dhalai",
        localities: [
          "Gumti WLS--Dumboor Lake (Gandachara)",
          "Jagabandhupara, Gandacherra",
          "Manu Railway Station",
        ],
      },
      {
        district: "Gomati",
        localities: [
          "Banduar Brick Industry & Surrounds",
          "Debatamura Reserve Forest",
          "Deb Bari Reserve Forest",
          "Gomati River--Nograi Fields",
          "Gumti WLS",
          "Hurijala, Kakraban",
          "Kalyan Sagar, Matabari",
          "Nethaji Subhash Mahavidyalaya",
          "Sukh Sagar Wetlands",
          "Tepania Eco Park",
        ],
      },
      {
        district: "Khowai",
        localities: [
          "Baramura Eco Park",
          "Baramura Hills--Baramura Waterfall",
          "Baramura Hills (general area)",
          "Gumti WLS--Nunachhara RF",
          "Khowai River--Kalyanpur Fields",
          "Khowai River--Moharchhara Zone",
          "Teliamura Railway Station",
        ],
      },
      {
        district: "North Tripura",
        localities: [
          "Churaibari Railway Station",
          "Dharmanagar Railway Station",
          "Jampui Hills--Eden Tourist Lodge",
          "Jampui Hills--Phuldungsei",
          "Jampui Hills--Vanghmun",
          "Panisagar Railway Station",
          "Rowa WLS",
          "Unakoti Eco Park",
        ],
      },
      {
        district: "Sepahijala",
        localities: [
          "Boxanagar",
          "Chandi Park",
          "Hurijala, Melaghar (OBI)",
          "Krishna Kishore Nagar Wetlands",
          "Melaghar (OBI)",
          "Rudrasagar Lake",
          "Rudrasagar Lake--Neermahal",
          "Sepahijala WLS",
          "Sepahijala WLS--Clouded Leopard NP",
          "Sepahijala WLS--Sepahijala Zoological Park",
        ],
      },
      {
        district: "South Tripura",
        localities: [
          "Trishna WLS--Eden of Bison",
          "Trishna WLS (general area)",
          "Trishna WLS--Joychandrapur Ecotourism Zone",
        ],
      },
      {
        district: "Unakoti",
        localities: [
          "Kumarghat Railway Station",
          "Village in North Tripura (OBI)",
        ],
      },
      {
        district: "West Tripura",
        localities: [
          "Baramura Hills--Champabari",
          "Baramura Hills--Sitacherra Lake",
          "Baramura Hills--Subalsingh Forest",
          "Charulata Boutique Guest House",
          "Circuit House Agartala",
          "College of Agriculture, Lembucherra",
          "Heritage Park",
          "Holy Cross School and College",
          "ICAR Research Complex",
          "IC Nagar--Ishanchandranagar Paddyfields",
          "IC Nagar--Pandabpur Eastern Woodlands",
          "IC Nagar--Pandabpur Paddyfields",
          "Jamtala Paddyfields",
          "Khejurbagan (OBI)",
          "Koko Tilla",
          "Kunjaban (OBI)",
          "Lembucherra (OBI)",
          "Maharaja Bir Bikram Airport",
          "MBB College",
          "MBB College Lake",
          "NIT Agartala",
          "Oxygen Park",
          "Shani Mandir, Ram Nagar",
        ],
      },
    ],
  },
  {
    state: "Uttar Pradesh",
    districts: [
      { district: "Banda", localities: ["Chilla Ghat"] },
      {
        district: "Rampur",
        localities: ["Bilaspur Railway Station", "Janu Nagar near Kemri"],
      },
      { district: "Farrukhabad", localities: [] },
      { district: "Amethi", localities: ["Samaspur Bird Sanctuary -- Mamuni"] },
      {
        district: "Azamgarh",
        localities: ["Baba Baraila Tal", "Semari Kotia"],
      },
      {
        district: "Baghpat",
        localities: ["Yamuna Bridge oneastern peripheral express way"],
      },
      {
        district: "Jhansi",
        localities: [
          "Bharatpura on Betwa river bank",
          "Grassland Research Institute, Jhansi (OBI)",
          "Indian Grassland and Fodder Research Institute (IGFRI) Jhansi",
          "Jhansi Fort",
          "Jhansi (OBI)",
          "Pahuj Reservoir",
        ],
      },
      {
        district: "Prayagraj",
        localities: [
          "Allahabad Fort",
          "Arail River bank",
          "Arail Road",
          "Belwaniya Wetland Region",
          "Chandra Shekhar Azad Park (Company Garden)",
          "Chhatnag Ghat",
          "Dewari Goan Lake",
          "Dwarika Puri (OBI)",
          "Ganga River Below Phaphamau Bridge",
          "Ganga River Near Rasulabad",
          "Guleriya Dam",
          "Harish Chandra Research Institute",
          "Kalidas Point",
          "Koraon Forest Range",
          "MacFerson Lake & Around",
          "Mawaiya Uparhar",
          "Meja Road Railway Station",
          "Minto Park Allahabad",
          "Naini Forest Near TSL",
          "Prayagraj -- Allahabad University",
          "Prayagraj -- Baghla Dam",
          "Prayagraj -- Indian Institute for Information Technology (IIIT)",
          "Prayagraj -- Phaphamau Bridge & Surroundings",
          "Prayagraj -- Yamuna bank Saeedpur",
          "Sam Higginbottom Institute of Agricultural Technology and Sciences (SHIATS)",
          "Sam Higginbotton Institute of Agriculture and Technology (SHIATS) Girls hostel",
          "Samrat Harsh Vardhan Ghat",
          "Shankargarh Talab Area",
          "Sonbhadri Wetland Region",
          "Triveni Sangam",
          "Ulta Qila",
          "Yamuna South Bank, Arail Road",
        ],
      },
      {
        district: "Etawah",
        localities: [
          "Chambal Bird Fair (UP) (OBI)",
          "Chambal boating point",
          "Chambal River Sanctuary Etawah",
          "Lion Safari Parks",
          "National Chambal Sanctuary -- Bhareh Fort at Chambal Yamuna Confluence",
          "National Chambal Sancturay -- Yamuna River Simar",
          "Saifai village, Chambal, Uttar Pradesh",
          "wetland south of Jaswantnagar (OBI)",
        ],
      },
      {
        district: "Hardoi",
        localities: [
          "River Ramganga, Bareilly (OBI)",
          "Sandi Bird Sanctuary (OBI)",
          "Sandi Pakshi Vihar (Sandi Bird Sanctuary)",
        ],
      },
      {
        district: "Maharajganj",
        localities: [
          "Darjaniya Lake",
          "Jharvaliya, Nichlaul",
          "Nautanwa",
          "Sohagi Barwa WILS -- Madhwalia FRH",
          "Sohagi Barwa WLS -- Deibhar FRH (North Chowk Range)",
          "Sohagi Barwa WLS--Madhwaliia FRH",
          "Sohagi Barwa WLS -- Nichlaul FRH",
          "Sohagi Barwa WLS -- Pakadi FRH (Pakadi Range)",
        ],
      },
      { district: "Kasganj", localities: ["Kashganj (OBI)"] },
      {
        district: "Mainpuri",
        localities: [
          "Karhal Bypass Road, Karhal",
          "Khirongi (Lucknow-Agra Exp Way)",
          "Kirthua Ponds",
          "Mainpuri, UP (OBI)",
          "Saman Bird Sanctuary",
          "Sarus Crane Wetlands Saifai near  Kirthua",
        ],
      },
      {
        district: "Balrampur",
        localities: [
          "Parvati Aranga (Arga) WLS -- General Area",
          "Suheldev Wildlife Sanctuary, Balrampur (OBI)",
          "Suhelwa (Suheldev) WLS -- Bhagwanpur Reservoir",
          "Suhelwa (Suheldev) WLS -- Chittaurgarh Reservoir",
          "Suhelwa (Suheldev) WLS -- Girgitahi Reservoir",
          "Suhelwa (Suheldev) WLS -- Khairman Reservoir",
          "Suhelwa (Suheldev) WLS -- Pipra FRH (Hathiakunda Trail)",
        ],
      },
      {
        district: "Mathura",
        localities: [
          "Ajhai, Akbarpur",
          "Dholi pyau pond",
          "Gokul Dham",
          "Jodhpur Jhal Bird Sanctuary",
          "Keshi Ghat, Vrindavan",
          "Kosi Kalan East",
          "Kosi Khurd",
          "Mathura Railway Station",
          "Raya Toll Booth Ponds near Jawra, Mathura",
          "Sonot Village pond",
          "Vrindavan",
          "Wildlife SOS Elephant Conservation and Care Centre",
          "Yamuna River Bed at General ganj, Mathura",
        ],
      },
      {
        district: "Amroha",
        localities: [
          "Amroha Railway Station",
          "Brajghat on Ganga -- East Bank",
          "Moradabad Bypass Road at Mohra",
        ],
      },
      {
        district: "Shrawasti",
        localities: [
          "Bhaisahi Naala,Tulsipur - Sirsiya - Bilaspur Road, Gabbapur Kalan",
          "Bhinga Forest and Wetland",
          "Suhelwa (Suheldev) WLS -- East Sohelwa FRH",
          "Suhelwa (Suheldev) WLS -- Motipur Reservoir",
          "Suhelwa (Suheldev) WLS -- Rampur Bund (Irrigation Reservoir)",
          "Suhelwa (Suheldev) WLS-- Sirsiya Dagmara Nala",
        ],
      },
      { district: "Fatehpur", localities: ["Makrail Village (OBI)"] },
      { district: "Jaunpur", localities: ["Dandsawli Wetlands near Shahganj"] },
      {
        district: "Mirzapur",
        localities: [
          "Banaras Hindu University (South campus)",
          "Chuna Dari Waterfall",
          "Chunar Railway Junction (Mirzapur, Uttar Pradesh)",
          "Jargo Dam, Mirzapur",
          "Jeonathpur Railway Station, (Mirzapur, Uttar Pradesh)",
          "Kaimur WLS -- Harra forest",
          "Mirzapur (Uttar Pradesh) Railway Station",
          "Rajiv Gandhi South campus, Banaras Hindu University",
        ],
      },
      {
        district: "Unnao",
        localities: [
          "Firojpur Kalan",
          "Food King Plaza, Agra - Lucknow Expressway, Bhikharipur Patsia Gair",
          "Hanumangarhi",
          "Ma Kalika Mandir",
          "Nawabganj Bird Sanctuary -- Sanctuary Proper",
          "Nawabganj Bird Santuary -- Open Fields Unnao",
          "Raukarna Banda Khera Route",
          "Sidhnath Temple Area",
        ],
      },
      {
        district: "Barabanki",
        localities: [
          "Akauna -- Ghaggra River Banks",
          "Akauna -- Village Areas",
          "Chaksar Wetland, Gangaganj",
          "Chowkaghat Area -- Bhavania Nulla",
          "Chowkaghat Area-- Bridge 393, near Chowkaghat Railway Stn. Barabanki",
          "Chowkaghat Area -- Lohati  Pasai Road",
          "Ghagra Ghat -- Durgarpur on Barabanki side",
          "Harakka Market",
          "Ibrahimpur Farm Fields",
          "Khanwaha",
          "Murtazanagar Farm Fields",
          "Salarpur Wetland, Dewa Road",
          "Sursanda Link Road",
        ],
      },
      {
        district: "Hapur",
        localities: ["Ganga (Ganges) Snan Ghat, Garhmukteshwar"],
      },
      {
        district: "Ayodhya",
        localities: [
          "Company Garden Park, Faizabad Cantt",
          "Faizabad Cantonment -- Manjh Range",
          "Faizabad Cantonment (Restricted Area) -- General Area",
          "Fatehpur Saraya Manjh",
          "Guptar Ghat",
          "Kaushalya Ghat and Manjha",
          "Manjha Kala on Ghaggra/Saryu River at Ayodha",
          "Manjha Range faizabad",
          "Mau Shivala Bazar wetland",
          "Ramayana Theme Park -- Ã Â¤â€¦Ã Â¤Â®Ã Â¤Â°Ã Â¥â€šÃ Â¤Â¤Ã Â¤Â¹Ã Â¤Â¿Ã Â¤Â¯Ã Â¤Â¾ (Gauva Plantations)",
          "Ramayana Theme Park -- General Area",
          "Ram Janki Mandir Demua Ghat",
          "Samda lake and bird sanctuary",
          "Saryu Majha, Naya Ghat, Ayodhya",
          "Sittanur Area Ã Â®Å¡Ã Â®Â¿Ã Â®Å¸Ã Â¯ÂÃ Â®Å¸Ã Â®Â©Ã Â¯â€šÃ Â®Â°Ã Â¯Â Ã Â®ÂªÃ Â®â€¢Ã Â¯ÂÃ Â®Â¤Ã Â®Â¿",
        ],
      },
      { district: "Auraiya", localities: ["Phaphund Railway Station"] },
      {
        district: "Mahoba",
        localities: [
          "Bania Talab Mahanpur",
          "Madan Sagar",
          "Vijay Sagar Bird Sanctuary",
        ],
      },
      {
        district: "Lalitpur",
        localities: [
          "Galitpur, Haryana (OBI)",
          "Mahaveer Swami Wildlife Sanctuary",
        ],
      },
      {
        district: "Sonbhadra",
        localities: [
          "Kaimoor Wildlife Sanctuary--Blackbuck Valley",
          "Myorpur Forest Range (OBI)",
          "Obra Rail Station Road",
        ],
      },
      { district: "Budaun", localities: ["Badaun (OBI)", "Kachla (OBI)"] },
      {
        district: "Meerut",
        localities: [
          "Chaudhary Charan Singh University Meerut",
          "Gandhi Bagh (formerly known as Company Garden)",
          "Ishwar Nagar Park Meerut",
          "Jani, Upper Ganges Canal",
          "Meerut Cantonment",
          "Meerut Railway Station",
          "Rustampur Bhikund, Hastinapur",
          "Victoria Park Meerut",
        ],
      },
      { district: "Deoria", localities: [] },
      { district: "Kanpur Nagar", localities: [] },
      {
        district: "Hathras",
        localities: [
          "Gopalpur sidhamai wetland",
          "Jaau-Bastoi Hathras",
          "Nagla Kanch , Rati Ka Nagla Wetland",
        ],
      },
      {
        district: "Varanasi",
        localities: [
          "Banaras Hindu University -- Ayurvedic Garden",
          "Banaras Hindu University (BHU)",
          "Benares Hindu University (BHU) Campus -- Agriculture Farm Air Strip Varanasi",
          "Beniya Bagh Park, Varanasi",
          "Chak Bansdev Bharthra",
          "Dashashwamedh Ghat, Varanasi",
          "Harishchandra Ghat, Varanasi",
          "Machodari Park, Rabindranath Tagore Rd. Varanasi",
          "Nehru Park, Kennedy Rd, Varanasi",
          "Rajghat Besant School, Varanasi",
          "Sant Ravidas Park, Varanasi",
          "Sarnath Deer Park",
          "Varanasi Airport, Babatpur",
        ],
      },
      { district: "Kaushambi", localities: [] },
      { district: "Shamli", localities: [] },
      { district: "Sambhal", localities: [] },
      { district: "Kushinagar", localities: [] },
      {
        district: "Shahjahanpur",
        localities: ["Millennium School, Shahjahanpur"],
      },
      {
        district: "Ghaziabad",
        localities: [
          "City forest, Ghaziabad UP/IN",
          "Ghaziabad (OBI)",
          "Ghaziabad Railway Station",
          "Hindon Ghat on GT Road",
          "Hindon green area Ghaziabad",
          "Indira Priyadarshini Park",
          "Model Town Park, Ghaziabad",
          "Sewage treatment plant Indrapuram",
          "Sherpur fields (Niwari Rd), Modinagar",
          "Vasundhra Ashram",
        ],
      },
      {
        district: "Gorakhpur",
        localities: [
          "Deen Dayal Upadhyaya Gorakhpur University",
          "East bank of Rapti river, near north side of Rajghat Bridge",
          "Gorakhpur Rail Museum",
          "Gorakhpur Railway Station",
          "Kasso Taal, Baranagar",
          "Madan Mohan Malaviya University of Technology, Gorakhpur",
          "Ramgarh Taal, Gorakhpur",
          "Sarus point Moharipur",
          "Shaheed Ashfaqullah Khan Prani Udyan (Gorakhpur Zoo)--General Area",
          "Shaheed Ashfaqullah Khan Prani Udyan (Gorakhpur Zoo)--Wetland Area",
          "Vinod Van, Kasia - Gorakhpur Road, Jungle Ramgarh Urf Sarka,",
        ],
      },
      {
        district: "Raebareli",
        localities: [
          "Indira Gandhi Smarak Vanaspati Udyan",
          "Malhipur-Padera at Bacchrawan- Harchandpur Road",
          "Pakka Ghat Dalmau",
          "Rae Bareli District Nursery",
          "Rajapur Village on Maharaja Ganj Road",
          "Surjapur at Bacchrawan-- Sharda Canal and Reedbed",
        ],
      },
      { district: "Kanpur Dehat", localities: [] },
      {
        district: "Bahraich",
        localities: [
          "Chahlarighat -- Sisaiya churaman",
          "Crop Research Station, Ghaghraghat",
          "Ghagra Ghat -- Tappe Sipah on Bahraich side",
          "Golavaghat near Mari Mata Temple on Saryu river",
          "Katerniaghat wildlife sanctuary",
          "Katerniaghat wildlife Sanctuary",
          "Katerniaghat WLS -- General Area",
          "Katerniaghat WLS -- Katerniaghat FRH",
        ],
      },
      {
        district: "Gautam Buddha Nagar",
        localities: [
          "Alauda Jagir Village Pond",
          "Alawalpur pond (Beside Shiva Dhaba)",
          "Alpha 1 Park, Greater NOIDA",
          "Amity University Campus, Noida",
          "Bhikanpur Wetlands (seasonal)",
          "Crowne Plaza, Greater NOIDA",
          "Dadri Wetland (Historical)",
          "Dankaur Village (OBI)",
          "Dhanauri Wetlands -- Dhanauri Kalan East",
          "Dhanauri Wetlands -- Dhankaur-Bhatta-Parsaul",
          "Dhanauri Wetlands -- Kherli Hafizpur (Dhanauri Kalan)",
          "Forest View Park, Sector 54, Noida",
          "Gaur Yamuna City, 16th Park view, Sector 19, Greater Noida",
          "Girdharpur Pond, Surajpur Kasna Rd",
          "Girdharpur Pond UP/IN",
          "Giridharpur (OBI)",
          "Greater Noida (OBI)",
          "Hasanpur Lake",
          "HCL Campus Sector 126 NOIDA",
          "Jewar Canal -- Mukimpur Sivara",
          "Jhajhar lake (seasonal)",
          "LG Chowk Forest Greater Noida UP India",
          "Motipur, Yamuna Expressway",
          "Nagli Bahrampur, Kidawali",
          "Nagli Bahrampur Noida UP/IN",
          "Nagli-Bahrampur Noida Yamuna Bank IN/Dl",
          "NOIDA Biodiversity Park",
          "NOIDA, Gautam Budh Nagar (OBI)",
          "Noida International Airport Jewar",
          "Okhla Barrage",
          "Okhla Bird Sanctuary",
          "Okhla Bird Sanctuary Gate",
          "Omaxe twin tower, Sector 50, Noida",
          "Radiant Academy, B Block, Sector 55, NOIDA",
          "Raipur Khadar, NOIDA (OBI)",
          "Sector 15A (OBI)- Noida",
          "Sector 23 Noida Uttar Pradesh",
          "Shiv Nadar University",
          "South East Noida -- Kulesara",
          "Step By Step School, Noida",
          "Surajpur Police line",
          "Surajpur Wetlands Bird Sanctuary",
          "Thasrana (OBI)",
          "Tusyana Village (OBI)",
          "Unitech Golf Course, Sector 97 NOIDA",
        ],
      },
      { district: "Jalaun", localities: ["Kalpi Yamuna Bed"] },
      {
        district: "Ballia",
        localities: [
          "Beyasi Ballia Bridge",
          "Suraha Tal Ballia (IBA IN-UP-25)",
        ],
      },
      { district: "Basti", localities: [] },
      { district: "Mau", localities: [] },
      {
        district: "Sitapur",
        localities: [
          "Chahlari Ghat -- Chahlari",
          "Chahlarighat River Boating",
          "Sarwarpur",
          "Sitapur Shiksha Sansthan",
        ],
      },
      {
        district: "Saharanpur",
        localities: [
          "Asli Bagh Saharanpur",
          "Badshahi Bagh Reserve Forest",
          "Deoband Railway Station",
          "Hathni Kund Barrage",
          "Mohand checkpost and vicinity (Reserve Forest)",
          "Saharanpur Railway Station",
          "Shakumbri Devi Mandir (Temple Nearby)",
        ],
      },
      {
        district: "Etah",
        localities: ["Patna Pakshi Vihar (Pundhir Bird sanctuary)  Etah"],
      },
      {
        district: "Kannauj",
        localities: [
          "Lakh Bahosi Bird Sanctuary Ã¢â‚¬â€ Jheel and surrounding",
          "Lakh Bahosi Bird Sanctuary -- Bahosi Jheel",
          "Lakh Bahosi Bird Sanctuary -- Lakh Jheel",
        ],
      },
      {
        district: "Muzaffarnagar",
        localities: [
          "Bamanheri Railway Station",
          "Haiderpur Farming Ponds",
          "Haiderpur Wetlands -- Forest Trail",
          "Haiderpur Wetlands -- General Area",
          "Haiderpur Wetlands -- Madhya Ganga Canal Trail",
          "Haiderpur Wetlands -- Muzaffarnagar Zone",
          "Haiderpur Wetland--Ujaili Trail",
          "Muzaffarnagar Railway Station",
        ],
      },
      { district: "Hamirpur", localities: [] },
      {
        district: "Bareilly",
        localities: [
          "Badrai Kuiyan, Bareily (OBI)",
          "Baheri (OBI)",
          "Bareilly Cantonment (OBI)",
          "Delapeer--Narayan Awas (OBI)",
          "Delapeer Pond",
          "Dohra Road Bareilly",
          "Fatehganj (OBI)",
          "Gandhi Udyan Bareilly",
          "Golf Course Bareilly Cantt",
          "Hajrat pur, aonla, bareilly",
          "Indian Veterinary Research Institute Bareilly",
          "Invertis University",
          "IVRI Farms, Bareilly (OBI)",
          "Kalapur, Pilibhit Bypass Road",
          "Kargaina (OBI)",
          "Kumra Village (OBI)",
          "Lal Fatak, Bareilly",
          "mahanagar colony",
          "Mahanagar Silverstate area,Bhoor (Bareilly)",
          "Mundia Ahmed Nagar (OBI)",
          "Narayan Awas, Bareilly (OBI)",
          "Nawabganj (OBI)",
          "Nawgawan Jagir Village (OBI)",
          "Railway Bridge, Opposite to Akashvani, Bareilly",
          "Ramganga Dam -- Badaun Road",
          "Rohilkhand Medical College Campus and Outskirts",
          "Saqlain Miyan Academy Bareilly",
          "St Stephens Church Bareilly",
          "St. Xavier's College Campus",
          "St. Xavier's Primary School",
          "Tigra Village and Fields",
          "Tigra Village (OBI)",
          "Tilolia Village, Bareilly (OBI)",
          "Unchagaon (OBI)",
        ],
      },
      {
        district: "Chandauli",
        localities: [
          "Chandraprabha WLS",
          "Chandraprabha WLS--Devdari Waterfall",
          "Pandit Deendayal Upadhyay (prev Mughal Sarai) Railway Junction",
          "River Karamnasa Birding Point",
        ],
      },
      { district: "Chitrakoot", localities: [] },
      {
        district: "Pratapgarh",
        localities: [
          "Agriculture - Horticulture department campus",
          "ATL ground Pratapgarh",
          "Gaighat on Sai River Pratapgarh -- South Bank",
          "Horticulture office campus",
          "Khajohari",
          "Korahi Kunda Pratapgarh",
          "Pure Mohan, Pratapgarh",
          "Shivrajpur Gadwara Pratapgarh",
          "Shree Shani dev dham",
          "Sri Ram Temple Belha Devi on Sai River",
          "Tala Patti Pratapgarh",
        ],
      },
      {
        district: "Lucknow",
        localities: [
          "Adhar Khera -- Open Fields, Farmlands and Forest area Near Adhar Khera",
          "Ambedkar Memorial Park- Lucknow",
          "Amity Lucknow Campus",
          "Babasaheb Bhimrao Ambedkar University Campus, Lucknow",
          "Babasaheb Bhimrao Amedkar University (BBAU) Lucknow -- Botanical Garden",
          "Bara Imambara, Lucknow",
          "Bhuyia Mandir, Sarora Village",
          "Bijnor South Open Grounds and Scrub",
          "Butler Palace Colony",
          "Central Institute of Medicinal and Aromatic Plants (CIMAP)",
          "Chandrika Devi Temple",
          "Chaudhary Charan Singh International Airport",
          "Dr APJ Abdul Kalam Technical University Lucknow",
          "Dr Ram Manohar Lohia Park, Lucknow",
          "Dr Ram Manohar Lohiya National Law University",
          "Dudhara, Itaunja. Lucknow",
          "Dudhara Oxbow Lake and Fields",
          "Ekana wetland",
          "Ekana Wetlands East of Stadium",
          "G20 Investor Summit Ground",
          "Ganga Aquarium, Canal Ring (VIP) Road, Lucknow",
          "Gautam Buddha Park Gomti South Bank",
          "Gomti Bridge on IIM Road, Lucknow",
          "Gomti Riverfront Park",
          "Gomti River North Bank (Birbal Sahni Marg)",
          "Gomti Weir East",
          "Gosainganj Wetlands -- Dular Mau",
          "Gosainganj Wetlands -- Meesa",
          "Gosainganj Wetlands -- Sri Jharkhandeswar Baba Dham",
          "GRRC",
          "Hanuman Ji Mandir (Ramghat Kolwa)",
          "Hulas Khera--(Hulaskheda Fort) Archaeological Excavation Site & Oxbow Lake",
          "IIM Lucknow",
          "Indian Institute of Management (IIM) Campus, Lucknow",
          "Indira Canal -- Sultanpur Road",
          "Indira Dam -- North Bank near Aqueduct",
          "Indira Dam -- Wetlands and reedbed near Dhakawa on south bank",
          "Integral University, Lucknow",
          "Janeshwar Mishra Park",
          "Jugauli open forest & Lake",
          "Jyotiba Phule Zonal Park",
          "Kalli Paschim -- Kalpana Estate and Oxbow Lake",
          "Kalli Poorab Wetlands",
          "Kall Paschim (West) Road",
          "Kathauta Jheel",
          "Khanwaha",
          "Koko's Farm, Jabrauli",
          "Kukrail Forest and Gharial Park -- General Area",
          "Kukrail Gharial Park, Lucknow, Uttar Pradesh",
          "Kuria Ghat on Gomti River",
          "Lakshman Mela grounds, Lucknow",
          "La MartiniÃƒÂ¨re Boys College -- Gomti West Bank",
          "La MartiniÃƒÂ¨re Boys College (Lucknow)-- Campus Proper",
          "Lucknow Cantonment",
          "Lucknow (OBI)",
          "Lucknow Railway Station",
          "Lucknow University - New Campus at Janakipuram",
          "Lucknow University -- Old/Main Campus",
          "Maharaja Bijli Pasi Quila",
          "Malookpur Wetland",
          "Manyavar Shri Kanshiram Ji Eco Garden Park",
          "Mastemau - Gomti South Bank  behind Cricket Stadium Ekana",
          "MEPTA Golf Course",
          "Moosa Bagh Forest, Hardoi Road, Lucknow",
          "National Bank Staff College (NBSC) Campus, Ashiyana",
          "National Botanical Research Institute (NBRI)",
          "National Botanical Research Institute (NBRI) Research Station, Banthra",
          "Nawab Wajid Ali Shah Zoological Garden (Lucknow Zoo)",
          "Nehru Enclave Forest",
          "Pooran Pur Jheel, Mohanlalganj",
          "Rajkiya Udyan, Lucknow",
          "RDSO Stadium",
          "Residency Ruins Lucknow",
          "Riverfront Butler Rd",
          "Roberts Lines",
          "SGPGI Campus Surroundings - Agricultural Fields & Canal",
          "SGPGI Campus & Surroundings--Campus Proper",
          "SGPGI Campus & Surroundings -- Deen Dayal Upadhyaya Park",
          "SGPGI Campus & Surroundings -- Open Fields and Swamp (Lucknow)",
          "SGPGI Campus & Surroundings -- Trauma Center",
          "Shaheed Zia Ul Haq Park, Khurram Nagar",
          "Shalimar park & surrounding area",
          "Sharda Canal -- Mohan Malihabad road",
          "Shri Ramswaroop Memorial College of Engineering and Management",
          "Study Hall School, Lucknow",
          "Tellibagh",
        ],
      },
      { district: "Bhadohi", localities: ["Sitamarhi Ganga Banks"] },
      {
        district: "Sultanpur",
        localities: [
          "Gomti River at Sita Kund",
          "Kamla Nehru Institute of Technology, Sultanpur",
          "Lambua Sultanpur",
          "Nirsahiya",
        ],
      },
      { district: "Siddharthnagar", localities: [] },
      {
        district: "Aligarh",
        localities: [
          "Aligarh Fort",
          "Aligarh Muslim University -- Abdullah Hall",
          "Aligarh Muslim University (AMU)--General Area",
          "Fly Ash Storage Ground of Kasimpur Powerhouse",
          "Sheikha Jheel (IBA IN-UP-21)",
          "Shekha Bird Sanctuary",
        ],
      },
      {
        district: "Lakhimpur Kheri",
        localities: [
          "Dudhwa National park",
          "Dudhwa National Park -- Near watchtower main Dudhwa guest house",
          "Dudhwa  national park -- Sonaripur",
          "Dudhwa NP -- Bankey Tal (Sonaripur Range, Route #1)",
          "Dudhwa NP -- General Area",
          "Dudhwa NP -- Palia Range",
          "Dudhwa NP -- Satiana FRH",
          "Dudhwa NP -- Satiana Range (Route # 2)",
          "Gomti River , surrounding areas, Lakhimpur kheri",
          "Janglinath baba mandir,Sharda River",
          "Kishanpur WLS -- General Area (Dudhwa NP Route#3 Chadi Taal)",
          "Mohammadi, Kheri",
          "South Kheri Forest (OBI)",
        ],
      },
      { district: "Ambedkar Nagar", localities: ["Hawai Patti DM Office"] },
      {
        district: "Pilibhit",
        localities: [
          "Baba Ikottar Nath, Pooranpur Kurraiya",
          "Chaltua Ashram Gurdwara, Puranpur,",
          "Chuka Resevoir,UP",
          "Haripur Forest Range, Pilibhit (OBI)",
          "Pilibhit Tiger Reserve",
        ],
      },
      {
        district: "Gonda",
        localities: [
          "Chandapur Oxbow Lake",
          "Parvati Aranga Bird Sanctuary -- General Area",
        ],
      },
      {
        district: "Moradabad",
        localities: [
          "Kashipur Moradabad Road (OBI)",
          "Missarwala on Moradabad Kashipur Road",
          "Moradabad Bypass Road",
          "Ramganga -- Deer Park on Rampur Road",
        ],
      },
      {
        district: "Firozabad",
        localities: [
          "Anardevi Rajaram Inter College, Pratappur road,Shikohabad",
          "Firozabad Railway Station",
          "Hazratpur Wetlands (OBI)",
          "Sarus Crane Trail Nagla Chanda",
        ],
      },
      {
        district: "Sant Kabir Nagar",
        localities: [
          "Bakhira Bird Sanctuary",
          "Bakhira Wildlife Sanctuary (Bakhira Taal)",
        ],
      },
      {
        district: "Ghazipur",
        localities: [
          "Bara Mathiya Mandir",
          "Dildar Nagar Railway  Jn, Ghazipur, Uttar Pradesh",
        ],
      },
      {
        district: "Bijnor",
        localities: [
          "Bhaguwala -- Kashirampur on Bijnor-Haridwar bypass",
          "Ganga Barrage Bijnor",
          "Ganga Pushta Road, Ranjeetpur",
          "Gulalwali on Eastern Ganga Canal Road",
          "Haiderpur wetland",
          "Haiderpur Wetlands and Bijnor Barrage -- General Area",
          "Haiderpur Wetlands -- Bijnor Zone",
          "Jhandichaur",
          "Maluaa",
          "Najibabad",
          "River Ganga, Bijnor (OBI)",
          "Rokhata Bijnor",
          "Thanda Rampur Chatha",
        ],
      },
      {
        district: "Agra",
        localities: [
          "Agra Fort",
          "Bateshwar Maharaj Temple",
          "Central jail",
          "Chambal River, near Bah (OBI)",
          "Chambal Safari Lodge",
          "Chambal Safari Lodge--MP (OBI)",
          "Fatehpur Sikri",
          "Hastinapur Wildlife Sanctuary",
          "Jarar Village (OBI)",
          "Jaypee Palace Hotel, Agra",
          "KD Paliwal Park",
          "Mehtab Bagh",
          "Mughal Hotel & Grounds",
          "National Chambal Sanctuary -- Chambal Ravines at Pinahat",
          "National Chambal Sanctuary -- Jaitpur",
          "National Chambal Sanctuary -- Nadgawan",
          "Poiya Ghat",
          "Rambagh Park",
          "Soor Sarovar Bird Sanctuary (Keetham Lake)",
          "Taj Mahal",
          "Taj Nature Walk",
          "Tomb of Akbar the Great, Agra",
          "Tomb of I'timÃ„Âd-ud-Daulah (Little Taj)",
          "Yamuna River near Taj",
          "Yamuna River Swara",
        ],
      },
      {
        district: "Bulandshahr",
        localities: ["Ganga river (Ganges) at Narora Khader"],
      },
    ],
  },
  {
    state: "Uttarakhand",
    districts: [
      {
        district: "Pauri Garhwal",
        localities: [
          "Aamsaur",
          "Bhulla Tal--Lansdowne",
          "Byasi",
          "Chamoli",
          "Chaukhamb, Lansdowne Forest Division",
          "Cheela Dam, cut for Vindhya Vasini temple",
          "Chilla-Rishikesh Canal Road",
          "Corbett NP--Dhikala",
          "Corbett NP--Jhirna Range",
          "Corbett NP--Kalagarh Dam Road",
          "Corbett--Saddle Dam",
          "Dhimki, Lansdowne Forest Division",
          "Dugadda, Kotdwar Uttarakhand,",
          "Eco Wild Camp Rajaji National Park",
          "Ekeshwar",
          "Halduparao Forest Rest House",
          "Halduparao FRH, Sonanadi",
          "Jamargadi Talli, Kotdwar",
          "Kalagarh Tiger Reserve",
          "Kalagarh Tiger Reserve--Sona",
          "Kalagarh Tiger Reserve--Vatanvasa",
          "Kalaghar Tiger Reserve--Jungle Camp",
          "Kalaghar Tiger Reserve--Rathuwadhab",
          "Kalaghar Tigre Reserve--Mundiyapani FRH",
          "Kamarpatta",
          "Kanda",
          "Kaudiya/Gangabhogpur -- Rajaji NP",
          "Khirsu (OBI)",
          "Kolhu Chaur, Lansdowne Forest Division",
          "Kotdwar",
          "Kotdwar-Lansdowne road--S.B. Mount Resort",
          "Lal Darvaza, Lansdowne Forest Division",
          "Landsdowne Tip N Top",
          "Lansdowne",
          "Lohachur Uttarakhand",
          "Loha Siddh, Kimsar Road",
          "Magpie Cottage - Dhoutiyal",
          "Mandlu to Sheela Tonk",
          "Nauri, Lansdowne Forest Division",
          "Nilkanth trek",
          "Nodi Dugadda",
          "Pakhro -- Kalaghar Tiger Reserve",
          "Patna Waterfall",
          "Pauri (OBI)",
          "Phoolchatti",
          "Rajaji National Park",
          "Rajaji NP--Ganga Bhogpur",
          "Rajaji NP--Khara",
          "Rajaji NP--Rawasan Range",
          "Rami sera",
          "Rikhnikhal",
          "Rishikesh--Laxman Jhula",
          "Saneh Kotdwar",
          "Saneh, Lansdowne Forest Division",
          "Sheela Tonk",
          "Sidhbali Mandir, Kotdwar",
          "Sigaddi",
          "Sonanadi Range",
          "Sonanadi Wildlife Sanctuary",
          "Srinagar Garhwal",
          "Sural",
          "The Beatles Ashram",
          "Tip in Top, Lansdowne",
          "Vanghat--Deema Range",
          "Wild Brook, Gohari Range",
        ],
      },
      {
        district: "Bageshwar",
        localities: [
          "Bageshwar",
          "Baijnath (OBI)",
          "Binsar Wildlife Sanctuary",
          "Dwali",
          "Gogina",
          "Khati",
          "KMVN Kaushani, Uttarakhand",
          "Phurkiya (Pindari Trek)",
          "Shikhar Mool Narayan Mandir",
          "TRH Phurkiya",
          "Wachham (Jaikuni/Base)",
        ],
      },
      {
        district: "Champawat",
        localities: [
          "Abbott Mount",
          "Banasur's Kila (Fort)",
          "Pancheshwar Fishing Retreat",
          "Poornagiri Hills, Danda Range",
          "Sharda Barrage, Banbasa",
          "Shree Krantheshwar Mahadeva Mandir Forest",
          "Tanakpur",
          "Tanakpur Dam",
          "Tea Garden,  Champawat",
        ],
      },
      {
        district: "Udham Singh Nagar",
        localities: [
          "Baigul Dam",
          "Baigul Wetland, Sitarganj",
          "Baur (Haripura) Reservoir, Haldwani",
          "Dhaura Reservoir, Khatima (OBI)",
          "GB Pant University, Pantnagar",
          "Nanak Sagar Dam",
          "Nanak Sagar Dam--Machlijaala",
          "Rudrapur (OBI)",
          "Sharada Sagar",
          "Tumariya Reservoir",
        ],
      },
      {
        district: "Dehradun",
        localities: [
          "Chandrabani-Karwapani Trail",
          "18 Mohini Road, Dalanwala",
          "Rajpur Road Enclave, Dhoran",
          "Chakrata",
          "Jharipani",
          "Kanasar",
          "Benog WLS--Dhobhighat",
          "Mata Wala Bagh",
          "Karwapani",
          "Hanol",
          "Chandrabani Chowk",
          "Mehuwala forest",
          "Mussoorie--Mossy Falls",
          "Tuntawala",
          "Lytton Road",
          "ISBT Dehradun",
          "Sahajpur",
          "Mahendra Vihar",
          "Jolly Grant (OBI)",
          "Kansrao-Motichur road",
          "Chalang",
          "Malsi--Deer Park and Dehradun Zoo",
          "Hathipaon--George Everest",
          "LBSNAA to Waverley school",
          "Karvapani sal forest (OBI)",
          "Mota Mahadev Temple",
          "Jhanda Saheb Dehradun",
          "Asan Conservation Reserve",
          "Thano",
          "Selaqui (Selakui)",
          "Khalanga Reserved Forest",
          "Circular Road, Dalanwala, Dehradun",
          "Dalai Hill--General Area",
          "Timli Reserved Forest",
          "LBSNAA To Company Bagh Trail",
          "WII--Pattiyonwala Road",
          "Shikhar Falls",
          "Chakrata-- Gurudwara Trail",
          "Sauda Saroli, Thano Road",
          "Maldevta--Red Bridge",
          "Dehradun--Karwapani",
          "Lal Tappad Dehradun-Haridwar Road",
          "Adhoiwala",
          "Landour--Hanifl Centre Woodstock",
          "Song Bridge, Raipur",
          "Outskirts of Doon University",
          "Zoological Survey Of India, Northern Regional Centre--Dehradun",
          "Mussoorie--Lal Tibba",
          "Mount Fort Academy",
          "Jakhan",
          "Dalanwala, Eastern Canal Road",
          "Jaswant Modern School, Dehradun",
          "Sahastradhara",
          "Tons Valley Forests (OBI)",
          "Dehradun--Panditwadi",
          "Asarodi, Ramgarh Range",
          "Wildlife Institute of India (WII)",
          "Landour",
          "Dehradun District Court Campus",
          "Rajpur",
          "Maindrath",
          "Virbhadra, Rishikesh",
          "Hathipaon--Lambi Dehar mines",
          "Between WII and Sal Patch",
          "Bajaj Institute of Learning",
          "Bhadraj",
          "Bhilangana River--Bangai",
          "Doon PG College Of Agriculture Science And Technology, Shankerpur Hakumatpur",
          "Majra",
          "Harbanswala Tea Estate, Dehradun",
          "Bhandari Bagh",
          "Chowki Village (OBI)",
          "Sal Patch near WII, Dehradun",
          "Chandrabani Reserved Forest",
          "Tons Bridge, Bishtgaon",
          "Dehradun--Railway Stations",
          "Rajpur-Mussoorie trail",
          "Bijapur",
          "Rajaji National Park--Chilla Range",
          "Forest Research Institute (FRI), New Forest Campus",
          "Doiwala",
          "IRDE Residential Campus",
          "Than Gaon",
          "Chakrata--Gwasa Bridge",
          "Hydel Colony, Araghar",
          "The Doon School",
          "Burans Khanda",
          "Park Road, Laxman Chowk",
          "IDPL Rishikesh",
          "Rajpur Post office",
          "Horawala",
          "Purkul Gaon",
          "Guchhupani",
          "Dharampur, Dehradun",
          "Hathibarkala",
          "Jaitanwala",
          "Dehradun--Clock Tower",
          "Jamoliwala",
          "Mussoorie--Barlowganj",
          "Rajpur--Shahenshahi Ashram",
          "Virbhadra Barrage, Rishikesh",
          "Dehradun (OBI)",
          "Indian Institute of Remote Sensing (IIRS), Dehradun",
          "Dakpatthar--GMVN Rest House",
          "Welham Boys' School",
          "Walterre Resort",
          "Barkot Reserved Forest, Dehradun",
          "LBSNAA--Happy valley to Polo grounds",
          "Nakraunda",
          "Song River Bridge Haridwar Road, Rishikesh,",
          "Mussoorie--Kincraig",
          "Deoban",
          "CASFOS--North Hostel",
          "Bijapur, Dehradun Cantonment",
          "Pritam Road",
          "Chandra Nagar Road",
          "Morowala, Haridwar Bypass Road",
          "Aam Wala",
          "Rishikesh (OBI)",
          "Gangotri Vihar, Canal Road",
          "Kolukhet",
          "Lakhwar",
          "Songtsen Library, Kirsali",
          "Ganga bank near Chilla",
          "ISBT Rishikesh, Uttarakhand,",
          "Sakya Hospital",
          "Rangers College",
          "Wildlife Institute of India (WII)--Nature Trail & Lake",
          "Jaswant Modern Senior Secondary School",
          "Suwakholi",
          "Kimadi--Bhitar Wali",
          "Cloud end--Dudhli village",
          "Kalu Sidh Temple, Dehradun (OBI)",
          "Dehradun Cantonment",
          "MDDA Colony, Chandar Road, Adhoiwala",
          "River Asan near Herbertpur (OBI)",
          "Company Garden - Dhobighat Trail",
          "Guniyal Gaon",
          "Aduwala Forest, Timli Range",
          "Jhajhra",
          "Jabarkhet Nature Reserve",
          "DEAL Colony",
          "Unnamed Road, Dehradun, Uttarakhand, IN (30.261, 78.039)",
          "Chilla Range--Ganga Bank",
          "FRI--Botanical Garden",
          "Doon University Campus",
          "Lachhiwala",
          "Lal Bahadur Shastri National Academy of Administration (LBSNAA)--Main Campus",
          "Kharakhet",
          "Raipur",
          "Masrana-Tipli Dhar-Bataghat",
          "Maldevta",
          "Doon Hospital",
          "Jamniwala",
          "Talai, Thano",
          "Doon Officers' Enclave, Chandrabani",
          "Curzon Road, Dalanwala, Dehradun",
          "Banjarawala",
          "Sigli",
          "Thano Reserve Forest",
          "Rajaji National Park--Kansrao--restricted access",
          "Indian Institute of Petroleum",
          "Kedarpur",
          "Dehradun--Mohkampur",
          "Mussoorie--Camel's Back Road",
          "Cloud end--Estate",
          "Polykids school",
          "Sarni",
          "Galjwari",
          "Vasant Vihar",
          "Lytton Road, Dehradun",
          "Mussoorie Town--General Area",
          "Tapovan",
          "Galajwari-3",
          "Malsi--Reserve Forest",
          "Fulsani, Dehradun",
          "Kaudiya/Gangabhogpur -- Talla",
        ],
      },
      {
        district: "Haridwar",
        localities: [
          "Aalia Jungle Bandarjudh",
          "Amichand Gaushala",
          "Balawali",
          "Bhimgoda barrage",
          "Bhimgoda Barrage",
          "Chandi Devi Temple",
          "Chandi Ghat Haridwar",
          "Chiriyapur -- Dipo",
          "CPU Office, Sector 1, BHEL",
          "Gaindi Khata",
          "Gujjar Basti, Gaindikhata",
          "Gujjar Basti Mazar Trail",
          "Haridwar (General) (OBI)",
          "Haridwar Railway Station",
          "Indian Institute of Technology (IIT) Roorkee",
          "Jattpura",
          "Jhilmil Jheel Conservation Reserve",
          "Kotawali",
          "Laksar Railway Junction",
          "Laldhang--Rasoolpur",
          "Mansa Devi Temple",
          "Nahar Pur Eastern Ganga Canal",
          "Nalowala",
          "New Shivalik Nagar Block-F",
          "Norangabad",
          "Pili Range, Hardwar",
          "Pond Sector 1, BHEL",
          "Railway Bridge Upper Ganga Canal Road, Roorkee,",
          "Rajaji National Park--Ranipur Range",
          "Rajaji NP",
          "Rajaji NP--Luni",
          "Rajaji NP--Mohand range",
          "Roorkee Laksar Road, Mutkarabad,",
          "Roshanabad",
          "Sajanpur Pili",
          "Shyampur",
          "Siddh Sot Shyampur  Forest Range, Uttarakhand,",
          "Sidhsot Van Gujjar Colony",
          "Simbal Sot Shyampur Forest Range",
          "Vishnu Ghat, Haridwar",
          "Yog Gram Haridwar",
        ],
      },
      {
        district: "Pithoragarh",
        localities: [
          "Adi-Kailash Trek (Malpa-Kuti and Nabhidang)",
          "Askote",
          "Askot Wildlife Sanctuary (OBI)",
          "Balati Bend",
          "Betuli-Kalamuni Road",
          "Birthi Waterfalls",
          "Budhi",
          "Chaukori",
          "Dharchula",
          "Ginni Bend",
          "Haat Kalika",
          "Himkhola",
          "Jauljibi",
          "Kajera--Forest Park",
          "Kalamuni Forest Trail",
          "Kalapani",
          "Kwiti",
          "Linthura, Pithoragarh",
          "Madkote",
          "Madkot-Munsyari road--Kirkhet",
          "Malla Sarmoli",
          "Malpa",
          "Martoli",
          "Misty Mountain",
          "Munsiyari--Khalia Top",
          "Munsiyari--Mesar Kund",
          "Munsyari",
          "Munsyari--Bheema Wodeyar",
          "Munsyari--Thamri kund",
          "Nabhidang",
          "Nanda Devi Mandir",
          "Narayana Ashram",
          "Pancheshwar",
          "Pangu",
          "Pithoragarh",
          "Sama",
          "Sarmoli",
          "Shankhdhura Forest",
        ],
      },
      {
        district: "Rudraprayag",
        localities: [
          "Agastmuni",
          "Ansuya",
          "Baniyakund",
          "Baniyari",
          "Chopta",
          "Chopta Valley (OBI)",
          "Deoriatal",
          "DEWAR",
          "Duggalbitta",
          "Gaurikund",
          "Guptakashi",
          "Kakragaad",
          "Kanakchauri",
          "Kanchula kharak",
          "Kedarnath",
          "Kund",
          "Madhyamaheshwar",
          "Madmaheshwar trek route",
          "Madola",
          "Makku Bend",
          "Makku Farm",
          "Makkumath",
          "Mandakini Bird Camp (OBI)",
          "Narkota",
          "near Jhenji, Chamoli (OBI)",
          "Pothiwasa (OBI)",
          "Ransi-Gaundar-Madmaheshwar",
          "Rudraprayag (OBI)",
          "Sari",
          "Silgaon Bhardar",
          "Silli",
          "Syalsour",
          "Tungnath",
          "Ukhi Math (OBI)",
        ],
      },
      {
        district: "Uttarkashi",
        localities: [
          "Assi Ganga--Kuflon",
          "Barkot",
          "Barkot Road, Tunalka",
          "Barnigad Forest Rest House",
          "Barsu",
          "Bhangeli Gidara Bugyal",
          "Bhatwari",
          "Byali",
          "Chaurangi Khal",
          "Chinyalisour",
          "Dayara Bugyal",
          "Dayara Bugyal Trek--Gui",
          "Dharwa Top",
          "Dhauntri, Uttarkashi,",
          "Dodi Tal",
          "Gangotri",
          "Gangotri National Park--Bhojbasa",
          "Govind Pashu Vihar NP & Sanctuary",
          "Har Ki Doon",
          "Har-Ki-Dun",
          "Harsil",
          "Janki Chatti",
          "Kot Bangalow, Uttarkashi",
          "Laldevta  Camp",
          "Manpur, Uttarakashi",
          "Mori",
          "Nelang Valley",
          "Netwar",
          "Purola",
          "Purola--Devta Range",
          "Sankri",
          "Taluka",
          "Thandi Uttarkashi",
          "Uttarkashi",
          "Yamunotri (OBI)",
        ],
      },
      {
        district: "Nainital",
        localities: [
          "Garjiya",
          "Tiffin Top",
          "IVRI Forest",
          "bajoon, camp wildlife",
          "Nainital--Kilbury, Pangot WLS",
          "Taj Corbett",
          "Pangot Forest Trail",
          "Bhowali",
          "Maheshkhan--RF",
          "I.V.R.I., Mukteshwar Campus",
          "Patkot Village (OBI)",
          "Barati Rao",
          "Ayan Jungle Lodge",
          "Mohan",
          "Nathuakhan",
          "Baba Haidakhan, chakrata range",
          "Teda Forest, Ramnagar (OBI)",
          "Gaduppu, Banna Khera Range",
          "Bhagotpur Tariyal",
          "Chaffi",
          "Lal Ghar, Lweshal",
          "Chanfi",
          "Nandhaur WLS--Nandhaur Range",
          "Pawalgarh Conservation Reserve",
          "Ghatgarh (OBI)",
          "Jeolikote--Camp Sparrow",
          "CheerPoint, Sigri",
          "Amdanda",
          "Vinayak Point",
          "Sattal--Lake, Uttarakhand 263136",
          "Dhela",
          "Nainital, uttarakhand",
          "Kiari Range",
          "Dhela Road Kanda Range",
          "Nalni",
          "Pawalgarh-Bhandarpani Road",
          "Mangoli",
          "Ghuggu Kham",
          "Sandani Gaja",
          "Sattal--Purna Tal",
          "Laduwarao",
          "Mehragaon, Nainital (OBI)",
          "Jim's Jungle Retreat, Dhela",
          "KMVN Sigri",
          "SatTal Forest Resort",
          "Bhimtal",
          "Bagar Road, Pangot",
          "Nandhaur Wildlife Sanctuary--Machhli Van",
          "Nainital",
          "G B Pant High Altitude Zoo",
          "PPP Homestay (Eco Harryman's homestay)",
          "Corbett NP--Dhela",
          "Kosi River",
          "Birders Den--Bhimtaal",
          "Bohra Koth",
          "Pawalgarh Conservation Reserve--Pawalgarh Village",
          "Aahana Resort, Savaldeh, Ramnagar",
          "Kaladhungi",
          "Sitabani",
          "Khurpatal",
          "Purna Tal, Bhowali Range, Uttarakhand",
          "Shyamkhet (OBI)",
          "Baret",
          "Kunjakharak",
          "Mukteshwar - Sitla Forest",
          "Darima",
          "Sattal Studio Bird Watching Point",
          "Naina Peak",
          "Gahna",
          "Ramnagar",
          "Ratta Forest",
          "Gairal",
          "Nainital--China Peak",
          "Raye Khata (EGS Kendra)",
          "Satbunga",
          "Kotdwar Road, Ramnagar",
          "Himalayan Botanical Garden",
          "Vinayak",
          "Kaladhungi Road",
          "Dhikuli",
          "Barati Rau, Chunakhan",
          "Lweshal, Nayeli",
          "Kalyanpur",
          "Ranikota (OBI)",
          "Nainital--Niglat (OBI)",
          "Pandaychore Road--Gaula River",
          "Pangot--Woodpecker Point",
          "Hartola",
          "Kotabagh",
          "Nainital -Ayar Jungle Camp",
          "Nandhaur Wildlife Sanctuary--Chorgalia",
          "Bhatrojkhan (OBI)",
          "Gaula River--Haldwani",
          "Satoli, Nainital (OBI)",
          "Haathidangar",
          "Jhoola Pul (Suspension Bridge), Garjiya, Ramnagar",
          "Sonapani",
          "Sattal--Garur Tal",
          "Nainital Lake",
          "Jungle Lore Birding Lodge",
          "Gangachaur Village",
          "Tiger Camp",
          "Dhanachuli",
          "Corbett NP--Dhangarhi",
          "Blue Magpie Homestay--Peora",
          "Ramnagar--Jungle Lore Resort",
          "Pathkote",
          "Pawalgarh Conservation Reserve--Hathigaliyar",
          "Nayagaon",
          "Dharampur Auliya",
          "Chhoti Haldwani",
          "Corbett NP--General",
          "Corbett NP--Bijrani Range",
          "Sigri jungle camp",
          "Syamkhat",
          "Chhoi",
          "Mall Road--Nainital",
          "Cheer Point, Vinayak Forest (Pangot)",
          "Kathgodam (OBI)",
          "Jilling Estate, near Bhowali (OBI)",
          "Corbett Riverside Resort, Dhikuli",
          "Ramnagar--Quality Inn",
          "Naukuchiatal",
          "Sunderkhal, Nainital (OBI)",
          "Musabangar",
          "Pangot",
          "Mukteshwar (OBI)",
          "Priya Bend",
          "Land's End Trail",
          "Ranibagh",
          "Koshi River -- Girija Devi Temple",
          "Uttarakhand Open University Campus",
          "Bhalon",
          "Sattal, Hari Lama's camp",
          "Prabhu's Bird Photography Hide",
          "Sattal--vicinity",
          "Camp Milieu",
          "Bagar Talla",
          "Pangot--Cheer Point",
          "Kainchi Temple",
          "Nalni Estate, Nainital (OBI)",
          "Kumeriya RF (OBI)",
          "Pande Gaon (OBI)",
          "Jeolikote",
          "Ramgarh, Nainital (OBI)",
          "Pawalgarh",
          "Kosi Barrage, Ramnagar",
          "Camp Hornbill,Kyari",
          "Dudhkanie Dhar, Uttarakhand, IN (29.441, 79.588)",
          "Brahmasthali",
          "Butterfly Research Centre- Bhimtal",
          "Sunkiya Forest",
          "Sigri",
          "Ramnagar--Kosi River",
          "Ramgarh",
          "Narsinghpur Arera",
          "Kalapatal",
          "Bhimtal--Birder's Den Eco Friendly Resort",
          "Teda",
          "Kilbury Bird Sanctuary i(OBI)",
          "Sattal--Christian Ashram Estate",
          "Ayarpatta",
          "Sattal",
          "Sariyatal Lake",
          "Kunjakharak (OBI)",
          "Sattal Studio",
          "Garjiya Tourism Zone, Corbett Tiger Reserve",
          "Corbett River View Retreat, Garjiya, Ramnagar",
          "Pangoot Luxury Homes",
          "Sawal Deh",
          "Chamfi",
        ],
      },
      {
        district: "Almora",
        localities: [
          "Almora Town",
          "Anant Rasa - Sitlaketh Road",
          "Babar Khola Village (Raniketh Almora Rd)",
          "Bhaunkhal",
          "Binsar Forest Rest House",
          "binsar forest retreat",
          "Binsar Forest Retreat",
          "Binsar Sanctuary--Gaunap",
          "Binsar Wildlife Sanctuary",
          "Camp Forktail Creek Lodge",
          "Champanagar (OBI)",
          "Chiliyanaula (OBI)",
          "Chimtakhal",
          "Corbett NP--Bhakrakot",
          "Dalmoti Forest Range",
          "Dandeshwar",
          "Dhaulchhina",
          "Dotiyal (OBI)",
          "Durgadevi",
          "GBPNIHESD, Kosi-Katarmal, Almora",
          "Ghweri",
          "Jageshwar",
          "Jageshwar--Van Serai",
          "Kausani",
          "Kausani Eco Park",
          "Khali Estate",
          "Kharkiya",
          "Kwarali Village",
          "Lebua Corbett Saraca Estate",
          "Lohachaur, Corbett NP",
          "Maini's Hill Cottages",
          "Manila",
          "Manila Birding Lodge",
          "Marchula",
          "Mrig Vihar Zoo (Baldhauti)--Almora Zoo",
          "Mystic Mountain Resort",
          "Mystic Mountain Resort Birding Hotspot",
          "Pant Kotuli",
          "Ranikhet Dumpyard",
          "Ranikhet (OBI)",
          "Rudradhari Waterfall",
          "Seraghat",
          "Shitlaket",
          "The Dozen Oaks, Kayala",
          "The Himalayan Outback",
          "Vanghat",
          "Vriddha Jageshwar Dham",
        ],
      },
      {
        district: "Chamoli",
        localities: [
          "Ansuya",
          "Ansuya Devi Trek",
          "Auli",
          "Badhangarhi, Taal",
          "Bainoli",
          "Bedeni Bugiyal (OBI)",
          "Bhagwa Basa",
          "Bhulkan",
          "Bhulkan (OBI)-- General Area",
          "Bhyudar",
          "Bulkana-Tungnath, Kedarnath Widlife Sanctuary",
          "Gairsain (OBI)",
          "Garur Ganga",
          "Ghangaria (OBI)",
          "Govind Ghat",
          "Govindghat Hemkund Sahib trekking trail",
          "Hanuman Chatti",
          "Hemkunt Sahib",
          "Joshimath (OBI)",
          "Kanchula Kharak",
          "Kanchula Kharak, Kedarnath Wildlife Sanctuary",
          "Kedarnath WLS",
          "Khullara Campsite",
          "Kuari Pass (OBI)",
          "Mana Uttarkhand (OBI)",
          "Mandal--General Area (OBI)",
          "Mandal--Herbal Research Centre",
          "Mandal, Kedarnath Wildlife Sanctuary",
          "Mohankhal, Kedarnath Wildlife Sanctuary",
          "Nanda Devi NP--General Area",
          "Near Badrinath (OBI)",
          "Niti",
          "Pyura (OBI)",
          "Roopkund",
          "Shokharak",
          "Siran",
          "Talwari",
          "Ukhimath,  Chopta - Gopeshwar Road",
          "Urgam Valley",
          "Urgam Valley--Devgram",
          "Urgam Valley--Lyari Than",
          "Urgam Valley--Urgam",
          "Valley of Flowers National Park",
          "Valley of Flowers (OBI)",
          "way towards Karnaprayag (OBI)",
        ],
      },
      ,
      {
        district: "Tehri Garhwal",
        localities: [
          "Buddha Kedar",
          "Camp Xterra, Dehradun (OBI)",
          "Chamba",
          "Devalsari ",
          "Devalsari--Deodar Ecotourism & Research Centre (DERC)",
          "Devprayag",
          "Dhanaulti",
          "Dhaula Giri",
          "Glasshouse on the Ganges",
          "JW Marriott Mussoorie",
          "Kanatal--Horticulture Fields",
          "Kanatal--Rausli Khal",
          "Kempty Waterfalls",
          "Kith, Uttarakhand,",
          "Kunjapuri",
          "Kunjapuri-Tapovan Rishikesh",
          "Magra Forest Rest House",
          "Mahendrapur",
          "Nag Tibba",
          "Narendra Nagar",
          "New Tehri",
          "Rajaji NP--Gohri Range",
          "Rishikesh--Tapovan",
          "Srinagar, Joshimath (OBI)",
          "Surkanda Devi Temple, Chamba-Mussoorie Road",
          "Tehri Garhwal (OBI)",
          "Teva Village",
          "Thatyur  (OBI)",
        ],
      },
    ],
  },
  {
    state: "West Bengal",
    districts: [
      {
        district: "Birbhum",
        localities: [
          "Bagdola",
          "Bakreshwar Dam (Nil Nirjon)",
          "Ballavpur WLS--General Area",
          "Ballavpur WLS Jheel 1",
          "Ballavpur WLS Jheel 2",
          "Ballavpur WLS--Jheel 3",
          "Belia",
          "Bidyadharpur--Kopai River Bank",
          "Chowpahari, Islambajar, Birbhum",
          "Chowpahari Jungle Beside BABLI Wilderness Farm",
          "Dakshin Harirampur",
          "Debaipur",
          "Haripur",
          "Jagadhari",
          "Lalbandh",
          "New Ash Pond Kachujor",
          "Parulia",
          "Santiniketan Ashrama Campus",
          "Sonajhuri Forest",
          "Tarapith (OBI)",
          "Tilpara Barrage",
        ],
      },
      {
        district: "Uttar Dinajpur",
        localities: [
          "Abdulghata",
          "Abdulghata Forest",
          "Baharail Forest",
          "Dwiprajar Dighi",
          "Nehari Beel",
          "Raiganj Bird Sanctuary",
        ],
      },
      {
        district: "North 24 Parganas",
        localities: [
          "Adamas University",
          "AD Block Canal side Park",
          "Aminpur",
          "Bally Bridge",
          "Bangaon Forested Patch",
          "Barasat Government College",
          "Barbaria Beel",
          "Barrackpore Dhobighat Adjacent area",
          "Bazetaraf",
          "Beliaghata Wetland",
          "Bibhutibhushan WLS",
          "Bonhoogly Lake",
          "Bortibeel",
          "Cando Infospace",
          "Central Park (Banabitan)",
          "Corner Plot ST 706 x ST 777",
          "Dakshineswar Kali Temple",
          "Dhanyakuria, Nadia",
          "Duttabad,Salt Lake",
          "Eastland Jheel",
          "Eco Park",
          "Eco Park--Butterfly Garden",
          "Eco Urban Village",
          "Fatuapur - Milangarh",
          "Fishery Ponds beside Thakdari Road",
          "Halisahar Railway Yard",
          "Hooghly, West Bengal (OBI)",
          "ISI Kolkata",
          "Jawahar Kunj",
          "Kamduni",
          "Kharibari",
          "Kheblir Beel",
          "Kolkata, West Bengal (OBI)",
          "Mahish Bathan",
          "Mathura Bil",
          "Mitpukur",
          "Mudia",
          "Nalban Bheri",
          "Nandagarh, Barasat",
          "Navadarsha Co-operative Housing Society",
          "Netaji Colony",
          "Netaji Shubhash Chandra Bose International Airport",
          "New Barrackpore, Kolkata",
          "New Barrackpur, Kolkata",
          "Rajarhaat Canal Area Beside ST 380",
          "Rajarhat Grassland near Aliah University Bus Stop",
          "Rajarhat NKDA Park",
          "Rajarhat Wetland--Canal side only",
          "Rajarhat Wetland--Opposite New Stadium",
          "Rajarhat Wetlands I",
          "Rajarhat Wetlands--Near Jatragachhi Flyover",
          "Rajarhat Wetlands--Proposed Silicon Valley",
          "R.B.G. Mukherjee Road, Panihati",
          "Sahapur",
          "Samsernagar",
          "Sandeshkhali PWD Bunglow",
          "Sarojini Naidu College for Women",
          "Shanpukur Mathpara",
          "Shapoorji Complex",
          "Shribhumi, Kolkata (OBI)",
          "Vedic Village (OBI)",
          "Wireless Ground",
        ],
      },
      {
        district: "Nadia",
        localities: [
          "Bahadurpur Reserve Forest",
          "Bahadurpur Village",
          "Bahirgachi",
          "Balakhana Estate, Maheshganj",
          "Bangabani Ghat,Nabadwip",
          "Bethuadahari WLS",
          "Debagram Forest",
          "Hijuli Forest",
          "Indian Institute of Science Education and Research (IISER) Kolkata",
          "Jaguli Grassland",
          "Kamalapur",
          "Khisma Forest",
          "Krishnanagar Rajbari",
          "Kulia Jhill",
          "Mangaldeep Char",
          "Nayachar",
          "Patikabari",
          "Raghunathpur Forest",
          "Rawtara Oxbow lake",
          "Saheb Bagan",
          "Sambhunagar",
          "Shankarpur Forest",
        ],
      },
      {
        district: "Cooch Behar",
        localities: [
          "Cooch Behar Railway Station",
          "Rasik Bill Bird Sanctuary",
          "Sal Bagan Park",
          "Teesta riverbank near Joyee bridge",
          "Uttar Banga Krishi Viswavidyalaya",
        ],
      },
      {
        district: "Kolkata",
        localities: [
          "Agriculture Horticultural Society",
          "Alipore Zoo",
          "Bhawanipur--River Bank of Adi Ganga",
          "Brace Bridge (Historical)",
          "Chetla Forest",
          "Deshbandhu Park",
          "Eden Gardens",
          "Harish Park",
          "Hatibagan (Kolkata)",
          "Judges Ghat",
          "Maidan",
          "New Alipore Triangular Park",
          "Northern Park",
          "Orbit Pond",
          "Rabindra Sarovar (including Lion's Safari Park)",
          "Rawdon Square",
          "St Paul's Cathedral",
          "Strand Bank Road",
          "Subhash Sarobar",
          "Tala Park",
          "Taratala Nature Park",
          "Victoria Memorial",
        ],
      },
      {
        district: "Jalpaiguri",
        localities: [
          "Ananda Chandra College",
          "Apalchand Forest",
          "Baikunthapur Forest",
          "Bengal Safari",
          "Bhatikhana Wetlands JG",
          "Bhorer Alo",
          "Binnaguri Cantonment JG",
          "Chapramari Forest Railway Level crossing",
          "Chapramari Wildlife Sanctuary JG",
          "Chapramari WLS--Chapramari Watchtower",
          "Domohani Wetland JG",
          "Dreamland Resort Dakshin Dhupjhora JG",
          "Fulbari Area JG",
          "Fulbari Grassland",
          "Fulbari Wetlands JG",
          "Gajoldoba Barrage JG",
          "Gora More Siliguri",
          "Gorumara Jungle Camp",
          "Gorumara National Park",
          "Gorumara National Park--Butterfly Garden JG",
          "Gorumara National Park--Medla Watch Tower JG",
          "Gorumara National Park_Upper Tendu Range JG",
          "Gorumara NP--Jatra Prasad Watch Tower",
          "Gorumara NP JG",
          "Gorumara NP_Khunia Forest JG",
          "Gorumara NP--Mahakal Trail",
          "Gorumara NP--Murti Forest JG",
          "Gorumara NP_Murti Waterhole JG",
          "Jalpaiguri JG",
          "Karala River Bank",
          "Lalitabari-Gajoldoba Road JG",
          "Lataguri JG",
          "Lataguri (OBI)",
          "Mainaguri Road Wetlands JG",
          "Mal Bazar JG",
          "Manabari JG",
          "Maynaguri (OBI)",
          "Moraghat Forest JG",
          "Murti River JG",
          "Murti-WB (OBI)",
          "Neora Jungle Camp",
          "Neora Valley--Samsing KP",
          "Phulbari Barrage, near Siliguri (OBI)",
          "Ramsai JG",
          "Rangdhamali (OBI)",
          "Salugara",
          "Sinclair's Retreat JG",
          "Teesta 14 no Spur",
          "Teesta Riverbank near Teesta Bridge, JG",
          "Teesta riverbed JG",
          "Teesta Riverbed--No. 10 spar",
          "Teesta Spar JG",
          "Tiyabon Resort Campus JG",
        ],
      },
      {
        district: "Dakshin Dinajpur",
        localities: [
          "Kaldighi Park",
          "Punarvabha Riverbed--Gangarampur",
          "Saraihat Bajedonor Farmlands",
        ],
      },
      {
        district: "Alipurduar",
        localities: [
          "Barobisha",
          "Bhutan Ghat AD",
          "Buxa Forest",
          "Buxa Hill Forest AD",
          "Buxa Tiger Reserve AD",
          "Buxa Tiger Reserve--Buxa Fort AD",
          "Buxa Tiger Reserve--Chunavati AD",
          "Buxa Tiger Reserve--Ghoradunga AD",
          "Buxa Tiger Reserve--Jainti AD",
          "Buxa Tiger Reserve--Lepchakha AD",
          "Buxa Tiger Reserve--Oumchupani AD",
          "Buxa Tiger Reserve--Raimatang AD",
          "Buxa Tiger Reserve--Rajabhatkhawa AD",
          "Buxa Tiger Reserve-Santrabari AD",
          "Buxa Tiger Reserve--Tashi Gaon",
          "Buxa TR-28 Mile Busti AD",
          "Dalsingpara Tea Garden AD",
          "Hasimara AD",
          "Jaigoan AD",
          "Jaldapara NP--Chilapata Reserve Forest AD",
          "Jaldapara NP--General Area",
          "Jaldapara NP--Hollong AD",
          "Jaldapara NP--Jaldapara Tourist Lodge AD",
          "Jayanti, Alipurduar (OBI)",
          "Khairbari Tiger Rescue Center AD",
          "Rover's point,Buxa Tiger Reserve",
          "Sikia Jhora AD",
          "The Dooars (OBI)",
          "Uttar Chakoakheti",
        ],
      },
      {
        district: "Jhargram",
        localities: [
          "Amjharna Birding Trail",
          "Bhasraghat, JGM",
          "Brindabanpur JGM",
          "Dubrajpur JGM",
          "Jamboni Rd JGM",
          "Jhargram Deer Park surroundings JGM",
          "Jhilli Pakhiralay JGM",
          "Kankabati PM",
          "Kankrajhore Village",
          "Kendua , JGM-Jamboni Rd",
          "Khandarani Lake",
          "Khechenda Dam JGM",
          "Krishnapur JGM",
          "Mayurjharna Elephant Reserve--General Area",
          "Nepura",
        ],
      },
      {
        district: "Kalimpong",
        localities: [
          "Algarah",
          "Algarah-Lava Road KP",
          "Bidyang",
          "Bindu KP",
          "Changey Falls KP",
          "Charkhole KP",
          "Chel Riverbed KP",
          "Chumirchen (OBI)",
          "Delo Hills KP",
          "Doorkhola",
          "Gairibas_Jaldhaka region KP",
          "Garubathan - Lava Road",
          "Git Kolbong KP",
          "Icche Gaon, KP",
          "Jhalong KP",
          "Jhandi,Kalimpong",
          "Jhandi-- Nim Khasmahal KP",
          "Jhandi--Upper Lungsel KP",
          "Kaffir Forest KP",
          "Khoppi",
          "Kolakham Village KP",
          "Kolbong Forest KP",
          "Kumai Tea Garden KP",
          "Lava--General Area KP",
          "Lava-Kolakham Forest Road KP",
          "Lava- Rishop Forest Road",
          "Lava WB Forest Dept lodge",
          "Lolegaon KP",
          "Lower Rishop Road, Lava KP",
          "Mahananda WLS KP",
          "Mangal Dham Temple KP",
          "Milan Top KP",
          "Mouchuki Forest Bungalow",
          "Neora Valley Jungle Camp, Kolakham KP",
          "Neora Valley NP--Bhote Kharka KP",
          "Neora Valley NP--Chaudaferi Forest Camp KP",
          "Neora Valley NP--General Area KP",
          "Neora Valley NP--Suntalekhola KP",
          "Nimbong Khasmahal KP",
          "Paporkheti",
          "Paren Jaldhaka",
          "Paren KP",
          "Pedong Forest KP",
          "Ramdhura KP",
          "Raset Road KP",
          "Rikisum",
          "Rishop - 6th Mile Road",
          "Rishop--General Area KP",
          "Sakam",
          "Sambiyong Tea Garden KP",
          "Samsing",
          "Sillery Gaon KP",
          "Teesta Dam Lake",
          "Teesta Low Dam III KP",
          "Tiffin Dara",
          "Todeykhola Riverbed",
          "Upper Fagu Tea Garden KP",
          "Zang Dhok Palri Monastery KP",
        ],
      },
      {
        district: "South 24 Parganas",
        localities: [
          "Ahalyanagar Wetland",
          "Amlamethi Chaar",
          "Anandapur",
          "Bakkhali Beach",
          "Bakkhali Casuarina Grove",
          "Bakkhali--General Area",
          "Bakkhali Village",
          "Bali Island-Bali Village",
          "Baruipur Marshes",
          "Bata Club",
          "Beach between Frasergunj and Bakkhali",
          "Behala Flying Club Road",
          "Bhasha-Parbatipur Grassland",
          "Bonnie Camp",
          "Buderhat Lake",
          "Budge Budge (OBI)",
          "Burirdabri",
          "Canning 24S",
          "Chak Garia Wetland",
          "Chintamani Kar Bird Sanctuary (CKBS)",
          "Chowbaga Canal Side Road",
          "Dhapa Garbage Dump",
          "Dhyan Ashram Wetland",
          "Dingelpota",
          "Dulki 24S",
          "Falta",
          "Fishery Ponds around Hatgachia and Dharmatala Pachuria",
          "Frazerganj Beach",
          "Frazergunj--General Area",
          "Frazergunj Village",
          "Ganga Joara Road",
          "Ghoramara Island",
          "Gitanjali Park",
          "Gopalpur",
          "Gourangatala More",
          "Green View",
          "Hanspukuria Joka Canal Rd",
          "Haridevpur Pukur",
          "Hatishala Wetlands (Behind ITC Infotech)",
          "Henry's Island",
          "Hindustan Park Pond",
          "Hussainpur Wetland",
          "Jadavpur CR Colony",
          "Jadavpur University (Restricted Access)",
          "Jadu Mitra Colony Pond",
          "Jambu Dweep Sandbar",
          "Jharkhali",
          "Jharkhali dock",
          "Jodhpur Park",
          "Joka Chotokachari",
          "Joka Wetlands",
          "Joka Wetlands (Historical)",
          "Kalikapur Wetlands",
          "Kalisthan beach",
          "Kargil Beach",
          "Kasba",
          "Koikhali (OBI)",
          "Mallikpur Dakshin Hindupara",
          "Masjid Bari Road",
          "Master Society Marshes",
          "Matla River Bank, Canning",
          "Mirania Bheri",
          "Monmohan park",
          "Mousuni Island Beach",
          "Namkhana",
          "Narendrapur (OBI)",
          "Noorpur",
          "Nuner Bheri",
          "Oxidation Pond",
          "Pailan, Kolkata (OBI)",
          "Pakhiralay Village",
          "Pashchim Putiary Jheel",
          "Patibunia Beach",
          "Patuli",
          "Piyali Island Tourist Lodge",
          "Raghabpur",
          "Raichak (OBI)",
          "Rajarhat New Town (OBI)",
          "Sagar Island",
          "Sankharipota",
          "Sarkarpool Wetland",
          "Science City",
          "Shyamkhola",
          "Sonarpur, Kolkata (OBI)",
          "Sun City",
          "Sundarban NP--Bonnie Camp",
          "Sundarbans Jungle Camp",
          "Sundarbans NP--Sudhanyakhali Watch Tower",
          "Sundarban Tiger Reserve--Dobanki Camp",
          "Sundarban Tiger Reserve--General Area",
          "Sundarban Tiger Reserve--Kendo Island",
          "Sunderbans Tiger Reserve--Chamta Camp",
          "Survey Park",
          "Tollygunge Club",
          "Vidyasagar State General Hospital Pond",
          "Vivekananda College",
        ],
      },
      {
        district: "Malda",
        localities: [
          "Aamjaam Tola Birding Area",
          "Adina Deer Park",
          "Atla Beel, Begunbari",
          "Baharal",
          "Bamangola",
          "Bara Beel and Mahan Beel",
          "Bara Sagardighi Fish Farm",
          "Bhatra Beel",
          "Bishnuprasad",
          "Bolatuli Beel",
          "Chhatra Beel",
          "Dahala Wetland",
          "Dari Doba Beel",
          "Dharampur",
          "Farakka Barrage--Kargil Chaur",
          "Farakka IBA Panchanandapur",
          "Firoz Minar",
          "Gour Road and Ruins Of Gour",
          "Hatimari Salbona Forest",
          "Jagjibanpur at Habibpur Block",
          "Karja Danga Forest Beat",
          "Mahakalbona Beel",
          "Mahananda River",
          "Malda Aerodrome",
          "Malda District",
          "Malda Food park",
          "Malda Town",
          "Manikchak Ghat Road",
          "Manikchak, Mango Orchards Behind Sikkha Niketan HS",
          "Muchia Birding Site",
          "Nagri River between Boirgachi and Joyhat",
          "Nawabganj Badonpur Birding Area",
          "Naya Bundh Wetland Complex (IBA)",
          "Pathar Khairan--Jamalpur Forest Beat",
          "Rabindra Bhavan",
          "Ramnagar--Fulahar Riverbank Malda",
          "Singabad Tilason",
          "Sohorol Forest--Rajadighi Forest Beat",
          "Sohorol Forest, Rajadighi Forest Beat",
        ],
      },
      {
        district: "Darjeeling",
        localities: [
          "8 Mile Market DG",
          "Ahaldara-Shelphu top DG",
          "Area Near Sevoke Railway Station DG",
          "Bagdogra Airport DG",
          "Baghora Road",
          "Bagora Forest DG",
          "Bamonpokhari Forest DG",
          "Barbotey Rock Garden",
          "Bhikeybhanjan DG",
          "Bijanbari",
          "Bungkulung DG",
          "Camp, Rangli Forest",
          "Chitre DG",
          "Darjeeling--General Area DG",
          "Darjeeling--Himalayan Mountaineering Institute DG",
          "Dhotrey DG",
          "Dowhill (OBI)",
          "Dudhia DG",
          "Gairibas Old Jeep Road",
          "Gitingey Tea Garden DG",
          "Glenburn Tea Estate",
          "Gorkhey DG",
          "Gurdum DG",
          "Happy Valley Tea Estate",
          "Hill Cart Road birding area (Paglajhora area)",
          "Jalapahar",
          "Jore Pokhri WLS DG",
          "Kaiakata DG",
          "Kalijhora DG",
          "Kalipokhri",
          "Karmi Farm, Kolbong",
          "Khoribari Village",
          "Kurseong (OBI)",
          "Lamahatta",
          "Lamahatta Garden DG",
          "Lameydhura (OBI)",
          "Latpanchar-Kalijhora Road",
          "Latpanchar--Nursery area",
          "Latpanchar--Stream Area",
          "Lepcha Jagat DG",
          "Lloyd Botanical Garden",
          "Lodhoma DG",
          "Lopchu khasmahal",
          "Mahananda WLS--General Area DG",
          "Mahananda WLS--Latpanchar DG",
          "Mahananda WLS--Latpanchar Forest Rest House",
          "Mahananda WLS--Nurbong Tea Garden",
          "Mahananda WLS--Rongtong and Shivakhola Forest DG",
          "Mahananda WLS--Sevoke DG",
          "Mahananda WLS--Sittong DG",
          "Mahananda WLS--Sukna Forest DG",
          "Majua Rangeet ,Sawaji Khola River DG",
          "Mall Road",
          "Manebhanjang DG",
          "Mirik Bustee DG",
          "Mirik Lake DG",
          "Mungpoo DG",
          "North Bengal University (Restricted Access) DG",
          "Padmaja Naidu Zoological Gardens",
          "Paglajhora Falls",
          "Paglajhora Forest",
          "Palmajua Forest Road DG",
          "Pasupati Nagar (OBI)",
          "Peshok DG",
          "Prakashnagar (OBI)",
          "Rangaroon Tea Garden",
          "Rangbul, Old Military Rd DG",
          "Rangli Forest",
          "Rishi, Darjeeling (OBI)",
          "Rohini, on the way to Darjeeling (OBI)",
          "Sandakhphu NP",
          "Senchal Wildlife Sanctuary--Chatakpur DG",
          "Senchal WLS--General Area",
          "Senchal WLS--Tiger Hill DG",
          "Shrubbery Nightingale Park",
          "Singalila NP_Between Sandakphu & Kalipokhri DG",
          "Singalila NP--Gairibas DG",
          "Singalila NP--General Area DG",
          "Singalila NP_Kalipokhri DG",
          "Singalila NP--Lamey Dhura",
          "Singalila NP_Phalut DG",
          "Singalila NP_Tonglu DG",
          "Singalila NP--Tumling DG",
          "Srikhola DG",
          "State Highway 12, DG",
          "Tabakoshi",
          "Takdah (OBI)",
          "Tea Garden Nr Shivkhola Temple",
          "Tinchule Forest",
          "Tinchuley Village",
          "Tinchuley Village DG",
          "Triveni DG",
        ],
      },
      {
        district: "Bankura",
        localities: [
          "Bamandihi--Damodar River Bank",
          "Bardi Pahar",
          "Basudebpur",
          "Basudebpur chatal",
          "Basudebpur Runway",
          "Beliatore",
          "Bhagalpur",
          "Bhat Pushkarini",
          "Biharinath Hill",
          "Biharinath Hill Base",
          "Biharinathparbat, Saltora Road, West Bengal, IN",
          "Deuli More",
          "Durgapur Barrage",
          "Durgapur Barrage--Riverbed",
          "Dwarakeswar River Bed",
          "Jhero",
          "Joypur Forest",
          "Kadam Deuli Dam",
          "KalaJharia Burning Ghat PAB",
          "Kharda road",
          "Lal Bandh",
          "Mejia--Damodar River Bank",
          "Mukutmanipur",
          "Mukutmanipur Dam--Sibrampur",
          "Near Rabindra Bhawan, Burdwan",
          "Saharjora Forest",
          "Sarigari",
          "Sonadaha",
          "Sonamui",
          "Srirampur--Damodar River Side",
          "Sushunia",
          "Sutan Forest",
        ],
      },
      {
        district: "Howrah",
        localities: [
          "58 Gates,Garchumuk",
          "Ã¨â€“Â©Ã§â€œÂ¦Ã¤Â¼Å Ã©Â¦Â¬Ã¥Â¤Å¡Ã¦â„¢Â®Ã§Ë†Â¾, Ã¦â€¹â€°Ã¨Â³Ë†Ã¦â€“Â¯Ã¥ÂÂ¦Ã©â€šÂ¦, IN (26.044, 76.40",
          "Acharya Jagadish Chandra Bose National Botanic Gardens",
          "Alampur",
          "Alipur, Mathapara, Shyampur",
          "Amta Grasslands",
          "Anjanapura Central ParkÃ¢â‚¬â€œterrestrialÃ¢â‚¬â€œthorny scrubÃ¢â‚¬â€œPMP",
          "Bagnan--Rupnarayan River Front",
          "Bangalpur",
          "Benapur",
          "Bokpota",
          "Dankuni Jheel",
          "Deulpur",
          "Deulpur Panchayat",
          "Dihimandalghat",
          "Gadiara",
          "Howrah General Area (OBI)",
          "Husainpura Bridge   Ã Â²Ã Â§ÂÃ Â¦Â°Ã Â¦â€”Ã Â¦Â¤Ã Â¦Â¿ Ã Â¦ÂªÃ Â¦Â¾Ã Â¦Â Ã Â¦Â¾â€¡Ã Â²Â¤Ã Â³ÂÃ¦Â°)",
          "Kalbansh",
          "Karizanga NPÃ¢â‚¬â€Central Range grasslands by Indian ElephantÂ¦Â¾Ã Â¦Â Ã Â¦Â¾Ã Â¦â€”Ã Â¦Â¾Ã Â¦Â°)",
          "Khaira",
          "Khajurnan",
          "Kona Bheri",
          "Lalteghar",
          "Maheshpur, Ghoshpara, Uluberia",
          "Mahishrekha Forested Patch",
          "Mohishrekha, Bagnan",
          "Narit, Amta",
          "Paschim Gazipur, Amta",
          "Pragati Pathagar (Ã Â¦ÂªÃ Â§ÂÃ Â¦Â°Ã Â¦â€”Ã Â¦Â¤Ã Â¦Â¿ Ã Â¦ÂªÃ Â¦Â¾Ã Â¦Â Ã Â¦Â¾Ã Â¦â€”Ã Â¦Â¾Ã Â¦Â°)",
          "Santragachi Jheel (Lake)",
          "Shibpur Ferry Ghat",
        ],
      },
      {
        district: "Murshidabad",
        localities: [
          "Ahiron Wetland",
          "Andulia",
          "Ash Pond of Farakka Thermal Power Project",
          "Bachra Kamartala",
          "Bazarsau",
          "Bazarsau Railway Station adjacant area",
          "Bazarsau Talikhola",
          "Bhandardaha Bill",
          "Bhundar Kismat",
          "Bidupara",
          "Binpara",
          "Bishurpukur",
          "Catholic Church",
          "Chaltia Bil",
          "Charnarayanpur",
          "Chuanpore Power Station",
          "Chumarigacha",
          "Chumrigacha",
          "Chunapure",
          "Cossimbazar, Kati Ganga",
          "Dafarpur Bill",
          "Dakshin Hijal",
          "Dayanagar Kuthipara Oxbow Lake",
          "Dukhur Bil",
          "Farakka Barrage Tal Tola Ghat",
          "Farakka Barrrage",
          "Gajdhar para",
          "Gangarampur Village",
          "Gholla Beel",
          "Gorabajar",
          "Gorduara Land Registration office",
          "Gouripur Beel",
          "Kadkhali",
          "Kalaberia",
          "Kandi",
          "Kandi Bus Stand",
          "Karna Subarna bheel",
          "Katra masjid",
          "Khagraghat",
          "Khairamari Bill",
          "Kiriteswari Temple",
          "Korea",
          "Lalbag Court Station",
          "Lalnagar",
          "Majhdiya",
          "Manganpara Char",
          "Matiara",
          "Mehedipur Bypass",
          "Monigram Ash Pond",
          "Motijheel park",
          "Nabagram",
          "Nonai Bridge",
          "Old Karbala",
          "Pakuria",
          "Patamari Village",
          "Pulinda",
          "Ramnagar Bachra Bill",
          "Rampara",
          "Sagardighi Ash Pond",
          "Sahajadpur",
          "Sahapur Beel (Beelkurul)",
          "Saktipur Sosan Ghat",
          "Sargachhi",
          "Sekedarpur Chorer Gaba",
          "Sheeal Lake",
          "Shibnagar Ferry Ghat",
          "Sujapur Oxbow Lake",
          "Sundalpur Ferry Ghat",
          "Sundoriya Bill",
          "Telkar Beel",
        ],
      },
      {
        district: "Hooghly",
        localities: [
          "Baidyabati DVC Canal",
          "Bally Khal",
          "Bandel Junction Adjacent Lake",
          "Boshipota",
          "Chandan Nagar - Narua Joramandir Tala Ponds",
          "Chandanpur",
          "chinsurah farm",
          "Dankuni Wetland",
          "Dey Ghat",
          "Dhopaghata birding spot",
          "Diara",
          "Dunlop Estate",
          "Erenga, Polba,",
          "Haripal (OBI)",
          "Jirat",
          "Kajla Dighi",
          "Land for Tata Project, Singur",
          "Malipukur Pond",
          "Mankundu",
          "Mollarber",
          "Nabagram 1 no. ground",
          "Narkolpota tentulpota",
          "Pond off Trauma Care Centre Rd",
          "Purushottampur",
          "Serampore College Main Campus",
          "Serampore (OBI)",
          "Uttarpara (OBI)",
        ],
      },
      {
        district: "Purba Medinipur",
        localities: [
          "Baguran Jalpai Beach",
          "Bakiput",
          "Begunbari",
          "Deulia Bheri",
          "Digha",
          "Digha Shankarpur estuary",
          "Hijli Sea Beach",
          "Jaldha",
          "Kanai chatta",
          "Kotlauri Bhagabanpur",
          "Mandarmani",
          "Mayachar",
          "Meidinagar Beach",
          "Moyna",
          "Patanda (OBI)",
          "Pitambarchak (OBI)",
          "Sasan Kali Road",
          "Tajpur",
          "Tamluk",
          "Vidyasagar Park",
        ],
      },
      {
        district: "Paschim Bardhaman",
        localities: [
          "Ambuja Wetland PAB",
          "Andal Old Aerofield PAB",
          "B. B. College, Asansol PAB",
          "Bhuta Buri Mandir",
          "Deul Forest",
          "Durgapur Government College",
          "Durgapur Steel Plant Airport PAB",
          "Faridpur",
          "Gandhi More PAB",
          "Garh Jungle PUB",
          "Gunjan Ecological Park",
          "Gunjan ecological park PAB",
          "Hanuman Mandir Dam PAB",
          "Hirapur MCT Institution",
          "Karnail Singh Park PAB",
          "Kulti",
          "Madhaiganj Forest",
          "Madhaigunj Jagannathpur Old Runway",
          "Maithon Dam--Majhladi",
          "Maithon Dam PAB",
          "Mohon Kumar Mangalam Park PAB",
          "Pardaha PAB",
          "Sankarpur Outskirts, Durgapur PAB",
        ],
      },
      {
        district: "Paschim Medinipur",
        localities: [
          "Airbase ,Kharagpur PM",
          "Arabari PM",
          "Bandhi PM",
          "Barkola wetland",
          "Belia Farmlands",
          "Bhadutola Reserve Forest PM",
          "Birsingha PM",
          "Chowringee PM",
          "Cossye river bank PM",
          "Dalapatipur Village",
          "Ghuchisol PM",
          "Gokulpur",
          "Gopegarh PM",
          "Gymkhana Island",
          "Hijli Forest PM",
          "Indian Institute of Technology (IIT) Kharagpur PM",
          "Kangshabati River bank",
          "Keshiary PM",
          "Khajra",
          "Koilash",
          "Kunjapur (OBI)",
          "Midnapur (OBI)",
          "Nandapur--Kapaleswari River Bank PM",
          "Parimal Kanan PM",
          "Rajagram PM",
          "Rampur (OBI)",
          "Rampur Village (OBI)",
          "Salua Forest Renge PM",
          "Sijua PM",
          "Tamlipara , School Bazar PM",
        ],
      },
      {
        district: "Purulia",
        localities: [
          "Ajodhya Hill",
          "Asanpani",
          "Balrampur",
          "Baranti Reservoir",
          "Bara Panjania Hills",
          "Bathandhi hills",
          "Chemta, Jhalda (OBI)",
          "Chharrah",
          "Duarsini",
          "Durga Bera Dam",
          "Futiyari Dam",
          "Gajraidi",
          "Garpanchkot",
          "Garpanchkot (OBI)",
          "Jhalda",
          "Kuki Dam",
          "Kumari Dam",
          "Marble Lake",
          "Matha",
          "Murguma Dam",
          "Raghunathpur",
          "Rani Bandh",
          "Saheb Bandh",
          "Sidho-Kanho-Birsha University",
          "Sponsored Teachers Training College (Restricted)",
          "Telidih",
          "Tenyasi--Dhol Buru",
        ],
      },
      {
        district: "Purba Bardhaman",
        localities: [
          "Ajay Baandh",
          "Ambalgram--Shibai River Bank",
          "Barshul--Bajeshalepur PUB",
          "Barshul Ferry Ghat PUB",
          "Begor Mukh PUB",
          "Choitpukur",
          "Harishaba Park",
          "Isufabad--DVC Canal Bank",
          "Jhamatpur Bahran",
          "Kankurhati--Ajay River Bank",
          "Karjana Pond (Pakhiralay Pond)",
          "Mahagama PUB",
          "Mettalar Char, Kashthasali PUB",
          "Mettalar Char (mudflats)",
          "Purbasthali Bird Sanctuary PUB",
          "Purbasthali Oxbow Lake",
          "Ramnabagan WLS",
          "Roop Sayar",
          "Sadarghat Damodar River Bank PUB",
          "SIPHON DVC CANAL PUB",
          "University Institute Of Technology",
        ],
      },
    ],
  },
];
